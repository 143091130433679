import { useState } from 'react'

import TopTaps from './TopTaps'
import StatusControl from './StatusControl'
import SoftphoneContent from './SoftphoneContent'

import { useAppDispatch } from '../../store/hooks'
import { setPhoneCallStatus } from '../../store/reducer'

import { CallsDataProps, DispatchersProps, PermissionsProps } from '../../App'
import { Device } from 'twilio-client'

import '../../styles/components/online-call.sass'

interface ContactProps {
  company_name: string
  first_name: string
  last_name: string
  phone: string
}

interface OnlineCallProps {
  togglePhoneCall: () => void
  handleAnswer: (value: string) => void
  handleRejectCall: (value: string) => void
  disconnectedCall: (value: string) => void
  handleStopTalk: (value: string) => void
  handleOutgoingCall: (to: string, phone_id: string) => void
  handleRemoveCompletedCall: (value: string) => void
  callsData: CallsDataProps[] | []
  phoneCallStatus: string
  handleAddComment: (sid: string, tags: string, comment: string) => void
  permissions: Partial<PermissionsProps>
  handleCallExtension: (value: string) => void
  device: Device | null
  setSelectedCallCenter: (value: string) => void
  selectedCallCenter: string
  dispatcherTwillio: DispatchersProps[]
  handleRedirectCall: (call_sid: string, dispatcher_id: string) => void
  handlePauseCall: (call_sid: string) => void
  handleResumeCall: (call_sid: string) => void
}

const statusOptions = [
  'Available',
  // 'Outgoing Only', 
  'Not Available']

export default function OnlineCall({
  togglePhoneCall,
  handleAnswer,
  handleRejectCall,
  handleStopTalk,
  disconnectedCall,
  handleOutgoingCall,
  callsData,
  phoneCallStatus,
  handleRemoveCompletedCall,
  handleAddComment,
  permissions,
  handleCallExtension,
  device,
  setSelectedCallCenter,
  selectedCallCenter,
  dispatcherTwillio,
  handleRedirectCall,
  handlePauseCall,
  handleResumeCall,
}: OnlineCallProps) {
  const dispatch = useAppDispatch()

  const [tap, setTap] = useState('Home')
  const [volume, setVolume] = useState('50')
  const [actionMenu, setActionMenu] = useState<string[]>([])
  const [actionMenuCompetedCall, setActionMenuCompetedCall] = useState<string[]>([])
  const [infoBlockCompetedCall, setInfoBlockCompetedCall] = useState<string[]>([])
  const [infoBlock, setInfoBlock] = useState<string[]>([])
  const [infoBlockIncoming, setInfoBlockIncoming] = useState<string[]>([])
  const [pin, setPin] = useState(false)
  const [number, setNumber] = useState('+')
  const [selectedNumberFromContact, setSelectedNumberFromContact] = useState<Partial<ContactProps>>({})

  function handleVolume(value: string) {
    setVolume(value)
    if (value === '0') {
      if (device) {
        if (device.activeConnection()) {
          if (device.activeConnection()?.isMuted()) {
          } else {
            device.activeConnection()?.mute(true)
          }
        }
      }
    } else {
      if (device) {
        if (device.activeConnection()) {
          if (device.activeConnection()?.isMuted()) {
            device.activeConnection()?.mute(false)
          }
        }
      }
    }
  }

  async function handleMuteCall() {
    if (device) {
      if (device.activeConnection()) {
        if (device.activeConnection()?.isMuted()) {
          device.activeConnection()?.mute(false)
          setVolume('100')
        } else {
          setVolume('0')
          device.activeConnection()?.mute(true)
        }
      }
    }
  }

  function handleChangePhoneStatus(value: string) {
    dispatch(setPhoneCallStatus(value))
  }

  function handleSelectedContact(contact: ContactProps) {

    setSelectedNumberFromContact(contact)
    setTap('Dial')
    setNumber(contact.phone)
  }

  function handleDialCall(to: string, phone_id: string) {
    handleOutgoingCall(to, phone_id)
    setTap('Home')
    setNumber('+')
    setSelectedNumberFromContact({})
  }

  return (
    <div className="section__account_softphone">
      {/* Top of Taps */}
      <TopTaps
        tap={tap}
        setTap={setTap}
      />

      {/* Status Control */}
      <StatusControl
        statusOptions={statusOptions}
        status={phoneCallStatus}
        setStatus={handleChangePhoneStatus}
        togglePhoneCall={togglePhoneCall}
        volume={volume}
        handleVolume={handleVolume}
        softphone_calls={permissions.softphone_calls}
        handleMuteCall={handleMuteCall}
        setSelectedCallCenter={setSelectedCallCenter}
        selectedCallCenter={selectedCallCenter}
      />

      {/* Softphone Content */}
      <SoftphoneContent
        tap={tap}
        actionMenu={actionMenu}
        setActionMenu={setActionMenu}
        infoBlock={infoBlock}
        setInfoBlock={setInfoBlock}
        pin={pin}
        setPin={setPin}
        actionMenuCompetedCall={actionMenuCompetedCall}
        setActionMenuCompetedCall={setActionMenuCompetedCall}
        infoBlockCompetedCall={infoBlockCompetedCall}
        setInfoBlockCompetedCall={setInfoBlockCompetedCall}
        callsData={callsData}
        stop={handleRejectCall}
        disconnectedCall={disconnectedCall}
        handleAnswer={handleAnswer}
        stoptalk={handleStopTalk}
        number={number}
        setNumber={setNumber}
        handleRemoveCompletedCall={handleRemoveCompletedCall}
        infoBlockIncoming={infoBlockIncoming}
        setInfoBlockIncoming={setInfoBlockIncoming}
        handleAddComment={handleAddComment}
        permissions={permissions}
        selectedNumberFromContact={selectedNumberFromContact}
        handleSelectedContact={handleSelectedContact}
        handleDialCall={handleDialCall}
        phoneCallStatus={phoneCallStatus}
        handleCallExtension={handleCallExtension}
        setTap={setTap}
        dispatcherTwillio={dispatcherTwillio}
        handleRedirectCall={handleRedirectCall}
        handlePauseCall={handlePauseCall}
        handleResumeCall={handleResumeCall}
      />
    </div>
  )
}
