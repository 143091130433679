import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import moment from 'moment'

import SearchJob from './SearchJob'
import TicketsBlock from './TicketsBlock'

import { capitalizeFirstLetter, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

import NewTicketBlock from './NewTicketBlock'

import { CallTypeProps } from '../AppointmentReschedule/Models'
import Ticket from '../../models/Ticket'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import './CustomerService.sass'

interface HttpClientCallTypeReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallTypeProps
}

interface HttpTicketReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    tickets: Ticket[]
    ticket_templates: TemplateProps[]
    edit: EditProps
  }
}

export interface HttpTicketViewReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: TicketViewProps
}

export interface TicketViewProps {
  ticket: {
    date_time_start: string
    name: string
    number: number
    status: string
    theme: string
    ticket_id: string
    type: string
  }
  ticket_items: {
    author: string
    created_at: string
    message: string
  }[]
  responsible: {
    item_id: string
    type: string
    service_resource_code?: number
    service_resource_name?: string
  }[]
  edit: {
    user_groups: {
      name: string
      user_group_id: string
    }[]
    status: {
      id: number
      name: string
    }[]
  }
}

export interface EditProps {
  status: {
    id: number
    name: string
    type: string
  }[]
  user_groups: {
    name: string
    user_group_id: string
  }[]
}

export interface TemplateProps {
  responsibles: string
  template: string
  type: string
}

export interface ServiceOtionsProps {
  name: string
  service_resource_code: number
  id: string
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [appointmentSearch, setAppointmentSearch] = useState('')
  const [selectedJob, setSelectedJob] = useState<string>('')
  const [appointmentSearchData, setAppointmentSearchData] = useState<CallTypeProps | null>(null)

  const [edit, setEdit] = useState<EditProps | null>(null)

  const [ticketView, setTicketView] = useState<TicketViewProps | null>(null)
  const [isReserve, setIsReserve] = useState(false)

  const [message, setMessage] = useState('')
  const [ticketData, setTicketData] = useState({
    created_at: new Date(),
    number: 0,
    status: 'New',
    type: '',
    theme: '',
    author: '',
  })
  const [tickets, setTickets] = useState<Ticket[] | []>([])
  const [template, setTemplate] = useState<TemplateProps[] | []>([])
  const [serviceOptions, setServiceOptions] = useState<ServiceOtionsProps[] | []>([])
  const [selectedService, setSelectedService] = useState({
    name: '',
    code: 0,
    id: '',
  })
  const [sendTo, setSendTo] = useState({
    service_resource: false,
    author: false,
  })

  const [sendToFromEdit, setSendToFromEdit] = useState({})

  //  search input in callType component
  async function handleSearchAppointment(search?: string) {
    beforeSearchCleaner()
    try {
      const { data: { data: searchData, success, error } } = await httpClientUpdate.get('/bookings/client-search', {
        params: {
          account_id: activeAccountId,
          key_words: search ? search : appointmentSearch,
          page: 1,
          limit_rows: 100,
        }
      }) as { data: HttpClientCallTypeReport }
      if (success) {
        setAppointmentSearchData(searchData)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  //  get Ticket
  async function getTicket(jobId: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/customer-services?account_id=88888&job_id=88888211lka2jveic3
      const { data: { data: ticketData, success, error } } = await httpClientUpdate.get('/customer-services', {
        params: {
          account_id: activeAccountId,
          job_id: jobId
        }
      }) as { data: HttpTicketReport }
      if (success) {
        appointmentSearchData &&
          setAppointmentSearchData({
            jobs: appointmentSearchData.jobs.filter(item => item.job_id === jobId)
          })

        setEdit(ticketData.edit)
        setSelectedJob(jobId)
        setTickets(ticketData.tickets)
        setTemplate(ticketData.ticket_templates)

        let updatedSendEdit = {}
        let userGroups = ticketData.edit.user_groups.map(item => capitalizeFirstLetter(item.name))
        userGroups.forEach(item => updatedSendEdit[item] = false)
        setSendToFromEdit(updatedSendEdit)

      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function saveTicket() {
    setIsSaving(true)
    let created_at = moment(ticketData.created_at).format('MM/DD/YYYY hh:mma')
    try {
      let responsibles: { type: string; item_id: string | undefined }[] = []
      Object.keys(sendTo).forEach(item => {
        if (sendTo[item]) {
          if (item === 'service_resource') {
            responsibles.push({
              type: "service_resource",
              item_id: selectedService.id
            })
          } else if (item === 'author') {
            responsibles.push({
              type: "Author",
              item_id: user?.user_id
            })
          }
        }
      })

      Object.keys(sendToFromEdit).forEach(item => {
        if (sendToFromEdit[item]) {
          responsibles.push({
            type: 'user_group',
            item_id: edit ? edit.user_groups.filter(group => capitalizeFirstLetter(group.name) === item)[0].user_group_id : undefined
          })
        }
      })

      // https://2022back4.artemiudintsev.com/api/tickets
      const response = await httpClientUpdate.post(`/tickets`, {
        account_id: activeAccountId,
        type: ticketData.type,
        message: message,
        date: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, created_at as string),
        docs: [
          {
            type: "job",
            id: selectedJob
          },
        ],
        responsibles: responsibles
      })
      if (response.data.success) {
        loadTicketView(response.data.data.ticket_id)
        setMessage('')
        setTicketData({
          created_at: new Date(),
          number: 0,
          status: 'New',
          type: '',
          theme: '',
          author: 'Alex',
        })
        setServiceOptions([])
        setSelectedService({
          name: '',
          code: 0,
          id: ''
        })
        getTicket(selectedJob)
        setIsSaving(false)
      } else {
        setIsSaving(false)
      }

    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load info function
  async function loadTicketView(id: string) {
    try {
      const { data: { data: ticketData, success, error } } = await httpClientUpdate.get('/tickets/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpTicketViewReport }
      if (success) {
        setTicketView(ticketData)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function handleCloseViewTicket() {
    setTicketView(null)
  }

  function handleSelectedJob(jobId: string) {
    !selectedJob && getTicket(jobId)
  }

  useEffect(() => {
    updated && resetPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    if ($router.router.getState().params.caller_phone) {
      setAppointmentSearch($router.router.getState().params.caller_phone)
      handleSearchAppointment($router.router.getState().params.caller_phone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router])

  useEffect(() => {
    if (selectedJob) {
      appointmentSearchData?.jobs.forEach(item => {
        if (item.appointments.length) {
          if (item.appointments.length === 1) {
            item.appointments.forEach(app => {
              setServiceOptions([{
                name: app.service_resource_name,
                service_resource_code: app.service_resource_code,
                id: app.service_resource_id as string,
              }])
              setSelectedService({
                name: app.service_resource_name,
                code: app.service_resource_code,
                id: app.service_resource_id as string,
              })
            })
          } else {
            let arr: { name: string; service_resource_code: number, id: string }[] = []
            item.appointments.forEach((app, index) => {
              if (index === 0) {
                arr.push({
                  name: app.service_resource_name,
                  service_resource_code: app.service_resource_code,
                  id: app.service_resource_id as string
                })
              } else {
                let checkService = arr.filter(item => item.id === app.service_resource_id)
                !checkService.length && arr.push({
                  name: app.service_resource_name,
                  service_resource_code: app.service_resource_code,
                  id: app.service_resource_id as string
                })
              }
            })

            if (arr.length === 1) {
              setSelectedService({
                name: arr[0].name,
                code: arr[0].service_resource_code,
                id: arr[0].id as string,
              })
            }
            setServiceOptions(arr)
          }
        }
      })
    } else {
      setServiceOptions([])
      setSelectedService({
        name: '',
        code: 0,
        id: '',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob])

  function handleUpdatedTickets() {
    getTicket(selectedJob)
  }

  //  reset page function
  function resetPage() {
    setTicketView(null)
    setTickets([])
    setEdit(null)
    setAppointmentSearch('')
    setSelectedJob('')
    setAppointmentSearchData(null)
    setMessage('')
    setTicketData({
      created_at: new Date(),
      number: 0,
      status: 'New',
      type: '',
      theme: '',
      author: 'Alex',
    })
    setServiceOptions([])
    setSelectedService({
      name: '',
      code: 0,
      id: ''
    })
  }

  function beforeSearchCleaner() {
    setTicketView(null)
    setTickets([])
    setEdit(null)
    setSelectedJob('')
    setAppointmentSearchData(null)
    setMessage('')
    setTicketData({
      created_at: new Date(),
      number: 0,
      status: 'New',
      type: '',
      theme: '',
      author: 'Alex',
    })
    setServiceOptions([])
    setSelectedService({
      name: '',
      code: 0,
      id: ''
    })
  }

  return (
    <div className='customer-service'>
      <div className='center'>
        <SearchJob
          appointmentSearch={appointmentSearch}
          setAppointmentSearch={setAppointmentSearch}
          handleSearchAppointment={handleSearchAppointment}
          appointmentSearchData={appointmentSearchData}
          selectedJob={selectedJob}
          handleSelectedJob={handleSelectedJob}
          isReserve={isReserve}
        />

        {
          selectedJob &&
          <TicketsBlock
            tickets={tickets}
            ticketView={ticketView}
            handleCloseViewTicket={handleCloseViewTicket}
            loadTicketView={loadTicketView}
            setTicketView={setTicketView}
            handleUpdatedTickets={handleUpdatedTickets}
            isReserve={isReserve}
            setIsReserve={setIsReserve}
          />
        }

        {
          selectedJob &&
          <NewTicketBlock
            ticketData={ticketData}
            setTicketData={setTicketData}
            message={message}
            setMessage={setMessage}
            template={template}
            jobName={appointmentSearchData?.jobs[0].job_name}
            serviceOptions={serviceOptions}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            edit={edit}
            sendTo={sendTo}
            setSendTo={setSendTo}
            saveTicket={saveTicket}
            sendToFromEdit={sendToFromEdit}
            setSendToFromEdit={setSendToFromEdit}
            isReserve={isReserve}
            isSaving={isSaving}
          />
        }
      </div>
    </div>
  )
}

