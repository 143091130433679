import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Checkbox from "../../../components/Checkbox"
import Icon from "../../../components/Icon"
import ReportFilters from "../../../components/reports/Filters"
import ReportTableControls from "../../../components/reports/TableControls"
import ReportTableField from "../../../components/reports/TableField"

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../../funcs";
import { useAppSelector } from "../../../store/hooks"

import { CallCenter_Dispatcher } from "../../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/report-list.sass"

type FilterWord = 'phone' | 'softphone' | 'active' | 'mentor'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DispatchersReport
}

export interface DispatchersReport {

  interface: {

    filter_words: {

      phone: string[],
      softphone: string[],
      active: string[],
      mentor: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    dispatcher_add: boolean
    dispatcher_delete: boolean
    dispatcher_edit: boolean
    dispatcher_report_show: boolean
    dispatcher_schedule_add: boolean
    dispatcher_schedule_delete: boolean
    dispatcher_schedule_edit: boolean
    dispatcher_schedule_show: boolean
    dispatcher_show: boolean
  },

  edit: {
    users: [
      {
        user_id: string,
        user: string
      }[]
    ],
    mentors: {
      service_resource: string
      user_name: string
      user_id: string
    }[]
  },

  dispatchers: CallCenter_Dispatcher[]
}

function CallCenter_DispatchersPage_List() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<DispatchersReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.call_center_dispatchers?.filter_field ? {
      phone: [] as string[],
      softphone: [] as string[],
      active: [] as string[],
      mentor: [] as string[],
      ...cookiesSetting.call_center_dispatchers.filter_field,
    } : {
      phone: [] as string[],
      softphone: [] as string[],
      active: [] as string[],
      mentor: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.call_center_dispatchers?.sort_field ? cookiesSetting.call_center_dispatchers.sort_field : '',
      direction: cookiesSetting && cookiesSetting?.call_center_dispatchers?.sort_type ? cookiesSetting.call_center_dispatchers.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'User',
      value: 'user'
    }, {
      span: 'Mentor',
      value: 'mentor'
    }, {
      span: 'Phone',
      value: 'phone'
    }, {
      span: 'Phone',
      value: 'is_phone'
    }, {
      span: 'Softphone',
      value: 'is_softphone'
    }, {
      span: 'Groups',
      value: 'call_groups'
    }, {
      span: 'Active',
      value: 'active'
    }, {
      span: '',
      value: '',
    }]

  })

  // Load dispatchers function
  async function loadDispatchers() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field ? $router.router.getState().params.localInterface.sort.field : null,
          sort_type: $router.router.getState().params.localInterface.sort.field ? $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc' : null,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field ? localInterface.sort.field : null,
          sort_type: localInterface.sort.field ? localInterface.sort.direction === 'up' ? 'asc' : 'desc' : null,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, call_center_dispatchers: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.call_center_dispatchers?.limit_rows ? cookiesSetting?.call_center_dispatchers.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: dispatchersReport, success, error } } = (await httpClientUpdate.post('/callcenter/dispatchers/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.call_center_dispatchers?.limit_rows ? cookiesSetting?.call_center_dispatchers?.limit_rows : reportsMaxRows,
        ...filterParams,
        filters: activeFilters,
      }, { skipNulls: true }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (dispatchersReport.permissions.dispatcher_report_show) {
          setReportData({
            ...dispatchersReport,
            interface: {
              ...dispatchersReport.interface,
              filter_words: {
                ...localInterface.filter_words,
                ...dispatchersReport.interface.filter_words,
              }
            }
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, call_center_dispatchers: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadDispatchers()
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getMentorName(id: string) {
    let name = ''

    reportData && reportData.edit.mentors.forEach(item => {
      if (item.user_id === id) {
        name = item.user_name
      }
    })

    return name
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="CallCenter_DispatchersPage_List" >

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="page-header">
          <h1>Dispatchers</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.call_center_dispatchers?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, call_center_dispatchers: { ...cookiesSetting.call_center_dispatchers, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.dispatcher_add ?
              (
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    onClick={() => $router.router.navigate('call_center.dispatchers.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                    }, { reload: true })}>
                    <Icon icon="plus-thin" />
                    <span>Add Dispatcher</span>
                    <Icon icon="user-1" />
                  </button>
                </div>
              ) :
              <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>User</span>)}

                  sortDirection={localInterface.sort.field === 'user' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'user', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Mentor</span>)}

                  allFiltersSelected={isAllFiltersSelected("mentor")}
                  onAllFiltersChange={(value) => switchFilter("mentor", "All", value)}

                  filterWords={reportData.interface.filter_words.mentor.map((filterWord) => ({
                    word: filterWord,
                    word_name: getMentorName(filterWord),
                    selected: isFilterSelected("mentor", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("mentor", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'mentor' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'mentor', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Phone</span>)}

                  sortDirection={localInterface.sort.field === 'phone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Phone</span>)}

                  allFiltersSelected={isAllFiltersSelected("phone")}
                  onAllFiltersChange={(value) => switchFilter("phone", "All", value)}

                  filterWords={reportData.interface.filter_words.phone.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("phone", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("phone", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'is_phone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'is_phone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Softphone</span>)}

                  allFiltersSelected={isAllFiltersSelected("softphone")}
                  onAllFiltersChange={(value) => switchFilter("softphone", "All", value)}

                  filterWords={reportData.interface.filter_words.softphone.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("softphone", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("softphone", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'is_softphone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'is_softphone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Groups</span>)}

                  sortDirection={localInterface.sort.field === 'call_groups' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_groups', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Active</span>)}

                  allFiltersSelected={isAllFiltersSelected("active")}
                  onAllFiltersChange={(value) => switchFilter("active", "All", value)}

                  filterWords={reportData.interface.filter_words.active.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("active", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("active", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'active' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'active', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.dispatchers.map((dispatcher, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.dispatcher_show && dispatcher.dispatcher_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  $router.router.navigate('call_center.dispatchers.item', {
                    companyId: activeAccountId,
                    dispatchersId: dispatcher.dispatcher_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  handleChangeMenu(event, dispatcher.dispatcher_id, 'dispatchers', i)}
              >
                <td>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</td>
                <td>{dispatcher.user}</td>
                <td>{dispatcher.mentor}</td>
                <td>{formatPhoneNumber(dispatcher.phone)}</td>
                <td><Checkbox contents='' value={dispatcher.is_phone} /></td>
                <td><Checkbox contents='' value={dispatcher.is_softphone} /></td>
                <td>{dispatcher.call_groups}</td>
                <td className={classNames({
                  'red-text': !dispatcher.active,
                  'green-text': dispatcher.active,
                })}>
                  {dispatcher.active ? 'on' : 'off'}
                  {
                    reportData.permissions.dispatcher_show &&
                    showContext === `dispatchers_${dispatcher.dispatcher_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.dispatchers.item', {
                            companyId: activeAccountId,
                            dispatchersId: dispatcher.dispatcher_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/dispatchers/${dispatcher.dispatcher_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.dispatchers.map((dispatcher, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.dispatcher_show && dispatcher.dispatcher_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  $router.router.navigate('call_center.dispatchers.item', {
                    companyId: activeAccountId,
                    dispatchersId: dispatcher.dispatcher_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  handleChangeMenu(event, dispatcher.dispatcher_id, 'dispatchers', i)}
              >
                <td>
                  <div>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</div>
                  <div>{dispatcher.user}</div>
                </td>
                <td>
                  <div>{dispatcher.mentor}</div>
                  <div>{formatPhoneNumber(dispatcher.phone)}</div>
                </td>
                <td>
                  <div className="flex-container"><span>Phone:</span> <Checkbox contents='' value={dispatcher.is_phone} /></div>
                  <div className="flex-container"><span>Softphone:</span> <Checkbox contents='' value={dispatcher.is_softphone} /></div>
                </td>
                <td>
                  <div>{dispatcher.call_groups}</div>
                  <div className={classNames({
                    'red-text': !dispatcher.active,
                    'green-text': dispatcher.active,
                  })}>{dispatcher.active ? 'on' : 'off'}</div>
                  {
                    reportData.permissions.dispatcher_show &&
                    showContext === `dispatchers_${dispatcher.dispatcher_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.dispatchers.item', {
                            companyId: activeAccountId,
                            dispatchersId: dispatcher.dispatcher_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/dispatchers/${dispatcher.dispatcher_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.dispatchers.map((dispatcher, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  $router.router.navigate('call_center.dispatchers.item', {
                    companyId: activeAccountId,
                    dispatchersId: dispatcher.dispatcher_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><b>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</b></div>
                    <div>
                      {dispatcher.user}
                    </div>

                    <div> Mentor: {dispatcher.mentor} </div>
                  </div>

                  <div className="__right small">
                    <div className={classNames({
                      'red-text': !dispatcher.active,
                      'green-text': dispatcher.active,
                    })}>
                      {dispatcher.active ? 'on' : 'off'}
                    </div>
                    <div>
                      {formatPhoneNumber(dispatcher.phone)}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div className="flex-container">
                      <span className="gray">Phone:</span>
                      <span> <Checkbox contents='' value={dispatcher.is_phone} /></span>
                    </div>
                    <div className="flex-container">
                      <span className="gray">Softphone:</span>
                      <span> <Checkbox contents='' value={dispatcher.is_softphone} /></span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      <b>{dispatcher.call_groups}</b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.call_center_dispatchers?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, call_center_dispatchers: { ...cookiesSetting.call_center_dispatchers, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default CallCenter_DispatchersPage_List
