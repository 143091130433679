import { memo, useState } from 'react'

import Icon from '../../components/Icon'
import AutoCompliteSelectComponent from '../../components/AutoCompliteSelect'

import { ApplianceBrandsProps, AppliancesProps, UnitsListProps, CallContactProps } from './Models'

let unitTypeOptions = ['Residential', 'Commercial', 'Industrial']

interface CallUnitsProps {
  unitOptions: AppliancesProps[] | []
  brandOptions: ApplianceBrandsProps[] | []
  callContact: CallContactProps
  setCallContact: (value: CallContactProps) => void
  unitsList: UnitsListProps[] | []
  setUnitsList: (value: UnitsListProps[]) => void
}

interface UnitProps {
  unit_type: string
  appliance: string
  appliance_id: string
  unit_id: string
  brand: string
  brand_id: string
  other_unit: string
  other_brand: string
  serial_number: string
  model_number: string
  description: string
  diagnostic_fee: string
  is_use: boolean
  is_new: boolean
}

const initialUnit = {
  unit_type: '',
  appliance: '',
  appliance_id: '',
  unit_id: '',
  brand: '',
  brand_id: '',
  other_unit: '',
  other_brand: '',
  serial_number: '',
  model_number: '',
  description: '',
  diagnostic_fee: 'Diagnostic Fee $69',
  is_use: true,
  is_new: true,
}

function CallUnits({
  unitOptions,
  brandOptions,
  callContact,
  setCallContact,
  unitsList,
  setUnitsList
}: CallUnitsProps) {
  const [editUnit, setEditUnit] = useState(0)
  const [editNewUnit, setEditNewUnit] = useState(0)
  const [newUnit, setNewUnit] = useState<UnitProps[]>([initialUnit])

  const [changeUnit, setChangeUnit] = useState(initialUnit)

  function handleRemoveNewUnit(i: number) {
    setNewUnit(newUnit.filter((item, index) => index !== i))
  }

  function handleCancelNewUnit() {
    setEditNewUnit(0)

    setChangeUnit(initialUnit)
  }

  function handleCancelUnit() {
    setEditUnit(0)

    setChangeUnit(initialUnit)
  }

  function handleAddUnit(i: number) {
    let updateUnitsList = unitsList.map(item => item)
    updateUnitsList.push({
      unit_type: newUnit[i].unit_type,
      appliance: newUnit[i].appliance,
      appliance_id: newUnit[i].appliance_id,
      unit_id: newUnit[i].unit_id,
      brand: newUnit[i].brand,
      brand_id: newUnit[i].brand_id,
      other_unit: newUnit[i].other_unit,
      other_brand: newUnit[i].other_brand,
      serial_number: newUnit[i].serial_number,
      model_number: newUnit[i].model_number,
      description: newUnit[i].description,
      diagnostic_fee: newUnit[i].diagnostic_fee,
      is_new: true,
      is_use: typeof newUnit[i].is_use === 'boolean' ? newUnit[i].is_use : true
    })
    setUnitsList(updateUnitsList)
    setNewUnit(newUnit.filter((iten, index) => i !== index))
  }

  function handleRemoveUnit(index: number) {
    let updateUnitsList = unitsList.filter((item, idx) => index !== idx)

    setUnitsList(updateUnitsList)
  }

  function handleEditUnit(index: number) {
    setEditNewUnit(0)
    setEditUnit(index + 1)
    let activeUnit = callContact.client.filter(item => item.active)[0].units

    setChangeUnit({
      unit_type: activeUnit[index].unit_type,
      appliance: activeUnit[index].appliance,
      appliance_id: activeUnit[index].appliance_id,
      unit_id: activeUnit[index].unit_id as string,
      brand: activeUnit[index].brand,
      brand_id: activeUnit[index].brand_id,
      other_unit: activeUnit[index].other_unit,
      other_brand: activeUnit[index].other_brand,
      serial_number: activeUnit[index].serial_number,
      model_number: activeUnit[index].model_number,
      description: activeUnit[index].description,
      diagnostic_fee: activeUnit[index].diagnostic_fee,
      is_use: activeUnit[index].is_use,
      is_new: activeUnit[index].is_new,
    })
  }

  function handleEditNewUnit(index: number) {
    setEditUnit(0)
    setEditNewUnit(index + 1)
    setChangeUnit({
      unit_type: unitsList[index].unit_type,
      appliance: unitsList[index].appliance,
      appliance_id: unitsList[index].appliance_id,
      unit_id: unitsList[index].unit_id as string,
      brand: unitsList[index].brand,
      brand_id: unitsList[index].brand_id,
      other_unit: unitsList[index].other_unit,
      other_brand: unitsList[index].other_brand,
      serial_number: unitsList[index].serial_number,
      model_number: unitsList[index].model_number,
      description: unitsList[index].description,
      diagnostic_fee: unitsList[index].diagnostic_fee,
      is_use: unitsList[index].is_use,
      is_new: unitsList[index].is_new,
    })
  }

  function handleAddEditNewUnit() {
    let updatedUnitsList = unitsList.map((item, index) => {
      if (index === editNewUnit - 1) {
        return {
          unit_type: changeUnit.unit_type,
          appliance: changeUnit.appliance,
          appliance_id: changeUnit.appliance_id,
          unit_id: changeUnit.unit_id as string,
          brand: changeUnit.brand,
          brand_id: changeUnit.brand_id,
          other_unit: changeUnit.other_unit,
          other_brand: changeUnit.other_brand,
          serial_number: changeUnit.serial_number,
          model_number: changeUnit.model_number,
          description: changeUnit.description,
          diagnostic_fee: changeUnit.diagnostic_fee,
          is_use: changeUnit.is_use,
          is_new: changeUnit.is_new
        }
      } else {
        return { ...item }
      }
    })

    setEditNewUnit(0)
    setUnitsList(updatedUnitsList)
    setChangeUnit(initialUnit)
  }

  function handleAddEditUnit() {
    let updateUnitsList = callContact.client.map(item => {
      if (item.active) {
        return {
          ...item,
          units: item.units.map((item, index) => {
            if (index === editUnit - 1) {
              return {
                unit_type: changeUnit.unit_type,
                appliance: changeUnit.appliance,
                appliance_id: changeUnit.appliance_id,
                unit_id: changeUnit.unit_id as string,
                brand: changeUnit.brand,
                brand_id: changeUnit.brand_id,
                other_unit: changeUnit.other_unit,
                other_brand: changeUnit.other_brand,
                serial_number: changeUnit.serial_number,
                model_number: changeUnit.model_number,
                description: changeUnit.description,
                diagnostic_fee: changeUnit.diagnostic_fee,
                is_use: changeUnit.is_use,
                is_new: changeUnit.is_new
              }
            } else {
              return { ...item }
            }
          })
        }
      } else {
        return { ...item }
      }
    })

    setEditUnit(0)
    setCallContact({
      client: updateUnitsList
    })
    setChangeUnit(initialUnit)
  }

  function checkUnits() {
    let check = true

    let unitsListUse = callContact.client.filter(item => item.active)[0].units.filter(item => item.is_use)
    let newUnitsListUse = unitsList.filter(item => item.is_use)

    if (!unitsListUse.length && !newUnitsListUse.length) {
      check = false
    }

    newUnitsListUse.forEach(item => {
      if (
        item.brand_id === '' ||
        item.appliance_id === '' ||
        item.unit_type === ''
      ) {
        check = false
      }
    })

    unitsListUse.forEach(item => {
      if (
        item.brand_id === '' ||
        item.appliance_id === '' ||
        item.unit_type === ''
      ) {
        check = false
      }
    })
    return check
  }

  function handleUseUnit(value: boolean, index: number) {
    let updatedUnitsList = callContact.client.map(item => {
      if (item.active) {
        return {
          ...item,
          units: item.units.map((unit, idx) => {
            if (idx === index) {
              return {
                ...unit,
                is_use: value
              }
            } else {
              return { ...unit }
            }
          })
        }
      } return {
        ...item
      }
    })

    setCallContact({
      client: updatedUnitsList
    })
  }

  function handleUseNewUnit(value: boolean, index: number) {
    let updateUnitsList = unitsList.map((item) => item)
    updateUnitsList[index].is_use = value

    setUnitsList(updateUnitsList)
  }

  function handleSelectAutoComplite(id: string, span: string, name: string, otherSpan?: string) {
    if (name === 'appliance') {
      setChangeUnit({ ...changeUnit, appliance_id: id, appliance: span, other_unit: otherSpan ? otherSpan : '' })
    } else {
      setChangeUnit({ ...changeUnit, brand_id: id, brand: span, other_brand: otherSpan ? otherSpan : '' })
    }
  }

  function handleAddNewUnit() {
    let updatedNewUnit = newUnit.map(item => item)

    updatedNewUnit.push(initialUnit)

    setNewUnit(updatedNewUnit)
  }

  function editNewUnitFunction(i: number, name: string, value: string) {
    let updatedNewUnit = newUnit.map((item, index) => {
      if (index === i) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })

    setNewUnit(updatedNewUnit)
  }

  function handleSelectAutoCompliteNewUnit(i: number, id: string, span: string, name: string, otherSpan?: string) {
    if (name === 'appliance') {
      let updatedUnit = newUnit.map((item, index) => {
        if (i === index) {
          return {
            ...item,
            appliance_id: id,
            appliance: span,
            other_unit: otherSpan ? otherSpan : ''
          }
        } else {
          return { ...item }
        }
      })
      setNewUnit(updatedUnit)
    } else {
      let updatedUnit = newUnit.map((item, index) => {
        if (i === index) {
          return {
            ...item,
            brand_id: id,
            brand: span,
            other_brand: otherSpan ? otherSpan : ''
          }
        } else {
          return { ...item }
        }
      })
      setNewUnit(updatedUnit)
    }
  }

  return (
    <div className='call-units'>
      <div className='block-content'>
        <div className='block-content-title'>
          <div className='block-title'>Units</div>

          {
            !unitsList.length && !callContact.client.filter(item => item.active)[0].units.length ?
              <div className='btn-marking attention'>
                !
              </div> :
              checkUnits() ?
                <div className='btn-marking saved'>
                  <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                </div> :
                <div className='btn-marking unsaved'>
                  <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                </div>
          }
        </div>

        <div className='data-border call-units-content'>
          <div className='header-units'>
            <div style={{ marginRight: '60px' }}>
              <p>Use</p>
            </div>
          </div>
          {
            callContact.client.filter(item => item.active)[0].units.map((item, index) => (
              <div key={index} className='block-content-units'>
                <div>
                  <p>
                    {!!editUnit && editUnit - 1 === index && '* '}
                    {item.unit_type}: {item.appliance === 'Other' ? item.other_unit : item.appliance},{' '}
                    {item.brand === 'Other' ? item.other_brand : item.brand}
                    <span style={{ color: 'rgb(142, 142, 147)' }}> {item.diagnostic_fee ? `/ Price: ${item.diagnostic_fee}` : ''}  </span>
                  </p>
                  <p style={{ color: 'rgb(142, 142, 147)' }}>
                    {item.serial_number ? `s/n: ${item.serial_number} ` : ''}
                    {item.serial_number && item.model_number && '/ '}
                    {item.model_number ? `m/n: ${item.model_number}` : ''}
                  </p>
                  <p style={{ color: 'rgb(142, 142, 147)' }}> {item.description} </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <div
                    className='trash call-contact_new-block_trash inactive'
                    style={{ marginRight: '34px' }}
                  >
                    <div className='check'>
                      <input
                        className='input-checkbox'
                        name='is_use'
                        type="checkbox"
                        checked={!!item.is_use}
                        onChange={(event) => handleUseUnit(event.target.checked, index)}
                      />

                      <label className='checkbox' htmlFor="">
                        {
                          !!item.is_use &&
                          <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                        }
                      </label>
                    </div>
                  </div>

                  <div
                    className='trash call-contact_new-block_trash inactive'
                    style={{ marginRight: '10px' }}
                    onClick={() => handleEditUnit(index)}
                  >
                    <Icon
                      style={{ fill: '#FFB700', width: '100%' }}
                      viewBox="0 0 16 16"
                      icon="pencil-1"
                    />
                  </div>

                  <div
                    className='trash call-contact_new-block_trash inactive'
                    onClick={() => item.is_new && handleRemoveUnit(index)}
                  >
                    <Icon
                      style={{ fill: item.is_new ? '#FF0000' : '#8e8e93', width: '90%' }}
                      icon="x-mark-1"
                    />
                  </div>
                </div>
              </div>
            ))
          }

          {
            unitsList.map((item, index) => (
              <div key={index} className='block-content-units'>
                <div>
                  <p>
                    {!!editNewUnit && editNewUnit - 1 === index && '* '}
                    {item.unit_type}: {item.appliance === 'Other' ? item.other_unit : item.appliance},{' '}
                    {item.brand === 'Other' ? item.other_brand : item.brand}
                    <span style={{ color: 'rgb(142, 142, 147)' }}> {item.diagnostic_fee ? `/ Price: ${item.diagnostic_fee}` : ''}  </span>
                  </p>
                  <p style={{ color: 'rgb(142, 142, 147)' }}>
                    {item.serial_number ? `s/n: ${item.serial_number} ` : ''}
                    {item.serial_number && item.model_number && '/ '}
                    {item.model_number ? `m/n: ${item.model_number}` : ''}
                  </p>
                  <p style={{ color: 'rgb(142, 142, 147)' }}> {item.description} </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <div
                    className='trash call-contact_new-block_trash inactive'
                    style={{ marginRight: '34px' }}
                  >
                    <div className='check'>
                      <input
                        className='input-checkbox'
                        name='is_use'
                        type="checkbox"
                        checked={!!item.is_use}
                        onChange={(event) => handleUseNewUnit(event.target.checked, index)}
                      />

                      <label className='checkbox' htmlFor="">
                        {
                          !!item.is_use &&
                          <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                        }
                      </label>
                    </div>
                  </div>

                  <div
                    className='trash call-contact_new-block_trash inactive'
                    style={{ marginRight: '10px' }}
                    onClick={() => handleEditNewUnit(index)}
                  >
                    <Icon
                      style={{ fill: '#FFB700', width: '100%' }}
                      viewBox="0 0 16 16"
                      icon="pencil-1"
                    />
                  </div>

                  <div
                    className='trash call-contact_new-block_trash inactive'
                    onClick={() => item.is_new && handleRemoveUnit(index)}
                  >
                    <Icon
                      style={{ fill: item.is_new ? '#FF0000' : '#8e8e93', width: '90%' }}
                      icon="x-mark-1"
                    />
                  </div>
                </div>
              </div>
            ))
          }

          <div className='call-units-add'>
            <div className='call-units_content-add'>
              <button
                className='btn btn-add'
                onClick={() => handleAddNewUnit()}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        {
          newUnit.map((unit, index) => (
            <div key={index} className='call-units_content'>
              <div className='data-border call-units_add'>
                <div style={{ flexWrap: 'wrap' }} className='call-units_add-row'>
                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Unit Type:</p>

                    <AutoCompliteSelectComponent
                      options={unitTypeOptions.map((type) => ({
                        span: type,
                        value: type
                      }))}
                      selectedOption={unit.unit_type}
                      onChange={(value: string) =>
                        editNewUnitFunction(index, 'unit_type', value)
                      }
                    />
                  </div>

                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Unit:</p>

                    <AutoCompliteSelectComponent
                      options={unitOptions.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                        span: type.name,
                        value: type.appliance_id
                      }))}
                      selectedOption={unit.appliance_id || ''}
                      onChange={(value: string, span: string, otherSpan: string) =>
                        handleSelectAutoCompliteNewUnit(index, value, span, 'appliance', otherSpan)
                      }
                    />
                  </div>

                  {
                    unit.appliance === 'Other' &&
                    <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                      <p className='call-units_add-data_title'>Other Unit:</p>

                      <input
                        style={{ width: '50%' }}
                        value={unit.other_unit}
                        onChange={(event) => editNewUnitFunction(index, 'other_unit', event.target.value)}
                        className='data-border call-units_add-modelNumber_input'
                        type="text"
                      />
                    </div>
                  }

                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Brand:</p>

                    <AutoCompliteSelectComponent
                      options={brandOptions.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                        span: type.name,
                        value: type.brand_id
                      }))}
                      selectedOption={unit.brand_id || ''}
                      onChange={(value: string, span: string, otherSpan: string) =>
                        handleSelectAutoCompliteNewUnit(index, value, span, 'brand', otherSpan)
                      }
                    />
                  </div>

                  {
                    unit.brand === 'Other' &&
                    <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                      <p className='call-units_add-data_title'>Other Brand:</p>

                      <input
                        style={{ width: '50%' }}
                        value={unit.other_brand}
                        onChange={(event) => editNewUnitFunction(index, 'other_brand', event.target.value)}
                        className='data-border call-units_add-modelNumber_input'
                        type="text"
                      />
                    </div>
                  }

                  <div style={{ width: '30%', marginBottom: '10px' }} className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor="">Model Number:</label>

                    <input
                      style={{ width: '50%' }}
                      value={unit.model_number}
                      onChange={(event) => editNewUnitFunction(index, 'model_number', event.target.value)}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>

                  <div style={{ width: '30%', marginBottom: '10px' }} className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor="">Serial Number:</label>

                    <input
                      style={{ width: '50%' }}
                      value={unit.serial_number}
                      onChange={(event) => editNewUnitFunction(index, 'serial_number', event.target.value)}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>
                </div>

                <div className='call-units_add-row'>
                  <div className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor="">Description:</label>

                    <textarea
                      className='data-border call-units_add-description_textarea'
                      value={unit.description}
                      onChange={(event) => editNewUnitFunction(index, 'description', event.target.value)}
                      cols={2}
                      rows={2}
                    ></textarea>
                  </div>

                  <div className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor=""> Price: </label>

                    <input
                      value={unit.diagnostic_fee}
                      onChange={(event) => editNewUnitFunction(index, 'diagnostic_fee', event.target.value)}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>
                </div>

                <div
                  className='call-units_add-row'
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div className='call-units_add-data mobile-btns'>
                    <div style={{
                      marginRight: '1rem',
                    }}>
                      <button
                        className='btn btn-save'
                        style={{
                          fontSize: '13px',
                          padding: '1px 18px'
                        }}
                        disabled={unit.appliance_id === '' || unit.brand_id === '' || unit.unit_type === ''}
                        onClick={() => handleAddUnit(index)}
                      >
                        Save
                      </button>
                    </div>

                    <button
                      className='btn btn-cancel'
                      style={{
                        fontSize: '13px',
                        padding: '1px 18px'
                      }}
                      onClick={() => handleRemoveNewUnit(index)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        }

        {
          (!!editUnit || !!editNewUnit) &&
          <div className='call-units_content'>
            <div className='data-border call-units_add'>
              <div style={{ flexWrap: 'wrap' }} className='call-units_add-row'>
                <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                  <p className='call-units_add-data_title'>Unit Type:</p>

                  <AutoCompliteSelectComponent
                    options={unitTypeOptions.map((type) => ({
                      span: type,
                      value: type
                    }))}
                    selectedOption={changeUnit.unit_type}
                    onChange={(value: string) => setChangeUnit({ ...changeUnit, unit_type: value })}
                  />
                </div>

                <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                  <p className='call-units_add-data_title'>Unit:</p>

                  <AutoCompliteSelectComponent
                    options={unitOptions.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                      span: type.name,
                      value: type.appliance_id
                    }))}
                    selectedOption={changeUnit.appliance_id || ''}
                    onChange={(value: string, span: string, otherSpan: string) =>
                      handleSelectAutoComplite(value, span, 'appliance', otherSpan)
                    }
                  />
                </div>

                {
                  changeUnit.appliance === 'Other' &&
                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Other Unit:</p>

                    <input
                      style={{ width: '50%' }}
                      value={changeUnit.other_unit}
                      onChange={(event) => setChangeUnit({ ...changeUnit, other_unit: event.target.value })}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>
                }

                <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                  <p className='call-units_add-data_title'>Brand:</p>

                  <AutoCompliteSelectComponent
                    options={brandOptions.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                      span: type.name,
                      value: type.brand_id
                    }))}
                    selectedOption={changeUnit.brand_id || ''}
                    onChange={(value: string, span: string, otherSpan: string) =>
                      handleSelectAutoComplite(value, span, 'brand', otherSpan)
                    }
                  />
                </div>

                {
                  changeUnit.brand === 'Other' &&
                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Other Brand:</p>

                    <input
                      style={{ width: '50%' }}
                      value={changeUnit.other_brand}
                      onChange={(event) => setChangeUnit({ ...changeUnit, other_brand: event.target.value })}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>
                }

                <div style={{ width: '30%', marginBottom: '10px' }} className='call-units_add-data'>
                  <label className='call-units_add-data_title' htmlFor="">Model Number:</label>

                  <input
                    style={{ width: '50%' }}
                    value={changeUnit.model_number}
                    onChange={(event) => setChangeUnit({ ...changeUnit, model_number: event.target.value })}
                    className='data-border call-units_add-modelNumber_input'
                    type="text"
                  />
                </div>

                <div style={{ width: '30%', marginBottom: '10px' }} className='call-units_add-data'>
                  <label className='call-units_add-data_title' htmlFor="">Serial Number:</label>

                  <input
                    style={{ width: '50%' }}
                    value={changeUnit.serial_number}
                    onChange={(event) => setChangeUnit({ ...changeUnit, serial_number: event.target.value })}
                    className='data-border call-units_add-modelNumber_input'
                    type="text"
                  />
                </div>
              </div>

              <div className='call-units_add-row'>
                <div className='call-units_add-data'>
                  <label className='call-units_add-data_title' htmlFor="">Description:</label>

                  <textarea
                    className='data-border call-units_add-description_textarea'
                    value={changeUnit.description}
                    onChange={(event) => setChangeUnit({ ...changeUnit, description: event.target.value })}
                    cols={2}
                    rows={2}
                  ></textarea>
                </div>

                <div className='call-units_add-data'>
                  <label className='call-units_add-data_title' htmlFor=""> Price: </label>

                  <input
                    value={changeUnit.diagnostic_fee}
                    onChange={(event) => setChangeUnit({ ...changeUnit, diagnostic_fee: event.target.value })}
                    className='data-border call-units_add-modelNumber_input'
                    type="text"
                  />
                </div>
              </div>

              <div
                className='call-units_add-row'
                style={{ justifyContent: 'flex-end' }}
              >
                <div className='call-units_add-data mobile-btns'>
                  <div style={{
                    marginRight: '1rem',
                  }}>
                    {
                      !!editNewUnit &&
                      <button
                        className='btn btn-save'
                        style={{
                          fontSize: '13px',
                          padding: '1px 18px'
                        }}
                        disabled={changeUnit.appliance_id === '' || changeUnit.brand_id === '' || changeUnit.unit_type === ''}
                        onClick={() => handleAddEditNewUnit()}
                      >
                        Save
                      </button>
                    }
                    {
                      !!editUnit &&
                      <button
                        className='btn btn-save'
                        style={{
                          fontSize: '13px',
                          padding: '1px 18px'
                        }}
                        disabled={changeUnit.appliance_id === '' || changeUnit.brand_id === '' || changeUnit.unit_type === ''}
                        onClick={() => handleAddEditUnit()}
                      >
                        Save
                      </button>
                    }
                  </div>

                  {
                    !!editNewUnit &&
                    <button
                      className='btn btn-cancel'
                      style={{
                        fontSize: '13px',
                        padding: '1px 18px'
                      }}
                      onClick={() => handleCancelNewUnit()}
                    >
                      Cancel
                    </button>
                  }

                  {
                    !!editUnit &&
                    <button
                      className='btn btn-cancel'
                      style={{
                        fontSize: '13px',
                        padding: '1px 18px'
                      }}
                      onClick={() => handleCancelUnit()}
                    >
                      Cancel
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default memo(CallUnits)
