import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'
import moment, { Moment } from 'moment'
import Datetime from 'react-datetime'

import Icon from '../../../components/Icon'
import Checkbox from '../../../components/Checkbox'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../../funcs'
import { useAppSelector } from '../../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportData
}

interface ReportData {

  permissions: {
    additional_time_dispatcher_delete: boolean
    additional_time_dispatcher_edit: boolean
  },

  dispatcher_additional_time: AdditionalTime
}

interface AdditionalTime {
  additional_time_end: Moment | Date | string;
  additional_time_start: Moment | Date | string;
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string

  dispatcher_additional_time_id: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<ReportData | null>(null)

  const [newData, setNewData] = useState<Partial<AdditionalTime>>({
  })

  function handleCancelEdit() {
    setEditing(false)
    if (reportData) {
      const dateStart = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.dispatcher_additional_time.additional_time_start as string)
      const dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.dispatcher_additional_time.additional_time_end as string)

      setNewData({
        ...reportData.dispatcher_additional_time,
        additional_time_start: dateStart,
        additional_time_end: dateEnd,
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l
      const response = await httpClientUpdate.delete(`/dispatchers/additional-times/${$router.router.getState().params.additionalTimeId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.additionalTime', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      let start = moment.isMoment(newData.additional_time_start) ? moment(newData.additional_time_start).format('MM/DD/YYYY hh:mma') : newData.additional_time_start
      let end = moment.isMoment(newData.additional_time_end) ? moment(newData.additional_time_end).format('MM/DD/YYYY hh:mma') : newData.additional_time_end

      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l
      const response = await httpClientUpdate.put(`/dispatchers/additional-times/${$router.router.getState().params.additionalTimeId}`, {
        account_id: activeAccountId,
        additional_time_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, start as string),
        additional_time_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, end as string),
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/dispatchers/additional-times/88888022tg9ceodl6z?account_id=88888
      const { data: { data: responseData, success, error } } = await httpClientUpdate.get('/dispatchers/additional-times/' + $router.router.getState().params.additionalTimeId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        const dateStart = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, responseData.dispatcher_additional_time.additional_time_start as string)
        const dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, responseData.dispatcher_additional_time.additional_time_end as string)

        setReportData(responseData)
        setNewData({
          ...responseData.dispatcher_additional_time,
          additional_time_start: dateStart,
          additional_time_end: dateEnd,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  return (
    <>
      {
        reportData &&
        <div className="CallCenter_CallGroupsPage_Item entity-edit">

          {/* Top navigation */}
          <div className="top-nav">
            <BaseLink
              router={$router.router}
              routeName="call_center.phoneNumbers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
            >
              <Icon icon="phone-1" />
              <span>Phone Numbers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.dispatchers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
            >
              <Icon icon="user-1" />
              <span>Dispatchers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.additionalTime"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="time-22" />
              <span>Additional Time</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.absence"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.absence" })}
            >
              <Icon icon="time-16" />
              <span>Absence</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.groups" })}
            >
              <Icon icon="user-29" />
              <span>Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callFlows"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
            >
              <Icon icon="share-7" />
              <span>Call Flows</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.caller_groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
            >
              <Icon icon="user-29" />
              <span>Caller Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.temporaryClosed"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="close-664" />
              <span>Temporary Closed</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callCenters"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
            >
              <Icon icon="delivery-8" />
              <span>Call Centers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.audioLibrary"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="audio-15" />
              <span>Audio Library</span>
            </BaseLink>
          </div>

          <div className="wrapper flex-container sb">
            <h1>Dispatcher Additional Time</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.additionalTime',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  reportData?.permissions.additional_time_dispatcher_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Dispatcher:</span>

                  <input
                    type="text"
                    defaultValue={newData.dispatcher_nickname ? `${newData.dispatcher_nickname} (${newData.dispatcher_code})` : newData.dispatcher_nickname}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Start:</span>
                  <Datetime
                    dateFormat="MM/DD/YYYY"
                    timeFormat="hh:mma"
                    inputProps={{ disabled: !editing }}
                    value={newData.additional_time_start as Date}
                    onChange={(value) => setNewData({ ...newData, additional_time_start: value })}
                  />
                </div>

                <div className="field">
                  <span>End:</span>

                  <Datetime
                    dateFormat="MM/DD/YYYY"
                    timeFormat="hh:mma"
                    inputProps={{ disabled: !editing }}
                    value={newData.additional_time_end as Date}
                    onChange={(value) => setNewData({ ...newData, additional_time_end: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.dispatcher_additional_time.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    defaultValue={reportData?.dispatcher_additional_time.created_by}
                    disabled={true}
                  />
                </div>
              </div>


              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.dispatcher_additional_time.updated_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.dispatcher_additional_time.updated_by}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  reportData.permissions.additional_time_dispatcher_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Additional time</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {/* Item share popup */}
          {
            reportData.permissions.additional_time_dispatcher_delete &&
              deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Additional time
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the additional time it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button className="_bordered _green" onClick={() => setDeleting(false)}>
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete || isSaving}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
        </div>
      }
    </>
  )
}
