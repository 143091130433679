import { useState, useRef, useCallback, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api'

import CurrentLocationMarker from './CurrentLocationMarker'

interface CustomerMapProps {
  center: PositionProps | null
  directionResponse: google.maps.DirectionsResult | null,
  currentLocation: PositionProps | null
  setLoadingGoogle: (value: boolean) => void
  mode: string
  startDriving: string
}

const containerStyle = {
  width: '100%',
  height: '85%'
};

interface PositionProps {
  lat: number,
  lng: number
}

const defaultOptions = {
  panControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
  clickableControl: false,
  keyboardShortcuts: false,
  scrollweel: false,
  disabledDoubleClickZoom: false,
  fullScreenControl: false
}

const API_KEY = process.env.REACT_APP_API_KEY
const libraries: "places"[] = ["places"]

function CustomerMap({
  center,
  directionResponse,
  currentLocation,
  setLoadingGoogle,
  mode,
  startDriving
}: CustomerMapProps) {
  const mapRef = useRef(null)
  const [zoom, setZoom] = useState(15)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY as string,
    libraries: libraries as "places"[]
  })

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map
  }, [])

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null
  }, [])

  useEffect(() => {
    if (isLoaded) {
      setLoadingGoogle(true)
    }
  }, [isLoaded, setLoadingGoogle])

  useEffect(() => {
    if (startDriving === '-1') {
      if (mapRef.current && currentLocation) {
        (mapRef.current as google.maps.Map).panTo(currentLocation)
        setZoom(18)
      }
    }
  }, [currentLocation, mapRef, mode, startDriving])

  return (
    <>
      {
        isLoaded && center ?
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            tilt={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={defaultOptions}
          >
            {
              center &&
              <Marker position={center} />
            }
            {
              directionResponse && currentLocation &&
              <CurrentLocationMarker position={directionResponse ? { lat: directionResponse?.routes[0].legs[0].start_location.lat(), lng: directionResponse?.routes[0].legs[0].start_location.lng() } : currentLocation} />
            }
            {
              directionResponse && <DirectionsRenderer
                directions={directionResponse}
                options={{
                  preserveViewport: startDriving === '-1' ? true : false,
                  suppressMarkers: true,
                }}
              />
            }
          </GoogleMap> :
          <p
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >Loading...</p>
      }
    </>
  )
}

export default CustomerMap