import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../../components/Icon"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"

import { dateToInfoBlock, formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

import { CallCenter_CallFlow, CallCenter_CallGroup, CallCenter_Dispatcher } from "../../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallGroupData
}

interface CallGroupData {

  permissions: {
    call_flow_show: boolean
    call_group_add: boolean
    call_group_delete: boolean
    call_group_edit: boolean
    call_group_report_show: boolean
    call_group_show: boolean
    dispatcher_show: boolean
  },

  call_group: CallCenter_CallGroup,
  dispatchers: CallCenter_Dispatcher[],
  call_flows: CallCenter_CallFlow[],

  edit: {

    dispatchers: CallCenter_Dispatcher[]
  }
}

const emptyDispatcher: CallCenter_Dispatcher = {
  dispatcher_id: '',
  name: '',
  user: '',
  user_id: '',
  phone: '',
  is_phone: false,
  is_softphone: false,
  active: 'Active',
  call_groups: '',
  "created_at": "05/04/2021 09:32am",
  "created_by": "Alex",
  "updated_at": "05/04/2021 10:34am",
  "updated_by": "Alex",
  dispatcher_code: 0,
  mentor: '',
  mentor_id: '',
}

function CallCenter_CallGroupsPage_Item() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [editing, setEditing] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [callGroupData, setCallGroupData] = useState<CallGroupData | null>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dispatchers, setDispatchers] = useState<CallCenter_Dispatcher[] | null>(null)
  const [availableDispatchers, setAvailableDispatchers] = useState<CallCenter_Dispatcher[]>([])
  const [selectedDispatcher, setSelectedDispatcher] = useState<CallCenter_Dispatcher>(emptyDispatcher)

  const [newCallGroupData, setNewCallGroupData] = useState({
    name: '',
    dispatchers: [] as CallCenter_Dispatcher[]
  })

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/callcenter/callgroups/${$router.router.getState().params.groupsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.groups', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.put(`/callcenter/callgroups/${$router.router.getState().params.groupsId}`, qs.stringify({
        account_id: activeAccountId,
        name: newCallGroupData.name,
        dispatchers: JSON.stringify(newCallGroupData.dispatchers.map(item => item.dispatcher_id))
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: callGroupData, success, error } } = (await httpClientUpdate.get('/callcenter/callgroups/' + $router.router.getState().params.groupsId, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (callGroupData.permissions.call_group_show) {
          setCallGroupData(callGroupData)

          let _availableDispatchers = []

          _availableDispatchers = callGroupData.edit.dispatchers.filter((item) => {
            return callGroupData.dispatchers.findIndex((dispatcher) => {
              return dispatcher.dispatcher_id === item.dispatcher_id
            }) === -1
          })

          setDispatchers(callGroupData.edit.dispatchers)
          setAvailableDispatchers([emptyDispatcher].concat(_availableDispatchers))
          setSelectedDispatcher(emptyDispatcher)

          setNewCallGroupData({
            name: callGroupData.call_group.name,

            dispatchers: callGroupData.dispatchers
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load callGroup data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newCallGroupData
  ])

  // Add dispatcher function
  function addDispatcher() {

    if (!newCallGroupData.dispatchers || !newCallGroupData) return

    const newDispatchersList = [
      ...newCallGroupData.dispatchers,
      selectedDispatcher
    ]

    setNewCallGroupData({
      ...newCallGroupData,
      dispatchers: newDispatchersList
    })

    let _availableDispatchers = [...availableDispatchers]

    _availableDispatchers = _availableDispatchers.filter(($dispatcher) => {

      return newDispatchersList.findIndex((dispatcher) => {
        return dispatcher.name === $dispatcher.name
      }) === -1
    })

    setAvailableDispatchers([
      ..._availableDispatchers
    ])

    setSelectedDispatcher(emptyDispatcher)

    setReadyToSave(true)
  }

  // Remove dispatcher function
  function removeDispatcher(i: number) {

    let _callGroupDispatchers = [...newCallGroupData.dispatchers]

    let _dispatcher = _callGroupDispatchers[i]

    _callGroupDispatchers.splice(i, 1)

    setNewCallGroupData({
      ...newCallGroupData,
      dispatchers: _callGroupDispatchers
    })

    setAvailableDispatchers([
      ...availableDispatchers,
      _dispatcher
    ])

    setReadyToSave(true)
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {callGroupData ? (
      <div className="CallCenter_CallGroupsPage_Item entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>Call Group</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.groups',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                callGroupData?.permissions.call_group_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  value={newCallGroupData.name}
                  disabled={!editing}
                  onChange={({ target: { value } }) => {
                    setNewCallGroupData({ ...newCallGroupData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Dispatchers</div>

            <div className="legend-action-wrapper">
              <label>Dispatcher:</label>
              <div className="input-wrapper">
                <Select
                  disabled={!editing}
                  options={availableDispatchers.map((dispatcher) => ({
                    span: dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name,
                    value: dispatcher.name
                  }))}
                  selectedOption={selectedDispatcher.name}
                  onChange={(value) => setSelectedDispatcher(availableDispatchers.find((_dispatcher) => _dispatcher.name === value) as CallCenter_Dispatcher)} />
                <button
                  disabled={!selectedDispatcher.name}
                  className="_green"
                  onClick={() => addDispatcher()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>User</th>
              <th>Phone</th>
              <th>Phone</th>
              <th>Softphone</th>
              <th>Active</th>
              <th></th>
            </tr>
            {(newCallGroupData.dispatchers as CallCenter_Dispatcher[]).map((dispatcher, i) => (
              <tr
                key={i}
                style={{ cursor: callGroupData.permissions.dispatcher_show && dispatcher.dispatcher_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  callGroupData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  $router.router.navigate('call_center.dispatchers.item', {
                    companyId: activeAccountId, dispatchersId: dispatcher.dispatcher_id
                  }, { reload: true })}
                onContextMenu={(event) =>
                  callGroupData.permissions.dispatcher_show &&
                  dispatcher.dispatcher_id &&
                  handleChangeMenu(event, dispatcher.dispatcher_id, 'dispatchers', i)}
              >
                <td>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</td>
                <td>{dispatcher.user}</td>
                <td>{formatPhoneNumber(dispatcher.phone)}</td>
                <td>
                  <Checkbox contents={''} value={dispatcher.is_phone} />
                </td>
                <td>
                  <Checkbox contents={''} value={dispatcher.is_softphone} />
                </td>
                <td className={classNames({
                  'red-text': dispatcher.active,
                  'green-text': dispatcher.active,
                })}>{dispatcher.active ? 'On' : 'Off'}</td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    disabled={!editing}
                    onClick={(event) => {
                      event.stopPropagation()
                      removeDispatcher(i)
                    }}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                  {
                    callGroupData.permissions.dispatcher_show &&
                    showContext === `dispatchers_${dispatcher.dispatcher_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.dispatchers.item', {
                            companyId: activeAccountId, dispatchersId: dispatcher.dispatcher_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/dispatchers/${dispatcher.dispatcher_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {newCallGroupData.dispatchers.map((dispatcher, i: number) => (
                <div
                  className="item"
                  key={i}
                  style={{ cursor: callGroupData.permissions.dispatcher_show && dispatcher.dispatcher_id ? 'pointer' : 'inherit' }}
                  onClick={() =>
                    callGroupData.permissions.dispatcher_show &&
                    dispatcher.dispatcher_id &&
                    $router.router.navigate('call_center.dispatchers.item', {
                      companyId: activeAccountId, dispatchersId: dispatcher.dispatcher_id
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><b>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</b></div>
                      <div>
                        {dispatcher.user}
                      </div>
                    </div>

                    <div className="__right small">
                      <div className={classNames({
                        'red-text': dispatcher.active,
                        'green-text': dispatcher.active,
                      })}>
                        {dispatcher.active ? 'On' : 'Off'}
                      </div>
                      <div>
                        {formatPhoneNumber(dispatcher.phone)}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">

                    <div className="__left">
                      <div className="flex-container">
                        <span className="gray">Phone:</span>
                        <span> <Checkbox contents='' value={dispatcher.is_phone} /></span>
                      </div>
                      <div className="flex-container">
                        <span className="gray">Softphone:</span>
                        <span> <Checkbox contents='' value={dispatcher.is_softphone} /></span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <button
                          className="_zeroed _iconed _red"
                          disabled={!editing}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeDispatcher(i)
                          }}
                        >
                          <Icon icon="x-mark-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callGroupData.call_group.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={callGroupData.call_group.created_by} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callGroupData.call_group.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={callGroupData.call_group.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Call Flows</div>

          <table className="table">
            <tr>
              <th>Name</th>
            </tr>

            {callGroupData.call_flows.map((callFlow, i) => (
              <tr
                key={i}
                style={{ cursor: callGroupData.permissions.call_flow_show && callFlow.call_flow_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  callGroupData.permissions.call_flow_show &&
                  callFlow.call_flow_id &&
                  $router.router.navigate('call_center.callFlows.item', {
                    companyId: activeAccountId, callFlowsId: callFlow.call_flow_id
                  }, { reload: true })}
                onContextMenu={(event) =>
                  callGroupData.permissions.call_flow_show &&
                  callFlow.call_flow_id &&
                  handleChangeMenu(event, callFlow.call_flow_id, 'callFlows', i)}
              >
                <td>
                  {callFlow.name}
                  {
                    callGroupData.permissions.call_flow_show &&
                    showContext === `callFlows_${callFlow.call_flow_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.callFlows.item', {
                            companyId: activeAccountId, callFlowsId: callFlow.call_flow_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/callFlows/${callFlow.call_flow_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                callGroupData.permissions.call_group_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Call Group</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => $router.router.navigate('call_center.groups', {
                  companyId: activeAccountId
                }, { reload: true })}
              >
                Cancel
              </button>

              <button
                className="_bordered _green"
                disabled={!readyToSave || !!errorFields.length || isSaving}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item deleting popup */}
        {callGroupData.permissions.call_group_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete CallGroup
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the call group it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _red"
                  disabled={!readyToDelete || isSaving}
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default CallCenter_CallGroupsPage_Item
