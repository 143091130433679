import { useState, useEffect } from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import { useRoute } from "react-router5"
import qs from 'qs'

import Icon from '../../components/Icon'
import Select from '../../components/Select'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import "../../styles/pages/common/entity-edit.sass"

interface Appointment {
  appointment_start: string
  appointment_finish: string
  status_id: number
  date: string
  type: string
}

interface AppointmentAddProps {
  switchPage: (name: string) => void
  edit: {
    appointment_status: string
    appointment_status_id: number
  }[]
  client_id: string
  jobId: string
}

export default function AppointmentAdd({ switchPage, edit, client_id, jobId }: AppointmentAddProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newData, setNewData] = useState<Partial<Appointment>>({
    date: moment().format('YYYY-MM-DD'),
    appointment_start: '10:00 AM',
    appointment_finish: '11:00 AM',
  })

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/appointments`, qs.stringify({
      account_id: activeAccountId,
      appointment_start: `${newData.date} ${moment(newData.appointment_start, "hh:mm A").format("HH:mm:ss")}`,
      appointment_finish: `${newData.date} ${moment(newData.appointment_finish, "hh:mm A").format("HH:mm:ss")}`,
      appointment_id: $router.router.getState().params.jobId,
      appointment_status_id: newData.status_id,
      contact_id: client_id,
      job_id: jobId,
      service_resource_id: '88888010ty7gjfyh90',
      type: newData.type
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  return (
    <div className="EstimatePage_Edit entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Add new Appointment</h1>

        <div style={{ display: 'flex' }} >
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
            className="_wa"
            onClick={() => switchPage('main')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>
      </div>

      <div className="fieldset">
        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Status:</span>

              {
                edit &&
                <Select
                  options={edit.map((option) => ({
                    span: option.appointment_status,
                    value: option.appointment_status_id
                  }))}
                  selectedOption={newData.status_id as number}
                  onChange={(value) => setNewData({ ...newData, status_id: value as number })}
                />
              }
            </div>

            <div className="field">
              <span>Type:</span>

              <Select
                options={[{ value: 'SC', span: 'Service Call' }, { value: 'FU', span: 'Follow Up' }, { value: 'RC', span: 'Recall' }].map((type) => ({
                  span: type.span,
                  value: type.value
                }))}
                selectedOption={newData.type as string}
                onChange={(value) => setNewData({ ...newData, type: value as string })}
              />
            </div>

            <div className='field'>
              <span>Date:</span>

              <Datetime
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                value={newData.date}
                onChange={(value) => setNewData({ ...newData, date: moment(value).format("YYYY-MM-DD") })} />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>From:</span>

              <Datetime
                dateFormat={false}
                timeFormat="hh:mm A"
                value={newData.appointment_start}
                onChange={(value) => setNewData({ ...newData, appointment_start: moment(value).format("hh:mm A") })}
              />
            </div>

            <div className="field">
              <span>To:</span>

              <Datetime
                dateFormat={false}
                timeFormat="hh:mm A"
                value={newData.appointment_finish}
                onChange={(value) => setNewData({ ...newData, appointment_finish: moment(value).format("hh:mm A") })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => switchPage('main')}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
