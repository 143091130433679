import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import Job from "../../models/Job"
import Appointment from "../../models/Appointment"
import Estimate from "../../models/Estimate"
import Invoice from "../../models/Invoice"
import Payment from "../../models/Payments"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import Unit from "../../models/Unit"

import { httpClientUpdate, nErrorUpdate, useOuterClick, formatPhoneNumber, getSchuduleTime, dateToInfoBlock } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"

interface Client {
  appointments: number
  client_id: string
  company_name: string
  first_name: string
  jobs: number
  last_name: string
  main_phone: string
  paid: string
  recalls: number
  source: string
  total: string
  unpaid: string
  created_at: string,
  created_by: string,
  last_edited_at: string,
  last_edited_by: string,
  updated_at: string,
  updated_by: string,
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ClientData
}

interface Phone {
  name: string,
  phone: string
}

interface Address {
  address: string,
  property_type: string,
  note: string
}

interface Comment {
  created_at: string,
  comment: string,
  created_by: string
}

export interface ClientData {

  permissions: {
    client_add: boolean
    client_delete: boolean
    client_edit: boolean
    client_report_show: boolean
    client_show: boolean
    appointment_show: boolean
    estimate_show: boolean
    invoice_show: boolean
    job_show: boolean
    payment_show: boolean
  },

  client: Client[],

  phones: Phone[],
  addresses: Address[],
  comments: Comment[],
  jobs: Job[],
  appointments: Appointment[],
  estimates: Estimate[],
  invoices: Invoice[],
  payments: Payment[],
  units: Unit[],

  edit: {
    source: {
      id_source: string
      name: string
    }[]
  }
}

function ClientsPage_Item() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [clientData, setClientData] = useState<ClientData | null>(null)

  const [newClientData, setNewClientData] = useState<Partial<Client> | null>(null)

  function handleCancelEdit() {
    let source = clientData && clientData.client[0].source ? clientData.edit.source.filter(item => item.name === clientData.client[0].source)[0]?.id_source : ""

    clientData && setNewClientData({
      ...clientData.client[0],
      source
    })
    setEditing(false)
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/clients/${$router.router.getState().params.clientId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('clients', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)

      const response = await httpClientUpdate.put(`/clients/${$router.router.getState().params.clientId}`, {
        account_id: activeAccountId,
        firstname: newClientData?.first_name,
        lastname: newClientData?.last_name,
        company: newClientData?.company_name,
        sourceid: newClientData?.source ? newClientData?.source : null
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: clientData, success, error } } = await httpClientUpdate.get('/clients/' + $router.router.getState().params.clientId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (clientData.permissions.client_show) {
          let source = clientData.client[0].source ? clientData.edit.source.filter(item => item.name === clientData.client[0].source)[0]?.id_source : ""
          setClientData(clientData)
          setNewClientData({
            ...clientData.client[0],
            source
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load client data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newClientData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {clientData && newClientData ? (
      <div className="ClientsPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Client</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() => $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'clients',
                {
                  companyId: activeAccountId,
                  [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                  localInterface: $router.router.getState().params.localInterface
                }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                clientData?.permissions.client_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>First Name:</span>
                <input
                  type="text"
                  disabled={!editing}
                  value={newClientData.first_name}
                  // defaultValue={newClientData.first_name}
                  onChange={({ target: { value } }) => setNewClientData({ ...newClientData, first_name: value })}
                />
              </div>

              <div className="field">
                <span>Last Name:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={newClientData.last_name}
                  onChange={({ target: { value } }) => setNewClientData({ ...newClientData, last_name: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Company:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={newClientData.company_name}
                  onChange={({ target: { value } }) => setNewClientData({ ...newClientData, company_name: value })}
                />
              </div>

              <div className="field">
                <span>Source:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', id_source: '' }].concat(clientData.edit.source).map((option) => ({
                    span: option.name,
                    value: option.id_source
                  }))}
                  selectedOption={newClientData.source as string}
                  onChange={(value) => setNewClientData({ ...newClientData, source: value as string })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Financial</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Total:</span>
                <input type="text" disabled={true} defaultValue={newClientData.total} />
              </div>

              <div className="field">
                <span>Paid:</span>
                <input type="text" disabled={true} defaultValue={newClientData.paid} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Unpaid:</span>
                <input type="text" disabled={true} defaultValue={newClientData.unpaid} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Phones</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>Phone</th>
            </tr>
            {clientData.phones.map((phone, i) => (
              <tr
                key={i}
                className="permission-show"
              >
                <td>{phone.name}</td>
                <td>{formatPhoneNumber(phone.phone)}</td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {clientData.phones.map((phone, i: number) => (
                <div className="item" key={i}>

                  <div className="__top">

                    <div className="__left">
                      <div><strong>{formatPhoneNumber(phone.phone)}</strong></div>
                      <div>
                        {phone.name}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Addresses</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Address</th>
              <th>Property Type</th>
              <th>Note</th>
            </tr>
            {clientData.addresses.map((address, i) => (
              <tr
                key={i}
                className="permission-show"
              >
                <td>{address.address}</td>
                <td>{address.property_type}</td>
                <td>{address.note}</td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {clientData.addresses.map((address, i: number) => (
                <div className="item" key={i}>

                  <div className="__top">

                    <div className="__left">
                      <div><strong>{address.address}</strong></div>
                      <div>
                        {address.note}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">

                    <div className="__left">

                    </div>

                    <div className="__right">
                      <div>
                        {address.property_type}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div >

        <div className="fieldset">

          <div className="legend">Comments</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Date</th>
              <th>Comment</th>
              <th>User</th>
            </tr>
            {clientData.comments.map((comment, i) => (
              <tr
                key={i}
                className="permission-show"
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, comment.created_at)}</td>
                <td>{comment.comment}</td>
                <td>{comment.created_by}</td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {clientData.comments.map((comment, i: number) => (
                <div className="item" key={i}>

                  <div className="__top">

                    <div className="__left">
                      <div><strong>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, comment.created_at)}</strong></div>
                      <div>
                        {comment.comment}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">

                    <div className="__left">

                    </div>

                    <div className="__right">
                      <div>
                        <b>{comment.created_by}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div >

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newClientData.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={newClientData.created_by} disabled={true} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newClientData.updated_at)}
                  disabled={true}
                />
              </div>
              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={newClientData.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Units</div>
          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Unit type</th>
                <th>Unit</th>
                <th>Brand</th>
                <th>Model Number</th>
                <th>Serial number</th>
              </tr>
            </thead>

            {clientData.units.map((unit, i) => (
              <tr
                key={unit.unit_id}
                className="permission-show"
              >
                <td>{unit.unit_type}</td>
                <td>{unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}</td>
                <td>{unit.brand === 'Other' ? unit.other_brand : unit.brand}</td>
                <td>{unit.model_number}</td>
                <td>{unit.serial_number}</td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {clientData.units.map((unit, i) => (
              <tr
                key={unit.unit_id}
                className="permission-show"
              >
                <td>
                  <div>{unit.unit_type}</div>
                  <div>{unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}</div>
                </td>
                <td>
                  <div>{unit.brand === 'Other' ? unit.other_brand : unit.brand}</div>
                </td>
                <td>
                  <div>Model Number: {unit.model_number}</div>
                  <div>Serial Number: {unit.serial_number}</div>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {clientData.units.map((unit, i: number) => (
                <div className="item" key={unit.unit_id}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><b>{unit.unit_type}</b></div>
                      <div>
                        {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}
                      </div>
                      <div>
                        {unit.brand === 'Other' ? unit.other_brand : unit.brand}
                      </div>
                    </div>

                    <div className="__right">
                      <div>Model Number: {unit.model_number}</div>
                      <div>Serial Number: {unit.serial_number}</div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                    </div>

                    <div className="__right small">
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div >

        <div className="fieldset">
          <div className="legend">Jobs</div>
          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Status</th>
                <th>Type</th>
                <th>Area</th>
                <th>Source</th>
                <th>Appointments</th>
                <th>Service resource</th>
                <th>Total</th>
                <th>Paid</th>
              </tr>
            </thead>

            {clientData.jobs.map((job, i) => (
              <tr
                style={{ cursor: clientData.permissions.job_show && job.job_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={job.job_id}
                onClick={() =>
                  clientData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</td>
                <td>{job.name}</td>
                <td>
                  <span className={classNames({
                    red: job.status && job.status.toLowerCase() === 'canceled',
                    green: job.status && job.status.toLowerCase() === 'closed',
                    blue: job.status && !['canceled', 'closed'].includes(job.status.toLowerCase()),
                  })}
                  >
                    {job.status}
                  </span>
                </td>
                <td>{job.property_type}</td>
                <td>{job.area}</td>
                <td>{job.source}</td>
                <td>{job.appointments}</td>
                <td>
                  {
                    job.service_resources.map(item => item.code ? `${item.nickname} (${item.code})` : item.nickname).join(', ')
                  }
                </td>
                <td>{job.total}</td>
                <td>
                  {job.paid}
                  {
                    clientData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobId: job.job_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {clientData.jobs.map((job, i) => (
              <tr
                style={{ cursor: clientData.permissions.job_show && job.job_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={job.job_id}
                onClick={() =>
                  clientData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>
                  <div>{job.name}</div>
                  <div>Date: {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</div>
                  <div>Status:{' '}
                    <span
                      className={classNames({
                        red: job.status && job.status.toLowerCase() === 'canceled',
                        green: job.status && job.status.toLowerCase() === 'closed',
                        blue: job.status && !['canceled', 'closed'].includes(job.status.toLowerCase()),
                      })}
                    >
                      {job.status}
                    </span>
                  </div>
                </td>
                <td>
                  <div>Type: {job.property_type}</div>
                  <div>Area: {job.area}</div>
                  <div>Source: {job.source}</div>
                </td>
                <td>
                  <div>Appointments: {job.appointments}</div>
                  <div>Service Resource: {job.service_resources.map(item => item.code ? `${item.nickname} (${item.code})` : item.nickname).join(', ')}</div>
                </td>
                <td>
                  <div>Total: {job.total}</div>
                  <div>Paid: {job.paid}</div>
                  {
                    clientData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobId: job.job_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <div className="mobile-table-items">
              {clientData.jobs.map((job, i: number) => (
                <div
                  className="item"
                  key={job.job_id}
                  onClick={() =>
                    clientData.permissions.job_show &&
                    job.job_id &&
                    $router.router.navigate('jobs.item', {
                      companyId: activeAccountId,
                      jobId: job.job_id,
                      fromPage: 'clients.item',
                      nameId: 'clientId',
                      id: $router.router.getState().params.clientId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >

                  <div className="__top">
                    <div className="__left">
                      <div><b>{job.name}</b></div>
                      <div>Date {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</div>
                      <div>
                        Status:{' '}
                        <span
                          className={classNames({
                            red: job.status && job.status.toLowerCase() === 'canceled',
                            green: job.status && job.status.toLowerCase() === 'closed',
                            blue: job.status && !['canceled', 'closed'].includes(job.status.toLowerCase()),
                          })}
                        >
                          {job.status}
                        </span>
                      </div>
                      <div>
                        Type: {job.property_type}
                      </div>
                    </div>

                    <div className="__right">
                      <div>Area: {job.area}</div>
                      <div>Source: {job.source}</div>
                      <div>Appointments: {job.appointments}</div>
                      <div>Service Resource: {job.service_resources.map(item => item.code ? `${item.nickname} (${item.code})` : item.nickname).join(', ')}</div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                    </div>

                    <div className="__right">
                      <div>Total: {job.total}</div>
                      <div>Paid: {job.paid}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div >

        <div className="fieldset">
          <div className="legend">Appointments</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Property type</th>
              <th>Area</th>
              <th>Created by</th>
              <th>Schedule time</th>
              <th>Service resource</th>
            </tr>
            {clientData.appointments.map((appointment, i) => (
              <tr
                style={{ cursor: clientData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={i}
                onClick={() =>
                  clientData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</td>
                <td>{appointment.name}</td>
                <td>
                  <span className={classNames({
                    red: ['Recall', 'RC'].includes(appointment.type),
                    green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                    blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                  })}
                  >
                    {appointment.type}
                  </span>
                </td>
                <td>
                  <span
                    className={classNames({
                      red: appointment.status.toLowerCase() === 'canceled',
                      green: appointment.status.toLowerCase() === 'closed',
                      blue: !['canceled', 'closed'].includes(appointment.status.toLowerCase()),
                    })}
                  >
                    {appointment.status}
                  </span>
                </td>
                <td>{appointment.property_type}</td>
                <td>{appointment.area}</td>
                <td>{appointment.created_by}</td>
                <td>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</td>
                <td>
                  {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}
                  {
                    clientData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {clientData.appointments.map((appointment, i) => (
              <tr
                style={{ cursor: clientData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={i}
                onClick={() =>
                  clientData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>
                  <div>{appointment.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</div>
                </td>
                <td>
                  <div>{appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</div>
                  <div>{appointment.created_by}</div>
                </td>
                <td>
                  <div>{appointment.area}</div>
                  <div>
                    <span className={classNames({
                      red: ['Recall', 'RC'].includes(appointment.type),
                      green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                      blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                    })}
                    >
                      {appointment.type}
                    </span>
                  </div>
                </td>
                <td>
                  <div>{appointment.property_type}</div>
                  <div>
                    <span
                      className={classNames({
                        red: appointment.status.toLowerCase() === 'canceled',
                        green: appointment.status.toLowerCase() === 'closed',
                        blue: !['canceled', 'closed'].includes(appointment.status.toLowerCase()),
                      })}
                    >
                      {appointment.status}
                    </span>
                  </div>
                </td>
                <td>
                  <div>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</div>
                  <div>&nbsp;</div>
                  {
                    clientData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">
              {clientData.appointments.map((appointment, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    clientData.permissions.appointment_show &&
                    appointment.appointment_id &&
                    $router.router.navigate('appointments.item', {
                      companyId: activeAccountId,
                      appointmentId: appointment.appointment_id,
                      fromPage: 'clients.item',
                      nameId: 'clientId',
                      id: $router.router.getState().params.clientId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div className="flex-container">
                        <strong>{appointment.name}</strong>
                      </div>
                      <div>
                        {getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        <span className={classNames({
                          red: ['Recall', 'RC'].includes(appointment.type),
                          green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                          blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                        })}
                        >
                          {appointment.type}
                        </span>
                      </div>
                      <div>
                        {appointment.property_type}
                      </div>
                      <div>
                        <div className="fw500">
                          <span
                            className={classNames({
                              red: appointment.status.toLowerCase() === 'canceled',
                              green: appointment.status.toLowerCase() === 'closed',
                              blue: !['canceled', 'closed'].includes(appointment.status.toLowerCase()),
                            })}
                          >
                            {appointment.status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {appointment.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}
                      </div>
                      <div>
                        Created by {appointment.created_by}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div >

        <div className="fieldset">
          <div className="legend">Estimates</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th><span>Date</span></th>
              <th><span>Name</span></th>
              <th><span>Status</span></th>
              <th><span>Service Resource</span></th>
              <th><span>Area</span></th>
              <th><span>Total</span></th>
              <th><span>Comment</span></th>
            </tr>
            {clientData.estimates.map((estimate, i) => (
              <tr
                style={{ cursor: clientData.permissions.estimate_show && estimate.estimate_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={i}
                onClick={() =>
                  clientData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  $router.router.navigate('estimates.item', {
                    companyId: activeAccountId,
                    estimatesId: estimate.estimate_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  handleChangeMenu(event, estimate.estimate_id, 'estimates', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</td>
                <td>{estimate.name}</td>
                <td>
                  <span
                    className={classNames({
                      red: estimate.status.toLowerCase() === 'canceled',
                      green: estimate.status.toLowerCase() === 'closed',
                      blue: !['canceled', 'closed'].includes(estimate.status.toLowerCase()),
                    })}
                  >
                    {estimate.status}
                  </span>
                </td>
                <td>{estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</td>
                <td>{estimate.area}</td>
                <td>{estimate.total}</td>
                <td>
                  {estimate.comment}
                  {
                    clientData.permissions.estimate_show &&
                    showContext === `estimates_${estimate.estimate_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('estimates.item', {
                            companyId: activeAccountId,
                            estimatesId: estimate.estimate_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/estimates/${estimate.estimate_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">
              {clientData.estimates.map((estimate, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    clientData.permissions.estimate_show &&
                    estimate.estimate_id &&
                    $router.router.navigate('estimates.item', {
                      companyId: activeAccountId,
                      estimatesId: estimate.estimate_id,
                      fromPage: 'clients.item',
                      nameId: 'clientId',
                      id: $router.router.getState().params.clientId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>
                        <strong>{estimate.name}</strong>
                      </div>
                      <div>
                        <strong>
                          <span
                            className={classNames({
                              red: estimate.status.toLowerCase() === 'canceled',
                              green: estimate.status.toLowerCase() === 'closed',
                              blue: !['canceled', 'closed'].includes(estimate.status.toLowerCase()),
                            })}
                          >
                            {estimate.status}
                          </span>
                        </strong>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{estimate.total}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {estimate.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</b>
                      </div>
                    </div>
                  </div>

                  <div className="text">
                    {estimate.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div >

        <div className="fieldset">
          <div className="legend">Invoices</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th><span>Date</span></th>
              <th><span>Name</span></th>
              <th><span>Status</span></th>
              <th><span>Service Resource</span></th>
              <th><span>Area</span></th>
              <th><span>Total</span></th>
              <th><span>Paid</span></th>
              <th><span>Unpaid</span></th>
            </tr>

            {clientData.invoices.map((invoice, i) => (
              <tr
                key={i}
                style={{ cursor: clientData.permissions.invoice_show && invoice.invoice_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  clientData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  $router.router.navigate('invoices.item', {
                    companyId: activeAccountId,
                    invoicesId: invoice.invoice_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  handleChangeMenu(event, invoice.invoice_id, 'invoices', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}</td>
                <td>{invoice.name}</td>
                <td>
                  <span
                    className={classNames({
                      red: (invoice.status ? invoice.status : '').toLowerCase() === 'canceled',
                      green: (invoice.status ? invoice.status : '').toLowerCase() === 'closed',
                      blue: !['canceled', 'closed'].includes((invoice.status ? invoice.status : '').toLowerCase()),
                    })}
                  >
                    {invoice.status}
                  </span>
                </td>
                <td>{invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</td>
                <td>{invoice.area}</td>
                <td>{invoice.total}</td>
                <td>{invoice.paid}</td>
                <td>
                  {invoice.unpaid}
                  {
                    clientData.permissions.invoice_show &&
                    showContext === `invoices_${invoice.invoice_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('invoices.item', {
                            companyId: activeAccountId,
                            invoicesId: invoice.invoice_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/invoices/${invoice.invoice_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {clientData.invoices.map((invoice, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    clientData.permissions.invoice_show &&
                    invoice.invoice_id &&
                    $router.router.navigate('invoices.item', {
                      companyId: activeAccountId,
                      invoicesId: invoice.invoice_id,
                      fromPage: 'clients.item',
                      nameId: 'clientId',
                      id: $router.router.getState().params.clientId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><b>{invoice.name}</b></div>
                      <div>
                        <span
                          className={classNames({
                            red: (invoice.status ? invoice.status : '').toLowerCase() === 'canceled',
                            green: (invoice.status ? invoice.status : '').toLowerCase() === 'closed',
                            blue: !['canceled', 'closed'].includes((invoice.status ? invoice.status : '').toLowerCase()),
                          })}
                        >
                          {invoice.status}
                        </span>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{invoice.total}</b>
                      </div>
                      <div>
                        Paid: <b>{invoice.paid}</b>
                      </div>
                      <div>
                        Unpaid: <b>{invoice.unpaid}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {invoice.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resource:</span>
                        <span> {invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div >

        <div className="fieldset">
          <div className="legend">Payments</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th><span>Date</span></th>
              <th><span>Type</span></th>
              <th><span>Number</span></th>
              <th><span>Service</span></th>
              <th><span>Status</span></th>
              <th><span>Net</span></th>
              <th><span>Total</span></th>
              <th><span>Fee</span></th>
            </tr>

            {clientData.payments.map((payment, i) => (
              <tr
                style={{ cursor: clientData.permissions.payment_show && payment.payment_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={i}
                onClick={() =>
                  clientData.permissions.payment_show &&
                  payment.payment_id &&
                  $router.router.navigate('payments.item', {
                    companyId: activeAccountId,
                    paymentsId: payment.payment_id,
                    fromPage: 'clients.item',
                    nameId: 'clientId',
                    id: $router.router.getState().params.clientId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  clientData.permissions.payment_show &&
                  payment.payment_id &&
                  handleChangeMenu(event, payment.payment_id, 'payments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</td>
                <td>{payment.type}</td>
                <td>{payment.number}</td>
                <td>{payment.service}</td>
                <td>
                  <span
                    className={classNames({
                      red: payment.status.toLowerCase() === 'canceled',
                      green: payment.status.toLowerCase() === 'closed',
                      blue: !['canceled', 'closed'].includes(payment.status.toLowerCase()),
                    })}
                  >
                    {payment.status}
                  </span>
                </td>
                <td>{payment.net}</td>
                <td>{payment.total}</td>
                <td>
                  {payment.fee}
                  {
                    clientData.permissions.payment_show &&
                    showContext === `payments_${payment.payment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('payments.item', {
                            companyId: activeAccountId,
                            paymentsId: payment.payment_id,
                            fromPage: 'clients.item',
                            nameId: 'clientId',
                            id: $router.router.getState().params.clientId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/payments/${payment.payment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {clientData.payments.map((payment, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    clientData.permissions.payment_show &&
                    payment.payment_id &&
                    $router.router.navigate('payments.item', {
                      companyId: activeAccountId,
                      paymentsId: payment.payment_id,
                      fromPage: 'clients.item',
                      nameId: 'clientId',
                      id: $router.router.getState().params.clientId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><strong>{payment.number}</strong></div>
                      <div>{payment.service}</div>
                      <div>
                        <span
                          className={classNames({
                            red: payment.status.toLowerCase() === 'canceled',
                            green: payment.status.toLowerCase() === 'closed',
                            blue: !['canceled', 'closed'].includes(payment.status.toLowerCase()),
                          })}
                        >
                          <b>
                            {payment.status}
                          </b>
                        </span>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Net: <b>{payment.net}</b>
                      </div>
                      <div>
                        Total: <b>{payment.total}</b>
                      </div>
                      <div>
                        Fee: <b>{payment.fee}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div >

        {
          editing ? (
            <div className="wrapper flex-container sb editing-buttons" >
              <div>
                {
                  clientData.permissions.client_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Client</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null
        }

        {/* Item delete popup */}
        {
          deleting && clientData.permissions.client_delete ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Client
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox
                    contents="I understand that after deleting the client it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null
        }
      </div >
    ) : null}
  </>)
}

export default ClientsPage_Item
