import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment";
import classNames from "classnames";
import { useCookies } from "react-cookie";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls";
import Select from "../../components/Select";
import Icon from "../../components/Icon";
import ReportTableField from "../../components/reports/TableField";

import { useAppSelector } from "../../store/hooks"
import { getDateRangeByPreset } from "../../funcs/reports"
import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { DateRangePreset } from "../../models/Misc"

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'has_attachments'

export interface ListMailsReportProps {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    can_read_mailboxes: CanReadProps[]
    can_write_mailboxes: CanReadProps[]
  }
}

export interface CanReadProps {
  email: string
  mailbox_id: string
  folders: FoldersProps[]
}

interface FoldersProps {
  mailbox_folder_id: string
  name: string
}

interface GetReportProps {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    mails: MailsProps[]
    interface: {
      filter_words: {
        has_attachments: string[],
      },
      max_date: Date
      max_pages: number
      min_date: Date
      rows_all: number
      rows_end: number
      rows_start: number
    }
  }
}

interface InterfaceProps {
  filter_words: {
    has_attachments: string[],
  },
  max_date: Date
  max_pages: number
  min_date: Date
  rows_all: number
  rows_end: number
  rows_start: number
}

interface MailsProps {
  answered: boolean
  archive: boolean
  body_html: string
  body_text: string
  date: string
  draft: boolean
  flagged: boolean
  from_mail: string
  attachments: []
  has_attachments: boolean
  mail_id: string
  mailbox_id: string
  message_id: string
  reply_to: string
  seen: boolean
  spam: boolean
  subject: string
  to_mail: string
}

export default function Reports_Page_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [selectedSearch, setSelectedSearch] = useState('')
  const [selectedFolder, setSelectedFolder] = useState('')

  const [reportData, setReportData] = useState<InterfaceProps | null>(null)
  const [mails, setMails] = useState<MailsProps[]>([])

  const [mailsOptions, setMailsOptions] = useState<CanReadProps[]>([])
  const [writeOptions, setWriteOptions] = useState<CanReadProps[]>([])
  const [foldersOptions, setFoldersOptions] = useState<FoldersProps[]>([])

  const [isAddMessage, setIsAddMessage] = useState(false)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "last_7_days" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.mail?.filter_field ? {
      has_attachments: [] as string[],
      ...cookiesSetting.mail.filter_field,
    } : {
      has_attachments: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.mail?.sort_field ? cookiesSetting.mail.sort_field : 'date',
      direction: cookiesSetting && cookiesSetting?.mail?.sort_type ? cookiesSetting.mail.sort_type : 'down' as 'down' | 'up'
    },
    selectedSearchFilters: { type: [] as string[], direction: [] as string[] },
    sortFields: [{
      span: 'Date',
      value: 'date',
    }, {
      span: 'From mail',
      value: 'from_mail',
    }, {
      span: 'To mail',
      value: 'to_mail',
    }, {
      span: 'Subject',
      value: 'subject',
    }, {
      span: 'Attachments',
      value: 'has_attachments',
    }]
  })

  async function loadListMails() {
    try {

      // https://2022back4.artemiudintsev.com/api/mailboxes/check?account_id=88888
      const { data: { data: listMailsReport, success } } = (await httpClientUpdate.get('/mailboxes/check', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId,
        }
      })) as { data: ListMailsReportProps }

      if (success) {
        setMailsOptions(listMailsReport.can_read_mailboxes)
        setWriteOptions(listMailsReport.can_write_mailboxes)
        if (listMailsReport.can_write_mailboxes.length) {
          setIsAddMessage(true)
        } else {
          setIsAddMessage(false)
        }
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
      }, 100)
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function getReport() {
    try {
      let activeFilters: any = { ...localInterface.filter_words }
      Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

      setCookie('settings', { ...cookiesSetting, mail: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.mail?.limit_rows ? cookiesSetting?.mail.limit_rows : reportsMaxRows } })

      // https://2022back4.artemiudintsev.com/api/mailboxes/folders/{mailbox_folder_id}/mails
      const { data: { data: report, success, error } } = (await httpClientUpdate.post(`/mailboxes/folders/${selectedFolder}/mails`, {
        account_id: activeAccountId,
        is_new: 1,
        limit_rows: cookiesSetting && cookiesSetting?.mail?.limit_rows ? JSON.stringify(cookiesSetting?.mail?.limit_rows) : JSON.stringify(reportsMaxRows),
        page: JSON.stringify(localInterface.page),
        date_start: localInterface.min_date,
        date_end: localInterface.max_date,
        sort_field: localInterface.sort.field,
        sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        filters: activeFilters,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: GetReportProps }
      if (success) {
        setMails(report.mails)
        setReportData({
          ...report.interface,
          min_date: moment(report.interface.min_date).startOf('day').toDate(),
          max_date: moment(report.interface.max_date).endOf('day').toDate(),
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
      }, 100)
    } catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, mail: {} })
      // let createdError = nErrorUpdate(error)
      // $router.router.navigate(`${createdError.content.code}`, {
      //   reload: true
      // })
    }
  }

  useEffect(() => {
    loadListMails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMails([])
    if (selectedSearch) {
      mailsOptions.forEach(item => {
        if (item.mailbox_id === selectedSearch) {
          setFoldersOptions(item.folders)

          if (item.folders.length === 0) {
            setSelectedFolder('')
          }

          item.folders.forEach(folder => {
            if (folder.name === 'INBOX') {
              setSelectedFolder(folder.mailbox_folder_id)
            }
          })
        }
      })
    } else {
      setFoldersOptions([])
      setSelectedFolder('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch, $updater, mailsOptions])

  useEffect(() => {
    if (selectedFolder) {
      getReport()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder, $updater])

  useEffect(() => {
    if (!!updated) {
      setLocalInterface({
        ...localInterface,
        search: '',
        dateRangeCalendarShown: false,
        dateRangeType: "schedule" as "created" | "schedule",
        dateRangePreset: "last_7_days" as DateRangePreset,
        ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
        page: 1,
        filter_words: cookiesSetting && cookiesSetting?.mail?.filter_field ? {
          has_attachments: [] as string[],
          ...cookiesSetting.mail.filter_field,
        } : {
          has_attachments: [] as string[],
        },
        selectedSearchFilters: { type: [] as string[], direction: [] as string[] },
        sort: {
          field: cookiesSetting && cookiesSetting?.mail?.sort_field ? cookiesSetting.mail.sort_field : 'date',
          direction: cookiesSetting && cookiesSetting?.mail?.sort_type ? cookiesSetting.mail.sort_type : 'down' as 'down' | 'up'
        },
      })
      $setUpdater(updated)
      setSelectedFolder('')
      setSelectedSearch('')

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {

      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
      $setUpdater(Math.random())
    }

    if ($router.router.getState().params.selectedSearch) {

      setSelectedSearch($router.router.getState().params.selectedSearch)
    }

    if ($router.router.getState().params.selectedFolder) {

      setSelectedFolder($router.router.getState().params.selectedFolder)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Watch for deprecated
  useEffect(() => {
    if (!mailsOptions.length) return

    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  // Watch date range preset
  useEffect(() => {

    if (!mailsOptions.length) return

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.min_date, reportData?.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {
        <div className="ReportsPage_List">
          { /* Page header */}
          <div className="page-header">
            <h1>Mail</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            selectSearch={true}
            is_mail={true}
            selectedSearch={selectedSearch}
            setSelectedSearch={(value) => {
              setSelectedSearch(value)
              setLocalInterface({ ...localInterface, page: 1 })
            }}
            selectOptions={mailsOptions.map(item => {
              return {
                span: item.email,
                value: item.mailbox_id
              }
            })}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}

            childrenAfterSearch={
              <div className="search-select">
                {
                  selectedSearch &&
                  <Select
                    disabled={!selectedSearch}
                    options={foldersOptions.map((folder) => ({
                      span: folder.name,
                      value: folder.mailbox_folder_id
                    }))}
                    selectedOption={selectedFolder as string}
                    onChange={(value) => {
                      setSelectedFolder(value as string)
                      setLocalInterface({ ...localInterface, page: 1 })
                    }}
                  />
                }
              </div>
            }
          />

          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.mail?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, mail: { ...cookiesSetting.mail, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData ? reportData.rows_all : 0,
              start: reportData ? reportData.rows_start : 0,
              end: reportData ? reportData.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData ? reportData.max_pages : 0}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData ? reportData.max_pages : 0 })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={(
              isAddMessage && writeOptions.length ?
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    disabled={!writeOptions.map(item => item.mailbox_id).includes(selectedSearch)}
                    onClick={() => $router.router.navigate('mail.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                      selectedSearch: selectedSearch,
                      selectedFolder: selectedFolder,
                      selectedEmailName: mailsOptions.filter(item => item.mailbox_id === selectedSearch)[0].email,
                    })}
                  >
                    <Icon icon="plus-thin" />
                    <span>Write Message</span>
                    <Icon icon="email-1" />
                  </button>
                </div> : <></>
            )}
          />

          <div className="contents">
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Date</span>)}

                    sortDirection={localInterface.sort.field === 'date' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'date', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>From mail</span>)}

                    sortDirection={localInterface.sort.field === 'from_mail' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'from_mail', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>To mail</span>)}

                    sortDirection={localInterface.sort.field === 'to_mail' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'to_mail', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Subject</span>)}

                    sortDirection={localInterface.sort.field === 'subject' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'subject', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Attachments</span>)}

                    allFiltersSelected={isAllFiltersSelected("has_attachments")}
                    onAllFiltersChange={(value) => switchFilter("has_attachments", "All", value)}

                    filterWords={reportData?.filter_words.has_attachments.map((filterWord) => ({
                      word: filterWord,
                      word_name: filterWord ? 'Yes' : 'No',
                      selected: isFilterSelected("has_attachments", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("has_attachments", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'has_attachments' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'has_attachments', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>

              {
                mails.map((mail, i) => (
                  <tr
                    key={mail.mail_id}
                    onClick={() =>
                      mail.mail_id &&
                      $router.router.navigate('mail.item', {
                        companyId: activeAccountId,
                        mailId: mail.mail_id,
                        id: mail.mail_id,
                        nameId: 'mailId',
                        localInterface: localInterface,
                        selectedSearch: selectedSearch,
                        selectedFolder: selectedFolder,
                      }, { reload: true })}
                    onContextMenu={(event) => {
                      mail.mail_id &&
                        handleChangeMenu(event, mail.mailbox_id, 'mail', i)
                    }}
                  >
                    <td> {mail.date} </td>
                    <td> {mail.from_mail} </td>
                    <td> {mail.to_mail} </td>
                    <td> {mail.subject} </td>
                    <td>
                      {
                        !!mail.has_attachments &&
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >

                          <Icon style={{ width: '16px', height: '16px' }} viewBox="0 0 1024 1024" icon="attachments-1" />
                        </div>
                      }
                      {
                        showContext === `mail_${mail.mail_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('mail.item', {
                                companyId: activeAccountId,
                                mailboxesId: mail.mailbox_id,
                                localInterface: localInterface,
                                selectedSearch: selectedSearch,
                                selectedFolder: selectedFolder,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/mail/${mail.mail_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))
              }

            </table>

            {/* Medum screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>From mail</th>
                  <th>To mail</th>
                  <th>Subject</th>
                  <th> Attachments </th>
                </tr>
              </thead>

              {
                mails.map((mail, i) => (
                  <tr
                    key={mail.mail_id}
                    onClick={() =>
                      mail.mail_id &&
                      $router.router.navigate('mail.item', {
                        companyId: activeAccountId,
                        mailId: mail.mail_id,
                        id: mail.mail_id,
                        nameId: 'mailId',
                        localInterface: localInterface,
                        selectedSearch: selectedSearch,
                        selectedFolder: selectedFolder,
                      }, { reload: true })}
                    onContextMenu={(event) => {
                      mail.mail_id &&
                        handleChangeMenu(event, mail.mailbox_id, 'mail', i)
                    }}
                  >
                    <td> {mail.date} </td>
                    <td> {mail.from_mail} </td>
                    <td> {mail.to_mail} </td>
                    <td> {mail.subject} </td>
                    <td>
                      {
                        !!mail.has_attachments &&
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >

                          <Icon style={{ width: '16px', height: '16px' }} viewBox="0 0 1024 1024" icon="attachments-1" />
                        </div>
                      }
                      {
                        showContext === `mail_${mail.mail_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('mail.item', {
                                companyId: activeAccountId,
                                mailboxesId: mail.mailbox_id,
                                localInterface: localInterface,
                                selectedSearch: selectedSearch,
                                selectedFolder: selectedFolder,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/mail/${mail.mail_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))
              }
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              {
                mails.map((mail, i) => (
                  <div
                    key={i}
                    className="item"
                    onClick={() =>
                      mail.mail_id &&
                      $router.router.navigate('mail.item', {
                        companyId: activeAccountId,
                        mailId: mail.mail_id,
                        id: mail.mail_id,
                        nameId: 'mailId',
                        localInterface: localInterface,
                        selectedSearch: selectedSearch,
                        selectedFolder: selectedFolder,
                      }, { reload: true })}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>
                          <span className="gray">From mail:</span>
                          <span> {mail.from_mail}</span>
                        </div>

                        <div>
                          <span className="gray">To mail:</span>
                          <span> {mail.to_mail}</span>
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          <span className="gray">Subject:</span>
                          <span> {mail.subject}</span>
                        </div>

                        <div style={{
                          display: 'flex'
                        }}>
                          <span className="gray">Attachments:</span>
                          {
                            !!mail.has_attachments &&
                            <span>
                              <Icon style={{ width: '16px', height: '16px', marginLeft: '10px' }} viewBox="0 0 1024 1024" icon="attachments-1" />
                            </span>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div>
                          <span className="gray">Date:</span>
                          <span> {mail.date}</span>
                        </div>
                      </div>

                      <div className="__right"></div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {
            reportData &&
            <ReportTableControls
              zIndex={5}

              cookie_reportsMaxRows={cookiesSetting?.mail?.limit_rows}

              onMaxRowsChange={(value) => {
                setCookie('settings', { ...cookiesSetting, mail: { ...cookiesSetting.mail, limit_rows: value } })
                $setUpdater(Math.random())
              }}

              amount={{
                total: reportData.rows_all,
                start: reportData.rows_start,
                end: reportData.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.max_pages}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData ? reportData.max_pages : 0 })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}
            />
          }
        </div>
      }
    </>
  )
}