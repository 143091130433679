import { memo } from 'react'
import classNames from "classnames"

import BookingSettings from './BookingSettings'
import AvalTime from './AvalTime'
import ZipCode from './ZipCode'

import { SettingsProps, checkAvailableDataProps, SelectedAvailabelTimeProps, ServiceEditProps, PermissionsProps } from './Models'
import { SelectedMenualTime } from '../AppointmentReschedule/Models'

interface BookingRightSideProps {
  mobile?: boolean
  settings: SettingsProps
  setSettings: (value: SettingsProps) => void
  checkAvailable: checkAvailableDataProps
  setSelectedAvailabelTime: (value: SelectedAvailabelTimeProps | null) => void
  selectedAvailabelTime: SelectedAvailabelTimeProps | null

  zipCheck: string
  changeZipCode: (value: string) => void
  checkZip: () => void
  status: { available: boolean; text: string }[]
  outAvailable: string

  startReserve: boolean
  reserveAvalTime: (
    start: string,
    end: string,
    service_resource_id: string,
    type: string,
    time_zone: string,
    duration?: number) => void
  isSaveClick: boolean

  serviceOptions: ServiceEditProps[]
  handleManualSchedule: (value: boolean) => void

  doubleSelected: string
  setDoubleSelected: (value: string) => void

  menualSettings: SelectedMenualTime
  setMenualSettings: (value: SelectedMenualTime) => void

  selectedMenualTime: boolean
  setSelectedMenualTime: (value: boolean) => void
  cancelChangeTime: boolean

  permissions: PermissionsProps | undefined
  isAvalableOneService: string[]
  appointment_reserve_time_set_duration: boolean | undefined
}

function BookingRightSide({
  mobile,
  settings,
  setSettings,
  checkAvailable,
  setSelectedAvailabelTime,
  selectedAvailabelTime,
  zipCheck,
  changeZipCode,
  checkZip,
  status,
  outAvailable,
  startReserve,
  reserveAvalTime,
  serviceOptions,
  handleManualSchedule,
  doubleSelected,
  setDoubleSelected,
  menualSettings,
  setMenualSettings,
  selectedMenualTime,
  setSelectedMenualTime,
  cancelChangeTime,
  permissions,
  isSaveClick,
  isAvalableOneService,
  appointment_reserve_time_set_duration,
}: BookingRightSideProps) {
  return (
    <div className={
      classNames('booking_page_right', {
        mobile: mobile
      })
    }
    >
      <BookingSettings
        settings={settings}
        setSettings={setSettings}
        handleManualSchedule={handleManualSchedule}
        appointment_schedule_manual={permissions?.appointment_schedule_manual || false}
      />

      <ZipCode
        zipCheck={zipCheck}
        changeZipCode={changeZipCode}
        checkZip={checkZip}
        status={status}
        availability={settings.availability}
        outAvailable={outAvailable}
      />

      <AvalTime
        checkAvailable={checkAvailable}
        show_service={settings.show_service}
        setSelectedAvailabelTime={setSelectedAvailabelTime}
        selectedAvailabelTime={selectedAvailabelTime}
        startReserve={startReserve}
        reserveAvalTime={reserveAvalTime}
        serviceOptions={serviceOptions}
        doubleSelected={doubleSelected}
        setDoubleSelected={setDoubleSelected}
        availability={settings.availability}
        on_date={settings.on_date}
        menualSettings={menualSettings}
        setMenualSettings={setMenualSettings}
        selectedMenualTime={selectedMenualTime}
        setSelectedMenualTime={setSelectedMenualTime}
        cancelChangeTime={cancelChangeTime}
        appointment_reserve_time={permissions?.appointment_reserve_time || false}
        isSaveClick={isSaveClick}
        isAvalableOneService={isAvalableOneService}
        timeDuration={settings.duration}
        setSettings={setSettings}
        settings={settings}
        appointment_reserve_time_set_duration={appointment_reserve_time_set_duration}
      />
    </div>
  )
}

export default memo(BookingRightSide)