import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment"
import classNames from "classnames"
import qs from "qs"
import { useCookies } from "react-cookie";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from "../../models/Misc"
import Payment from "../../models/Payments";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'status' | 'type' | 'service'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PaymentReport
}

interface PaymentReport {
  interface: {
    filter_words: {
      status: string[],
      type: string[],
      service: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    payment_add: boolean
    payment_delete: boolean
    payment_edit: boolean
    payment_report_show: boolean
    payment_show: boolean
  },

  dashboard: {
    all_payments: number
    fee_amount: number
    total_amount: number
    fee_compensation: string
  },

  payments: Payment[]
}

function PaymentsPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<PaymentReport | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.payments?.filter_field ? {
        status: [] as string[],
        type: [] as string[],
        service: [] as string[],
        ...cookiesSetting.payments.filter_field,
      } : {
        status: [] as string[],
        type: [] as string[],
        service: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.payments?.sort_field ? cookiesSetting.payments.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.payments?.sort_type ? cookiesSetting.payments.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Type',
        value: 'type'
      }, {
        span: 'Number',
        value: 'number'
      }, {
        span: 'Service',
        value: 'service'
      }, {
        span: 'Status',
        value: 'status'
      }, {
        span: 'Total',
        value: 'total'
      }, {
        span: 'Net',
        value: 'net'
      }, {
        span: 'Fee',
        value: 'fee'
      }, {
        span: 'Compensation',
        value: 'fee_compensation'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.payments?.filter_field ? {
        status: [] as string[],
        type: [] as string[],
        service: [] as string[],
        ...cookiesSetting.payments.filter_field,
      } : {
        status: [] as string[],
        type: [] as string[],
        service: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.payments?.sort_field ? cookiesSetting.payments.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.payments?.sort_type ? cookiesSetting.payments.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load Payment function
  async function loadPayment() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.payments?.limit_rows ? cookiesSetting?.payments?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.payments?.limit_rows ? cookiesSetting?.payments?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, payments: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.payments?.limit_rows ? cookiesSetting?.payments.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: payments, success, error } } = (await httpClientUpdate.post('/payments/report', qs.stringify(filterParams), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (payments.permissions.payment_report_show) {
          setReportData({
            ...payments,
            interface: {
              ...payments.interface,
              min_date: moment(payments.interface.min_date).startOf('day').toDate(),
              max_date: moment(payments.interface.max_date).endOf('day').toDate(),
            }
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, payments: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load Payment on page mount
  useEffect(() => {
    loadPayment()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function calculateFee() {
    let fee = 0

    if (reportData) {
      if (reportData.dashboard?.fee_amount / reportData.dashboard?.total_amount) {
        fee = (reportData.dashboard?.fee_amount / reportData.dashboard?.total_amount) * 100
      } else {
        fee = 0
      }
    }

    return fee.toFixed(2)
  }

  // Render function
  return (
    <>
      {
        reportData &&
        reportData.permissions.payment_report_show &&
        <div className="JobsPage_List">
          { /* Reports grid */}
          <div className="reports-grid">
            <div className="cell">
              <div className="amount">{reportData.dashboard?.all_payments || 0}</div>
              <div className="legend">All Payments</div>
            </div>

            <div className="cell">
              <div className="amount">${reportData.dashboard?.total_amount.toFixed(2) || 0}</div>
              <div className="legend">Total Amount</div>
            </div>

            <div className="cell">
              <div className="amount">{calculateFee()}%</div>
              <div className="legend">Fee(%)</div>
            </div>

            <div className="cell">
              <div className="amount">${reportData.dashboard?.fee_amount.toFixed(2) || '0.00'} / ${reportData.dashboard.fee_compensation ? reportData.dashboard.fee_compensation : '0.00'}</div>
              <div className="legend">Fee / Compensation</div>
            </div>
          </div>

          { /* Page header */}
          <div className="page-header">
            <h1>Payments</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.payments?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, payments: { ...cookiesSetting.payments, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />

          <div className="contents">

            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Date</span>)}

                    sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Type</span>)}

                    allFiltersSelected={isAllFiltersSelected("type")}
                    onAllFiltersChange={(value) => switchFilter("type", "All", value)}

                    filterWords={reportData.interface.filter_words.type.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("type", filterWord)
                    }))}

                    sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                    onFilterChange={(value) => switchFilter("type", value.word, value.selected)}
                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Number</span>)}

                    sortDirection={localInterface.sort.field === 'number' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'number', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Service</span>)}

                    allFiltersSelected={isAllFiltersSelected("service")}
                    onAllFiltersChange={(value) => switchFilter("service", "All", value)}

                    filterWords={reportData.interface.filter_words.service.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("service", filterWord)
                    }))}
                    onFilterChange={(value) => { switchFilter("service", value.word, value.selected) }}
                    onFilterFire={() => $setUpdater(Math.random())}

                    sortDirection={localInterface.sort.field === 'service' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'service', direction: value } })}
                  />
                  <ReportTableField
                    contents={(<span>Status</span>)}

                    allFiltersSelected={isAllFiltersSelected("status")}
                    onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                    filterWords={reportData.interface.filter_words.status.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("status", filterWord)
                    }))}
                    onFilterChange={(value) => { switchFilter("status", value.word, value.selected) }}
                    onFilterFire={() => $setUpdater(Math.random())}

                    sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}
                  />
                  <ReportTableField
                    contents={(<span>Net</span>)}

                    sortDirection={localInterface.sort.field === 'net' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'net', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Total</span>)}

                    sortDirection={localInterface.sort.field === 'total' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Fee</span>)}

                    sortDirection={localInterface.sort.field === 'fee' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'fee', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Compensation</span>)}

                    sortDirection={localInterface.sort.field === 'fee_compensation' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'fee_compensation', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>
              {reportData.payments.map((pay, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.payment_show && pay.payment_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.payment_show &&
                    pay.payment_id &&
                    $router.router.navigate('payments.item', {
                      companyId: activeAccountId,
                      paymentsId: pay.payment_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.payment_show &&
                    pay.payment_id &&
                    handleChangeMenu(event, pay.payment_id, 'payments', i)}
                >
                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}</td>
                  <td>{pay.type}</td>
                  <td>{pay.number}</td>
                  <td>{pay.service}</td>
                  <td style={{ color: pay.status === 'Succeeded' ? '#219653' : 'inherit' }} >{pay.status}</td>
                  <td>{pay.net}</td>
                  <td>{pay.total}</td>
                  <td>
                    {pay.fee}
                  </td>
                  <td>
                    ${
                      pay.fee_compensation
                    }
                    {
                      reportData.permissions.payment_show &&
                      showContext === `payments_${pay.payment_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('payments.item', {
                              companyId: activeAccountId,
                              paymentsId: pay.payment_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/payments/${pay.payment_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Medum screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>
              {reportData.payments.map((pay, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.payment_show && pay.payment_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.payment_show &&
                    pay.payment_id &&
                    $router.router.navigate('payments.item', {
                      companyId: activeAccountId,
                      paymentsId: pay.payment_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.payment_show &&
                    pay.payment_id &&
                    handleChangeMenu(event, pay.payment_id, 'payments', i)}
                >
                  <td>
                    <div>{pay.type}</div>
                    <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}</div>
                  </td>
                  <td>
                    <div>{pay.number}</div>
                  </td>
                  <td>
                    <div>{pay.service}</div>
                    <div style={{ color: pay.status === 'Succeeded' ? '#219653' : 'inherit' }}>{pay.status}</div>
                  </td>
                  <td>
                    <div>Total: {pay.total}</div>
                  </td>
                  <td>
                    <div>Net: {pay.net}</div>
                    <div>Fee: {pay.fee}</div>
                  </td>

                  <td>
                    Compensation: ${pay.fee_compensation}
                    {
                      reportData.permissions.payment_show &&
                      showContext === `payments_${pay.payment_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('payments.item', {
                              companyId: activeAccountId,
                              paymentsId: pay.payment_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/payments/${pay.payment_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {reportData.payments.map((pay, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    reportData.permissions.payment_show &&
                    pay.payment_id &&
                    $router.router.navigate('payments.item', {
                      companyId: activeAccountId,
                      paymentsId: pay.payment_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>
                        <strong>{pay.type}</strong> ({pay.number})
                      </div>
                      <div>{pay.service}</div>
                      <div style={{ color: pay.status === 'Succeeded' ? '#219653' : 'inherit' }}>{pay.status}</div>
                    </div>

                    <div className="__right">
                      <div>
                        Net: <b>{pay.net}</b>
                      </div>
                      <div>
                        Total: <b>{pay.total}</b>
                      </div>
                      <div>
                        Fee: <b>{pay.fee}</b>
                      </div>
                      <div>
                        Compensation: <b>${pay.fee_compensation}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">

                    </div>
                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.payments?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, payments: { ...cookiesSetting.payments, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}

export default PaymentsPage_List
