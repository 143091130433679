import { useState, useEffect } from "react"
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from 'moment'

import ReportFilters from "./Filters"
import GantChart from "./GantChart"

import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setScheduleDay, setScheduleFrame } from '../../store/reducer'
import { httpClientUpdate, nErrorUpdate, usePrevious } from "../../funcs"
import { arrTimesOfDay, arrGridOfDay } from '../../constans'

import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios"

import './schedule.sass'

export interface TimesProps {
  span: string,
  value: string
}

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    permissions: PermissionsProps
  }
}

export interface PermissionsProps {
  appointment_send: boolean
  appointment_show: boolean
  absence_show: boolean
}

export interface ScheduleDataProps {
  area_id: string
  area_name: string
  area_timezone: string
  service_resources: {
    service_resource_fullname: string
    service_resource_function: string
    service_resource_id: string
    service_resource_image: string | null
    service_resource_nickname: string
    service_resource_code: number | null
    note_yes: string
    note_no: string
    available: boolean
    limit: {
      date: string
      is_limit: boolean
    }[]
    appointments: {
      appointment_date_end: string
      appointment_date_start: string
      appointment_id: string
      appointment_job_number: string
      appointment_notification: boolean
      appointment_type: string
      appointment_zip: string
      units: {
        appliance: string
        appliance_brand: string
        appliance_brand_id: string
        appliance_id: string
        model_number: string
        other_appliance: string
        other_brand: string
        serial_number: string
        unit_id: string
        unit_type: string
      }[]
    }[] | []
    absences: {
      absence_end: string
      absence_start: string
      absence_id: string
      note: string
    }[] | []
    reserves: {
      start: string
      end: string
      duration: null | number
      created_at: string
      created_by: string
    }[]
    time_slots: {
      time_slot_end: string,
      time_slot_start: string,
      time_slot_type: string,
    }[] | []
    absence_time_slots: {
      absence_id: string
      note: string
      time_slot_end: string
      time_slot_start: string
      time_slot_type: string
    }[]
    additional_times: {
      additional_time_end: string
      additional_time_id: string
      additional_time_start: string
    }[]
  }[]
}

function SchedulePage({ updated }: { updated: number }) {
  const $router = useRoute()
  const dispatch = useAppDispatch()

  const [isSending, setIsSending] = useState(false)

  const scheduleDay = useAppSelector((store) => store.scheduleDay)
  const user = useAppSelector((store) => store.user)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const scheduleFrame = useAppSelector((store) => store.scheduleFrame)

  let timeZone = user?.time_zone;

  const [permissions, setPermissions] = useState({
    appointment_send: false,
    appointment_show: false,
    absence_show: false
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [$updater, $setUpdater] = useState<any>(Math.random())
  const [scheduleData, setScheduleData] = useState<ScheduleDataProps[] | []>([])
  const [arrTimesGrid, setArrTimesGrid] = useState<TimesProps[]>(arrTimesOfDay)
  const [arrTimesDayGrid, setArrTimesDayGrid] = useState<string[]>(arrGridOfDay)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: scheduleDay as string,
    dateRangePreset: "today" as DateRangePreset,
    min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
    // min_date: DateTime.fromISO("2023-02-25").setZone(timeZone).startOf('day').toJSDate(),
    // max_date: DateTime.fromISO("2023-02-25").setZone(timeZone).startOf('day').toJSDate(),
    max_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
    min_time: scheduleFrame.min_time,
    max_time: scheduleFrame.max_time,

    page: 1,

    filter_words: {
      area: [] as string[],
      created_by: [] as string[],
      service_resource: [] as string[]
    },

    sort: {
      field: 'created_at',
      direction: 'down' as 'up' | 'down'
    },
    sortFields: [{}]
  })

  const pervMinDate = usePrevious(localInterface.min_date)
  const prevScheduleDay = usePrevious(scheduleDay)
  const prevUpdater = usePrevious($updater)

  // Load info function
  async function loadInfo() {
    try {
      // /schedule?account_id=88888&date=2023-02-23&type=3
      let type = scheduleDay === 'one' ? 1 : scheduleDay === 'two' ? 2 : 3
      const { data: { data: scheduleData, success, error } } = await httpClientUpdate.get('/schedule', {
        params: {
          account_id: activeAccountId,
          date: moment.tz(moment(localInterface.min_date), timeZone as string).format('YYYY-MM-DD'),
          type
        }
      }) as { data: HttpReport }
      if (success) {
        let scheduleArr: ScheduleDataProps[] = []
        Object.keys(scheduleData).forEach(item => {
          if (item !== 'permissions') {
            scheduleArr.push({
              ...scheduleData[item]
            })
          }
        })

        setPermissions(scheduleData.permissions)
        setScheduleData(scheduleArr)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // send appointment
  async function handleSend(appointment_id: string, setAppoint: (value: null) => void) {
    setIsSending(true)
    try {
      // https://2022back4.artemiudintsev.com/api/notifications/serviceresource/appointment
      const response = await httpClientUpdate.post(`/notifications/serviceresource/appointment`, {
        account_id: activeAccountId,
        appointment_id: appointment_id
      })

      if (response.data.success) {
        setAppoint(null)
        setScheduleData(scheduleData.map(area => {
          return {
            ...area,
            service_resources: area.service_resources.map(service => {
              return {
                ...service,
                appointments: service.appointments.map(appoint => {
                  if (appoint.appointment_id === appointment_id) {
                    return {
                      ...appoint,
                      appointment_notification: true
                    }
                  } else {
                    return { ...appoint }
                  }
                })
              }
            })
          }
        }))
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  useEffect(() => {
    updated && setLocalInterface({
      search: '',
      dateRangeCalendarShown: false,
      dateRangeType: scheduleDay as string,
      dateRangePreset: "today" as DateRangePreset,
      min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
      // min_date: DateTime.fromISO("2023-02-25").setZone(timeZone).startOf('day').toJSDate(),
      // max_date: DateTime.fromISO("2023-02-25").setZone(timeZone).startOf('day').toJSDate(),
      max_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
      min_time: scheduleFrame.min_time,
      max_time: scheduleFrame.max_time,

      page: 1,

      filter_words: {
        area: [] as string[],
        created_by: [] as string[],
        service_resource: [] as string[]
      },

      sort: {
        field: 'created_at',
        direction: 'down' as 'up' | 'down'
      },
      sortFields: [{}]
    })
    updated && $setUpdater(updated)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      moment(pervMinDate).format('yyyy-MM-DD') !== moment(localInterface.min_date).format('yyyy-MM-DD')
      || (prevScheduleDay && prevScheduleDay !== scheduleDay)
      || (prevUpdater && prevUpdater !== $updater)
    ) {
      loadInfo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.min_date, scheduleDay, $updater])

  useEffect(() => {
    let indexStart = arrTimesOfDay.findIndex(x => x.span === localInterface.min_time) + 1
    let indexEnd = arrTimesOfDay.findIndex((x, idx) => idx !== 0 && x.span === localInterface.max_time)
    if (localInterface.dateRangeType === 'one') {
      let arr = indexStart === indexEnd ?
        arrTimesOfDay.slice(indexStart - 1, indexEnd) :
        arrTimesOfDay.slice(indexStart, indexEnd)

      setArrTimesGrid(arr)

      let indexStartGrid = arr.length && arrGridOfDay.findIndex(x => x === arr[0].value) - 2
      let indexEndGrid = arr.length && arrGridOfDay.findIndex(x => x === arr[arr.length - 1].value) + 3

      setArrTimesDayGrid(arrGridOfDay.slice(indexStartGrid, indexEndGrid))
    } else {
      let arrForGhant = indexStart === indexEnd ?
        arrTimesOfDay.slice(indexStart - 1, indexEnd) :
        arrTimesOfDay.slice(indexStart, indexEnd)

      if (arrForGhant.length % 2 === 0) {
        setArrTimesGrid(arrForGhant.map((item, index) => {
          if (index === 0 || index % 2 !== 0) {
            return item
          } else {
            return {
              span: '',
              value: ''
            }
          }
        }))

        let indexStartGrid = arrForGhant && arrGridOfDay.findIndex(x => x === arrForGhant[0].value) - 2
        let indexEndGrid = arrForGhant && arrGridOfDay.findIndex(x => x === arrForGhant[arrForGhant.length - 1].value) + 3

        setArrTimesDayGrid(arrGridOfDay.slice(indexStartGrid, indexEndGrid))
      } else {
        let removeOneHour = arrForGhant.map((item, index) => {
          if (index !== 1) {
            return item
          } else {
            return {
              span: '',
              value: ''
            }
          }
        })

        setArrTimesGrid(removeOneHour.map((item, index) => {
          if (index === 0 || index % 2 === 0) {
            return item
          } else {
            return {
              span: '',
              value: ''
            }
          }
        }))

        let indexStartGrid = arrForGhant && arrGridOfDay.findIndex(x => x === arrForGhant[0].value) - 2
        let indexEndGrid = arrForGhant && arrGridOfDay.findIndex(x => x === arrForGhant[arrForGhant.length - 1].value) + 3

        setArrTimesDayGrid(arrGridOfDay.slice(indexStartGrid, indexEndGrid))
      }
    }
  }, [localInterface.min_time, localInterface.max_time, localInterface.dateRangeType])

  useEffect(() => {
    let days = 0

    if (localInterface.dateRangeType === 'one') {
      days = 0
    } else if (localInterface.dateRangeType === 'two') {
      days = 1
    } else if (localInterface.dateRangeType === 'three') {
      days = 2
    }

    if (localInterface.dateRangePreset === 'today') {
      setLocalInterface({
        ...localInterface,
        min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
        max_date: DateTime.now().setZone(timeZone).plus({ days: days }).startOf('day').toJSDate()
      })
    } else if (localInterface.dateRangePreset === 'tomorrow') {
      setLocalInterface({
        ...localInterface,
        min_date: DateTime.now().setZone(timeZone).plus({ days: 1 }).startOf('day').toJSDate(),
        max_date: DateTime.now().setZone(timeZone).plus({ days: days + 1 }).startOf('day').toJSDate()
      })
    } else if (localInterface.dateRangePreset === 'yesterday') {
      setLocalInterface({
        ...localInterface,
        min_date: DateTime.now().setZone(timeZone).minus({ days: 1 }).startOf('day').toJSDate(),
        max_date: days === 0 ?
          DateTime.now().setZone(timeZone).minus({ days: 1 }).startOf('day').toJSDate() :
          days === 1 ? DateTime.now().setZone(timeZone).startOf('day').toJSDate() :
            DateTime.now().setZone(timeZone).plus({ days: 1 }).startOf('day').toJSDate()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset, localInterface.dateRangeType])

  return (
    <div className="SchedulePage_List">

      { /* List filters */}
      <ReportFilters
        onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}

        dateRangeType={localInterface.dateRangeType}
        onDateRangeTypeChange={(value) => {
          dispatch(setScheduleDay(value))
          setLocalInterface({ ...localInterface, dateRangeType: value as any })
        }}

        dateRangePreset={localInterface.dateRangePreset}
        onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value })}

        minDate={localInterface.min_date}
        onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom' })}

        maxDate={localInterface.max_date}
        onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom' })}

        minTime={localInterface.min_time}
        // onMinTimeChange={(value) => setLocalInterface({ ...localInterface, min_time: value })}

        maxTime={localInterface.max_time}
        // onMaxTimeChange={(value) => setLocalInterface({ ...localInterface, max_time: value })}

        onChangeTime={(start, end) => {
          dispatch(setScheduleFrame({ min_time: start, max_time: end }))
          setLocalInterface({ ...localInterface, min_time: start, max_time: end })
        }}

        arrTimesOfDay={arrTimesOfDay}

        updateButtonActive={reportIsDeprecated}
        onUpdate={() => $setUpdater(Math.random())}

        navActive={navActive.is}
        phoneCall={phoneCall}
      />

      <GantChart
        dateRangeType={localInterface.dateRangeType}
        minDate={moment(localInterface.min_date).format('ddd, MMM DD YYYY')}
        arrTimesGrid={arrTimesGrid}
        arrTimesDayGrid={arrTimesDayGrid}
        navActive={navActive.is}
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
        activeAccountId={activeAccountId}
        handleSend={handleSend}
        permissions={permissions}
        isSending={isSending}
      />
    </div>
  )
}

export default SchedulePage
