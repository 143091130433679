import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'
import moment from 'moment'
import Datetime from 'react-datetime'

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from '../../components/Checkbox'

import { capitalizeFirstLetter, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { HttpClientUpdateReport, TicketReport } from './List'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"
import '../../styles/pages/sms.sass'

export interface TemplateProps {
  responsibles: string
  template: string
  type: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone

  const [isSaving, setIsSaving] = useState(false)

  const [message, setMessage] = useState('')
  const [readyToSave, setReadyToSave] = useState(false)
  const [ticketData, setTicketData] = useState({
    // ticket_id: '1',
    created_at: new Date(),
    number: 0,
    status: 'New',
    type: '',
    theme: '',
    author: '',
  })

  const [tempateOptions, setTempateOptions] = useState<TemplateProps[] | []>([])
  const [dataReport, setDataReport] = useState<Partial<TicketReport>>({})
  const [sendTo, setSendTo] = useState({
    service_resource: false,
    author: false,
  })

  const [sendToFromEdit, setSendToFromEdit] = useState({})

  // Load tickets function
  async function loadTickets() {
    try {
      const { data: { data: ticketReport, success, error } } = (await httpClientUpdate.post('/ticket/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,

      }))) as { data: HttpClientUpdateReport }
      if (success) {
        let user_group: string[] = []
        setDataReport(ticketReport)

        let updatedSendEdit = {}
        ticketReport.edit.user_groups.forEach(item => {
          if (user_group.includes(item.user_group_id)) {
            updatedSendEdit[capitalizeFirstLetter(item.name)] = true
          } else {
            updatedSendEdit[capitalizeFirstLetter(item.name)] = false
          }
        })
        setSendToFromEdit(updatedSendEdit)

        if (ticketReport.edit.ticket_templates) {
          let uniqTemplates: TemplateProps[] = []
          ticketReport.edit.ticket_templates.forEach(temp => {
            let partTemplates = uniqTemplates.map(item => item.type)
            if (!partTemplates.includes(temp.type)) {
              uniqTemplates.push({ ...temp })
            }
          })
          setTempateOptions(uniqTemplates)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absences on page mount
  useEffect(() => {
    loadTickets()

    // unmount page
    return () => {
      // setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  useEffect(() => {
    setReadyToSave(true)
  }, [ticketData, sendTo])

  useEffect(() => {
    if (ticketData.type) {
      dataReport.edit?.ticket_templates.forEach(item => {
        if (item.type === ticketData.type) {
          if (item.responsibles) {
            let types = item.responsibles.split(', ')
            types = types.map(item => capitalizeFirstLetter(item))

            let updatedSend = {}
            Object.keys(sendToFromEdit).forEach(item => {

              updatedSend[item] = types.includes(item)
            })
            setSendToFromEdit(updatedSend)
            setSendTo({
              service_resource: false,
              author: types.includes('Author'),
            })
          } else {
            setSendTo({
              service_resource: false,
              author: false,
            })
            let updatedSend = {}
            Object.keys(sendToFromEdit).forEach(item => {
              updatedSend[item] = false
            })
            setSendToFromEdit(updatedSend)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData.type])

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      let created_at = moment(ticketData.created_at).format('MM/DD/YYYY hh:mma')

      let responsibles: { type: string; item_id: string | undefined }[] = []
      Object.keys(sendTo).forEach(item => {
        if (sendTo[item]) {
          if (item === 'service_resource') {
            responsibles.push({
              type: "service_resource",
              item_id: "88888010ty7gjfyh90"
            })
          } else if (item === 'author') {
            responsibles.push({
              type: "author",
              item_id: user?.user_id
            })
          }
        }
      })

      Object.keys(sendToFromEdit).forEach(item => {
        if (sendToFromEdit[item]) {
          responsibles.push({
            type: 'user_group',
            item_id: dataReport.edit && dataReport.edit.user_groups.filter(group => capitalizeFirstLetter(group.name) === item)[0].user_group_id
          })
        }
      })

      // https://2022back4.artemiudintsev.com/api/tickets
      const response = await httpClientUpdate.post(`/tickets`, {
        account_id: activeAccountId,
        type: ticketData.type,
        message: message,
        date: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, created_at as string),
        docs: [],
        responsibles: responsibles
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('tickets', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleSendMessage() {
    // let updateChat: Message[] = chat.map(item => item)

    // let newMessage = {
    //   message_id: `${chat.length}`,
    //   is_read: false,
    //   date: moment().format('MMMM Do YYYY, h:mm:ss a'),
    //   text: message,
    //   name: `${user?.first_name} ${user?.last_name}`,
    //   isClient: false
    // }
    // updateChat.push(newMessage)
    // updateChat.sort((a, b) => (a.date < b.date ? 1 : -1))
    // setChat(updateChat)
    setMessage('')
  }

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && handleSendMessage()
    }
  }

  function checkSendTo() {
    let check = false
    Object.keys(sendTo).forEach(item => {
      if (sendTo[item]) {
        check = true
      }
    })

    Object.keys(sendToFromEdit).forEach(item => {
      if (sendToFromEdit[item]) {
        check = true
      }
    })

    return check
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Ticket</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('tickets', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">

            <div className="field">
              <span>Date:</span>
              <Datetime
                value={ticketData.created_at}
                onChange={(value) => setTicketData({ ...ticketData, created_at: moment.isMoment(value) ? value.toDate() : new Date() })}
              />
            </div>

            <div className="field">
              <span>Status:</span>
              <Select
                options={['New', 'Complited', 'Open', 'Close'].map((option) => ({
                  span: option,
                  value: option
                }))}
                disabled={true}
                selectedOption={ticketData.status as string}
                onChange={(value) => setTicketData({ ...ticketData, status: value as string })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Type:</span>
              <Select
                options={tempateOptions.map((option) => ({
                  span: option.type,
                  value: option.type
                }))}
                selectedOption={ticketData.type as string}
                onChange={(value) => setTicketData({ ...ticketData, type: value as string })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Send To</div>


        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Author:</span>
              <Checkbox
                contents=""
                value={sendTo.author}
                onChange={(value) => setSendTo({ ...sendTo, author: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Service Resource:</span>
              <Checkbox
                contents={''}
                disabled={true}
                value={sendTo.service_resource}
                onChange={(value) => setSendTo({ ...sendTo, service_resource: value })}
              />
            </div>
          </div>
        </div>

        <div
          className="fields"
          style={{ gridGap: 'inherit', marginTop: '0' }}
        >
          {
            dataReport.edit?.user_groups.map((item, index) => {
              if (index === 0 || index % 2 === 0) {
                return (
                  <div
                    key={index}
                    style={{ display: 'flex', width: '50%' }}
                  >
                    <div className="field">
                      <span>{capitalizeFirstLetter(item.name)}:</span>
                      <Checkbox
                        contents=""
                        value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                        onChange={(value) => setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })}
                      />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    style={{ display: 'flex', width: '50%', paddingLeft: '15px' }}
                  >
                    <div className="field">
                      <span>{capitalizeFirstLetter(item.name)}:</span>
                      <Checkbox
                        contents=''
                        value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                        onChange={(value) => setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })}
                      />
                    </div>
                  </div>
                )
              }
            })
          }
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Update</div>

        <div
          style={{
            justifyContent: 'space-between',
            gridGap: 'inherit',
            textAlign: 'center'
          }}
          className="fields"
        >
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'relative'
          }}>
            <textarea
              style={{ width: '100%' }}
              placeholder='Your Message'
              value={message}
              onChange={({ target: { value } }) => setMessage(value)}
              onKeyDown={(event) => handleChangeMessage(event)}
            />

            <div
              style={{
                position: 'absolute',
                right: '5px',
                bottom: '5px',
                display: 'flex'
              }}
            >
              <button
                className='clear'
                disabled={message === ''}
                onClick={() => setMessage('')}
              >
                Clear
              </button>

              <button
                className='send'
                disabled={message === '' || !checkSendTo() || !readyToSave || isSaving}
                onClick={() => handleSave()}
              >
                Create
              </button>
            </div>
          </div>
        </div>

        {/* show all screen */}
        {/* <div className='chat'
        >
          {
            chat.map((message, i) => (
              <div key={i} className='chat-row chat-update'>
                <div className='item left'>
                  <p> {message.text} </p>
                  <p style={{ textAlign: 'right' }} >
                    <span> {message.date} from <strong>{message.name}</strong></span>
                  </p>
                </div>
              </div>
            ))
          }
        </div> */}
      </div>
    </div>
  )
}
