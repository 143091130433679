import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'

import Icon from '../../components/Icon'
import BlankPage from './BlankPage'
import TimeHasPassed from '../../components/TimeHasPassed'
import TimeLogBlock from './TimeLogBlock'
import Payment from './Payment'
import DefaultHouseImage from '../../images/house.png'

import ActiveWallet from '../../images/active_wallet.png'
import Wallet from '../../images/wallet.png'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, formatPhoneNumberForItemTech, getSchuduleTime, getTimeHasPassed, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import '../../styles/pages/jobItemTech.sass'

interface HttpTimeLogReport {
  success: boolean
  error: {
    code: number,
    message: string
  },
  data: {
    time_slot_tasks: TimeSlotTasks[]
  }
}

export interface TimeSlotTasks {
  appointment_id: string
  created_by: string
  diff: string
  finish: string
  id: number
  job_id: string
  service_resource_id: string
  start: string
  time_slot_type: string
  updated_by: string
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    job: JobDataProps
    permissions: PermissionsProps
  }
}

interface PermissionsProps {
  technician_appointment_add: boolean
  technician_appointment_show: boolean
  technician_client_show: boolean
  technician_estimate_add: boolean
  technician_estimate_show: boolean
  technician_invoice_add: boolean
  technician_invoice_show: boolean
  technician_map_show: boolean
  technician_part_add: boolean
  technician_part_show: boolean
  technician_unit_add: boolean
  technician_unit_show: boolean

  job_search_show: boolean
  job_search_show_own: boolean

  payment_square_make: boolean
  payment_stripe_make: boolean

  payment_add: boolean
  payment_add_own: boolean
}

interface JobDataProps {
  job_id: string
  job_address: string
  job_name: string
  contact_address_id: string
  contact_id: string
  company_id: string
  service_resource_id: string
  company_name: string
  is_need_payment: boolean
  appointment: {
    appointment_finish: string
    appointment_id: string
    appointment_start: string
    appointment_type: string
    appointment_note: string
    invoice_status: string
    time_zone: string
    appointment_last_time_slot_task: {
      appointment_id: string
      start: string
      time_slot_task_id: string
      time_slot_type: string
    }
    appointment_phones: {
      laravel_through_key: string
      name: string
      phone: string
    }[]
  }
  other_appointments: {
    appointment_finish: string
    appointment_id: string
    appointment_start: string
    appointment_type: string
    invoice_status: string
    time_zone: string
  }[]
  client: {
    address: string
    address_image: string
    client_id: string
    name: string
  }[]
  edit: EditProps
  estimates: {
    estimate_id: string
    estimate_total: string
    estimate_status: string | null
  }[]
  invoices: InvoicesProps[]
  parts: {
    part_id: string
    part_name: string
    part_status: string
  }[]
  units: {
    unit_id: string
    unit_type: string
    appliance: string
    appliance_brand: string
    other_appliance: string
    other_brand: string
    description: string
  }[]
  payments: {
    company_name: string
    created_at: string
    deposit_id: string
    fee_compensation: string
    number: string
    payment_id: string
    service_name: string
    status: string
    total: string
    type: string
    items: {
      invoice_id: string
      job_id: string
      note: string
      payment_id: string
      total_item: string
    }[]
  }[]
}

export interface InvoicesProps {
  invoice_id: string | null
  invoice_paid: string | null
  invoice_status: string | null
  invoice_total: string | null
  name: string
}

export interface EditProps {
  appliance_brands: {
    brand_id: string
    name: string
  }[]
  appliances: {
    appliance_id: string
    name: string
  }[]
  unit_type: string[]
  appointment_status: {
    appointment_status: string
    appointment_status_id: number
  }[]
  estimate_status: {
    estimate_status: string
    estimate_status_id: number
  }[]
  invoice_status: {
    invoice_status: string
    invoice_status_id: number
  }[]
  part_status: {
    part_status: string
    part_status_id: number
  }[]
  warehouses: {
    warehouse: string
    warehouse_id: string
  }[]
  payment_services: PaymentServicesProps[]
}

export interface PaymentServicesProps {
  name: string
  payment_services_id: string
  is_not_editable: boolean
}

export interface TimeObjectProps {
  start_hours: number
  start_minutes: number
  start_seconds: number
}

export interface DataProps {
  is_add_invoice: boolean
  job_id?: string
  invoice_id?: string
  contact_id?: string
  name: string
  note: string
  id: string | null
  total: number
  paid: number
  amount: number
  is_check: boolean
}

export default function ItemTech() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const time_zone = user?.time_zone

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [jobData, setJobData] = useState<JobDataProps | null>(null)
  const [permissions, setPermissions] = useState<Partial<PermissionsProps>>({})
  const [page, setPage] = useState('main')
  const [id, setId] = useState('')
  const [activeBtn, setActiveBtn] = useState('')
  const [address, setAddress] = useState('')

  const [isStart, setIsStart] = useState('')
  const [isMyTyme, setIsMyTyme] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [timeSlotTaskId, setTimeSlotTaskId] = useState('')

  const [timeObject, setTimeObject] = useState<TimeObjectProps | null>(null)

  const [timeTrackingModal, setTimeTrackingModal] = useState(false)
  const [timeLogReport, setTimeLogReport] = useState<TimeSlotTasks[]>([])

  const [paymentModal, setPaymentModal] = useState(false)
  const [paymentPage, setPaymentPage] = useState('')
  const [paymentOtherName, setPaymentOtherName] = useState('')

  const [isOpenScheduled, setIsOpenScheduled] = useState(true)

  // load Job
  async function loadJob() {
    try {
      const { data: { data: jobData, success, error } } = await httpClientUpdate.get('/technician/jobs/' + $router.router.getState().params.jobId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setJobData(jobData.job)
        setPermissions(jobData.permissions)
        if (jobData.job.appointment.appointment_last_time_slot_task) {
          if (jobData.job.appointment.appointment_last_time_slot_task.time_slot_type === 'on_my_way') {
            let time = getTimeHasPassed(jobData.job.appointment.appointment_last_time_slot_task.start)

            setTimeObject({
              start_hours: time.hours,
              start_minutes: time.minutes,
              start_seconds: time.seconds,
            })
            setIsStart(jobData.job.appointment.appointment_last_time_slot_task.time_slot_task_id)
          } else if (jobData.job.appointment.appointment_last_time_slot_task.time_slot_type === 'start_my_time') {
            let time = getTimeHasPassed(jobData.job.appointment.appointment_last_time_slot_task.start)

            setTimeObject({
              start_hours: time.hours,
              start_minutes: time.minutes,
              start_seconds: time.seconds,
            })
            setIsMyTyme(true)
          }
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function timeSlotTasks() {
    try {
      setIsSaving(true)
      if (!!isStart) {
        // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-stop
        const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-stop`, {
          account_id: activeAccountId,
          appointment_id: jobData?.appointment.appointment_id,
          time_slot_task_id: isStart,
        })

        if (response.data.success) {
          setIsSaving(false)
          setIsStart('')
          setTimeObject(null)
        } else {
          setIsSaving(false)
        }
      } else {
        // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-start
        const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-start`, {
          account_id: activeAccountId,
          appointment_id: jobData?.appointment.appointment_id,
          time_slot_type: 'on_my_way',
        })

        if (response.data.success) {
          setIsSaving(false)
          setIsStart(response.data.data.time_slot_task_id)
          isMyTyme && setIsMyTyme(false)

          if (timeObject) {
            setTimeObject(null)

            setTimeout(() => {
              setTimeObject({
                start_hours: 0,
                start_minutes: 0,
                start_seconds: 1,
              })
            }, 100)
          } else {
            setTimeObject({
              start_hours: 0,
              start_minutes: 0,
              start_seconds: 1,
            })
          }
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function startMyTime() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-start
      const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-start`, {
        account_id: activeAccountId,
        appointment_id: jobData?.appointment.appointment_id,
        time_slot_type: 'start_my_time',
      })

      if (response.data.success) {
        timeObject && setTimeObject(null)
        setIsSaving(false)
        setIsMyTyme(true)

        setTimeSlotTaskId(response.data.data.time_slot_task_id)

        !!isStart && setIsStart('')

        if (timeObject) {
          setTimeObject(null)

          setTimeout(() => {
            setTimeObject({
              start_hours: 0,
              start_minutes: 0,
              start_seconds: 1,
            })
          }, 100)
        } else {
          setTimeObject({
            start_hours: 0,
            start_minutes: 0,
            start_seconds: 1,
          })
        }
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function stopMyTime() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-start
      const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-stop`, {
        account_id: activeAccountId,
        appointment_id: jobData?.appointment.appointment_id,
        time_slot_task_id: timeSlotTaskId,
      })

      if (response.data.success) {
        setTimeObject(null)
        setIsSaving(false)
        setIsMyTyme(false)

        !!isStart && setIsStart('')
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function getTimeLog() {
    try {
      setIsSaving(true)
      const { data: { data: report, success } } = await httpClientUpdate.get('/technician/time-slot-tasks/report', {
        params: {
          account_id: activeAccountId,
          job_id: jobData?.job_id,
          page: 1,
          limit_rows: 100,
        }
      }) as { data: HttpTimeLogReport }

      if (success) {
        setIsSaving(false)
        setTimeTrackingModal(true)
        setTimeLogReport(report.time_slot_tasks)
      }
    } catch (error) { }
  }

  async function handlePayment(type: string, data: DataProps[], total: number, check_number?: string) {
    try {
      setIsSaving(true)
      let payment_services_id = ''
      let type_name = ''

      if (type === 'Cash' || type === 'Check') {
        type_name = type
      } else {
        type_name = 'Other'
      }

      jobData?.edit.payment_services.forEach(item => {
        if (item.name === type) {
          payment_services_id = item.payment_services_id
        }
      })

      //  https://2022back4.artemiudintsev.com/api/payments
      const response = await httpClientUpdate.post(`/payments`, {
        account_id: activeAccountId,
        type: type_name,
        service_id: payment_services_id,
        number: check_number ? check_number : `${total}`,
        items: data.map(item => {
          if (item.is_add_invoice) {
            if (item.invoice_id) {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                invoice_id: item.invoice_id,
                job_id: item.job_id
              }
            } else {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                job_id: item.job_id
              }
            }
          } else {
            if (item.id) {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                invoice_id: item.id,
                job_id: jobData?.job_id
              }
            } else {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                job_id: jobData?.job_id
              }
            }
          }
        })
      }) as { data: { success: boolean } }

      if (response.data.success) {
        setPaymentModal(false)
        setPaymentOtherName('')
        setPaymentPage('')
        setIsSaving(false)
        loadJob()
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (page === 'main') {
      loadJob()
      setId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (paymentModal) {
      setPaymentPage('')
    }
  }, [paymentModal])

  function handleCloseTimeLog() {
    setTimeTrackingModal(false)
    setTimeLogReport([])
  }

  function switchPage(name: string) {
    setPage(name)
  }

  function handleClose() {
    setPaymentModal(false)
    setPaymentPage('')
  }

  function handleCloseCardModal() {
    setPaymentModal(false)
    setPaymentPage('')
    loadJob()
  }

  function getInvoiceName(id: string) {
    let name = ''

    jobData?.invoices.forEach(item => {
      if (item.invoice_id === id) {
        name = item.name
      }
    })

    return name ? name : id
  }

  function getPaidSumForJob() {
    let sum = 0

    jobData?.payments.forEach(payment => {
      payment.items.forEach(item => {
        if (!item.invoice_id) {
          sum = sum + Number(item.total_item)
        }
      })
    })

    return sum
  }

  return (
    <>
      {
        page === 'main' ?
          <div className='job-tech'>
            {
              jobData &&
              <>
                {/* header for desctop and table */}
                <div className={classNames('job-tech-header', '__hide-on-mobile',
                  {
                    __respectAsidePanel: navActive.is && !phoneCall,
                    __phoneCall: phoneCall && !navActive.is,
                    __bothOpen: navActive.is && phoneCall,
                    __nonePanel: !navActive.is && !phoneCall
                  }
                )}
                >
                  <button
                    onClick={() =>
                      // reportData.permissions.job_show &&
                      $router.router.navigate('appointment_calendar', {
                        companyId: activeAccountId
                      }, { reload: true })}
                  >
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>
                  <div className='header-info-image' >
                    {
                      jobData.client.length ?
                        <img
                          style={{ objectFit: 'cover' }}
                          src={jobData.client[0].address_image ? jobData.client[0].address_image : DefaultHouseImage}
                          alt="House"
                        /> :
                        <img
                          style={{ objectFit: 'cover' }}
                          src={DefaultHouseImage}
                          alt="House"
                        />
                    }
                    <div className='header-info'>
                      <div className='header-info-customer'>
                        {!!jobData.client.length && jobData.client[0].name}
                      </div>

                      <div className='header-info-address'>
                        {!!jobData.client.length && jobData.client[0].address}
                      </div>
                    </div>
                  </div>
                </div>

                {/* header for mobile */}
                <div className={classNames('job-tech-header mobile', '__show-on-mobile',
                  {
                    __respectAsidePanel: navActive.is && !phoneCall,
                    __phoneCall: phoneCall && !navActive.is,
                    __bothOpen: navActive.is && phoneCall,
                    __nonePanel: !navActive.is && !phoneCall
                  }
                )}
                >
                  <div
                    className='header-arrow-button'
                  >
                    <button
                      className={classNames({
                        unactive: (paymentPage !== '' && paymentPage !== 'other_list') || paymentOtherName !== ''
                      })}
                      onClick={() => {
                        paymentPage === 'other_list' && paymentOtherName === '' && paymentModal && setPaymentPage('')
                        paymentPage === '' && paymentModal && setPaymentModal(false)
                        paymentPage === '' && !paymentModal &&
                          $router.router.navigate('appointment_calendar', {
                            companyId: activeAccountId
                          }, { reload: true })
                      }}
                    >
                      <Icon style={{ width: '18px' }} viewBox="0 0 24 24" icon="arrow-25" />
                    </button>

                    <div className='header-arrow-info'>
                      <div title={jobData.job_name} className='header-arrow-info-text'>
                        Job: {jobData.job_name}
                      </div>
                    </div>

                    <div className='header-arrow-info-none'></div>
                  </div>
                </div>

                {/* Buttons block*/}
                <div
                  className={classNames('job-buttons', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <button
                    className="unactive"
                  >
                    Messages
                  </button>

                  <button
                    className="unactive"
                  >
                    Calls
                  </button>

                  <button
                    className={classNames(
                      {
                        active: activeBtn === 'start'
                      }
                    )}
                    onClick={() => setActiveBtn(activeBtn === 'start' ? '' : 'start')}
                  >
                    {activeBtn === 'start' ? 'Break' : 'Start'}
                  </button>

                  <button
                  >
                    Finish
                  </button>

                  <button
                  >
                    Payment
                  </button>
                </div>

                <div
                  className={classNames('job-buttons mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <button
                    className="unactive stroke"
                  >
                    <Icon viewBox="0 0 87 83" icon="message-job-tech-1" />

                    <div className='title'>
                      Messages
                    </div>
                  </button>

                  <button
                    className="unactive stroke"
                  >
                    <Icon viewBox="0 0 80 80" icon="calls-job-tech-1" />

                    <div className='title'>
                      Calls
                    </div>
                  </button>

                  <button
                    className={classNames({
                      unactive: paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)
                    })}
                    onClick={() => (permissions.payment_add || permissions.payment_add_own) && paymentPage === '' && jobData.is_need_payment && setPaymentModal(true)}
                  >
                    {
                      (paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)) ?
                        <img src={Wallet} alt="Wallet" /> :
                        <img src={ActiveWallet} alt="Wallet" />
                    }

                    <div className='title'>
                      Pay
                    </div>
                  </button>

                  <button
                    className={classNames('stroke', {
                      unactive: paymentPage !== ''
                    })}
                  >
                    <Icon style={{ height: '28px' }} viewBox="0 0 18 76" icon="dots-job-tech-1" />

                    <div className='title'>
                      More
                    </div>
                  </button>
                </div>

                {/* Content block */}
                {/* Customer block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                    }
                  )}
                >
                  <div className='content-title'>
                    Customer
                  </div>

                  <div
                    className='content-customer'
                    style={{ cursor: permissions.technician_client_show ? 'pointer' : 'inherit' }}
                    onClick={() => permissions.technician_client_show && setPage('client')}
                  >
                    <div className='content-customer-left'>
                      <Icon viewBox="3 0 22 20" icon="pin-1" />
                    </div>

                    <div className='content-customer-center'>
                      {!!jobData.client.length && jobData.client[0].name}
                    </div>

                    <div className='content-customer-right'>
                      {
                        permissions.technician_client_show &&
                        <button
                          onClick={() => permissions.technician_client_show && setPage('client')}
                        >
                          <Icon viewBox="0 0 24 24" icon="arrow-25" />
                        </button>
                      }
                    </div>
                  </div>

                  <div
                    className='content-customer'
                    style={{ cursor: permissions.technician_map_show ? 'pointer' : 'inherit' }}
                    onClick={() => {
                      permissions.technician_map_show && jobData.job_address && setPage('google_map')
                      permissions.technician_map_show && jobData.job_address && setAddress(jobData.job_address)
                    }}
                  >
                    <div className='content-customer-left'>
                      <Icon viewBox="0 0 11 11" icon="location-1" />
                    </div>

                    <div className='content-customer-center'>
                      {jobData.job_address}
                    </div>

                    <div className='content-customer-right'>
                      {
                        permissions.technician_map_show &&
                        jobData.job_address &&
                        <button
                          onClick={() => {
                            setPage('google_map')
                            setAddress(jobData.job_address)
                          }}
                        >
                          <Icon viewBox="0 0 24 24" icon="arrow-25" />
                        </button>
                      }
                    </div>
                  </div>
                </div>

                {
                  timeTrackingModal &&
                  <TimeLogBlock
                    navActive={navActive}
                    phoneCall={phoneCall}
                    handleCloseTimeLog={handleCloseTimeLog}
                    data={timeLogReport}
                    isStart={isStart}
                    isMyTyme={isMyTyme}
                  />
                }

                {
                  paymentModal &&
                  <Payment
                    setPaymentModal={setPaymentModal}
                    navActive={navActive}
                    phoneCall={phoneCall}
                    paymentPage={paymentPage}
                    setPaymentPage={setPaymentPage}
                    invoices={jobData.invoices}
                    permission_search_show={!!permissions.job_search_show || !!permissions.job_search_show_own}
                    job_name={jobData.job_name}
                    permissions_payment_make={!!permissions.payment_square_make && !!permissions.payment_stripe_make}
                    payment_services={jobData.edit.payment_services.filter(item => !item.is_not_editable)}
                    payment_services_all={jobData.edit.payment_services}
                    paymentOtherName={paymentOtherName}
                    setPaymentOtherName={setPaymentOtherName}
                    payment_square_make={!!permissions.payment_square_make}
                    payment_stripe_make={!!permissions.payment_stripe_make}
                    handlePayment={handlePayment}
                    handleClose={handleClose}
                    isSaving={isSaving}
                    job_id={jobData.job_id}
                    handleCloseCardModal={handleCloseCardModal}
                    company_id={jobData.company_id}
                    contact_id={jobData.client[0].client_id}
                    paid_job={getPaidSumForJob()}
                    service_resource_id={jobData.service_resource_id}
                  />
                }

                <div
                  className={classNames('job-tech-content mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal
                    }
                  )}
                >
                  <div style={{ padding: '5px 15px', marginTop: '10px' }} className='content-title'>
                    <div
                      className='content-title-row'
                      onClick={() => setIsOpenScheduled(!isOpenScheduled)}
                    >
                      <Icon
                        className={classNames({ _flipped: !isOpenScheduled })}
                        style={{ width: '12px', minWidth: '12px' }}
                        icon="arrow-65"
                      />

                      <div className='mobile-text'>
                        Scheduled
                      </div>
                    </div>

                    <div
                      className='content-title-row'
                      onClick={() => getTimeLog()}
                    >
                      <div className='mobile-text' >
                        {
                          timeObject ?
                            <TimeHasPassed
                              start_hours={timeObject.start_hours}
                              start_minutes={timeObject.start_minutes}
                              start_seconds={timeObject.start_seconds}
                            /> : 'Time log'
                        }
                      </div>

                      <Icon
                        style={{ width: '12px', minWidth: '12px' }}
                        viewBox="0 0 24 24"
                        icon="arrow-25"
                      />
                    </div>
                  </div>

                  {
                    isOpenScheduled &&
                    <div className='content-customer-mobile'>

                      <div className='content-customer-actions'>
                        <div className='content-customer-actions-btn'>
                          <div className='content-customer-actions-btn-pic'>
                            <Icon
                              style={{ marginRight: '5px' }}
                              viewBox="0 0 100 100"
                              icon="cursor-job-tech-1"
                            />
                          </div>

                          <div className='mobile-text'>
                            Navigate
                          </div>
                        </div>

                        <div
                          className={classNames('content-customer-actions-btn',
                            {
                              is_start: !!isStart,
                            }
                          )}
                          onClick={() => !isSaving && timeSlotTasks()}
                        >
                          <div className='content-customer-actions-btn-pic'>
                            <Icon
                              viewBox="0 0 136 92"
                              style={{ fill: '#fff', width: '28px' }}
                              icon="group-2"
                            />
                          </div>

                          <div className='mobile-text'>
                            On my way
                          </div>
                        </div>

                        <div
                          className='content-customer-actions-btn'
                          onClick={() => !isSaving && !isMyTyme ? startMyTime() : stopMyTime()}
                        >
                          <div className='content-customer-actions-btn-pic'>
                            {
                              isMyTyme ?
                                <Icon
                                  icon="media-control-49"
                                /> :
                                <Icon
                                  style={{ marginLeft: '5px' }}
                                  viewBox="0 0 86 88"
                                  icon="play-job-tech-1"
                                />
                            }
                          </div>

                          <div className='mobile-text'>
                            {isMyTyme ? 'Stop my time' : 'Start my time'}
                          </div>
                        </div>
                      </div>

                      <div className='content-customer-appointment'>
                        <div className='content-customer-appointment-row'>
                          <div className='mobile-text'>
                            Current appointment:
                          </div>

                          <div
                            className={classNames('content-customer-appointment-type',
                              {
                                SC: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'SC',
                                RC: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'RC',
                                FU: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'FU',
                              }
                            )}
                          >
                            {jobData.appointment.appointment_type}
                          </div>
                        </div>

                        <div className='content-customer-appointment-row'>
                          <div className='content-customer-appointment-date'>
                            {getSchuduleTime(jobData.appointment.appointment_start, jobData.appointment.appointment_finish, jobData.appointment.time_zone)}
                          </div>
                        </div>

                        <div className='content-customer-appointment-row'>
                          <div className='mobile-text'>
                            Note:
                          </div>

                          <div className='content-customer-appointment-note'>
                            {jobData.appointment.appointment_note}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div
                  className={classNames('job-tech-customer mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal
                    }
                  )}
                >
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <Icon viewBox='0 0 85 85' icon="customer-job-tech-1" />

                      <div className='mobile-text'>
                        Customer
                      </div>
                    </div>
                  </div>

                  <div className='job-tech-customer-appointments'>
                    <div className='job-tech-customer-appointments-row'>
                      <div className='job-tech-customer-appointments-title'>
                        {!!jobData.client.length && jobData.client[0].name}
                      </div>

                      <div className='content-customer-right'>
                        <button
                          disabled={!permissions.technician_client_show}
                          onClick={() => permissions.technician_client_show && setPage('client')}
                        >
                          <Icon viewBox="0 0 24 24" icon="arrow-25" />
                        </button>
                      </div>
                    </div>

                    <div className='job-tech-customer-appointments-row'>
                      <div className='job-tech-customer-appointments-title'>
                        {jobData.job_address}
                      </div>
                    </div>

                    {
                      jobData.appointment.appointment_phones.map((item, index) => (
                        <div key={index} className='job-tech-customer-appointments-row'>
                          <div className='job-tech-customer-appointments-title'>
                            <a href={`tel:${item.phone}`}>
                              {`${formatPhoneNumberForItemTech(item.phone)} (${item.name})`}
                            </a>
                          </div>
                        </div>
                      ))
                    }

                    <div className='job-tech-customer-appointments-row'>
                      <div></div>

                      <div className='job-tech-customer-appointments-show'>
                        <div className='mobile-text nowrap'>
                          All appointments for this job
                        </div>

                        <button
                          disabled={!permissions.technician_client_show}
                          onClick={() => permissions.technician_client_show && setPage('client')}
                        >
                          <Icon viewBox="0 0 24 24" icon="arrow-25" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Units block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <div className='content-title content-title-action'>
                    <span>Units</span>
                    {
                      permissions.technician_unit_add &&
                      <button
                        onClick={() => setPage('unit_add')}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    }
                  </div>
                  {
                    jobData.units.map((device, index) => (
                      <div
                        key={index}
                        className='content-customer'
                        style={{ cursor: permissions.technician_unit_show ? 'pointer' : 'inherit' }}
                        onClick={() => {
                          permissions.technician_unit_show && setPage('unit_edit')
                          permissions.technician_unit_show && setId(device.unit_id)
                        }
                        }
                      >
                        <div className='content-customer-left'>
                          <span className='device'></span>
                        </div>

                        <div className='content-customer-center'>
                          {device.unit_type}: {device.appliance === 'Other' ? device.other_appliance : device.appliance}, {device.appliance_brand === 'Other' ? device.other_brand : device.appliance_brand}
                        </div>

                        <div className='content-customer-right'>
                          {
                            permissions.technician_unit_show &&
                            <button
                              onClick={() => {
                                setPage('unit_edit')
                                setId(device.unit_id)
                              }
                              }
                            >
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>

                <div
                  className={classNames('job-tech-content mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal
                    }
                  )}
                >
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <Icon viewBox='0 0 70 77' icon="units-job-tech-1" />

                      <div className='mobile-text'>
                        Units
                      </div>
                    </div>

                    <div className='content-title-row'>
                      <button
                        onClick={() => setPage('unit_add')}
                      >
                        <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                      </button>
                    </div>
                  </div>

                  {
                    <div
                      className='job-tech-content-items'
                      style={{ padding: !!jobData.units.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.units.map((device, index) =>
                          <div
                            key={index}
                            className='job-tech-content-item'
                            style={{ cursor: permissions.technician_unit_show ? 'pointer' : 'inherit' }}
                            onClick={() => {
                              permissions.technician_unit_show && setPage('unit_edit')
                              permissions.technician_unit_show && setId(device.unit_id)
                            }}
                          >
                            <div className='job-tech-content-item-text'>
                              <div className='main'>
                                {`${device.appliance === 'Other' ? device.other_appliance : device.appliance} ${device.unit_type !== 'Residential' ? '(' + device.unit_type + ')' : ''}: ${device.appliance_brand === 'Other' ? device.other_brand : device.appliance_brand}`}
                              </div>

                              <div className='other'>
                                {device.description}
                              </div>
                            </div>

                            <div className='job-tech-content-item-arrow'>
                              <button
                                disabled={!permissions.technician_unit_show}
                                onClick={() => {
                                  setPage('unit_edit')
                                  setId(device.unit_id)
                                }
                                }
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  }
                </div>

                {/* Invoices block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <div className='content-title content-title-action'>
                    <span>Invoices</span>
                    {
                      permissions.technician_invoice_add &&
                      <button
                        onClick={() => setPage('invoice_add')}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    }
                  </div>
                  {
                    jobData.invoices.map((invoice, index) => (
                      <div
                        key={index}
                        className='content-customer'
                        style={{ cursor: permissions.technician_invoice_show ? 'pointer' : 'inherit' }}
                        onClick={() => {
                          permissions.technician_invoice_show && setPage('invoice_edit')
                          permissions.technician_invoice_show && invoice.invoice_id && setId(invoice.invoice_id)
                        }}
                      >
                        <div className='content-customer-left estimate'>
                          0{index + 1}
                        </div>

                        <div className='content-customer-center estimate'>
                          <div>
                            {invoice.invoice_status}
                          </div>

                          <div>
                            {
                              invoice.invoice_total &&
                              `$${invoice.invoice_paid ? invoice.invoice_paid : '0.00'}/${invoice.invoice_total ? invoice.invoice_total : '0.00'}`
                            }
                          </div>
                        </div>

                        <div className='content-customer-right'>
                          {
                            permissions.technician_invoice_show &&
                            <button
                              onClick={() => {
                                setPage('invoice_edit')
                                invoice.invoice_id && setId(invoice.invoice_id)
                              }}
                            >
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>

                <div
                  className={classNames('job-tech-content mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal
                    }
                  )}
                >
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <Icon viewBox="0 0 70 77" icon="group-23" />

                      <div className='mobile-text'>
                        Invoices
                      </div>
                    </div>

                    <div className='content-title-row'>
                    </div>
                  </div>

                  {
                    <div
                      className='job-tech-content-items'
                      style={{ padding: !!jobData.invoices.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.invoices.map((invoice, index) =>
                          <div
                            key={index}
                            className='job-tech-content-item'
                            style={{ cursor: permissions.technician_invoice_show ? 'pointer' : 'inherit' }}
                            onClick={() => {
                              permissions.technician_invoice_show && setPage('invoice_edit')
                              permissions.technician_invoice_show && invoice.invoice_id && setId(invoice.invoice_id)
                            }}
                          >
                            <div className='job-tech-content-item-text'>
                              <div className='main invoice'>
                                {invoice.name}

                                {
                                  invoice.invoice_total && invoice.invoice_paid && invoice.invoice_total === invoice.invoice_paid &&
                                  <div className='check-mark'>
                                    <Icon icon="check-mark-1" />
                                  </div>
                                }
                              </div>

                              <div className='invoice-info'>
                                <div className='invoice-info-total'>
                                  Total: {invoice.invoice_total ? invoice.invoice_total : '$0.00'}
                                </div>

                                <div className='invoice-info-paid'>
                                  Paid: ${invoice.invoice_paid ? invoice.invoice_paid : '0.00'}
                                </div>
                              </div>
                            </div>

                            <div className='job-tech-content-item-arrow'>
                              <button
                                disabled={!permissions.technician_invoice_show}
                                onClick={() => {
                                  setPage('invoice_edit')
                                  invoice.invoice_id && setId(invoice.invoice_id)
                                }}
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  }
                </div>

                {/* Estimates block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <div className='content-title content-title-action'>
                    <span>Estimates</span>
                    {
                      permissions.technician_estimate_add &&
                      <button
                        onClick={() => setPage('estimate_add')}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    }
                  </div>
                  {
                    jobData.estimates.map((estimate, index) => (
                      <div
                        key={index}
                        className='content-customer'
                        style={{ cursor: permissions.technician_estimate_show ? 'pointer' : 'inherit' }}
                        onClick={() => {
                          permissions.technician_estimate_show && setPage('estimate_edit')
                          permissions.technician_estimate_show && setId(estimate.estimate_id)
                        }}
                      >
                        <div className='content-customer-left estimate'>
                          0{index + 1}
                        </div>

                        <div className='content-customer-center estimate'>
                          <div>
                            {estimate.estimate_status}
                          </div>

                          <div>
                            {estimate.estimate_total}
                          </div>
                        </div>

                        <div className='content-customer-right'>
                          {
                            permissions.technician_estimate_show &&
                            <button
                              onClick={() => {
                                setPage('estimate_edit')
                                setId(estimate.estimate_id)
                              }}
                            >
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>

                <div
                  className={classNames('job-tech-content mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal,
                    }
                  )}
                >
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <Icon viewBox="0 0 70 77" icon="group-22" />

                      <div className='mobile-text'>
                        Estimates
                      </div>
                    </div>

                    <div className='content-title-row'>
                      <button
                        disabled={!permissions.technician_estimate_add}
                        onClick={() => setPage('estimate_add')}
                      >
                        <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                      </button>
                    </div>
                  </div>

                  {
                    <div
                      className='job-tech-content-items'
                      style={{ padding: !!jobData.estimates.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.estimates.map((estimate, index) =>
                          <div
                            key={index}
                            className='job-tech-content-item'
                            style={{ cursor: permissions.technician_estimate_show ? 'pointer' : 'inherit' }}
                            onClick={() => {
                              permissions.technician_estimate_show && setPage('estimate_edit')
                              permissions.technician_estimate_show && setId(estimate.estimate_id)
                            }}
                          >
                            <div className='job-tech-content-item-text'>
                              <div className='main'>
                                {estimate.estimate_status}
                              </div>

                              <div className='other'>
                                {estimate.estimate_total}
                              </div>
                            </div>

                            <div className='job-tech-content-item-arrow'>
                              <button
                                disabled={!permissions.technician_estimate_show}
                                onClick={() => {
                                  setPage('estimate_edit')
                                  setId(estimate.estimate_id)
                                }
                                }
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  }
                </div>

                {/* Payments block */}
                <div
                  className={classNames('job-tech-content mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal,
                    }
                  )}
                >
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <Icon viewBox="0 0 70 77" icon="payment-job-tech-9" />

                      <div className='mobile-text'>
                        Payments
                      </div>
                    </div>

                    <div className='content-title-row'>
                      <button
                        disabled={paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)}
                        onClick={() => (permissions.payment_add || permissions.payment_add_own) && paymentPage === '' && jobData.is_need_payment && setPaymentModal(true)}
                      >
                        <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                      </button>
                    </div>
                  </div>

                  {
                    <div
                      className='job-tech-content-items'
                      style={{ padding: !!jobData.payments.length ? '10px 5px' : '9px 10px' }}
                    >
                      <table>
                        <tbody>
                          {
                            jobData.payments.map((payment) =>
                              payment.items.map((payment_item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div>
                                      {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, payment.created_at)}
                                    </div>

                                    <div className='bold center'>
                                      {payment_item.invoice_id ? getInvoiceName(payment_item.invoice_id) : jobData.job_name}
                                    </div>
                                  </td>

                                  <td className='flex'>
                                    {
                                      payment.type === 'Card' &&
                                      <div className='bold'> {payment.type} {payment.number} </div>
                                    }

                                    {
                                      payment.type === 'Check' &&
                                      <div className='bold'> {payment.number} </div>
                                    }
                                  </td>

                                  <td className='right'>
                                    <div className='other'>
                                      {payment.service_name}
                                    </div>

                                    <div className='bold'>
                                      ${payment_item.total_item}
                                    </div>

                                    <div className='note'>
                                      {payment_item.note}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )
                          }
                        </tbody>
                      </table>
                      {/* {
                        jobData.payments.map((payment) =>
                          payment.items.map((payment_item, index) => (

                            <div
                              key={index}
                              className='job-tech-content-item flex-start border_bottom'
                            >
                              <div className='job-tech-content-item-text center'>
                                <div style={{ margin: '0' }} className='other'>
                                  {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, payment.created_at)}
                                </div>

                                <div className='main'>
                                  {payment_item.invoice_id ? getInvoiceName(payment_item.invoice_id) : jobData.job_name}
                                </div>
                              </div>

                              {
                                payment.type === 'Card' &&
                                <div className='job-tech-content-item-text'>
                                  <div className='main'>
                                    {payment.type} {payment.number}
                                  </div>
                                </div>
                              }

                              {
                                payment.type === 'Check' &&
                                <div className='job-tech-content-item-text'>
                                  <div className='main'>
                                    {payment.number}
                                  </div>
                                </div>
                              }

                              <div className='job-tech-content-item-text right'>
                                <div className='other'>
                                  {payment.service_name}
                                </div>

                                <div className='main'>
                                  ${payment_item.total_item}
                                </div>

                                <div className='note'>
                                  {payment_item.note}
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      } */}
                    </div>
                  }
                </div>

                {/* Appointment block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <div className='content-title content-title-action'>
                    <span>Appointment</span>
                  </div>
                  <div
                    className='content-customer'
                    style={{ cursor: permissions.technician_appointment_show ? 'pointer' : 'inherit' }}
                    onClick={() => {
                      permissions.technician_appointment_show && setPage('appointment_edit')
                      permissions.technician_appointment_show && setId(jobData.appointment.appointment_id)
                    }}
                  >
                    <div className='content-customer-left estimate appointment'>
                      <span>
                      </span>

                      <span className={classNames('type',
                        {
                          SC: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'SC',
                          RC: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'RC',
                          FU: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'FU',
                        }
                      )} ></span>
                    </div>

                    <div className='content-customer-center estimate'>
                      <div>
                        {jobData.appointment.invoice_status}
                      </div>

                      <div>
                        {getSchuduleTime(jobData.appointment.appointment_start, jobData.appointment.appointment_finish, jobData.appointment.time_zone)}
                      </div>
                    </div>

                    <div className='content-customer-right'>
                      {
                        permissions.technician_appointment_show &&
                        <button
                          onClick={() => {
                            setPage('appointment_edit')
                            setId(jobData.appointment.appointment_id)
                          }}
                        >
                          <Icon viewBox="0 0 24 24" icon="arrow-25" />
                        </button>
                      }
                    </div>
                  </div>
                </div>

                {/* Appointments other block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <div className='content-title content-title-action'>
                    <span>Appointments (previous, next) </span>
                    {
                      permissions.technician_appointment_add &&
                      <button
                        onClick={() => setPage('appointment_add')}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    }
                  </div>
                  {
                    jobData.other_appointments.map((appoint, index) => (
                      <div
                        key={index}
                        className='content-customer'
                        style={{ cursor: permissions.technician_appointment_show ? 'pointer' : 'inherit' }}
                        onClick={() => {
                          permissions.technician_appointment_show && setPage('appointment_edit')
                          permissions.technician_appointment_show && setId(appoint.appointment_id)
                        }}
                      >
                        <div className='content-customer-left estimate appointment'>
                          <span>
                            0{index + 1}
                          </span>

                          <span className={classNames('type',
                            {
                              SC: appoint.appointment_type && appoint.appointment_type === 'SC',
                              RC: appoint.appointment_type && appoint.appointment_type === 'RC',
                              FU: appoint.appointment_type && appoint.appointment_type === 'FU',
                            }
                          )} ></span>
                        </div>

                        <div className='content-customer-center estimate'>
                          <div>
                            {appoint.invoice_status}
                          </div>

                          <div>
                            {getSchuduleTime(appoint.appointment_start, appoint.appointment_finish, appoint.time_zone)}
                          </div>
                        </div>

                        <div className='content-customer-right'>
                          {
                            permissions.technician_appointment_show &&
                            <button
                              onClick={() => {
                                setPage('appointment_edit')
                                setId(appoint.appointment_id)
                              }}
                            >
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>

                {/* Parts block */}
                <div
                  className={classNames('job-tech-content', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <div className='content-title content-title-action'>
                    <span>Parts</span>
                    {
                      permissions.technician_part_add &&
                      <button
                        onClick={() => setPage('parts_add')}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    }
                  </div>
                  {
                    jobData.parts.map((part, index) => (
                      <div
                        key={index}
                        className='content-customer'
                        style={{ cursor: permissions.technician_part_show ? 'pointer' : 'inherit' }}
                        onClick={() => {
                          permissions.technician_part_show && setPage('parts_edit')
                          permissions.technician_part_show && setId(part.part_id)
                        }}
                      >
                        <div className='content-customer-left estimate'>
                          0{index + 1}
                        </div>

                        <div className='content-customer-center estimate'>
                          <div>
                            {part.part_status}
                          </div>

                          <div>
                            {part.part_name}
                          </div>
                        </div>

                        <div className='content-customer-right'>
                          {
                            permissions.technician_part_show &&
                            <button
                              onClick={() => {
                                setPage('parts_edit')
                                setId(part.part_id)
                              }}
                            >
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>

                <div
                  className={classNames('job-tech-content mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall,
                      __disabled: timeTrackingModal || paymentModal,
                    }
                  )}
                >
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <Icon viewBox='0 0 1024 1024' icon="part-f" />

                      <div className='mobile-text'>
                        Parts
                      </div>
                    </div>

                    <div className='content-title-row'>
                      <button
                        disabled={!permissions.technician_part_add}
                        onClick={() => setPage('parts_add')}
                      >
                        <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                      </button>
                    </div>
                  </div>

                  {
                    <div
                      className='job-tech-content-items'
                      style={{ padding: !!jobData.parts.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.parts.map((part, index) =>
                          <div
                            key={index}
                            className='job-tech-content-item'
                            style={{ cursor: permissions.technician_part_show ? 'pointer' : 'inherit' }}
                            onClick={() => {
                              permissions.technician_part_show && setPage('parts_edit')
                              permissions.technician_part_show && setId(part.part_id)
                            }}
                          >
                            <div className='job-tech-content-item-text'>
                              <div className='main'>
                                {part.part_status}
                              </div>

                              <div className='other'>
                                {part.part_name}
                              </div>
                            </div>

                            <div className='job-tech-content-item-arrow'>
                              <button
                                disabled={!permissions.technician_part_show}
                                onClick={() => {
                                  setPage('parts_edit')
                                  setId(part.part_id)
                                }}
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  }
                </div>
              </>
            }
          </div > :
          <BlankPage
            page={page}
            switchPage={switchPage}
            id={id}
            jobId={jobData?.job_id}
            edit={jobData?.edit}
            client_id={jobData?.client.length ? jobData?.client[0].client_id : null}
            address={address}
            jobAddress={jobData?.job_address}
          />
      }
    </>
  )
}
