import { useState, useEffect } from 'react'
import moment from "moment"

interface ModalSaveProps {
  time_start?: string
  time_finish?: string
  technician?: string
  setModalSave: () => void
}

export default function ModalSave({
  time_start,
  time_finish,
  technician,
  setModalSave,
}: ModalSaveProps) {
  const [dateForModal, setDateForModal] = useState({
    date: '',
    start: '',
    end: ''
  })

  useEffect(() => {
    if (time_start && time_finish) {
      let isSomeDay = moment(time_start, "YYYY-MM-DD hh:mm:ss").isSame(moment(time_finish, "YYYY-MM-DD hh:mm:ss"), 'day')
     
      if (isSomeDay) {
        let timeStart = moment(time_start, "YYYY-MM-DD hh:mm:ss").format('ha')
        let timeEnd = moment(time_finish, "YYYY-MM-DD hh:mm:ss").format('ha')
        let date =  moment(time_finish, "YYYY-MM-DD hh:mm:ss").format('YYYY-MM-DD')

        setDateForModal({
          date: date,
          start: timeStart,
          end: timeEnd
        })
      } else {
        let timeStart = moment(time_start, "YYYY-MM-DD hh:mm:ss").format('MM-DD-YYYY ha')
        let timeEnd = moment(time_finish, "YYYY-MM-DD hh:mm:ss").format('MM-DD-YYYY ha')
        let dateStart = moment(time_start, "YYYY-MM-DD hh:mm:ss").format('YYYY-MM-DD')
        let dateEnd =moment(time_finish, "YYYY-MM-DD hh:mm:ss").format('YYYY-MM-DD')

        setDateForModal({
          date: `${dateStart} - ${dateEnd}`,
          start: timeStart,
          end: timeEnd
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='modal-save'>
      <div className='modal-save-content'>
        <div className='header'>
          Appointment
        </div>

        <div>
          On date: {dateForModal.date}
        </div>

        <div>Time: {dateForModal.start}-{dateForModal.end} </div>

        <div>
          Technician: {technician}
        </div>

        <div className='btns'>
          <button
            className='btn btn-clear'
            onClick={() => setModalSave()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
