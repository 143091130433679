import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import qs from 'qs'

import SelectComponent from '../../components/Select'
import Icon from '../../components/Icon'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import { Unit } from './UnitEdit'

import "../../styles/pages/common/entity-edit.sass"
import '../Appointments/UnitsBlock.sass'

interface UnitAddProps {
  switchPage: (name: string) => void
  edit: {
    unit_type: string[]
    appliances: {
      appliance_id: string
      name: string
    }[]
    appliance_brands: {
      brand_id: string
      name: string
    }[]
  }
  jobId: string
  client_id: string
}

export default function UnitAdd({ switchPage, edit, jobId, client_id }: UnitAddProps) {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newUnitData, setNewUnitData] = useState<Partial<Unit>>({

  })

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/units`, qs.stringify({
      account_id: activeAccountId,
      unit_type: newUnitData.unit_type,
      appliance_id: newUnitData.appliance_id,
      other_appliance: newUnitData.other_appliance || 'Fryer new',
      brand_id: newUnitData.appliance_brand_id,
      other_brand: newUnitData.other_brand || 'US_Top_new',
      model_number: newUnitData.model_number,
      serial_number: newUnitData.serial_number,
      price: newUnitData.price,
      description: newUnitData.description,
      appointment_id: $router.router.getState().params.jobId,
      contact_id: client_id,
      job_id: jobId,
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  useEffect(() => setReadyToSave(true), [
    newUnitData
  ])

  function checkOtherFieldAppliences() {
    let appliances = edit.appliances.filter(item => item.appliance_id === newUnitData.appliance_id)
    return appliances?.length === 1 ? appliances[0].name : ''
  }

  function checkOtherFieldBrand() {
    let brand = edit.appliance_brands.filter(item => item.brand_id === newUnitData.appliance_brand_id)
    return brand?.length === 1 ? brand[0].name : ''
  }

  return (
    <div className="UnitPage_Add entity-edit">
      < div className="wrapper flex-container sb" >
        <h1>Add new Unit</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => switchPage('main')}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div >

      <div className="fieldset">
        <div className={classNames('__show-on-wide', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div
              className='row'
              style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
            >
              <div
                className='row unit_type'
                style={{ width: 'calc(20% - 20px)', marginRight: '20px' }}
              >
                <span>Unit type:</span>

                <SelectComponent
                  options={edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                    span: type,
                    value: type
                  }))}
                  selectedOption={newUnitData.unit_type || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
                />
              </div>

              <div
                className='row unit_type'
                style={{ width: 'calc(20% - 20px)', marginRight: '20px' }}
              >
                <span>Unit:</span>

                <SelectComponent
                  options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                    span: type.name,
                    value: type.appliance_id
                  }))}
                  selectedOption={newUnitData.appliance_id || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
                />
              </div>

              {
                checkOtherFieldAppliences() === 'Other' &&
                <div
                  className="row unit_type"
                  style={{ width: 'calc(20% - 20px)', marginRight: '20px' }}
                >
                  <span>Other Unit:</span>
                  <input
                    className='model_number'
                    name='other_appliance'
                    type="text"
                    value={newUnitData.other_appliance}
                    onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                  />
                </div>
              }

              <div
                className='row unit_type'
                style={{ width: 'calc(20% - 20px)', marginRight: '20px' }}
              >
                <span>Brand:</span>

                <SelectComponent
                  options={edit.appliance_brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                    span: type.name,
                    value: type.brand_id
                  }))}
                  selectedOption={newUnitData.appliance_brand_id || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
                />
              </div>

              {
                checkOtherFieldBrand() === '' &&
                <div
                  className="row unit_type"
                  style={{ width: 'calc(20% - 20px)', marginRight: '20px' }}
                >
                  <span>Other Brand:</span>
                  <input
                    className='model_number'
                    name='other_brand'
                    type="text"
                    value={newUnitData.other_brand}
                    onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                  />
                </div>
              }

              <div
                className="row unit_type"
                style={{ width: 'calc(20% - 20px)', marginRight: '20px' }}
              >
                <span>Model Number:</span>
                <input
                  className='model_number'
                  name='model_number'
                  type="text"
                  value={newUnitData.model_number}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
                />
              </div>

              <div
                className="row unit_type"
                style={{ width: '20%' }}
              >
                <span>Serial Number:</span>
                <input
                  className='serial_number'
                  name='serial_number'
                  type="text"
                  value={newUnitData.serial_number}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
                />
              </div>
            </div>

            <div className='row'>
              <div className="row">
                <span>Description:</span>

                <textarea
                  className='description'
                  name='description'
                  value={newUnitData.description}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
                >

                </textarea>
              </div>

              <div className="row">
                <span>Price:</span>
                <input
                  className='price'
                  name='price'
                  type="text"
                  value={newUnitData.price}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, price: value })}
                />
              </div>
            </div>
          </div>
        </div>

        {/* tablet */}
        <div className={classNames('__hide-on-wide', '__hide-on-mobile', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div
              className='row'
              style={{ flexWrap: 'wrap' }}
            >
              <div
                className='row unit_type'
                style={{ width: 'calc(33% - 20px)', marginRight: '20px' }}
              >
                <span>Unit type:</span>

                <SelectComponent
                  options={edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                    span: type,
                    value: type
                  }))}
                  selectedOption={newUnitData.unit_type || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
                />
              </div>

              <div
                className='row unit_type'
                style={{ width: 'calc(33% - 20px)', marginRight: '20px' }}
              >
                <span>Unit:</span>

                <SelectComponent
                  options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                    span: type.name,
                    value: type.appliance_id
                  }))}
                  selectedOption={newUnitData.appliance_id || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
                />
              </div>

              {
                checkOtherFieldAppliences() === 'Other' &&
                <div
                  className="row unit_type"
                  style={{ width: 'calc(33% - 20px)', marginRight: '20px' }}
                >
                  <span>Other Unit:</span>
                  <input
                    className='model_number'
                    name='other_appliance'
                    type="text"
                    value={newUnitData.other_appliance}
                    onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                  />
                </div>
              }

              <div
                className='row unit_type'
                style={{ width: 'calc(33% - 20px)', marginRight: '20px' }}
              >
                <span>Brand:</span>

                <SelectComponent
                  options={edit.appliance_brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                    span: type.name,
                    value: type.brand_id
                  }))}
                  selectedOption={newUnitData.appliance_brand_id || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
                />
              </div>

              {
                checkOtherFieldBrand() === 'Other' &&
                <div
                  className="row unit_type"
                  style={{ width: 'calc(33% - 20px)', marginRight: '20px' }}
                >
                  <span>Other Brand:</span>
                  <input
                    className='model_number'
                    name='other_brand'
                    type="text"
                    value={newUnitData.other_brand}
                    onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                  />
                </div>
              }
            </div>

            <div className='row'>
              <div className="row">
                <span>Model Number:</span>
                <input
                  className='model_number'
                  name='model_number'
                  type="text"
                  value={newUnitData.model_number}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
                />
              </div>

              <div className="row">
                <span>Serial Number:</span>
                <input
                  className='serial_number'
                  name='serial_number'
                  type="text"
                  value={newUnitData.serial_number}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
                />
              </div>

              <div className="row">
                <span>Price:</span>
                <input
                  className='price'
                  name='price'
                  type="text"
                  value={newUnitData.price}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, price: value })}
                />
              </div>
            </div>

            <div className='row'>
              <div className="row">
                <span>Description:</span>

                <textarea
                  className='description'
                  name='description'
                  value={newUnitData.description}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
                >

                </textarea>
              </div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className={classNames('__show-on-mobile', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div className='row unit_type'>
              <span>Unit type:</span>

              <SelectComponent
                options={edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                  span: type,
                  value: type
                }))}
                selectedOption={newUnitData.unit_type || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
              />
            </div>

            <div className='row unit_type'>
              <span>Unit:</span>

              <SelectComponent
                options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                  span: type.name,
                  value: type.appliance_id
                }))}
                selectedOption={newUnitData.appliance_id || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
              />
            </div>

            {
              checkOtherFieldAppliences() === 'Other' &&
              <div className="row unit_type">
                <span>Other Unit:</span>
                <input
                  className='model_number'
                  name='other_appliance'
                  type="text"
                  value={newUnitData.other_appliance}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                />
              </div>
            }

            <div className='row unit_type'>
              <span>Brand:</span>

              <SelectComponent
                options={edit.appliance_brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                  span: type.name,
                  value: type.brand_id
                }))}
                selectedOption={newUnitData.appliance_brand_id || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
              />
            </div>

            {
              checkOtherFieldBrand() === 'Other' &&
              <div className="row unit_type">
                <span>Other Brand:</span>
                <input
                  className='model_number'
                  name='other_brand'
                  type="text"
                  value={newUnitData.other_brand}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                />
              </div>
            }

            <div className="row">
              <span>Model Number:</span>
              <input
                className='model_number'
                name='model_number'
                type="text"
                value={newUnitData.model_number}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
              />
            </div>

            <div className="row">
              <span>Serial Number:</span>
              <input
                className='serial_number'
                name='serial_number'
                type="text"
                value={newUnitData.serial_number}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
              />
            </div>

            <div className="row">
              <span>Price:</span>
              <input
                className='price'
                name='price'
                type="text"
                value={newUnitData.price}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, price: value })}
              />
            </div>

            <div style={{ marginBottom: '0' }} className="row">
              <span>Description:</span>

              <textarea
                className='description'
                name='description'
                value={newUnitData.description}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
              >

              </textarea>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => switchPage('main')}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
