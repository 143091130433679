import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import { DateTime } from "luxon"
import classNames from "classnames"
import { Transition } from "react-transition-group";
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import Checkbox from "../../components/Checkbox";

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from "../../store/hooks"

import Ticket from "../../models/Ticket";
import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'author_id' | 'status' | 'type'

export interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: TicketReport
}

export interface TicketReport {
  interface: {
    filter_words: {
      author_id: string[],
      status: string[],
      type: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    ticket_add: boolean
    ticket_delete: boolean
    ticket_edit: boolean
    ticket_report_show: boolean
    ticket_send_message: boolean
    ticket_show: boolean
  },

  tickets: Ticket[]
  edit: {
    user_groups: {
      name: string
      user_group_id: string
    }[]
    ticket_templates: {
      responsibles: string
      template: string
      type: string
    }[]
    authors: {
      dispatcher_code: number
      dispatcher_nickname: string
      first_name: string
      last_name: string
      service_resource_code: number
      service_resource_nickname: string
      user_id: string
    }[]
  }
}

export default function Tickets_Page_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [topFilterShown, setTopFilterShown] = useState(false)

  const [$updater, $setUpdater] = useState<any>(Math.random())
  const [reportData, setReportData] = useState<TicketReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "all" as DateRangePreset,
    min_date: DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(),
    max_date: DateTime.now().setZone(timeZone).endOf('day').toJSDate(),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.tickets?.filter_field ? {
      author_id: [] as string[],
      status: [] as string[],
      type: [] as string[],
      ...cookiesSetting.tickets.filter_field,
    } : {
      author_id: [] as string[],
      status: [] as string[],
      type: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.tickets?.sort_field ? cookiesSetting.tickets.sort_field : 'date_time_start',
      direction: cookiesSetting && cookiesSetting?.tickets?.sort_type ? cookiesSetting.tickets.sort_type : 'down' as 'down' | 'up'
    },
    searchFilters: [["show scheduled tickets", "hide completed tickets"], ["inboud", "outbound"]],
    selectedSearchFilters: { type: ["hide completed tickets"] as string[] },
    sortFields: [{
      span: 'Date',
      value: 'date_time_start'
    }, {
      span: 'Number',
      value: 'number'
    }, {
      span: 'Status',
      value: 'status'
    }, {
      span: 'Type',
      value: 'type'
    }, {
      span: 'Theme',
      value: 'theme'
    }, {
      span: 'Author',
      value: 'author_id'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      dateRangeCalendarShown: false,
      dateRangeType: "schedule" as "created" | "schedule",
      dateRangePreset: "all" as DateRangePreset,
      min_date: DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(),
      max_date: DateTime.now().setZone(timeZone).endOf('day').toJSDate(),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.tickets?.filter_field ? {
        author_id: [] as string[],
        status: [] as string[],
        type: [] as string[],
        ...cookiesSetting.tickets.filter_field,
      } : {
        author_id: [] as string[],
        status: [] as string[],
        type: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.tickets?.sort_field ? cookiesSetting.tickets.sort_field : 'date_time_start',
        direction: cookiesSetting && cookiesSetting?.tickets?.sort_type ? cookiesSetting.tickets.sort_type : 'down' as 'down' | 'up'
      },
      searchFilters: [["show scheduled tickets", "hide completed tickets"], ["inboud", "outbound"]],
      selectedSearchFilters: { type: ["hide completed tickets"] as string[] },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load tickets function
  async function loadTickets() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.tickets?.limit_rows ? cookiesSetting?.tickets?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date === 'all' ? null : $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date === 'all' ? null : $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          main_filter: JSON.stringify($router.router.getState().params.localInterface.selectedSearchFilters.type.includes('hide completed tickets') ?
            { 'is_hide_closed': 1 } :
            { 'is_hide_closed': 0 }),
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.tickets?.limit_rows ? cookiesSetting?.tickets?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          main_filter: JSON.stringify(localInterface.selectedSearchFilters.type.includes('hide completed tickets') ?
            { 'is_hide_closed': 1 } :
            { 'is_hide_closed': 0 }),
          date_start: localInterface.dateRangePreset === 'all' ? null : localInterface.min_date,
          date_end: localInterface.dateRangePreset === 'all' ? null : localInterface.max_date,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, tickets: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.tickets?.limit_rows ? cookiesSetting?.tickets.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: ticketReport, success, error } } = (await httpClientUpdate.post('/ticket/report', qs.stringify({
        ...filterParams
      }, { skipNulls: true }))) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData({
          ...ticketReport,
          interface: {
            ...ticketReport.interface,
            min_date: DateTime.fromISO(ticketReport.interface.min_date as unknown as string).startOf('day').toJSDate(),
            max_date: DateTime.fromISO(ticketReport.interface.max_date as unknown as string).endOf('day').toJSDate(),
          }
        })

        if (localInterface.dateRangePreset === 'all') {
          setLocalInterface({
            ...localInterface,
            min_date: DateTime.fromISO(ticketReport.interface.min_date as unknown as string).startOf('day').toJSDate(),
            max_date: DateTime.fromISO(ticketReport.interface.max_date as unknown as string).startOf('day').toJSDate(),
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, tickets: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absences on page mount
  useEffect(() => {
    loadTickets()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return

    setReportIsDeprecated(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is all search fields selected function
  function isAllSearchFiltersSelected(i: number, field: string) {
    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.length === 0
    }
  }

  // Is search field selected function

  function isSearchFilterSelected(i: number, value: string, field: string) {

    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.includes(value);
    }
  }

  // Toggle search filter function
  function switchSearchFilter(i: number, value: string, toggle: boolean, field: string, $arr: string[]) {
    let $arrType = $arr;
    let $object = localInterface.selectedSearchFilters;

    if (toggle && value === "All") {
      $arrType = [];
    }
    else if (toggle === false) {
      for (let i = 0; i < $arrType.length; i++) {
        // eslint-disable-next-line no-loop-func
        $arrType.forEach((v, index) => {
          if (v === value) {
            $arrType.splice(index, 1)
          }
        })
      }
    } else {
      $arrType.push(value);
    }

    if (field === 'type') {
      $object.type = $arrType;
    }
    setLocalInterface({ ...localInterface, selectedSearchFilters: $object })
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  function getAuthorName(id: string) {
    let name = ''

    reportData?.edit.authors.forEach(item => {
      if (item.user_id === id) {
        if (item.service_resource_nickname) {
          name = item.service_resource_code ? `${item.service_resource_nickname} (${item.service_resource_code})` : item.service_resource_nickname
        } else if (item.dispatcher_code || item.dispatcher_nickname) {
          name = item.dispatcher_code ? `${item.dispatcher_nickname} (${item.dispatcher_code})` : item.dispatcher_nickname
        } else {
          name = `${item.first_name} ${item.last_name}`
        }
      }
    })

    return name
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {
        reportData ?
          <div className="AbsencesPage_List" >
            { /* Page header */}
            <div className="page-header">
              <h1>Tickets</h1>
            </div>

            { /* List filters */}
            <ReportFilters
              onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
              searchInput={localInterface.search}
              searchButtonActive={searchButtonActive}

              dateRangePreset={localInterface.dateRangePreset}
              onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

              minDate={localInterface.min_date}
              onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

              maxDate={localInterface.max_date}
              onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

              updateButtonActive={reportIsDeprecated}
              onUpdate={() => $setUpdater(Math.random())}

              phoneCall={phoneCall}
              navActive={navActive.is}

              childrenAfterSearch={(
                <div
                  tabIndex={-1}
                  className="filter-button"
                  onFocus={() => setTopFilterShown(true)}
                  onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setTopFilterShown(false)}>
                  <button className={classNames("_wa", "_rounded", "_iconed", {
                    _blue: !isAllSearchFiltersSelected(0, 'type')
                  })}>
                    <Icon icon="filter-8" />
                  </button>

                  <Transition in={topFilterShown} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                    {(state) => (
                      <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                        {/* <Checkbox contents="All" value={isAllSearchFiltersSelected(0, 'type')} onChange={(value) => switchSearchFilter(0, "All", value, 'type', localInterface.selectedSearchFilters.type)} /> */}
                        {localInterface.searchFilters[0].map((option, index) => (
                          <Checkbox
                            key={index}
                            contents={option}
                            value={isSearchFilterSelected(0, option, 'type')}
                            onChange={(value) => switchSearchFilter(1, option, value, 'type', localInterface.selectedSearchFilters.type)}
                          />
                        ))}
                        <hr />
                        <button onClick={() => {
                          $setUpdater(Math.random());
                          setTopFilterShown(false)
                        }} className="_bordered _blue _wa">
                          Filter
                        </button>
                      </div>
                    )}
                  </Transition>
                </div>
              )}
            />

            {/* Table controls */}
            <ReportTableControls
              zIndex={5}

              cookie_reportsMaxRows={cookiesSetting?.tickets?.limit_rows}

              onMaxRowsChange={(value) => {
                setCookie('settings', { ...cookiesSetting, tickets: { ...cookiesSetting.tickets, limit_rows: value } })
                $setUpdater(Math.random())
              }}

              amount={{
                total: reportData.interface.rows_all,
                start: reportData.interface.rows_start,
                end: reportData.interface.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}

              addButton={(
                reportData.permissions.ticket_add ?
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('tickets.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      })}
                    >
                      <Icon icon="plus-thin" />
                      <span>Add Ticket</span>
                      <Icon icon="time-16" />
                    </button>
                  </div> :
                  <></>
              )}
            />

            <div className="contents">

              {/* Wide desktop table */}
              <table className={classNames('table', '__show-on-wide',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>
                <thead>
                  <tr>
                    <ReportTableField
                      contents={(<span>Date</span>)}

                      sortDirection={localInterface.sort.field === 'date_time_start' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'date_time_start', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />

                    <ReportTableField
                      contents={(<span>Id</span>)}

                      sortDirection={localInterface.sort.field === 'number' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'number', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />

                    <ReportTableField
                      contents={(<span>Status</span>)}

                      allFiltersSelected={isAllFiltersSelected("status")}
                      onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                      filterWords={reportData.interface.filter_words.status.map((filterWord) => ({
                        word: filterWord,
                        selected: isFilterSelected("status", filterWord)
                      }))}
                      onFilterChange={(value) => switchFilter("status", value.word, value.selected)}

                      sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />

                    <ReportTableField
                      contents={(<span>Type</span>)}

                      allFiltersSelected={isAllFiltersSelected("type")}
                      onAllFiltersChange={(value) => switchFilter("type", "All", value)}

                      filterWords={reportData.interface.filter_words.type.map((filterWord) => ({
                        word: filterWord,
                        selected: isFilterSelected("type", filterWord)
                      }))}
                      onFilterChange={(value) => switchFilter("type", value.word, value.selected)}

                      sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />

                    <ReportTableField
                      contents={(<span>Theme</span>)}

                      sortDirection={localInterface.sort.field === 'theme' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'theme', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />

                    <ReportTableField
                      contents={(<span>Author</span>)}

                      allFiltersSelected={isAllFiltersSelected("author_id")}
                      onAllFiltersChange={(value) => switchFilter("author_id", "All", value)}

                      filterWords={reportData.interface.filter_words.author_id.map((filterWord) => ({
                        word: filterWord,
                        word_name: getAuthorName(filterWord),
                        selected: isFilterSelected("author_id", filterWord)
                      }))}
                      onFilterChange={(value) => switchFilter("author_id", value.word, value.selected)}

                      sortDirection={localInterface.sort.field === 'author_id' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'author_id', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />
                  </tr>
                </thead>

                {reportData?.tickets.map((ticket, i) => (
                  <tr
                    key={i}
                    style={{ position: 'relative', cursor: reportData.permissions.ticket_show && ticket.ticket_id ? 'pointer' : 'inherit' }}
                    onClick={() =>
                      reportData.permissions.ticket_show &&
                      ticket.ticket_id &&
                      $router.router.navigate('tickets.item', {
                        companyId: activeAccountId,
                        ticketId: ticket.ticket_id,
                        localInterface: localInterface,
                      }, { reload: true })}
                    onContextMenu={(event) => {
                      reportData.permissions.ticket_show &&
                        ticket.ticket_id &&
                        handleChangeMenu(event, ticket.ticket_id, 'tickets', i)
                    }}
                  >
                    <td style={{ position: 'relative', paddingRight: '25px' }}>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticket.date_time_start)}
                      {
                        !!ticket.is_responsible &&
                        ticket.status !== 'Closed' &&
                        ticket.status !== 'Canceled' &&
                        <span
                          style={{
                            position: 'absolute',
                            right: '5px',
                            width: '20px',
                            height: '20px',
                            background: ticket.status === 'In-progress' ? '#FFB700' : '#FF0000',
                            borderRadius: '50%',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: '3px'
                          }}
                        >!</span>
                      }
                    </td>
                    <td>{ticket.number}</td>
                    <td
                      className={classNames('status',
                        {
                          canceled: ticket.status === 'Canceled',
                          closed: ticket.status === 'Closed',
                          initial: ticket.status !== 'Canceled' && ticket.status !== 'Closed'
                        }
                      )}
                    >
                      {ticket.status}
                    </td>
                    <td>{ticket.type}</td>
                    <td>{ticket.theme}</td>
                    <td>
                      {ticket.author}
                      {
                        reportData.permissions.ticket_show &&
                        showContext === `tickets_${ticket.ticket_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('tickets.item', {
                                companyId: activeAccountId,
                                ticketId: ticket.ticket_id,
                                localInterface: localInterface,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/tickets/${ticket.ticket_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </table>

              {/* Medium desktop table */}
              <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>
                <tr></tr>
                {reportData.tickets.map((ticket, i) => (
                  <tr
                    key={i}
                    style={{ position: 'relative', cursor: reportData.permissions.ticket_show && ticket.ticket_id ? 'pointer' : 'inherit' }}
                    onClick={() =>
                      reportData.permissions.ticket_show &&
                      ticket.ticket_id &&
                      $router.router.navigate('tickets.item', {
                        companyId: activeAccountId,
                        ticketId: ticket.ticket_id,
                        localInterface: localInterface,
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      reportData.permissions.ticket_show &&
                      ticket.ticket_id &&
                      handleChangeMenu(event, ticket.ticket_id, 'tickets', i)}
                  >
                    <td style={{ position: 'relative', paddingRight: '25px' }}>
                      <div>Date: {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticket.date_time_start)}</div>
                      <div>Id: {ticket.number}</div>
                      {
                        !!ticket.is_responsible &&
                        ticket.status !== 'Closed' &&
                        ticket.status !== 'Canceled' &&
                        <span
                          style={{
                            position: 'absolute',
                            right: '5px',
                            width: '20px',
                            height: '20px',
                            background: ticket.status === 'In-progress' ? '#FFB700' : '#FF0000',
                            borderRadius: '50%',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: '3px'
                          }}
                        >!</span>
                      }
                    </td>
                    <td>
                      <div>
                        Status: {' '}
                        <span className={classNames('status',
                          {
                            canceled: ticket.status === 'Canceled',
                            closed: ticket.status === 'Closed',
                            initial: ticket.status !== 'Canceled' && ticket.status !== 'Closed'
                          }
                        )}>
                          {ticket.status}
                        </span>
                      </div>
                      <div>Type: {ticket.type}</div>
                    </td>
                    <td>
                      <div>Theme: {ticket.theme}</div>
                      <div>Author: {ticket.author}</div>
                      {
                        reportData.permissions.ticket_show &&
                        showContext === `tickets_${ticket.ticket_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('tickets.item', {
                                companyId: activeAccountId,
                                ticketId: ticket.ticket_id,
                                localInterface: localInterface,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/tickets/${ticket.ticket_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </table>

              {/* Mobile table items */}
              <div className={classNames('mobile-table-items', '__show-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>

                {reportData.tickets.map((ticket, i: number) => (
                  <div
                    className="item"
                    key={i}
                    onClick={() =>
                      reportData.permissions.ticket_show &&
                      ticket.ticket_id &&
                      $router.router.navigate('tickets.item', {
                        companyId: activeAccountId,
                        ticketId: ticket.ticket_id,
                        localInterface: localInterface,
                      }, { reload: true })}>

                    <div className="__top">
                      <div className="__left">
                        {
                          !!ticket.is_responsible &&
                          ticket.status !== 'Closed' &&
                          ticket.status !== 'Canceled' &&
                          <div>
                            <span
                              style={{
                                marginLeft: '10px',
                                marginBottom: '10px',
                                width: '20px',
                                height: '20px',
                                background: ticket.status === 'In-progress' ? '#FFB700' : '#FF0000',
                                borderRadius: '50%',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >!</span>
                          </div>
                        }
                        <div>
                          <span className="gray">Status: </span>
                          <span
                            className={classNames('status',
                              {
                                canceled: ticket.status === 'Canceled',
                                closed: ticket.status === 'Closed',
                                initial: ticket.status !== 'Canceled' && ticket.status !== 'Closed'
                              }
                            )}>
                            {ticket.status}
                          </span>
                        </div>
                        <div>
                          <span className="gray">Id: </span>
                          <span> {ticket.number}</span>
                        </div>
                      </div>

                      <div className="__right">
                        <span className="gray">Type: </span>
                        <span> {ticket.type}</span>
                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div>
                          Theme: <b>{ticket.theme}</b>
                        </div>
                      </div>

                      <div className="__right small">
                        <div>
                          Date: {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticket.date_time_start)}
                        </div>
                        <div>
                          Author: {ticket.author}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Table controls */}
            <ReportTableControls
              isUnder={true}

              cookie_reportsMaxRows={cookiesSetting?.tickets?.limit_rows}

              onMaxRowsChange={(value) => {
                setCookie('settings', { ...cookiesSetting, tickets: { ...cookiesSetting.tickets, limit_rows: value } })
                $setUpdater(Math.random())
              }}

              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
                $setUpdater(Math.random())
              }}

              amount={{
                total: reportData.interface.rows_all,
                start: reportData.interface.rows_start,
                end: reportData.interface.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}
            />
          </div> :
          null
      }
    </>
  )
}
