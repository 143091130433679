import { memo } from 'react'
import { useRoute } from "react-router5"
import classNames from "classnames"

import Icon from '../Icon'

import { useAppSelector } from "../../store/hooks"
import { formatPhoneNumber } from '../../funcs'

import { CallsDataProps, ClientsProps } from '../../App'
import InfoBlock from './InfoBlock'

interface Props {
  stop: (value: string) => void
  handleAnswer: (value: string) => void
  callData: CallsDataProps
  infoBlockIncoming: string[]
  setInfoBlockIncoming: (value: string[]) => void
}

function IncomingCallItem({
  stop,
  callData,
  handleAnswer,
  infoBlockIncoming,
  setInfoBlockIncoming
}: Props) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  function getClientName(clients: ClientsProps[], current_name: string) {
    let nameString = ''
    if (clients.length || current_name) {
      if (clients.length) {
        if (clients[0].company_name) {
          nameString = `${clients[0].company_name} ${clients[0].first_name ? `(${clients[0].first_name} ${clients[0].last_name})` : ''}`
        } else {
          nameString = `${clients[0].first_name} ${clients[0].last_name}`
        }
      } else {
        nameString = current_name
      }
    } else {
      nameString = 'Unknown'
    }
    return nameString
  }

  function handleInfoBlock(id: string) {
    if (infoBlockIncoming.includes(id)) {
      setInfoBlockIncoming(infoBlockIncoming.filter(item => item !== id))
    } else {
      let updatedInfoBlock = infoBlockIncoming.map(item => item)
      updatedInfoBlock.push(id)
      setInfoBlockIncoming(updatedInfoBlock)
    }
  }

  return (
    <div className='section__account_softphone-content_home-item section__account_softphone-content_home-incomingCall'>
      <div className='section__account_softphone-content_home-item_icon'>
        <Icon viewBox="0 0 16 16" style={{ fill: '#6093DE' }} icon="phone-incoming-1" />

        <p>{callData.current_call.status}</p>
      </div>

      <div className='section__account_softphone-content_home-item_data'>
        <div className='section__account_softphone-content_home-item_text'>
          <div
            className='section__account_softphone-content_home-item_client-name'
            style={{ cursor: callData.permissions.client_show && !!callData.clients.length ? 'pointer' : 'inherit' }}
            onClick={() =>
              callData.permissions.client_show &&
              !!callData.clients.length &&
              $router.router.navigate('clients.item', {
                companyId: activeAccountId, clientId: callData.clients[0].client_id
              }, { reload: true })
            }
          >
            {
              !!callData.clients.length &&
              <div className='section__account_softphone-content_calls-contIcon'>
                <Icon viewBox="0 0 11 13" style={{ fill: '#8E8E93' }} icon="account-1" />
              </div>
            }

            <p>
              {
                getClientName(callData.clients, callData.current_call.caller_name)
              }
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_client-tel'>
            {formatPhoneNumber(callData.current_call.from_phone)}
          </div>

          <div className='section__account_softphone-content_home-item_client-location'>
            <div className='section__account_softphone-content_home-item_location-icon'>
              <Icon viewBox="0 0 6 8" style={{ fill: '#8E8E93' }} icon="location-1" />
            </div>

            <p>
              {/* {call.client_location} */}
              {callData.current_call.caller_city}, {callData.current_call.caller_state} {callData.current_call.caller_zip}
            </p>
          </div>
        </div>

        <div className='section__account_softphone-content_home-item_text'>
          <div className='section__account_softphone-content_home-item_operator-name'>
            {/* {call.operator_name} */}
            {callData.current_call.friendly_name}
          </div>

          <div className='section__account_softphone-content_home-item_operator-tel'>
            {/* {call.operator_tel} */}
            {formatPhoneNumber(callData.current_call.to_phone)}
          </div>

          <div className='section__account_softphone-content_home-item_operator-location'>
            <div className='section__account_softphone-content_home-item_location-icon'>
              <Icon viewBox="0 0 6 8" style={{ fill: '#8E8E93' }} icon="location-1" />
            </div>

            <p>
              {/* {call.operator_location} */}
              {callData.current_call.area}
            </p>
          </div>
        </div>
      </div>

      <div className='section__account_softphone-content_home-item_buttons'>
        <div
          className={classNames("section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-info", {
            inactive: !callData.calls.length
          })}
          style={{ cursor: !callData.calls.length ? 'inherit' : 'pointer' }}
          onClick={() => handleInfoBlock(callData.current_call.caller_sid)}
        >
          <p>i</p>
        </div>

        <div className='section__account_softphone-content_home-item_button-callsBlock'>
          <div
            className='section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-takeCall'
            onClick={() => handleAnswer(callData.current_call.caller_sid)}
          >
            <Icon viewBox="0 0 16 17" style={{ fill: '#ffffff' }} icon="call-1" />
          </div>

          <div
            className='section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-rejectCall'
            onClick={() => stop(callData.current_call.caller_sid)}
          >
            <Icon viewBox="0 0 16 17" style={{ fill: '#ffffff', transform: 'rotate(135deg)' }} icon="call-1" />
          </div>
        </div>
      </div>

      {/* Info Block */}
      {
        infoBlockIncoming.includes(callData.current_call.caller_sid) &&
        <InfoBlock
          calls={callData.calls}
          client_name={getClientName(callData.clients, callData.current_call.caller_name)}
          client_show={callData.permissions.client_show}
          client_id={callData.clients.length ? callData.clients[0].client_id : ''}
        />
      }
    </div >
  )
}

export default memo(IncomingCallItem)