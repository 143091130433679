function SupportPage__Tickets() {

  return (
    <div className="SupportPage__Tickets">
      Tickets page (was not created yet)
    </div>
  )
}

export default SupportPage__Tickets
