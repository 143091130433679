import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment, { Moment } from 'moment'
import qs from 'qs'

// import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import Select from '../../components/Select'
import PreviewInvoicePdf from './PreviewInvoicePdf'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import "../../styles/pages/common/entity-edit.sass"

const data = {
  status_id: 3,
  comment: 'comment',
  service_resource: 'Mike'
}

export interface Invoice {
  status_id: number
  comment: string
  service_resource: string
  data: {
    name: string
    price: number
    type: string
  }[]
  payments: {
    date: Moment
    status: string
    type: string
    number: string
    total: number
  }[]
}

interface InvoiceAddProps {
  switchPage: (name: string) => void
  jobId: string
  client_id: string
  edit: {
    invoice_status: string
    invoice_status_id: number
  }[]
  jobAddress: string
}

export default function InvoiceAdd({ switchPage, jobId, client_id, edit, jobAddress }: InvoiceAddProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)
  const [isPreview, setIsPreview] = useState(false)

  const [newData, setNewData] = useState<Invoice>({
    status_id: 0,
    comment: '',
    service_resource: '',
    data: [{
      name: '',
      price: 0,
      type: 'part',
    }],
    payments: [{
      date: moment(),
      status: '',
      type: '',
      number: '0',
      total: 0,
    }]
  })

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  function handleCancelEdit() {
    setNewData({
      ...data,
      data: [{ name: '', price: 0, type: 'part' }],
      payments: [{
        date: moment(),
        status: '',
        type: '',
        number: '0',
        total: 0,
      }]
    })
  }

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/invoices`, qs.stringify({
      account_id: activeAccountId,
      comment: newData.comment,
      status_id: newData.status_id,
      appointment_id: $router.router.getState().params.jobId,
      job_id: jobId,
      contact_id: client_id,
      total: getTotal(),
      estimate_id: "88888292aaaaaaaaaa"
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChange(value: string | number, name: string, index: number) {
    let updateData = newData.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewData({ ...newData, data: updateData })
  }

  function handleChangePayments(value: string | number | Moment, name: string, index: number) {
    let updateData = newData.payments.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewData({ ...newData, payments: updateData })
  }

  function handleRemoveUnit(index: number) {
    let updateData = newData.data.filter((item, idx) => idx !== index)
    setNewData({ ...newData, data: updateData })
  }

  function handleAddUnit() {
    let updateData = newData.data.map(item => item)
    updateData.push({
      name: '', price: 0, type: 'part'
    })
    setNewData({ ...newData, data: updateData })
  }

  function handleAddPayment() {
    let updateData = newData.payments.map(item => item)
    updateData.push({
      date: moment(),
      status: '',
      type: '',
      number: '0',
      total: 0,
    })
    setNewData({ ...newData, payments: updateData })
  }

  function getTotal() {
    let sum = 0
    newData.data.forEach(item => {
      sum = sum + +item.price
    })
    return sum.toFixed(2)
  }

  function getTotalPayments() {
    let sum = 0
    newData.payments.forEach(item => {
      sum = sum + +item.total
    })
    return sum.toFixed(2)
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newData.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            price: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewData({ ...newData, data: updateData })
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, data: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, data: updateData })
      }
    }
  }

  function handleChangeTotal(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newData.payments.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            total: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewData({ ...newData, payments: updateData })
    }
  }

  function handleChangeTotalBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newData.payments.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, payments: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newData.payments.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, payments: updateData })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  return (
    <>
      {
        isPreview ?
          <PreviewInvoicePdf setIsPreview={setIsPreview} newData={newData} jobAddress={jobAddress} /> :
          <div className="EstimatePage_Edit entity-edit">
            <div className="wrapper flex-container sb">
              <h1>Add new Invoice</h1>

              <div style={{ display: 'flex' }}>
                <button
                  style={{ background: '#ffb700', color: 'white', marginRight: '20px', padding: '2px 15px' }}
                  className="_wa"
                  onClick={() => setIsPreview(true)}
                >
                  <Icon
                    style={{ width: '24px', height: '24px', fill: '#fff' }}
                    viewBox="0 0 1024 1024"
                    icon="preview-pdf-1"
                  />
                </button>

                <button
                  style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
                  className="_wa"
                  onClick={() => switchPage('main')}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>
              </div>
            </div>

            <div className="fieldset">

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Status:</span>
                    {
                      edit &&
                      <Select
                        options={edit.map((option) => ({
                          span: option.invoice_status,
                          value: option.invoice_status_id
                        }))}
                        selectedOption={newData.status_id as number}
                        onChange={(value) => setNewData({ ...newData, status_id: value as number })}
                      />
                    }
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Comment:</span>
                    <textarea
                      style={{ marginRight: '0', marginLeft: '0' }}
                      className='description'
                      name='comment'
                      value={newData.comment || ''}
                      onChange={({ target: { value, name } }) => setNewData({ ...newData, comment: value })}
                    >

                    </textarea>
                  </div>
                </div>
              </div>

              {/* Estimate table */}
              <div className="fields">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      newData.data.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: '55%' }}>
                            <input
                              type="text"
                              value={item.name || ''}
                              onChange={({ target: { value } }) => handleChange(value, 'name', index)}
                            />
                          </td>

                          <td style={{ width: '20%' }}>
                            <Select
                              options={['part', 'labor'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.type as string}
                              onChange={(value) => handleChange(value as string, 'type', index)}
                            />
                          </td>

                          <td style={{ width: '20%' }}>
                            <input
                              style={{ textAlign: 'right' }}
                              type="number"
                              value={item.price.toFixed(2)}
                              onFocus={(event) => handleFocus(event)}
                              onChange={(event) => handleChangePrice(event, index)}
                              onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                            />
                          </td>

                          <td style={{ width: '5%' }}>
                            <button
                              onClick={() => handleRemoveUnit(index)}
                              style={{ background: 'inherit', width: 'fit-content', padding: '0', margin: '0 auto' }}
                            >
                              <Icon
                                style={{ fill: '#FF0000', width: '15px', height: '15px' }}
                                icon="x-mark-1"
                              />
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="field">
                <div style={{ marginTop: '10px' }} className='add-button'>
                  <button
                    className='_green'
                    onClick={() => handleAddUnit()}
                  >Add</button>
                </div>
              </div>

              <div style={{ flexWrap: 'nowrap' }} className="fields">
                <div style={{ width: '50%' }} ></div>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>Total:</span>
                  <input
                    style={{ marginRight: '20px', textAlign: 'right' }}
                    type="text"
                    value={getTotal()}
                    disabled={true}
                  />
                </div>
              </div>

              {/* Payments table */}
              <div className="fields">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Number</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      newData.payments.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: 'calc(100% / 5)' }}>
                            <Datetime
                              value={item.date}
                              onChange={(value) => handleChangePayments(value, 'date', index)} />

                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <Select
                              options={['Pending', 'Complete'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.status as string}
                              onChange={(value) => handleChangePayments(value as string, 'status', index)}
                            />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <Select
                              options={['Card', 'Cash', 'Check'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.type as string}
                              onChange={(value) => handleChangePayments(value as string, 'type', index)}
                            />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <input
                              type="number"
                              value={item.number || ''}
                              onChange={({ target: { value } }) => handleChangePayments(value, 'number', index)}
                            />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <input
                              style={{ textAlign: 'right' }}
                              type="number"
                              value={item.total.toFixed(2)}
                              onFocus={(event) => handleFocus(event)}
                              onChange={(event) => handleChangeTotal(event, index)}
                              onKeyDown={(event) => handleChangeTotalBackspace(event, index)}
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="field">
                <div style={{ marginTop: '10px' }} className='add-button'>
                  <button
                    className='_green'
                    onClick={() => handleAddPayment()}
                  >Add</button>
                </div>
              </div>

              <div style={{ flexWrap: 'nowrap' }} className="fields">
                <div style={{ width: '50%' }} ></div>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>Total:</span>
                  <input
                    style={{ marginRight: '20px', textAlign: 'right' }}
                    type="text"
                    value={getTotalPayments()}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="wrapper flex-container sb editing-buttons">
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
      }
    </>
  )
}
