import { useEffect, useState, useRef } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'
import ReactInputMask from 'react-input-mask'
import classNames from "classnames"

import Icon from '../../components/Icon'
import StripeComponent from './StripeComponent'
import Select from '../../components/Select'
import Checkbox from '../../components/Checkbox'
import SquareComponent from './SquareComponent'

import {
  formatPhoneNumber,
  getName,
  httpClientUpdate,
  nErrorUpdate,
  validateEmail,
  formatPhoneNumberToServerString,
} from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import '../../styles/pages/AppointmentSchedule.sass'
import './Stripe.sass'

interface HttpClientCallTypeReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallTypeProps
}

interface CallTypeProps {
  permissions: {
    payment_square_make: boolean
    payment_stripe_make: boolean
  }
  jobs: {
    company_name: string;
    company_id: string;
    first_name: string;
    last_name: string;
    client_name: string;
    job_id: string;
    job_name: string;
    contact_phones: {
      name: string;
      phone: string;
    }[];
    appointments: {
      address?: string;
      appointment_id: string;
      appointment_end: string;
      appointment_name: string;
      appointment_start: string;
      appointment_type: string;
      note: string;
      non_schedule: number;
      service_resource_code: number;
      service_resource_name: string;
      service_resource_id?: string;
      service_resource_time_zone: string;
      status: string;
      status_id: number;
      units: {
        appliance: string;
        brand: string;
        other_appliance: string;
        other_brand: string;
        unit_type: string;
      }[];
    }[];
    units: {
      appliance: string;
      brand: string;
      other_appliance: string;
      other_brand: string;
      unit_type: string;
    }[];
    invoices: {
      area: string;
      created_at: string;
      invoice_id: string;
      name: string;
      paid: number;
      service_resource: string;
      service_resource_id: string;
      service_resource_code: number;
      status: string;
      total: number;
      unpaid: number;
    }[];
    client: {
      client_id: string;
      company_name: string;
      first_name: string;
      last_name: string;
      main_phone: string;
    };
    service_resources: {
      code: number;
      nickname: string;
      service_resource_id: string;
    }[];
  }[];
}

interface GetTokenForStripeProps {
  data: {
    stripe_pk: string
    stripe_fee_compensation: string
    permissions: {
      can_be_canceled_payment_fee_compensation: boolean
    }
  }
  success: boolean
  error: {
    code: number,
    message: string
  },
}

interface GetTokenForSquareProps {
  data: {
    square_payment_application_id: string
    square_payment_location_id: string
    square_payment_location_name: string
    square_fee_compensation: string
    permissions: {
      can_be_canceled_payment_fee_compensation: boolean
    }
  }
  success: boolean
  error: {
    code: number,
    message: string
  },
}

interface InvoiceProps {
  job_id: string,
  job_name: string,
  client_id: string,
  first_name: string,
  last_name: string,
  service_resource_id: string,
  invoice_id: string | null,
  name: string,
  total: number,
  note?: string,
  id: string,
  service_resurce: string,
  serviceOptions: {
    code: number;
    nickname: string;
    service_resource_id: string;
  }[],
  isSelect?: boolean
}

interface SaveReport {
  data: {
    email_notification_details: {
      notify_subject: string
      notify_text: string
    }
    message: string
    payment_id: string
    phone_notification_details: {
      notify_text: string
    }
  }
  success: boolean,
  error: {
    code: number,
    message: string
  },
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const pageRef = useRef(null)

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [appointmentSearch, setAppointmentSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [appointmentSearchData, setAppointmentSearchData] = useState<CallTypeProps | null>(null)
  const [company_id, setCompany_id] = useState('')

  const [isNothingFound, setIsNothingFound] = useState(false)

  const [paymentMethodOptions, setPaymentMethodOptions] = useState<string[]>([])

  const [sending, setSending] = useState({
    phone: false,
    email: false
  })

  const [paymentMethod, setPaymentMethod] = useState('')
  const [feeCompensation, setFeeCompensation] = useState(0)

  const [showEmailDetails, setShowEmailDetails] = useState(false)
  const [showPhoneDetails, setShowPhoneDetails] = useState(false)

  const [tokenStripe, setTokenStripe] = useState('')
  const [tokenSquare, setTokenSquare] = useState({
    square_payment_application_id: '',
    square_payment_location_id: '',
    square_payment_location_name: '',
  })
  const [canBeCanceledPaymentFeeCompensation, setCanBeCanceledPaymentFeeCompensation] = useState({
    permission: false,
    value: true,
  })
  const [apiToken, setApiToken] = useState('')

  const [errorService, setErrorService] = useState(false)
  const [errorPayment, setErrorPayment] = useState(false)

  const [saveData, setSaveData] = useState({
    isSendCheck: false,
    message: '',
    email_notification_details: {
      notify_subject: '',
      notify_text: '',
    },
    phone_notification_details: {
      notify_text: ''
    },
    payment_id: '',
    email: '',
    is_repeated_email: false,
    is_repeated_phone: false,
    phone: '',
    isErrorSendEmail: false,
    isErrorSendPhone: false,
    message_email: '',
    message_phone: '',
    isSendEmail: false,
    isSendPhone: false,
  })

  const [invoicesSelected, setInvoicesSelected] = useState<InvoiceProps[]>([])

  useEffect(() => {
    setLoading(false)
    setSaveData({
      isSendCheck: false,
      message: '',
      email_notification_details: {
        notify_subject: '',
        notify_text: '',
      },
      phone_notification_details: {
        notify_text: ''
      },
      payment_id: '',
      is_repeated_email: false,
      is_repeated_phone: false,
      email: '',
      phone: '',
      isErrorSendEmail: false,
      isErrorSendPhone: false,
      message_email: '',
      message_phone: '',
      isSendEmail: false,
      isSendPhone: false,
    })
    setInvoicesSelected([])
    setAppointmentSearchData(null)
    setCompany_id('')
    setApiToken('')
    setAppointmentSearch('')
    setErrorService(false)
    setShowEmailDetails(false)
    setShowPhoneDetails(false)
  }, [updated])

  function handleCloseSendInfo() {
    setSaveData({
      isSendCheck: false,
      message: '',
      email_notification_details: {
        notify_subject: '',
        notify_text: '',
      },
      phone_notification_details: {
        notify_text: ''
      },
      payment_id: '',
      is_repeated_email: false,
      is_repeated_phone: false,
      email: '',
      phone: '',
      isErrorSendEmail: false,
      isErrorSendPhone: false,
      message_email: '',
      message_phone: '',
      isSendEmail: false,
      isSendPhone: false,
    })
    setInvoicesSelected([])
    setAppointmentSearchData(null)
    setCompany_id('')
    setApiToken('')
    setAppointmentSearch('')
    setPaymentMethod('')
    setShowEmailDetails(false)
    setShowPhoneDetails(false)
  }

  async function handleSendEmail() {
    setSending({
      ...sending,
      email: true
    })
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${saveData.payment_id}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: saveData.email,
        type: 'email',
        is_repeated: saveData.is_repeated_email ? 1 : null
      }, { skipNulls: true })) as { data: SaveReport }

      if (success) {
        setSending({
          ...sending,
          email: false
        })
        setSaveData({
          ...saveData,
          isErrorSendEmail: false,
          message_email: 'Success send',
          isSendEmail: true,
        })
        setInvoicesSelected([])
        setAppointmentSearchData(null)
        setCompany_id('')
        setApiToken('')
        setAppointmentSearch('')
      } else {
        setSending({
          ...sending,
          email: false
        })
        setSaveData({
          ...saveData,
          isErrorSendEmail: true,
          message_email: 'The email has not been sent, try again later or change the email',
          isSendEmail: true,
        })
        setInvoicesSelected([])
        setAppointmentSearchData(null)
        setCompany_id('')
        setApiToken('')
        setAppointmentSearch('')
      }
    } catch (error) {
      setSending({
        ...sending,
        email: false
      })
      setSaveData({
        ...saveData,
        isErrorSendEmail: true,
        message_email: 'The email has not been sent, try again later or change the email',
        isSendEmail: true,
      })
      setInvoicesSelected([])
      setAppointmentSearchData(null)
      setCompany_id('')
      setApiToken('')
      setAppointmentSearch('')
    }
  }

  async function handleSendPhone() {
    setSending({
      ...sending,
      phone: true
    })
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${saveData.payment_id}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: formatPhoneNumberToServerString(saveData.phone),
        type: 'phone',
        is_repeated: saveData.is_repeated_phone ? 1 : null
      }, { skipNulls: true })) as { data: SaveReport }

      if (success) {
        setSending({
          ...sending,
          phone: false
        })
        setSaveData({
          ...saveData,
          isErrorSendPhone: false,
          message_phone: 'Success send',
          isSendPhone: true,
        })
        setInvoicesSelected([])
        setAppointmentSearchData(null)
        setCompany_id('')
        setApiToken('')
        setAppointmentSearch('')
      } else {
        setSending({
          ...sending,
          phone: false
        })
        setSaveData({
          ...saveData,
          isErrorSendPhone: true,
          message_phone: 'The receipt has not been sent, try again later or check the phone number',
          isSendPhone: true,
        })
        setInvoicesSelected([])
        setAppointmentSearchData(null)
        setCompany_id('')
        setApiToken('')
        setAppointmentSearch('')
      }
    } catch (error) {
      setSending({
        ...sending,
        phone: false
      })
      setSaveData({
        ...saveData,
        isErrorSendPhone: true,
        message_phone: 'The receipt has not been sent, try again later or check the phone number',
        isSendPhone: true,
      })
      setInvoicesSelected([])
      setAppointmentSearchData(null)
      setCompany_id('')
      setApiToken('')
      setAppointmentSearch('')
    }
  }

  //  search input in callType component
  async function handleSearchAppointment(search?: string) {
    setLoading(true)
    isNothingFound && setIsNothingFound(false)
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: searchData, success, error } } = await httpClientUpdate.get('/bookings/client-search', {
        params: {
          account_id: activeAccountId,
          key_words: search ? search : appointmentSearch,
          page: 1,
          limit_rows: 100,
          invoices: 1,
        }
      }) as { data: HttpClientCallTypeReport }
      if (success) {
        setLoading(false)
        setAppointmentSearchData(searchData)
        if (searchData.permissions.payment_square_make && searchData.permissions.payment_stripe_make) {
          setPaymentMethodOptions(['', 'Stripe', 'Square'])
        } else if (searchData.permissions.payment_square_make && !searchData.permissions.payment_stripe_make) {
          setPaymentMethodOptions(['', 'Square'])
        } else if (!searchData.permissions.payment_square_make && searchData.permissions.payment_stripe_make) {
          setPaymentMethodOptions(['', 'Stripe'])
        } else {
          setPaymentMethodOptions([''])
        }
        if (searchData.jobs.length === 0) {
          setIsNothingFound(true)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
        setLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
      setLoading(false)
    }
  }

  async function getTokenForStripe() {
    try {
      // https://2022back4.artemiudintsev.com/api/accounts/stripe?account_id=88888
      const { data: { data: token, success, error } } = (await httpClientUpdate.get(`/accounts/stripe`, {
        params: {
          account_id: activeAccountId,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: GetTokenForStripeProps }
      if (success) {
        setTokenStripe(token.stripe_pk)
        setFeeCompensation(+token.stripe_fee_compensation)
        setCanBeCanceledPaymentFeeCompensation({
          permission: token.permissions.can_be_canceled_payment_fee_compensation,
          value: true,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      // let createdError = nErrorUpdate(error)
      // $router.router.navigate(`${createdError.content.code}`, {
      //   reload: true
      // })
    }
  }

  async function getTokenForSquare() {
    try {
      // https://2022back4.artemiudintsev.com/api/accounts/square?account_id=88888
      const { data: { data: token, success, error } } = (await httpClientUpdate.get(`/accounts/square`, {
        params: {
          account_id: activeAccountId,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: GetTokenForSquareProps }
      if (success) {
        setTokenSquare(token)
        setFeeCompensation(+token.square_fee_compensation)
        setCanBeCanceledPaymentFeeCompensation({
          permission: token.permissions.can_be_canceled_payment_fee_compensation,
          value: true,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      // let createdError = nErrorUpdate(error)
      // $router.router.navigate(`${createdError.content.code}`, {
      //   reload: true
      // })
    }
  }

  async function handleSave() {
    setLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/payments/pay
      const { data: { data: reportData, success, error } } = await httpClientUpdate.post(`/payments/pay/stripe`, qs.stringify({
        account_id: activeAccountId,
        stripe_token: apiToken,
        company_id: company_id,
        fee_compensation: canBeCanceledPaymentFeeCompensation.value ? processingFee() : '0.00',
        items: invoicesSelected.map(item => {
          return {
            job_id: item.job_id,
            invoice_id: item.invoice_id,
            contact_id: item.client_id,
            total_item: item.total,
            service_resource_id: item.service_resource_id,
            note: item.note,
          }
        }),
      }, { skipNulls: true })) as { data: SaveReport }
      if (success) {
        setSaveData({
          isSendCheck: true,
          email: '',
          phone: '',
          is_repeated_email: false,
          is_repeated_phone: false,
          ...reportData,
          isErrorSendEmail: false,
          isErrorSendPhone: false,
          message_email: '',
          message_phone: '',
          isSendEmail: false,
          isSendPhone: false,
        })
        setCanBeCanceledPaymentFeeCompensation({
          permission: false,
          value: true,
        })
        setPaymentMethod('')
        setTokenStripe('')
        setTokenSquare({
          square_payment_application_id: '',
          square_payment_location_id: '',
          square_payment_location_name: '',
        })
        setFeeCompensation(0)
        setLoading(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      setLoading(false)
      setPaymentMethod('')
      setTokenStripe('')
      setTokenSquare({
        square_payment_application_id: '',
        square_payment_location_id: '',
        square_payment_location_name: '',
      })
      setCanBeCanceledPaymentFeeCompensation({
        permission: false,
        value: true,
      })
      setFeeCompensation(0)
      setSaveData({
        isSendCheck: false,
        message: '',
        email_notification_details: {
          notify_subject: '',
          notify_text: '',
        },
        phone_notification_details: {
          notify_text: ''
        },
        payment_id: '',
        is_repeated_email: false,
        is_repeated_phone: false,
        email: '',
        phone: '',
        isErrorSendEmail: false,
        isErrorSendPhone: false,
        message_email: '',
        message_phone: '',
        isSendEmail: false,
        isSendPhone: false,
      })
    }
  }

  async function handlePaySquare(token: string) {
    setLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/payments/pay/square
      const { data: { data: reportData, success, error } } = await httpClientUpdate.post(`/payments/pay/square`, qs.stringify({
        account_id: activeAccountId,
        square_token: token,
        location_id: tokenSquare.square_payment_location_id,
        location_name: tokenSquare.square_payment_location_name,
        company_id: company_id,
        fee_compensation: canBeCanceledPaymentFeeCompensation.value ? processingFee() : '0.00',
        items: invoicesSelected.map(item => {
          return {
            job_id: item.job_id,
            invoice_id: item.invoice_id,
            contact_id: item.client_id,
            total_item: item.total,
            service_resource_id: item.service_resource_id,
            note: item.note,
          }
        }),
      }, { skipNulls: true })) as { data: SaveReport }
      if (success) {
        setSaveData({
          isSendCheck: true,
          email: '',
          phone: '',
          is_repeated_email: false,
          is_repeated_phone: false,
          ...reportData,
          isErrorSendEmail: false,
          isErrorSendPhone: false,
          message_email: '',
          message_phone: '',
          isSendEmail: false,
          isSendPhone: false,
        })
        setPaymentMethod('')
        setTokenSquare({
          square_payment_application_id: '',
          square_payment_location_id: '',
          square_payment_location_name: '',
        })
        setFeeCompensation(0)
        setLoading(false)
        setCanBeCanceledPaymentFeeCompensation({
          permission: false,
          value: true,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      setLoading(false)
      setPaymentMethod('')
      setTokenSquare({
        square_payment_application_id: '',
        square_payment_location_id: '',
        square_payment_location_name: '',
      })
      setFeeCompensation(0)
      setCanBeCanceledPaymentFeeCompensation({
        permission: false,
        value: true,
      })
      setSaveData({
        isSendCheck: false,
        message: '',
        email_notification_details: {
          notify_subject: '',
          notify_text: '',
        },
        phone_notification_details: {
          notify_text: ''
        },
        payment_id: '',
        is_repeated_email: false,
        is_repeated_phone: false,
        email: '',
        phone: '',
        isErrorSendEmail: false,
        isErrorSendPhone: false,
        message_email: '',
        message_phone: '',
        isSendEmail: false,
        isSendPhone: false,
      })
    }
  }

  useEffect(() => {
    if (paymentMethod === 'Stripe') {
      getTokenForStripe()
    } else if (paymentMethod === 'Square') {
      getTokenForSquare()
    } else if (paymentMethod === '') {
      setCanBeCanceledPaymentFeeCompensation({
        permission: false,
        value: true,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  useEffect(() => {
    if (apiToken) {
      handleSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiToken])

  useEffect(() => {
    if (!checkServiceEmpty()) {
      setErrorService(false)
    }

    errorPayment && setErrorPayment(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesSelected])

  useEffect(() => {

    if (!!paymentMethod && pageRef.current && (!!tokenStripe || !!tokenSquare.square_payment_application_id)) {
      // @ts-ignore
      pageRef.current.scrollTop = pageRef.current.scrollHeight
    }

  }, [paymentMethod, pageRef, tokenStripe, tokenSquare.square_payment_application_id])

  function handleSelectedInvoices(data: InvoiceProps, name: string) {
    let invoices = invoicesSelected.map(item => item.id)
    if (name === 'invoice') {
      if (!invoices.includes(data.id)) {
        let updatedSelected = invoicesSelected.map(item => item)
        updatedSelected.push(data)

        setInvoicesSelected(updatedSelected)
      }
    } else {
      if (!invoices.includes(data.id)) {
        let updatedSelected = invoicesSelected.map(item => item)

        let updatedData = { ...data }

        if (data.serviceOptions.length === 1) {
          updatedData.service_resource_id = data.serviceOptions[0].service_resource_id
          updatedData.service_resurce = data.serviceOptions[0].code ? `${data.serviceOptions[0].nickname} (${data.serviceOptions[0].code})` : data.serviceOptions[0].nickname
        } else {
          updatedData.isSelect = true
        }

        updatedSelected.push({
          ...updatedData,
        })

        setInvoicesSelected(updatedSelected)
      }
    }
  }

  function invoicesSum() {
    let sum = 0.00
    invoicesSelected.forEach(item => {
      sum = sum + +item.total
    })


    return sum.toFixed(2)
  }

  function processingFee() {
    let sum = 0
    let total = 0

    invoicesSelected.forEach(item => {
      sum = sum + +item.total
    })

    if (sum > 0) {
      total = ((sum / 100) * feeCompensation)
    }

    return total.toFixed(2)
  }

  function totalIncludeFee() {
    let sum = 0
    let total = 0

    invoicesSelected.forEach(item => {
      sum = sum + +item.total
    })

    if (sum > 0 && canBeCanceledPaymentFeeCompensation.value) {
      total = ((sum / 100) * feeCompensation)
    }

    sum = sum + total

    return sum.toFixed(2)
  }

  function handleCancel() {
    setInvoicesSelected([])
    setPaymentMethod('')
    setFeeCompensation(0)
  }

  function handleChangeInvoices(index: number, value: string, name: string) {
    let invoices = invoicesSelected.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })

    setInvoicesSelected(invoices)
  }

  function handleRemove(id: string) {
    let updatedInvoices = invoicesSelected.filter(item => item.id !== id)
    if (updatedInvoices.length === 0) {
      setCompany_id('')
      setPaymentMethod('')
      setFeeCompensation(0)
    }
    setInvoicesSelected(updatedInvoices)
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let invoices = invoicesSelected.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            total: +newNumber
          }
        } else {
          return { ...item }
        }
      })

      setInvoicesSelected(invoices)
    } else if (price.length === 1) {
      let newNumber = `0.0${price}`
      let invoices = invoicesSelected.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            total: +newNumber
          }
        } else {
          return { ...item }
        }
      })

      setInvoicesSelected(invoices)
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {

        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = invoicesSelected.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })

        setInvoicesSelected(updateData)
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = invoicesSelected.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setInvoicesSelected(updateData)
      }
    }
  }

  function handleChangeService(index: number, id: string, span: string) {
    let invoices = invoicesSelected.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          service_resource_id: id,
          service_resurce: span
        }
      } else {
        return { ...item }
      }
    })

    setInvoicesSelected(invoices)
  }

  function checkServiceEmpty() {
    let check = false

    invoicesSelected.forEach(item => {
      if (item.service_resource_id === '') {
        check = true
      }
    })

    return check
  }

  return (
    <div
      style={{ overflow: 'auto' }}
      className='appointment_schedule_page payment-terminal'
      ref={pageRef}
    >
      {
        saveData.isSendCheck ?
          <div className='send-check'>
            {
              <div className='send-check-content'>
                <p style={{ fontWeight: '700', fontSize: '18px' }}>
                  Payment was successful. {saveData.email_notification_details.notify_text || saveData.phone_notification_details.notify_text ? 'Send a receipt?' : ''}
                </p>

                {
                  saveData.email_notification_details.notify_text &&
                  <button
                    className={classNames('_bordered', '_blue', {
                      __show: showEmailDetails
                    })}
                    style={{ width: 'fit-content' }}
                    onClick={() => setShowEmailDetails(!showEmailDetails)}
                  >
                    Send by Email
                  </button>
                }

                {
                  showEmailDetails &&
                  <>
                    {
                      saveData.isErrorSendEmail && saveData.isErrorSendEmail &&
                      <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                        {saveData.message_email}
                      </p>
                    }

                    {
                      saveData.isSendEmail && !saveData.isErrorSendEmail &&
                      <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {saveData.message_email} </p>
                    }

                    <div className='email-label'>
                      <span>Email: </span>
                      <input
                        type="text"
                        value={saveData.email}
                        onChange={(event) => setSaveData({ ...saveData, email: event.target.value })}
                      />
                    </div>

                    <div className='email-label'>
                      <span> Subject: </span>

                      <span className='span-input'>
                        {saveData.email_notification_details.notify_subject}
                      </span>
                    </div>

                    <div className='email-label'>
                      <div
                        className='text-block'
                      >
                        <div dangerouslySetInnerHTML={{ __html: saveData.email_notification_details.notify_text ? saveData.email_notification_details.notify_text : '' }}></div>
                      </div>
                    </div>

                    <div className='btns'>
                      <div
                        className={classNames({
                          __is_repeated: !saveData.isSendEmail
                        })}
                      >
                        <Checkbox
                          contents='Resend?'
                          disabled={!saveData.isSendEmail}
                          value={saveData.is_repeated_email}
                          onChange={(value) => setSaveData({ ...saveData, is_repeated_email: value })}
                        />
                      </div>

                      <button
                        className="_bordered _green"
                        style={{ whiteSpace: 'nowrap' }}
                        disabled={sending.email || saveData.email === '' || !validateEmail(saveData.email) || (saveData.isSendEmail && !saveData.is_repeated_email)}
                        onClick={handleSendEmail}
                      >
                        Send
                      </button>
                    </div>
                  </>
                }

                {
                  saveData.phone_notification_details.notify_text &&
                  <button
                    style={{ marginTop: '10px', width: 'fit-content' }}
                    className={classNames('_bordered', '_blue', {
                      __show: showPhoneDetails
                    })}
                    onClick={() => setShowPhoneDetails(!showPhoneDetails)}
                  >
                    Send by SMS
                  </button>
                }

                {
                  showPhoneDetails &&
                  <>
                    {
                      saveData.isSendPhone && saveData.isErrorSendPhone &&
                      <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                        {saveData.message_phone}
                      </p>
                    }

                    {
                      saveData.isSendPhone && !saveData.isErrorSendPhone &&
                      <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {saveData.message_phone} </p>
                    }

                    <div className='email-label'>
                      <span>Phone: </span>

                      <ReactInputMask
                        type="text"
                        mask="+1 (999) 999-9999"
                        value={saveData.phone}
                        onChange={({ target: { value } }) => setSaveData({ ...saveData, phone: value })}
                      />
                    </div>

                    <div className='email-label'>
                      <div
                        className='text-block'
                      >
                        <div dangerouslySetInnerHTML={{ __html: saveData.phone_notification_details.notify_text ? saveData.phone_notification_details.notify_text : '' }}></div>
                      </div>
                    </div>

                    <div className='btns'>
                      <div
                        className={classNames({
                          __is_repeated: !saveData.isSendPhone
                        })}
                      >
                        <Checkbox
                          contents='Resend?'
                          disabled={!saveData.isSendPhone}
                          value={saveData.is_repeated_phone}
                          onChange={(value) => setSaveData({ ...saveData, is_repeated_phone: value })}
                        />
                      </div>

                      <button
                        className="_bordered _green"
                        style={{ whiteSpace: 'nowrap' }}
                        disabled={sending.phone || formatPhoneNumberToServerString(saveData.phone).length !== 12 || (saveData.isSendPhone && !saveData.is_repeated_phone)}
                        onClick={handleSendPhone}
                      >
                        Send
                      </button>
                    </div>
                  </>
                }

                <div className='btns'>
                  <button
                    className="_bordered _red"
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={handleCloseSendInfo}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          </div> :
          <div className='center AppointmentsPage_Item entity-edit'>
            <div className='search'>
              <div className='call-variant_search-form'>
                <input
                  value={appointmentSearch}
                  className='text-field data-border call-variant_search-input'
                  type="text"
                  onChange={(event) => {
                    setAppointmentSearch(event.target.value)
                  }}
                />

                <button
                  disabled={appointmentSearch === '' || loading}
                  className='btn call-variant_search-button'
                  style={{ marginRight: '5px' }}
                  onClick={() => !loading && handleSearchAppointment()}
                >
                  Search
                </button>
              </div>

              {
                isNothingFound &&
                <p style={{ color: '#eb5757', fontSize: '13px' }}> Nothing was found! </p>
              }

              {
                appointmentSearchData && appointmentSearchData.jobs.map((job, index) => (
                  <div
                    key={index}
                    className='call-variant_search-type'
                  >
                    <div className='call-variant_search-type_data'>
                      <div
                        className='job-info'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <p
                          className='call-variant_search-type_id'
                          style={{ marginBottom: 0, width: '100%' }}
                        >
                          Job#{job.job_name} - {getName(job.company_name, job.first_name, job.last_name)} - {job.contact_phones.map(item => `${formatPhoneNumber(item.phone)} (${item.name})`).join(', ')}
                        </p>

                        <button
                          className='_zeroed _iconed'
                          style={{ width: '16px', height: '16px', marginRight: '5px' }}
                          onClick={() => {
                            company_id === '' && setCompany_id(job.company_id)
                            handleSelectedInvoices({
                              job_id: job.job_id,
                              job_name: job.job_name,
                              client_id: job.client.client_id,
                              first_name: job.first_name,
                              last_name: job.last_name,
                              invoice_id: null, name: '',
                              total: 0,
                              service_resource_id: '',
                              id: `${job.job_id}_${index}`,
                              service_resurce: '',
                              serviceOptions: job.service_resources,
                            }, 'job')
                          }}
                        >
                          <Icon style={{
                            fill: invoicesSelected.length && invoicesSelected.map(item => item.id).includes(`${job.job_id}_${index}`) ? 'rgb(1, 180, 31)' : 'inherit',
                            transform: 'rotate(90deg)',
                            width: '16px'
                          }} icon="arrow-20" />
                        </button>
                      </div>

                      {
                        !!job.invoices.length &&
                        <div className='appointment-info'>
                          {
                            job.invoices.map((invoice, idx) => (
                              <div
                                key={idx}
                                className="appointment-info-item"
                              >
                                <div
                                  style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                  className='call-variant_search-type_product'
                                >
                                  <p style={{ fontSize: '13px' }}>
                                    {invoice.name}: <span style={{ color: invoice.status === 'Canceled' ? '#FF0000' : invoice.status === 'Closed' ? '#01b41f' : 'inherit' }}> {invoice.status} </span> -
                                    {' '}{invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource} -
                                    {' '}Total: {invoice.total} -
                                    {' '}Paid: {invoice.paid} -
                                    {' '}Unpaid: {invoice.unpaid}
                                  </p>
                                  <div className='call-variant_search-type_radiobtn'>
                                    <div className='radio'>

                                      <button
                                        className='_zeroed _iconed'
                                        style={{ width: '16px', height: '16px' }}
                                        onClick={() => {
                                          company_id === '' &&
                                            setCompany_id(job.company_id)
                                          handleSelectedInvoices({
                                            job_id: job.job_id,
                                            job_name: job.job_name,
                                            client_id: job.client.client_id,
                                            first_name: job.first_name,
                                            last_name: job.last_name,
                                            invoice_id: invoice.invoice_id,
                                            name: invoice.name,
                                            total: invoice.unpaid && invoice.unpaid > 0 ? invoice.unpaid : 0,
                                            service_resource_id: invoice.service_resource_id, id: `${job.job_id}_${invoice.invoice_id}`,
                                            service_resurce: invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource,
                                            serviceOptions: job.service_resources,
                                          }, 'invoice')
                                        }}
                                      >
                                        <Icon style={{
                                          fill: invoicesSelected.length && invoicesSelected.map(item => item.id).includes(`${job.job_id}_${invoice.invoice_id}`) ? 'rgb(1, 180, 31)' : 'inherit',
                                          transform: 'rotate(90deg)',
                                          width: '16px'
                                        }} icon="arrow-20" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      }
                    </div>
                  </div>
                ))
              }
            </div>

            {
              !!invoicesSelected.length &&
              <div className="fieldset payment-table">
                <div className="block-content-title">
                  <div className="block-title">
                    Payment
                  </div>
                </div>

                <table
                  className={classNames('table', '__show-on-wide',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Job</th>
                      <th>Invoice</th>
                      <th>Service Resource</th>
                      <th>Note</th>
                      <th>Amount</th>
                      <th>Delete</th>
                    </tr>
                  </thead>

                  {
                    invoicesSelected.map((item, index) => (
                      <tr key={index}>
                        <td> {item.first_name} {item.last_name} </td>
                        <td> {item.job_name} </td>
                        <td> {item.name} </td>
                        <td
                          style={{ padding: item.isSelect ? '2.5px 5px' : '5px 10px' }}
                        >
                          {
                            item.isSelect ?
                              <Select
                                error={errorService && item.service_resource_id === ''}
                                options={item.serviceOptions.map((option) => ({
                                  span: option.code ? `${option.nickname} (${option.code})` : option.nickname,
                                  value: option.service_resource_id
                                }))}
                                selectedOption={item.service_resource_id}
                                onChange={(value, span) => handleChangeService(index, value as string, span)}
                              /> :
                              item.service_resurce
                          }
                        </td>
                        <td>
                          <input
                            style={{ border: 'none' }}
                            type="text"
                            value={item.note}
                            onChange={(event) => handleChangeInvoices(index, event?.target.value, 'note')}
                          />
                        </td>
                        <td>
                          <input
                            className='amount-field'
                            style={{ border: 'none', color: errorPayment ? '#FF0000' : 'inherit' }}
                            type="number"
                            value={item.total.toFixed(2)}
                            onFocus={(event) => handleFocus(event)}
                            onChange={(event) => handleChangePrice(event, index)}
                            onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                          />
                        </td>
                        <td>
                          <button
                            className='_zeroed _iconed _red'
                            onClick={() => {
                              handleRemove(item.id)
                            }}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>

                <table
                  className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Job</th>
                      <th>Invoice</th>
                      <th>Service Resource</th>
                      <th>Note</th>
                      <th>Amount</th>
                      <th>Delete</th>
                    </tr>
                  </thead>

                  {
                    invoicesSelected.map((item, index) => (
                      <tr key={index}>
                        <td> {item.first_name} {item.last_name} </td>
                        <td> {item.job_name} </td>
                        <td> {item.name} </td>
                        <td
                          style={{ padding: item.isSelect ? '2.5px 5px' : '5px 10px' }}
                        >
                          {
                            item.isSelect ?
                              <Select
                                error={errorService && item.service_resource_id === ''}
                                options={item.serviceOptions.map((option) => ({
                                  span: option.code ? `${option.nickname} (${option.code})` : option.nickname,
                                  value: option.service_resource_id
                                }))}
                                selectedOption={item.service_resource_id}
                                onChange={(value, span) => handleChangeService(index, value as string, span)}
                              /> :
                              item.service_resurce
                          }
                        </td>
                        <td>
                          <input
                            style={{ border: 'none' }}
                            type="text"
                            value={item.note}
                            onChange={(event) => handleChangeInvoices(index, event?.target.value, 'note')}
                          />
                        </td>
                        <td>
                          <input
                            className='amount-field'
                            style={{ border: 'none', color: errorPayment ? '#FF0000' : 'inherit' }}
                            type="number"
                            value={item.total.toFixed(2)}
                            onFocus={(event) => handleFocus(event)}
                            onChange={(event) => handleChangePrice(event, index)}
                            onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                          />
                        </td>
                        <td>
                          <button
                            className='_zeroed _iconed _red'
                            onClick={() => {
                              handleRemove(item.id)
                            }}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>

                <table
                  className={classNames('table', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                >
                  <thead>
                    <tr>
                      <th>
                        <div>Client /</div>
                        <div>Job</div>
                      </th>

                      <th>
                        <div>Invoice /</div>
                        <div>Service Resource</div>
                      </th>

                      <th>
                        <div>Note /</div>
                        <div>Amount</div>
                      </th>

                      <th>Delete</th>
                    </tr>
                  </thead>

                  {
                    invoicesSelected.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div style={{ marginBottom: '5px' }}> {item.first_name} {item.last_name} </div>
                          <div> {item.job_name}</div>

                        </td>

                        <td>
                          <div style={{ marginBottom: '5px' }}>{item.name}</div>
                          <div>
                            {
                              item.isSelect ?
                                <Select
                                  error={errorService && item.service_resource_id === ''}
                                  options={item.serviceOptions.map((option) => ({
                                    span: option.code ? `${option.nickname} (${option.code})` : option.nickname,
                                    value: option.service_resource_id
                                  }))}
                                  selectedOption={item.service_resource_id}
                                  onChange={(value, span) => handleChangeService(index, value as string, span)}
                                /> :
                                item.service_resurce
                            }
                          </div>
                        </td>

                        <td className='td-inputs'>
                          <div style={{ marginBottom: '5px' }}>
                            <input
                              type="text"
                              value={item.note}
                              onChange={(event) => handleChangeInvoices(index, event?.target.value, 'note')}
                            />
                          </div>
                          <div>
                            <input
                              className='amount-field'
                              style={{ color: errorPayment ? '#FF0000' : 'inherit' }}
                              type="number"
                              value={item.total.toFixed(2)}
                              onFocus={(event) => handleFocus(event)}
                              onChange={(event) => handleChangePrice(event, index)}
                              onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                            />
                          </div>
                        </td>

                        <td>
                          <button
                            className='_zeroed _iconed _red'
                            onClick={() => {
                              handleRemove(item.id)
                            }}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>

                <div className="fields">
                  <div className="__left">
                    <div style={{ justifyContent: 'flex-end' }} className="flex-container _gap-narrow">
                      <span style={{ fontWeight: 700 }}>
                        Total: $ {invoicesSum()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="fields">
                  <div className="__left">
                    <div style={{ justifyContent: 'flex-end', opacity: canBeCanceledPaymentFeeCompensation.permission ? '1' : '.5' }} className="flex-container _gap-narrow">
                      <Checkbox
                        contents=''
                        disabled={!canBeCanceledPaymentFeeCompensation.permission}
                        value={canBeCanceledPaymentFeeCompensation.value}
                        onChange={(value) => setCanBeCanceledPaymentFeeCompensation({ ...canBeCanceledPaymentFeeCompensation, value: value })}
                      />
                      <span style={{ fontWeight: 700, color: canBeCanceledPaymentFeeCompensation.value ? '#000' : '#929292' }}>
                        Processing Fee ({canBeCanceledPaymentFeeCompensation.value ? feeCompensation.toFixed(2) : '0.00'}%): ${canBeCanceledPaymentFeeCompensation.value ? processingFee() : '0.00'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="fields">
                  <div className="__left">
                    <div style={{ justifyContent: 'flex-end' }} className="flex-container _gap-narrow">
                      <span style={{ fontWeight: 700 }}>
                        Total (including fee): $ {totalIncludeFee()}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="fields"
                  style={{ marginBottom: paymentMethod && (!!tokenStripe || !!tokenSquare.square_payment_application_id) ? '30px' : '60px' }}
                >
                  <div className="__left">
                    <div className='field'>
                      <span>
                        Payment Method:
                      </span>

                      <Select
                        options={paymentMethodOptions.map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={paymentMethod as string}
                        onChange={(value) => setPaymentMethod(value as string)}
                      />
                    </div>
                  </div>

                  <div className="__right">
                    {
                      paymentMethod === 'Square' &&
                      <div className='field'>
                        <span>
                          Location Name:
                        </span>

                        <input
                          style={{ border: 'none' }}
                          type="text"
                          disabled={true}
                          value={tokenSquare.square_payment_location_name}
                          onChange={(event) => setTokenSquare({ ...tokenSquare, square_payment_location_name: event.target.value })}
                        />
                      </div>
                    }
                  </div>
                </div>

                {
                  paymentMethod === 'Stripe' &&
                  !!tokenStripe &&
                  <div className="fields">
                    <StripeComponent
                      tokenStripe={tokenStripe}
                      setApiToken={setApiToken}
                      cancel={handleCancel}
                      disabled={+invoicesSum() <= 0 || loading}
                      disabledCancel={loading}
                      setLoading={setLoading}
                      checkServiceEmpty={checkServiceEmpty()}
                      setErrorService={setErrorService}
                      errorService={errorService}
                    />
                  </div>
                }

                {
                  paymentMethod === 'Square' &&
                  !!tokenSquare.square_payment_application_id &&
                  <div className="fields">
                    <SquareComponent
                      square_payment_application_id={tokenSquare.square_payment_application_id}
                      handlePaySquare={handlePaySquare}
                      square_payment_location_id={tokenSquare.square_payment_location_id}
                      disabled={+invoicesSum() <= 0 || loading}
                      setErrorPayment={setErrorPayment}
                    />
                  </div>
                }
              </div>
            }
          </div>
      }
    </div>
  )
}
