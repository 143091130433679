import { useEffect, useState } from "react"
import { useJsApiLoader } from '@react-google-maps/api'

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import AddressAutocomplete from "../Booking/AddressAutocomplete"

import { AppointDataProps } from "./List"
import { AddressProps } from "./Models"

interface BlockProps {
  editing: boolean
  setEditing: (value: string) => void
  data: AddressProps[]
  setData: (value: AddressProps[]) => void
  appointmentReport: Partial<AppointDataProps>
  disabledEditButton: boolean
  updatedAddressItem: () => void
  status: boolean
  permmisions_address_edit: boolean
  isSending: boolean
}

interface AddressComponentProps {
  address_id?: string
  address_zip: string;
  address_street: string;
  address_appatment: string;
  address_city: string;
  address_state: string;
  address_arr: string;
  address_note: string;
  street_number: string
  address_state_id?: string;
  apt?: string
  new?: boolean
  use?: boolean
}

const API_KEY = process.env.REACT_APP_API_KEY
const libraries: "places"[] = ["places"]

const addressOptions = ['Residential', 'Commercial', 'Industrial']

export default function AddressBlock({
  editing,
  setEditing,
  data,
  setData,
  appointmentReport,
  disabledEditButton,
  updatedAddressItem,
  status,
  permmisions_address_edit,
  isSending
}: BlockProps) {
  const [readyToSave, setReadyToSave] = useState(false)
  const [isNewAddress, setIsNewAddress] = useState(false)
  const [editAddress, setEditAddress] = useState(0)
  const [newAddress, setNewAddress] = useState<AddressComponentProps>({
    address_id: '',
    address_zip: '',
    address_street: '',
    address_appatment: '',
    address_city: '',
    address_state: '',
    address_state_id: '',
    address_arr: addressOptions[0],
    address_note: '',
    street_number: '',
    apt: 'Apt',
    new: true,
    use: false
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clearAutoComplite, setClearAutoComplite] = useState(0)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY as string,
    libraries: libraries as "places"[],
    language: 'en',
  })

  useEffect(() => {
    editing && setReadyToSave(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleCancel() {
    setEditing('')
    setReadyToSave(false)
    setIsNewAddress(false)
    setEditAddress(0)
    appointmentReport.addresses && setData(appointmentReport.addresses.map(item => {
      return {
        ...item,
        use: true,
        new: false
      }
    }))
    setNewAddress({
      address_id: '',
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_state_id: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      apt: 'Apt',
      new: true,
      use: false
    })
  }

  function handleSave() {
    updatedAddressItem()
    setEditing('')
    setReadyToSave(false)
  }

  function handleRemoveAddress(index: number) {
    setData(data.filter((item, idx) => idx !== index))
  }

  function handleAddAddress() {
    let updatedAddress = data.map(item => {
      return {
        ...item,
        use: false
      }
    })
    updatedAddress.push({
      address_id: newAddress.address_id ? newAddress.address_id : '',
      city: newAddress.address_city,
      is_appointment: 0,
      note: newAddress.address_note,
      postal_code: newAddress.address_zip,
      state: newAddress.address_state,
      street: newAddress.street_number ? `${newAddress.street_number} ${newAddress.address_street}` : newAddress.address_street,
      type: newAddress.address_arr,
      unit: newAddress.address_appatment,
      apt: newAddress.apt,
      use: true,
      new: true
    })

    setData(updatedAddress)
    setIsNewAddress(false)

    setNewAddress({
      address_id: '',
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_state_id: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      apt: 'Apt',
      new: true,
      use: false
    })
  }

  function handleChangeUseAddress(value: boolean, index: number) {
    let updatedAddress = data.map(item => {
      return {
        ...item,
        use: false
      }
    })
    updatedAddress[index].use = value

    setData(updatedAddress)
  }

  function handleEditAddress(index: number) {
    setNewAddress({
      address_id: data[index].address_id,
      address_zip: data[index].postal_code,
      address_street: data[index].street,
      address_appatment: data[index].unit,
      address_city: data[index].city,
      address_state: data[index].state,
      address_state_id: '',
      address_arr: data[index].type,
      address_note: data[index].note,
      street_number: '',
      apt: data[index].apt,
      new: data[index].new,
      use: data[index].use,
    })
    setEditAddress(index + 1)
  }

  function handleCancelNewAddress() {
    setNewAddress({
      address_id: '',
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_state_id: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      apt: 'Apt',
      new: true,
      use: false
    })

    setIsNewAddress(false)
  }

  function handleAddEditAddress() {
    let updatedAddress = data.map((item, index) => {
      if (index === editAddress - 1) {
        return {
          address_id: newAddress.address_id ? newAddress.address_id : '',
          city: newAddress.address_city,
          is_appointment: 0,
          note: newAddress.address_note,
          postal_code: newAddress.address_zip,
          state: newAddress.address_state,
          street: newAddress.street_number ? `${newAddress.street_number} ${newAddress.address_street}` : newAddress.address_street,
          type: newAddress.address_arr,
          unit: newAddress.address_appatment,
          apt: newAddress.apt,
          use: true,
          new: typeof newAddress.new === 'boolean' ? newAddress.new : true
        }
      } else {
        return {
          ...item,
          use: false
        }
      }
    })

    setNewAddress({
      address_id: '',
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_state_id: '',
      address_arr: '',
      address_note: '',
      street_number: '',
      apt: 'Apt',
      new: false,
      use: false,
    })

    setData(updatedAddress)
    setEditAddress(0)
  }

  function handleCancelEditAddress() {
    setNewAddress({
      address_id: '',
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_state_id: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      apt: 'Apt',
      new: true,
      use: false
    })

    setEditAddress(0)
  }

  function checkHandleSave() {
    let check = false
    let checkData = data.filter(item => item.use)
    if (checkData.length) {
      if (
        checkData[0].type === '' || checkData[0].street === '' ||
        checkData[0].city === '' || checkData[0].state === '' ||
        checkData[0].postal_code === ''
      ) {
        check = true
      }
    } else {
      check = true
    }

    return check
  }

  return (
    <>
      <div className='call-contact_new-addresses'>

        <div className="block-content-title">
          <div className="block-title">
            Address
          </div>
        </div>

        <div
          className="action-buttons"
          style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '10px' }}
        >
          {
            !editing &&
            <button
              disabled={editing || disabledEditButton || status || !permmisions_address_edit}
              className="btn btn-add"
              onClick={() => setEditing('Address')}
            >
              Edit
            </button>
          }

          {
            editing &&
            <>
              <button
                disabled={isNewAddress}
                className="btn btn-add btn-cancel"
                style={{ width: 'fit-content', marginRight: '15px' }}
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || checkHandleSave() || isSending}
                className="btn btn-add btn-save"
                style={{ width: 'fit-content' }}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </>
          }
        </div>

        <div style={{ padding: '0' }} className="data-border">
          <div className='call-contact_new-topBlock'>
            <div className='call-contact_new-titleBlock'>
              <p>Addresses:</p>
            </div>

            <div className='call-contact_new-dontUse'>
              <p>Use</p>
            </div>
          </div>

          {
            data.map((address, index) => (
              <div key={index} className='call-contact_new-block'>
                <div className='call-contact_new-addresses_item'>
                  <div className='call-contact_new-addresses_data'>
                    <p className='call-contact_new-addresses_data-text'>
                      <span> {!!editAddress && editAddress - 1 === index && '* '} </span>
                      <span> {address.street}, </span>
                      <span> {address.unit ? `${address.apt}#${address.unit}` : address.unit} </span>
                      <span> {address.city}, </span>
                      <span> {address.state} </span>
                      <span> {address.postal_code} </span>
                    </p>

                    <div className='call-contact_new-addresses_data-block'>
                      <p>{address.type}</p>

                      <p className='call-contact_new-addresses_data-note'> {address.note} </p>
                    </div>
                  </div>

                  <div className='call-contact_new-block_delete'>
                    <div className='call-contact_number-radiobtn'>
                      <div className='radio'>
                        <input
                          name={'use'}
                          disabled={!editing}
                          className='input-radio'
                          type="radio"
                          style={{ cursor: editing ? 'pointer' : 'inherit' }}
                          id={`radio-${index}`}
                          checked={address.use}
                          onChange={(event) => handleChangeUseAddress(event.target.checked, index)}
                        />

                        <label className='radiobox' htmlFor={`radio-${index}`}>
                          {
                            address.use &&
                            <span className='active'></span>
                          }
                        </label>
                      </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                      {
                        editing && !isNewAddress && !!!editAddress ?
                          <div
                            className='trash call-contact_new-block_trash inactive'
                            style={{ marginRight: '10px' }}
                            onClick={() => handleEditAddress(index)}
                          >
                            <Icon
                              style={{ fill: '#FFB700', width: '16px', cursor: 'pointer' }}
                              viewBox="0 0 16 16"
                              icon="pencil-1"
                            />
                          </div> :
                          <div
                            className='trash call-contact_new-block_trash inactive'
                            style={{ marginRight: '10px' }}
                          >
                            <Icon
                              style={{ fill: '#8e8e93', width: '16px', cursor: 'inherit' }}
                              viewBox="0 0 16 16"
                              icon="pencil-1"
                            />
                          </div>
                      }

                      {
                        editing && address.new ?
                          <div
                            className='trash call-contact_new-block_trash inactive'
                            onClick={() => handleRemoveAddress(index)}
                          >
                            <Icon
                              style={{ fill: '#FF0000', width: '90%', cursor: 'pointer' }}
                              icon="x-mark-1"
                            />
                          </div> :
                          <div
                            className='trash call-contact_new-block_trash inactive'
                          >
                            <Icon style={{ fill: '#8E8E93', width: '90%', cursor: 'inherit' }} icon="x-mark-1" />
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))
          }

          {
            editing && isNewAddress && isLoaded &&
            <div className='call-contact_new-block'>
              <div className='call-contact_new-addresses_add'>
                <p>Autocomplete: </p>

                <div style={{ width: '75%' }} className='call-contact_new-addresses_add-data'>
                  <AddressAutocomplete
                    addNewCity={newAddress}
                    setAddNewCity={setNewAddress}
                    clearAutoComplite={clearAutoComplite}
                  />
                </div>
              </div>
            </div>
          }

          {
            editing &&
            <div className='call-contact_new-block'>

              <div className='call-contact_new-addresses_add'>
                <p>
                  {
                    !!editAddress ?
                      'Edit Address:' :
                      'Add Address:'
                  }
                </p>

                {
                  (isNewAddress || !!editAddress) &&
                  <div className='call-contact_new-addresses_add-data'>
                    <div className='call-contact_new-addresses_add-block'>
                      <input
                        placeholder='Street'
                        // value={newAddress.street_number ? `${newAddress.street_number} ${newAddress.address_street}` : newAddress.address_street}
                        value={newAddress.address_street}
                        onChange={(event) => setNewAddress({ ...newAddress, address_street: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input street'
                        type="text"
                      />

                      <div className="select-apt">
                        <Select options={['Apt', 'Unit', 'Lot'].map((item) => ({
                          span: item,
                          value: item
                        }))}
                          selectedOption={newAddress.apt as string}
                          onChange={(value: string) => setNewAddress({
                            ...newAddress,
                            apt: value
                          })}
                        />
                      </div>

                      <input
                        placeholder='Unit/Apt'
                        value={newAddress.address_appatment}
                        onChange={(event) => setNewAddress({ ...newAddress, address_appatment: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input unit'
                        type="text"
                      />

                      <input
                        placeholder='City'
                        value={newAddress.address_city}
                        onChange={(event) => setNewAddress({ ...newAddress, address_city: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input city'
                        type="text"
                      />

                      <input
                        placeholder='State'
                        value={newAddress.address_state}
                        onChange={(event) => setNewAddress({ ...newAddress, address_state: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input state'
                        type="text"
                      />

                      <input
                        placeholder='Zip'
                        value={newAddress.address_zip}
                        onChange={(event) => setNewAddress({ ...newAddress, address_zip: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input zip'
                        type="text"
                      />
                    </div>

                    <div className='call-contact_new-addresses_add-block'>
                      <Select options={addressOptions.map((item) => ({
                        span: item,
                        value: item
                      }))} selectedOption={newAddress.address_arr} onChange={(value: string) => setNewAddress({
                        ...newAddress,
                        address_arr: value
                      })} />

                      <input
                        placeholder='Note'
                        value={newAddress.address_note}
                        onChange={(event) => setNewAddress({ ...newAddress, address_note: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input note'
                        type="text"
                      />
                    </div>
                  </div>
                }

                {
                  !isNewAddress && !editAddress &&
                  <div className='call-contact_new-addresses_add-btn'>
                    <button
                      onClick={() => setIsNewAddress(true)}
                      className='btn btn-add'
                    >
                      Add
                    </button>
                  </div>
                }

                {
                  isNewAddress &&
                  <div className='call-contact_new-addresses_add-btn'>
                    <button
                      style={{ marginRight: '15px' }}
                      onClick={() => handleCancelNewAddress()}
                      className='btn btn-add btn-cancel'
                    >
                      Cancel
                    </button>

                    <button
                      disabled={newAddress.address_zip === '' || newAddress.address_street === '' ||
                        newAddress.address_city === '' ||
                        newAddress.address_state === ''}
                      onClick={() => handleAddAddress()}
                      className='btn btn-add btn-save'
                    >
                      Save
                    </button>
                  </div>
                }

                {
                  !!editAddress &&
                  <div className='call-contact_new-addresses_add-btn'>
                    <button
                      style={{ marginRight: '15px' }}
                      disabled={newAddress.address_zip === '' || newAddress.address_street === '' ||
                        newAddress.address_city === '' ||
                        newAddress.address_state === ''}
                      onClick={() => handleCancelEditAddress()}
                      className='btn btn-add btn-cancel'
                    >
                      Cancel
                    </button>

                    <button
                      disabled={newAddress.address_zip === '' || newAddress.address_street === '' ||
                        newAddress.address_city === '' ||
                        newAddress.address_state === ''}
                      onClick={() => handleAddEditAddress()}
                      className='btn btn-add btn-save'
                    >
                      Save
                    </button>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
