import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"

import { httpClientUpdate, nErrorUpdate, validateEmail } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { HttpMailboxesReport } from './List'

import "../../styles/pages/common/entity-edit.sass"

export interface TemplateProps {
  responsibles: string
  template: string
  type: string
}

export interface UserGroupsProps {
  name: string
  user_group_id: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [emailOptions, setEmailOptions] = useState<string[]>([])

  const [emailError, setEmailError] = useState(false)

  const [data, setData] = useState({
    name: '',
    email: '',
  })

  const [isSaving, setIsSaving] = useState(false)

  // Load mailboxes function
  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/google/accounts/report
      const { data: { data: response, success, error } } = (await httpClientUpdate.get('/google/accounts/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 500,
          page: JSON.stringify(1),
        }
      })) as { data: HttpMailboxesReport }
      if (success) {

        const options: string[] = []

        response.google_accounts.forEach(item => {
          if (!options.includes(item.email)) {
            options.push(item.email)
          }
        })

        setEmailOptions(options)

        // setReportData(response)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load calls on page mount
  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (emailError) {
      setEmailError(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.email])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      if (emailOptions.includes(data.email)) {
        setEmailError(true)
        setIsSaving(false)
      } else {
        // https://2022back4.artemiudintsev.com/api/google/accounts
        const response = await httpClientUpdate.post(`/google/accounts`, {
          account_id: activeAccountId,
          email: data.email,
          name: data.name,
        })
        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('google-accounts', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Account</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('google-accounts', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div
          className="legend"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Main
          {
            emailError &&
            <span style={{ color: '#FF0000', marginLeft: '10px', fontSize: '16px' }}>
              This email is already in use!
            </span>
          }
        </div>

        <div className="fields">
          <div className="__left">

            <div className="field">
              <span>Name:</span>
              <input
                type="text"
                value={data.name}
                onChange={(event) => setData({ ...data, name: event.target.value })}
              />
            </div>

          </div>

          <div className="__right">
            <div className="field">
              <span>Email:</span>
              <input
                type="text"
                value={data.email}
                onChange={(event) => setData({ ...data, email: event.target.value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('google-accounts', {
              companyId: activeAccountId,
            }, { reload: true })}
          >
            Cancel
          </button>

          <button
            disabled={!validateEmail(data.email) || data.name === '' || isSaving || emailError}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
