import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import SelectWithSearchComponent from "../../components/SelectWithSearch";

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import { AccountMenusReport, AccountMenusEdit } from "./List";
import AccountMenus from "../../models/AccountMenus";
import { HttpAccountMenusItemReport } from "./Item";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AccountMenusReport
}

function AccountMenusPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [actions, setActions] = useState<AccountMenusEdit[]>([])
  const [availableActions, setAvailableActions] = useState<AccountMenusEdit[]>([])
  const [selectedAction, setSelectedAction] = useState<string | number>('')
  const [newData, setNewData] = useState({
    name: '',
    menu_items: [] as AccountMenusEdit[]
  })

  const [permissionsRows, setPermissionsRows] = useState<AccountMenus[] | []>([])
  const [selectedPermissionRow, setSelectedPermissionRow] = useState('')

  // save document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.post(`/menus/accounts`, qs.stringify({
        account_id: activeAccountId,
        name: newData.name,
        items: JSON.stringify(newData.menu_items?.map(item => item.id))
      }))
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('accounts_menus', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: menusData, success, error } } = (await httpClientUpdate.post('/menus/accounts/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 50,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (menusData.permissions.account_menu_add) {
          setActions(menusData.edit.menu_items)
          setAvailableActions(menusData.edit.menu_items)
          setSelectedAction('')

          setPermissionsRows(menusData.menus_rows)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load accountPermission data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Loading accountMenus
  async function loadingAccountMenus() {
    try {
      setIsSaving(true)
      const { data: { data: permissionData, success, error } } = await httpClientUpdate.get('/menus/accounts/' + selectedPermissionRow, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpAccountMenusItemReport }
      if (success) {
        let _availableActions = permissionData.edit.menu_items.filter(action => !permissionData.menu_items.filter(item => item.id === action.id).length && action)

        setAvailableActions(_availableActions)
        setSelectedAction('')
        setNewData({
          ...newData,
          menu_items: permissionData.menu_items
        })

        setIsSaving(false)
        setSelectedPermissionRow('')
      } else {
        setIsSaving(false)
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Add action function
  function addAction() {

    if (!newData || !actions) return

    let updateActions = newData.menu_items.map(item => item)
    actions.forEach((item: AccountMenusEdit) => item.id === selectedAction && updateActions.push(item))

    setNewData({
      ...newData,
      menu_items: updateActions
    })

    let _availableActions = [...availableActions]

    _availableActions = _availableActions.filter((action) => !(newData.menu_items as AccountMenusEdit[]).includes(action))
    _availableActions.splice(_availableActions.findIndex((_action) => selectedAction === _action.id), 1)

    setAvailableActions([
      ..._availableActions
    ])

    setSelectedAction('')

    setReadyToSave(true)
  }

  // Remove action function
  function removeAction(i: number) {

    let _accountPermissionActions = [...(newData.menu_items as AccountMenusEdit[])]

    let _action = _accountPermissionActions[i]

    _accountPermissionActions.splice(i, 1)

    setNewData({
      ...newData,
      menu_items: _accountPermissionActions
    })

    setAvailableActions([
      ...availableActions,
      _action
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  return (
    <>
      {
        availableActions &&
        <div className="AccountPermissionsPage_New entity-edit">
          <div className="wrapper flex-container sb">
            <h1>New Account`s Menus</h1>

            <button
              style={{ background: '#d0d3da', color: 'white' }}
              className="_wa"
              onClick={() => $router.router.navigate('accounts_menus', {
                companyId: activeAccountId,
                localInterface: $router.router.getState().params.localInterface
              }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">

              <div className="__left">
                <div className="field">
                  <span>Name:</span>
                  <input type="text" onChange={({ target: { value } }) => setNewData({ ...newData, name: value })} />
                </div>
              </div>
            </div>
          </div>


          <div className="fieldset">
            <div className="flex-container sb wrap">
              <div className="legend">Menus</div>

              <div className="legend-action-wrapper">
                <label>Menu:</label>
                <div style={{ zIndex: '7' }} className="input-wrapper">
                  <SelectWithSearchComponent
                   options={availableActions.map((menu) => ({
                    span: menu.name,
                    value: menu.id
                  }))} selectedOption={selectedAction} onChange={(value) => setSelectedAction(value as number)} />
                  <button disabled={!selectedAction} className="_green" onClick={() => addAction()}>
                    Add
                  </button>
                </div>
              </div>
            </div>

            <table className="table som">
              <tr>
                <th style={{ width: '100%' }}>Action</th>
                <th>Allow</th>
                <th></th>
              </tr>
              {(newData.menu_items as AccountMenusEdit[]).map((action, i) => (
                <tr key={i}>
                  <td>{action.name}</td>
                  <td>
                    <Checkbox contents={''} value={true} />
                  </td>
                  <td>
                    <button className="_zeroed _iconed _red" onClick={() => removeAction(i)}>
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ))}
            </table>

            <div
              className="flex-container wrap"
              style={{ justifyContent: 'flex-end', marginTop: '20px' }}
            >
              <div
                className="legend"
                style={{ fontSize: '16px', opacity: '.6', fontWeight: 'normal' }}
              >
                Copy actions from another accounts menus
              </div>

              <div className="legend-action-wrapper">
                <div
                  className="input-wrapper"
                >
                  <Select
                    options={permissionsRows.map((action) => ({
                      span: action.name,
                      value: action.account_menu_id
                    }))}
                    selectedOption={selectedPermissionRow}
                    onChange={(value) => setSelectedPermissionRow(value as string)}
                  />
                  <span
                    style={{
                      cursor: !isSaving && selectedPermissionRow ? 'pointer' : 'inherit',
                      opacity: !isSaving && selectedPermissionRow ? '1' : '.6'
                    }}
                    onClick={() => !isSaving && selectedPermissionRow && loadingAccountMenus()}
                  >
                    <Icon
                      viewBox="0 0 16 16"
                      style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                      icon="download-1"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper flex-container sb editing-buttons">
            <div />
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => $router.router.navigate('accounts_menus', {
                  companyId: activeAccountId,
                }, {
                  reload: true
                })}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AccountMenusPage_New