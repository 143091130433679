import React, { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import qs from 'qs'

import Icon from "../../components/Icon"
import Switcher from '../../components/Switcher'
import ModalImage from '../Sms/ModalImage'
import AudioPlayer from '../../components/AudioPlayer'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { WebsiteRequestItem } from '../../models/WebsiteRequests'

import "../../styles/pages/common/entity-edit.sass"
import '../../styles/pages/sms.sass'
import "./WebsiteRequestItem.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    website_request: WebsiteRequestItem
    permissions: {
      website_request_action_close: boolean
      website_request_action_hidden: boolean
      website_request_action_in_progress: boolean
      website_request_update_send: boolean
      website_request_update_show: boolean
      website_request_calls_show: boolean
      website_request_messages_show: boolean
      website_request_contact_info_show: boolean
      website_request_website_show: boolean
    }
  }
}

interface CommentsProps {
  comment: string
  created_at: string
  created_by: string
}

interface ItemProps {
  handleCallExtension: (value: string) => void
  softphone_call_extension: boolean | undefined
}

export default function Item({ handleCallExtension, softphone_call_extension }: ItemProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [callListenPopup, setCallListenPopup] = useState<null | string>(null)

  const [openModal, setOpenModal] = useState(false)
  const [zoomImage, setZoomImage] = useState({ url: '', type: '' })

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [message, setMessage] = useState('')
  const [data, setData] = useState<Partial<WebsiteRequestItem>>({})
  const [permissions, setPermissions] = useState({
    website_request_action_close: false,
    website_request_action_hidden: false,
    website_request_action_in_progress: false,
    website_request_update_send: false,
    website_request_update_show: false,
    website_request_calls_show: false,
    website_request_messages_show: false,
    website_request_contact_info_show: false,
    website_request_website_show: false,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chat, setChat] = useState<CommentsProps[] | []>([])

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isShowContact, setIsShowContact] = useState(false)

  const [isCantBlock, setIsCantBlock] = useState(false)

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/website/requests/888885019gqcnt625r?account_id=88888
      const { data: { data: websiteData, success, error } } = await httpClientUpdate.get(`/website/requests/${$router.router.getState().params.website_requestId}`,
        {
          params: {
            account_id: activeAccountId
          }
        }) as { data: HttpClientUpdateReport }
      if (success) {
        setData({
          ...websiteData.website_request
        })
        setPermissions(websiteData.permissions)
        websiteData.permissions.website_request_update_show && setChat(websiteData.website_request.comments)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function toggleHidden(is_hidden: boolean) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/website/requests/888885019gqcnt625r
      const response = await httpClientUpdate.put(`/website/requests/${data.website_request_id}`, qs.stringify({
        account_id: activeAccountId,
        is_hidden: is_hidden ? 1 : 0
      }))
      if (response.data.success) {
        setIsSaving(false)
        data && setData({
          ...data,
          is_hidden: is_hidden
        })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function toggleClose(is_closed: boolean) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/website/requests/888885019gqcnt625r
      const response = await httpClientUpdate.put(`/website/requests/${data.website_request_id}`, qs.stringify({
        account_id: activeAccountId,
        is_closed: !is_closed ? 1 : 0
      }))
      if (response.data.success) {
        setIsSaving(false)
        data && setData({
          ...data,
          is_closed: !is_closed
        })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function toggleInProgress(is_in_progress: boolean) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/website/requests/888885019gqcnt625r
      const response = await httpClientUpdate.put(`/website/requests/${data.website_request_id}`, qs.stringify({
        account_id: activeAccountId,
        is_in_progress: !is_in_progress ? 1 : 0
      }))
      if (response.data.success) {
        setIsSaving(false)
        data && setData({
          ...data,
          is_in_progress: !is_in_progress
        })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function toggleShowContact(type: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/website/requests/888885011sm4yrn9bg/reserve?account_id=88888
      const response = await httpClientUpdate.post(`/website/requests/${data.website_request_id}/reserve?account_id=${activeAccountId}&type=${type}`
      )
      if (response.data.success) {
        setIsSaving(false)
        if (response.data.data.message === 'Success block') {
          loadInfo()
          setIsCantBlock(false)
        }
        setIsShowContact(!isShowContact)
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      if (type === 'block') {
        let createdError = nErrorUpdate(error)

        if (createdError.content.errorText === "can't block") {
          setIsCantBlock(true)
        }
      }
      setIsSaving(false)
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          isShowContact && toggleShowContact('unblock')
          isShowContact && setIsShowContact(false)
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    if (isShowContact) {
      setMinutes(9)
      setSeconds(59)
    } else {
      setMinutes(0)
      setSeconds(0)
    }
  }, [isShowContact])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  async function handleSendMessage() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/website/requests/888885019gqcnt625r/comments
      const response = await httpClientUpdate.post(`/website/requests/${data.website_request_id}/comments`, qs.stringify({
        account_id: activeAccountId,
        message: message,
      }))
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
        setMessage('')
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && handleSendMessage()
    }
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getDispatcherName(dispathcer: string, code: number, device: string) {
    let name = ''
    if (code) {
      if (device) {
        name = `${dispathcer} (${code}${device[0]})`
      } else {
        name = `${dispathcer} (${code})`
      }
    } else {
      if (device) {
        name = `${dispathcer} (${device})`
      } else {
        name = dispathcer
      }
    }
    return name
  }

  function handleOpenImage(url: string, type: string) {
    setZoomImage({ url, type })
    setOpenModal(true)
  }

  function getName(first_name?: string, last_name?: string) {
    let name = ''

    if (first_name && last_name) {
      name = `${first_name} ${last_name}`
    } else if (first_name && !last_name) {
      name = first_name
    } else if (!first_name && last_name) {
      name = last_name
    }

    return name
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Website request</h1>
        </div>

        <button
          className="_wa"
          style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
          disabled={isShowContact}
          onClick={() =>
            $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'website_requests',
              {
                companyId: activeAccountId,
                [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                localInterface: $router.router.getState().params.localInterface
              }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Date:</span>
              <input
                type="text"
                disabled={true}
                value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
              />
            </div>

            <div className="field">
              <span>Type:</span>
              <input
                type="text"
                defaultValue={data.type || ''}
                disabled={true}
              />
            </div>

            <div className="field">
              <span>Source:</span>
              <input
                type="text"
                defaultValue={data.source || ''}
                disabled={true}
              />
            </div>
          </div>

          <div className="__right">
            {
              permissions.website_request_website_show &&
              <div className="field">
                <span>Website:</span>
                <input
                  type="text"
                  defaultValue={data.website || ''}
                  disabled={true}
                />
              </div>
            }

            {
              permissions.website_request_website_show &&
              <div className="field">
                <span>Page:</span>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={data.page || ''}
                />
              </div>
            }

            {
              permissions.website_request_action_hidden &&
              <div className="field">
                <span>Hidden:</span>
                <Switcher
                  disabled={!permissions.website_request_action_hidden || isSaving}
                  checked={data.is_hidden}
                  onChange={(value) => toggleHidden(value)}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >

          <div
            className="legend"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Contact

            {
              isShowContact && data &&
              <button
                style={{
                  marginLeft: '15px',
                  width: '16px',
                  height: '16px',
                  minHeight: '16px',
                  minWidth: '16px',
                  backgroundColor: 'inherit',
                  padding: '0'
                }}
                onClick={() => {
                  localStorage.setItem('copy_name', getName(data.first_name, data.last_name))
                  localStorage.setItem('copy_phone', data.phone ? data.phone : '')
                }}
                title={''}
              >
                <Icon
                  style={{
                    width: '16px',
                    height: '16px',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    fill: 'inherit'
                  }}
                  viewBox="0 0 1024 1024"
                  icon="copy-1"
                />
              </button>
            }
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className="item-name"
              style={{
                fontSize: '14pt',
                color: '#FF0000',
                marginLeft: '20px'
              }}
            >
              {
                minutes === 0 && seconds === 0
                  ? null
                  : <>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>
              }
            </div>
            {
              permissions.website_request_contact_info_show &&
              <>
                {
                  isCantBlock &&
                  <p style={{ color: '#FF0000', margin: 0 }}>
                    Somebody work right now
                  </p>
                }

                {
                  isShowContact ?
                    <button
                      className='unclose'
                      style={{ marginLeft: '20px' }}
                      disabled={isSaving}
                      // disabled={!permissions.website_request_action_close}
                      onClick={() => toggleShowContact('unblock')}
                    >
                      Show Contact
                    </button> :
                    <button
                      className='close'
                      style={{ marginLeft: '20px' }}
                      disabled={isSaving}
                      // disabled={!permissions.website_request_action_close}
                      onClick={() => toggleShowContact('block')}
                    >
                      Show Contact
                    </button>
                }
              </>
            }
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>
              <input
                type="text"
                disabled={true}
                value={`${data.first_name ? data.first_name : ''} ${data.last_name ? data.last_name : ''}` || ''}
              />
            </div>

            <div className="field">
              <div className="field">
                <span>Phone:</span>

                <input
                  type={isShowContact ? 'text' : 'password'}
                  defaultValue={data.phone ? formatPhoneNumber(data.phone) as string : ''}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Method of contact:</span>
              <input
                type="text"
                defaultValue={data.method_of_contact || ''}
                disabled={true}
              />
            </div>

            <div className="field">
              <span>Email:</span>

              <input
                type={isShowContact ? 'text' : 'password'}
                defaultValue={data.email || ''}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Address</div>

        <div className="fields">
          <input
            type="text"
            disabled={true}
            value={data.address || ''}
          />
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Message</div>

        <div className="fields">
          <textarea
            rows={5}
            style={{ minHeight: 'auto' }}
            disabled={true}
            value={data.message || ''}
          />
        </div>
      </div>

      {
        permissions.website_request_calls_show &&
        <div className="fieldset">
          <div className="legend">
            Calls
          </div>

          <div style={{ maxHeight: '400px', overflow: 'auto', marginTop: '30px' }}>
            {/* Wide desktop table */}
            <table
              className={classNames('table', '__show-on-wide',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}
              style={{ marginTop: '0' }}
            >
              <thead
                style={{
                  position: 'sticky',
                  top: '0',
                  background: 'white',
                  zIndex: '1'
                }}
              >
                <tr>
                  <th>Date</th>
                  <th></th>
                  <th>Dispatcher</th>
                  <th>Caller Name</th>
                  <th>Client</th>
                  <th>Friendly name</th>
                  <th>To</th>
                  <th>Extension</th>
                  <th></th>
                  <th>Status</th>
                  <th>Duration</th>
                  <th></th>
                </tr>
              </thead>

              {/* if (compareCallsDate(dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at), dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at))) { */}
              {data.calls && data.calls.map((call, i) => {
                if (i === 0 && dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)) {
                  return (
                    <React.Fragment key={i}>
                      <tr style={{ backgroundColor: '#dfe4ff', position: 'relative' }}>
                        <td style={{ borderRight: 'none', height: '25px' }}>
                          <div
                            className="item-name"
                            style={{
                              fontSize: '11pt',
                              color: '#8391ad',
                              marginLeft: '20px',
                              fontWeight: 'initial',
                              position: 'absolute',
                              top: '5px'
                            }}
                          >
                            The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
                          </div>
                        </td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td></td>
                      </tr>

                      <tr
                        style={{ cursor: 'inherit', position: 'relative' }}
                      >
                        <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}</td>
                        <td>
                          <button
                            className={classNames('_zeroed', '_iconed', {
                              _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                              _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                              _blue: call.direction === 'outbound'
                            })}
                          >
                            <Icon icon="arrow-20" className={classNames({
                              '_rotated-180': call.direction === 'outbound'
                            })} />
                          </button>
                        </td>
                        {
                          // reportData.permissions.call_call_show_dispatcher &&
                          <td>{getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</td>
                        }
                        <td>{call.caller_name}</td>
                        <td>{call.client ? (
                          <div
                            className="flex-container"
                            style={{
                              gap: '5px',
                              //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                            }}
                            onClick={() =>
                              // reportData.permissions.client_show && call.client_id &&
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId, clientId: call.client_id
                              }, { reload: true })}
                            onContextMenu={(event) =>
                              // reportData.permissions.client_show && call.client_id &&
                              handleChangeMenu(event, call.client_id, 'clients', i)}
                          >
                            <button
                              className="_zeroed _iconed _gray"
                            // style={{ cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                            >
                              <Icon icon="user-32" />
                            </button>
                            <span>
                              {call.client}
                            </span>
                            {
                              // reportData.permissions.client_show && call.client_id &&
                              showContext === `clients_${call.client_id}_${i}` &&
                              <div
                                className="redirect-menu"
                                ref={contextMenuRef}
                                style={{ left: `${screenX}px` }}
                              >
                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    $router.router.navigate('clients.item', {
                                      companyId: activeAccountId, clientId: call.client_id
                                    }, { reload: true })
                                  }
                                  }
                                >
                                  Open
                                </button>

                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                                    setShowContext('')
                                  }}
                                >
                                  Open in new tab
                                </button>
                              </div>
                            }
                          </div>
                        ) : null}</td>
                        {/* <td>{call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</td> */}
                        <td>{call.friendly_name}</td>
                        <td>{call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                          >
                            <span>
                              {call.extension}
                            </span>
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            // reportData.permissions.appointment_show &&
                            call.appointment_id &&
                            $router.router.navigate('appointments.item', {
                              companyId: activeAccountId, appointmentId: call.appointment_id
                            }, { reload: true })}
                          onContextMenu={(event) =>
                            // reportData.permissions.appointment_show &&
                            call.appointment_id &&
                            handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                        >
                          {call.is_appointment ? (
                            <button
                              // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                              className={classNames('_zeroed', '_iconed', {
                                _green: call.is_appointment === 'main',
                                _blue: call.is_appointment === 'lead'
                              })}>
                              <Icon icon="clipboard-5" />
                            </button>
                          ) : null}
                          {
                            // reportData.permissions.appointment_show &&
                            showContext === `appointments_${call.appointment_id}_${i}` &&
                            <div
                              className="redirect-menu"
                              ref={contextMenuRef}
                              style={{ left: `${screenX}px` }}
                            >
                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  $router.router.navigate('appointments.item', {
                                    companyId: activeAccountId, appointmentId: call.appointment_id
                                  }, { reload: true })
                                }
                                }
                              >
                                Open
                              </button>

                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                                  setShowContext('')
                                }}
                              >
                                Open in new tab
                              </button>
                            </div>
                          }
                        </td>
                        <td className={classNames({
                          'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                          'green-text': call.status === 'completed',
                          'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                        })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''} </td>
                        <td>{call.duration}</td>
                        {
                          // reportData.permissions.call_call_record &&
                          <td>
                            {call.recording_url ? (
                              <button
                                className="_zeroed _iconed"
                                onClick={() => setCallListenPopup(call.recording_url)}
                              >
                                <Icon icon="media-control-48" />
                              </button>
                            ) : null}
                          </td>
                        }
                      </tr>

                    </React.Fragment>
                  )
                } else if (
                  i !== 0 &&
                  data.calls &&
                  dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at) &&
                  dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) < dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.calls[i - 1].created_at)
                ) {
                  return (
                    <React.Fragment key={i}>
                      <tr style={{ backgroundColor: '#dfe4ff', position: 'relative' }}>
                        <td style={{ borderRight: 'none', height: '25px' }}>
                          <div
                            className="item-name"
                            style={{
                              fontSize: '11pt',
                              color: '#8391ad',
                              marginLeft: '20px',
                              fontWeight: 'initial',
                              position: 'absolute',
                              top: '5px',
                            }}
                          >
                            The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
                          </div>
                        </td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td></td>
                      </tr>

                      <tr
                        style={{ cursor: 'inherit', position: 'relative' }}
                      >
                        <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}</td>
                        <td>
                          <button
                            className={classNames('_zeroed', '_iconed', {
                              _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                              _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                              _blue: call.direction === 'outbound'
                            })}
                          >
                            <Icon icon="arrow-20" className={classNames({
                              '_rotated-180': call.direction === 'outbound'
                            })} />
                          </button>
                        </td>
                        {
                          // reportData.permissions.call_call_show_dispatcher &&
                          <td>{getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</td>
                        }
                        <td>{call.caller_name}</td>
                        <td>{call.client ? (
                          <div
                            className="flex-container"
                            style={{
                              gap: '5px',
                              //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                            }}
                            onClick={() =>
                              // reportData.permissions.client_show && call.client_id &&
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId, clientId: call.client_id
                              }, { reload: true })}
                            onContextMenu={(event) =>
                              // reportData.permissions.client_show && call.client_id &&
                              handleChangeMenu(event, call.client_id, 'clients', i)}
                          >
                            <button
                              className="_zeroed _iconed _gray"
                            // style={{ cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                            >
                              <Icon icon="user-32" />
                            </button>
                            <span>
                              {call.client}
                            </span>
                            {
                              // reportData.permissions.client_show && call.client_id &&
                              showContext === `clients_${call.client_id}_${i}` &&
                              <div
                                className="redirect-menu"
                                ref={contextMenuRef}
                                style={{ left: `${screenX}px` }}
                              >
                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    $router.router.navigate('clients.item', {
                                      companyId: activeAccountId, clientId: call.client_id
                                    }, { reload: true })
                                  }
                                  }
                                >
                                  Open
                                </button>

                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                                    setShowContext('')
                                  }}
                                >
                                  Open in new tab
                                </button>
                              </div>
                            }
                          </div>
                        ) : null}</td>
                        {/* <td>{call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</td> */}
                        <td>{call.friendly_name}</td>
                        <td>{call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                          >
                            <span>
                              {call.extension}
                            </span>
                            {/* <Icon
                      style={{
                        width: '16px',
                        height: '16px',
                        marginBottom: '3px',
                        cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                        fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                      }}
                      icon="phone-1"
                    /> */}
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            // reportData.permissions.appointment_show &&
                            call.appointment_id &&
                            $router.router.navigate('appointments.item', {
                              companyId: activeAccountId, appointmentId: call.appointment_id
                            }, { reload: true })}
                          onContextMenu={(event) =>
                            // reportData.permissions.appointment_show &&
                            call.appointment_id &&
                            handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                        >
                          {call.is_appointment ? (
                            <button
                              // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                              className={classNames('_zeroed', '_iconed', {
                                _green: call.is_appointment === 'main',
                                _blue: call.is_appointment === 'lead'
                              })}>
                              <Icon icon="clipboard-5" />
                            </button>
                          ) : null}
                          {
                            // reportData.permissions.appointment_show &&
                            showContext === `appointments_${call.appointment_id}_${i}` &&
                            <div
                              className="redirect-menu"
                              ref={contextMenuRef}
                              style={{ left: `${screenX}px` }}
                            >
                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  $router.router.navigate('appointments.item', {
                                    companyId: activeAccountId, appointmentId: call.appointment_id
                                  }, { reload: true })
                                }
                                }
                              >
                                Open
                              </button>

                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                                  setShowContext('')
                                }}
                              >
                                Open in new tab
                              </button>
                            </div>
                          }
                        </td>
                        <td className={classNames({
                          'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                          'green-text': call.status === 'completed',
                          'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                        })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''} </td>
                        <td>{call.duration}</td>
                        {
                          // reportData.permissions.call_call_record &&
                          <td>
                            {call.recording_url ? (
                              <button
                                className="_zeroed _iconed"
                                onClick={() => setCallListenPopup(call.recording_url)}
                              >
                                <Icon icon="media-control-48" />
                              </button>
                            ) : null}
                          </td>
                        }
                      </tr>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <tr
                      style={{ cursor: 'inherit', position: 'relative' }}
                      key={i}
                    >
                      <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}</td>
                      <td>
                        <button
                          className={classNames('_zeroed', '_iconed', {
                            _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                            _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                            _blue: call.direction === 'outbound'
                          })}
                        >
                          <Icon icon="arrow-20" className={classNames({
                            '_rotated-180': call.direction === 'outbound'
                          })} />
                        </button>
                      </td>
                      {
                        // reportData.permissions.call_call_show_dispatcher &&
                        <td>{getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</td>
                      }
                      <td>{call.caller_name}</td>
                      <td>{call.client ? (
                        <div
                          className="flex-container"
                          style={{
                            gap: '5px',
                            //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                          }}
                          onClick={() =>
                            // reportData.permissions.client_show && call.client_id &&
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId, clientId: call.client_id
                            }, { reload: true })}
                          onContextMenu={(event) =>
                            // reportData.permissions.client_show && call.client_id &&
                            handleChangeMenu(event, call.client_id, 'clients', i)}
                        >
                          <button
                            className="_zeroed _iconed _gray"
                          // style={{ cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                          >
                            <Icon icon="user-32" />
                          </button>
                          <span>
                            {call.client}
                          </span>
                          {
                            // reportData.permissions.client_show && call.client_id &&
                            showContext === `clients_${call.client_id}_${i}` &&
                            <div
                              className="redirect-menu"
                              ref={contextMenuRef}
                              style={{ left: `${screenX}px` }}
                            >
                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  $router.router.navigate('clients.item', {
                                    companyId: activeAccountId, clientId: call.client_id
                                  }, { reload: true })
                                }
                                }
                              >
                                Open
                              </button>

                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                                  setShowContext('')
                                }}
                              >
                                Open in new tab
                              </button>
                            </div>
                          }
                        </div>
                      ) : null}</td>
                      {/* <td>{call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</td> */}
                      <td>{call.friendly_name}</td>
                      <td>{call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</td>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                        >
                          <span>
                            {call.extension}
                          </span>
                          {/* <Icon
                      style={{
                        width: '16px',
                        height: '16px',
                        marginBottom: '3px',
                        cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                        fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                      }}
                      icon="phone-1"
                    /> */}
                        </div>
                      </td>
                      <td
                        onClick={() =>
                          // reportData.permissions.appointment_show &&
                          call.appointment_id &&
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId, appointmentId: call.appointment_id
                          }, { reload: true })}
                        onContextMenu={(event) =>
                          // reportData.permissions.appointment_show &&
                          call.appointment_id &&
                          handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                      >
                        {call.is_appointment ? (
                          <button
                            // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                            className={classNames('_zeroed', '_iconed', {
                              _green: call.is_appointment === 'main',
                              _blue: call.is_appointment === 'lead'
                            })}>
                            <Icon icon="clipboard-5" />
                          </button>
                        ) : null}
                        {
                          // reportData.permissions.appointment_show &&
                          showContext === `appointments_${call.appointment_id}_${i}` &&
                          <div
                            className="redirect-menu"
                            ref={contextMenuRef}
                            style={{ left: `${screenX}px` }}
                          >
                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                $router.router.navigate('appointments.item', {
                                  companyId: activeAccountId, appointmentId: call.appointment_id
                                }, { reload: true })
                              }
                              }
                            >
                              Open
                            </button>

                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                                setShowContext('')
                              }}
                            >
                              Open in new tab
                            </button>
                          </div>
                        }
                      </td>
                      <td className={classNames({
                        'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                        'green-text': call.status === 'completed',
                        'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                      })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''} </td>
                      <td>{call.duration}</td>
                      {
                        // reportData.permissions.call_call_record &&
                        <td>
                          {call.recording_url ? (
                            <button
                              className="_zeroed _iconed"
                              onClick={() => setCallListenPopup(call.recording_url)}
                            >
                              <Icon icon="media-control-48" />
                            </button>
                          ) : null}
                        </td>
                      }
                    </tr>
                  )
                }
              })}
            </table>

            {/* Medium screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>
              {data.calls && data.calls.map((call, i) => {
                if (i === 0 && dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)) {
                  return (
                    <React.Fragment key={i}>
                      <tr style={{ backgroundColor: '#dfe4ff', position: 'relative' }}>
                        <td style={{ borderRight: 'none', height: '25px' }}>
                          <div
                            className="item-name"
                            style={{
                              fontSize: '11pt',
                              color: '#8391ad',
                              marginLeft: '20px',
                              fontWeight: 'initial',
                              position: 'absolute',
                              top: '5px',
                            }}
                          >
                            The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
                          </div>
                        </td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td></td>
                      </tr>

                      <tr style={{ cursor: 'inherit', position: 'relative' }}>
                        <td>
                          <div>
                            {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}
                          </div>
                          <div className="flex-container _gap-narrow">
                            <button className={classNames('_zeroed', '_iconed', {
                              _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                              _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                              _blue: call.direction === 'outbound'
                            })}>
                              <Icon icon="arrow-20" className={classNames({
                                '_rotated-180': call.direction === 'outbound'
                              })} />
                            </button>
                            {
                              // reportData.permissions.call_call_show_dispatcher &&
                              <span>Dispatcher: {getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</span>
                            }
                          </div>
                        </td>
                        <td>
                          <div>
                            {call.caller_name}
                          </div>
                          {call.client ? (
                            <div
                              className="flex-container"
                              style={{
                                gap: '5px',
                                //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit'
                              }}
                              onClick={() =>
                                // reportData.permissions.client_show && call.client_id &&
                                $router.router.navigate('clients.item', {
                                  companyId: activeAccountId, clientId: call.client_id
                                }, { reload: true })}
                              onContextMenu={(event) =>
                                // reportData.permissions.client_show && call.client_id &&
                                handleChangeMenu(event, call.client_id, 'clients', i)}
                            >
                              <button
                                style={{
                                  gap: '5px',
                                  //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                                }}
                                className="_zeroed _iconed _gray">
                                <Icon icon="user-32" />
                              </button>
                              <span>{call.client}</span>
                              {
                                // reportData.permissions.client_show && call.client_id &&
                                showContext === `clients_${call.client_id}_${i}` &&
                                <div
                                  className="redirect-menu"
                                  ref={contextMenuRef}
                                  style={{ left: `${screenX}px` }}
                                >
                                  <button
                                    onClick={(event) => {
                                      event.preventDefault()
                                      $router.router.navigate('clients.item', {
                                        companyId: activeAccountId, clientId: call.client_id
                                      }, { reload: true })
                                    }
                                    }
                                  >
                                    Open
                                  </button>

                                  <button
                                    onClick={(event) => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                                      setShowContext('')
                                    }}
                                  >
                                    Open in new tab
                                  </button>
                                </div>
                              }
                            </div>
                          ) : <span>&nbsp;</span>}
                        </td>
                        <td>
                          {/* <div>From: {call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</div> */}
                          <div>To: {call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</div>
                        </td>
                        <td>
                          <div>{call.friendly_name}</div>
                          <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                          // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                          >
                            <span>
                              Extension: {call.extension}
                            </span>
                            {/* <span style={{ marginBottom: '1px' }} >
                      <Icon
                        style={{
                          width: '17px',
                          height: '17px',
                          marginLeft: '5px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }}
                        icon="phone-1" />
                    </span> */}
                          </div>
                        </td>
                        <td>
                          {call.is_appointment ? (
                            <button
                              className={classNames('_zeroed', '_iconed', {
                                _green: call.is_appointment === 'main',
                                _blue: call.is_appointment === 'lead'
                              })}
                              // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                              onClick={() =>
                                // reportData.permissions.appointment_show &&
                                call.appointment_id &&
                                $router.router.navigate('appointments.item', {
                                  companyId: activeAccountId, appointmentId: call.appointment_id
                                }, { reload: true })}
                              onContextMenu={(event) =>
                                // reportData.permissions.appointment_show &&
                                call.appointment_id &&
                                handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                            >
                              <Icon icon="clipboard-5" />
                            </button>
                          ) : null}
                          {
                            // reportData.permissions.appointment_show &&
                            showContext === `appointments_${call.appointment_id}_${i}` &&
                            <div
                              className="redirect-menu"
                              ref={contextMenuRef}
                              style={{ left: `${screenX}px` }}
                            >
                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  $router.router.navigate('appointments.item', {
                                    companyId: activeAccountId, appointmentId: call.appointment_id
                                  }, { reload: true })
                                }
                                }
                              >
                                Open
                              </button>

                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                                  setShowContext('')
                                }}
                              >
                                Open in new tab
                              </button>
                            </div>
                          }
                        </td>
                        <td>
                          <div className={classNames({
                            'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                            'green-text': call.status === 'completed',
                            'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                          })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''}</div>
                          <div className="flex-container _gap-narrow">
                            <div>{call.duration}</div>
                            {
                              // reportData.permissions.call_call_record && 
                              call.recording_url ? (
                                <button
                                  className="_zeroed _iconed"
                                  onClick={() => setCallListenPopup(call.recording_url)}
                                >
                                  <Icon icon="media-control-48" />
                                </button>
                              ) : null}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                } else if (
                  i !== 0 &&
                  data.calls &&
                  dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at) &&
                  dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) < dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.calls[i - 1].created_at)
                ) {
                  return (
                    <React.Fragment key={i}>
                      <tr style={{ backgroundColor: '#dfe4ff', position: 'relative' }}>
                        <td style={{ borderRight: 'none', height: '25px' }}>
                          <div
                            className="item-name"
                            style={{
                              fontSize: '11pt',
                              color: '#8391ad',
                              marginLeft: '20px',
                              fontWeight: 'initial',
                              position: 'absolute',
                              top: '5px',
                            }}
                          >
                            The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
                          </div>
                        </td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td style={{ borderRight: 'none' }}></td>
                        <td></td>
                      </tr>

                      <tr style={{ cursor: 'inherit', position: 'relative' }}>
                        <td>
                          <div>
                            {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}
                          </div>
                          <div className="flex-container _gap-narrow">
                            <button className={classNames('_zeroed', '_iconed', {
                              _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                              _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                              _blue: call.direction === 'outbound'
                            })}>
                              <Icon icon="arrow-20" className={classNames({
                                '_rotated-180': call.direction === 'outbound'
                              })} />
                            </button>
                            {
                              // reportData.permissions.call_call_show_dispatcher &&
                              <span>Dispatcher: {getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</span>
                            }
                          </div>
                        </td>
                        <td>
                          <div>
                            {call.caller_name}
                          </div>
                          {call.client ? (
                            <div
                              className="flex-container"
                              style={{
                                gap: '5px',
                                //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit'
                              }}
                              onClick={() =>
                                // reportData.permissions.client_show && call.client_id &&
                                $router.router.navigate('clients.item', {
                                  companyId: activeAccountId, clientId: call.client_id
                                }, { reload: true })}
                              onContextMenu={(event) =>
                                // reportData.permissions.client_show && call.client_id &&
                                handleChangeMenu(event, call.client_id, 'clients', i)}
                            >
                              <button
                                style={{
                                  gap: '5px',
                                  //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                                }}
                                className="_zeroed _iconed _gray">
                                <Icon icon="user-32" />
                              </button>
                              <span>{call.client}</span>
                              {
                                // reportData.permissions.client_show && call.client_id &&
                                showContext === `clients_${call.client_id}_${i}` &&
                                <div
                                  className="redirect-menu"
                                  ref={contextMenuRef}
                                  style={{ left: `${screenX}px` }}
                                >
                                  <button
                                    onClick={(event) => {
                                      event.preventDefault()
                                      $router.router.navigate('clients.item', {
                                        companyId: activeAccountId, clientId: call.client_id
                                      }, { reload: true })
                                    }
                                    }
                                  >
                                    Open
                                  </button>

                                  <button
                                    onClick={(event) => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                                      setShowContext('')
                                    }}
                                  >
                                    Open in new tab
                                  </button>
                                </div>
                              }
                            </div>
                          ) : <span>&nbsp;</span>}
                        </td>
                        <td>
                          {/* <div>From: {call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</div> */}
                          <div>To: {call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</div>
                        </td>
                        <td>
                          <div>{call.friendly_name}</div>
                          <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                          // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                          >
                            <span>
                              Extension: {call.extension}
                            </span>
                            {/* <span style={{ marginBottom: '1px' }} >
                      <Icon
                        style={{
                          width: '17px',
                          height: '17px',
                          marginLeft: '5px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }}
                        icon="phone-1" />
                    </span> */}
                          </div>
                        </td>
                        <td>
                          {call.is_appointment ? (
                            <button
                              className={classNames('_zeroed', '_iconed', {
                                _green: call.is_appointment === 'main',
                                _blue: call.is_appointment === 'lead'
                              })}
                              // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                              onClick={() =>
                                // reportData.permissions.appointment_show &&
                                call.appointment_id &&
                                $router.router.navigate('appointments.item', {
                                  companyId: activeAccountId, appointmentId: call.appointment_id
                                }, { reload: true })}
                              onContextMenu={(event) =>
                                // reportData.permissions.appointment_show &&
                                call.appointment_id &&
                                handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                            >
                              <Icon icon="clipboard-5" />
                            </button>
                          ) : null}
                          {
                            // reportData.permissions.appointment_show &&
                            showContext === `appointments_${call.appointment_id}_${i}` &&
                            <div
                              className="redirect-menu"
                              ref={contextMenuRef}
                              style={{ left: `${screenX}px` }}
                            >
                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  $router.router.navigate('appointments.item', {
                                    companyId: activeAccountId, appointmentId: call.appointment_id
                                  }, { reload: true })
                                }
                                }
                              >
                                Open
                              </button>

                              <button
                                onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                                  setShowContext('')
                                }}
                              >
                                Open in new tab
                              </button>
                            </div>
                          }
                        </td>
                        <td>
                          <div className={classNames({
                            'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                            'green-text': call.status === 'completed',
                            'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                          })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''}</div>
                          <div className="flex-container _gap-narrow">
                            <div>{call.duration}</div>
                            {
                              // reportData.permissions.call_call_record && 
                              call.recording_url ? (
                                <button
                                  className="_zeroed _iconed"
                                  onClick={() => setCallListenPopup(call.recording_url)}
                                >
                                  <Icon icon="media-control-48" />
                                </button>
                              ) : null}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <tr style={{ cursor: 'inherit', position: 'relative' }} key={i}>
                      <td>
                        <div>
                          {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}
                        </div>
                        <div className="flex-container _gap-narrow">
                          <button className={classNames('_zeroed', '_iconed', {
                            _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                            _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                            _blue: call.direction === 'outbound'
                          })}>
                            <Icon icon="arrow-20" className={classNames({
                              '_rotated-180': call.direction === 'outbound'
                            })} />
                          </button>
                          {
                            // reportData.permissions.call_call_show_dispatcher &&
                            <span>Dispatcher: {getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</span>
                          }
                        </div>
                      </td>
                      <td>
                        <div>
                          {call.caller_name}
                        </div>
                        {call.client ? (
                          <div
                            className="flex-container"
                            style={{
                              gap: '5px',
                              //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit'
                            }}
                            onClick={() =>
                              // reportData.permissions.client_show && call.client_id &&
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId, clientId: call.client_id
                              }, { reload: true })}
                            onContextMenu={(event) =>
                              // reportData.permissions.client_show && call.client_id &&
                              handleChangeMenu(event, call.client_id, 'clients', i)}
                          >
                            <button
                              style={{
                                gap: '5px',
                                //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                              }}
                              className="_zeroed _iconed _gray">
                              <Icon icon="user-32" />
                            </button>
                            <span>{call.client}</span>
                            {
                              // reportData.permissions.client_show && call.client_id &&
                              showContext === `clients_${call.client_id}_${i}` &&
                              <div
                                className="redirect-menu"
                                ref={contextMenuRef}
                                style={{ left: `${screenX}px` }}
                              >
                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    $router.router.navigate('clients.item', {
                                      companyId: activeAccountId, clientId: call.client_id
                                    }, { reload: true })
                                  }
                                  }
                                >
                                  Open
                                </button>

                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                                    setShowContext('')
                                  }}
                                >
                                  Open in new tab
                                </button>
                              </div>
                            }
                          </div>
                        ) : <span>&nbsp;</span>}
                      </td>
                      <td>
                        {/* <div>From: {call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</div> */}
                        <div>To: {call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</div>
                      </td>
                      <td>
                        <div>{call.friendly_name}</div>
                        <div
                          style={{ display: 'flex', alignItems: 'flex-end' }}
                        // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                        >
                          <span>
                            Extension: {call.extension}
                          </span>
                          {/* <span style={{ marginBottom: '1px' }} >
                      <Icon
                        style={{
                          width: '17px',
                          height: '17px',
                          marginLeft: '5px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }}
                        icon="phone-1" />
                    </span> */}
                        </div>
                      </td>
                      <td>
                        {call.is_appointment ? (
                          <button
                            className={classNames('_zeroed', '_iconed', {
                              _green: call.is_appointment === 'main',
                              _blue: call.is_appointment === 'lead'
                            })}
                            // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                            onClick={() =>
                              // reportData.permissions.appointment_show &&
                              call.appointment_id &&
                              $router.router.navigate('appointments.item', {
                                companyId: activeAccountId, appointmentId: call.appointment_id
                              }, { reload: true })}
                            onContextMenu={(event) =>
                              // reportData.permissions.appointment_show &&
                              call.appointment_id &&
                              handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                          >
                            <Icon icon="clipboard-5" />
                          </button>
                        ) : null}
                        {
                          // reportData.permissions.appointment_show &&
                          showContext === `appointments_${call.appointment_id}_${i}` &&
                          <div
                            className="redirect-menu"
                            ref={contextMenuRef}
                            style={{ left: `${screenX}px` }}
                          >
                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                $router.router.navigate('appointments.item', {
                                  companyId: activeAccountId, appointmentId: call.appointment_id
                                }, { reload: true })
                              }
                              }
                            >
                              Open
                            </button>

                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                                setShowContext('')
                              }}
                            >
                              Open in new tab
                            </button>
                          </div>
                        }
                      </td>
                      <td>
                        <div className={classNames({
                          'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                          'green-text': call.status === 'completed',
                          'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                        })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''}</div>
                        <div className="flex-container _gap-narrow">
                          <div>{call.duration}</div>
                          {
                            // reportData.permissions.call_call_record && 
                            call.recording_url ? (
                              <button
                                className="_zeroed _iconed"
                                onClick={() => setCallListenPopup(call.recording_url)}
                              >
                                <Icon icon="media-control-48" />
                              </button>
                            ) : null}
                        </div>
                      </td>
                    </tr>
                  )
                }
              })}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {data.calls && data.calls.map((call, i: number) => (
                <div className="item" key={i}>
                  {
                    i === 0 && dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at) &&
                    <div
                      className="item-name"
                      style={{
                        fontSize: '1em',
                        color: '#8391ad',
                        marginLeft: '20px',
                        fontWeight: 'initial',
                        position: 'relative',
                        width: '100%',
                        backgroundColor: '#dfe4ff',
                      }}
                    >
                      The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
                    </div>
                  }
                  {
                    i !== 0 &&
                    data.calls &&
                    dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at) &&
                    dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) < dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.calls[i - 1].created_at) &&
                    <div
                      className="item-name"
                      style={{
                        fontSize: '1em',
                        color: '#8391ad',
                        marginLeft: '20px',
                        fontWeight: 'initial',
                        position: 'relative',
                        width: '100%',
                        backgroundColor: '#dfe4ff',
                      }}
                    >
                      The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}
                    </div>
                  }
                  <div className="__top">
                    <div className="__left">
                      <div className="flex-container">
                        <button className={classNames('_zeroed', '_iconed', {
                          _red: ['no-answer', 'busy', 'canceled'].includes(call.status),
                          _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                          _blue: call.direction === 'outbound'
                        })}>
                          <Icon icon="arrow-20" className={classNames({
                            '_rotated-180': call.direction === 'outbound'
                          })} />
                        </button>
                        <strong>{call.friendly_name}</strong>
                      </div>
                      <div>
                        <strong>{call.caller_name}</strong>
                      </div>
                      {call.client ? (
                        <div
                          className="flex-container"
                          style={{
                            gap: '5px',
                            //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                          }}
                          onClick={() =>
                            // reportData.permissions.client_show && call.client_id &&
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId, clientId: call.client_id
                            }, { reload: true })}
                        >
                          <button
                            style={{
                              gap: '5px',
                              //  cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' 
                            }}
                            className="_zeroed _iconed _gray">
                            <Icon icon="user-32" />
                          </button>
                          <span>{call.client}</span>
                        </div>
                      ) : (<span>&nbsp;</span>)}
                    </div>

                    <div className="__right">
                      <div className={classNames({
                        'red-text': ['no-answer', 'busy', 'canceled'].includes(call.status),
                        'green-text': call.status === 'completed',
                        'blue-text': !['no-answer', 'busy', 'completed'].includes(call.status),
                      })}>
                        {call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''}
                      </div>
                      <div className="flex-container">
                        <span>{call.duration}</span>
                        {
                          // reportData.permissions.call_call_record &&
                          call.recording_url ? (
                            <button
                              className="_zeroed _iconed"
                              onClick={() => setCallListenPopup(call.recording_url)}
                            >
                              <Icon icon="media-control-48" />
                            </button>
                          ) : null}
                      </div>
                      {call.is_appointment ? (
                        <div>
                          <button
                            // style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                            onClick={() =>
                              // reportData.permissions.appointment_show &&
                              call.appointment_id &&
                              $router.router.navigate('appointments.item', {
                                companyId: activeAccountId, appointmentId: call.appointment_id
                              }, { reload: true })}
                            className={classNames('_zeroed', '_iconed', {
                              _green: call.is_appointment === 'main',
                              _blue: call.is_appointment === 'lead'
                            })}>
                            <Icon icon="clipboard-5" />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      {/* <div>
                    <span className="gray">From:</span>
                    <span> {call.direction === 'outbound' ? formatPhoneNumber(call.to_phone) : formatPhoneNumber(call.from_phone)}</span>
                  </div> */}
                      <div>
                        <span className="gray">To:</span>
                        <span> {call.direction === 'outbound' ? formatPhoneNumber(call.from_phone) : formatPhoneNumber(call.to_phone)}</span>
                      </div>
                      <div
                        style={{ display: 'flex' }}
                      // onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                      >
                        <span className="gray">Extension:</span>
                        <span style={{ margin: '0 5px' }} > {call.extension}</span>
                        {/* <span>
                        <Icon style={{
                          width: '16px',
                          height: '16px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }} icon="phone-1" />
                      </span> */}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}
                      </div>
                      {
                        // reportData.permissions.call_call_show_dispatcher &&
                        <div>
                          Dispatcher: {getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

      {
        permissions.website_request_messages_show &&
        <div className="fieldset">
          <div className="legend">
            Messages
          </div>

          <div style={{ maxHeight: '400px', overflow: 'auto', marginTop: '30px' }}>
            <div className='table'>
              <div className='thead'>
                <div className='tr'>
                  <div className='th'>
                    Contact Name
                  </div>

                  <div className='th'>
                    Friendly Name
                  </div>

                  <div className='th'>
                    To
                  </div>
                </div>
              </div>

              {
                data.message_dialogs && data.message_dialogs.map((mes, index) => (
                  <React.Fragment key={index}>
                    <div className='tr'>
                      <div className='td'> {mes.contact_name} </div>
                      <div className='td'> {mes.friendly_name} </div>
                      <div className='td'> {formatPhoneNumber(mes.to)} </div>
                    </div>
                    {
                      mes.messages && mes.messages.map((item, idx) => {
                        if (idx === 0 && dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)) {
                          return (
                            <React.Fragment key={idx}>
                              <div className='tr-received'>
                                The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at)}

                              </div>
                              <div className='tr-item'>
                                <div className='td'>
                                  <Icon
                                    viewBox="0 0 1024 1024"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                    }}
                                    icon="share-65"
                                  />
                                </div>
                                <div className='td'>
                                  {item.message}
                                </div>

                                <div className='td'>
                                  {item.direction}
                                </div>

                                <div
                                  className='td'
                                  style={{ position: 'relative' }}
                                >
                                  {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} {item.created_by && `(${item.created_by})`}
                                  {
                                    !!item.items.length &&
                                    <button
                                      style={{
                                        position: 'absolute',
                                        width: '16px',
                                        right: '20px',
                                        top: '5px',
                                        padding: '0'
                                      }}
                                      onClick={() => handleOpenImage(item.items[0].url, item.items[0].type)}
                                    >
                                      <Icon
                                        style={{ width: '16px', height: '16px', }}
                                        viewBox="0 0 16 16"
                                        icon="image-1"
                                      />
                                    </button>
                                  }
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        } else if (
                          idx !== 0 &&
                          dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) > dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at) &&
                          dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) < dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, mes.messages[idx - 1].created_at)) {
                          return (
                            <React.Fragment key={idx}>
                              <div className='tr-received'>
                                The request was received at {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) !== 'Invalid DateTime' ? dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at) : data.created_at}

                              </div>
                              <div className='tr-item'>
                                <div className='td'>
                                  <Icon
                                    viewBox="0 0 1024 1024"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                    }}
                                    icon="share-65"
                                  />
                                </div>
                                <div className='td'>
                                  {item.message}
                                </div>

                                <div className='td'>
                                  {item.direction}
                                </div>

                                <div
                                  className='td'
                                  style={{ position: 'relative' }}
                                >
                                  {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} {item.created_by && `(${item.created_by})`}
                                  {
                                    !!item.items.length &&
                                    <button
                                      style={{
                                        position: 'absolute',
                                        width: '16px',
                                        right: '20px',
                                        top: '5px',
                                        padding: '0'
                                      }}
                                      onClick={() => handleOpenImage(item.items[0].url, item.items[0].type)}
                                    >
                                      <Icon
                                        style={{ width: '16px', height: '16px', }}
                                        viewBox="0 0 16 16"
                                        icon="image-1"
                                      />
                                    </button>
                                  }
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        } else {
                          return (
                            <div
                              className='tr-item'
                              key={idx}
                            >
                              <div className='td'>
                                <Icon
                                  viewBox="0 0 1024 1024"
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                  }}
                                  icon="share-65"
                                />
                              </div>
                              <div className='td'>
                                {item.message}
                              </div>

                              <div className='td'>
                                {item.direction}
                              </div>

                              <div
                                className='td'
                                style={{ position: 'relative' }}
                              >
                                {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} {item.created_by && `(${item.created_by})`}
                                {
                                  !!item.items.length &&
                                  <button
                                    style={{
                                      position: 'absolute',
                                      width: '16px',
                                      right: '20px',
                                      top: '5px',
                                      padding: '0'
                                    }}
                                    onClick={() => handleOpenImage(item.items[0].url, item.items[0].type)}
                                  >
                                    <Icon
                                      style={{ width: '16px', height: '16px', }}
                                      viewBox="0 0 16 16"
                                      icon="image-1"
                                    />
                                  </button>
                                }
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </React.Fragment>
                ))
              }
            </div>
          </div>
        </div>
      }

      <div className="fieldset">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="legend">Update</div>

          <div style={{ display: 'flex' }}>
            {
              permissions.website_request_action_in_progress &&
              !data.is_closed && !data.is_in_progress &&
              <button
                className='in_progress'
                style={{ marginLeft: '20px' }}
                disabled={!isShowContact || isSaving}
                onClick={() => toggleInProgress(data.is_in_progress as boolean)}
              >
                In Progress
              </button>
            }
            {
              permissions.website_request_action_in_progress &&
              !data.is_closed && !!data.is_in_progress &&
              <button
                className='unclose'
                style={{ marginLeft: '20px' }}
                disabled={!isShowContact || isSaving}
                onClick={() => toggleInProgress(data.is_in_progress as boolean)}
              >
                In Progress
              </button>
            }
            {
              permissions.website_request_action_close &&
              <>
                {
                  !data.is_closed ?
                    <button
                      className='close'
                      style={{ marginLeft: '20px' }}
                      disabled={!permissions.website_request_action_close || !isShowContact || isSaving}
                      onClick={() => toggleClose(data.is_closed as boolean)}
                    >
                      Close
                    </button> :
                    <button
                      className='unclose'
                      style={{ marginLeft: '20px' }}
                      disabled={!permissions.website_request_action_close || !isShowContact || isSaving}
                      onClick={() => toggleClose(data.is_closed as boolean)}
                    >
                      Close
                    </button>
                }
              </>
            }
          </div>
        </div>

        {
          permissions.website_request_update_show &&
          < div
            style={{
              justifyContent: 'space-between',
              gridGap: 'inherit',
              textAlign: 'center'
            }}
            className="fields"
          >
            {
              permissions.website_request_update_send &&
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                position: 'relative'
              }}>

                <textarea
                  style={{ width: '100%' }}
                  placeholder='Your Comment'
                  value={message}
                  disabled={!isShowContact}
                  onChange={({ target: { value } }) => setMessage(value)}
                  onKeyDown={(event) => handleChangeMessage(event)}
                />

                <button
                  style={{
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px'
                  }}
                  className='send'
                  disabled={message === '' || !isShowContact || isSaving}
                  onClick={() => handleSendMessage()}
                >
                  Send
                </button>
              </div>
            }
          </div>
        }

        {/* show all screen */}
        <div className='chat'
        >
          {
            chat.map((message, i) => (
              <div key={i} className='chat-row chat-update'>
                <div className='chat-update-item'>
                  <p> {message.comment} </p>
                  <p style={{ textAlign: 'right' }} >
                    <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      {/* Call listen popup */}
      {callListenPopup ? (
        <div className="popup callListenPopup" onClick={() => setCallListenPopup(null)}>

          <div className="wrapper" onClick={(e) => e.stopPropagation()}>

            <div className="call-player">
              <AudioPlayer audioSrc={callListenPopup} />
            </div>
          </div>
        </div>
      ) : null}

      {
        openModal &&
        <ModalImage
          image={zoomImage}
          setOpen={setOpenModal}
        />
      }
    </div>
  )
}
