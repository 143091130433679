import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import ReactInputMask from "react-input-mask";

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, useOuterClick, validateEmail } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import Payment from "../../models/Payments"
import Deposite from "../../models/Deposite";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"
import "./Item.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PaymentData
}

interface HttpShowReceiptReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReceiptData
}

interface ReceiptData {
  email_notification_details: {
    notify_subject: string
    notify_text: string
  }
  phone_notification_details: {
    notify_text: string
  }
}

interface PaymentData {

  permissions: {
    payment_add: boolean
    payment_delete: boolean
    payment_edit: boolean
    payment_report_show: boolean
    payment_show: boolean
    payment_send_receipt: boolean


    client_show: boolean
    deposit_show: boolean
    invoice_show: boolean
    job_show: boolean
  },

  payment: Payment,
  deposits: Deposite[],

  edit: {
    status: {
      id: number
      name: string
    }[],
  }
}

function PaymentPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [showReceipt, setShowReceipt] = useState(false)
  const [receiptData, setReceiptData] = useState({
    isSendCheck: false,
    message: '',
    email_notification_details: {
      notify_subject: '',
      notify_text: '',
    },
    phone_notification_details: {
      notify_text: ''
    },
    payment_id: '',
    email: '',
    is_repeated_email: true,
    is_repeated_phone: true,
    phone: '',
    isErrorSendEmail: false,
    isErrorSendPhone: false,
    message_email: '',
    message_phone: '',
    isSendEmail: false,
    isSendPhone: false,
  })
  const [showEmailDetails, setShowEmailDetails] = useState(false)
  const [showPhoneDetails, setShowPhoneDetails] = useState(false)

  const [editing, setEditing] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [paymentData, setPaymentData] = useState<PaymentData | null>(null)

  const [newPaymentData, setNewPaymentData] = useState<Partial<Payment>>({
    status: '',
  })

  useEffect(() => {
    if (!showReceipt) {
      setReceiptData({
        isSendCheck: false,
        message: '',
        email_notification_details: {
          notify_subject: '',
          notify_text: '',
        },
        phone_notification_details: {
          notify_text: ''
        },
        payment_id: '',
        email: '',
        is_repeated_email: true,
        is_repeated_phone: true,
        phone: '',
        isErrorSendEmail: false,
        isErrorSendPhone: false,
        message_email: '',
        message_phone: '',
        isSendEmail: false,
        isSendPhone: false,
      })
    }
  }, [showReceipt])

  function handleCancelEdit() {
    setEditing(false)

    paymentData && setNewPaymentData(paymentData.payment)
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/payments/${$router.router.getState().params.paymentsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('payments', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function handleShowReceipt() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/payments/888883031zksh0x2gv/check?account_id=88888
      const response = await httpClientUpdate.get(`/payments/${$router.router.getState().params.paymentsId}/check`, {
        params: {
          account_id: activeAccountId,
        }
      }) as { data: HttpShowReceiptReport }
      if (response.data.success) {
        setReceiptData({
          ...receiptData,
          ...response.data.data
        })
        setShowReceipt(true)
        setIsSaving(false)
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/payments/${$router.router.getState().params.paymentsId}`, qs.stringify({
        account_id: activeAccountId,
        payment_id: newPaymentData.payment_id,
        type: newPaymentData.type,
        number: newPaymentData.number,
        status: newPaymentData.status_id,
        job_id: newPaymentData.job_id,
        contact_id: newPaymentData.contact_id,
        service_id: newPaymentData.service_id,
        invoice_id: newPaymentData.invoice_id,
        deposit_id: newPaymentData.deposit_id,
        total: newPaymentData.total,
        net: newPaymentData.net,
        fee: newPaymentData.fee
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: paymentData, success, error } } = await httpClientUpdate.get('/payments/' + $router.router.getState().params.paymentsId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (paymentData.permissions.payment_show) {
          setPaymentData({
            ...paymentData,
            permissions: {
              ...paymentData.permissions,
              payment_send_receipt: true
            }
          })
          setNewPaymentData(paymentData.payment)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSendEmail() {
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${$router.router.getState().params.paymentsId}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: receiptData.email,
        type: 'email',
        is_repeated: 1,
      }, { skipNulls: true }))

      if (success) {
        setReceiptData({
          ...receiptData,
          isErrorSendEmail: false,
          message_email: 'Success send',
          isSendEmail: true,
        })
      } else {
        setReceiptData({
          ...receiptData,
          isErrorSendEmail: true,
          message_email: 'The email has not been sent, try again later or change the email',
          isSendEmail: true,
        })
      }
    } catch (error) {
      setReceiptData({
        ...receiptData,
        isErrorSendEmail: true,
        message_email: 'The email has not been sent, try again later or change the email',
        isSendEmail: true,
      })
    }
  }

  async function handleSendPhone() {
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${$router.router.getState().params.paymentsId}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: formatPhoneNumberToServerString(receiptData.phone),
        type: 'phone',
        is_repeated: 1
      }, { skipNulls: true }))

      if (success) {
        setReceiptData({
          ...receiptData,
          isErrorSendPhone: false,
          message_phone: 'Success send',
          isSendPhone: true,
        })
      } else {
        setReceiptData({
          ...receiptData,
          isErrorSendPhone: true,
          message_phone: 'The receipt has not been sent, try again later or check the phone number',
          isSendPhone: true,
        })
      }
    } catch (error) {
      setReceiptData({
        ...receiptData,
        isErrorSendPhone: true,
        message_phone: 'The receipt has not been sent, try again later or check the phone number',
        isSendPhone: true,
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newPaymentData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (
    <>
      {paymentData &&
        paymentData.permissions.payment_show &&
        <div className="JobsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Payment</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'payments',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  paymentData?.permissions.payment_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div className="legend">Main</div>

              {
                paymentData.payment.status === 'Succeeded' &&
                paymentData.payment.type === 'Card' &&
                paymentData.permissions.payment_send_receipt &&
                <div>
                  <button
                    className='close'
                    style={{
                      backgroundColor: '#01B41F',
                      border: '1px solid #01B41F',
                      padding: '4px 20px',
                      height: 'fit-content',
                      width: 'fit-content'
                    }}
                    disabled={isSaving}
                    onClick={() => handleShowReceipt()}
                  >
                    <Icon
                      viewBox="0 0 1024 1024"
                      style={{
                        width: '30px',
                        height: '16px',
                        marginRight: '5px',
                        cursor: 'pointer',
                        fill: '#fff'
                      }}
                      icon="send-21"
                    />
                  </button>
                </div>
              }
            </div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Number:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.number}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Type:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.type}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Status:</span>
                  {
                    paymentData?.edit?.status ?
                      <Select disabled={!editing} options={paymentData.edit.status.map((option) => ({
                        span: option.name,
                        value: option.id
                      }))}
                        selectedOption={newPaymentData.status_id as number}
                        onChange={(value) => setNewPaymentData({ ...newPaymentData, status_id: value as number })}
                      /> :
                      <input type="text" defaultValue={newPaymentData.status} disabled={true} />
                  }
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Service:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.service_name}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Date:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newPaymentData.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Location:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.location_name}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Financial</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Net:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.net}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Total:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.total}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Fee:</span>

                  <input
                    type="text"
                    defaultValue={newPaymentData.fee}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Compensation:</span>

                  <input
                    type="text"
                    defaultValue={`$${newPaymentData.fee_compensation}`}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Items</div>

            <table className="table">
              <thead>
                <tr>
                  <th>Contact Name</th>
                  <th>Invoice Name</th>
                  <th>Job Name</th>
                  <th>Total</th>
                </tr>
              </thead>

              {
                paymentData.payment.items.map((item, i) => (
                  <tr
                    key={i}
                    style={{ cursor: 'inherit' }}
                  >
                    <td
                      style={{
                        cursor: paymentData.permissions.client_show && item.contact_id ? 'pointer' : 'inherit'
                      }}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        paymentData.permissions.client_show && item.contact_id && window.open(`${window.location.origin}/${activeAccountId}/clients/${item.contact_id}`, "_blank", 'noopener,noreferrer')
                      }}
                    >
                      {item.contact_name}
                    </td>

                    <td
                      style={{
                        cursor: paymentData.permissions.invoice_show && item.invoice_id ? 'pointer' : 'inherit'
                      }}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        paymentData.permissions.invoice_show && item.invoice_id && window.open(`${window.location.origin}/${activeAccountId}/invoices/${item.invoice_id}`, "_blank", 'noopener,noreferrer')
                      }}
                    >
                      {item.invoice_name}
                    </td>

                    <td
                      style={{
                        cursor: paymentData.permissions.job_show && item.job_id ? 'pointer' : 'inherit'
                      }}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        paymentData.permissions.job_show && item.job_id && window.open(`${window.location.origin}/${activeAccountId}/jobs/${item.job_id}`, "_blank", 'noopener,noreferrer')
                      }}
                    >
                      {item.job_name}
                    </td>

                    <td>
                      ${item.total_item}
                    </td>
                  </tr>
                ))
              }
            </table>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, paymentData?.payment.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    defaultValue={paymentData?.payment.created_by}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, paymentData?.payment.updated_at)}
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    defaultValue={paymentData?.payment.updated_by}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Deposits */}
          <div className="fieldset">
            <div className="legend">Deposits</div>

            <table className={classNames('table', '__show-on-wide', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Bank Date</th>
                  <th>Type</th>
                  <th>Service</th>
                  <th>Status</th>
                  <th>Payments</th>
                  <th>Net</th>
                  <th>Total</th>
                  <th>Fee</th>
                </tr>
              </thead>

              {paymentData.deposits && paymentData.deposits.map((deposit, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative' }}
                  onClick={() =>
                    deposit.deposit_id &&
                    $router.router.navigate('deposits.item', {
                      companyId: activeAccountId,
                      depositId: deposit.deposit_id,
                      fromPage: 'payments.item',
                      nameId: 'paymentsId',
                      id: $router.router.getState().params.paymentsId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    deposit.deposit_id &&
                    handleChangeMenu(event, deposit.deposit_id, 'deposits', i)}
                >
                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, deposit.created_at)}</td>
                  <td>{deposit.bank_date}</td>
                  <td>{deposit.type}</td>
                  <td>{deposit.service}</td>
                  <td>{deposit.status}</td>
                  <td>{deposit.payments}</td>
                  <td>{deposit.net}</td>
                  <td>{deposit.total}</td>
                  <td>
                    {deposit.fee}
                    {
                      // reportData.permissions.job_show &&
                      showContext === `deposits_${deposit.deposit_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('deposits.item', {
                              companyId: activeAccountId,
                              depositId: deposit.deposit_id,
                              fromPage: 'payments.item',
                              nameId: 'paymentsId',
                              id: $router.router.getState().params.paymentsId,
                              localInterface: $router.router.getState().params.localInterface
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/deposits/${deposit.deposit_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
              <tr></tr>
              {paymentData.deposits && paymentData.deposits.map((deposit, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative' }}
                  onClick={() =>
                    deposit.deposit_id &&
                    $router.router.navigate('deposits.item', {
                      companyId: activeAccountId,
                      depositId: deposit.deposit_id,
                      fromPage: 'payments.item',
                      nameId: 'paymentsId',
                      id: $router.router.getState().params.paymentsId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    deposit.deposit_id &&
                    handleChangeMenu(event, deposit.deposit_id, 'deposits', i)}
                >
                  <td>
                    <div>{deposit.service}</div>
                    <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, deposit.created_at)}</div>
                  </td>
                  <td>
                    <div>{deposit.status}</div>
                    <div>{deposit.bank_date}</div>
                  </td>
                  <td>
                    <div>Type: {deposit.type}</div>
                    <div>Payments: {deposit.payments}</div>
                  </td>
                  <td>
                    <div>Total: {deposit.total}</div>
                  </td>
                  <td>
                    <div>Net: {deposit.net}</div>
                    <div>Fee: {deposit.fee}</div>
                    {
                      // reportData.permissions.job_show &&
                      showContext === `deposits_${deposit.deposit_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('deposits.item', {
                              companyId: activeAccountId,
                              depositId: deposit.deposit_id,
                              fromPage: 'payments.item',
                              nameId: 'paymentsId',
                              id: $router.router.getState().params.paymentsId,
                              localInterface: $router.router.getState().params.localInterface
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/deposits/${deposit.deposit_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            <div className={classNames('mobile-table', '__show-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>

              <div className="mobile-table-items">

                {paymentData.deposits && paymentData.deposits.map((deposit, i: number) => (
                  <div
                    className="item"
                    key={i}
                    onClick={() =>
                      deposit.deposit_id &&
                      $router.router.navigate('deposits.item', {
                        companyId: activeAccountId,
                        depositId: deposit.deposit_id,
                        fromPage: 'payments.item',
                        nameId: 'paymentsId',
                        id: $router.router.getState().params.paymentsId,
                        localInterface: $router.router.getState().params.localInterface
                      }, { reload: true })}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div><strong>{deposit.service}</strong> ({deposit.bank_date}) </div>
                        <div>
                          {deposit.type}
                        </div>
                        <div>
                          <strong>{deposit.status}</strong>
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          Net: <b>{deposit.net}</b>
                        </div>
                        <div>
                          Total: <b>{deposit.total}</b>
                        </div>
                        <div>
                          Fee: <b>{deposit.fee}</b>
                        </div>
                      </div>
                    </div>

                    <div className="__bottom">

                      <div className="__left">
                        <div>
                          <span className="gray">Payments:</span>
                          <span> {deposit.payments}</span>
                        </div>
                      </div>

                      <div className="__right small">
                        <div>
                          <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, deposit.created_at)}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {editing &&
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  paymentData.permissions.payment_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Payment</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>}

          {/* Item share popup */}
          {
            paymentData.permissions.payment_delete &&
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Payment
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the payment it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }

          {
            showReceipt && receiptData &&
            <div className="item-delete-popup" onClick={() => setShowReceipt(false)}>

              <div style={{ maxWidth: '500px' }} className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div style={{ background: '#6093de' }} className="title">
                  Payment Send Receipt
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}
                  className="send-check-content"
                >
                  {
                    receiptData.email_notification_details.notify_text &&
                    <button
                      className={classNames('_bordered', '_blue', {
                        __show: showEmailDetails
                      })}
                      style={{ width: 'fit-content' }}
                      onClick={() => setShowEmailDetails(!showEmailDetails)}
                    >
                      Send by Email
                    </button>
                  }

                  {
                    showEmailDetails &&
                    <>
                      {
                        receiptData.isErrorSendEmail && receiptData.isErrorSendEmail &&
                        <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                          {receiptData.message_email}
                        </p>
                      }

                      {
                        receiptData.isSendEmail && !receiptData.isErrorSendEmail &&
                        <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {receiptData.message_email} </p>
                      }

                      <div
                        className='email-label'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          margin: '10px 0',
                        }}
                      >
                        <span style={{ width: '100px', marginRight: '20px' }}>Email: </span>
                        <input
                          type="text"
                          value={receiptData.email}
                          onChange={(event) => setReceiptData({ ...receiptData, email: event.target.value })}
                        />
                      </div>

                      <div
                        className='email-label'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          margin: '10px 0',
                        }}
                      >
                        <span style={{ width: '100px', marginRight: '20px' }} > Subject: </span>

                        <span
                          className='span-input'
                          style={{
                            border: '1px solid #d0d3da!important',
                            display: 'block',
                            width: '100%',
                            padding: '7px 15px',
                            // appearance: none
                            borderRadius: '5px',
                            font: '1em Roboto, sans-serif',
                            marginRight: '0',
                            minHeight: '34px',
                          }}
                        >
                          {receiptData.email_notification_details.notify_subject}
                        </span>
                      </div>

                      <div
                        className='email-label'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          margin: '10px 0',
                        }}
                      >
                        <div
                          className='text-block'
                          style={{
                            width: '100%',
                            minHeight: '50px',
                            padding: '10px',
                            border: '1px solid #d0d3da',
                            borderRadius: '5px',
                          }}
                        >
                          <div dangerouslySetInnerHTML={{ __html: receiptData.email_notification_details.notify_text ? receiptData.email_notification_details.notify_text : '' }}></div>
                        </div>
                      </div>

                      <div className='btns'>
                        <div
                          className={classNames({
                            __is_repeated: !receiptData.isSendEmail
                          })}
                        >
                          <Checkbox
                            contents='Resend?'
                            value={receiptData.is_repeated_email}
                            disabled={true}
                            onChange={(value) => setReceiptData({ ...receiptData, is_repeated_email: value })}
                          />
                        </div>

                        <button
                          className="_bordered _green"
                          style={{ whiteSpace: 'nowrap' }}
                          disabled={receiptData.email === '' || receiptData.email === '' || !validateEmail(receiptData.email) || (receiptData.isSendEmail && !receiptData.is_repeated_email)}
                          onClick={handleSendEmail}
                        >
                          Send
                        </button>
                      </div>
                    </>
                  }

                  {
                    receiptData.phone_notification_details.notify_text &&
                    <button
                      className={classNames('_bordered', '_blue', {
                        __show: showPhoneDetails
                      })}
                      style={{ width: 'fit-content' }}
                      onClick={() => setShowPhoneDetails(!showPhoneDetails)}
                    >
                      Send by SMS
                    </button>
                  }

                  {
                    showPhoneDetails &&
                    <>
                      {
                        receiptData.isSendPhone && receiptData.isErrorSendPhone &&
                        <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                          {receiptData.message_phone}
                        </p>
                      }

                      {
                        receiptData.isSendPhone && !receiptData.isErrorSendPhone &&
                        <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {receiptData.message_phone} </p>
                      }

                      <div
                        className='email-label'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          margin: '10px 0',
                        }}
                      >
                        <span style={{ width: '100px', marginRight: '20px' }}>Phone: </span>

                        <ReactInputMask
                          type="text"
                          mask="+1 (999) 999-9999"
                          value={receiptData.phone}
                          onChange={({ target: { value } }) => setReceiptData({ ...receiptData, phone: value })}
                        />
                      </div>

                      <div
                        className='email-label'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          margin: '10px 0',
                        }}
                      >
                        <div
                          className='text-block'
                          style={{
                            width: '100%',
                            minHeight: '50px',
                            padding: '10px',
                            border: '1px solid #d0d3da',
                            borderRadius: '5px',
                          }}
                        >
                          <div dangerouslySetInnerHTML={{ __html: receiptData.phone_notification_details.notify_text ? receiptData.phone_notification_details.notify_text : '' }}></div>
                        </div>
                      </div>

                      <div className='btns'>
                        <div
                          className={classNames({
                            __is_repeated: !receiptData.isSendPhone
                          })}
                        >
                          <Checkbox
                            contents='Resend?'
                            value={receiptData.is_repeated_phone}
                            disabled={true}
                            onChange={(value) => setReceiptData({ ...receiptData, is_repeated_phone: value })}
                          />
                        </div>

                        <button
                          className="_bordered _green"
                          style={{ whiteSpace: 'nowrap' }}
                          disabled={formatPhoneNumberToServerString(receiptData.phone).length !== 12 || (receiptData.isSendPhone && !receiptData.is_repeated_phone)}
                          onClick={handleSendPhone}
                        >
                          Send
                        </button>
                      </div>
                    </>
                  }
                </div>

                <div style={{ justifyContent: 'flex-end' }} className="buttons">
                  <button
                    style={{ width: 'fit-content' }}
                    className="_bordered _green"
                    onClick={() => {
                      setShowReceipt(false)
                      setShowEmailDetails(false)
                      setShowPhoneDetails(false)
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          }

        </div>
      }
    </>
  )
}

export default PaymentPage_Item