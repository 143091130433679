import { useState, useEffect } from 'react'
import qs from 'qs'

import Select from '../../components/Select'
import Icon from '../../components/Icon'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import "../../styles/pages/common/entity-edit.sass"

interface Part {
  status_id: number
  name: string
  part_number: number | null
  price: number
  warehouse: string
  warehouse_id: string
  other_warehouse: string
  part_name: string
  part_owner: string
}

interface PartsAddProps {
  switchPage: (name: string) => void
  jobId: string
  edit: {
    part_status: {
      part_status: string
      part_status_id: number
    }[]
    warehouses: {
      warehouse: string
      warehouse_id: string
    }[]
  }
}

export default function PartsAdd({ switchPage, jobId, edit }: PartsAddProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newData, setNewData] = useState<Part>({
    status_id: 0,
    name: '',
    part_number: null,
    price: 0,
    warehouse: '',
    warehouse_id: '',
    other_warehouse: '',
    part_name: '',
    part_owner: 'Technician'
  })

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  function handleCancelEdit() {
    switchPage('main')
  }

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/parts`, qs.stringify({
      account_id: activeAccountId,
      status_id: newData.status_id,
      price: newData.price,
      part_number: newData.part_number,
      name: newData.name,
      warehouse_id: newData.warehouse_id,
      other_warehouse: newData.other_warehouse,
      owner: newData.part_owner,
      job_id: jobId,
      invoice_id: '88888292aaaaaaaaaa'
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChangePrice(event: any) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewData({
        ...newData,
        price: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function checkOtherField() {
    let warehouses = edit.warehouses.filter(item => item.warehouse_id === newData.warehouse_id)
    return warehouses?.length === 1 ? warehouses[0].warehouse : ''
  }

  return (
    <div className="EstimatePage_Edit entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Add new Part</h1>

        <div style={{ display: 'flex' }} >
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
            className="_wa"
            onClick={() => switchPage('main')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>
      </div>

      <div className="fieldset">
        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Status:</span>

              <Select
                options={edit.part_status.sort((a, b) => a.part_status.localeCompare(b.part_status)).map((option) => ({
                  span: option.part_status,
                  value: option.part_status_id
                }))}
                selectedOption={newData.status_id as number}
                onChange={(value) => setNewData({ ...newData, status_id: value as number })}
              />
            </div>

            <div className="field">
              <span>Part Number:</span>
              <input
                type="number"
                defaultValue={newData.part_number || ''}
                onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
              />
            </div>

            <div className="field">
              <span>Name:</span>
              <input
                type="text"
                defaultValue={newData.name}
                onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Price:</span>
              <input
                type="number"
                pattern="[0-9]*"
                style={{ textAlign: 'right' }}
                value={newData.price.toFixed(2)}
                onFocus={(event) => handleFocus(event)}
                onChange={(event) => handleChangePrice(event)}
                onKeyDown={handleChangePriceBackspace}
              />
            </div>

            <div className="field">
              <span>Warehouse:</span>
              <Select
                options={edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                  span: option.warehouse,
                  value: option.warehouse_id
                }))}
                selectedOption={newData.warehouse_id as string}
                onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
              />
            </div>

            {
              checkOtherField() === 'Other' &&
              <div className="field">
                <span>Other Warehouse:</span>

                <input
                  type="text"
                  defaultValue={newData.other_warehouse}
                  onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                />
              </div>
            }

            <div className="field">
              <span>Part`s Owner:</span>
              <Select
                options={['Technician', 'Company'].map((option) => ({
                  span: option,
                  value: option
                }))}
                selectedOption={newData.part_owner as string}
                onChange={(value) => setNewData({ ...newData, part_owner: value as string })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => handleCancelEdit()}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
