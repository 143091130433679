import { useEffect, useState } from 'react';
import { useRoute } from "react-router5"

import ReportFilters from "../../components/reports/Filters"

import ServiceAreaMap from './ServiceAreaMap'
import ServiceResourcesMap from './ServiceResourcesMap';
import JobMap from './JobMap';

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from '../../funcs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';

import './Tools.sass'

interface ListProps {
  updated: number
}

interface HttpListData {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    permissions: Permissions
  }
}

interface Permissions {
  tools_service_area_map: boolean
  tools_service_resources_areas: boolean
  tools_job_map: boolean
}

export default function List({ updated }: ListProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [selectedSearch, setSelectedSearch] = useState('')
  const [selectSearchOptions, setSelectSearchOptions] = useState<string[]>([])

  useEffect(() => {
    setSelectedSearch('')
  }, [updated])

  useEffect(() => {
    loadList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadList() {
    // https://2022back4.artemiudintsev.com/api/tools?account_id=88888
    try {
      const { data: { data: permissionsData, success, error } } = (await httpClientUpdate.get('/tools', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpListData }
      if (success) {
        let permissionsOption: string[] = []
        Object.keys(permissionsData.permissions).forEach(item => {
          if (permissionsData.permissions[item]) {
            if (item === 'tools_service_area_map') {
              permissionsOption.push('Service Area Map')
            } else if (item === 'tools_service_resources_areas') {
              permissionsOption.push('Service Resources Areas')
            } else if (item === 'tools_job_map') {
              permissionsOption.push('Jobs Map')
            }
          }
        })
        setSelectSearchOptions(permissionsOption)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  return (
    <div className="ReportsPage_List">
      <div className="page-header">
        <h1>Tools</h1>
      </div>

      <ReportFilters
        selectSearch={true}
        selectedSearch={selectedSearch}
        setSelectedSearch={setSelectedSearch}
        selectSearchOptions={selectSearchOptions}

        onUpdate={() => $setUpdater(Math.random())}

        phoneCall={phoneCall}
        navActive={navActive.is}
      />

      {
        selectedSearch === 'Service Area Map' &&
        <ServiceAreaMap />
      }
      {
        selectedSearch === 'Service Resources Areas' &&
        <ServiceResourcesMap
          activeAccountId={activeAccountId ? activeAccountId : ''}
        />
      }
      {
        selectedSearch === 'Jobs Map' &&
        <JobMap
          activeAccountId={activeAccountId ? activeAccountId : ''}
        />
      }
    </div>
  )
}
