import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import qs from "qs";
import classNames from "classnames";
import moment from "moment";

import Select from "../../components/Select"
import Icon from "../../components/Icon";
import IncomingCallSvg from "../../components/IncomingCallSvg";

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { Payout } from '../../models/Payouts'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PayoutData
}

interface PayoutData {

  permissions: {
    payment_add: boolean
    payment_delete: boolean
    payment_edit: boolean
    payment_report_show: boolean
    payment_show: boolean
  },
  payout: Payout

  edit: {
    status: {
      id: number,
      name: string
    }[],
    service_resources: {
      code: number
      nickname: string
      service_resource_id: string
    }[]
  }
}
const invoicesData = [
  {
    invoice_id: '1',
    date: '01/11/2023',
    name: 'Mike',
    status: 'Complete',
    total: 215,
    paid: 100,
    unpaid: 50,
    deposited: 65,
    parts: '1',
  },
  {
    invoice_id: '2',
    date: '01/21/2023',
    name: 'Mike',
    status: 'Complete',
    total: 815,
    paid: 315,
    unpaid: 0,
    deposited: 500,
    parts: '1',
  },
  {
    invoice_id: '3',
    date: '01/25/2023',
    name: 'Mike',
    status: 'Complete',
    total: 400,
    paid: 200,
    unpaid: 150,
    deposited: 50,
    parts: '1',
  }
]

interface InvoicesProps {
  invoice_id: string,
  date: string,
  name: string,
  status: string,
  total: number,
  paid: number,
  unpaid: number,
  deposited: number,
  parts: string,
  check?: boolean,
  next?: boolean
}

interface EditProps {
  status: {
    id: number,
    name: string
  }[],
  service_resources: {
    code: number
    nickname: string
    service_resource_id: string
  }[]
}

export default function PayoutPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [invoices, setInvoices] = useState<InvoicesProps[] | []>([])
  const [payoutEdit, setPayoutEdit] = useState<EditProps>({
    status: [],
    service_resources: []
  })
  const [newPayoutData, setNewPayoutData] = useState<Partial<Payout>>({})

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.post(`/payouts`, qs.stringify({
        account_id: activeAccountId,
        service_resource_id: newPayoutData.service_resource_id,
        status_id: newPayoutData.status_id,
        date_start: moment(newPayoutData.date_start).format('YYYY-MM-DD'),
        date_end: moment(newPayoutData.date_end).format('YYYY-MM-DD'),
        type: newPayoutData.type,
        // amount: newPayoutData.amount
      }))

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('payouts', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: payoutsData, success, error } } = (await httpClientUpdate.get('/payouts/report', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId,
          limit_rows: reportsMaxRows,
          page: 1,
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setPayoutEdit(payoutsData.edit)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load client data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newPayoutData
  ])

  function handleDownloadInvoices() {
    let download = invoicesData.map(item => {
      return {
        ...item,
        check: false,
        next: false
      }
    })
    setInvoices(download)
  }

  function getSum(name: string) {
    let sum = 0
    invoices.length && invoices.forEach(item => sum = sum + item[name])
    return sum
  }

  function handleChangeChecbox(id: string, name: string) {
    let updatedInvoices = invoices.map(item => {
      if (item.invoice_id === id) {
        return {
          ...item,
          [name]: !item[name]
        }
      } else {
        return item
      }
    })
    setInvoices(updatedInvoices)
  }

  return (
    <div className="PayoutPage_Item PayoutPage_New entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Payout</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('payouts', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>
              <Select options={payoutEdit.service_resources.map((option) => ({
                span: option.code ? `${option.nickname}(${option.code})` : option.nickname,
                value: option.service_resource_id
              }))}
                selectedOption={newPayoutData.service_resource_id as string}
                onChange={(value) => setNewPayoutData({ ...newPayoutData, service_resource_id: value as string })}
              />
            </div>

            <div className="field">
              <span>Type:</span>
              <input
                type="text"
                defaultValue={newPayoutData.type}
                onChange={(event) => setNewPayoutData({
                  ...newPayoutData, type: event.target.value
                })}
              />
            </div>

            <div className="field">
              <span>Status:</span>
              <Select options={payoutEdit.status.map((option) => ({
                span: option.name,
                value: option.id
              }))}
                selectedOption={newPayoutData.status_id as string}
                onChange={(value) => setNewPayoutData({ ...newPayoutData, status_id: value as string })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>From:</span>
              <Datetime
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                value={newPayoutData.date_start}
                onChange={(value) => setNewPayoutData({ ...newPayoutData, date_start: moment.isMoment(value) ? value.toDate() : new Date() })}
              />
            </div>

            <div className="field">
              <span>To:</span>
              <Datetime
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                value={newPayoutData.date_end}
                onChange={(value) => setNewPayoutData({ ...newPayoutData, date_end: moment.isMoment(value) ? value.toDate() : new Date() })}
              />
            </div>

            {/* <div className="field">
              <span>Amount:</span>
              <input
                type="text"
                defaultValue={newPayoutData.amount}
                onChange={(event) => setNewPayoutData({
                  ...newPayoutData, amount: event.target.value
                })}
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* Invoices */}
      <div className="fieldset">
        <div style={{ display: 'flex', alignItems: 'center' }} className="legend">
          Invoices
          <span
            onClick={() => handleDownloadInvoices()}
          >
            <Icon
              viewBox="0 0 16 16"
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
              icon="download-1"
            />
          </span>
        </div>

        <table className={classNames('table', '__show-on-wide', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Status</th>
              <th>Total</th>
              <th>Paid</th>
              <th>Unpaid</th>
              <th>Deposited</th>
              <th>Parts</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          {invoices && invoices.map((invoice, i) => (
            <tr
              key={i}
            // onClick={() => $router.router.navigate('deposits.item', {
            //   companyId: activeAccountId, depositId: deposit.deposit_id
            // }, { reload: true })}
            >
              <td>{invoice.date}</td>
              <td>{invoice.name}</td>
              <td>{invoice.status}</td>
              <td>${invoice.total}</td>
              <td>${invoice.paid}</td>
              <td>${invoice.unpaid}</td>
              <td>${invoice.deposited}</td>
              <td>{invoice.parts}</td>
              <td
                onClick={() => handleChangeChecbox(invoice.invoice_id, 'check')}
              >
                <Icon
                  style={{ width: '20px', height: '20px', fill: invoice.check ? '#01B41F' : '#C3C6CB' }}
                  icon="check-mark-1"
                />
              </td>
              <td
                onClick={() => handleChangeChecbox(invoice.invoice_id, 'next')}
              >
                <IncomingCallSvg width='20px' height='20px' color={invoice.next ? '#FFB700' : '#C3C6CB'} />
              </td>
            </tr>
          ))}

          {
            !!invoices.length &&
            <tr>
              <td>In total</td>
              <td></td>
              <td></td>

              <td> ${getSum('total')} </td>
              <td> ${getSum('paid')} </td>
              <td> ${getSum('unpaid')} </td>
              <td> ${getSum('deposited')} </td>

              <td></td>
              <td></td>
              <td></td>
            </tr>
          }
          {
            !!invoices.length &&
            <tr>
              <td></td>
              <td></td>
              <td></td>

              <td> Orders: 18 </td>
              <td> Avg. Labor: $212.15 </td>
              <td> Labor: $3818.70 </td>
              <td> Not Deposited: $640.00 </td>

              <td> Payout: $1215.87 </td>
              <td></td>
              <td></td>
            </tr>
          }

        </table>

        <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>
          <tr></tr>
          {invoices.map((invoice, i) => (
            <tr
              key={i}
            // onClick={() => $router.router.navigate('deposits.item', {
            //   companyId: activeAccountId, depositId: deposit.deposit_id
            // }, { reload: true })}
            >
              <td>
                <div>{invoice.date}</div>
                <div>{invoice.name}</div>
              </td>
              <td>
                <div>{invoice.status}</div>
                <div>Total: ${invoice.total}</div>
              </td>
              <td>
                <div>Paid: ${invoice.paid}</div>
                <div>Unpaid: ${invoice.unpaid}</div>
                <div>Deposited: ${invoice.deposited}</div>
              </td>
              <td>
                <div>Parts: {invoice.parts}</div>
              </td>
              <td>
                <div
                  onClick={() => handleChangeChecbox(invoice.invoice_id, 'check')}
                >
                  Check:
                  <Icon
                    style={{ width: '20px', height: '20px', fill: invoice.check ? '#01B41F' : '#C3C6CB' }}
                    icon="check-mark-1"
                  />
                </div>
                <div
                  onClick={() => handleChangeChecbox(invoice.invoice_id, 'next')}
                >
                  Next:
                  <IncomingCallSvg width='20px' height='20px' color={invoice.next ? '#FFB700' : '#C3C6CB'} />
                </div>
              </td>
            </tr>
          ))}

          {
            !!invoices.length &&
            <tr>
              <td>In total</td>

              <td>Total: ${getSum('total')} </td>
              <td>Paid: ${getSum('paid')} </td>
              <td>Unpaid: ${getSum('unpaid')} </td>
              <td>Deposited: ${getSum('deposited')} </td>
            </tr>
          }
          {
            !!invoices.length &&
            <tr>
              <td> Orders: 18 </td>
              <td> Avg. Labor: $212.15 </td>
              <td> Labor: $3818.70 </td>
              <td> Not Deposited: $640.00 </td>

              <td> Payout: $1215.87 </td>
            </tr>
          }
        </table>

        <div className={classNames('mobile-table', '__show-on-mobile', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>

          <div className="mobile-table-items">

            {invoices.map((invoice, i: number) => (
              <div
                className="item"
                key={i}
              //  onClick={() => $router.router.navigate('deposits.item', {
              //   companyId: activeAccountId, depositId: deposit.deposit_id
              // }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><strong>{invoice.name}</strong> ({invoice.date}) </div>
                    <div>
                      {invoice.status}
                    </div>
                    <div>
                      <strong>${invoice.total}</strong>
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Paid: <b>${invoice.paid}</b>
                    </div>
                    <div>
                      Unpaid: <b>${invoice.unpaid}</b>
                    </div>
                    <div>
                      Deposited: <b>${invoice.deposited}</b>
                    </div>
                  </div>
                </div>

                <div className="__bottom">

                  <div
                    className="__left"
                    onClick={() => handleChangeChecbox(invoice.invoice_id, 'check')}
                  >
                    <Icon
                      style={{ width: '20px', height: '20px', fill: invoice.check ? '#01B41F' : '#C3C6CB' }}
                      icon="check-mark-1"
                    />
                  </div>

                  <div
                    className="__right small"
                    onClick={() => handleChangeChecbox(invoice.invoice_id, 'next')}
                  >
                    <IncomingCallSvg width='20px' height='20px' color={invoice.next ? '#FFB700' : '#C3C6CB'} />
                  </div>
                </div>
              </div>
            ))}
            {
              !!invoices.length &&
              <div
                className="item"
              //  onClick={() => $router.router.navigate('deposits.item', {
              //   companyId: activeAccountId, depositId: deposit.deposit_id
              // }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div>Total: ${getSum('total')} </div>
                    <div>
                      Paid: ${getSum('paid')}
                    </div>
                    <div>
                      Unpaid: ${getSum('unpaid')}
                    </div>
                    <div>
                      Deposited: ${getSum('deposited')}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Orders: <b>18</b>
                    </div>
                    <div>
                      Avg. Labor: <b>$212.15</b>
                    </div>
                    <div>
                      Labor: <b>$3818.70</b>
                    </div>
                    <div>
                      Not Deposited: <b>$640.00</b>
                    </div>
                    <div>
                      Payout: <b>$1215.87</b>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('payouts', {
              companyId: activeAccountId,
            }, { reload: true })}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave || isSaving}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
