import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";

import Icon from "../../components/Icon"
import AccountPermission from "../../models/AccountPermission"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import AccountMenus from "../../models/AccountMenus";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

export interface HttpUsersMenusItemReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UsersMenusData
}

interface UsersMenusEdit {
  id: number,
  name: string
}

interface Users {
  active: string,
  first_name: string,
  function: string,
  last_name: string,
  user_id: string
}

interface UsersMenusData {

  permissions: {
    user_menu_add: boolean
    user_menu_delete: boolean
    user_menu_edit: boolean
    user_menu_report_show: boolean
    user_menu_show: boolean
    user_show: boolean
  },

  permission: AccountPermission,
  menu: AccountMenus[],
  users: Users[],
  menu_items: UsersMenusEdit[],
  edit: {
    menu_items: UsersMenusEdit[]
  }
}

function UsersMenusPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [data, setData] = useState<UsersMenusData | null>(null)

  const [selectedAction, setSelectedAction] = useState<string | number>('')

  const [newData, setNewData] = useState<Partial<AccountMenus>>({
    name: '',
    menu_items: [] as UsersMenusEdit[]
  })

  const [availableActions, setAvailableActions] = useState<UsersMenusEdit[]>([])

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/menus/users/${$router.router.getState().params.usersMenusId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('users_menus', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/menus/users/${$router.router.getState().params.usersMenusId}`, qs.stringify({
        account_id: activeAccountId,
        name: newData.name,
        items: JSON.stringify(newData.menu_items?.map(item => item.id))
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data, success, error } } = await httpClientUpdate.get('/menus/users/' + $router.router.getState().params.usersMenusId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpUsersMenusItemReport }
      if (success) {
        if (data.permissions.user_menu_show) {
          let _availableActions = data.edit.menu_items.filter(action => !data.menu_items.filter(item => item.id === action.id).length && action)
          setData(data)
          setAvailableActions(_availableActions)
          setSelectedAction('')
          setNewData({
            ...data.menu,
            menu_items: data.menu_items
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load permission data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add action function
  function addAction() {

    if (!newData.menu_items || !data || !newData) return

    let updateActions = newData.menu_items.map(item => item)
    data.edit.menu_items.forEach(item => item.id === selectedAction && updateActions.push(item))
    setNewData({
      ...newData,
      menu_items: updateActions
    })

    let _availableActions = data.edit.menu_items.filter(action => !(newData.menu_items as UsersMenusEdit[]).filter(item => item.id === action.id).length && action)
    _availableActions.splice(_availableActions.findIndex((_action) => selectedAction === _action.id), 1)

    setAvailableActions([
      ..._availableActions
    ])

    setSelectedAction('')

    setReadyToSave(true)
  }

  // Remove action function
  function removeAction(i: number) {

    let _menusActions = [...(newData.menu_items as UsersMenusEdit[])]

    let _action = _menusActions[i]

    _menusActions.splice(i, 1)

    setNewData({
      ...newData,
      menu_items: _menusActions
    })

    setAvailableActions([
      ...availableActions,
      _action
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {
        data &&
        data.permissions.user_menu_show &&
        newData && (
          <div className="AccountPermissionsPage_Item entity-edit">
            <div className="wrapper flex-container sb">
              <div className="flex-container _gap-narrow">
                <h1>User`s Menus:</h1>
                <div className="item-name">
                  {newData.name}
                </div>
              </div>

              <div style={{ display: 'flex' }} >
                <button
                  disabled={editing}
                  style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  onClick={() =>
                    $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'users_menus',
                      {
                        companyId: activeAccountId,
                        [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                        localInterface: $router.router.getState().params.localInterface
                      }, { reload: true })}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>

                {
                  !editing &&
                    data?.permissions.user_menu_add ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      Edit
                    </button>
                  ) : null
                }
              </div>
            </div>

            <div className="fieldset">
              <div className="legend">Main</div>

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Name:</span>
                    <input type="text"
                      defaultValue={newData.name}
                      disabled={!editing}
                      onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fieldset">
              <div className="flex-container sb wrap">
                <div className="legend">Menus</div>

                <div className="legend-action-wrapper">
                  <label>Menu:</label>
                  <div className="input-wrapper">
                    <Select
                      disabled={!editing}
                      options={availableActions.map((action) => ({
                        span: action.name,
                        value: action.id
                      }))}
                      selectedOption={selectedAction}
                      onChange={(value) => setSelectedAction(value as string)} />
                    <button
                      disabled={!selectedAction}
                      className="_green" onClick={() => addAction()}>
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <table className="table som">
                <thead>
                  <tr>
                    <th style={{ width: '100%' }}>Menu</th>
                    <th>Allow</th>
                    <th></th>
                  </tr>
                </thead>

                {(newData.menu_items as UsersMenusEdit[]).map((action, i) => (
                  <tr
                    key={i}
                    style={{ cursor: editing ? 'pointer' : 'inherit' }}
                  >
                    <td>{action.name}</td>
                    <td>
                      <Checkbox contents={''} value={true} />
                    </td>
                    <td>
                      <button
                        disabled={!editing}
                        className="_zeroed _iconed _red"
                        onClick={() => removeAction(i)}>
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="fieldset">
              <div className="legend">Info</div>

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Date Created:</span>
                    <input
                      type="text"
                      defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newData.created_at)}
                      disabled={true}
                    />
                  </div>

                  <div className="field">
                    <span>Created by:</span>
                    <input type="text" defaultValue={newData.created_by} disabled={true} />
                  </div>
                </div>

                <div className="__right">

                  <div className="field">
                    <span>Last Edited:</span>
                    <input
                      type="text"
                      defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newData.created_at)}
                      disabled={true}
                    />
                  </div>

                  <div className="field">
                    <span>Last Edited by:</span>
                    <input type="text" defaultValue={newData.updated_by} disabled={true} />
                  </div>
                </div>
              </div>
            </div>

            <div className="fieldset">
              <div className="legend">Users</div>

              <table className="table">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Function</th>
                    <th>Active</th>
                  </tr>
                </thead>

                {data.users.map((user, i) => (
                  <tr
                    key={i}
                    style={{ cursor: data.permissions.user_show && user.user_id ? 'pointer' : 'inherit', position: 'relative' }}
                    onClick={() =>
                      data.permissions.user_show &&
                      user.user_id &&
                      $router.router.navigate('users.item', {
                        companyId: activeAccountId,
                        userId: user.user_id,
                        fromPage: 'users_menus.item',
                        nameId: 'usersMenusId',
                        id: $router.router.getState().params.usersMenusId,
                        localInterface: $router.router.getState().params.localInterface
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      data.permissions.user_show &&
                      user.user_id &&
                      handleChangeMenu(event, user.user_id, 'users', i)}
                  >
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.function}</td>
                    <td>
                      {user.active}
                      {
                        data.permissions.user_show &&
                        showContext === `users_${user.user_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('users.item', {
                                companyId: activeAccountId,
                                userId: user.user_id,
                                fromPage: 'users_menus.item',
                                nameId: 'usersMenusId',
                                id: $router.router.getState().params.usersMenusId,
                                localInterface: $router.router.getState().params.localInterface
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/users/${user.user_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            {
              editing &&
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.user_menu_delete &&
                    <button
                      className="_red"
                      onClick={() => setDeleting(true)}>
                      Delete User`s Menus
                    </button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => $router.router.navigate('users_menus', {
                      companyId: activeAccountId
                    }, {
                      reload: true
                    })}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave || isSaving}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            }

            {/* Item delete popup */}
            {
              data.permissions.user_menu_delete &&
              deleting &&
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>
                <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                  <div className="title">
                    Delete User`s Menus
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the user`s menus it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">
                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete || isSaving}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        )
      }
    </>
  )
}

export default UsersMenusPage_Item