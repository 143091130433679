import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import { UsersEditProps } from "../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UserGroupsData
}

interface UserGroupsData {
  permissions: {
    user_group_add: boolean
    user_group_delete: boolean
    user_group_edit: boolean
    user_group_report_show: boolean
    user_group_show: boolean
  },

  user_group: {
    created_at: string
    created_by: string
    name: string
    updated_at: string
    updated_by: string
  }

  users: {
    available: string
    first_name: string
    function: string
    last_name: string
    user_id: string
  }[]

  edit: {
    users: UsersEditProps[]
  }
}

interface UsersProps {
  available: string;
  first_name: string;
  function: string;
  last_name: string;
  user_id: string;
}

const emptyUser: UsersProps = {
  available: '',
  first_name: '',
  function: '',
  last_name: '',
  user_id: '',
}

function UserGroupsPage_Item() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [userGroupsData, setUserGroupsData] = useState<UserGroupsData | null>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users, setUsers] = useState<UsersProps[] | null>(null)
  const [availableUsers, setAvailableUsers] = useState<UsersProps[]>([])
  const [selectedUser, setSelectedUser] = useState<UsersProps>(emptyUser)
  const [newUserGroupsData, setNewUserGroupsData] = useState({
    name: '',
    users: [] as UsersProps[],
  })

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/users/groups/88888002hd34bfcpk7?account_id=88888
      const response = await httpClientUpdate.delete(`/users/groups/${$router.router.getState().params.userGroupsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('user_groups', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/users/groups/88888002hd34bfcpk7
      const response = await httpClientUpdate.put(`/users/groups/${$router.router.getState().params.userGroupsId}`, {
        account_id: activeAccountId,
        name: newUserGroupsData.name,
        users: newUserGroupsData.users.map(item => item.user_id)
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/users/groups/88888002rhnc87juix?account_id=88888
      const { data: { data: userGroupsData, success, error } } = (await httpClientUpdate.get('/users/groups/' + $router.router.getState().params.userGroupsId, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (userGroupsData.permissions.user_group_show) {
          let _availableUsers = []

          _availableUsers = userGroupsData.edit.users.filter(user => !userGroupsData.users.filter(item => item.user_id === user.user_id).length && user)

          setUsers(userGroupsData.edit.users)
          setAvailableUsers([emptyUser].concat(_availableUsers))
          setSelectedUser(emptyUser)

          setUserGroupsData(userGroupsData)

          setNewUserGroupsData({
            name: userGroupsData.user_group.name,
            users: userGroupsData.users
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load userGroups data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newUserGroupsData
  ])

  // Add user function
  function addUser() {

    if (!newUserGroupsData.users || !newUserGroupsData) return

    const newUsersList = [
      ...newUserGroupsData.users,
      selectedUser
    ]

    setNewUserGroupsData({
      ...newUserGroupsData,
      users: newUsersList
    })

    let _availableUser = [...availableUsers]

    _availableUser = _availableUser.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user.user_id === $user.user_id
      }) === -1
    })

    setAvailableUsers([
      ..._availableUser
    ])

    setSelectedUser(emptyUser)

    setReadyToSave(true)
  }

  // Remove user function
  function removeUser(i: number) {

    let _userGroupsUsers = [...newUserGroupsData.users]

    let _user = _userGroupsUsers[i]

    _userGroupsUsers.splice(i, 1)

    setNewUserGroupsData({
      ...newUserGroupsData,
      users: _userGroupsUsers
    })

    setAvailableUsers([
      ...availableUsers,
      _user
    ])

    setReadyToSave(true)
  }

  // Render function
  return (<>
    {userGroupsData ? (
      <div className="CallCenter_CallGroupsPage_Item entity-edit">
        <div className="wrapper flex-container sb">
          <h1>User Groups</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'user_groups',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                userGroupsData?.permissions.user_group_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  value={newUserGroupsData.name}
                  disabled={!editing}
                  onChange={({ target: { value } }) => {
                    setNewUserGroupsData({ ...newUserGroupsData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Users</div>

            <div className="legend-action-wrapper">
              <div className="input-wrapper">
                <Select
                  disabled={!editing}
                  options={availableUsers.map((user) => ({
                    span: `${user.first_name} ${user.last_name}`,
                    value: user.user_id
                  }))}
                  selectedOption={selectedUser.user_id}
                  onChange={(value) => setSelectedUser(availableUsers.find((user) => user.user_id === value) as UsersProps)}
                />
                <button
                  disabled={!selectedUser.user_id}
                  className="_green"
                  onClick={() => addUser()}
                >
                  Add
                </button>
              </div>
            </div>

          </div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>Function</th>
              <th>Active</th>
              <th></th>
            </tr>
            {newUserGroupsData.users.map((user, i) => (
              <tr
                key={i}
              >
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.function}</td>
                <td className={classNames({
                  'red-text': user.available === 'Inactive',
                  'green-text': user.available === 'Active',
                  'blue-text': user.available === 'Waiting',
                })}>
                  {user.available}
                </td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    disabled={!editing}
                    onClick={() => removeUser(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {newUserGroupsData.users.map((user, i: number) => (
                <div
                  className="item"
                  key={i}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>Name<b>{user.first_name} {user.last_name}</b></div>
                    </div>

                    <div className="__right">
                      <div>Function<b>{user.function}</b></div>
                      <div className={classNames({
                        'red-text': user.available === 'Inactive',
                        'green-text': user.available === 'Active',
                        'blue-text': user.available === 'Waiting',
                      })}>
                        {user.available}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__right">
                      <button
                        className="_zeroed _iconed _red"
                        disabled={!editing}
                        onClick={() => removeUser(i)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, userGroupsData.user_group.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={userGroupsData.user_group.created_by} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, userGroupsData.user_group.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={userGroupsData.user_group.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                userGroupsData.permissions.user_group_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete User Groups</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => $router.router.navigate('user_groups', {
                  companyId: activeAccountId
                }, { reload: true })}
              >
                Cancel
              </button>

              <button
                disabled={!readyToSave || !!errorFields.length || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item deleting popup */}
        {userGroupsData.permissions.user_group_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete User Groups
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the user groups it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default UserGroupsPage_Item
