import Icon from "../components/Icon"

import '../styles/pages/page403.sass'

function Page403() {

  return (
    <div className="Page403">
      <p>
        You don`t have permission to access this page
      </p>
      <Icon icon="lock-fill-2" />

    </div>
  )
}

export default Page403
