import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";

import Icon from "../../components/Icon"
import Select from "../../components/Select"

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import Client from "../../models/Client"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { ClientData } from './Item'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ClientData
}

function ClientsPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newClientData, setNewClientData] = useState<Partial<Client>>({
    firstname: '',
    lastname: '',
    company_name: '',
    source: ''
  })

  const [clientDataEdit, setNewClientDataEdit] = useState<{
    source: {
      id_source: string
      name: string
    }[],
  } | null>(null)

  // save document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.post(`/clients`, {
        account_id: activeAccountId,
        firstname: newClientData?.firstname,
        lastname: newClientData?.lastname,
        company: newClientData?.company_name,
        source_id: newClientData.source ? newClientData.source : null,
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('clients', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: clientData, success, error } } = (await httpClientUpdate.post('/clients/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (clientData.permissions.client_add) {
          setNewClientDataEdit(clientData.edit)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load client data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newClientData
  ])

  // Render function
  return (<>
    {clientDataEdit ? (
      <div className="ClientsPage_Item ClientPage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>New Client</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('clients', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>First Name:</span>
                <input type="text" defaultValue={newClientData.firstname} onChange={({ target: { value } }) => setNewClientData({ ...newClientData, firstname: value })} />
              </div>

              <div className="field">
                <span>Last Name:</span>
                <input type="text" defaultValue={newClientData.lastname} onChange={({ target: { value } }) => setNewClientData({ ...newClientData, lastname: value })} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Company:</span>
                <input type="text" defaultValue={newClientData.company_name} onChange={({ target: { value } }) => setNewClientData({ ...newClientData, company_name: value })} />
              </div>

              <div className="field">
                <span>Source:</span>
                <Select
                  options={[{ name: '', id_source: '' }].concat(clientDataEdit.source).map((option) => ({
                    span: option.name,
                    value: option.id_source
                  }))}
                  selectedOption={newClientData.source as string}
                  onChange={(value) => setNewClientData({ ...newClientData, source: value as string })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div></div>
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => $router.router.navigate('clients', {
                companyId: activeAccountId,
              }, { reload: true })}
            >
              Cancel
            </button>
            <button
              disabled={!readyToSave || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default ClientsPage_New
