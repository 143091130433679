export default function AccountSvg() {
  return (
    <svg viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">

      <path
        fill="#8E8E93"
        d="M9.57265 1.7H8.97436V0.5H7.77778V1.7H2.99145V0.5H1.79487V1.7H1.19658C0.53547 1.7 0 2.237 0 2.9V11.3C0 11.963 0.53547 12.5 1.19658 12.5H9.57265C10.2338 12.5 10.7692 11.963 10.7692 11.3V2.9C10.7692 2.237 10.2338 1.7 9.57265 1.7ZM5.38462 3.5C6.37479 3.5 7.17949 4.307 7.17949 5.3C7.17949 6.296 6.37479 7.1 5.38462 7.1C4.39444 7.1 3.58974 6.296 3.58974 5.3C3.58974 4.307 4.39444 3.5 5.38462 3.5ZM8.97436 10.7H1.79487V10.1C1.79487 8.9 4.18804 8.24 5.38462 8.24C6.5812 8.24 8.97436 8.9 8.97436 10.1V10.7Z"
      ></path>
    </svg>
  )
}
