import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import ModalImage from '../Sms/ModalImage'

import { useAppSelector } from '../../store/hooks'
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"
import "./Mail.sass"
import '../../styles/pages/sms.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },

  data: ReportData
}

interface ReportData {
  mail: MailProps
}

interface MailProps {
  answered: boolean
  archive: boolean
  body_html: string
  body_text: string
  can_read_user_group_id: string
  date: string
  draft: boolean
  flagged: boolean
  from_mail: string
  has_attachments: boolean
  attachments: AttachmentsProps[]
  mail_id: string
  mailbox_id: string
  message_id: string
  reply_to: string
  seen: boolean
  spam: boolean
  subject: string
  to_mail: string
  mailbox_folder_name: string
  mailbox_folder_id: string
}

interface AttachmentsProps {
  mail_attachment_id: string
  url: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [dataReport, setDataReport] = useState<MailProps | null>(null)

  const [openModal, setOpenModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState({
    url: '',
    type: '',
  })

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/mails/{mail_id}?account_id=88888
      const { data: { data: mailData, success } } = await httpClientUpdate.get(`/mails/${$router.router.getState().params.mailId}`, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setDataReport(mailData.mail)
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  function rawMarkup(text: string) {
    let rawMarkup = text

    return { __html: rawMarkup.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('\\&quot;', '"') }
  }

  function handleOpenModal(url: string) {
    setSelectedImage({
      url: url,
      type: 'image'
    })
    setOpenModal(true)
  }

  const downloadImage = (url: string) => {
    const name = url.split('/')[url.split('/').length - 1]

    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    img.src = url;
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx && ctx.drawImage(img, 0, 0);
      const a = document.createElement('a');
      a.download = name;
      a.href = canvas.toDataURL('image/png');
      a.click();
    };
  }

  function getAttachmentName(url: string) {
    let name = ''

    const splitUrl = url.split('/')

    if (splitUrl.length) {
      name = splitUrl[splitUrl.length - 1]
    }

    return name
  }

  return (
    <>
      {
        dataReport &&
        <div style={{ position: 'relative' }} className="Mail_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Mail</h1>
              <div className="item-name">
              </div>
            </div>

            <div style={{ display: 'flex' }} >
              <button
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() => $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'mail',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface,
                    selectedSearch: $router.router.getState().params.selectedSearch,
                    selectedFolder: $router.router.getState().params.selectedFolder,
                  }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>
            </div>
          </div>

          <div className='mail-content fieldset'>
            <div className='content-row'>
              <span className='title'>Date:</span>

              <span className='text'> {dataReport.date} </span>
            </div>

            <div className='content-row'>
              <span className='title'>To:</span>

              <span className='text'> {dataReport.to_mail} </span>
            </div>

            <div className='content-row'>
              <span className='title'>From:</span>

              <span className='text'> {dataReport.from_mail} </span>
            </div>

            <div className='content-row'>
              <span className='title'>Subject:</span>

              <span className='text'> {dataReport.subject} </span>
            </div>

            <div className='content-row body_html'>
              <div dangerouslySetInnerHTML={rawMarkup(dataReport.body_html)}></div>
            </div>

            <div className='content-row'>
              <span className='title'>Attachments:</span>

              <div
                className="mail-images"
              >
                {
                  !!dataReport.attachments.length &&
                  dataReport.attachments.map((item, index) => (
                    <div
                      key={item.mail_attachment_id}
                      className="mail-image"
                    >
                      <img
                        src={item.url}
                        alt={`attachments_${index}`}
                        onClick={() => handleOpenModal(item.url)}
                      />

                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {getAttachmentName(item.url)}
                      </span>

                      <div className='mail-image-actions' >
                        <button
                          onClick={() => downloadImage(item.url)}
                        >
                          <Icon
                            style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="download-sms"
                          />
                        </button>

                        <button
                          style={{ padding: '0' }}
                          onClick={() => handleOpenModal(item.url)}
                        >
                          <Icon
                            style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="zoom-in-1"
                          />
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>

          {
            openModal &&
            <ModalImage
              image={selectedImage}
              setOpen={setOpenModal}
            />
          }
        </div>
      }
    </>
  )
}
