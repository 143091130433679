import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import moment from "moment"
import { DateTime } from "luxon"
import DateTimeComponent from "react-datetime"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import SelectComponent from "../../components/Select";

import { httpClientUpdate, nErrorUpdate, screenSizeWidth } from "../../funcs"
import { useAppSelector } from "../../store/hooks"
import { arrTimesForSchedule } from "../../constans";

import { AdditionalTimeReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AdditionalTimeReport
}

interface TimesSlotsProps {
  start: string
  end: string
  editing: boolean
}

function AbsencesPage_New() {
  const $router = useRoute()

  const width = screenSizeWidth()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newData, setNewData] = useState({
    service_resource_id: '',
    date: DateTime.now().plus({ hours: 0 }).set({ minute: 0 }).toJSDate(),
  })

  const [timesSlots, setTimesSlots] = useState<TimesSlotsProps[]>([])

  const [serviceResources, setServiceResources] = useState<{
    code: number
    nickname: string
    service_resource_id: string
    time_zone: string
  }[] | null>(null)

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      let date = moment(newData.date).format('YYYY-MM-DD')
      let windows: { from: string, to: string }[] = []

      if (!!timesSlots.length) {
        timesSlots.forEach(item => {
          if (!item.editing) {
            windows.push({
              from: item.start,
              to: item.end
            })
          }
        })
      }

      // https://2022back4.artemiudintsev.com/api/additional-times
      const response = await httpClientUpdate.post(`/additional-times`, {
        account_id: activeAccountId,
        service_resource_id: newData.service_resource_id,
        date: date,
        windows: windows,
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('additional-time', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      //  https://2022back4.artemiudintsev.com/api/additional-times/report?account_id=88888&page=1&limit_rows=100
      const { data: { data: responseData, success, error } } = (await httpClientUpdate.get('/additional-times/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
          date_type: 'created',
          sort_field: 'created_at',
          sort_type: 'asc',
          filter_field: JSON.stringify({})
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (responseData.permissions.additional_time_service_resource_add) {
          setServiceResources(responseData.edit.service_resources)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData, timesSlots
  ])

  function handleAddTimeSlot() {
    let updated = timesSlots.map(item => item)

    let start = ''
    let end = ''


    updated.push({
      start,
      end,
      editing: true
    })

    setTimesSlots(updated)
  }

  function handleSaveChangeTimeSlot(index: number) {
    setTimesSlots(timesSlots.map((item, i) => {
      if (i === index) {
        return { ...item, editing: !item.editing }
      } else {
        return { ...item }
      }
    }))
  }

  function removeTimeSlot(index: number) {
    setTimesSlots(timesSlots.filter((item, i) => i !== index))
  }

  function handleChangeTimeSlot(index: number, name: string, value: string) {

    setTimesSlots(timesSlots.map((item, i) => {
      if (i === index) {
        if (name === 'start') {
          return { ...item, start: value }
        } else {
          return { ...item, end: value }
        }
      } else {
        return { ...item }
      }
    }))
  }

  // Render function
  return (<>
    {serviceResources ? (
      <div className="AbsencesPage_Item AbsencePage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Additional Time</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('additional-time', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        {
          width >= 901 ?
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                className="fieldset"
                style={{ width: 'calc(100% - 500px)' }}
              >
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field" style={{ zIndex: 6 }}>
                      <span>Service resource:</span>

                      <Select
                        options={serviceResources.map((option) => ({
                          span: option.code ? `${option.nickname}(${option.code})` : option.nickname,
                          value: option.service_resource_id
                        }))}
                        selectedOption={newData.service_resource_id as string}
                        onChange={(value) => setNewData({ ...newData, service_resource_id: value as string })}
                      />
                    </div>
                  </div>


                  <div className="__right">

                    <div className="field">
                      <span>Date:</span>

                      <DateTimeComponent
                        dateFormat={true}
                        timeFormat={false}
                        value={newData.date as Date}
                        onChange={(value) => setNewData({ ...newData, date: moment.isMoment(value) ? value.toDate() : newData.date })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="fieldset time_slots"
                style={{ width: '500px' }}
              >
                <div
                  className="legend"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  Time slots

                  <button className="_wa _green"

                    onClick={() => handleAddTimeSlot()}
                  >
                    Add
                  </button>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 'calc(90% / 4)' }}>From</th>

                      <th style={{ width: 'calc(90% / 4)' }}>To</th>

                      <th style={{ width: '5%' }}></th>

                      <th style={{ width: '5%' }}></th>
                    </tr>
                  </thead>

                  {
                    timesSlots.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={arrTimesForSchedule.map((option) => ({
                                  span: option.span,
                                  value: option.value
                                }))}
                                selectedOption={item.start as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'start', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.start).length ?
                                arrTimesForSchedule.filter(time => time.value === item.start)[0].span :
                                item.start
                          }
                        </td>

                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={
                                  item.start === '' ?
                                    arrTimesForSchedule.map((option) => ({
                                      span: option.span,
                                      value: option.value
                                    })) :
                                    arrTimesForSchedule.filter((option) => item.start < option.value && ({
                                      span: option.span,
                                      value: option.value
                                    }))
                                }
                                selectedOption={item.end as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'end', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.end).length ?
                                arrTimesForSchedule.filter(time => time.value === item.end)[0].span :
                                item.end
                          }
                        </td>

                        <td>
                          {
                            !item.editing ?
                              <button
                                className="_zeroed _iconed _blue"
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="pencil-14" />
                              </button> :
                              <button
                                className="_zeroed _iconed _green"
                                disabled={item.start === '' || item.end === ''}
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="check-mark-1" />
                              </button>
                          }
                        </td>

                        <td>
                          <button
                            className="_zeroed _iconed _red"
                            onClick={() => removeTimeSlot(index)}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </div>
            </div> :
            <>
              <div className="fieldset">
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field" style={{ zIndex: 6 }}>
                      <span>Service resource:</span>

                      <Select
                        options={serviceResources.map((option) => ({
                          span: option.code ? `${option.nickname}(${option.code})` : option.nickname,
                          value: option.service_resource_id
                        }))}
                        selectedOption={newData.service_resource_id as string}
                        onChange={(value) => setNewData({ ...newData, service_resource_id: value as string })}
                      />
                    </div>
                  </div>


                  <div className="__right">

                    <div className="field">
                      <span>Start:</span>

                      <DateTimeComponent
                        dateFormat={true}
                        timeFormat={false}
                        value={newData.date as Date}
                        onChange={(value) => setNewData({ ...newData, date: moment.isMoment(value) ? value.toDate() : newData.date })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="fieldset time_slots"
              >
                <div
                  className="legend"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  Time slots

                  <button className="_wa _green"

                    onClick={() => handleAddTimeSlot()}
                  >
                    Add
                  </button>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 'calc(90% / 4)' }}>From</th>

                      <th style={{ width: 'calc(90% / 4)' }}>To</th>

                      <th style={{ width: '5%' }}></th>

                      <th style={{ width: '5%' }}></th>
                    </tr>
                  </thead>

                  {
                    timesSlots.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={arrTimesForSchedule.map((option) => ({
                                  span: option.span,
                                  value: option.value
                                }))}
                                selectedOption={item.start as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'start', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.start).length ?
                                arrTimesForSchedule.filter(time => time.value === item.start)[0].span :
                                item.start
                          }
                        </td>

                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={arrTimesForSchedule.map((option) => ({
                                  span: option.span,
                                  value: option.value
                                }))}
                                selectedOption={item.end as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'end', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.end).length ?
                                arrTimesForSchedule.filter(time => time.value === item.end)[0].span :
                                item.end
                          }
                        </td>

                        <td>
                          {
                            !item.editing ?
                              <button
                                className="_zeroed _iconed _blue"
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="pencil-14" />
                              </button> :
                              <button
                                className="_zeroed _iconed _green"
                                disabled={item.start === '' || item.end === ''}
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="check-mark-1" />
                              </button>
                          }
                        </td>

                        <td>
                          <button
                            className="_zeroed _iconed _red"
                            onClick={() => removeTimeSlot(index)}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </div>
            </>
        }

        <div className="wrapper flex-container sb editing-buttons">
          <div></div>
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('additional-time', {
              companyId: activeAccountId
            }, { reload: true })}>
              Cancel
            </button>

            <button
              disabled={!readyToSave || newData.service_resource_id === '' || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default AbsencesPage_New
