import { ReactElement, useState } from "react";
import { DateTime } from "luxon"
import moment from "moment";
import classNames from "classnames"

import DateRangeCalendar from "./DateRangeCalendar"
import Icon from "../../components/Icon"
import Select from "../../components/Select"
import SelectTime from './SelectSettingsTime'

import { DateRangePreset } from "../../models/Misc"

import "../../styles/components/reports/filters.sass"

interface Props {

  onSearchInputChange(value: string): void,

  dateRangeType?: string,
  onDateRangeTypeChange?(value: string): void,

  dateRangePreset?: DateRangePreset,
  onDateRangePresetChange?(value: DateRangePreset): void,

  minDate?: Date,
  onMinDateChange?(value: Date): void,

  minTime?: string,
  // onMinTimeChange?(value: string): void,

  maxDate?: Date,
  onMaxDateChange?(value: Date): void,

  maxTime?: string,
  // onMaxTimeChange?(value: string): void,
  onChangeTime?(start: string, end: string): void,

  updateButtonActive?: boolean,
  onUpdate(): void,

  childrenAfterSearch?: ReactElement,
  childrenBeforeDateType?: ReactElement,

  arrTimesOfDay: {
    span: string,
    value: string
  }[]

  phoneCall: boolean,
  navActive: boolean,
}

function ReportFilters({
  onSearchInputChange,
  dateRangeType,
  onDateRangeTypeChange,
  dateRangePreset,
  onDateRangePresetChange,
  minDate,
  onMinDateChange,
  minTime,
  // onMinTimeChange,
  maxDate,
  onMaxDateChange,
  maxTime,
  // onMaxTimeChange,
  onChangeTime,
  updateButtonActive,
  onUpdate,
  childrenAfterSearch,
  childrenBeforeDateType,
  arrTimesOfDay,
  phoneCall,
  navActive
}: Props) {

  const [dateRangeCalendarShown, setDateRangeCalendarShown] = useState(false)
  // const [timeRangeShow, setTimeRangeShow] = useState(false)

  function onChangeDay(day: number) {
    let updateMinDate = moment(minDate).add(day, 'd').toDate()
    onMinDateChange && onMinDateChange(updateMinDate)
  }

  // Render function
  return (
    <div className="ReportFilters">

      <div className="flex-container">

        <div
          className={classNames("__hide-on-mobile", "__left", "flex-container",
            {
              __respectAsidePanel: navActive && !phoneCall,
              __phoneCall: phoneCall && !navActive,
              __bothOpen: navActive && phoneCall,
              __nonePanel: !navActive && !phoneCall
            }
          )}
        >

          { /* Search field */}
          <div className="form-field __search-form">
            <input type="text" className="_rounded" onChange={({ target: { value } }) => onSearchInputChange(value)} />
            <button className="_wa _white _iconed _rounded" onClick={() => onUpdate()}>
              <Icon icon="magnifier-5" />
            </button>
          </div>

          {childrenAfterSearch}

          { /* Settings field */}
          <div className="controls__board-hour-select board-hour-select">
            <div
              className="controls__board-hour-select-btn"
              style={{ position: 'relative' }}
            >
              {
                minTime && maxTime
                && onChangeTime &&
                <SelectTime
                  options={arrTimesOfDay}
                  selectedStart={minTime}
                  selectedEnd={maxTime}
                  onChangeTime={(start: string, end: string) => { (onChangeTime as any)(start, end) }}
                />
              }
            </div>
          </div>
        </div>

        <div
          className={classNames("__hide-on-mobile", "__right", "flex-container",
            {
              __respectAsidePanel: navActive && !phoneCall,
              __phoneCall: phoneCall && !navActive,
              __bothOpen: navActive && phoneCall,
              __nonePanel: !navActive && !phoneCall
            }
          )}
        >

          {childrenBeforeDateType}

          { /* Date range type selection */}
          {(
            true
            && dateRangeType
            && onDateRangeTypeChange
          ) ? (
            <div>
              <Select zeroed={true} options={[{
                span: "1 Day",
                value: "one"
              }, {
                span: "2 Days",
                value: "two"
              }, {
                span: "3 Days",
                value: "three"
              }]} selectedOption={dateRangeType} onChange={(value: string) => {
                (onDateRangeTypeChange as any)(value)
              }} />
            </div>
          ) : null}

          { /* Date range type selection */}
          {(true
            && dateRangePreset
            && onDateRangePresetChange
          ) ? (
            <div>
              <Select zeroed={true} options={[{
                span: "Custom",
                value: "custom"
              }, {
                span: "Tomorrow",
                value: "tomorrow"
              }, {
                span: "Today",
                value: "today"
              }, {
                span: "Yesterday",
                value: "yesterday"
              }]} selectedOption={dateRangePreset} onChange={(value: string) => {
                (onDateRangePresetChange as any)(value as DateRangePreset)
              }} />
            </div>
          ) : null}

          { /* Date range */}
          {(true
            && minDate
            && maxDate
            && onMinDateChange
            && onMaxDateChange
          ) ? (
            <div onFocus={() => setDateRangeCalendarShown(true)} onBlur={() => setDateRangeCalendarShown(false)} tabIndex={-1} className="form-field date-range">
              <div className="form-field _iconed">
                <input
                  type="text"
                  readOnly
                  value={DateTime.fromJSDate(minDate).toLocaleString(DateTime.DATE_SHORT)}
                />
                <button className="_zeroed _iconed __noclick">
                  <Icon icon="calendar-4" />
                </button>
              </div>

              <div style={{ width: '350px', left: '25%' }} className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
                <DateRangeCalendar
                  startDate={minDate}
                  endDate={maxDate}

                  dateRangeType={dateRangeType}
                  dateRangePreset={dateRangePreset}

                  onStartDateUpdate={(date) => { (onMinDateChange as any)(date); }}
                  onEndDateUpdate={(date) => { (onMaxDateChange as any)(date); }}
                />
              </div>
            </div>
          ) : null}

          {
            minDate && maxDate &&
            <div style={{ display: 'flex' }} >
              <button className="_zeroed _iconed" onClick={() => onChangeDay(-1)}>
                <Icon style={{ fill: '#8391AD' }} className="_rotated-180" icon="arrow-25" />
              </button>

              <button className="_zeroed _iconed" onClick={() => onChangeDay(1)}>
                <Icon style={{ fill: '#8391AD' }} icon="arrow-25" />
              </button>
            </div>
          }

          { /* Refresh button */}
          {(true
            && minDate
            && maxDate
          ) ? (
            <button
              className={classNames(['_wa', '_rounded', '_iconed', 'update-button', '__hide-on-mobile', { _blue: updateButtonActive }])}
              onClick={() => onUpdate()}
            >
              <Icon className="_mirrored-y" icon="refresh-2" />
            </button>
          ) : null}
        </div>
      </div>

      <div className={classNames("__show-on-mobile", "flex-container",
        {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        }
      )}>

        { /* Search field */}
        <div className="form-field __search-form">
          <input type="text" className="_rounded" onChange={({ target: { value } }) => onSearchInputChange(value)} />
          <button className="_wa _white _iconed _rounded" onClick={() => onUpdate()}>
            <Icon icon="magnifier-5" />
          </button>
        </div>

        {childrenAfterSearch}

        { /* Settings field */}
        <div className="controls__board-hour-select board-hour-select">
          <div className="controls__board-hour-select-btn" >
            {
              minTime && maxTime
              && onChangeTime &&
              <SelectTime
                options={arrTimesOfDay}
                selectedStart={minTime}
                selectedEnd={maxTime}
                onChangeTime={(start: string, end: string) => { (onChangeTime as any)(start, end) }}
              />
            }
          </div>
        </div>
      </div>

      <div
        className={classNames("__show-on-mobile", "flex-container",
          {
            __respectAsidePanel: navActive && !phoneCall,
            __phoneCall: phoneCall && !navActive,
            __bothOpen: navActive && phoneCall,
            __nonePanel: !navActive && !phoneCall
          }
        )}
        style={{ marginLeft: '0' }}
      >

        {childrenBeforeDateType}

        { /* Date range type selection */}
        {(
          true
          && dateRangeType
          && onDateRangeTypeChange
        ) ? (
          <div>
            <Select zeroed={true} options={[{
              span: "1 Day",
              value: "one"
            }, {
              span: "2 Days",
              value: "two"
            }, {
              span: "3 Days",
              value: "three"
            }]} selectedOption={dateRangeType} onChange={(value: string) => {
              (onDateRangeTypeChange as any)(value)
            }} />
          </div>
        ) : null}

        { /* Date range type selection */}
        {(true
          && dateRangePreset
          && onDateRangePresetChange
        ) ? (
          <div>
            <Select zeroed={true} options={[{
              span: "Custom",
              value: "custom"
            }, {
              span: "Tomorrow",
              value: "tomorrow"
            }, {
              span: "Today",
              value: "today"
            }, {
              span: "Yesterday",
              value: "yesterday"
            }]} selectedOption={dateRangePreset} onChange={(value: string) => {
              (onDateRangePresetChange as any)(value as DateRangePreset)
            }} />
          </div>
        ) : null}

        { /* Date range */}
        {(true
          && minDate
          && maxDate
          && onMinDateChange
          && onMaxDateChange
        ) ? (
          <div onFocus={() => setDateRangeCalendarShown(true)} onBlur={() => setDateRangeCalendarShown(false)} tabIndex={-1} className="form-field date-range">
            <div className="form-field _iconed">
              <input type="text" readOnly value={DateTime.fromJSDate(minDate).toLocaleString(DateTime.DATE_SHORT)} />
              <button className="_zeroed _iconed __noclick">
                <Icon icon="calendar-4" />
              </button>
            </div>

            <div style={{ width: '350px', left: '25%' }} className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
              <DateRangeCalendar
                startDate={minDate}
                endDate={maxDate}

                dateRangeType={dateRangeType}
                dateRangePreset={dateRangePreset}

                onStartDateUpdate={(date) => { (onMinDateChange as any)(date); }}
                onEndDateUpdate={(date) => { (onMaxDateChange as any)(date); }}
              />
            </div>
          </div>
        ) : null}

        {
          minDate && maxDate &&
          <div style={{ display: 'flex' }} >
            <button className="_zeroed _iconed" onClick={() => onChangeDay(-1)}>
              <Icon style={{ fill: '#8391AD' }} className="_rotated-180" icon="arrow-25" />
            </button>

            <button className="_zeroed _iconed" onClick={() => onChangeDay(1)}>
              <Icon style={{ fill: '#8391AD' }} icon="arrow-25" />
            </button>
          </div>
        }

        { /* Refresh button */}
        {(true
          && minDate
          && maxDate
        ) ? (
          <button
            className={classNames(['_wa', '_rounded', '_iconed', 'update-button', '__hide-on-mobile', { _blue: updateButtonActive }])}
            onClick={() => onUpdate()}
          >
            <Icon className="_mirrored-y" icon="refresh-2" />
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default ReportFilters
