import { useState } from "react";
import classNames from "classnames"
import { Transition } from 'react-transition-group'

import Icon from "../../components/Icon"

import { useOuterClick } from "../../funcs";

import "../../styles/components/select.sass"

export interface Option {
  span: string,
  value: any
}

interface Props {

  options: Option[],

  selectedStart: string,
  selectedEnd: string,

  onChangeTime(start: string, end: string): void,

  zeroed?: boolean,
  disabled?: boolean
}

function SelectComponent({
  options,
  selectedStart,
  selectedEnd,
  onChangeTime,
  disabled
}: Props) {

  const [active, setActive] = useState(false)
  const [activeTimeStart, setActiveTimeStart] = useState(false)
  const [activeTimeEnd, setActiveTimeEnd] = useState(false)
  const [changeTime, setChangeTime] = useState({
    start: selectedStart,
    end: selectedEnd
  })

  async function handleApply() {
    let optionsSpan = options.map(item => item.span)
    let startIndex = optionsSpan.indexOf(changeTime.start)
    let endIndex = optionsSpan.findIndex((x, idx) => idx !== 0 && x === changeTime.end)

    if (startIndex >= endIndex) {
      onChangeTime(changeTime.start, optionsSpan[startIndex + 1])
      setChangeTime({ start: changeTime.start, end: optionsSpan[startIndex + 1] })
    } else {
      onChangeTime(changeTime.start, changeTime.end)
    }
    setActive(false)
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setActive(false)
    setActiveTimeStart(false)
    setActiveTimeEnd(false)
  });

  // Render function
  return (
    <div
      className="SelectComponent"
      ref={contextMenuRef}
    >
      <button disabled={disabled}
        className='_wa gray _iconed _rounded _costum'
        onClick={() => setActive(true)}
      >
        <Icon style={{ width: '32px!important', fill: '#8391AD !important' }} icon="gear-1" />
      </button>

      {
        active &&
        <div
          className={classNames("options-list-wrapper", "options-list-wrapper-time-list", `transition-fade-entered`)}
          style={{ transform: 'none', right: '0', left: 'initial' }}
        >
          <div style={{ marginBottom: '1em' }} className="row">
            <p style={{ width: 'inherit' }} >
              On time:
            </p>
            <button disabled={disabled}
              className='_select_time'
              style={{ marginRight: '5px' }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setActiveTimeStart(true)
                activeTimeEnd && setActiveTimeEnd(false)
              }}
            >
              <span>{changeTime.start}</span>
            </button>
            <span>
              {' '}-{' '}
            </span>
            <button disabled={disabled}
              style={{ marginLeft: '5px' }}
              className='_select_time'
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setActiveTimeEnd(true)
                activeTimeStart && setActiveTimeStart(false)
              }}
            >
              <span>{changeTime.end}</span>
            </button>
          </div>

          <div className="row">
            {
              changeTime.start !== selectedStart ||
                changeTime.end !== selectedEnd ?
                <button disabled={disabled}
                  className='_select_time'
                  onClick={() => handleApply()}
                >
                  <span>Apply</span>
                </button> :
                <button disabled={disabled}
                  className='_select_time'
                  onClick={() => setActive(false)}
                >
                  <span>Close</span>
                </button>
            }
          </div>
        </div>
      }

      <Transition in={activeTimeStart} mountOnEnter={true} unmountOnExit={true} timeout={210}>
        {(state) => (
          <div
            className={classNames("options-list-wrapper", "options-list-wrapper-time-item", `transition-fade-${state}`)}
            style={{ left: '-95px' }}
          >

            {options.map((option, i) => (
              <button
                className={classNames("_zeroed", "option", { _active: option.value === changeTime.start })}
                key={i}
                onClick={() => {
                  setActiveTimeStart(false);
                  setChangeTime({ ...changeTime, start: option.span })
                }}
              >
                {option.span || <span>&nbsp;</span>}
              </button>
            ))}
          </div>
        )}
      </Transition>

      <Transition in={activeTimeEnd} mountOnEnter={true} unmountOnExit={true} timeout={210}>
        {(state) => (
          <div
            className={classNames("options-list-wrapper", "options-list-wrapper-time-item", `transition-fade-${state}`)}
            style={{ left: '-5px' }}
          >

            {options.slice(options.findIndex(x => x.span === changeTime.start) + 1).map((option, i) => (
              <button
                className={classNames("_zeroed", "option", { _active: option.value === changeTime.end })}
                key={i}
                onClick={() => {
                  setActiveTimeEnd(false);
                  setChangeTime({ ...changeTime, end: option.span })
                }}
              >
                {option.span || <span>&nbsp;</span>}
              </button>
            ))}
          </div>
        )}
      </Transition>
    </div>
  )
}

export default SelectComponent
