import { useState } from "react";
import classNames from "classnames"
import { Transition } from 'react-transition-group'

import Icon from "./Icon"

import { useOuterClick } from '../funcs'

import "../styles/components/select.sass"

export interface Option {
  span: string,
  value: any,
  is_connected?: boolean
}

interface Props {

  options: Option[],

  selectedOption: string | number | boolean,
  onChange(value: string | number | boolean, span?: any): void,

  zeroed?: boolean,
  disabled?: boolean

  error?: boolean

  is_google?: boolean
}

function SelectWithSearchComponent({
  options,
  selectedOption,
  onChange,
  zeroed,
  disabled,
  error,
  is_google,
}: Props) {
  const [search, setSearch] = useState('')

  const [active, setActive] = useState(false)

  // Get selected option function
  function getSelectedOption() {
    return options.find((option) => option.value === selectedOption) || {
      span: '',
      value: ''
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setActive(false)
  });

  // Render function
  return (
    <div
      className="SelectComponent"
      ref={contextMenuRef}
    >
      <button
        disabled={disabled}
        className={classNames({ _zeroed: zeroed, error: error }, '_iconed', 'sb')}
        onClick={() => setActive(true)}
        onFocus={() => setActive(true)}

      >
        {
          is_google ?
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              {
                getSelectedOption().span &&
                <div className={classNames('connect',
                  {
                    _connected: getSelectedOption().is_connected,
                  }
                )}></div>
              }
              <span>{getSelectedOption().span}</span>
            </div> :
            <span>{getSelectedOption().span}</span>
        }
        <Icon className={classNames({ _flipped: active })} icon="arrow-65" />
      </button>

      <Transition in={active} mountOnEnter={true} unmountOnExit={true} timeout={210}>
        {(state) => (
          <div className={classNames("options-list-wrapper", `transition-fade-${state}`)}>
            <input
              type="text"
              value={search}
              onChange={(event) => {
                setActive(true);
                event.stopPropagation()
                event.preventDefault()
                setSearch(event.target.value)
              }}
              placeholder="Search"
            />

            {options
            .filter(option => option.span.toLowerCase().includes(search.toLowerCase()))
            .map((option, i) => (
              <button
                className={classNames("_zeroed", "option",
                  {
                    _active: option.value === getSelectedOption().value,
                  }
                )}
                key={i}
                onClick={() => {
                  setActive(false);
                  onChange(option.value, option.span)
                }}
              >
                {
                  is_google && <div className={classNames('connect',
                    {
                      _connected: option.is_connected,
                    }
                  )}></div>
                }
                {option.span || <span>&nbsp;</span>}
              </button>
            ))}
          </div>
        )}
      </Transition>
    </div>
  )
}

export default SelectWithSearchComponent
