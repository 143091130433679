import { useEffect, useState } from 'react';
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'
import { DateTime } from "luxon"
import DateTimeComponent from "react-datetime"
import moment from 'moment'

import Icon from '../../../components/Icon'
import SelectComponent from '../../../components/Select';

import { useAppSelector } from '../../../store/hooks';
import { httpClientUpdate, updatedDateToReqServer } from '../../../funcs'

import { CallCentersProps, HttpReport } from './List';

export default function New() {
  const $router = useRoute();

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [newData, setNewData] = useState({
    from: DateTime.now().plus({ hours: 0 }).set({ minute: 0 }).toJSDate(),
    to: DateTime.now().plus({ hours: 1 }).set({ minute: 0 }).toJSDate(),
    call_center_id: '',
  })
  const [loading, setLoading] = useState(false)

  const [edit, setEdit] = useState<CallCentersProps[]>([])

  useEffect(() => {
    getStore()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getStore() {
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/temporary-closed/report
      const { data: { data: temporaryReport, success } } = (await httpClientUpdate.get('/callcenter/temporary-closed/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
        }
      })) as { data: HttpReport }

      if (success) {
        setEdit(temporaryReport.edit.call_centers)
      }
    } catch (error) { }
  }

  // update document
  async function handleSave() {
    setLoading(true)
    try {
      let start = moment(newData.from).format('MM/DD/YYYY hh:mma')
      let end = moment(newData.to).format('MM/DD/YYYY hh:mma')

      // https://2022back4.artemiudintsev.com/api/callcenter/temporary-closed
      const response = await httpClientUpdate.post(`/callcenter/temporary-closed`, {
        account_id: activeAccountId,
        from: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, start),
        to: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, end),
        call_center_id: newData.call_center_id
      })
      if (response.data.success) {
        setLoading(false)
        $router.router.navigate('call_center.temporaryClosed', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className="CallCenter_CallGroupsPage_New entity-edit">
      {/* Top navigation */}
      <div className="top-nav">
        <BaseLink
          router={$router.router}
          routeName="call_center.phoneNumbers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
        >
          <Icon icon="phone-1" />
          <span>Phone Numbers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.dispatchers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
        >
          <Icon icon="user-1" />
          <span>Dispatchers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.additionalTime"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="time-22" />
          <span>Additional Time</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.absence"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.absence" })}
        >
          <Icon icon="time-16" />
          <span>Absence</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.groups" })}
        >
          <Icon icon="user-29" />
          <span>Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callFlows"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
        >
          <Icon icon="share-7" />
          <span>Call Flows</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.caller_groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
        >
          <Icon icon="user-29" />
          <span>Caller Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.temporaryClosed"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="close-664" />
          <span>Temporary Closed</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callCenters"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
        >
          <Icon icon="delivery-8" />
          <span>Call Centers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.audioLibrary"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="audio-15" />
          <span>Audio Library</span>
        </BaseLink>
      </div>

      <div className="wrapper flex-container sb">
        <h1>New Temporary Closed</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('call_center.temporaryClosed', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>From:</span>
              <DateTimeComponent
                value={newData.from as Date}
                onChange={(value) => setNewData({ ...newData, from: moment.isMoment(value) ? value.toDate() : newData.from })}
              />
            </div>

            <div className="field">
              <span>Call Center:</span>

              <SelectComponent
                options={edit.map((item) => ({
                  span: item.name,
                  value: item.call_center_id,
                }))}
                selectedOption={newData.call_center_id}
                onChange={(value: string) => setNewData({ ...newData, call_center_id: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>To:</span>
              <DateTimeComponent
                value={newData.to as Date}
                onChange={(value) => setNewData({ ...newData, to: moment.isMoment(value) ? value.toDate() : newData.to })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button className="_bordered _red"
            onClick={() => $router.router.navigate('call_center.temporaryClosed', {
              companyId: activeAccountId
            }, {
              reload: true
            })}>
            Cancel
          </button>

          <button
            disabled={loading || !newData.call_center_id}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>

    </div>
  )
}
