import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import moment, { Moment } from "moment"
import DateTimeComponent from "react-datetime"

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import SelectComponent from "../../components/Select";

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, screenSizeWidth } from "../../funcs"
import { useAppSelector } from "../../store/hooks"
import { arrTimesForSchedule } from "../../constans";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportData
}

interface ReportData {

  permissions: {
    additional_time_service_resource_delete: boolean
    additional_time_service_resource_edit: boolean
  },

  additional_time: AdditionalTime
}

interface AdditionalTime {
  additional_time_id: string
  date: Moment | Date | string
  created_at: string
  created_by: string
  service_resource_id: string
  updated_at: string
  updated_by: string

  service_resource_code: number
  service_resource_nickname: string
  service_resource_time_zone: string

  windows: WindowsProps[]
}

interface WindowsProps {
  additional_time_id?: string
  from: string
  to: string
  editing: boolean
}

function AbsencesPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const width = screenSizeWidth()

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<ReportData | null>(null)

  const [newData, setNewData] = useState<Partial<AdditionalTime>>({
  })

  function handleCancelEdit() {
    setEditing(false)
    if (reportData) {

      setNewData({
        ...reportData.additional_time,
        windows: reportData.additional_time.windows.map(item => {
          return {
            ...item,
            editing: false,
          }
        })
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l
      const response = await httpClientUpdate.delete(`/additional-times/${$router.router.getState().params.additionalTimeId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('additional-time', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      let date = moment(newData.date).format('YYYY-MM-DD')

      let windows: { from: string, to: string }[] = []

      if (newData.windows && !!newData.windows.length) {
        newData.windows.forEach(item => {
          if (!item.editing) {
            windows.push({
              from: item.from,
              to: item.to
            })
          }
        })
      }

      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l
      const response = await httpClientUpdate.put(`/additional-times/${$router.router.getState().params.additionalTimeId}`, {
        account_id: activeAccountId,
        date: date,
        windows: windows,
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l?account_id=88888
      const { data: { data: responseData, success, error } } = await httpClientUpdate.get('/additional-times/' + $router.router.getState().params.additionalTimeId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {

        setReportData(responseData)
        setNewData({
          ...responseData.additional_time,
          windows: responseData.additional_time.windows.map(item => {
            return {
              ...item,
              editing: false,
            }
          })
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  function handleAddTimeSlot() {
    if (newData.windows) {
      let updated = newData.windows.map(item => item)

      let from = ''
      let to = ''


      updated.push({
        from,
        to,
        editing: true
      })

      setNewData({
        ...newData,
        windows: updated
      })
    }
  }

  function handleSaveChangeTimeSlot(index: number) {
    if (newData.windows) {
      setNewData({
        ...newData,
        windows: newData.windows.map((item, i) => {
          if (i === index) {
            return { ...item, editing: !item.editing }
          } else {
            return { ...item }
          }
        })
      })
    }
  }

  function removeTimeSlot(index: number) {
    if (newData.windows) {
      setNewData({
        ...newData,
        windows: newData.windows.filter((item, i) => i !== index)
      })
    }
  }

  function handleChangeTimeSlot(index: number, name: string, value: string) {
    if (newData.windows) {
      setNewData({
        ...newData,
        windows: newData.windows.map((item, i) => {
          if (i === index) {
            if (name === 'start') {
              return { ...item, from: value }
            } else {
              return { ...item, to: value }
            }
          } else {
            return { ...item }
          }
        })
      })
    }
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="AbsencesPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Additional Time</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'additional-time',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                reportData.permissions.additional_time_service_resource_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        {
          width >= 901 ?
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 500px)' }}>
                <div className="fieldset">
                  <div className="legend">Main</div>

                  <div className="fields">
                    <div className="__left">
                      <div className="field" style={{ zIndex: 6 }}>
                        <span>Service resource:</span>

                        <input
                          type="text"
                          defaultValue={newData.service_resource_code ? `${newData.service_resource_nickname}(${newData.service_resource_code})` : newData.service_resource_nickname}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="__right">
                      <div className="field">
                        <span>Date:</span>

                        <DateTimeComponent
                          dateFormat={true}
                          timeFormat={false}
                          inputProps={{ disabled: !editing }}
                          value={newData.date}
                          onChange={(value) => setNewData({ ...newData, date: moment.isMoment(value) ? value.toDate() : newData.date })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldset">
                  <div className="legend">Info</div>

                  <div className="fields">
                    <div className="__left">
                      <div className="field">
                        <span>Date Created:</span>
                        <input
                          type="text"
                          defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.additional_time.created_at)}
                          disabled={true}
                        />
                      </div>

                      <div className="field">
                        <span>Created by:</span>
                        <input
                          type="text"
                          defaultValue={reportData.additional_time.created_by}
                          disabled={true}
                        />
                      </div>
                    </div>


                    <div className="__right">
                      <div className="field">
                        <span>Last Edited:</span>
                        <input
                          type="text"
                          defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.additional_time.updated_at)}
                          disabled={true}
                        />
                      </div>

                      <div className="field">
                        <span>Last Edited by:</span>
                        <input
                          type="text"
                          defaultValue={reportData.additional_time.updated_by}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="fieldset time_slots"
                style={{ width: '500px' }}
              >
                <div
                  className="legend"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  Time slots

                  <button className="_wa _green"
                    disabled={!editing}
                    onClick={() => handleAddTimeSlot()}
                  >
                    Add
                  </button>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 'calc(90% / 4)' }}>From</th>

                      <th style={{ width: 'calc(90% / 4)' }}>To</th>

                      <th style={{ width: '5%' }}></th>

                      <th style={{ width: '5%' }}></th>
                    </tr>
                  </thead>

                  {
                    newData.windows && newData.windows.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={arrTimesForSchedule.map((option) => ({
                                  span: option.span,
                                  value: option.value
                                }))}
                                selectedOption={item.from as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'start', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.from).length ?
                                arrTimesForSchedule.filter(time => time.value === item.from)[0].span :
                                item.from
                          }
                        </td>

                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={
                                  item.from === '' ?
                                    arrTimesForSchedule.map((option) => ({
                                      span: option.span,
                                      value: option.value
                                    })) :
                                    arrTimesForSchedule.filter((option) => item.from < option.value && ({
                                      span: option.span,
                                      value: option.value
                                    }))
                                }
                                selectedOption={item.to as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'end', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.to).length ?
                                arrTimesForSchedule.filter(time => time.value === item.to)[0].span :
                                item.to
                          }
                        </td>

                        <td>
                          {
                            !item.editing ?
                              <button
                                className="_zeroed _iconed _blue"
                                disabled={!editing}
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="pencil-14" />
                              </button> :
                              <button
                                className="_zeroed _iconed _green"
                                disabled={item.from === '' || item.to === ''}
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="check-mark-1" />
                              </button>
                          }
                        </td>

                        <td>
                          <button
                            className="_zeroed _iconed _red"
                            disabled={!editing}
                            onClick={() => removeTimeSlot(index)}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </div>
            </div> :
            <>
              <div className="fieldset">
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field" style={{ zIndex: 6 }}>
                      <span>Service resource:</span>

                      <input
                        type="text"
                        defaultValue={newData.service_resource_code ? `${newData.service_resource_nickname}(${newData.service_resource_code})` : newData.service_resource_nickname}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="__right">
                    <div className="field">
                      <span>Date:</span>

                      <DateTimeComponent
                        dateFormat={true}
                        timeFormat={false}
                        inputProps={{ disabled: !editing }}
                        value={newData.date}
                        onChange={(value) => setNewData({ ...newData, date: moment.isMoment(value) ? value.toDate() : newData.date })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="fieldset time_slots"
              >
                <div
                  className="legend"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  Time slots

                  <button className="_wa _green"
                    disabled={!editing}
                    onClick={() => handleAddTimeSlot()}
                  >
                    Add
                  </button>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 'calc(90% / 4)' }}>From</th>

                      <th style={{ width: 'calc(90% / 4)' }}>To</th>

                      <th style={{ width: '5%' }}></th>

                      <th style={{ width: '5%' }}></th>
                    </tr>
                  </thead>

                  {
                    newData.windows && newData.windows.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={arrTimesForSchedule.map((option) => ({
                                  span: option.span,
                                  value: option.value
                                }))}
                                selectedOption={item.from as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'start', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.from).length ?
                                arrTimesForSchedule.filter(time => time.value === item.from)[0].span :
                                item.from
                          }
                        </td>

                        <td>
                          {
                            item.editing ?
                              <SelectComponent
                                options={arrTimesForSchedule.map((option) => ({
                                  span: option.span,
                                  value: option.value
                                }))}
                                selectedOption={item.to as string || ''}
                                onChange={(value: string) => handleChangeTimeSlot(index, 'end', value)}
                              /> :
                              arrTimesForSchedule.filter(time => time.value === item.to).length ?
                                arrTimesForSchedule.filter(time => time.value === item.to)[0].span :
                                item.to
                          }
                        </td>

                        <td>
                          {
                            !item.editing ?
                              <button
                                className="_zeroed _iconed _blue"
                                disabled={!editing}
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="pencil-14" />
                              </button> :
                              <button
                                className="_zeroed _iconed _green"
                                disabled={item.from === '' || item.to === ''}
                                onClick={() => handleSaveChangeTimeSlot(index)}
                              >
                                <Icon icon="check-mark-1" />
                              </button>
                          }
                        </td>

                        <td>
                          <button
                            className="_zeroed _iconed _red"
                            disabled={!editing}
                            onClick={() => removeTimeSlot(index)}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </div>

              <div className="fieldset">
                <div className="legend">Info</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Date Created:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.additional_time.created_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Created by:</span>
                      <input
                        type="text"
                        defaultValue={reportData.additional_time.created_by}
                        disabled={true}
                      />
                    </div>
                  </div>


                  <div className="__right">
                    <div className="field">
                      <span>Last Edited:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.additional_time.updated_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Last Edited by:</span>
                      <input
                        type="text"
                        defaultValue={reportData.additional_time.updated_by}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
        }

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.additional_time_service_resource_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Additional time</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {
          reportData.permissions.additional_time_service_resource_delete &&
            deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Additional time
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the additional time it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
    ) : null}
  </>)
}

export default AbsencesPage_Item