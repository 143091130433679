import { memo, useState } from 'react'

import { formatPhoneNumber, getName, getSchuduleTimeWithName } from '../../funcs'

import { CallTypeProps } from './Models'

interface CallTypeP {
  callType: string
  setCallType: (value: string) => void
  callTypeSearch: string
  setCallTypeSearch: (value: string) => void
  handleSearchOnCallType: () => void
  callTypeData: CallTypeProps | null
  errorTerminal: () => string[]
  selectedJob: string
  setSelectedJob: (value: string) => void
  client_search_show: boolean
  isSaveClick: boolean
}

function CallType({
  callType,
  setCallType,
  callTypeSearch,
  setCallTypeSearch,
  handleSearchOnCallType,
  callTypeData,
  errorTerminal,
  selectedJob,
  setSelectedJob,
  client_search_show,
  isSaveClick,
}: CallTypeP) {
  const [activeRadio, setActiveRadio] = useState({
    "radio-type-1": true,
    'radio-type-2': false
  })

  function handleRadio(event: any, id: string) {
    const { name, checked } = event.target
    setSelectedJob(id)
    if (checked) {
      let updateRadio = { ...activeRadio }
      Object.keys(activeRadio).forEach(item => {
        if (item !== name) {
          updateRadio[item] = false
        } else {
          updateRadio[item] = checked
        }
      })
      setActiveRadio(updateRadio)
    }
  }

  function getType(type: string) {
    let typeString = ''
    if (type === 'RC') {
      typeString = 'Recall'
    } else if (type === 'FU') {
      typeString = 'Follow Up'
    } else if (type === 'SC') {
      typeString = 'Service Call'
    } else {
      typeString = type
    }

    return typeString
  }

  return (
    <div className='call-type booking-type'>
      <div className='call-variant'>
        <div className='call-variant_buttons'>
          <button
            className={callType === 'Service' ? 'btn call-variant_btn selected' : 'btn call-variant_btn'}
            onClick={() => setCallType('Service')}
          >
            Service Call
          </button>

          <button
            className={callType === 'Follow' ? 'btn call-variant_btn selected' : 'btn call-variant_btn'}
            onClick={() => setCallType('Follow')}
          >
            Follow Up
          </button>

          <button
            className={callType === 'Recall' ? 'btn call-variant_btn selected' : 'btn call-variant_btn'}
            onClick={() => setCallType('Recall')}
          >
            Recall
          </button>
        </div>

        <div className='call-terminal'>
          {
            !!errorTerminal().length &&
            errorTerminal().map(item => (
              <p key={item} > {item} </p>

            ))
          }
        </div>
      </div>

      {
        client_search_show &&
        <div className={callType === 'Service' ? 'call-variant_search collapse' : 'call-variant_search active'}>
          <div className='call-variant_search-form'>
            <input
              value={callTypeSearch}
              className='data-border call-variant_search-input'
              type="text"
              onChange={(event) => setCallTypeSearch(event.target.value)}
            />

            <button
              disabled={callTypeSearch === '' || isSaveClick}
              className='btn call-variant_search-button'
              onClick={() => !isSaveClick && handleSearchOnCallType()}
            >
              Search
            </button>
          </div>

          {
            callTypeData && callTypeData.jobs.map((job) => (
              <div
                key={job.job_id}
                className='call-variant_search-type'
              >
                <div className='call-variant_search-type_data'>
                  <p
                    className='call-variant_search-type_id'
                    style={{ marginBottom: 0 }}
                  >
                    Job#{job.job_name} - {getName(job.company_name, job.first_name, job.last_name)} {job.contact_phones.map(item => `${formatPhoneNumber(item.phone)} (${item.name})`).join(', ')}
                  </p>

                  {
                    !!job.appointments.length &&
                    <div className='appointment-info'>
                      {
                        job.appointments.map((appoint, idx) => (
                          <div
                            key={idx}
                            className="appointment-info-item"
                          >
                            <div
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                              className='call-variant_search-type_product'
                            >
                              <p style={{ fontSize: '13px' }}>
                                {appoint.appointment_name}: {getType(appoint.appointment_type)} - <span style={{ color: appoint.status === 'Canceled' ? '#FF0000' : appoint.status === 'Closed' ? '#01b41f' : 'inherit' }}>{appoint.status}</span> -
                                {' '}{getSchuduleTimeWithName(appoint.appointment_start, appoint.appointment_end, appoint.service_resource_time_zone)} -
                                {' '}{appoint.service_resource_code ? `${appoint.service_resource_name} (${appoint.service_resource_code})` : appoint.service_resource_name}
                              </p>
                            </div>

                            <div
                              className='call-variant_search-type_product'
                              style={{ marginBottom: '10px', color: 'rgb(142, 142, 147)' }}
                            >
                              <p>
                                {appoint.address}
                              </p>
                            </div>

                            {
                              appoint.units.map((unit, idx) => (
                                <div
                                  key={idx}
                                  className='call-variant_search-type_product'
                                >
                                  <p>
                                    {unit.unit_type}: {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}, {unit.brand === 'Other' ? unit.other_brand : unit.brand}
                                  </p>
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>

                <div className='call-variant_search-type_radiobtn'>
                  <div
                    className='radio'
                    onClick={() => setSelectedJob(job.job_id)}
                  >
                    <input
                      checked={activeRadio['radio-type-1']}
                      className='input-radio'
                      name='radio-type-1'
                      type="radio"
                      onChange={(event) => handleRadio(event, job.job_id)}
                    />
                    <label className='radiobox' htmlFor="radio-type-1">
                      {
                        selectedJob === job.job_id &&
                        <span className='active'></span>
                      }
                    </label>
                  </div>
                </div>
              </div>
            ))
          }

          {
            callTypeData && !callTypeData.jobs.length &&
            <div>
              <p style={{ textAlign: 'center' }}>Not Found</p>
            </div>
          }

          <div className='call-variant_search-type'></div>
        </div>
      }
    </div>
  )
}

export default memo(CallType)