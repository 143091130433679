import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import Appointment from "../../models/Appointment"
import Estimate from "../../models/Estimate"
import Invoice from "../../models/Invoice"
import Payment from "../../models/Payments"
import Client from "../../models/Client"
import Job from "../../models/Job"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import { getSchuduleTime, formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick, dateToInfoBlock } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: EstimateData
}

interface EstimateData {
  permissions: {
    estimate_add: boolean
    estimate_delete: boolean
    estimate_edit: boolean
    estimate_report_show: boolean
    estimate_show: boolean

    appointment_show: boolean
    client_show: boolean
    invoice_show: boolean
    job_show: boolean
  },

  invoice: Invoice[],
  clients: Client[],
  appointments: Appointment[],
  estimate: Estimate[],
  payments: Payment[],
  jobs: Job[],

  edit: {
    status: {
      id: number
      name: string
    }[]
  }
}

function EstimatePage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [estimateData, setEstimateData] = useState<EstimateData | null>(null)

  const [newEstimateData, setNewEstimateData] = useState<Partial<Estimate>>({
    status: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    estimateData && setNewEstimateData({
      ...estimateData.estimate[0],
      data: [
        {
          name: '', price: 0, type: 'part'
        }
      ]
    })
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/estimates/${$router.router.getState().params.estimatesId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('estimates', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/estimates/${$router.router.getState().params.estimatesId}`, {
        account_id: activeAccountId,
        name: newEstimateData.name,
        status: newEstimateData.status_id,
        total: newEstimateData.total,
        comment: newEstimateData.comment
      })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: estimateData, success, error } } = await httpClientUpdate.get('/estimates/' + $router.router.getState().params.estimatesId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (estimateData.permissions.estimate_show) {
          setEstimateData(estimateData)
          setNewEstimateData({
            ...estimateData.estimate[0],
            data: [
              {
                name: '', price: 0, type: 'part'
              }
            ]
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newEstimateData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function handleChange(value: string | number, name: string, index: number) {
    let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function handleRemoveUnit(index: number) {
    let updateData = newEstimateData.data && newEstimateData.data.filter((item, idx) => idx !== index)
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function handleAddUnit() {
    if (newEstimateData.data) {
      let updateData = newEstimateData.data.map(item => item)

      updateData.push({
        name: '', price: 0, type: 'part'
      })
      setNewEstimateData({ ...newEstimateData, data: updateData })
    }
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            price: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewEstimateData({ ...newEstimateData, data: updateData })
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewEstimateData({ ...newEstimateData, data: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewEstimateData({ ...newEstimateData, data: updateData })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  return <>
    {
      estimateData &&
      estimateData.permissions.estimate_show &&
      <div className="JobsPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Estimate</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'estimates',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                estimateData?.permissions.estimate_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  type="text"
                  defaultValue={newEstimateData.name || ''}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Status:</span>
                <Select
                  disabled={!editing}
                  options={estimateData.edit.status.map((option) => ({
                    span: option.name,
                    value: option.id
                  }))}
                  selectedOption={newEstimateData.status_id as number}
                  onChange={(value) => setNewEstimateData({ ...newEstimateData, status_id: value as number })}
                />
              </div>

              <div className="field">
                <span>Comment:</span>
                <input
                  type="text"
                  value={newEstimateData.comment || ''}
                  onChange={({ target: { value } }) => setNewEstimateData({ ...newEstimateData, comment: value })}
                  disabled={!editing}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Service Resource:</span>
                <input
                  type="text"
                  defaultValue={estimateData?.estimate[0].service_resource_code ? `${estimateData?.estimate[0].service_resource} (${estimateData?.estimate[0].service_resource_code})` : estimateData?.estimate[0].service_resource}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Area:</span>
                <input
                  type="text"
                  defaultValue={estimateData?.estimate[0].area}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Financial</div>

          <div className="fields">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {
                  newEstimateData.data && newEstimateData.data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: '55%' }}>
                        <input
                          type="text"
                          value={item.name || ''}
                          onChange={({ target: { value } }) => handleChange(value, 'name', index)}
                          disabled={!editing}
                        />
                      </td>

                      <td style={{ width: '20%' }}>
                        <Select
                          disabled={!editing}
                          options={['part', 'labor'].map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={item.type as string}
                          onChange={(value) => handleChange(value as string, 'type', index)}
                        />
                      </td>

                      <td style={{ width: '20%' }}>
                        <input
                          className='text-field'
                          style={{ textAlign: 'right' }}
                          type="number"
                          disabled={!editing}
                          value={item.price.toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangePrice(event, index)}
                          onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                        />
                      </td>

                      <td style={{ width: '5%' }}>
                        {
                          editing &&
                          <button
                            onClick={() => handleRemoveUnit(index)}
                            style={{ background: 'inherit', width: 'fit-content', padding: '0', margin: '0 auto' }}
                          >
                            <Icon
                              style={{ fill: '#FF0000', width: '15px', height: '15px' }}
                              icon="x-mark-1"
                            />
                          </button>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className="field">
            {
              editing &&
              // data.permissions.technician_invoice_add &&
              <div style={{ marginTop: '10px' }} className='add-button'>
                <button
                  className='_green'
                  onClick={() => handleAddUnit()}
                >Add</button>
              </div>
            }
          </div>

          <div className="fields">
            <div className="__left"></div>

            <div className="__right">
              <div className="field">
                <span>Total:</span>

                <input
                  style={{ textAlign: 'right' }}
                  type="text"
                  defaultValue={newEstimateData.total || ''}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimateData?.estimate[0].created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={estimateData?.estimate[0].created_by || ''}
                  disabled={true}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimateData?.estimate[0].updated_at)}
                  disabled={true}
                />
              </div>
              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={estimateData?.estimate[0].updated_by || ''}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Clients</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Main Phone</th>
                <th>Source</th>
                <th>Jobs</th>
                <th>Appointments</th>
                <th>Recalls</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Unpaid</th>
              </tr>
            </thead>

            {estimateData.clients && estimateData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.client_show && client.client_id ? 'cursor' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>{client.name}</td>
                <td>{formatPhoneNumber(client.main_phone)}</td>
                <td>{client.source}</td>
                <td>{client.jobs}</td>
                <td>{client.appointments}</td>
                <td>{client.recalls}</td>
                <td>{client.total}</td>
                <td>{client.paid}</td>
                <td>
                  {client.unpaid}
                  {
                    estimateData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {estimateData.clients && estimateData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.client_show && client.client_id ? 'cursor' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>
                  <div>{client.name}</div>
                  <div>{formatPhoneNumber(client.main_phone)}</div>
                </td>
                <td>
                  <div>{client.source}</div>
                  <div>Jobs: {client.jobs}</div>
                </td>
                <td>
                  <div>Appointments: {client.appointments}</div>
                  <div>Recalls: {client.recalls}</div>
                </td>
                <td>
                  <div>Total: {client.total}</div>
                  <div>Paid: {client.paid}</div>
                </td>
                <td>
                  <div>Unpaid: {client.unpaid}</div>
                  <div>&nbsp;</div>
                  {
                    estimateData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {estimateData.clients && estimateData.clients.map((client, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    estimateData.permissions.client_show &&
                    client.client_id &&
                    $router.router.navigate('clients.item', {
                      companyId: activeAccountId,
                      clientId: client.client_id,
                      fromPage: 'estimates.item',
                      nameId: 'estimatesId',
                      id: $router.router.getState().params.estimatesId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><b>{client.name}</b></div>
                      <div>
                        {formatPhoneNumber(client.main_phone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{client.total}</b>
                      </div>
                      <div>
                        Paid: <b>{client.paid}</b>
                      </div>
                      <div>
                        Unpaid: <b>{client.unpaid}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Jobs:</span>
                        <span> {client.jobs}</span>
                      </div>
                      <div>
                        <span className="gray">Recalls:</span>
                        <span> {client.recalls}</span>
                      </div>
                      <div>
                        <span className="gray">Appointments:</span>
                        <span> {client.appointments}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{client.source}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Jobs</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Status</th>
                <th>Property type</th>
                <th>Area</th>
                <th>Source</th>
                <th>Created by</th>
                <th>Appointments</th>
                <th>Service resource</th>
                <th>Total</th>
                <th>Paid</th>
              </tr>
            </thead>

            {estimateData.jobs && estimateData.jobs.map((job, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.job_show && job.job_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobsId: job.job_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</td>
                <td>{job.name}</td>
                <td>{job.status}</td>
                <td>{job.property_type}</td>
                <td>{job.area}</td>
                <td>{job.source}</td>
                <td>{job.created_by}</td>
                <td>{job.appointments}</td>
                <td>{job.service_resource_code ? `${job.service_resource} (${job.service_resource_code})` : job.service_resource}</td>
                <td>{job.total}</td>
                <td>
                  {job.paid}
                  {
                    estimateData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobsId: job.job_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medum screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {estimateData.jobs && estimateData.jobs.map((job, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.job_show && job.job_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>
                  <div>{job.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</div>
                </td>
                <td>
                  <div>{job.created_by}</div>
                  <div>{job.service_resource_code ? `${job.service_resource} (${job.service_resource_code})` : job.service_resource}</div>
                </td>
                <td>
                  <div>{job.area}</div>
                  <div>{job.source}</div>
                </td>
                <td>
                  <div>{job.property_type}</div>
                  <div>{job.status}</div>
                </td>
                <td>
                  <div>Appointments: {job.appointments}</div>
                  <div>&nbsp;</div>
                </td>
                <td>
                  <div>Total: {job.total}</div>
                  <div>Paid: {job.paid}</div>
                  {
                    estimateData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobsId: job.job_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            {estimateData.jobs && estimateData.jobs.map((job, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  estimateData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div>
                      <strong>{job.name}</strong>
                    </div>
                    <div>
                      Appointments: {job.appointments}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Paid: <b>{job.paid}</b>
                    </div>
                    <div>
                      Total: <b>{job.total}</b>
                    </div>
                    <div>
                      {job.property_type}
                    </div>
                    <div>
                      <b>{job.status}</b>
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Area:</span>
                      <span> {job.area}</span>
                    </div>
                    <div>
                      <span className="gray">Source:</span>
                      <span> {job.source}</span>
                    </div>
                    <div>
                      <span className="gray">Service Resourse:</span>
                      <span> {job.service_resource_code ? `${job.service_resource} (${job.service_resource_code})` : job.service_resource}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}
                    </div>
                    <div>
                      Created by {job.created_by}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Appointments</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Property type</th>
                <th>Area</th>
                <th>Created by</th>
                <th>Schedule time</th>
                <th>Service resource</th>
              </tr>
            </thead>

            {estimateData.appointments && estimateData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</td>
                <td>{appointment.name}</td>
                <td>
                  <span className={classNames({
                    red: ['Recall', 'RC'].includes(appointment.type),
                    green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                    blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                  })}
                  >
                    {appointment.type}
                  </span>
                </td>
                <td>{appointment.status}</td>
                <td>{appointment.property_type}</td>
                <td>{appointment.area}</td>
                <td>{appointment.created_by}</td>
                <td>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</td>
                <td>
                  {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}
                  {
                    estimateData.permissions.job_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {estimateData.appointments && estimateData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>
                  <div>{appointment.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</div>
                </td>
                <td>
                  <div>{appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</div>
                  <div>{appointment.created_by}</div>
                </td>
                <td>
                  <div>{appointment.area}</div>
                  <div>
                    <span className={classNames({
                      red: ['Recall', 'RC'].includes(appointment.type),
                      green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                      blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                    })}
                    >
                      {appointment.type}
                    </span>
                  </div>
                </td>
                <td>
                  <div>{appointment.property_type}</div>
                  <div>{appointment.status}</div>
                </td>
                <td>
                  <div>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</div>
                  <div>&nbsp;</div>
                  {
                    estimateData.permissions.job_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <div className="mobile-table-items">
              {estimateData.appointments && estimateData.appointments.map((appointment, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    estimateData.permissions.appointment_show &&
                    appointment.appointment_id &&
                    $router.router.navigate('appointments.item', {
                      companyId: activeAccountId,
                      appointmentId: appointment.appointment_id,
                      fromPage: 'estimates.item',
                      nameId: 'estimatesId',
                      id: $router.router.getState().params.estimatesId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div className="flex-container">
                        <strong>{appointment.name}</strong>
                      </div>
                      <div>
                        {getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        <span className={classNames({
                          red: ['Recall', 'RC'].includes(appointment.type),
                          green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                          blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                        })}
                        >
                          {appointment.type}
                        </span>
                      </div>
                      <div>
                        {appointment.property_type}
                      </div>
                      <div>
                        <div className="fw500">{appointment.status}</div>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {appointment.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}
                      </div>
                      <div>
                        Created by {appointment.created_by}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Invoices</div>
          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th><span>Date</span></th>
                <th><span>Name</span></th>
                <th><span>Status</span></th>
                <th><span>Service Resource</span></th>
                <th><span>Area</span></th>
                <th><span>Total</span></th>
                <th><span>Paid</span></th>
                <th><span>Unpaid</span></th>
              </tr>
            </thead>

            {estimateData.invoice && estimateData.invoice.map((invoice, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.invoice_show && invoice.invoice_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  $router.router.navigate('invoices.item', {
                    companyId: activeAccountId,
                    invoicesId: invoice.invoice_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  handleChangeMenu(event, invoice.invoice_id, 'invoices', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}</td>
                <td>{invoice.name}</td>
                <td>{invoice.status}</td>
                <td>{invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</td>
                <td>{invoice.area}</td>
                <td>{invoice.total}</td>
                <td>{invoice.paid}</td>
                <td>
                  {invoice.unpaid}
                  {
                    estimateData.permissions.invoice_show &&
                    showContext === `invoices_${invoice.invoice_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('invoices.item', {
                            companyId: activeAccountId,
                            invoicesId: invoice.invoice_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/invoices/${invoice.invoice_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {estimateData.invoice && estimateData.invoice.map((invoice, i) => (
              <tr
                key={i}
                style={{ cursor: estimateData.permissions.invoice_show ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  estimateData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  $router.router.navigate('invoices.item', {
                    companyId: activeAccountId,
                    invoicesId: invoice.invoice_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  estimateData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  handleChangeMenu(event, invoice.invoice_id, 'invoices', i)}
              >
                <td>
                  <div>{invoice.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}</div>
                </td>
                <td>
                  <div>{invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</div>
                  <div>{invoice.area}</div>
                </td>
                <td>
                  <div>{invoice.status}</div>
                </td>
                <td>
                  <div>Total: {invoice.total}</div>
                </td>
                <td>
                  <div>Paid: {invoice.paid}</div>
                  <div>Unpaid: {invoice.unpaid}</div>
                  {
                    estimateData.permissions.invoice_show &&
                    showContext === `invoices_${invoice.invoice_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('invoices.item', {
                            companyId: activeAccountId,
                            invoicesId: invoice.invoice_id,
                            fromPage: 'estimates.item',
                            nameId: 'estimatesId',
                            id: $router.router.getState().params.estimatesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/invoices/${invoice.invoice_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            {estimateData.invoice && estimateData.invoice.map((invoice, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  estimateData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  $router.router.navigate('invoices.item', {
                    companyId: activeAccountId,
                    invoicesId: invoice.invoice_id,
                    fromPage: 'estimates.item',
                    nameId: 'estimatesId',
                    id: $router.router.getState().params.estimatesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div>
                      <strong>{invoice.name}</strong>
                    </div>
                    <div>
                      Appointments: {invoice.status}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Total: <b>{invoice.total}</b>
                    </div>
                    <div>
                      Paid: <b>{invoice.paid}</b>
                    </div>
                    <div>
                      Unpaid {invoice.unpaid}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Area:</span>
                      <span> {invoice.area}</span>
                    </div>

                    <div>
                      <span className="gray">Service Resourse:</span>
                      <span> {invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                estimateData.permissions.estimate_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Estimate</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {estimateData.permissions.estimate_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>
              <div className="title">
                Delete Estimate
              </div>

              <div className="checkbox-wrapper">
                <Checkbox
                  contents="I understand that after deleting the estimate it will not be possible to recover."
                  value={readyToDelete}
                  onChange={(value) => setReadyToDelete(value)}
                />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    }
  </>
}

export default EstimatePage_Item