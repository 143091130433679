import { ReactChild, useEffect, useState } from "react";
import classNames from "classnames"

import Icon from "./Icon"

import "../styles/components/checkbox.sass"

interface Props {

  contents: ReactChild,
  value?: boolean,

  disabled?: boolean,

  onChange?(value: boolean): void
}

function Checkbox({
  contents,
  value,
  disabled,
  onChange
}: Props) {

  const [active, setActive] = useState<boolean>(!!value)

  useEffect(() => {

    setActive(!!value)
  }, [value])

  // Render function
  return (
    <div className={classNames("Checkbox", { _active: active })} onClick={() => { !disabled && onChange && onChange(!active) }}>
      <Icon icon={active ? 'checkbox-9' : 'square-5'} />
      {contents ? (
        <div className="contents">
          {contents}
        </div>
      ) : null}
    </div>
  )
}

export default Checkbox
