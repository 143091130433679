import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment, { Moment } from 'moment'
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import Select from '../../components/Select'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  invoice: InvoiceProps
  permissions: {
    technician_invoice_delete: boolean
    technician_invoice_edit: boolean
  }
  edit: {
    invoice_status: {
      invoice_status: string
      invoice_status_id: number
    }[]
  }
}

const dataMock = {
  status_id: 3,
  comment: 'comment',
  service_resource: 'Mike',
  name: 'name'
}

interface InvoiceProps {
  contact_id: string
  estimate_id: string
  invoice_id: string
  job_id: string
  name: string
  status: string
  status_id: number
  total: number

  comment: string
  service_resource_code: number
  service_resource_nickname: string
}

interface Invoice {
  status_id: number
  comment: string
  service_resource: string
  name: string
  data: {
    name: string
    price: number
    type: string
  }[]
  payments: {
    date: Moment
    status: string
    type: string
    number: string
    total: number
  }[]
}

interface InvoiceEditProps {
  switchPage: (name: string) => void
  id: string
}

export default function InvoiceEdit({ switchPage, id }: InvoiceEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [editing, setEditing] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [data, setData] = useState<DataProps | null>(null)
  const [updateData, setUpdateData] = useState<Partial<InvoiceProps>>({})

  const [newData, setNewData] = useState<Invoice>({
    status_id: 0,
    comment: '',
    service_resource: '',
    name: 'name',
    data: [{
      name: '',
      price: 0,
      type: 'part',
    }],
    payments: [{
      date: moment(),
      status: '',
      type: '',
      number: '0',
      total: 0,
    }]
  })

  async function loadInvoices() {
    try {
      const { data: { data: invoiceData, success, error } } = await httpClientUpdate.get('/technician/invoices/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setData(invoiceData)
        setUpdateData(invoiceData.invoice)

        setNewData({ ...newData, ...dataMock })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    updateData
  ])

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/invoices/${id}`, {
      data: {
        account_id: activeAccountId,
      }
    })
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleCancelEdit() {
    data && setUpdateData(data.invoice)
    setNewData({
      ...dataMock,
      data: [{ name: '', price: 0, type: 'part' }],
      payments: [{
        date: moment(),
        status: '',
        type: '',
        number: '0',
        total: 0,
      }]
    })
    setEditing(false)
  }

  async function handleSave() {
    const response = await httpClientUpdate.put(`/technician/invoices/${id}`, qs.stringify({
      account_id: activeAccountId,
      comment: updateData.comment,
      status_id: updateData.status_id,
      appointment_id: $router.router.getState().params.jobId,
      job_id: updateData.job_id,
      client_id: updateData.contact_id,
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChange(value: string | number, name: string, index: number) {
    let updateData = newData.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewData({ ...newData, data: updateData })
  }

  function handleChangePayments(value: string | number | Moment, name: string, index: number) {
    let updateData = newData.payments.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewData({ ...newData, payments: updateData })
  }

  function handleRemoveUnit(index: number) {
    let updateData = newData.data.filter((item, idx) => idx !== index)
    setNewData({ ...newData, data: updateData })
  }

  function handleAddUnit() {
    let updateData = newData.data.map(item => item)
    updateData.push({
      name: '', price: 0, type: 'part'
    })
    setNewData({ ...newData, data: updateData })
  }

  function handleAddPayment() {
    let updateData = newData.payments.map(item => item)
    updateData.push({
      date: moment(),
      status: '',
      type: '',
      number: '0',
      total: 0,
    })
    setNewData({ ...newData, payments: updateData })
  }

  function getTotal() {
    let sum = 0
    newData.data.forEach(item => {
      sum = sum + +item.price
    })
    return sum.toFixed(2)
  }

  function getTotalPayments() {
    let sum = 0
    newData.payments.forEach(item => {
      sum = sum + +item.total
    })
    return sum.toFixed(2)
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newData.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            price: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewData({ ...newData, data: updateData })
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, data: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, data: updateData })
      }
    }
  }

  function handleChangeTotal(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newData.payments.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            total: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewData({ ...newData, payments: updateData })
    }
  }

  function handleChangeTotalBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newData.payments.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, payments: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newData.payments.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewData({ ...newData, payments: updateData })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  return (
    <>
      {
        data ?
          <div className="EstimatePage_Edit entity-edit">
            <div className="wrapper flex-container sb">
              <div className="flex-container _gap-narrow">
                <h1>Invoice: </h1>
                <div className="item-name">
                  {updateData.name}
                </div>
              </div>

              <div style={{ display: 'flex' }} >
                <button
                  disabled={editing}
                  style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  onClick={() => switchPage('main')}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>

                {
                  !editing &&
                    data.permissions.technician_invoice_edit ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      <Icon
                        style={{ width: '16px', height: '16px' }}
                        viewBox="0 0 16 16"
                        icon="pencil-1"
                      />
                    </button>
                  ) : null
                }
              </div>
            </div>

            <div className="fieldset">

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Service Resource:</span>
                    <input
                      type="text"
                      defaultValue={updateData.service_resource_code ? `${updateData.service_resource_nickname} (${updateData.service_resource_code})` : updateData.service_resource_nickname}
                      disabled={true}
                      onChange={({ target: { value } }) => setUpdateData({ ...updateData, service_resource_nickname: value })}
                    />
                  </div>

                  <div className="field">
                    <span>Status:</span>
                    {
                      data.edit.invoice_status ?
                        <Select
                          disabled={!editing}
                          options={data.edit.invoice_status.map((option) => ({
                            span: option.invoice_status,
                            value: option.invoice_status_id
                          }))}
                          selectedOption={updateData.status_id as number}
                          onChange={(value) => setUpdateData({ ...updateData, status_id: value as number })}
                        /> :
                        <input
                          type="text"
                          defaultValue={updateData.status || ''}
                          disabled={true}
                          onChange={({ target: { value } }) => setUpdateData({ ...updateData, status: value })}
                        />
                    }
                  </div>
                </div>
                <div className="__right">
                  <div className="field">
                    <span>Comment:</span>
                    <textarea
                      style={{ marginRight: '0', marginLeft: '0' }}
                      className='description'
                      name='comment'
                      disabled={!editing}
                      value={updateData.comment || ''}
                      onChange={({ target: { value, name } }) => setUpdateData({ ...updateData, comment: value })}
                    >

                    </textarea>
                  </div>
                </div>
              </div>

              {/* Estimate table */}
              <div className="fields">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      newData.data.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: '55%' }}>
                            <input
                              type="text"
                              value={item.name || ''}
                              onChange={({ target: { value } }) => handleChange(value, 'name', index)}
                              disabled={!editing}
                            />
                          </td>

                          <td style={{ width: '20%' }}>
                            <Select
                              disabled={!editing}
                              options={['part', 'labor'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.type as string}
                              onChange={(value) => handleChange(value as string, 'type', index)}
                            />
                          </td>

                          <td style={{ width: '20%' }}>
                            <input
                              style={{ textAlign: 'right' }}
                              type="number"
                              disabled={!editing}
                              value={item.price.toFixed(2)}
                              onFocus={(event) => handleFocus(event)}
                              onChange={(event) => handleChangePrice(event, index)}
                              onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                            />
                          </td>

                          <td style={{ width: '5%' }}>
                            {
                              editing &&
                              <button
                                onClick={() => handleRemoveUnit(index)}
                                style={{ background: 'inherit', width: 'fit-content', padding: '0', margin: '0 auto' }}
                              >
                                <Icon
                                  style={{ fill: '#FF0000', width: '15px', height: '15px' }}
                                  icon="x-mark-1"
                                />
                              </button>
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="field">
                {
                  editing &&
                  <div style={{ marginTop: '10px' }} className='add-button'>
                    <button
                      className='_green'
                      onClick={() => handleAddUnit()}
                    >Add</button>
                  </div>
                }
              </div>

              <div style={{ flexWrap: 'nowrap' }} className="fields">
                <div style={{ width: '50%' }} ></div>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>Total:</span>
                  <input
                    style={{ marginRight: '20px', textAlign: 'right' }}
                    type="text"
                    value={getTotal()}
                    disabled={true}
                  />
                </div>
              </div>

              {/* Payments table */}
              <div className="fields">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Number</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      newData.payments.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: 'calc(100% / 5)' }}>
                            <Datetime
                              inputProps={{ disabled: !editing }}
                              value={item.date}
                              onChange={(value) => handleChangePayments(value, 'date', index)} />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <Select
                              disabled={!editing}
                              options={['Pending', 'Complete'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.status as string}
                              onChange={(value) => handleChangePayments(value as string, 'status', index)}
                            />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <Select
                              disabled={!editing}
                              options={['Card', 'Cash', 'Check'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.type as string}
                              onChange={(value) => handleChangePayments(value as string, 'type', index)}
                            />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <input
                              type="number"
                              value={item.number || ''}
                              onChange={({ target: { value } }) => handleChangePayments(value, 'number', index)}
                              disabled={!editing}
                            />
                          </td>

                          <td style={{ width: 'calc(100% / 5)' }}>
                            <input
                              style={{ textAlign: 'right' }}
                              type="number"
                              disabled={!editing}
                              value={item.total.toFixed(2)}
                              onFocus={(event) => handleFocus(event)}
                              onChange={(event) => handleChangeTotal(event, index)}
                              onKeyDown={(event) => handleChangeTotalBackspace(event, index)}
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="field">
                <div style={{ marginTop: '10px' }} className='add-button'>
                  <button
                    className='_green'
                    onClick={() => handleAddPayment()}
                  >Add</button>
                </div>
              </div>

              <div style={{ flexWrap: 'nowrap' }} className="fields">
                <div style={{ width: '50%' }} ></div>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>Total:</span>
                  <input
                    style={{ marginRight: '20px', textAlign: 'right' }}
                    type="text"
                    value={getTotalPayments()}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.technician_invoice_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Unit</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Invoice
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the invoice it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div> : null
      }
    </>
  )
}
