import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Switcher from '../../components/Switcher'
import SelectWithSearchComponent from '../../components/SelectWithSearch'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { HttpReport } from './List'

import "../../styles/pages/common/entity-edit.sass"

interface UsersProps {
  user_id: string
  user_name: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [edit, setEdit] = useState<UsersProps[]>([])
  const [staffData, setStaffData] = useState({
    available: false,
    nickname: '',
    user_name: '',
  })

  useEffect(() => {
    loadInfo()

    return (() => {
      setEdit([])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Load info function
  async function loadInfo() {
    try {
      let activeFilters: any = {}
      activeFilters['filters[]'] = 'null'

      // https://2022back4.artemiudintsev.com/api/staff/report?account_id=88888&limit_rows=1&page=1
      const { data: { data: report, success, error } } = await httpClientUpdate.get('/staff/report',
        {
          params: {
            account_id: activeAccountId,
            limit_rows: 1,
            page: 1,
            ...activeFilters,
          }
        }) as { data: HttpReport }

      if (success) {
        setEdit(report.edit.users)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/staff
      const response = await httpClientUpdate.post(`/staff`, {
        account_id: activeAccountId,
        nickname: staffData.nickname,
        user_id: staffData.user_name,
        available: staffData.available ? 1 : 0,
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('staff', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Staff</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('staff', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>

              <input
                type="text"
                value={staffData.nickname}
                onChange={({ target: { value } }) => setStaffData({ ...staffData, nickname: value })}
              />
            </div>

            <div className="field">
              <span>User:</span>

              <SelectWithSearchComponent
                options={edit.map((action) => ({
                  span: action.user_name,
                  value: action.user_id
                }))}
                selectedOption={staffData.user_name}
                onChange={(value) => setStaffData({ ...staffData, user_name: value as string })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Active:</span>

              <Switcher
                checked={staffData.available}
                onChange={(value) => setStaffData({ ...staffData, available: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('staff', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, {
              reload: true
            })}
          >
            Cancel
          </button>

          <button
            className="_bordered _green"
            disabled={staffData.nickname === '' || staffData.user_name === '' || isSaving}
            onClick={() => handleSave()}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  )
}
