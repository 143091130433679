import { useEffect, useRef, useState } from 'react'
import ReactQuill, { Quill } from "react-quill";
//@ts-ignore
import quillEmoji from 'quill-emoji';
import classNames from 'classnames';

import EmojyPicker from "./PickerComponent";
import Icon from '../../components/Icon';

import { dateToInfoBlock, formatPhoneNumber, getSchuduleTime, useOuterClick } from '../../funcs';
import { useAppSelector } from '../../store/hooks';

import { AttachmentsProps, EditUsersProps, SelectedDocumentsProps } from './ChannelNew';
import ClipPopup, { AppointmentsProps, CallsProps, JobsProps, TicketsProps } from './ClipPopup';

import "react-quill/dist/quill.snow.css";
import "react-quill-emoji/dist/quill-emoji.css";

Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji
  },
  true
);

const modules = {
  toolbar: [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
  ],
  'emoji-toolbar': true,
  "emoji-shortname": true,
}
const formats = ['font', 'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'color', 'background', 'list', 'indent', 'align', 'image', 'clean', 'emoji']

interface EditTextProps {
  editText: string
  setEditText: (value: string) => void
  setEditMainMessage: (value: string) => void
  seconds: number
  updatedMessage: (value: boolean, text: string) => void
  usersOption: EditUsersProps[]
  channel_user_id: string
  openClip: boolean
  setOpenClip: (value: boolean) => void
  selectedDocuments: SelectedDocumentsProps[]
  setSelectedDocuments: (value: SelectedDocumentsProps[]) => void
  setUpdatedHeight: (value: number) => void
  selectedEditAttachments: AttachmentsProps[]
  setSelectedEditAttachments: (value: AttachmentsProps[]) => void
}

export default function EditText({
  editText,
  setEditText,
  setEditMainMessage,
  seconds,
  updatedMessage,
  usersOption,
  channel_user_id,
  openClip,
  setOpenClip,
  selectedDocuments,
  setSelectedDocuments,
  setUpdatedHeight,
  selectedEditAttachments,
  setSelectedEditAttachments,
}: EditTextProps) {
  const refComponent = useRef(null)

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [value, setValue] = useState(editText)
  const [selectIndex, setSelectIndex] = useState(0)
  const [showEmoji, setShowEmoji] = useState(false)
  const [openChannelUsers, setOpenChannelUsers] = useState(false)
  const [openMenuChannelUsers, setOpenMenuChannelUsers] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [filterUser, setFilterUser] = useState('')

  useEffect(() => {
    if (!!selectedDocuments.length) {
      setReadyToSave(true)
    }
    if (!!selectedEditAttachments.length) {
      setReadyToSave(true)
    }
  }, [selectedDocuments.length, selectedEditAttachments.length])

  useEffect(() => {
    if (refComponent.current) {
      //@ts-ignore
      handleChangeMainText(refComponent.current.editingArea.innerText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (selectedDocuments.length === 1) {
      setUpdatedHeight(Math.random())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocuments])

  function handleChangeMainText(text: string) {
    if (openChannelUsers) {
      if (text.charAt(text.length - 1) === ' ') {

        setOpenChannelUsers(false)
        filterUser && setFilterUser('')
      } else if (text === '') {
        setOpenChannelUsers(false)
        filterUser && setFilterUser('')
      } else {
        let findIndexes = findIndices(text, '@')

        if (findIndexes.length === 1) {
          setFilterUser(text.slice(findIndexes[0] + 1))
        } else if (findIndexes.length > 1) {
          setFilterUser(text.slice(findIndexes[findIndexes.length - 1] + 1))
        }
      }
    } else {
      if (text === '@' || (text.charAt(selectIndex - 1) === '@' && selectIndex === 1)) {
        setOpenChannelUsers(true)
        // setMainText(text)

      } else if (text.charAt(text.length - 1) === '@' && text.charAt(text.length - 2) === ' ') {
        setOpenChannelUsers(true)
      } else if (text.charAt(selectIndex - 1) === '@' && text.charAt(selectIndex - 2) === ' ') {
        setOpenChannelUsers(true)
      } else {
        // setMainText(text)
        setOpenChannelUsers(false)
        filterUser && setFilterUser('')
      }
    }
  }

  function handleChangeText(text: string) {
    if (refComponent.current) {
      //@ts-ignore
      let textWithOutTag = refComponent.current?.editingArea?.innerText

      let findUser = false
      usersOption.forEach(item => {
        if (textWithOutTag.slice(selectIndex - item.user_name.length - 1, selectIndex - 1) === item.user_name) {
          findUser = true
          const updatedText = text.replace(`@${item.user_name}${textWithOutTag.slice(selectIndex - 1, selectIndex)}</span>`, `@${item.user_name}</span>${textWithOutTag.slice(selectIndex - 1, selectIndex) === '' ? ' <span></span>' : textWithOutTag.slice(selectIndex - 1, selectIndex)}`)
          setValue(updatedText)
        }
      })

      if (text === '<p><br></p>') {
        setValue('')
        setOpenChannelUsers(false)
      } else {
        if (!findUser) {
          setValue(text)
        }
      }
    }
  }

  function handleAddEmojiInText(emoji: string) {
    try {
      //@ts-ignore
      let textWithOutTag = refComponent.current?.editingArea?.innerText
      let sliceText = textWithOutTag.slice(0, selectIndex)
      let splitText = sliceText.split(' ')

      let valueSplit = value.split(' ')

      if (splitText.length > 1) {
        if (splitText.length !== valueSplit.length) {
          if (splitText[splitText.length - 1] === '') {
            valueSplit[valueSplit.length - 1] = `${emoji}`
          } else {
            let findIndex = 0
            let checkIndex = 0
            valueSplit.forEach((item, index) => {
              if (item.includes(splitText[checkIndex])) {
                if (checkIndex === splitText.length - 1) {
                  findIndex = index
                } else {
                  checkIndex = checkIndex + 1
                }
              }
            })
            valueSplit[findIndex] = `${valueSplit[findIndex].replace('</p>', ' ')}<span>${emoji}</span>`
          }
        } else {
          if (valueSplit[splitText.length - 1].includes('</p>')) {
            valueSplit[splitText.length - 1] = `${valueSplit[splitText.length - 1].replace('</p>', '')}<span>${emoji}</span>`
          } else {
            valueSplit[splitText.length - 1] = `${valueSplit[splitText.length - 1]}<span>${emoji}</span>`
          }
        }
      } else {
        valueSplit[0] = `${valueSplit[0].replace('</p>', ' ')}<span>${emoji}</span>`
      }

      setValue(valueSplit.join(' '))
      setSelectIndex(selectIndex + 2)
      //@ts-ignore
      const editor = refComponent.current.getEditor();
      setTimeout(() => editor.setSelection(selectIndex + 2, selectIndex + 2), 0)
    } catch (error) { }
  }

  const findIndices = (str: string, char: string) => {
    return str.split('').reduce((indices: number[], letter, index) => {
      letter === char && indices.push(index);
      return indices;
    }, [])

  }

  function replaceAt(index: number, replacement: string) {
    return value.substring(0, index) + replacement + value.substring(index + 1);
  }

  function handleAddChannelUsers(id: string, name: string) {
    let index = selectIndex

    let findIndexes = findIndices(value, '@')

    let findIndex = 0
    let min = 0

    findIndexes.forEach((item, i) => {
      if (i === 0) {
        min = index - item
        findIndex = i
      } else if (index - item < min) {
        min = index - item
        findIndex = i
      }
    })

    setOpenChannelUsers(false)
    setValue(replaceAt(findIndexes[findIndex], `<span style="background-color: rgb(204, 224, 245); color: rgb(0, 102, 204);">@${name}</span> <span></span>`))
  }

  function handleAddMenuChannelUsers(name: string) {
    //@ts-ignore
    let textWithOutTag = refComponent.current?.editingArea?.innerText
    let sliceText = textWithOutTag.slice(0, selectIndex)
    let splitText = sliceText.split(' ')

    let valueSplit = value.split(' ')

    if (splitText.length > 1) {
      if (splitText.length !== valueSplit.length) {
        if (splitText[splitText.length - 1] === '') {
          valueSplit[valueSplit.length - 1] = `<span style="background-color: rgb(204, 224, 245); color: rgb(0, 102, 204);">@${name}</span> <span></span></p>`
        } else {
          let findIndex = 0
          let checkIndex = 0
          valueSplit.forEach((item, index) => {
            if (item.includes(splitText[checkIndex])) {
              if (checkIndex === splitText.length - 1) {
                findIndex = index
              } else {
                checkIndex = checkIndex + 1
              }
            }
          })
          valueSplit[findIndex] = `${valueSplit[findIndex].replace('</p>', ' ')}<span style="background-color: rgb(204, 224, 245); color: rgb(0, 102, 204);">@${name}</span> <span></span>`
        }
      } else {
        if (valueSplit[splitText.length - 1].includes('</p>')) {
          valueSplit[splitText.length - 1] = `${valueSplit[splitText.length - 1].replace('</p>', '')}<span style="background-color: rgb(204, 224, 245); color: rgb(0, 102, 204);">@${name}</span> <span></span>`
        } else {
          valueSplit[splitText.length - 1] = `${valueSplit[splitText.length - 1]}<span style="background-color: rgb(204, 224, 245); color: rgb(0, 102, 204);">@${name}</span> <span></span>`
        }
      }
    } else {
      valueSplit[0] = `${valueSplit[0].replace('</p>', ' ')}<span style="background-color: rgb(204, 224, 245); color: rgb(0, 102, 204);">@${name}</span> <span></span>`
    }

    setOpenMenuChannelUsers(false)
    setValue(valueSplit.join(' '))
  }

  const channelUsersMainRef = useOuterClick((ev: any) => {
    setOpenMenuChannelUsers(false)
  });

  const channelMainRef = useOuterClick((ev: any) => {
    setOpenChannelUsers(false)
  });

  function handleDeleteDocument(id: string) {
    setSelectedDocuments(selectedDocuments.filter(item => item.document_id !== id))
    setReadyToSave(true)
  }

  function handleDeleteCustomDocument(index: number) {
    setSelectedEditAttachments(selectedEditAttachments.filter((item, idx) => idx !== index))
    setReadyToSave(true)
  }

  function getHeight(documents: SelectedDocumentsProps[], attachments: AttachmentsProps[]) {
    let height = 0

    if (documents.length) {
      height = (documents.length * 21) + 142
    } else {
      height = 122
    }

    if (attachments.length) {
      height = height + 70
    }

    return `${height}px`
  }

  function getAttachmentName(url: string) {
    let name = ''

    const splitUrl = url.split('/')

    if (splitUrl.length) {
      name = splitUrl[splitUrl.length - 1]
    }

    return name
  }

  return (
    <div
      className='edit-message'
      style={{ height: getHeight(selectedDocuments, selectedEditAttachments) }}
    >
      {
        seconds > 0 &&
        <div className='message-timer'>
          Time for editing: <span>{seconds}</span>
        </div>
      }
      {
        showEmoji &&
        <EmojyPicker
          is_text={true}
          setShow={() => setShowEmoji(false)}
          hanldeAddReaction={(name) => handleAddEmojiInText(name)}
        />
      }

      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChangeText}
        onChangeSelection={(range) => range && setSelectIndex(range.index)}
        modules={modules}
        formats={formats}
        ref={refComponent}
      />

      <div>
        {
          openClip &&
          <ClipPopup
            setOpenClipMain={setOpenClip}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            selectedAttachments={selectedEditAttachments}
            setSelectedAttachments={setSelectedEditAttachments}
            is_main={true}
            is_edit={true}
          />
        }

        {
          openChannelUsers &&
          <div
            className='channel_users'
            ref={channelMainRef}
            style={{
              top: usersOption.filter(item => item.user_name.toLowerCase().includes(filterUser.toLowerCase())).length > 3 ? '90px' : `-${(usersOption.filter(item => item.user_name.toLowerCase().includes(filterUser.toLowerCase())).length * 40) - 30}px`
            }}
          >
            {
              usersOption.filter(item => item.user_name.toLowerCase().includes(filterUser.toLowerCase())).map(item => (
                <div
                  key={item.channel_user_id}
                  className='user-item'
                  onClick={() => handleAddChannelUsers(item.channel_user_id, item.user_name)}
                >
                  <Icon viewBox="0 0 14 16" style={{ fill: '#8E8E93' }} icon="account-2" />
                  {item.user_name} {channel_user_id === item.channel_user_id && '(you)'}
                </div>
              ))
            }
          </div>
        }

        {
          openMenuChannelUsers &&
          <div
            className='channel_users'
            ref={channelUsersMainRef}
            style={{
              top: usersOption.length > 3 ? '-90px' : `-${(usersOption.filter(item => item.user_name.toLowerCase().includes(filterUser.toLowerCase())).length * 40) - 30}px`
            }}
          >
            {
              usersOption.map(item => (
                <div
                  key={item.channel_user_id}
                  className='user-item'
                  onClick={() => handleAddMenuChannelUsers(item.user_name)}
                >
                  <Icon viewBox="0 0 14 16" style={{ fill: '#8E8E93' }} icon="account-2" />
                  {item.user_name} {channel_user_id === item.channel_user_id && '(you)'}
                </div>
              ))
            }
          </div>
        }
      </div>

      {
        (!!selectedDocuments.length || !!selectedEditAttachments.length) &&
        <div className="documents">
          {
            !!selectedEditAttachments.length &&
            <div className="document-images">
              {
                selectedEditAttachments.map((item, index) => (
                  <div
                    key={index}
                    className="document-image"
                  >
                    <img
                      style={{ objectFit: 'contain' }}
                      alt={item.url ? getAttachmentName(item.url) : 'New Img'}
                      // @ts-ignore
                      src={item.url ? item.url : URL.createObjectURL(item.details)}
                    />

                    <div
                      className="document-image-actions"
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        handleDeleteCustomDocument(index)
                      }}
                    >
                      <Icon
                        icon="x-mark-1"
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          }

          {
            !!selectedDocuments.length &&
            <table className="table">
              <tbody>
                <tr style={{ height: 0 }}></tr>
                {
                  selectedDocuments.map((item, index) => {
                    if (item.document_type === 'ticket') {
                      return (
                        <tr key={item.document_id}>
                          <td>
                            <div
                              className="document-item-popup"
                              onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                handleDeleteDocument(item.document_id)
                              }}
                            >
                              <Icon
                                icon="x-mark-1"
                              />
                            </div>
                          </td>
                          <td>
                            <strong>{item.document_type}</strong>
                          </td>
                          <td
                            onClick={() => {
                              window.open(`${window.location.origin}/${activeAccountId}/tickets/${(item.details as TicketsProps).ticket_id}`, "_blank", 'noopener,noreferrer')
                            }}
                          >
                            <div className='doc-open'>
                              <Icon icon="magnifier-5" />
                            </div>
                          </td>
                          <td style={{ position: 'relative', paddingRight: '25px' }}>
                            {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, (item.details as TicketsProps).date_time_start)}
                            {
                              item.details.status !== 'Closed' &&
                              item.details.status !== 'Canceled' &&
                              <span
                                style={{
                                  position: 'absolute',
                                  right: '5px',
                                  width: '14px',
                                  height: '14px',
                                  background: item.details.status === 'In-progress' ? '#FFB700' : '#FF0000',
                                  borderRadius: '50%',
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  top: '3px'
                                }}
                              >!</span>
                            }
                          </td>
                          <td>{(item.details as TicketsProps).number}</td>
                          <td
                            className={classNames('status',
                              {
                                canceled: item.details.status === 'Canceled',
                                closed: item.details.status === 'Closed',
                                initial: item.details.status !== 'Canceled' && item.details.status !== 'Closed'
                              }
                            )}
                          >
                            {item.details.status}
                          </td>
                          <td>{(item.details as TicketsProps).type}</td>
                          <td>{(item.details as TicketsProps).theme}</td>
                          <td> {(item.details as TicketsProps).author_dispatcher || (item.details as TicketsProps).author_user}</td>
                        </tr>
                      )
                    } else if (item.document_type === 'appointment') {
                      return (
                        <tr key={item.document_id}>
                          <td>
                            <div
                              className="document-item-popup"
                              onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                handleDeleteDocument(item.document_id)
                              }}
                            >
                              <Icon
                                icon="x-mark-1"
                              />
                            </div>
                          </td>
                          <td>
                            <strong>{item.document_type}</strong>
                          </td>
                          <td
                            onClick={() => {
                              window.open(`${window.location.origin}/${activeAccountId}/appointments/${(item.details as AppointmentsProps).appointment_id}`, "_blank", 'noopener,noreferrer')
                            }}
                          >
                            <div className='doc-open'>
                              <Icon icon="magnifier-5" />
                            </div>
                          </td>
                          <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, (item.details as AppointmentsProps).created_at)}</td>
                          <td>{(item.details as AppointmentsProps).name}</td>
                          <td>
                            {(item.details as AppointmentsProps).type === 'Recall' ? (
                              <span className="red">
                                {(item.details as AppointmentsProps).type}
                              </span>
                            ) : (item.details as AppointmentsProps).type === 'Service call' ? (
                              <span className="green">
                                {(item.details as AppointmentsProps).type}
                              </span>
                            ) : (
                              <span className="blue">
                                {(item.details as AppointmentsProps).type}
                              </span>
                            )}
                          </td>
                          <td>
                            {
                              item.details.status === 'Canceled' ?
                                <span className="red">
                                  {item.details.status}
                                </span> :
                                <span>
                                  {item.details.status}
                                </span>
                            }
                          </td>
                          <td>{(item.details as AppointmentsProps).property_type}</td>
                          <td>{(item.details as AppointmentsProps).area}</td>
                          <td>{(item.details as AppointmentsProps).dispatcher_code ? `${(item.details as AppointmentsProps).created_by} (${(item.details as AppointmentsProps).dispatcher_code})` : (item.details as AppointmentsProps).created_by}</td>
                          <td>{getSchuduleTime((item.details as AppointmentsProps).schedule_time_start as string, (item.details as AppointmentsProps).schedule_time_finish as string, (item.details as AppointmentsProps).time_zone)}</td>
                          <td>{(item.details as AppointmentsProps).service_resource_code ? `${(item.details as AppointmentsProps).service_resource}(${(item.details as AppointmentsProps).service_resource_code})` : (item.details as AppointmentsProps).service_resource}</td>
                        </tr>
                      )
                    } else if (item.document_type === 'job') {
                      return (
                        <tr key={item.document_id}>
                          <td>
                            <div
                              className="document-item-popup"
                              onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                handleDeleteDocument(item.document_id)
                              }}
                            >
                              <Icon
                                icon="x-mark-1"
                              />
                            </div>
                          </td>
                          <td>
                            <strong>{item.document_type}</strong>
                          </td>
                          <td
                            onClick={() => {
                              window.open(`${window.location.origin}/${activeAccountId}/jobs/${(item.details as JobsProps).job_id}`, "_blank", 'noopener,noreferrer')
                            }}
                          >
                            <div className='doc-open'>
                              <Icon icon="magnifier-5" />
                            </div>
                          </td>
                          <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, (item.details as JobsProps).created_at)}</td>
                          <td>{(item.details as JobsProps).name}</td>
                          <td>
                            {
                              item.details.status === 'Canceled' ?
                                <span className="red">
                                  {item.details.status}
                                </span> :
                                <span>
                                  {item.details.status}
                                </span>
                            }
                          </td>
                          <td>{(item.details as JobsProps).property_type}</td>
                          <td>{(item.details as JobsProps).area}</td>
                          <td>{(item.details as JobsProps).source}</td>
                          <td>{(item.details as JobsProps).created_by}</td>
                          <td>{(item.details as JobsProps).appointments}</td>
                          <td>{(item.details as JobsProps).total}</td>
                        </tr>
                      )
                    } else if (item.document_type === 'call') {
                      return (
                        <tr key={item.document_id}>
                          <td>
                            <div
                              className="document-item-popup"
                              onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                handleDeleteDocument(item.document_id)
                              }}
                            >
                              <Icon
                                icon="x-mark-1"
                              />
                            </div>
                          </td>
                          <td>
                            <strong>{item.document_type}</strong>
                          </td>
                          <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, (item.details as CallsProps).created_at)}</td>
                          <td>
                            <button
                              className={classNames('_zeroed', '_iconed', {
                                _red: ['no-answer', 'busy', 'canceled'].includes((item.details as CallsProps).status),
                                _green: ['completed', 'ringing', 'in-progress'].includes((item.details as CallsProps).status) && (item.details as CallsProps).direction === 'inbound',
                                _blue: (item.details as CallsProps).direction === 'outbound'
                              })}
                            >
                              <Icon icon="arrow-20" className={classNames({
                                '_rotated-180': (item.details as CallsProps).direction === 'outbound'
                              })} />
                            </button>
                          </td>

                          <td>{(item.details as CallsProps).caller_name}</td>
                          <td>{(item.details as CallsProps).client ? (item.details as CallsProps).client : null}</td>
                          <td>
                            <span>
                              {(item.details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.details as CallsProps).call_to) : formatPhoneNumber((item.details as CallsProps).call_from)}
                            </span>
                          </td>
                          <td>{(item.details as CallsProps).friendly_name}</td>
                          <td>{(item.details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.details as CallsProps).call_from) : formatPhoneNumber((item.details as CallsProps).call_to)}</td>
                          <td>{(item.details as CallsProps).extension}</td>
                          <td>{(item.details as CallsProps).is_appointment}</td>
                          <td className={classNames({
                            'red-text': ['no-answer', 'busy', 'canceled'].includes((item.details as CallsProps).status),
                            'green-text': (item.details as CallsProps).status === 'completed',
                            'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes((item.details as CallsProps).status),
                          })}>{(item.details as CallsProps).status} {(item.details as CallsProps).no_answered_reason ? `(${(item.details as CallsProps).no_answered_reason})` : ''} </td>
                          <td>{(item.details as CallsProps).duration}</td>
                        </tr>
                      )
                    } else {
                      return null
                    }
                  })
                }
              </tbody>
            </table>
          }
        </div>
      }

      <div className='edit-message-btns'>
        <div
          style={{ display: 'flex' }}
        >
          <button
            onClick={() => setShowEmoji(true)}
          >
            <Icon viewBox="0 0 1177 1024" icon="emoji-111" />
          </button>

          <button
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setOpenMenuChannelUsers(true)
            }}
          >
            @
          </button>

          <button
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setOpenClip(true)
            }}
          >
            <Icon style={{ width: '18px' }} viewBox="0 0 1025 1024" icon="clip-29" />
          </button>
        </div>

        <div className='actions-btns' style={{ display: 'flex' }}>
          <button
            className="_bordered _red"
            style={{ marginRight: '10px' }}
            onClick={() => {
              setEditText('')
              setEditMainMessage('')
            }}
          >
            Cancel
          </button>

          <button
            className="_bordered _green"
            style={{ marginRight: '10px' }}
            disabled={seconds === 0 || (value === editText && !readyToSave)}
            onClick={() => updatedMessage(false, value)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
