import { useState } from "react";
import classNames from "classnames"
import { Transition } from 'react-transition-group'

import Icon from "./Icon"

// import "../styles/components/select.sass"

export interface Option {
  span: string,
  value: any
}

interface Props {

  options: Option[],

  selectedOption: string | number | boolean,
  onChange(value: string | number | boolean, span?: any, otherValue?: string): void,

  zeroed?: boolean,
  disabled?: boolean

  error?: boolean
}

function AutoCompliteComponent({
  options,
  selectedOption,
  onChange,
  zeroed,
  disabled,
  error
}: Props) {
  const [search, setSearch] = useState(getSelectedOption().span)

  const [active, setActive] = useState(false)

  // Get selected option function
  function getSelectedOption() {
    return options.find((option) => option.value === selectedOption) || {
      span: '',
      value: ''
    }
  }

  function hanldeChange(value: string) {
    if (value === '') {
      onChange('', '')
    }
    setActive(true)
    setSearch(value)
    onChange(0, value)
  }

  function handleSelect(value: string, span: string) {
    setActive(false);
    if (span.toLowerCase() === 'other') {
      onChange(value, span, search)
    } else {
      onChange(value, span)
    }
    setSearch(span)
  }

  // Render function
  return (
    <div className="SelectComponent">
      <div
        style={{
          border: '1px solid #d0d3da',
          display: 'flex',
          padding: '7px 15px',
          backgroundColor: disabled ? 'inherit' : '#FFF'
        }}
      >
        <input
          style={{ padding: '0' }}
          disabled={disabled}
          value={search}
          onChange={(event) => hanldeChange(event.target.value)}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: disabled ? 'inherit' : '#FFF'
          }}
          onClick={() => !disabled && setActive((value: boolean) => !value)}
          className="auto-complite-select"
        >
          <Icon
            style={{ width: '16px', minWidth: '16px' }}
            className={classNames({ _flipped: active })}
            icon="arrow-65"
          />
        </div>
      </div>

      <Transition in={active} mountOnEnter={true} unmountOnExit={true} timeout={210}>
        {(state) => (
          <div className={classNames("options-list-wrapper", `transition-fade-${state}`)}>

            {options.filter(item => item.span.toLowerCase().includes(search.toLowerCase()) || item.span.toLowerCase() === 'other').map((option, i) => (
              <button
                className={classNames("_zeroed", "option",
                  {
                    _active: option.value === getSelectedOption().value,
                  }
                )}
                key={i}
                onClick={() => handleSelect(option.value, option.span)}
              >
                {option.span || <span>&nbsp;</span>}
              </button>
            ))}
          </div>
        )}
      </Transition>
    </div>
  )
}

export default AutoCompliteComponent
