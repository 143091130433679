import { useEffect, useRef, useState } from 'react'

import { usePrevious } from '../../funcs'

interface WorkerInfoProps {
  service_resource_id: string
  getAsideWidth: () => number
  available: boolean
  service_resource_code: number | null
  service_resource_nickname: string
  service_resource_fullname: string
  service_resource_function: string
}

export default function WorkerInfo({
  service_resource_id,
  getAsideWidth,
  available,
  service_resource_code,
  service_resource_nickname,
  service_resource_fullname,
  service_resource_function,
}: WorkerInfoProps) {
  let nicknameRef = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(0)

  const pervAsideWidth = usePrevious(getAsideWidth())
  const pervWidth = usePrevious(width)

  function getNicknameWidth() {
    if (nicknameRef && nicknameRef.current) {
      let { width } = nicknameRef.current.getBoundingClientRect()
      if (width > getAsideWidth() - 83) {
        setWidth(getAsideWidth() - 83)
      } else {
        if (pervWidth && pervAsideWidth && width !== 0) {
          pervAsideWidth < getAsideWidth() &&
            setWidth(getAsideWidth() - 83)
        }
      }
    }
  }

  useEffect(() => {
    if (pervAsideWidth !== getAsideWidth()) {
      getNicknameWidth();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAsideWidth()]);

  return (
    <div
      className="board__worker-info"
      ref={nicknameRef}
    >
      <p
        id={service_resource_id}
        className="board__worker-nickname"
        style={{
          color: !!available ? "inherit" : "#FF0000",
          maxWidth: width === 0 ? 'none' : width
        }}
        title={service_resource_code ? `${service_resource_nickname}(${service_resource_code})` : service_resource_nickname}
      >
        {service_resource_code ? `${service_resource_nickname}(${service_resource_code})` : service_resource_nickname}
      </p>

      <p
        className="board__worker-name-surname"
        style={{
          maxWidth: width === 0 ? 'none' : width
        }}
        title={service_resource_fullname}
      >
        {service_resource_fullname}
      </p>

      <p
        className="board__worker-post"
        style={{
          maxWidth: width === 0 ? 'none' : width
        }}
        title={service_resource_function}
      >
        {service_resource_function}
      </p>
    </div>
  )
}
