import PaymentList from './PaymentList'

import PaymentCash from './PaymentCash'
import PaymentCheck from './PaymentCheck'
import PaymentOther from './PaymentOther'
import PaymentOtherItem from './PaymentOtherItem'
import PaymentCard from './PaymentCard'

import { NavActive } from '../../store/reducer'

import { DataProps, InvoicesProps, PaymentServicesProps } from './ItemTech'

interface PaymentProps {
  navActive: NavActive
  phoneCall: boolean
  paymentPage: string
  setPaymentPage: (value: string) => void
  invoices: InvoicesProps[]
  setPaymentModal: (value: boolean) => void
  job_name: string
  job_id: string
  permission_search_show: boolean
  permissions_payment_make: boolean
  payment_services: PaymentServicesProps[]
  payment_services_all: PaymentServicesProps[]
  paymentOtherName: string
  setPaymentOtherName: (value: string) => void
  payment_square_make: boolean
  payment_stripe_make: boolean
  handlePayment: (type: string, data: DataProps[], total: number) => void
  handleClose: () => void
  isSaving: boolean
  handleCloseCardModal: () => void
  company_id: string
  contact_id: string
  paid_job: number
  service_resource_id: string
}

export default function Payment({
  navActive,
  phoneCall,
  paymentPage,
  setPaymentPage,
  invoices,
  permission_search_show,
  setPaymentModal,
  job_name,
  permissions_payment_make,
  payment_services,
  paymentOtherName,
  setPaymentOtherName,
  payment_square_make,
  payment_stripe_make,
  handlePayment,
  handleClose,
  isSaving,
  job_id,
  payment_services_all,
  handleCloseCardModal,
  company_id,
  contact_id,
  paid_job,
  service_resource_id,
}: PaymentProps) {

  return (
    <>
      {
        paymentPage === '' &&
        <PaymentList
          navActive={navActive}
          phoneCall={phoneCall}
          paymentPage={paymentPage}
          setPaymentPage={setPaymentPage}
          setPaymentModal={setPaymentModal}
          permissions_payment_make={permissions_payment_make}
        />
      }

      {
        paymentPage === 'credit_card' &&
        <PaymentCard
          navActive={navActive}
          phoneCall={phoneCall}
          setPaymentPage={setPaymentPage}
          invoices={invoices}
          permission_search_show={permission_search_show}
          job_name={job_name}
          payment_square_make={payment_square_make}
          payment_stripe_make={payment_stripe_make}
          job_id={job_id}
          payment_services={payment_services_all}
          handleCloseCardModal={handleCloseCardModal}
          company_id={company_id}
          contact_id={contact_id}
          paid_job={paid_job}
          service_resource_id={service_resource_id}
        />
      }

      {
        (paymentPage === 'cash') &&
        <PaymentCash
          navActive={navActive}
          phoneCall={phoneCall}
          invoices={invoices}
          permission_search_show={permission_search_show}
          job_name={job_name}
          handlePayment={handlePayment}
          handleClose={handleClose}
          isSaving={isSaving}
          contact_id={contact_id}
          job_id={job_id}
          paid_job={paid_job}
        />
      }

      {
        paymentPage === 'other_list' &&
        paymentOtherName === '' &&
        <PaymentOther
          navActive={navActive}
          phoneCall={phoneCall}
          setPaymentPage={setPaymentPage}
          invoices={invoices}
          permission_search_show={permission_search_show}
          job_name={job_name}
          payment_services={payment_services}
          setPaymentOtherName={setPaymentOtherName}
        />
      }

      {
        paymentOtherName !== '' &&
        <PaymentOtherItem
          navActive={navActive}
          phoneCall={phoneCall}
          invoices={invoices}
          permission_search_show={permission_search_show}
          job_name={job_name}
          other_name={paymentOtherName}
          setPaymentOtherName={setPaymentOtherName}
          handlePayment={handlePayment}
          handleClose={handleClose}
          isSaving={isSaving}
          contact_id={contact_id}
          job_id={job_id}
          paid_job={paid_job}
        />
      }

      {
        paymentPage === 'check' &&
        <PaymentCheck
          navActive={navActive}
          phoneCall={phoneCall}
          invoices={invoices}
          permission_search_show={permission_search_show}
          job_name={job_name}
          handlePayment={handlePayment}
          handleClose={handleClose}
          isSaving={isSaving}
          contact_id={contact_id}
          job_id={job_id}
          paid_job={paid_job}
        />
      }
    </>
  )
}
