import { useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [newData, setNewData] = useState({
    name: '',
    is_archive: false
  })

  async function handleSave() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/chats/channels
      const response = await httpClientUpdate.post(`/chats/channels`, {
        account_id: activeAccountId,
        name: newData.name,
        is_archive: newData.is_archive ? 1 : 0
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('chat-channels', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="ClientsPage_Item ClientPage_New entity-edit">

      <div className="wrapper flex-container sb">
        <h1>New Chat Channel</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('chat-channels', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">

        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>
              <input
                type="text"
                value={newData.name}
                onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
              />
            </div>
          </div>

          <div className="__right">
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('chat-channels', {
              companyId: activeAccountId,
            }, { reload: true })}
          >
            Cancel
          </button>

          <button
            disabled={isSaving || newData.name === ''}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
