export default function DotsSvg() {
  return (
    <svg width="54" height="24" viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="53" height="23" rx="11.5" stroke="#6093DE"></rect>
      <circle cx="20" cy="12" r="2.5" fill="#6093DE"></circle>
      <circle cx="27" cy="12" r="2.5" fill="#6093DE"></circle>
      <circle cx="34" cy="12" r="2.5" fill="#6093DE"></circle>
    </svg>
  )
}
