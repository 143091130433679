import { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import Icon from '../../components/Icon'
import TimeHasPassed from '../../components/TimeHasPassed'

import { NavActive } from '../../store/reducer'

import { TimeObjectProps, TimeSlotTasks } from './ItemTech'

interface TimeLogBlockProps {
  navActive: NavActive
  phoneCall: boolean
  data: TimeSlotTasks[]
  isStart: string
  isMyTyme: boolean
  handleCloseTimeLog: () => void
}

export default function TimeLogBlock({
  navActive,
  phoneCall,
  data,
  isStart,
  isMyTyme,
  handleCloseTimeLog,
}: TimeLogBlockProps) {
  const [timeObject, setTimeObject] = useState<TimeObjectProps | null>(null)

  useEffect(() => {
    if (isMyTyme) {
      let hours = 0
      let minutes = 0
      let seconds = 0

      data.forEach(item => {
        if (item.time_slot_type === 'start_my_time') {
          let timeSplit = item.diff.split(':')

          hours = hours + Number(timeSplit[0])
          minutes = minutes + Number(timeSplit[1])
          seconds = seconds + Number(timeSplit[2])

          if (seconds > 59) {
            seconds = seconds - 60
            minutes = minutes + 1
          }

          if (minutes > 59) {
            minutes = minutes - 60
            hours = hours + 1
          }
        }
      })

      setTimeObject({
        start_hours: hours,
        start_minutes: minutes,
        start_seconds: seconds,
      })
    }
    if (!!isStart) {
      let hours = 0
      let minutes = 0
      let seconds = 0

      data.forEach(item => {
        if (item.time_slot_type === 'on_my_way') {
          let timeSplit = item.diff.split(':')

          hours = hours + Number(timeSplit[0])
          minutes = minutes + Number(timeSplit[1])
          seconds = seconds + Number(timeSplit[2])

          if (seconds > 59) {
            seconds = seconds - 60
            minutes = minutes + 1
          }

          if (minutes > 59) {
            minutes = minutes - 60
            hours = hours + 1
          }
        }
      })

      setTimeObject({
        start_hours: hours,
        start_minutes: minutes,
        start_seconds: seconds,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getDiff(time: string) {
    let timeSplit = time.split(':')

    let hours = Number(timeSplit[0])
    let minutes = Number(timeSplit[1])

    return `${hours}h ${minutes}m`
  }

  function getTotalTravelTime() {
    let hours = 0
    let minutes = 0
    let seconds = 0

    data.forEach(item => {
      if (item.time_slot_type === 'on_my_way') {
        let timeSplit = item.diff.split(':')

        hours = hours + Number(timeSplit[0])
        minutes = minutes + Number(timeSplit[1])
        seconds = seconds + Number(timeSplit[2])

        if (seconds > 59) {
          seconds = seconds - 60
          minutes = minutes + 1
        }

        if (minutes > 59) {
          minutes = minutes - 60
          hours = hours + 1
        }
      }
    })

    return `${hours}h ${minutes}m`
  }

  function getTotalJobTime() {
    let hours = 0
    let minutes = 0
    let seconds = 0

    data.forEach(item => {
      if (item.time_slot_type === 'start_my_time') {
        let timeSplit = item.diff.split(':')

        hours = hours + Number(timeSplit[0])
        minutes = minutes + Number(timeSplit[1])
        seconds = seconds + Number(timeSplit[2])

        if (seconds > 59) {
          seconds = seconds - 60
          minutes = minutes + 1
        }

        if (minutes > 59) {
          minutes = minutes - 60
          hours = hours + 1
        }
      }
    })

    return `${hours}h ${minutes}m`
  }

  return (
    <div
      className={classNames('job-tech-customer time-tracking mobile', '__show-on-mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
    >
      <div className='content-title'>
        <div
          className='content-title-row'
          style={{ alignItems: 'center' }}
        >
          <div></div>

          <div className='mobile-text'>
            Time tracking
          </div>

          <div
            onClick={() => {
              setTimeObject(null)
              handleCloseTimeLog()
            }}
          >
            <Icon style={{ width: '12px' }} icon="x-mark-1" />
          </div>
        </div>
      </div>

      <div className='content-customer'>
        <div className='content-customer-time'>
          <div className='content-customer-title'>
            Total time on job
          </div>

          <div className='content-customer-value'>
            {
              isMyTyme && timeObject ?
                <TimeHasPassed
                  start_hours={timeObject.start_hours}
                  start_minutes={timeObject.start_minutes}
                  start_seconds={timeObject.start_seconds}
                /> : getTotalJobTime()
            }
          </div>
        </div>

        <div className='content-customer-time'>
          <div className='content-customer-title'>
            Total travel time
          </div>

          <div className='content-customer-value'>
            {
              isStart && timeObject ?
                <TimeHasPassed
                  start_hours={timeObject.start_hours}
                  start_minutes={timeObject.start_minutes}
                  start_seconds={timeObject.start_seconds}
                /> : getTotalTravelTime()
            }
          </div>
        </div>
      </div>

      <div className='content-customer-items'>
        {
          data.map((item, index) => (
            <div key={index} className='content-customer-item'>
              {
                item.time_slot_type === 'on_my_way' &&
                item.finish &&
                <div className='content-customer-item-content'>
                  <div className='content-customer-item-content-time'>
                    {`${moment(item.finish).format('h:mma DD MMM YYYY')} Finish way to Job`}
                  </div>

                  <div className='content-customer-item-content-value'>
                    {getDiff(item.diff)}
                  </div>
                </div>
              }

              {
                item.time_slot_type === 'start_my_time' &&
                item.finish &&
                <div className='content-customer-item-content'>
                  <div className='content-customer-item-content-time'>
                    {`${moment(item.finish).format('h:mma DD MMM YYYY')} Finish Job`}
                  </div>

                  <div className='content-customer-item-content-value'>
                    {getDiff(item.diff)}
                  </div>
                </div>
              }

              {
                item.time_slot_type === 'start_my_time' &&
                <div className='content-customer-item-content'>
                  <div className='content-customer-item-content-time'>
                    {`${moment(item.start).format('h:mma DD MMM YYYY')} Start work (diagnostic)`}
                  </div>
                </div>
              }

              {
                item.time_slot_type === 'on_my_way' &&
                <div className='content-customer-item-content'>
                  <div className='content-customer-item-content-time'>
                    {`${moment(item.start).format('h:mma DD MMM YYYY')} Start way to Job`}
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}
