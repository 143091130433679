import { memo } from 'react'
import { useRoute } from "react-router5"
import classNames from "classnames"

import ActionMenuBlock from './ActionMenuBlock'
import InfoBlock from './InfoBlock'
import Icon from '../Icon'
import DotsSvg from '../DotsSvg'

import AudioPlayer from './AudioPlayer'

import { useAppSelector } from "../../store/hooks"
import { formatPhoneNumber } from '../../funcs'

import { CallsDataProps, ClientsProps, PermissionsProps } from '../../App'

interface CompletedCallItemProps {
  actionMenuCompetedCall: string[]
  setActionMenuCompetedCall: (value: string[]) => void

  infoBlockCompetedCall: string[]
  setInfoBlockCompetedCall: (value: string[]) => void

  actionsMenus: string[]

  blackList: boolean
  setBlackList: (value: boolean) => void

  handleSubmit: (sid: string, tags: string, comment: string) => void
  callData: CallsDataProps

  handleRemoveCompletedCall: (value: string) => void

  permissions: Partial<PermissionsProps>
}

function CompletedCallItem({
  actionMenuCompetedCall,
  setActionMenuCompetedCall,
  infoBlockCompetedCall,
  setInfoBlockCompetedCall,
  actionsMenus,
  blackList,
  setBlackList,
  handleSubmit,
  callData,
  handleRemoveCompletedCall,
  permissions
}: CompletedCallItemProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  function getClientName(clients: ClientsProps[], current_name: string) {
    let nameString = ''
    if (clients.length || current_name) {
      if (clients.length) {
        if (clients[0].company_name) {
          nameString = `${clients[0].company_name} ${clients[0].first_name ? `(${clients[0].first_name} ${clients[0].last_name})` : ''}`
        } else {
          nameString = `${clients[0].first_name} ${clients[0].last_name}`
        }
      } else {
        nameString = current_name
      }
    } else {
      nameString = 'Unknown'
    }
    return nameString
  }

  function handleInfoBlock(id: string) {
    if (infoBlockCompetedCall.includes(id)) {
      setInfoBlockCompetedCall(infoBlockCompetedCall.filter(item => item !== id))
    } else {
      let updatedInfoBlock = infoBlockCompetedCall.map(item => item)
      updatedInfoBlock.push(id)
      setInfoBlockCompetedCall(updatedInfoBlock)
    }
  }

  function handleCommentBlock(id: string) {
    if (actionMenuCompetedCall.includes(id)) {
      setActionMenuCompetedCall(actionMenuCompetedCall.filter(item => item !== id))
    } else {
      let updatedCommentBlock = actionMenuCompetedCall.map(item => item)
      updatedCommentBlock.push(id)
      setActionMenuCompetedCall(updatedCommentBlock)
    }
  }

  return (
    <div className='section__account_softphone-content_home-item section__account_softphone-content_home-completCall'>
      <div className='section__account_softphone-content_home-item_icon'>
        <Icon
          style={{ transform: 'rotate(135deg)', fill: '#6093DE', strokeWidth: '1.6px', stroke: '#6093DE' }}
          viewBox="0 0 18 17"
          icon="call-1"
        />

        <p>Completed Call</p>
      </div>

      <div
        className='section__account_softphone-content_home-completCall_close'
        style={{ cursor: 'pointer' }}
        onClick={() => handleRemoveCompletedCall(callData.current_call.caller_sid)}
      >
        <div className='section__account_softphone-content_home-completCall_closeIcon'>
          <Icon
            style={{ fill: '#FF0000' }}
            icon="x-mark-1"
          />
        </div>
        <p>Close</p>
      </div>

      <div className='section__account_softphone-content_home-item_data'>
        <div className='section__account_softphone-content_home-item_text'>
          <div
            className='section__account_softphone-content_home-item_client-name'
            style={{ cursor: callData.permissions.client_show && !!callData.clients.length ? 'pointer' : 'inherit' }}
            onClick={() =>
              callData.permissions.client_show &&
              !!callData.clients.length &&
              $router.router.navigate('clients.item', {
                companyId: activeAccountId, clientId: callData.clients[0].client_id
              }, { reload: true })
            }
          >
            {
              !!callData.clients.length &&
              <div className='section__account_softphone-content_calls-contIcon visible'>
                <Icon viewBox="0 0 11 13" style={{ fill: '#8E8E93' }} icon="account-1" />
              </div>
            }

            <p>
              {
                getClientName(callData.clients, callData.current_call.caller_name)
              }
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_client-tel'>
            <p> {formatPhoneNumber(callData.current_call.from_phone)} </p>
          </div>

          <div className='section__account_softphone-content_home-item_client-location'>
            <div className='section__account_softphone-content_home-item_location-icon'>
              <Icon viewBox="0 0 6 8" style={{ fill: '#8E8E93' }} icon="location-1" />
            </div>

            <p>
              {callData.current_call.caller_city}, {callData.current_call.caller_state} {callData.current_call.caller_zip}
            </p>
          </div>
        </div>

        <div className='section__account_softphone-content_home-item_text'>
          <div className='section__account_softphone-content_home-item_operator-name'>
            <p>
              {callData.current_call.friendly_name}
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_operator-tel'>
            <p>
              {formatPhoneNumber(callData.current_call.to_phone)}
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_operator-location'>
            <div className='section__account_softphone-content_home-item_location-icon'>
              <Icon viewBox="0 0 6 8" style={{ fill: '#8E8E93' }} icon="location-1" />
            </div>

            <p>
              {callData.current_call.area}
            </p>
          </div>
        </div>
      </div>

      <div className='section__account_softphone-content_home-item_actionButtons'>
        <div className='section__account_softphone-content_home-item_actionButtons-block'>
          <div
            className={classNames("section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-info", {
              inactive: !callData.calls.length
            })}
            style={{ cursor: !callData.calls.length ? 'inherit' : 'pointer' }}
            onClick={() => callData.calls.length && handleInfoBlock(callData.current_call.caller_sid)}
          >
            <p>i</p>
          </div>

          {
            (permissions.softphone_add_comment || permissions.softphone_make_appointment ||
              permissions.softphone_make_ticket || permissions.softphone_mark_blacklist ||
              permissions.softphone_reschedule_appointment) &&
            <div
              onClick={() => handleCommentBlock(callData.current_call.caller_sid)}
              className='section__account_softphone-content_home-item_actionButtons-button section__account_softphone-content_home-item_actionButtons-actionsComplet'
            >
              <DotsSvg />
            </div>
          }
        </div>

        {
          callData.permissions.call_call_record &&
          callData.current_call.recording_url &&
          <div className='section__account_softphone-content_home-item_actionButtons-block'>
            <AudioPlayer
              audioSrc={callData.current_call.recording_url}
            />
          </div>
        }
      </div>
      {
        actionMenuCompetedCall.includes(callData.current_call.caller_sid) &&
        <ActionMenuBlock
          actionsMenus={actionsMenus}
          blackList={blackList}
          setBlackList={setBlackList}
          handleSubmit={handleSubmit}
          call_sid={callData.current_call.call_sid}
          company_id={callData.current_call.company_id}
          caller_phone={callData.current_call.from_phone}
          lead_type={callData.calls[0]}
          lead_id={callData.current_call.call_sid}
          real_lead_type={callData.calls[0]}
          real_lead_id={callData.current_call.call_sid}
          lead_text={`${callData.calls[0].direction} - From: ${callData.current_call.from_phone} ${getClientName(callData.clients, callData.current_call.caller_name)} - To: ${callData.current_call.to_phone} ${callData.current_call.friendly_name}`}
          source_id={callData.current_call.source_id}
          permissions={permissions}
        />
      }
      {
        infoBlockCompetedCall.includes(callData.current_call.caller_sid) &&
        <InfoBlock
          calls={callData.calls}
          client_name={getClientName(callData.clients, callData.current_call.caller_name)}
          client_show={callData.permissions.client_show}
          client_id={callData.clients.length ? callData.clients[0].client_id : ''}
        />
      }

    </div>
  )
}

export default memo(CompletedCallItem)