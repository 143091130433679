import { useRoute } from "react-router5"

import businessAccountIconImg from "../images/business-account_icon.svg"
import Icon from "./Icon"

import { signOut } from "../store/thunk"
import { useAppSelector, useAppDispatch } from "../store/hooks"
import { setActiveAccountId } from "../store/reducer"
// import { AppDispatch } from "../store/store"

import "../styles/components/account-menu.sass"

interface Props {
  twilio_allow?: boolean
  full?: boolean,
  onActiveNavButtonChange?: (mode: string) => void,
  setActiveNavButton?: (mode: null) => void,
  togglePhoneCall?: () => void
}

function AccountMenu({
  full,
  onActiveNavButtonChange,
  setActiveNavButton,
  togglePhoneCall,
  twilio_allow,
}: Props) {
  const dispatch = useAppDispatch()
  const $router = useRoute()

  const user = useAppSelector((store) => store.user)
  const accounts = useAppSelector((store) => store.accounts)
  const accessToken = useAppSelector((store) => store.accessToken)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  async function logOut() {
    await dispatch(signOut({ accessToken }))
    $router.router.navigate('auth')
    setActiveNavButton && setActiveNavButton(null)
  }

  function handleChangeAccount(id: string) {
    if (activeAccountId === id) {
      $router.router.navigate('dashboard', {
        companyId: activeAccountId,
      }, { reload: true })
    } else {
      dispatch(setActiveAccountId(id))
    }
  }

  return (
    <div className="AccountMenu">

      <div className="account-name">
        {`${user?.first_name} ${user?.last_name}`}
      </div>

      <div className="menu-buttons">

        <button className="_iconed">
          <div className="icon _light">
            <Icon icon="user-21" />
          </div>
          <span>Accounts</span>

          <div className="submenu">

            {accounts.map((account, i) => (
              <button
                className="_iconed"
                key={i}
                onClick={() => handleChangeAccount(account.account_id)}
              >
                {
                  !!account.photo_link ?
                    <img src={account.photo_link} alt="Account Pic" /> :
                    <img src={businessAccountIconImg} alt="Account Default" />
                }
                <span>{account.name}</span>
              </button>
            ))}
          </div>
        </button>

        {full && twilio_allow ? (
          <button
            className="_iconed"
            onClick={() => togglePhoneCall && togglePhoneCall()}
          >
            <div className="icon">
              <Icon icon="phone-1" />
            </div>
            <span>Phone</span>
          </button>
        ) : null}

        {full && false ? (
          <button className="_iconed" onClick={() => onActiveNavButtonChange && onActiveNavButtonChange('helpMenu')}>
            <div className="icon">
              <Icon icon="help-1" />
            </div>
            <span>Support</span>
          </button>
        ) : null}

        {full ? (
          <button className="_iconed" onClick={() => onActiveNavButtonChange && onActiveNavButtonChange('notificationsWidget')}>
            <div className="icon">
              <Icon icon="bell-1" />
            </div>
            <span>Notifications</span>
          </button>
        ) : null}

        {/* <button className="_iconed" onClick={() => signOut()}> */}
        <button className="_iconed" onClick={() => logOut()}>
          <div className="icon">
            <Icon icon="log-out-17" />
          </div>
          <span>Logout</span>
        </button>
      </div>
    </div>
  )
}

export default AccountMenu
