
import React, { useState, useRef, useEffect, memo } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";



const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "630px",
};

interface zipCodesViewProps {
  zip_code: string,
  isHave: boolean,
  lat: number,
  lng: number,
  place_id: string
}

interface addressCodesViewProps {
  address: string,
  isHave: boolean,
  place_id: string,
  lat: number,
  lng: number,
  color: string,
  count: number,
}

interface RenderMapProps {
  selectedZipCode: zipCodesViewProps[]
  addressCodesView: addressCodesViewProps[]
  isLoaded: boolean
  loadError: Error | undefined
}

function ViewMap({
  selectedZipCode,
  addressCodesView,
  isLoaded,
  loadError,
}: RenderMapProps) {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [center, setCenter] = useState({
    lat: 40.730610,
    lng: -73.935242,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [originalZoom, setOriginalZoom] = useState(8);
  const mapRef = useRef<google.maps.Map | null>(null);

  const [updated, setUpdated] = useState(Math.random())

  useEffect(() => {
    if (mapRef.current !== null && isLoaded) {
      initialMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapRef.current, isLoaded, updated])

  useEffect(() => {
    if (mapRef.current !== null && isLoaded) {
      codeAddress()
    }

    if (selectedZipCode.length === 0 && addressCodesView.length < 2) {
      mapRef.current?.setZoom(12)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapRef.current, selectedZipCode, isLoaded, addressCodesView])

  function codeAddress() {
    const bounds = new google.maps.LatLngBounds();

    selectedZipCode.forEach(item => {
      bounds.extend({ lat: item.lat, lng: item.lng })
    })

    addressCodesView.forEach(item => {
      bounds.extend({ lat: item.lat, lng: item.lng })
    })

    mapRef.current?.fitBounds(bounds)
  }

  function initialMap() {
    try {
      //@ts-ignore
      const featureLayer = mapRef.current?.getFeatureLayer('POSTAL_CODE')

      mapRef.current?.setClickableIcons(false)

      const featureStyleOptions: google.maps.FeatureStyleOptions = {
        strokeColor: "#000000",
        strokeOpacity: 1.0,
        strokeWeight: 1.0,
        fillColor: "#000000",
        fillOpacity: 0.4,
      };

      if (featureLayer) {
        //@ts-ignore
        featureLayer.style = (options) => {

          // @ts-ignore
          if (selectedZipCode.map(item => item.place_id).includes(options.feature?.placeId)) {
            return featureStyleOptions
          }
        }
      }
    } catch (error) { }
  }

  let isFirstLoad = false

  function changeZoom() {
    if (!isFirstLoad && mapRef.current?.getZoom()) {
      isFirstLoad = true
      let number = mapRef.current.getZoom() as number - 0.2

      mapRef.current?.setZoom(number)
    }
  }

  if (loadError) return <div>Error loading maps</div>;

  if (!isLoaded)
    return (
      <div className="flex h-full flex-col">
        loading
      </div>
    );

  return (
    <div className="w-full">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={originalZoom}
        center={center}
        onZoomChanged={() => changeZoom()}
        onBoundsChanged={() => setUpdated(Math.random())}
        onLoad={(map) => {
          mapRef.current = map;
          // setOriginalZoom(12);
        }}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapId: 'ec4fc7638d3b957e',
        }}
      >
        {
          addressCodesView.map((item, i) => (
            <Marker
              key={i}
              position={{ lat: item.lat, lng: item.lng }}
              label={{ text: item.count === 1 ? ' ' : `${item.count}`, color: '#FFFFFF' }}
              icon={{
                // path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 9,
                fillColor: item.color,
                fillOpacity: 1,
                strokeColor: item.color,
                strokeWeight: 2,
              }}
            />
          ))
        }
      </GoogleMap>
    </div>
  );
}

export default memo(ViewMap, (prevProps, nextProps) => {
  let check = true

  if (prevProps.selectedZipCode.length === nextProps.selectedZipCode.length) {
    prevProps.selectedZipCode.forEach((prevItem, index) => {
      if (prevItem.place_id !== nextProps.selectedZipCode[index].place_id) {
        check = false
      }
    })
  } else {
    check = false
  }

  if (prevProps.addressCodesView.length === nextProps.addressCodesView.length) {
    prevProps.addressCodesView.forEach((prevItem, index) => {
      if (prevItem.place_id !== nextProps.addressCodesView[index].place_id) {
        check = false
      }
    })
  } else {
    check = false
  }

  return check
})