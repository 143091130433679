import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from '../../components/Icon'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    client: ClientProps
  }
}

interface ClientProps {
  company_name: string
  first_name: string
  last_name: string
}

interface ClientShowProps {
  switchPage: (name: string) => void
  client_id: string
}

export default function ClientShow({ client_id, switchPage }: ClientShowProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState<Partial<ClientProps>>({})

  async function loadClient() {
    try {
      const { data: { data: clientsData, success, error } } = await httpClientUpdate.get('/technician/clients/' + client_id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setData(clientsData.client)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      // setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadClient()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="EstimatePage_Edit entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Client: </h1>
          <div className="item-name">
            {/* {newEstimateData.name} */}
          </div>
        </div>

        <div style={{ display: 'flex' }} >
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
            className="_wa"
            onClick={() => switchPage('main')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>
      </div>

      <div className="fieldset">
        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>First Name:</span>
              <input
                type="text"
                defaultValue={data.first_name}
                disabled={true}
              />
            </div>

            <div className="field">
              <span>Last Name:</span>
              <input
                type="text"
                defaultValue={data.last_name}
                disabled={true}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Company Name:</span>
              <input
                type="text"
                defaultValue={data.company_name}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
