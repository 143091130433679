import { ReactElement, useState } from "react";
import classNames from "classnames"
import { Transition } from "react-transition-group"

import Checkbox from "../Checkbox"
import Icon from "../Icon"

import "../../styles/components/reports/table-field.sass"

type FilterWord = {
  word: string,
  word_name?: string,
  selected: boolean
}

interface Props {
  allFiltersSelected?: boolean,
  onAllFiltersChange?(value: boolean): void

  filterWords?: FilterWord[],
  onFilterChange?(value: FilterWord): void,
  onFilterFire?(): void

  contents: ReactElement,

  sortDirection?: 'up' | 'down',
  onSortDirectionChange?(value: 'up' | 'down'): void,

}

function ReportTableField({
  allFiltersSelected,
  onAllFiltersChange,
  filterWords,
  onFilterChange,
  onFilterFire,
  contents,
  sortDirection,
  onSortDirectionChange
}: Props) {

  const [filterWrapperActive, setFilterWrapperActive] = useState(false)

  return (
    <th className="ReportTableField" onClick={() => { onSortDirectionChange && onSortDirectionChange(sortDirection === 'up' ? 'down' : 'up'); onFilterFire && onFilterFire() }}>
      <div className="flex-container">

        {(true
          && filterWords !== undefined
          && onFilterChange !== undefined
          && allFiltersSelected !== undefined
          && onAllFiltersChange !== undefined
        ) ? (
          <div tabIndex={-1} className="filter-button" onFocus={() => setFilterWrapperActive(true)} onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setFilterWrapperActive(false)} onClick={(e) => e.stopPropagation()}>
            <button className="_zeroed _iconed">
              {allFiltersSelected ? (
                <Icon icon="filter-2" />
              ) : (
                <Icon style={{ fill: '#6093DE' }} icon="filter-8" />
              )}
            </button>

            <Transition in={filterWrapperActive} mountOnEnter={true} unmountOnExit={true} timeout={210}>
              {(state) => (
                <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                  <Checkbox contents="All" value={allFiltersSelected} onChange={(value) => (onAllFiltersChange as any)(value)} />
                  {(filterWords as FilterWord[]).map((filterWord, i) => (
                    <Checkbox
                      key={`${filterWord}/${i}`}
                      contents={filterWord.word_name ? filterWord.word_name : filterWord.word}
                      value={filterWord.selected}
                      onChange={(value) => (onFilterChange as any)({ ...filterWord, selected: value })}
                    />
                  ))}
                  <button className="_bordered _blue _wa" onClick={() => { setFilterWrapperActive(false); onFilterFire && onFilterFire() }}>
                    Filter
                  </button>
                </div>
              )}
            </Transition>
          </div>
        ) : null}

        {contents}

        {(true
          && sortDirection !== undefined
          && onSortDirectionChange !== undefined
        ) ? (
          <button className="_zeroed _iconed sort-button">
            <Icon icon="arrow-20" className={classNames({
              '_rotated-90': sortDirection === 'up',
              '_rotated-270': sortDirection === 'down',
            })} />
          </button>
        ) : null}
      </div>
    </th>
  )
}

export default ReportTableField
