import React, { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Icon from '../../components/Icon'
import Select from "../../components/Select"

import { useAppSelector } from '../../store/hooks'
import { DayOfWeekOptionsForSchedule, arrTimesForSchedule } from '../../constans'
import { httpClientUpdate, nErrorUpdate, sortServiceResourceSchedule } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface ScheduleProps {
  day: string
  from: string
  schedule_id: string
  to: string,
  limit?: number,
  editing?: boolean
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    schedule: ScheduleProps[]
  }
}
interface ServiceResourcesScheduleProps {
  handleBackServiceResources: () => void,
  service_resources: {
    code: number
    nickname: string
    service_resource_id: string
  }[] | []
  permissions?: {
    service_resource_add: boolean
    service_resource_delete: boolean
    service_resource_edit: boolean
    service_resource_report_show: boolean
    service_resource_schedule_add: boolean
    service_resource_schedule_delete: boolean
    service_resource_schedule_edit: boolean
    service_resource_schedule_show: boolean
    service_resource_show: boolean
  },
  name?: string
}

interface ServiceResourcesListProps {
  code: number
  nickname: string
  service_resource_id: string
}

export default function List({
  handleBackServiceResources,
  permissions,
  name,
  service_resources
}: ServiceResourcesScheduleProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [scheduleData, setScheduleData] = useState<ScheduleProps[] | []>([])

  const [newScheduleData, setNewScheduleData] = useState<ScheduleProps[] | []>([])

  const [serviceResourcesList, setServiceResourcesList] = useState<ServiceResourcesListProps[] | []>([])
  const [selectedServiceResources, setSelectedServiceResources] = useState('')

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: scheduleData, success, error } } = (await httpClientUpdate.get('/serviceresources/schedule', {
        params: {
          account_id: activeAccountId,
          service_resource_id: $router.router.getState().params.serviceResourceId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

        let sortScheduleByDay = sortServiceResourceSchedule(scheduleData.schedule)

        setScheduleData(sortScheduleByDay.map(item => {
          return {
            ...item,
            day: capitalize(item.day),
            editing: false,
          }
        }))
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load schedule function
  async function loadSchedule() {
    try {
      const { data: { data: schedule, success, error } } = (await httpClientUpdate.get('/serviceresources/schedule', {
        params: {
          account_id: activeAccountId,
          service_resource_id: selectedServiceResources
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

        let sortScheduleByDay = sortServiceResourceSchedule(schedule.schedule)

        setNewScheduleData(sortScheduleByDay.map(item => {
          return {
            ...item,
            day: capitalize(item.day),
            editing: true,
          }
        }))
        setSelectedServiceResources('')
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load schedule data
  useEffect(() => {
    loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (service_resources.length) {
      setServiceResourcesList(
        service_resources.filter(item => item.service_resource_id !== $router.router.getState().params.serviceResourceId)
      )
    }
  }, [$router.router, service_resources])

  // add new schedule
  async function handleAddNewSchedule(i: number) {
    setIsSaving(true)
    try {
      const { day, from, to, limit } = newScheduleData[i]
      const response = await httpClientUpdate.post(`/serviceresources/schedule`, qs.stringify({
        account_id: activeAccountId,
        service_resource_id: $router.router.getState().params.serviceResourceId,
        day,
        from,
        to,
        limit
      }))
      if (response.data.success) {
        setIsSaving(false)
        let updatedSchedule = newScheduleData.filter((item, index) => i !== index)

        setNewScheduleData(updatedSchedule)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove schedule
  async function removeSchedule(i: number) {
    setIsSaving(true)
    try {
      const { schedule_id } = scheduleData[i]
      const response = await httpClientUpdate.delete(`/serviceresources/schedule/${schedule_id}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // change schedule
  async function handleSaveChangeSchedule(i: number) {
    setIsSaving(true)
    try {
      const { day, from, to, limit, schedule_id } = scheduleData[i]
      const response = await httpClientUpdate.put(`/serviceresources/schedule/${schedule_id}`, qs.stringify({
        account_id: activeAccountId,
        service_resource_id: $router.router.getState().params.serviceResourceId,
        day,
        from,
        to,
        limit
      }))
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }


  function handleChangeScheduleData(value: string, name: string, idx: number) {
    let updateScheduleData = scheduleData.map((item, index) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    setScheduleData(updateScheduleData)
  }

  function handleChangeNewScheduleData(value: string, name: string, idx: number) {
    let updateScheduleData = newScheduleData.map((item, index) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    setNewScheduleData(updateScheduleData)
  }

  function handleAddSchedule() {
    let updateScheduleData = newScheduleData.map(item => item)
    updateScheduleData.push({
      day: '',
      from: '',
      to: '',
      limit: 1,
      schedule_id: '',
      editing: true
    })
    setNewScheduleData(updateScheduleData)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...scheduleData]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setScheduleData(_items)
  }

  // Set editing function
  function setNewEditing(i: number, flag: boolean) {

    let _items = [...newScheduleData]
    _items = _items.map((item) => ({ ...item }))
    _items[i].editing = flag
    setNewScheduleData(_items)
  }

  function removeNewSchedule(index: number) {
    let updateScheduleData = newScheduleData.filter((item, idx) => idx !== index)
    setNewScheduleData(updateScheduleData)
  }

  return (
    <div className="SchedulePageList_List entity-edit">

      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Service Resource Schedule:</h1>
          <div className="item-name">
            {name}
          </div>
        </div>
        {/* Temporarily removed the rights check on the field edit_service_resource */}
        <div style={{ display: 'flex' }}>
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
            className="_wa"
            onClick={() => handleBackServiceResources()}
          >
            Back
          </button>

          {
            permissions?.service_resource_schedule_add &&
            <button className="_wa _green" onClick={() => handleAddSchedule()}>
              Add
            </button>
          }
        </div>
      </div>

      {/* Table */}
      <div className="fieldset">
        {(!!scheduleData.length || !!newScheduleData.length) &&
          < table className="table">
            <thead>
              <tr>
                <th style={{ width: 'calc(90% / 4)' }}>Day</th>
                <th style={{ width: 'calc(90% / 4)' }}>Limit</th>
                <th style={{ width: 'calc(90% / 4)' }}>From</th>
                <th style={{ width: 'calc(90% / 4)' }}>To</th>
                <th style={{ width: '5%' }}></th>
                <th style={{ width: '5%' }}></th>
              </tr>
            </thead>

            {scheduleData.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={DayOfWeekOptionsForSchedule.map((option) => ({
                          span: option.value,
                          value: option.value
                        }))}
                        selectedOption={item.day as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'day', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.limit}
                      min={0}
                      onChange={({ target: { value } }) => handleChangeScheduleData(value, 'limit', i)}
                    />
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={arrTimesForSchedule.map((option) => ({
                          span: option.span,
                          value: option.value
                        }))}
                        selectedOption={item.from as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'from', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={
                          item.from === '' ?
                            arrTimesForSchedule.map((option) => ({
                              span: option.span,
                              value: option.value
                            })) :
                            arrTimesForSchedule.filter((option) => item.from < option.value && ({
                              span: option.span,
                              value: option.value
                            }))
                        }
                        selectedOption={item.to as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'to', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={item.day === '' || item.from === '' || item.to === '' || isSaving}
                      onClick={() => handleSaveChangeSchedule(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions?.service_resource_schedule_delete || isSaving}
                      onClick={() => removeSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.day}</td>
                  <td>{item.limit}</td>
                  <td>
                    {arrTimesForSchedule.filter(time => time.value === item.from).length ?
                      arrTimesForSchedule.filter(time => time.value === item.from)[0].span :
                      item.from
                    }
                  </td>
                  <td>
                    {arrTimesForSchedule.filter(time => time.value === item.to).length ?
                      arrTimesForSchedule.filter(time => time.value === item.to)[0].span :
                      item.from
                    }
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissions?.service_resource_schedule_edit}
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions?.service_resource_schedule_delete || isSaving}
                      onClick={() => removeSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}

            {newScheduleData.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={DayOfWeekOptionsForSchedule.map((option) => ({
                          span: option.value,
                          value: option.value
                        }))}
                        selectedOption={item.day as string || ''}
                        onChange={(value: string) => handleChangeNewScheduleData(value, 'day', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.limit}
                      min={0}
                      onChange={({ target: { value } }) => handleChangeNewScheduleData(value, 'limit', i)}
                    />
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={arrTimesForSchedule.map((option) => ({
                          span: option.span,
                          value: option.value
                        }))}
                        selectedOption={item.from as string || ''}
                        onChange={(value: string) => handleChangeNewScheduleData(value, 'from', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={
                          item.from === '' ?
                            arrTimesForSchedule.map((option) => ({
                              span: option.span,
                              value: option.value
                            })) :
                            arrTimesForSchedule.filter((option) => item.from < option.value && ({
                              span: option.span,
                              value: option.value
                            }))
                        }
                        selectedOption={item.to as string || ''}
                        onChange={(value: string) => handleChangeNewScheduleData(value, 'to', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={item.day === '' || item.from === '' || item.to === '' || isSaving}
                      onClick={() => handleAddNewSchedule(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      disabled={!permissions?.service_resource_schedule_delete}
                      className="_zeroed _iconed _red"
                      onClick={() => removeNewSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.day}</td>
                  <td>{item.limit}</td>
                  <td>
                    {arrTimesForSchedule.filter(time => time.value === item.from).length ?
                      arrTimesForSchedule.filter(time => time.value === item.from)[0].span :
                      item.from
                    }
                  </td>
                  <td>
                    {arrTimesForSchedule.filter(time => time.value === item.to).length ?
                      arrTimesForSchedule.filter(time => time.value === item.to)[0].span :
                      item.from
                    }
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissions?.service_resource_schedule_edit}
                      onClick={() => setNewEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions?.service_resource_schedule_delete}
                      onClick={() => removeNewSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>}

        <div
          className="flex-container wrap"
          style={{ justifyContent: 'flex-end', marginTop: '20px' }}
        >
          <div
            className="legend"
            style={{ fontSize: '16px', opacity: '.6', fontWeight: 'normal' }}
          >
            Copy schedule from another service resources
          </div>

          <div className="legend-action-wrapper">
            <div
              className="input-wrapper"
            >
              <Select
                options={serviceResourcesList.map((action) => ({
                  span: action.code ? `${action.nickname} (${action.code})` : action.nickname,
                  value: action.service_resource_id
                }))}
                selectedOption={selectedServiceResources}
                onChange={(value) => setSelectedServiceResources(value as string)}
              />
              <span
                style={{
                  cursor: selectedServiceResources ? 'pointer' : 'inherit',
                  opacity: selectedServiceResources ? '1' : '.6'
                }}
                onClick={() => selectedServiceResources && loadSchedule()}
              >
                <Icon
                  viewBox="0 0 16 16"
                  style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                  icon="download-1"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
