import { memo } from 'react'

import UnitEdit from '../BlankPagesForTech/UnitEdit'
import UnitAdd from '../BlankPagesForTech/UnitAdd'
import EstimateAdd from '../BlankPagesForTech/EstimateAdd'
import EstimateEdit from '../BlankPagesForTech/EstimateEdit'
import InvoiceAdd from '../BlankPagesForTech/InvoiceAdd'
import InvoiceEdit from '../BlankPagesForTech/InvoiceEdit'
import AppointmentAdd from '../BlankPagesForTech/AppointmentAdd'
import AppointmentEdit from '../BlankPagesForTech/AppointmentEdit'
import PartsAdd from '../BlankPagesForTech/PartsAdd'
import PartsEdit from '../BlankPagesForTech/PartsEdit'
import CustomerAddress from '../BlankPagesForTech/CustomerAddress'

import { EditProps } from './ItemTech'
import ClientShow from '../BlankPagesForTech/ClientShow'

interface BlankPageProps {
  page: string
  switchPage: (name: string) => void
  id: string
  edit?: EditProps
  jobId?: string
  client_id?: string | null
  address: string
  jobAddress?: string
}

function BlankPage({ page, switchPage, id, edit, jobId, client_id, address, jobAddress }: BlankPageProps) {

  return (
    <div className='blank-page'>
      {
        page === 'unit_edit' && jobId && client_id &&
        <UnitEdit switchPage={switchPage} id={id} jobId={jobId} client_id={client_id} />
      }
      {
        page === 'unit_add' && jobId && client_id &&
        <UnitAdd
          switchPage={switchPage}
          edit={{
            unit_type: edit && edit.unit_type ? edit.unit_type : [],
            appliances: edit && edit.appliances ? edit.appliances : [],
            appliance_brands: edit && edit.appliance_brands ? edit.appliance_brands : [],
          }}
          jobId={jobId}
          client_id={client_id}
        />
      }
      {
        page === 'estimate_add' && jobId && client_id &&
        <EstimateAdd
          switchPage={switchPage}
          jobId={jobId}
          client_id={client_id}
          edit={edit && edit.estimate_status ? edit.estimate_status : []}
        />
      }
      {
        page === 'estimate_edit' && id && <EstimateEdit switchPage={switchPage} id={id} />
      }
      {
        page === 'invoice_add' && jobId && client_id && jobAddress &&
        <InvoiceAdd
          switchPage={switchPage}
          jobId={jobId}
          client_id={client_id}
          edit={edit && edit.invoice_status ? edit.invoice_status : []}
          jobAddress={jobAddress}
        />
      }
      {
        page === 'invoice_edit' && id && <InvoiceEdit switchPage={switchPage} id={id} />
      }
      {
        page === 'appointment_add' && jobId && client_id &&
        <AppointmentAdd
          switchPage={switchPage}
          edit={edit && edit.appointment_status ? edit.appointment_status : []}
          jobId={jobId}
          client_id={client_id}
        />
      }
      {
        page === 'appointment_edit' && edit && id &&
        <AppointmentEdit switchPage={switchPage} edit={edit.appointment_status} id={id} />
      }
      {
        page === 'parts_add' && jobId &&
        <PartsAdd
          switchPage={switchPage}
          jobId={jobId}
          edit={{
            part_status: edit && edit.part_status ? edit.part_status : [],
            warehouses: edit && edit.warehouses ? edit.warehouses : []
          }}
        />
      }
      {
        page === 'parts_edit' && id &&
        <PartsEdit
          switchPage={switchPage}
          id={id}
        />
      }
      {
        page === 'google_map' && <CustomerAddress switchPage={switchPage} address={address} />
      }
      {
        page === 'client' && client_id && <ClientShow switchPage={switchPage} client_id={client_id} />
      }
    </div>
  )
}

export default memo(BlankPage)
