import { memo } from 'react'

import Select from '../../components/Select'
import Icon from '../../components/Icon'

import { SourcesProps } from './Models'

interface CallInfoProps {
  selectedFromAction: string
  setSelectedFromAction: (value: string) => void
  fromActionOptions: string[]

  selectedLeadSource: string
  setSelectedLeadSource: (value: string) => void
  leadSourceOptions: string[]
  selectedSource: string
  setSelectedSource: (value: string) => void

  sources: SourcesProps[] | []
}

function CallInfo({
  selectedFromAction,
  setSelectedFromAction,
  fromActionOptions,
  selectedLeadSource,
  setSelectedLeadSource,
  leadSourceOptions,
  selectedSource,
  setSelectedSource,
  sources
}: CallInfoProps) {

  return (
    <div className='call-info booking-callInfo'>
      {/* <div className='call-info_row'>
        <p>From Action:</p>

        <Select options={fromActionOptions.map((action) => ({
          span: action,
          value: action
        }))} selectedOption={selectedFromAction} onChange={(value: string) => setSelectedFromAction(value)} />

        <div onClick={() => setSelectedFromAction('')} className='call-fromAction_clear'>
          <Icon style={{ fill: '#8E8E93', width: '90%' }} icon="x-mark-1" />
        </div>
      </div> */}

      {/* <div className='call-info_row'>
        <p>Lead Source:</p>

        <Select options={leadSourceOptions.map((source) => ({
          span: source,
          value: source
        }))} selectedOption={selectedLeadSource} onChange={(value: string) => setSelectedLeadSource(value)} />

        <div onClick={() => setSelectedLeadSource('')} className='call-fromAction_clear'>
          <Icon style={{ fill: '#8E8E93', width: '90%' }} icon="x-mark-1" />
        </div>
      </div> */}

      <div className='call-info_row'>
        <p style={{ fontWeight: 'bold' }} >Source:</p>

        <Select options={sources.map((source) => ({
          span: source.name,
          value: source.source_id
        }))} selectedOption={selectedSource} onChange={(value: string) => setSelectedSource(value)} />

        <div onClick={() => setSelectedSource('')} className='call-fromAction_clear'>
          <Icon style={{ fill: '#8E8E93', width: '90%' }} icon="x-mark-1" />
        </div>
      </div>
    </div>
  )
}

export default memo(CallInfo)
