import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment";
import { useCookies } from "react-cookie";
import classNames from "classnames";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import Icon from "../../components/Icon";

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'available'

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: StaffReport
}

interface StaffReport {
  interface: {
    filter_words: {
      available: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    staff_add: boolean
    staff_show: boolean
  },

  edit: {
    users: {
      user_id: string
      user_name: string
    }[]
  }

  staff: Staff[]
}

interface Staff {
  available: boolean
  nickname: string
  staff_id: string
  user_name: string
}

function StaffPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<StaffReport | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
      max_date: DateTime.now().setZone(timeZone).endOf('day').toJSDate(),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.staff?.filter_field ? {
        available: [] as string[],
        ...cookiesSetting.staff.filter_field,
      } : {
        available: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.staff?.sort_field ? cookiesSetting.staff.sort_field : 'nickname',
        direction: cookiesSetting && cookiesSetting?.staff?.sort_type ? cookiesSetting.staff.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Name',
        value: 'nickname'
      }, {
        span: 'User',
        value: 'user_name'
      }, {
        span: 'Active',
        value: 'available'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
      max_date: DateTime.now().setZone(timeZone).endOf('day').toJSDate(),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.staff?.filter_field ? {
        status: [] as string[],
        type: [] as string[],
        service: [] as string[],
        ...cookiesSetting.staff.filter_field,
      } : {
        status: [] as string[],
        type: [] as string[],
        service: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.staff?.sort_field ? cookiesSetting.staff.sort_field : 'nickname',
        direction: cookiesSetting && cookiesSetting?.staff?.sort_type ? cookiesSetting.staff.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load PayoutDispatcher function
  async function loadData() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    if (Object.keys(activeFilters).length === 0) {
      activeFilters['filters[]'] = 'null'
    }

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.staff?.limit_rows ? cookiesSetting?.staff?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.staff?.limit_rows ? cookiesSetting?.staff?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }

        setCookie('settings', { ...cookiesSetting, staff: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.staff?.limit_rows ? cookiesSetting?.staff.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/staff/report?account_id=88888&limit_rows=1&page=1
      const { data: { data: report, success, error } } = (await httpClientUpdate.get('/staff/report', {
        params: {
          ...filterParams,
          ...activeFilters,
        },
      })) as { data: HttpReport }
      if (success) {
        if (report.permissions.staff_show) {
          setReportData({
            ...report,
            interface: {
              ...report.interface,
              min_date: moment(report.interface.min_date).startOf('day').toDate(),
              max_date: moment(report.interface.max_date).endOf('day').toDate(),
            }
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, staff: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load PayoutDispatcher on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (
    <>
      {
        reportData &&
        <div className="JobsPage_List">

          { /* Page header */}
          <div className="page-header">
            <h1>Staff</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.staff?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, staff: { ...cookiesSetting.staff, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData?.interface?.rows_all ? reportData.interface.rows_all : 0,
              start: reportData?.interface?.rows_start ? reportData.interface.rows_start : 0,
              end: reportData?.interface?.rows_end ? reportData.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData?.interface?.max_pages ? reportData.interface.max_pages : 0}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.staff_add ?
                (
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('staff.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      }, { reload: true })}>
                      <Icon icon="plus-thin" />
                      <span>Add Staff</span>
                      <Icon icon="construction-3" />
                    </button>
                  </div>
                ) : <></>
            }
          />

          <div className="contents">

            {/* Wide desktop table */}
            <table className='table'>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Name</span>)}

                    sortDirection={localInterface.sort.field === 'nickname' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'nickname', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>User</span>)}

                    sortDirection={localInterface.sort.field === 'user_name' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'user_name', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Active</span>)}

                    allFiltersSelected={isAllFiltersSelected("available")}
                    onAllFiltersChange={(value) => switchFilter("available", "All", value)}

                    filterWords={reportData.interface.filter_words.available.map((filterWord) => ({
                      word: filterWord,
                      word_name: filterWord ? 'On' : 'Off',
                      selected: isFilterSelected("available", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("available", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'available' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'available', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>
              {reportData.staff.map((staff, i) => (
                <tr
                  key={i}
                  style={{ cursor: staff.staff_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    // reportData.permissions.payout_dispatcher_show &&
                    staff.staff_id &&
                    $router.router.navigate('staff.item', {
                      companyId: activeAccountId,
                      staffId: staff.staff_id,
                      localInterface: localInterface,
                    }, { reload: true })}

                  onContextMenu={(event) =>
                    // reportData.permissions.payout_dispatcher_show &&
                    staff.staff_id &&
                    handleChangeMenu(event, staff.staff_id, 'staff', i)}
                >
                  <td style={{ position: 'relative', paddingRight: '25px' }}>
                    {staff.nickname}
                  </td>
                  <td> {staff.user_name} </td>
                  <td
                    className={classNames({
                      'red-text': !staff.available,
                      'green-text': !!staff.available,
                    })}
                  >
                    {staff.available ? 'On' : 'Off'}
                    {
                      // reportData.permissions.payout_dispatcher_show &&
                      showContext === `staff_${staff.staff_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('staff.item', {
                              companyId: activeAccountId,
                              staffId: staff.staff_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/staff/${staff.staff_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.staff?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, staff: { ...cookiesSetting.staff, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData?.interface?.rows_all ? reportData.interface.rows_all : 0,
              start: reportData?.interface?.rows_start ? reportData.interface.rows_start : 0,
              end: reportData?.interface?.rows_end ? reportData.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData?.interface?.max_pages ? reportData.interface.max_pages : 0}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}

export default StaffPage_List
