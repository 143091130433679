import { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'

import Icon from '../../components/Icon'

interface CameraProps {
  setTakePic: (value: boolean) => void
}

export default function Camera({ setTakePic }: CameraProps) {
  const videoRef = useRef(null)
  const photoRef = useRef(null)

  const [hasPhoto, setHasPhoto] = useState(false)
  const [mode, setMode] = useState('user')

  const [errorString, setErrorString] = useState('')

  useEffect(() => {
    if (videoRef.current) {
      //@ts-ignore
      videoRef.current.setAttribute('autoplay', '');
      //@ts-ignore
      videoRef.current.setAttribute('muted', '');
      //@ts-ignore
      videoRef.current.setAttribute('playsinline', '');
      getVideo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, mode])

  function getVideo() {
    try {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: mode } })
        .then(stream => {
          let video = videoRef.current

          if (stream && video) {
            //@ts-ignore
            video.srcObject = stream
            //@ts-ignore
            window.localStream = stream;
            //@ts-ignore
            video.play()
          }
        })
    } catch (error) {
      //@ts-ignore
      setErrorString(error.name + ": " + error.message)
    }
  }

  function stopVideo() {
    //@ts-ignore
    localStream.getTracks().forEach((track) => {
      track.stop();
    });
    //@ts-ignore
    localStream.getVideoTracks()[0].stop();
  }

  function takePhoto() {
    const width = 200
    const height = 200

    let video = videoRef.current
    let photo = photoRef.current

    //@ts-ignore
    photo.width = width
    //@ts-ignore
    photo.height = height

    //@ts-ignore
    let ctx = photo.getContext('2d')
    ctx.drawImage(video, 0, 0, width, height)
    setHasPhoto(true)
  }

  function closePhoto() {
    let photo = photoRef.current
    //@ts-ignore
    let ctx = photo.getContext('2d')

    //@ts-ignore
    ctx.clearRect(0, 0, photo.width, photo.height)

    setHasPhoto(false)
  }

  function handleFlip() {
    if (mode === 'user') {
      setMode('environment')
    } else {
      setMode('user')
    }
  }

  // function getBase64Image() {
  //   const width = 200
  //   const height = 200

  //   const video = videoRef.current
  //   const photo = photoRef.current

  //   //@ts-ignore
  //   photo.width = width
  //   //@ts-ignore
  //   photo.height = height

  //   //@ts-ignore
  //   const ctx = photo.getContext('2d')
  //   ctx.drawImage(video, 0, 0, width, height)
  //   // const dataURL = photo.toDataURL("image/png");

  //   //@ts-ignore
  //   photo.toBlob(
  //     (blob: Blob | MediaSource) => {
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       let file = new File([blob as Blob], "fileName.jpg", { type: "image/jpeg" })
  //     },
  //     'image/png',
  //     1
  //   );
  //   //@ts-ignore
  //   return photo.toDataURL("image/jpeg", 0.95)
  // }

  function savePhoto() {
    stopVideo()
    closePhoto()
    setTakePic(false)
  }

  return (
    <div className='camera-box'>
      {
        errorString ?
          <div>
            {errorString}
          </div> :
          <div className={classNames('camera', { _hasPhoto: hasPhoto })}>
            <div className='video'>
              <video
                height={300}
                ref={videoRef}
                autoPlay
                playsInline
                muted></video>
            </div>
            <div className='camera-btns'>
              <button className="_bordered" onClick={takePhoto}>
                Pic
              </button>

              <button className="_bordered" onClick={() => handleFlip()}>
                <Icon
                  style={{ width: '20px', minWidth: '20px' }}
                  viewBox='0 0 1024 1024'
                  icon="flip-camera"
                />
              </button>
            </div>
          </div>
      }

      <div className={classNames('result', { hasPhoto: hasPhoto })}>
        <canvas ref={photoRef}></canvas>

        <div className='camera-btns'>
          <button
            className="_bordered _green"
            disabled={!hasPhoto}
            onClick={savePhoto}
          >
            Save
          </button>

          <button
            className="_bordered _red"
            disabled={!hasPhoto}
            onClick={closePhoto}
          >
            Reshoot
          </button>

          <button
            className="_bordered _red"
            onClick={() => {
              stopVideo()
              setTakePic(false)
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </ div>
  )
}
