import { useEffect, useState } from "react";
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import Select from "../../components/Select"

import { dateToInfoBlock, formatPhoneNumber, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import { EditProps } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: TamplateData
}

interface TamplateData {
  permissions: {
    notify_resource_delete: boolean
    notify_resource_edit: boolean
  },

  edit: EditProps,

  notify_resource: NotifyRousourcesProps,
}

interface NotifyRousourcesProps {
  created_at: string
  created_by: string
  item_id: string
  notify_type: string
  type: string
  updated_at: string
  updated_by: string
}

interface MailboxesProps {
  email: string
  mailbox_id: string
}

interface PhoneNumbersProps {
  friendly_name: string
  phone: string
  phonenumber_id: string
}

function NotifyResourcesPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [mailboxes, setMailboxes] = useState<MailboxesProps[] | []>([])
  const [phone_numbers, setPhone_numbers] = useState<PhoneNumbersProps[] | []>([])

  const [reportData, setReportData] = useState<TamplateData | null>(null)

  const [newResourcesData, setNewResourcesData] = useState<Partial<NotifyRousourcesProps>>({
    type: '',
    notify_type: '',
    item_id: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    if (reportData) {

      setNewResourcesData({
        ...reportData.notify_resource,
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/notify-resources/88888601wgntio547d
      const response = await httpClientUpdate.delete(`/notify-resources/${$router.router.getState().params.notify_resources_id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('notify-resources', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/notify-templates/88888601wgntio547d
      const response = await httpClientUpdate.put(`/notify-resources/${$router.router.getState().params.notify_resources_id}`,
        {
          account_id: activeAccountId,
          type: newResourcesData.type,
          notify_type: newResourcesData.notify_type,
          item_id: newResourcesData.item_id,
        })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/notify-resources/88888600c8d90shjao?account_id=88888
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/notify-resources/' + $router.router.getState().params.notify_resources_id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(loadData)
        setNewResourcesData(loadData.notify_resource)

        setMailboxes(loadData.edit.mailboxes)
        setPhone_numbers(loadData.edit.phone_numbers)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newResourcesData
  ])

  // Render function
  return (<>
    {reportData ? (
      <div className="NotifyResourcesPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Notify Resource</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'notify-resources',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                reportData?.permissions.notify_resource_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Type:</span>

                <Select
                  disabled={!editing}
                  options={['phone', 'email'].map((action) => ({
                    span: action,
                    value: action
                  }))}
                  selectedOption={newResourcesData.type ? newResourcesData.type : ''}
                  onChange={(value) => setNewResourcesData({ ...newResourcesData, type: value as string, item_id: '' })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Notify Type:</span>

                <Select
                  disabled={!editing}
                  options={['payment'].map((action) => ({
                    span: action,
                    value: action
                  }))}
                  selectedOption={newResourcesData.notify_type ? newResourcesData.notify_type : ''}
                  onChange={(value) => setNewResourcesData({ ...newResourcesData, notify_type: value as string })}
                />
              </div>
            </div>
          </div>

          <div className="fields">
            <div style={{ width: '100%' }} className="field">
              <span>Resource:</span>
              {
                newResourcesData.type === 'email' ?
                  <Select
                    disabled={!editing}
                    options={mailboxes.map((action) => ({
                      span: action.email,
                      value: action.mailbox_id
                    }))}
                    selectedOption={newResourcesData.item_id ? newResourcesData.item_id : ''}
                    onChange={(value) => setNewResourcesData({ ...newResourcesData, item_id: value as string })}
                  /> :
                  <Select
                    disabled={!editing || newResourcesData.type === ''}
                    options={phone_numbers.map((action) => ({
                      span: action.friendly_name ? `${formatPhoneNumber(action.phone)} (${action.friendly_name})` : formatPhoneNumber(action.phone),
                      value: action.phonenumber_id
                    }))}
                    selectedOption={newResourcesData.item_id ? newResourcesData.item_id : ''}
                    onChange={(value) => setNewResourcesData({ ...newResourcesData, item_id: value as string })}
                  />
              }
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.notify_resource.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={reportData?.notify_resource.created_by} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.notify_resource.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={reportData?.notify_resource.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.notify_resource_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Resource</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving || newResourcesData.item_id === '' || newResourcesData.notify_type === '' || newResourcesData.type === ''}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {reportData.permissions.notify_resource_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Resource
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the notify resource it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default NotifyResourcesPage_Item
