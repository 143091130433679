import { ReactChild, useEffect, useState } from "react";
import classNames from "classnames"

import Icon from "./Icon"

import "../styles/components/radio.sass"

interface Props {

  contents: ReactChild,
  value?: boolean,

  onChange?(value: boolean): void
}

function Radio({
  contents,
  value,
  onChange
}: Props) {

  const [active, setActive] = useState<boolean>(!!value)

  // Set local value from props
  useEffect(() => {
    setActive(!!value)
  }, [value])

  // Render function
  return (
    <div className={classNames("Radio", { _active: active })} onClick={() => { onChange && onChange(!active) }}>
      <Icon icon={active ? 'check-mark-5' : 'circle-2'} />
      <div className="contents">
        {contents}
      </div>
    </div>
  )
}

export default Radio
