import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import classNames from "classnames"
import moment, { Moment } from "moment";
import Datetime from 'react-datetime'

import Icon from "../../../components/Icon"
import Checkbox from "../../../components/Checkbox"
import SelectComponent from "../../../components/Select";

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

import { CallCentersProps } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: TemporaryData
}

interface TemporaryData {

  permissions: {
    call_center_temporary_closed_delete: boolean
    call_center_temporary_closed_show: boolean
    call_center_temporary_closed_edit: boolean
  },

  temporary_closed: TemporaryProps

  edit: {
    call_centers: CallCentersProps[]
  }
}

interface TemporaryProps {
  call_center_id: string
  call_center_name: string
  created_at: string
  created_by: string
  from: Date | Moment | string
  to: Date | Moment | string
  updated_at: string
  updated_by: string
  temporary_closed_id: string
}

function CallCenter_CallerGroupsPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [report, setReport] = useState<TemporaryData | null>(null)
  const [newData, setNewData] = useState<TemporaryProps | null>(null)

  const [edit, setEdit] = useState<CallCentersProps[]>([])

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/temporary-closed/888880194tvh13mbzx?account_id=88888
      const response = await httpClientUpdate.delete(`/callcenter/temporary-closed/${$router.router.getState().params.temporaryClosedId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.temporaryClosed', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const start = moment.isMoment(newData?.from) ? moment(newData?.from).format('MM/DD/YYYY hh:mma') : newData?.from
      const end = moment.isMoment(newData?.to) ? moment(newData?.to).format('MM/DD/YYYY hh:mma') : newData?.to

      // https://2022back4.artemiudintsev.com/api/callcenter/temporary-closed/888880194tvh13mbzx
      const response = await httpClientUpdate.put(`/callcenter/temporary-closed/${$router.router.getState().params.temporaryClosedId}`, {
        account_id: activeAccountId,
        from: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, start as string),
        to: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, end as string),
        call_center_id: newData?.call_center_id
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/temporary-closed/888880194tvh13mbzx?account_id=88888
      const { data: { data: callerGroupData, success, error } } = (await httpClientUpdate.get('/callcenter/temporary-closed/' + $router.router.getState().params.temporaryClosedId, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        let from = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callerGroupData.temporary_closed.from as string)
        let to = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callerGroupData.temporary_closed.to as string)

        setReport(callerGroupData)
        setNewData({
          ...callerGroupData.temporary_closed,
          from,
          to
        })

        setEdit(callerGroupData.edit.call_centers)

      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // // Load callerGroup data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  // Render function
  return (<>
    {report && newData ? (
      <div className="CallCenter_CallGroupsPage_Item entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>Temporary Closed</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.temporaryClosed',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                report.permissions.call_center_temporary_closed_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>From:</span>

                <Datetime
                  dateFormat="MM/DD/YYYY"
                  timeFormat="hh:mma"
                  inputProps={{ disabled: !editing }}
                  value={newData.from as Date}
                  onChange={(value) => setNewData({ ...newData, from: newData.from })}
                />
              </div>

              <div className="field">
                <span>Call Center:</span>

                <SelectComponent
                  disabled={!editing}
                  options={edit.map((item) => ({
                    span: item.name,
                    value: item.call_center_id,
                  }))}
                  selectedOption={newData.call_center_id}
                  onChange={(value: string) => setNewData({ ...newData, call_center_id: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>To:</span>

                <Datetime
                  dateFormat="MM/DD/YYYY"
                  timeFormat="hh:mma"
                  inputProps={{ disabled: !editing }}
                  value={newData.to as Date}
                  onChange={(value) => setNewData({ ...newData, to: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, report.temporary_closed.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={report.temporary_closed.created_by}
                  disabled={true}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, report.temporary_closed.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={report.temporary_closed.updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                report.permissions.call_center_temporary_closed_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Temporary Closed</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => $router.router.navigate('call_center.temporaryClosed', {
                  companyId: activeAccountId
                }, { reload: true })}
              >
                Cancel
              </button>

              <button
                className="_bordered _green"
                disabled={!readyToSave || isSaving}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item deleting popup */}
        {report.permissions.call_center_temporary_closed_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Temporary Closed
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the temporary closed it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _red"
                  disabled={!readyToDelete || isSaving}
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default CallCenter_CallerGroupsPage_Item
