import { memo } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'

import Icon from '../Icon'

import { CallsProps } from '../../App'
import { useAppSelector } from "../../store/hooks"
import { dateToInfoBlock } from '../../funcs'

interface InfoBlockProps {
  calls: CallsProps[]
  client_name: string
  client_show: boolean
  client_id: string
}

function InfoBlockDial({ calls, client_name, client_show, client_id }: InfoBlockProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  return (
    <div className='section__account_softphone-content_home-item_infoblock'>
      {/* Item Info block Appointment */}
      {
        client_show &&
        client_name !== 'Unknown' &&
        client_id &&
        <div
          className='section__account_softphone-content_home-item_infoblock-item section__account_softphone-content_home-item_infoblock-appointment'
          style={{ cursor: 'pointer' }}
          onClick={() =>
            $router.router.navigate('clients.item', {
              companyId: activeAccountId, clientId: client_id
            }, { reload: true })
          }
        >
          <div className='section__account_softphone-content_home-item_infoblock-item_icon'>
            <Icon viewBox="0 0 11 13" style={{ fill: '#8E8E93' }} icon="account-1" />
          </div>

          <div className='section__account_softphone-content_home-item_infoblock-item_text'>
            {client_name}
          </div>
        </div>
      }

      {
        calls.map((item, index) => {
          // Item Info block Incoming 
          if (item.direction === 'inbound') {
            return (
              <div
                key={index}
                className='section__account_softphone-content_home-item_infoblock-item section__account_softphone-content_home-item_infoblock-incoming'>
                <div
                  className={classNames('section__account_softphone-content_home-item_infoblock-item_icon', {
                    _red: ['no-answer', 'busy'].includes(item.status),
                    _green: ['completed'].includes(item.status),
                    _blue: !['no-answer', 'busy', 'completed'].includes(item.status)
                  })}
                >
                  <Icon icon="arrow-20" />
                </div>

                <div className='section__account_softphone-content_home-item_infoblock-item_text'>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)}
                  <b>{`${item.dispatcher_code ? `- ${item.dispatcher} (${item.dispatcher_code})` : ''}`}</b>
                </div>
                <div className='section__account_softphone-content_home-item_infoblock-item_text'>
                  {item.friendly_name}
                </div>
              </div>
            )
          } else if (item.direction === 'outbound') {
            return (
              // Item Info block Outgoing 
              <div
                key={index}
                className='section__account_softphone-content_home-item_infoblock-item section__account_softphone-content_home-item_infoblock-outgoing' >
                <div
                  className={classNames('section__account_softphone-content_home-item_infoblock-item_icon', {
                    _red: ['no-answer', 'busy'].includes(item.status),
                    _green: ['completed'].includes(item.status),
                    _blue: !['no-answer', 'busy', 'completed'].includes(item.status)
                  })}
                >
                  <Icon icon="arrow-20" className='_rotated-180' />
                </div>

                <div className='section__account_softphone-content_home-item_infoblock-item_text'>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} <b>{` ${item.dispatcher_code ? `- ${item.dispatcher} (${item.dispatcher_code})` : ''}`}</b>
                </div>
                <div className='section__account_softphone-content_home-item_infoblock-item_text'>
                  {item.friendly_name}
                </div>
              </div>
            )
          } else {
            return null
          }
        })
      }
    </div >
  )
}

export default memo(InfoBlockDial)