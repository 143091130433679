import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../../components/Icon"
import Select from "../../../components/Select"

import { CallCenter_CallGroup } from "../../../models/CallCenter"
import { CallFlowsReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import { httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallFlowsReport
}
interface CallCenter_CallGroupProps {
  call_group_id: string;
  name: string;
  dispatchers: {
    code: number, nickname: string
  }[];
  call_flows: string[];
}

const emptyCallGroup: Partial<CallCenter_CallGroupProps> = {
  call_group_id: '',
  name: '',
  dispatchers: [{ code: 0, nickname: '' }],
  call_flows: ['']
}

function CallCenter_CallFlowsPage_New() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [callGroupsData, setCallGroupsData] = useState<CallCenter_CallGroup[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [callGroups, setCallGroups] = useState<CallCenter_CallGroupProps[] | null>(null)
  const [availableCallGroups, setAvailableCallGroups] = useState<Partial<CallCenter_CallGroupProps>[]>([])
  const [selectedCallGroup, setSelectedCallGroup] = useState<Partial<CallCenter_CallGroupProps>>(emptyCallGroup)
  const [newCallFlowData, setNewCallFlowData] = useState({
    name: '',
    phrase_start: '',
    phrase_office_closed: '',
    phrase_office_temporary_closed: '',
    phrase_phone_not_available: '',
    phrase_dispatcher_not_available: ''
  })

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.post(`/callcenter/callflows`, qs.stringify({
        account_id: activeAccountId,
        name: newCallFlowData.name,
        phrase_start: newCallFlowData.phrase_start,
        phrase_office_closed: newCallFlowData.phrase_office_closed,
        phrase_office_temporary_closed: newCallFlowData.phrase_office_temporary_closed,
        phrase_phone_not_available: newCallFlowData.phrase_phone_not_available,
        phrase_dispatcher_not_available: newCallFlowData.phrase_dispatcher_not_available,
        call_groups: JSON.stringify(callGroupsData.map(item => item.call_group_id))
      }))
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.callFlows', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: callFlowsData, success, error } } = (await httpClientUpdate.post('callcenter/callflows/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (callFlowsData.permissions.call_flow_add) {
          setCallGroups(callFlowsData.edit.call_groups)

          setAvailableCallGroups([emptyCallGroup].concat(callFlowsData.edit.call_groups))

          setSelectedCallGroup(emptyCallGroup)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load callFlow data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add callGroup function
  function addCallGroup() {

    if (!callGroupsData || !newCallFlowData) return

    const newCallGroupsList = [
      ...callGroupsData,
      selectedCallGroup
    ]

    setCallGroupsData(newCallGroupsList as any)

    let _availableCallGroups = [...availableCallGroups]

    _availableCallGroups = _availableCallGroups.filter(($callGroup) => {

      return newCallGroupsList.findIndex((callGroup) => {
        return callGroup.name === $callGroup.name
      }) === -1
    })

    setAvailableCallGroups([
      ..._availableCallGroups
    ])

    setSelectedCallGroup(emptyCallGroup)

    setReadyToSave(true)
  }

  // Remove callGroup function
  function removeCallGroup(i: number) {

    let _callFlowCallGroups = [...callGroupsData]

    let _callGroup = _callFlowCallGroups[i]

    _callFlowCallGroups.splice(i, 1)

    setCallGroupsData(_callFlowCallGroups)

    setAvailableCallGroups([
      ...availableCallGroups,
      _callGroup
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newCallFlowData
  ])

  // Render function
  return (<>
    {true ? (
      <div className="CallCenter_CallFlowsPage_New entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>New Call Flow</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('call_center.callFlows', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewCallFlowData({ ...newCallFlowData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="flex-container sb wrap">
            <div className="legend">Call Groups</div>

            <div className="legend-action-wrapper">
              <label>Call Group:</label>
              <div className="input-wrapper">
                <Select
                  options={availableCallGroups.map((callGroup) => ({
                    span: callGroup.name as string,
                    value: callGroup.name as string
                  }))}
                  selectedOption={selectedCallGroup.name as string}
                  onChange={(value) => setSelectedCallGroup(availableCallGroups.find((_callGroup) => _callGroup.name === value) as CallCenter_CallGroup)} />
                <button disabled={!selectedCallGroup.name} className="_green" onClick={() => addCallGroup()}>
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>Dispatchers</th>
              <th>Call Flow</th>
              <th></th>
            </tr>
            {(callGroupsData as CallCenter_CallGroup[]).map((callGroup, i) => (
              <tr key={i}>
                <td>{callGroup.name}</td>
                <td>{callGroup.dispatchers.map((item, index) => (
                  index === 0 ?
                    item.code ? `${item.nickname} (${item.code})` : item.nickname :
                    item.code ? `, ${item.nickname} (${item.code})` : `, ${item.nickname}`
                ))}</td>
                <td>{typeof callGroup.call_flows === 'string' ? callGroup.call_flows : callGroup.call_flows.join(', ')}</td>
                <td>
                  <button className="_zeroed _iconed _red" onClick={(e) => { e.stopPropagation(); removeCallGroup(i) }}>
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {callGroupsData.map((callGroup, i: number) => (
                <div className="item" key={i}>

                  <div className="__top">

                    <div className="__left">
                      <div><b>{callGroup.name}</b></div>
                      <div>
                        {callGroup.dispatchers.map((item, index) => (
                          index === 0 ?
                            item.code ? `${item.nickname} (${item.code})` : item.nickname :
                            item.code ? `, ${item.nickname} (${item.code})` : `, ${item.nickname}`
                        ))}
                      </div>
                    </div>

                    <div className="__right">

                    </div>
                  </div>

                  <div className="__bottom">

                    <div className="__left">

                    </div>

                    <div className="__right small">
                      <div>
                        <b>{callGroup.call_flows}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Call Phrases</div>

          <div className="fields">

            <div className="__left">
              <div className="field _ait">
                <span>Start Phrase:</span>
                <textarea
                  className={classNames({
                    _error: errorFields.includes('phrase_start')
                  })}
                  defaultValue={newCallFlowData.phrase_start}
                  onChange={({ target: { value } }) => {
                    setNewCallFlowData({ ...newCallFlowData, phrase_start: value })
                    errorFields.includes('phrase_start') && setErrorFields(errorFields.filter(item => item !== 'phrase_start'))
                  }}
                ></textarea>
              </div>

              <div className="field _ait">
                <span>Office Closed:</span>
                <textarea
                  className={classNames({
                    _error: errorFields.includes('phrase_office_closed')
                  })}
                  defaultValue={newCallFlowData.phrase_office_closed}
                  onChange={({ target: { value } }) => {
                    setNewCallFlowData({ ...newCallFlowData, phrase_office_closed: value })
                    errorFields.includes('phrase_office_closed') && setErrorFields(errorFields.filter(item => item !== 'phrase_office_closed'))
                  }}></textarea>
              </div>

              <div className="field _ait">
                <span>Office Temporary Closed:</span>
                <textarea
                  className={classNames({
                    _error: errorFields.includes('phrase_office_temporary_closed')
                  })}
                  defaultValue={newCallFlowData.phrase_office_temporary_closed}
                  onChange={({ target: { value } }) => {
                    setNewCallFlowData({ ...newCallFlowData, phrase_office_temporary_closed: value })
                    errorFields.includes('phrase_office_temporary_closed') && setErrorFields(errorFields.filter(item => item !== 'phrase_office_temporary_closed'))
                  }}></textarea>
              </div>
            </div>

            <div className="__right">
              <div className="field _ait">
                <span>Phone Number Not Available:</span>
                <textarea
                  className={classNames({
                    _error: errorFields.includes('phrase_phone_not_available')
                  })}
                  defaultValue={newCallFlowData.phrase_phone_not_available}
                  onChange={({ target: { value } }) => {
                    setNewCallFlowData({ ...newCallFlowData, phrase_phone_not_available: value })
                    errorFields.includes('phrase_phone_not_available') && setErrorFields(errorFields.filter(item => item !== 'phrase_phone_not_available'))
                  }}></textarea>
              </div>

              <div className="field _ait">
                <span>Dispatchers Not Available:</span>
                <textarea
                  className={classNames({
                    _error: errorFields.includes('phrase_dispatcher_not_available')
                  })}
                  defaultValue={newCallFlowData.phrase_dispatcher_not_available}
                  onChange={({ target: { value } }) => {
                    setNewCallFlowData({ ...newCallFlowData, phrase_dispatcher_not_available: value })
                    errorFields.includes('phrase_dispatcher_not_available') && setErrorFields(errorFields.filter(item => item !== 'phrase_dispatcher_not_available'))
                  }}></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('call_center.callFlows', {
              companyId: activeAccountId,
            }, {
              reload: true
            })}>
              Cancel
            </button>
            <button
              className="_bordered _green"
              disabled={!readyToSave || !!errorFields.length || isSaving}
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default CallCenter_CallFlowsPage_New
