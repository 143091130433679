import { memo, useState } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Icon from '../Icon'

import { useAppSelector } from '../../store/hooks'
import { httpClientUpdate } from '../../funcs'

import { CallsProps, PermissionsProps } from '../../App'

interface ActionMenuBlockProps {
  actionsMenus: string[]

  blackList: boolean
  setBlackList: (value: boolean) => void

  handleSubmit: (sid: string, tags: string, comment: string) => void

  call_sid: string
  company_id: string
  caller_phone: string
  lead_type: CallsProps
  lead_id: string
  real_lead_type: CallsProps
  real_lead_id: string
  lead_text: string
  source_id: string

  permissions: Partial<PermissionsProps>
}

function ActionMenuBlock({
  blackList,
  setBlackList,
  actionsMenus,
  handleSubmit,
  call_sid,
  company_id,
  caller_phone,
  lead_type,
  lead_id,
  real_lead_type,
  real_lead_id,
  lead_text,
  source_id,
  permissions
}: ActionMenuBlockProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [addComment, setAddComment] = useState(false)
  const [formAddComment, setFormAddComment] = useState({
    tags: '',
    text: ''
  })

  function handleSaveComment(event: any) {
    event.preventDefault()
    handleSubmit(call_sid, formAddComment.tags, formAddComment.text)
    setFormAddComment({
      tags: '',
      text: ''
    })
  }

  // Toggle blackList
  async function toggleBlackList() {
    const response = await httpClientUpdate.put(`/callcenter/blacklist`, qs.stringify({
      account_id: activeAccountId,
      call_sid,
    }))
    if (response.data.success) {
      response.data.data.message === 'blacklist on' ? setBlackList(true) : setBlackList(false)
    }
  }

  return (
    <div className='section__account_softphone-content_home-item_actions-menu'>
      <div className='section__account_softphone-content_home-item_actions-list'>
        {
          actionsMenus.map(item => {
            if (item === 'Mark for Blacklist' && permissions.softphone_mark_blacklist) {
              return <div
                onClick={() => toggleBlackList()}
                key={item}
                className='section__account_softphone-content_home-item_actions-item section__account_softphone-content_home-item_actions-blacklist'
              >
                <p>{item}</p>
                {
                  blackList &&
                  <Icon style={{ fill: '#6093DE' }} icon="check-mark-1" />
                }
              </div>
            } else if (item === 'Add a Comment' && permissions.softphone_add_comment) {
              return <div
                key={item}
                className='section__account_softphone-content_home-item_actions-item'
              >
                <p onClick={() => setAddComment(!addComment)}>{item}</p>
                {
                  addComment &&
                  <div className='section__account_softphone-content_home-item_actions-comment_inputBlock'>
                    <form
                      // onSubmit={(event) => handleSubmit(event)}
                      className='section__account_softphone-content_home-item_actions-comment_form'
                      action=""
                    >
                      <div className='section__account_softphone-content_home-item_actions-comment_tags'>
                        <label className='section__account_softphone-content_home-item_actions-comment_label' htmlFor="">
                          tags:
                        </label>

                        <input
                          name='tags'
                          className='section__account_softphone-content_home-item_actions-comment_input'
                          type="text"
                          value={formAddComment.tags}
                          onChange={(event) =>
                            setFormAddComment({ ...formAddComment, tags: event.target.value })
                          }
                        />
                      </div>

                      <div className='section__account_softphone-content_home-item_actions-comment_text'>
                        <div className='section__account_softphone-content_home-item_actions-comment_textarea'>
                          <textarea
                            name="comment-text"
                            value={formAddComment.text}
                            onChange={(event) =>
                              setFormAddComment({ ...formAddComment, text: event.target.value })
                            }
                          ></textarea>
                        </div>
                        {
                          (formAddComment.tags !== '' || formAddComment.text !== '') &&
                          <input
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => handleSaveComment(event)}
                            value="Save"
                            className='section__account_softphone-content_home-item_actions-comment_submit'
                            type="submit"
                          />
                        }
                      </div>
                    </form>
                  </div>
                }
              </div>
            } else if (item === 'Make a Ticket' && permissions.softphone_make_ticket) {
              return (
                <div
                  key={item}
                  className='section__account_softphone-content_home-item_actions-item'
                  // onClick={() => handleMakeTicket()}
                  onClick={() => $router.router.navigate('customer_service', {
                    companyId: activeAccountId,
                    caller_phone: caller_phone
                  })}
                >
                  <p>{item}</p>
                </div>
              )
            } else if (item === 'Reschedule an Appointment' && permissions.softphone_reschedule_appointment) {
              return (
                <div
                  key={item}
                  className='section__account_softphone-content_home-item_actions-item'
                  onClick={() => $router.router.navigate('appointment_reschedule', {
                    companyId: activeAccountId,
                    caller_phone: caller_phone
                  })}
                >
                  <p>{item}</p>
                </div>
              )
            } else if (item === 'Make an Appointment' && permissions.softphone_make_appointment) {
              return (
                <div
                  key={item}
                  className='section__account_softphone-content_home-item_actions-item'
                  onClick={() => $router.router.navigate('booking', {
                    companyId: activeAccountId,
                    company_id: company_id,
                    caller_phone: caller_phone,
                    lead_type: lead_type,
                    lead_id: lead_id,
                    real_lead_type: real_lead_type,
                    real_lead_id: real_lead_id,
                    lead_text: lead_text,
                    source_id: source_id
                  })}
                >
                  <p>{item}</p>
                </div>
              )
            } else {
              return null
            }
          })
        }
      </div>
    </div>
  )
}

export default memo(ActionMenuBlock)