interface IncomingCallSvgProps {
  color: string,
  width?: string,
  height?: string,
}

export default function IncomingCallSvg({ color, width, height }: IncomingCallSvgProps) {
  return (
    <svg style={{ width, height }} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">

      <path
        stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
        d="M1.46387 4.5H11.4639M11.4639 4.5L7.8275 1.77273M11.4639 4.5L7.8275 7.22727"
      ></path>
    </svg>
  )
}
