import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon'
import Switcher from '../../components/Switcher'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

export default function NewPage() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [newData, setNewData] = useState({
    type: '',
    state: '',
    city: '',
    active: false,
    title: '',
    description: '',
    header_h1: '',
    header_1: '',
    text_1: '',
    header_2_1: '',
    header_2_2: '',
    text_2: '',
    service_city: '',
    service_areas: '',
  })

  const [readyToSave, setReadyToSave] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites/pages
      const response = await httpClientUpdate.post('/websites/pages', {
        account_id: activeAccountId,
        website_id: $router.router.getState().params.websiteId,
        type: newData.type,
        state: newData.state,
        city: newData.city,
        active: newData.active ? 1 : 0,
        title: newData.title,
        description: newData.description,
        header_h1: newData.header_h1,
        header_1: newData.header_1,
        text_1: newData.text_1,
        header_2_1: newData.header_2_1,
        header_2_2: newData.header_2_2,
        text_2: newData.text_2,
        service_city: newData.service_city,
        service_areas: newData.service_areas,
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('websites.pages', {
          companyId: activeAccountId,
          websiteId: $router.router.getState().params.websiteId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function checkEmptyField() {
    let is_empty = false

    Object.keys(newData).forEach(item => {
      if (newData[item] === '') {
        is_empty = true
      }
    })

    return is_empty
  }

  return (
    <div className="AccountsPage_New entity-edit">
      <div className="wrapper flex-container sb">
        <h1>New Page</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('websites.pages', {
            companyId: activeAccountId,
            websiteId: $router.router.getState().params.websiteId,
          }, {
            reload: true
          })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>State:</span>

              <input
                type="text"
                defaultValue={newData.state}
                onChange={({ target: { value } }) => setNewData({ ...newData, state: value })}
              />
            </div>

            <div className="field">
              <span>City:</span>

              <input
                type="text"
                defaultValue={newData.city}
                onChange={({ target: { value } }) => setNewData({ ...newData, city: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Type:</span>

              <input
                type="text"
                defaultValue={newData.type}
                onChange={({ target: { value } }) => setNewData({ ...newData, type: value })}
              />
            </div>

            <div className="field">
              <span>Active:</span>

              <Switcher
                checked={newData.active}
                onChange={(value) => setNewData({ ...newData, active: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Service</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>City:</span>

              <input
                type="text"
                defaultValue={newData.service_city}
                onChange={({ target: { value } }) => setNewData({ ...newData, service_city: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Areas:</span>

              <input
                type="text"
                defaultValue={newData.service_areas}
                onChange={({ target: { value } }) => setNewData({ ...newData, service_areas: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Info</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Title:</span>

              <input
                type="text"
                defaultValue={newData.title}
                onChange={({ target: { value } }) => setNewData({ ...newData, title: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Description:</span>

              <input
                type="text"
                defaultValue={newData.description}
                onChange={({ target: { value } }) => setNewData({ ...newData, description: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Content</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Header 1:</span>

              <input
                type="text"
                defaultValue={newData.header_1}
                onChange={({ target: { value } }) => setNewData({ ...newData, header_1: value })}
              />
            </div>

            <div className="field">
              <span>Header h1:</span>

              <input
                type="text"
                defaultValue={newData.header_h1}
                onChange={({ target: { value } }) => setNewData({ ...newData, header_h1: value })}
              />
            </div>

            <div className="field">
              <span>Text 1:</span>

              <input
                type="text"
                defaultValue={newData.text_1}
                onChange={({ target: { value } }) => setNewData({ ...newData, text_1: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Header 2.1:</span>

              <input
                type="text"
                defaultValue={newData.header_2_1}
                onChange={({ target: { value } }) => setNewData({ ...newData, header_2_1: value })}
              />
            </div>

            <div className="field">
              <span>Header 2.2:</span>

              <input
                type="text"
                defaultValue={newData.header_2_2}
                onChange={({ target: { value } }) => setNewData({ ...newData, header_2_2: value })}
              />
            </div>

            <div className="field">
              <span>Text 2:</span>

              <input
                type="text"
                defaultValue={newData.text_2}
                onChange={({ target: { value } }) => setNewData({ ...newData, text_2: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('websites.pages', {
              companyId: activeAccountId,
              websiteId: $router.router.getState().params.websiteId,
            }, {
              reload: true
            })}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave || isSaving || checkEmptyField()}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
