import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import ReactInputMask from "react-input-mask";
import classNames from "classnames"

import Icon from "../../../components/Icon"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"

import { dateToInfoBlock, formatPhoneNumber, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallerGroupData
}

interface CallerGroupData {

  permissions: {
    call_flow_show: boolean
    call_group_add: boolean
    call_group_delete: boolean
    call_group_edit: boolean
    call_group_report_show: boolean
    call_group_show: boolean
    dispatcher_show: boolean
  },

  caller_group: {
    call_flow: string
    call_flow_id: string
    caller_group_id: string
    created_at: string
    created_by: string
    name: string
    updated_at: string
    updated_by: string
  }

  phone_numbers: PhoneNumbersProps[]

  users: UsersProps[]
  callflow: string

  edit: {
    users: UsersProps[]
    callflow: CallFlowProps[]
  }
}

interface PhoneNumbersProps {
  name: string
  phone_number: string
}

interface CallFlowProps {
  call_flow_id: string
  name: string
}

interface UsersProps {
  account_id: string
  available: string
  email: string
  first_name: string
  function: string
  is_hide: boolean
  last_name: string
  phone: string
  photo_link: string
  ticket_notice: boolean
  time_zone_id: boolean
  user_id: string
}

const emptyUser: UsersProps = {
  account_id: '',
  available: '',
  email: '',
  first_name: '',
  function: '',
  is_hide: false,
  last_name: '',
  phone: '',
  photo_link: '',
  ticket_notice: false,
  time_zone_id: false,
  user_id: '',
}

function CallCenter_CallerGroupsPage_Item() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  // const [showContext, setShowContext] = useState('')
  // const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])
  const [readyToDelete, setReadyToDelete] = useState(false)
  const [newItemForm, setNewItemForm] = useState({
    name: '',
    phone: ''
  })

  const [callerGroupData, setCallerGroupData] = useState<CallerGroupData | null>(null)
  const [newCallerGroupData, setNewCallerGroupData] = useState({
    name: '',
    call_flow_id: '',
    phone_numbers: [] as PhoneNumbersProps[],
    users: [] as UsersProps[],
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users, setUsers] = useState<UsersProps[] | null>(null)
  const [availableUsers, setAvailableUsers] = useState<UsersProps[]>([])
  const [selectedUser, setSelectedUser] = useState<UsersProps>(emptyUser)

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/caller-groups/88888025mgkqzicrhf?account_id=88888
      const response = await httpClientUpdate.delete(`/callcenter/caller-groups/${$router.router.getState().params.callerId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.caller_groups', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/caller-groups/88888025mgkqzicrhf
      const response = await httpClientUpdate.put(`/callcenter/caller-groups/${$router.router.getState().params.callerId}`, {
        account_id: activeAccountId,
        name: newCallerGroupData.name,
        call_flow_id: newCallerGroupData.call_flow_id ? newCallerGroupData.call_flow_id : null,
        users: newCallerGroupData.users.map(item => item.user_id),
        phone_numbers: {
          ...newCallerGroupData.phone_numbers.map(item => {
            return {
              name: item.name,
              phone: formatPhoneNumberToServerString(item.phone_number)
            }
          })
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/caller-groups/888880253p401ilaef?account_id=88888
      const { data: { data: callerGroupData, success, error } } = (await httpClientUpdate.get('/callcenter/caller-groups/' + $router.router.getState().params.callerId, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setCallerGroupData(callerGroupData)

        let _availableUsers = []

        _availableUsers = callerGroupData.edit.users.filter((item) => {
          return callerGroupData.users.findIndex((user) => {
            return user.user_id === item.user_id
          }) === -1
        })

        setUsers(callerGroupData.edit.users)
        setAvailableUsers([emptyUser].concat(_availableUsers))
        setSelectedUser(emptyUser)

        setNewCallerGroupData({
          name: callerGroupData.caller_group.name,

          phone_numbers: callerGroupData.phone_numbers,
          users: callerGroupData.users,
          call_flow_id: callerGroupData.caller_group.call_flow_id
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // // Load callerGroup data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newCallerGroupData
  ])

  // Add user function
  function addUser() {

    if (!newCallerGroupData.users || !newCallerGroupData) return

    const newUsersList = [
      ...newCallerGroupData.users,
      selectedUser
    ]

    setNewCallerGroupData({
      ...newCallerGroupData,
      users: newUsersList
    })

    let _availableUser = [...availableUsers]

    _availableUser = _availableUser.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user.user_id === $user.user_id
      }) === -1
    })

    setAvailableUsers([
      ..._availableUser
    ])

    setSelectedUser(emptyUser)

    setReadyToSave(true)
  }

  // Remove dispatcher function
  function removeUser(i: number) {

    let _callerGroupUsers = [...newCallerGroupData.users]

    let _user = _callerGroupUsers[i]

    _callerGroupUsers.splice(i, 1)

    setNewCallerGroupData({
      ...newCallerGroupData,
      users: _callerGroupUsers
    })

    setAvailableUsers([
      ...availableUsers,
      _user
    ])

    setReadyToSave(true)
  }

  function removePhone(i: number) {
    let updatedPhones = newCallerGroupData.phone_numbers.map(item => item)

    setNewCallerGroupData({
      ...newCallerGroupData,
      phone_numbers: updatedPhones.filter((item, index) => index !== i)
    })
  }

  // function handleChangeMenu(event: any, id: string, name: string, index: number) {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   if (event.nativeEvent.button === 2) {
  //     let leftScreen = event.pageX
  //     let isNavOpen = navActive.is ? 280 : 0
  //     setScreenX(leftScreen - isNavOpen - 50)
  //     setShowContext(`${name}_${id}_${index}`)
  //   }
  // }

  // const contextMenuRef = useOuterClick((ev: any) => {
  //   setShowContext('')
  //   setScreenX(0)
  // });

  function addPhoneNumber() {
    let updatedPhones = newCallerGroupData.phone_numbers.map(item => item)

    updatedPhones.push({
      name: newItemForm.name,
      phone_number: newItemForm.phone
    })

    setNewCallerGroupData({
      ...newCallerGroupData,
      phone_numbers: updatedPhones
    })

    setNewItemForm({
      name: '',
      phone: ''
    })
  }


  // Render function
  return (<>
    {callerGroupData ? (
      <div className="CallCenter_CallGroupsPage_Item entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>Caller Group</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.caller_groups',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
              // callGroupData?.permissions.call_group_edit ? (
              <button className="_wa _green" onClick={() => setEditing(true)}>
                Edit
              </button>
              // ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  value={newCallerGroupData.name}
                  disabled={!editing}
                  onChange={({ target: { value } }) => {
                    setNewCallerGroupData({ ...newCallerGroupData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Call flow:</span>
                <Select
                  error={(errorFields as string[]).includes('call_flow_id')}
                  disabled={!editing}
                  options={[{ name: '', call_flow_id: '' }].concat(callerGroupData.edit.callflow).map((call) => ({
                    span: call.name,
                    value: call.call_flow_id
                  }))}
                  selectedOption={newCallerGroupData.call_flow_id}
                  onChange={(value) => {
                    setNewCallerGroupData({ ...newCallerGroupData, call_flow_id: value as string })
                    errorFields.includes('call_flow_id') && setErrorFields(errorFields.filter(item => item !== 'call_flow_id'))
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Phone numbers</div>

            {
              editing &&
              <div className="legend-action-wrapper">
                <div className="input-wrapper">
                  <div className="label">Name:</div>
                  <input
                    type="text"
                    value={newItemForm.name}
                    onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, name: value })}
                  />
                  <div className="label">Phone number:</div>
                  <ReactInputMask
                    type="text"
                    disabled={!editing}
                    mask="+1 (999) 999-9999"
                    value={newItemForm.phone}
                    onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, phone: value })}
                  />
                  <button
                    className="_green"
                    disabled={newItemForm.name === '' || newItemForm.phone === ''}
                    style={{ width: 'fit-content' }}
                    onClick={() => addPhoneNumber()}
                  >
                    Add
                  </button>
                </div>
              </div>
            }
          </div>

          <table className='table'>
            <tr>
              <th>Name</th>
              <th>Phone number</th>
              <th></th>
            </tr>
            {newCallerGroupData.phone_numbers.map((phone, i) => (
              <tr
                key={i}
              >
                <td>{phone.name}</td>
                <td>{formatPhoneNumber(phone.phone_number)}</td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    disabled={!editing}
                    onClick={() => removePhone(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Users</div>

            <div className="legend-action-wrapper">
              <div className="input-wrapper">
                <Select
                  disabled={!editing}
                  options={availableUsers.map((user) => ({
                    span: `${user.first_name} ${user.last_name}`,
                    value: user.user_id
                  }))}
                  selectedOption={selectedUser.user_id}
                  onChange={(value) => setSelectedUser(availableUsers.find((user) => user.user_id === value) as UsersProps)}
                />
                <button
                  disabled={!selectedUser.user_id}
                  className="_green"
                  onClick={() => addUser()}
                >
                  Add
                </button>
              </div>
            </div>

          </div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>Function</th>
              <th>Active</th>
              <th></th>
            </tr>
            {newCallerGroupData.users.map((user, i) => (
              <tr
                key={i}
              >
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.function}</td>
                <td className={classNames({
                  'red-text': user.available === 'Inactive',
                  'green-text': user.available === 'Active',
                  'blue-text': user.available === 'Waiting',
                })}>
                  {user.available}
                </td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    disabled={!editing}
                    onClick={() => removeUser(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {newCallerGroupData.users.map((user, i: number) => (
                <div
                  className="item"
                  key={i}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>Name<b>{user.first_name} {user.last_name}</b></div>
                    </div>

                    <div className="__right">
                      <div>Function<b>{user.function}</b></div>
                      <div className={classNames({
                        'red-text': user.available === 'Inactive',
                        'green-text': user.available === 'Active',
                        'blue-text': user.available === 'Waiting',
                      })}>
                        {user.available}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__right">
                      <button
                        className="_zeroed _iconed _red"
                        disabled={!editing}
                        onClick={() => removeUser(i)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callerGroupData.caller_group.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={callerGroupData.caller_group.created_by}
                  disabled={true}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callerGroupData.caller_group.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={callerGroupData.caller_group.updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                // callGroupData.permissions.call_group_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Caller Group</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => $router.router.navigate('call_center.caller_groups', {
                  companyId: activeAccountId
                }, { reload: true })}
              >
                Cancel
              </button>

              <button
                className="_bordered _green"
                disabled={!readyToSave || isSaving}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item deleting popup */}
        {/* {callGroupData.permissions.call_group_delete && */}
        {deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Caller Group
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the caller group it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _red"
                  disabled={!readyToDelete || isSaving}
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default CallCenter_CallerGroupsPage_Item
