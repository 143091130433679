import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartProps {
  colName: string
  labels: string[]
  dataForChart: {
    last?: number,
    this: number,
  }[]
  isWeek: boolean
  optionsScales: {
    max: number,
    setSize: number
  }
}

function BarChart({ colName, labels, dataForChart, isWeek, optionsScales }: BarChartProps) {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false
      },
    },
    scales: {
      y: {
        min: 0,
        max: optionsScales.max,
        stepSize: optionsScales.setSize,
      },
    }
  };

  let datasets = isWeek ? [
    {
      label: `Last ${colName}`,
      data: dataForChart.map((item) => item.last),
      backgroundColor: 'rgba(53, 162, 235, 1)',
      // maxBarThickness: 24,
    },
    {
      label: `This ${colName}`,
      data: dataForChart.map((item) => item.this),
      backgroundColor: 'rgba(85, 185, 245, 0.5)',
      // maxBarThickness: 24,
    },
  ] : [
    {
      label: `${colName}`,
      data: dataForChart.map((item) => item.this),
      backgroundColor: 'rgba(85, 185, 245, 0.5)',
      // maxBarThickness: 24,
    }
  ]

  const data = {
    labels,
    datasets,
  };

  return (
    <Bar
      height='250px'
      options={options}
      data={data}
    />
  );
}

export default BarChart;
