import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [newData, setNewData] = useState({
    domain: '',
    template: '',
    token: '',
    db_host: '',
    db_table_name: '',
    db_user_name: '',
    db_user_password: '',
  })
  const [readyToSave, setReadyToSave] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites
      const response = await httpClientUpdate.post('/websites', {
        account_id: activeAccountId,
        domain: newData.domain,
        template: newData.template,
        token: newData.token,
        db_host: newData.db_host,
        db_name: newData.db_table_name,
        db_user_name: newData.db_user_name,
        db_user_password: newData.db_user_password,
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('websites', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function checkEmptyField() {
    let is_empty = false

    Object.keys(newData).forEach(item => {
      if (newData[item] === '') {
        is_empty = true
      }
    })

    return is_empty
  }

  return (
    <div className="AccountsPage_New entity-edit">
      <div className="wrapper flex-container sb">
        <h1>New website</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('websites', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Domain:</span>

              <input
                type="text"
                defaultValue={newData.domain}
                onChange={({ target: { value } }) => setNewData({ ...newData, domain: value })}
              />
            </div>

            <div className="field">
              <span>Template:</span>

              <input
                type="text"
                defaultValue={newData.template}
                onChange={({ target: { value } }) => setNewData({ ...newData, template: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Token:</span>

              <input
                type="text"
                defaultValue={newData.token}
                onChange={({ target: { value } }) => setNewData({ ...newData, token: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Datebase</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>DB Host:</span>

              <input
                type="text"
                defaultValue={newData.db_host}
                onChange={({ target: { value } }) => setNewData({ ...newData, db_host: value })}
              />
            </div>

            <div className="field">
              <span>DB Table Name:</span>

              <input
                type="text"
                defaultValue={newData.db_table_name}
                onChange={({ target: { value } }) => setNewData({ ...newData, db_table_name: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>DB User Name:</span>

              <input
                type="text"
                defaultValue={newData.db_user_name}
                onChange={({ target: { value } }) => setNewData({ ...newData, db_user_name: value })}
              />
            </div>

            <div className="field">
              <span>DB User Password:</span>

              <input
                type="text"
                defaultValue={newData.db_user_password}
                onChange={({ target: { value } }) => setNewData({ ...newData, db_user_password: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('websites', {
              companyId: activeAccountId
            }, {
              reload: true
            })}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave || isSaving || checkEmptyField()}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
