import { useEffect, useState } from 'react'
import classNames from 'classnames';

import Icon from '../../components/Icon';

import { dateToInfoBlock, formatPhoneNumber, getSchuduleTime, httpClientUpdate, useOuterClick } from '../../funcs';
import { useAppSelector } from '../../store/hooks';

import { AttachmentsProps, SelectedDocumentsProps } from './ChannelNew';

interface ClipPopupProps {
  setOpenClipMain: (value: boolean) => void
  selectedDocuments: SelectedDocumentsProps[]
  setSelectedDocuments: (value: SelectedDocumentsProps[]) => void
  is_main: boolean
  selectedAttachments?: AttachmentsProps[]
  setSelectedAttachments?: (value: AttachmentsProps[]) => void
  is_edit?: boolean
  chatReplyReportLength?: number
}

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportProps
}

interface ReportProps {
  jobs: JobsProps[]
  calls: CallsProps[]
  tickets: TicketsProps[]
  appointments: AppointmentsProps[]
}

export interface TicketsProps {
  author_dispatcher: string
  author_id: string
  author_user: string
  created_by: string
  date_time_start: string
  number: number
  status: string
  status_id: number
  theme: string
  ticket_id: string
  type: string
}

export interface CallsProps {
  appointment_id: string
  area: string
  call_from: string
  call_to: string
  call_url: string
  caller_name: string
  check_from_phone: string
  client: string
  client_id: string
  created_at: string
  direction: string
  dispatcher: string
  dispatcher_code: string
  dispatcher_device: string
  duration: string
  extension: string
  friendly_name: string
  is_appointment: string
  no_answered_reason: string
  source: string
  status: string
  call_id: string
}

export interface JobsProps {
  appointments: string
  area: string
  created_at: string
  created_by: string
  job_id: string
  name: string
  paid: string
  property_type: string
  source: string
  status: string
  time_zone: string
  total: string
  updated_at: string
  updated_by: string
}

export interface AppointmentsProps {
  appointment_id: string
  area: string
  created_at: string
  created_by: string
  dispatcher_code: string
  is_sent: string
  name: string
  property_type: string
  schedule_time_finish: string
  schedule_time_start: string
  service_resource: string
  service_resource_code: string
  status: string
  time_zone: string
  type: string
}

const typeOptions = ['call', 'ticket', 'job', 'appointment', 'add file']

export default function ClipPopup({
  setOpenClipMain,
  selectedDocuments,
  setSelectedDocuments,
  is_main,
  chatReplyReportLength,
  is_edit,
  selectedAttachments,
  setSelectedAttachments,
}: ClipPopupProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [selectedType, setSelectedType] = useState('')
  const [search, setSearch] = useState('')

  const [showDocumnets, setShowDocumnets] = useState('')

  const [reportJob, setReportJob] = useState<JobsProps[]>([])
  const [reportCalls, setReportCalls] = useState<CallsProps[]>([])
  const [reportTickets, setTeportTickets] = useState<TicketsProps[]>([])
  const [reportAppointments, setReportAppointments] = useState<AppointmentsProps[]>([])

  useEffect(() => {
    if (showDocumnets === '') {
      setReportJob([])
      setReportCalls([])
      setTeportTickets([])
      setReportAppointments([])
    }
  }, [showDocumnets])

  async function getDocuments() {
    try {
      // https://2022back4.artemiudintsev.com/api/documents/search
      const { data: { data: loadData, success } } = await httpClientUpdate.get('/documents/search', {
        params: {
          account_id: activeAccountId,
          document_type: selectedType,
          page: 1,
          limit_rows: 50,
          key_words: search,
        }
      }) as { data: HttpReport }
      if (success) {
        if (loadData.jobs) {
          setShowDocumnets('jobs')
          setReportJob(loadData.jobs)
          setReportCalls([])
          setTeportTickets([])
          setReportAppointments([])
        } else if (loadData.calls) {
          setShowDocumnets('calls')
          setReportCalls(loadData.calls)
          setReportJob([])
          setTeportTickets([])
          setReportAppointments([])
        } else if (loadData.tickets) {
          setShowDocumnets('tickets')
          setReportCalls([])
          setReportJob([])
          setReportAppointments([])
          setTeportTickets(loadData.tickets)
        } else if (loadData.appointments) {
          setShowDocumnets('appointments')
          setReportCalls([])
          setReportJob([])
          setTeportTickets([])
          setReportAppointments(loadData.appointments)
        }
      } else {
      }
    } catch (error) { }
  }

  const clipModalRef = useOuterClick((ev: any) => {
    setOpenClipMain(false)
  });

  function handleSelectedDocument(id: string, type: string, name: string, item: TicketsProps | CallsProps | AppointmentsProps | JobsProps) {
    const ids = selectedDocuments.map(item => item.document_id)

    if (ids.includes(id)) {
      setSelectedDocuments(selectedDocuments.filter(item => item.document_id !== id))
    } else {
      const updated = selectedDocuments.map(item => item)
      updated.push({
        document_id: id,
        document_type: type,
        name: name,
        details: item,
      })
      setSelectedDocuments(updated)
    }
  }

  function handleClose(event: any) {
    event.preventDefault()
    setSelectedType('')
    setSearch('')
    setShowDocumnets('')
    setReportJob([])
    setReportCalls([])
    setTeportTickets([])
    setReportAppointments([])
    setOpenClipMain(false)
  }

  function getParamsForDiv(length: number) {
    let topParams = ''
    let leftParams = ''

    if (is_main) {
      if (length > 10) {
        topParams = '-315px'
      } else if (length === 0) {
        topParams = '-41px'
      } else {
        if ((length * 40) - 80 > 0) {
          topParams = `-${(length * 40) - 80}px`
        } else {
          topParams = `-${length * 40}px`
        }
      }
    } else {
      if (chatReplyReportLength && chatReplyReportLength > 2) {
        if (length > 10) {
          topParams = '-315px'
        } else if (length === 0) {
          topParams = '-41px'
        } else {
          if ((length * 40) - 80 > 0) {
            topParams = `-${(length * 40) - 80}px`
          } else {
            topParams = `-${length * 40}px`
          }
        }
      } else {
        if (selectedDocuments.length) {
          topParams = `${(selectedDocuments.length * 23.74) + 143}px`
        } else {
          topParams = `${(selectedDocuments.length * 23.74) + 128}px`
        }
      }
      leftParams = '0px'
    }


    return {
      top: topParams,
      left: leftParams,
    }
  }

  function onFileChange(event: any) {
    event.stopPropagation()
    event.preventDefault()

    const file = event.target.files[0]

    if (!!is_edit && selectedAttachments && setSelectedAttachments) {
      const updated = selectedAttachments.map(item => item)
      updated.push({
        chat_message_id: '',
        url: '',
        details: file
      })
      setSelectedAttachments(updated)
      handleClose(event)
    } else {
      const updated = selectedDocuments.map(item => item)
      updated.push({
        document_id: 'custom',
        document_type: file.type,
        name: file.name,
        details: file,
      })
      setSelectedDocuments(updated)
      handleClose(event)
    }
  }

  return (
    <>
      {
        selectedType === '' &&
        <div
          className='channel_users'
          ref={clipModalRef}
          style={{
            top: is_main ? `-${(typeOptions.length * 40) - 60}px` : chatReplyReportLength && chatReplyReportLength > 2 ? `-${(typeOptions.length * 40) - 60}px` : `${(typeOptions.length * 32)}px`,
            maxHeight: `${(typeOptions.length * 40) + 40}px`,
            left: getParamsForDiv(reportTickets.length).left,
          }}
        >
          <div
            className='search-title'
            style={{ top: '0' }}
            onClick={(event) => {
              event.stopPropagation()
              setShowDocumnets('')
            }}
          >
            Choose type
          </div>
          {
            typeOptions.map(item => (
              <div
                key={item}
                className='user-item'
                onClick={(event) => {
                  event.stopPropagation()
                  item !== 'add file' && setSelectedType(item)
                }}
              >
                {
                  item === 'add file' ?
                    <div>
                      {item}
                      <input
                        style={{
                          display: 'block',
                          opacity: '0',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                        }}
                        type="file"
                        onChange={onFileChange}
                        placeholder="add file"
                      />
                    </div> :
                    item
                }
              </div>
            ))
          }
        </div>
      }

      {
        !showDocumnets && selectedType !== '' &&
        <div
          className='channel_users'
          ref={clipModalRef}
          style={{
            top: is_main ? `-10px` : chatReplyReportLength && chatReplyReportLength > 2 ? `-10px` : '128px',
            maxHeight: `${typeOptions.length * 40}px`,
            left: getParamsForDiv(reportTickets.length).left,
          }}
        >
          <div
            className='search-title'
            style={{ top: '0' }}
          >
            Document ({selectedType})

            <div
              className='close-popup'
              onClick={handleClose}
            >
              <Icon
                icon="x-mark-1"
              />
            </div>
          </div>
          <div className='search-item'>
            <input
              type="text"
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
            />
            <button
              className='_bordered _green'
              disabled={search === ''}
              onClick={(event) => {
                event.stopPropagation()
                getDocuments()
              }}
            >
              Go
            </button>
          </div>
        </div>
      }

      {
        showDocumnets === 'tickets' &&
        <div
          className='channel_users'
          ref={clipModalRef}
          style={{
            top: getParamsForDiv(reportTickets.length).top,
            maxHeight: reportTickets.length > 10 ? '400px' : `${reportTickets.length === 1 ? 82 : reportTickets.length === 0 ? 41 : reportTickets.length * 41}px`,
            width: reportTickets.length ? is_main ? '500px' : '430px' : '200px',
            left: getParamsForDiv(reportTickets.length).left
          }}
        >
          <div
            className='search-title'
            style={{ top: '0' }}
            onClick={(event) => {
              event.stopPropagation()
              setShowDocumnets('')
            }}
          >
            Back to search

            <div
              className='close-popup'
              onClick={handleClose}
            >
              <Icon
                icon="x-mark-1"
              />
            </div>
          </div>

          <div className='table-popup' style={{ width: '100%', overflow: 'auto' }}>
            <table className='table'>
              <tbody>
                <tr style={{ height: 0 }}></tr>
                {
                  reportTickets.map(item => (
                    <tr
                      key={item.ticket_id}
                      className={classNames(
                        {
                          __active: selectedDocuments.map(item => item.document_id).includes(item.ticket_id),
                        }
                      )}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleSelectedDocument(item.ticket_id, 'ticket', item.type, item)
                      }}
                    >
                      <td style={{ position: 'relative', paddingRight: '25px' }}>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.date_time_start)}
                        {
                          item.status !== 'Closed' &&
                          item.status !== 'Canceled' &&
                          <span
                            style={{
                              position: 'absolute',
                              right: '5px',
                              width: '14px',
                              height: '14px',
                              background: item.status === 'In-progress' ? '#FFB700' : '#FF0000',
                              borderRadius: '50%',
                              color: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              top: '3px'
                            }}
                          >!</span>
                        }
                      </td>
                      <td>{item.number}</td>
                      <td
                        className={classNames('status',
                          {
                            canceled: item.status === 'Canceled',
                            closed: item.status === 'Closed',
                            initial: item.status !== 'Canceled' && item.status !== 'Closed'
                          }
                        )}
                      >
                        {item.status}
                      </td>
                      <td>{item.type}</td>
                      <td>{item.theme}</td>
                      <td> {item.author_dispatcher || item.author_user}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      {
        showDocumnets === 'calls' &&
        <div
          className='channel_users'
          ref={clipModalRef}
          style={{
            top: getParamsForDiv(reportCalls.length).top,
            maxHeight: reportCalls.length > 10 ? '400px' : `${reportCalls.length === 1 ? 82 : reportCalls.length === 0 ? 41 : reportCalls.length * 41}px`,
            width: reportCalls.length ? is_main ? '500px' : '430px' : '200px',
            left: getParamsForDiv(reportTickets.length).left,
          }}
        >
          <div
            className='search-title'
            style={{ top: '0' }}
            onClick={(event) => {
              event.stopPropagation()
              setShowDocumnets('')
            }}
          >
            Back to search

            <div
              className='close-popup'
              onClick={handleClose}
            >
              <Icon
                icon="x-mark-1"
              />
            </div>
          </div>

          <div className='table-popup' style={{ width: '100%', overflow: 'auto' }}>
            <table className='table'>
              <tbody>
                <tr style={{ height: 0 }}></tr>
                {
                  reportCalls.map(item => (
                    <tr
                      key={item.call_id}
                      className={classNames(
                        {
                          __active: selectedDocuments.map(item => item.document_id).includes(item.call_id),
                        }
                      )}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleSelectedDocument(item.call_id, 'call', item.call_id, item)
                      }}
                    >
                      <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)}</td>
                      <td>
                        <button
                          className={classNames('_zeroed', '_iconed', {
                            _red: ['no-answer', 'busy', 'canceled'].includes(item.status),
                            _green: ['completed', 'ringing', 'in-progress'].includes(item.status) && item.direction === 'inbound',
                            _blue: item.direction === 'outbound'
                          })}
                        >
                          <Icon icon="arrow-20" className={classNames({
                            '_rotated-180': item.direction === 'outbound'
                          })} />
                        </button>
                      </td>

                      <td>{item.caller_name}</td>
                      <td>{item.client ? item.client : null}</td>
                      <td>
                        <span>
                          {item.direction === 'outbound' ? formatPhoneNumber(item.call_to) : formatPhoneNumber(item.call_from)}
                        </span>
                      </td>
                      <td>{item.friendly_name}</td>
                      <td>{item.direction === 'outbound' ? formatPhoneNumber(item.call_from) : formatPhoneNumber(item.call_to)}</td>
                      <td>{item.extension}</td>
                      <td>{item.is_appointment}</td>
                      <td className={classNames({
                        'red-text': ['no-answer', 'busy', 'canceled'].includes(item.status),
                        'green-text': item.status === 'completed',
                        'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes(item.status),
                      })}>{item.status} {item.no_answered_reason ? `(${item.no_answered_reason})` : ''} </td>
                      <td>{item.duration}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      {
        showDocumnets === 'jobs' &&
        <div
          className='channel_users'
          ref={clipModalRef}
          style={{
            top: getParamsForDiv(reportJob.length).top,
            maxHeight: reportJob.length > 10 ? '400px' : `${reportJob.length === 1 ? 82 : reportJob.length === 0 ? 41 : reportJob.length * 41}px`,
            width: reportJob.length ? is_main ? '500px' : '430px' : '200px',
            left: getParamsForDiv(reportTickets.length).left,
          }}
        >
          <div
            className='search-title'
            style={{ top: '0' }}
            onClick={(event) => {
              event.stopPropagation()
              setShowDocumnets('')
            }}
          >
            Back to search

            <div
              className='close-popup'
              onClick={handleClose}
            >
              <Icon
                icon="x-mark-1"
              />
            </div>
          </div>

          <div className='table-popup' style={{ width: '100%', overflow: 'auto' }}>
            <table className='table'>
              <tbody>
                <tr style={{ height: 0 }}></tr>
                {
                  reportJob.map(item => (
                    <tr
                      key={item.job_id}
                      className={classNames(
                        {
                          __active: selectedDocuments.map(item => item.document_id).includes(item.job_id),
                        }
                      )}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleSelectedDocument(item.job_id, 'job', item.name, item)
                      }}
                    >
                      <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)}</td>
                      <td>{item.name}</td>
                      <td>
                        {
                          item.status === 'Canceled' ?
                            <span className="red">
                              {item.status}
                            </span> :
                            <span>
                              {item.status}
                            </span>
                        }
                      </td>
                      <td>{item.property_type}</td>
                      <td>{item.area}</td>
                      <td>{item.source}</td>
                      <td>{item.created_by}</td>
                      <td>{item.appointments}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      {
        showDocumnets === 'appointments' &&
        <div
          className='channel_users'
          ref={clipModalRef}
          style={{
            top: getParamsForDiv(reportAppointments.length).top,
            maxHeight: reportAppointments.length > 10 ? '400px' : `${reportAppointments.length === 1 ? 82 : reportAppointments.length === 0 ? 41 : reportAppointments.length * 41}px`,
            width: reportAppointments.length ? is_main ? '500px' : '430px' : '200px',
            left: getParamsForDiv(reportTickets.length).left,
          }}
        >
          <div
            className='search-title'
            style={{ top: '0' }}
            onClick={(event) => {
              event.stopPropagation()
              setShowDocumnets('')
            }}
          >
            Back to search

            <div
              className='close-popup'
              onClick={handleClose}
            >
              <Icon
                icon="x-mark-1"
              />
            </div>
          </div>

          <div className='table-popup' style={{ width: '100%', overflow: 'auto' }}>
            <table className='table'>
              <tbody>
                <tr style={{ height: 0 }}></tr>
                {
                  reportAppointments.map(item => (
                    <tr
                      key={item.appointment_id}
                      className={classNames(
                        {
                          __active: selectedDocuments.map(item => item.document_id).includes(item.appointment_id),
                        }
                      )}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleSelectedDocument(item.appointment_id, 'appointment', item.name, item)
                      }}
                    >
                      <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)}</td>
                      <td>{item.name}</td>
                      <td>
                        {item.type === 'Recall' ? (
                          <span className="red">
                            {item.type}
                          </span>
                        ) : item.type === 'Service call' ? (
                          <span className="green">
                            {item.type}
                          </span>
                        ) : (
                          <span className="blue">
                            {item.type}
                          </span>
                        )}
                      </td>
                      <td>
                        {
                          item.status === 'Canceled' ?
                            <span className="red">
                              {item.status}
                            </span> :
                            <span>
                              {item.status}
                            </span>
                        }
                      </td>
                      <td>{item.property_type}</td>
                      <td>{item.area}</td>
                      <td>{item.dispatcher_code ? `${item.created_by} (${item.dispatcher_code})` : item.created_by}</td>
                      <td>{getSchuduleTime(item.schedule_time_start as string, item.schedule_time_finish as string, item.time_zone)}</td>
                      <td>{item.service_resource_code ? `${item.service_resource}(${item.service_resource_code})` : item.service_resource}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}
