import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import AccountPermission from "../../models/AccountPermission"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import PermissionAccount from "../../models/PermissionAccount"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

export interface HttpAccountPermissionsItemReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AccountPermissionData
}

interface ActionProp {
  id: string;
  show: string;
}

interface AccountPermissionData {
  permissions: {
    account_permission_add: boolean
    account_permission_delete: boolean
    account_permission_edit: boolean
    account_permission_report_show: boolean
    account_permission_show: boolean
    account_show: boolean
  },

  permission: AccountPermission,
  accounts: PermissionAccount[],

  actions: ActionProp[],
  edit: {
    actions: ActionProp[]
  }
}

function AccountPermissionsPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [permissionData, setPermissionData] = useState<AccountPermissionData | null>(null)

  const [selectedAction, setSelectedAction] = useState<string>('')

  const [newPermissionData, setNewPermissionData] = useState<Partial<AccountPermission>>({
    name: '',
    accounts: '',
    actions: [] as ActionProp[]
  })

  const [availableActions, setAvailableActions] = useState<ActionProp[]>([])

  const [mode, setMode] = useState(false)
  const [modeActions, setModeActions] = useState<ActionProp[]>([])
  const [checkedActions, setCheckedActions] = useState<string[]>([])
  const [searchActions, setSearchActions] = useState('')

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/permissions/accounts/${$router.router.getState().params.permissionId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('accounts_permissions', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/permissions/accounts/${$router.router.getState().params.permissionId}`, qs.stringify({
        account_id: activeAccountId,
        name: newPermissionData.name,
        actions: mode ? JSON.stringify(checkedActions) : JSON.stringify(newPermissionData.actions?.map(item => item.id))
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        setMode(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: permissionData, success, error } } = await httpClientUpdate.get('/permissions/accounts/' + $router.router.getState().params.permissionId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpAccountPermissionsItemReport }
      if (success) {
        if (permissionData.permissions.account_permission_show) {
          let _availableActions = permissionData.edit.actions.filter(action => !permissionData.actions.filter(item => item.id === action.id).length && action)
          let checkedActions = permissionData.actions.map(item => item.id)

          setPermissionData(permissionData)
          setAvailableActions(_availableActions)
          setSelectedAction('')
          setNewPermissionData({
            ...newPermissionData,
            name: permissionData.permission.name,
            actions: permissionData.actions
          })

          setCheckedActions(checkedActions)
          setModeActions(permissionData.edit.actions.map(item => item))
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load permission data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (permissionData) {
      let updatedActions = permissionData.edit.actions.map(item => item)

      setModeActions(updatedActions.filter(item => checkedActions.includes(item.id) || item.show.toLowerCase().includes(searchActions.toLowerCase())))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchActions])

  useEffect(() => {
    if (newPermissionData.actions && permissionData) {
      if (mode) {
        setCheckedActions(newPermissionData.actions.map(item => item.id))

        setSearchActions('')
      } else {
        let updateActions = permissionData.edit.actions.filter(item => checkedActions.includes(item.id))

        setNewPermissionData({
          ...newPermissionData,
          actions: updateActions
        })

        let _availableActions = permissionData.edit.actions.filter(action => !(updateActions as ActionProp[]).filter(item => item.id === action.id).length && action)
        _availableActions.splice(_availableActions.findIndex((_action) => selectedAction === _action.id), 1)

        setAvailableActions([
          ..._availableActions
        ])

        setSelectedAction('')
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  // Add action function
  function addAction() {

    if (!newPermissionData.actions || !permissionData || !newPermissionData) return

    let updateActions = newPermissionData.actions.map(item => item)
    permissionData.edit.actions.forEach(item => item.id === selectedAction && updateActions.push(item))
    setNewPermissionData({
      ...newPermissionData,
      actions: updateActions
    })

    let _availableActions = permissionData.edit.actions.filter(action => !(newPermissionData.actions as ActionProp[]).filter(item => item.id === action.id).length && action)
    _availableActions.splice(_availableActions.findIndex((_action) => selectedAction === _action.id), 1)

    setAvailableActions([
      ..._availableActions
    ])

    setSelectedAction('')

    setReadyToSave(true)
  }

  // Remove action function
  function removeAction(i: number) {

    let _permissionActions = [...(newPermissionData.actions as ActionProp[])]

    let _action = _permissionActions[i]

    _permissionActions.splice(i, 1)

    setNewPermissionData({
      ...newPermissionData,
      actions: _permissionActions
    })

    setAvailableActions([
      ...availableActions,
      _action
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newPermissionData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function handleChangeMode() {
    setMode(!mode)
  }

  function handleChangeModeActions(id: string) {
    if (checkedActions.includes(id)) {
      setCheckedActions(checkedActions.filter(item => item !== id))
    } else {
      let updatedCheckedActions = checkedActions.map(item => item)
      updatedCheckedActions.push(id)
      setCheckedActions(updatedCheckedActions)
    }

    setReadyToSave(true)
  }

  // Render function
  return (<>
    {permissionData &&
      permissionData.permissions.account_permission_show &&
      newPermissionData ? (
      <div className="AccountPermissionsPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <div className="flex-container _gap-narrow">
            <h1>Account`s Permission:</h1>
            <div className="item-name">
              {permissionData.permission.name}
            </div>
          </div>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'accounts_permissions',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                permissionData?.permissions.account_permission_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>

                <input
                  disabled={!editing}
                  type="text"
                  defaultValue={permissionData.permission.name}
                  onChange={({ target: { value } }) => setNewPermissionData({ ...newPermissionData, name: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Actions</div>

            <div className="legend-action-wrapper">
              <button
                style={{ width: 'fit-content', backgroundColor: 'inherit' }}
                disabled={!editing}
                onClick={() => handleChangeMode()}
              >
                <Icon
                  style={{
                    width: '24px',
                    height: '24px',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    fill: mode ? 'inherit' : '#6093DE'
                  }}
                  viewBox="0 0 1024 1024"
                  icon="change-1"
                />
              </button>

              <label>Action:</label>

              {
                mode ?
                  <div>
                    <input
                      type="text"
                      value={searchActions}
                      onChange={({ target: { value } }) => setSearchActions(value)}
                    />
                  </div> :
                  <div className="input-wrapper">
                    <Select
                      disabled={!editing}
                      options={availableActions.map((action) => ({
                        span: action.show,
                        value: action.id
                      }))}
                      selectedOption={selectedAction}
                      onChange={(value) => setSelectedAction(value as string)}
                    />

                    <button
                      disabled={!selectedAction}
                      className="_green"
                      onClick={() => addAction()}
                    >
                      Add
                    </button>
                  </div>
              }
            </div>
          </div>

          {
            mode ?
              <table className="table som">
                <tr>
                  <th style={{ width: '100%' }}>Action</th>
                  <th>Allow</th>
                </tr>
                {(modeActions).map((action, i) => (
                  <tr
                    key={i}
                    style={{ cursor: editing ? 'pointer' : 'inherit' }}
                  >
                    <td>{action.show}</td>
                    <td>
                      <Checkbox
                        contents={''}
                        value={checkedActions.includes(action.id)}
                        onChange={() => handleChangeModeActions(action.id)}
                      />
                    </td>
                  </tr>
                ))}
              </table> :
              <table className="table som">
                <thead>
                  <tr>
                    <th style={{ width: '100%' }}>Action</th>
                    <th>Allow</th>
                    <th></th>
                  </tr>
                </thead>

                {(newPermissionData.actions as ActionProp[]).map((action, i) => (
                  <tr
                    key={i}
                    style={{ cursor: editing ? 'pointer' : 'inherit' }}
                  >
                    <td>{action.show}</td>
                    <td>
                      <Checkbox contents={''} value={true} />
                    </td>
                    <td>
                      <button
                        disabled={!editing}
                        className="_zeroed _iconed _red"
                        onClick={() => removeAction(i)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
          }
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, permissionData.permission.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={permissionData.permission.created_by} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, permissionData.permission.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={permissionData.permission.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Accounts</div>

          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Owner</th>
                <th>Status</th>
              </tr>
            </thead>

            {permissionData.accounts.map((account, i) => (
              <tr
                key={i}
                style={{ cursor: permissionData.permissions.account_show && account.account_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  permissionData.permissions.account_show &&
                  account.account_id &&
                  $router.router.navigate('accounts.item', {
                    companyId: activeAccountId, accountId: account.account_id
                  }, { reload: true })}
                onContextMenu={(event) =>
                  permissionData.permissions.account_show &&
                  account.account_id &&
                  handleChangeMenu(event, account.account_id, 'accounts', i)}
              >
                <td>{account.name}</td>
                <td>{account.owner}</td>
                <td className={classNames({
                  'red-text': account.status === 'Inactive',
                  'green-text': account.status === 'Active',
                  'blue-text': !['Inactive', 'Active'].includes(account.status)
                })}>
                  {account.status}
                  {
                    permissionData.permissions.account_show &&
                    showContext === `accounts_${account.account_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('accounts.item', {
                            companyId: activeAccountId, accountId: account.account_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/accounts/${account.account_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>
        </div>

        {
          editing &&
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                permissionData.permissions.account_permission_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Account`s Permission</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => $router.router.navigate('accounts_permissions', {
                  companyId: activeAccountId
                }, {
                  reload: true
                })}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        }

        {/* Item delete popup */}
        {permissionData.permissions.account_permission_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Account`s Permission
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the account`s permission it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div >
    ) : null
    }
  </>)
}

export default AccountPermissionsPage_Item
