import { useEffect, useState } from "react"
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios"
import { HttpReport } from "./List"

import "../../styles/pages/common/entity-edit.sass"

interface HttpLoadReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    payout_dispatchers: PayoutsProps[]
  }
}

interface PayoutsProps {
  created_at: string
  date_finish: string
  date_start: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  payout_dispatcher_id: string
  status_id: string
  status_name: string
  total_amount: string
  total_hours: string
  total_hours_amount: string
  total_mentor_orders: string
  total_mentor_orders_amount: string
  total_orders: string
  total_orders_amount: string
  type: string
  updated_at: string
  updated_by: string
  isPay: boolean
  amount: number
  paid: number
}

interface DispatchersProps {
  code: number
  dispatcher_id: string
  nickname: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [newData, setNewData] = useState({
    dispatcher_id: ''
  })

  const [payouts, setPayouts] = useState<PayoutsProps[]>([])
  const [isSaving, setIsSaving] = useState(false)

  const [edit, setEdit] = useState<DispatchersProps[]>([])

  useEffect(() => {
    loadReport()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (newData.dispatcher_id) {
      loadInfo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData.dispatcher_id])

  // Load PayoutDispatcher function
  async function loadReport() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: payoutDispatcher, success, error } } = (await httpClientUpdate.get('/payouts/dispatchers/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: reportsMaxRows,
          page: 1
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })) as { data: HttpReport }
      if (success) {
        setEdit(payoutDispatcher.edit.dispatchers)
        setIsSaving(false)
      } else {
        setIsSaving(false)
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load info function
  async function loadInfo() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/need-payment
      const { data: { data: reportData, success, error } } = (await httpClientUpdate.get('/payouts/dispatchers/need-payment', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId,
          dispatcher_id: newData.dispatcher_id,
          // limit_rows: reportsMaxRows,
          // page: 1,
        }
      })) as { data: HttpLoadReport }
      if (success) {
        setIsSaving(false)
        setPayouts(reportData.payout_dispatchers.map(item => {
          return {
            ...item,
            isPay: false,
            amount: 0,
          }
        }))
      } else {
        setIsSaving(false)
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    setIsSaving(true)
    try {
      const payuotsFilter = payouts.filter(item => item.isPay)
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/payments
      const response = await httpClientUpdate.post(`/payouts/dispatchers/payments`, {
        account_id: activeAccountId,
        payouts: payuotsFilter.map(item => {
          return {
            payout_dispatcher_id: item.payout_dispatcher_id,
            amount: item.amount
          }
        }),
        dispatcher_id: newData.dispatcher_id,
        total_amount: getTotal(),
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('payout-dispatcher', {
          companyId: activeAccountId,
          [$router.router.getState().params.nameId]: $router.router.getState().params.id,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleIsPay(index: number, value: boolean) {
    let updatedPay = payouts.map(item => item)

    if (value) {
      updatedPay[index].isPay = value
      updatedPay[index].amount = Number(updatedPay[index].total_amount) - Number(updatedPay[index].paid)

      setPayouts(updatedPay)
    } else {
      updatedPay[index].isPay = value
      updatedPay[index].amount = 0

      setPayouts(updatedPay)
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    let updatedPay = payouts.map(item => item)

    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      if (+newNumber <= +updatedPay[index].total_amount) {
        updatedPay[index].amount = +newNumber

        setPayouts(updatedPay)
      }
    } else if (price.length === 1) {
      let newNumber = `0.0${price}`
      if (+newNumber <= +updatedPay[index].total_amount) {
        updatedPay[index].amount = +newNumber

        setPayouts(updatedPay)
      }
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      let updatedPay = payouts.map(item => item)

      if (numberWithoutDot[0].length > 1) {

        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (+newNumber <= +updatedPay[index].total_amount) {
          updatedPay[index].amount = +newNumber

          setPayouts(updatedPay)
        }
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        if (+newNumber <= +updatedPay[index].total_amount) {
          updatedPay[index].amount = +newNumber

          setPayouts(updatedPay)
        }
      }
    }
  }

  function getTotal() {
    let total = 0

    payouts.forEach(item => {
      if (item.isPay) {
        total = total + (item.amount)
      }
    })

    return total
  }

  return (
    <div className="PayoutPage_Item PayoutPage_New entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Make Payment</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('payout-dispatcher', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Dispatcher:</span>
              <Select options={edit.map((option) => ({
                span: option.code ? `${option.nickname}(${option.code})` : option.nickname,
                value: option.dispatcher_id
              }))}
                selectedOption={newData.dispatcher_id as string}
                onChange={(value) => setNewData({ ...newData, dispatcher_id: value as string })}
              />
            </div>
          </div>

          <div className="__right">

          </div>
        </div>
      </div>

      {
        !!payouts.length &&
        <div className="fieldset">

          <div className="legend">Payouts</div>

          <table className='table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Status</th>
                <th>Range</th>
                <th>Amount</th>
                <th>Paid</th>
                <th>Choose</th>
                <th>Pay</th>
              </tr>
            </thead>

            {payouts.map((payout, i) => (
              <tr
                key={i}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payout.created_at)}</td>
                <td>
                  {payout.type}
                </td>
                <td>
                  {payout.status_name}
                </td>
                <td>{payout.date_start}{' - '}{payout.date_finish}</td>
                <td>${payout.total_amount}</td>
                <td>${`${Number(payout.paid).toFixed(2)}`}</td>
                <td>
                  <Checkbox
                    contents={''}
                    value={payout.isPay}
                    onChange={(value) => handleIsPay(i, value)}
                  />
                </td>
                <td>
                  <input
                    className='amount-field'
                    style={{ border: 'none' }}
                    disabled={!payout.isPay}
                    type="number"
                    value={payout.amount.toFixed(2)}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangePrice(event, i)}
                    onKeyDown={(event) => handleChangePriceBackspace(event, i)}
                  />
                </td>
              </tr>
            ))}
            <tfoot>
              <tr>
                <th>Total:</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style={{ textAlign: 'right' }}> ${getTotal().toFixed(2)} </th>
              </tr>
            </tfoot>
          </table>
        </div>
      }

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() =>
              $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'payout-dispatcher',
                {
                  companyId: activeAccountId,
                  [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                  localInterface: $router.router.getState().params.localInterface
                }, { reload: true })}
          >
            Cancel
          </button>
          <button
            disabled={getTotal() === 0 || isSaving}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
