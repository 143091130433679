import { useRoute } from "react-router5"

import classNames from "classnames"
import { Transition } from 'react-transition-group'

import Icon from "../components/Icon"
import logoImg from "../images/logo.svg";
import AccountMenu from "../components/AccountMenu"
import HelpMenu from "../components/HelpMenu"
import NotificationsWidget from "../components/NotificationsWidget"

import { useAppDispatch, useAppSelector } from "../store/hooks"
import { NavMode, NavActive, setNavActive, setNavMode, setChannels } from "../store/reducer"
import { httpClientUpdate } from "../funcs";

type HeaderProps = {
  navActive: NavActive;
  activeNavButton: string | null;
  setActiveNavButton(button: string | null): void;
  navMode: NavMode;
  searchPanelExpanded: boolean;
  setSearchPanelExpanded(panel: boolean): void;
  togglePhoneCall: () => void
  phoneCallStatus: string
  notify: {
    today: number
    yesterday: number
    weekend: number
  }
  agreeSound: boolean
  handleBell: () => void
  twilio_allow: boolean

  setIsCloseBefore: (value: boolean) => void
}

export default function Header({
  navActive,
  activeNavButton,
  setActiveNavButton,
  navMode,
  setSearchPanelExpanded,
  searchPanelExpanded,
  togglePhoneCall,
  phoneCallStatus,
  notify,
  agreeSound,
  handleBell,
  twilio_allow,
  setIsCloseBefore,
}: HeaderProps) {
  const $router = useRoute()
  const dispatch = useAppDispatch()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  async function handleChannel() {
    try {
      dispatch(setChannels([]))
      dispatch(setNavMode("channels"))

      let { data: { data: channels, success: successChannels, error: errorChannels } } = await httpClientUpdate.get(`/users/chats/channels`, {
        params: {
          account_id: activeAccountId
        },
      })

      if (!successChannels) {
        $router.router.navigate(`${errorChannels.code}`, {
          reload: true
        })
      }
      dispatch(setChannels(channels.chat_channels))
    } catch (error) { }
  }

  return (
    <header
      className={classNames({
        _is_display_none: $router.route.name === 'technician_job'
      })}
    >

      { /* Project about */}
      <div className="ProjectHeaders">

        { /* Mobile nav switcher */}
        <button
          className={classNames(["_zeroed", "_iconed", "mobile-nav-switcher", { _active: navActive.is }])}
          onClick={(e) => { e.stopPropagation(); dispatch(setNavActive({ ...navActive, is: !navActive.is })) }}
        >
          <Icon icon="menu-1" />
        </button>
        <img src={logoImg} alt="" onClick={() => $router.router.navigate('dashboard', {
          companyId: activeAccountId
        }, { reload: true })} />

        <div className="headers" onClick={() => $router.router.navigate('dashboard', {
          companyId: activeAccountId
        }, { reload: true })}>
          <h1> {process.env.REACT_APP_TITLE} </h1>
          <h2>Manage your business!</h2>
        </div>

        { /* Mobile header account menu */}
        <div className="mobile-account-menu-switcher">

          <button
            className="_zeroed _iconed"
            onClick={(e) => { e.stopPropagation(); setActiveNavButton(activeNavButton ? null : 'mobileAccountMenu') }}
          >
            <Icon icon="user-20" />
          </button>

          <Transition in={activeNavButton === "mobileAccountMenu"} timeout={210} mountOnEnter={true} unmountOnExit={true}>
            {(state) => (
              <div style={{ width: '180px' }} className={classNames("menu-container", "_approach-left", `transition-fade-${state}`)}>
                <AccountMenu
                  full={true}
                  onActiveNavButtonChange={setActiveNavButton}
                  togglePhoneCall={togglePhoneCall}
                  twilio_allow={twilio_allow}
                />
              </div>
            )}
          </Transition>

        </div>
      </div>

      { /* Nav switch button */}
      <div className="nav-switch-button">
        <button
          className={classNames(["_iconed", "_zeroed", { _active: navActive.is }])}
          onClick={() => dispatch(setNavActive({ ...navActive, is: !navActive.is }))}
        >
          <Icon icon="menu-1" />
        </button>
      </div>

      { /* Search panel */}
      <div className={classNames(["search-panel", { _forcedShown: navActive.is }])}>
        <button
          className="_iconed _zeroed"
          onClick={() => setSearchPanelExpanded(!searchPanelExpanded)}
        >
          <Icon icon="magnifier-5" />
        </button>

        <div className={classNames(["search-input", { _shown: searchPanelExpanded }])}>
          <input type="text" />
          <button className="_iconed _wa">
            <Icon icon="magnifier-5" />
          </button>
        </div>
      </div>

      { /* Nav switchers */}
      <div className={classNames(["nav-switchers", { _forcedShown: navActive.is }])}>

        <button
          className={classNames({ _active: navMode === "main" })}
          onClick={() => {
            dispatch(setNavMode("main"))
            !navActive.is && setIsCloseBefore(true)
            !navActive.is && dispatch(setNavActive({ ...navActive, is: true }))
          }}
        >
          <Icon icon="logo" viewBox="0 0 63.31 69.32" />
        </button>

        <button
          className={classNames({ _active: navMode === "channels" })}
          onClick={() => {
            handleChannel()
            !navActive.is && setIsCloseBefore(true)
            !navActive.is && dispatch(setNavActive({ ...navActive, is: true }))
          }}
        >
          <Icon icon="email-1" />
        </button>

        <button
          className={classNames({ _active: navMode === "settings" })}
          onClick={() => {
            dispatch(setNavMode("settings"))
            !navActive.is && setIsCloseBefore(true)
            !navActive.is && dispatch(setNavActive({ ...navActive, is: true }))
          }}
        >
          <Icon icon="gear-1" />
        </button>
      </div>

      { /* Nav buttons */}
      <div className="nav-buttons">
        {
          false &&
          <div>
            <button
              className={classNames("_zeroed", "_iconed", { _active: activeNavButton === "helpMenu" })}
              onClick={() => setActiveNavButton('helpMenu')}
            >
              <Icon icon="help-1" />
            </button>

            <Transition in={activeNavButton === "helpMenu"} timeout={210} mountOnEnter={true} unmountOnExit={true}>
              {(state) => (
                <div style={{ width: '210px' }} className={classNames("menu-container", "_approach-left", `transition-fade-${state}`)}>
                  <HelpMenu companyId={activeAccountId} setActiveNavButton={setActiveNavButton} />
                </div>
              )}
            </Transition>
          </div>
        }
        <div>
          <button
            className={classNames("_zeroed", "_iconed", { _active: activeNavButton === "notificationsWidget" })}
            onClick={() => setActiveNavButton('notificationsWidget')}
          >
            <Icon icon="bell-1" />
            {
              !!Object.keys(notify).filter(item => notify[item]).length &&
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: '#FF0000',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  color: '#fff',
                }}
              >!</span>
            }
          </button>

          <Transition in={activeNavButton === "notificationsWidget"} timeout={210} mountOnEnter={true} unmountOnExit={true}>
            {(state) => (
              <div
                style={{ width: '290px' }}
                className={classNames("menu-container", "_approach-left", `transition-fade-${state}`)}
              >
                <NotificationsWidget
                  notify={notify}
                  setActiveNavButton={setActiveNavButton}
                  agreeSound={agreeSound}
                  handleBell={handleBell}
                />
              </div>
            )}
          </Transition>
        </div>

        <div className="__account-menu-switcher">

          <button
            className={classNames("_zeroed", "_iconed")}
            onClick={() => setActiveNavButton('accountMenu')}
          >
            {
              user?.photo_link ?
                <img
                  src={user.photo_link}
                  alt="Photo_Link"
                /> :
                <Icon icon="user-20" />
            }
          </button>

          <Transition in={activeNavButton === "accountMenu"} timeout={210} mountOnEnter={true} unmountOnExit={true}>
            {(state) => (
              <div style={{ width: '180px' }} className={classNames("menu-container", "_approach-left", `transition-fade-${state}`)}>
                <AccountMenu setActiveNavButton={setActiveNavButton} />
              </div>
            )}
          </Transition>
        </div>
        {
          twilio_allow &&
          <div>
            <button
              className={classNames("_zeroed", "_iconed")}
              onClick={() => togglePhoneCall()}
            >
              <Icon style={{ fill: phoneCallStatus === 'Available' ? '#6093DE' : '' }} icon="phone-6" />
            </button>
          </div>
        }
      </div>
    </header>
  )
}
