import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import Appointment from "../../models/Appointment"
import Estimate from "../../models/Estimate"
import Invoice from "../../models/Invoice"
import Payment from "../../models/Payments"
import Client from "../../models/Client"
import Job from "../../models/Job"
import Unit from "../../models/Unit"

import {
  formatPhoneNumber,
  httpClientUpdate,
  nErrorUpdate,
  useOuterClick,
  getSchuduleTime,
  dateToInfoBlock,
} from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: JobData
}

interface JobData {

  permissions: {
    job_add: boolean
    job_delete: boolean
    job_edit: boolean
    job_report_show: boolean
    job_show: boolean

    appointment_show: boolean
    client_show: boolean
    estimate_show: boolean
    invoice_show: boolean
    payment_show: boolean
  },

  job: Job[],
  clients: Client[],
  appointments: Appointment[],
  estimates: Estimate[],
  invoices: Invoice[],
  payments: Payment[]
  units: Unit[],

  edit: {
    status: {
      id: number
      name: string
    }[],
    source: {
      list_sources_id: string
      name: string
    }[]
    contact_addresses: {
      contact_address: string
      contact_address_id: string
    }[]
    companies: {
      name: string
      company_id: string
    }[]
  }
}

function JobsPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [jobData, setJobData] = useState<JobData | null>(null)

  const [newJobData, setNewJobData] = useState<Partial<Job>>({
    status: '',
    source: '',
    company_id: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    jobData && setNewJobData(jobData.job[0])
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/jobs/${$router.router.getState().params.jobId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('jobs', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/jobs/${$router.router.getState().params.jobId}`, {
        account_id: activeAccountId,
        name: newJobData.name,
        status: newJobData.status_id,
        source_id: newJobData.source_id ? newJobData.source_id : null,
        contact_address_id: newJobData.contact_address_id ? newJobData.contact_address_id : null,
        company_id: newJobData.company_id ? newJobData.company_id : null,
      })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: jobData, success, error } } = await httpClientUpdate.get('/jobs/' + $router.router.getState().params.jobId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }

      if (success) {
        if (jobData.permissions.job_show) {
          setJobData(jobData)
          setNewJobData(jobData.job[0])
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newJobData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {jobData ? (
      <div className="JobsPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Job</h1>

          <div style={{ display: 'flex' }}>
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'jobs',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                jobData?.permissions.job_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input type="text" defaultValue={jobData?.job[0].name} disabled={true} />
              </div>



              <div className="field">
                <span>Status:</span>
                {
                  jobData.edit.status.length ?
                    <Select
                      disabled={!editing}
                      options={jobData.edit.status.map((option) => ({
                        span: option.name,
                        value: option.id
                      }))}
                      selectedOption={newJobData.status_id as number}
                      onChange={(value) => setNewJobData({ ...newJobData, status_id: value as number })}
                    /> :
                    <input type="text" defaultValue={newJobData.status} disabled={true} />
                }
              </div>

              <div className="field">
                <span>Source:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', list_sources_id: '' }].concat(jobData.edit.source).map((option) => ({
                    span: option.name,
                    value: option.list_sources_id
                  }))}
                  selectedOption={newJobData.source_id as string}
                  onChange={(value) => setNewJobData({ ...newJobData, source_id: value as string })} />
              </div>

              <div className="field">
                <span>Area:</span>
                <input type="text" defaultValue={jobData?.job[0].area} disabled={true} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Company:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', company_id: '' }].concat(jobData.edit?.companies ? jobData.edit?.companies : []).map((option) => ({
                    span: option.name,
                    value: option.company_id
                  }))}
                  selectedOption={newJobData.company_id as string}
                  onChange={(value) => setNewJobData({ ...newJobData, company_id: value as string })} />
              </div>

              <div className="field">
                <span>Property type:</span>
                <input type="text" defaultValue={jobData?.job[0].property_type} disabled={true} />
              </div>

              <div className="field">
                <span>Service Resource:</span>
                <input
                  type="text"
                  disabled={true}
                  value={
                    jobData?.job[0].service_resources.map((item, index) => (
                      index === 0 ?
                        item.code ? `${item.nickname} (${item.code})` : item.nickname :
                        item.code ? ` ${item.nickname} (${item.code})` : ` ${item.nickname}`
                    ))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Address</div>

          <div className="fields fields-address">
            {
              editing ?
                <Select
                  options={[{ contact_address: '', contact_address_id: '' }].concat(jobData.edit.contact_addresses).map((option) => ({
                    span: option.contact_address,
                    value: option.contact_address_id
                  }))}
                  selectedOption={newJobData.contact_address_id as string}
                  onChange={(value) => setNewJobData({ ...newJobData, contact_address_id: value as string })} /> :
                <input type="text" defaultValue={jobData?.job[0].address} disabled={true} />
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Units</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Unit type</th>
                <th>Unit</th>
                <th>Brand</th>
                <th>Model Number</th>
                <th>Serial Number</th>
                <th>Price</th>
                <th>Description</th>
              </tr>
            </thead>

            {
              jobData.units.map((unit, i) => (
                <tr key={i}>
                  <td> {unit.unit_type} </td>
                  <td> {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance} </td>
                  <td> {unit.brand === 'Other' ? unit.other_brand : unit.brand} </td>
                  <td> {unit.model_number} </td>
                  <td> {unit.serial_number} </td>
                  <td> {unit.price} </td>
                  <td> {unit.description} </td>
                </tr>
              ))
            }
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {
              jobData.units.map((unit, i) => (
                <tr key={i}>
                  <td>
                    <div>Unit type: {unit.unit_type} </div>

                    <div>Unit: {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance} </div>
                  </td>

                  <td>
                    <div>Brand: {unit.brand === 'Other' ? unit.other_brand : unit.brand} </div>

                    <div>Model number: {unit.model_number} </div>
                  </td>

                  <td>
                    <div>Serial number: {unit.serial_number} </div>

                    <div>Price: {unit.price} </div>
                  </td>

                  <td>
                    <div>Description {unit.description} </div>
                  </td>
                </tr>
              ))
            }
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <div className="mobile-table-items">
              {
                jobData.units.map((unit, i) => (
                  <div
                    className="item"
                    key={i}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>Unit type: {unit.unit_type}</div>
                        <div>
                          Unit: {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}
                        </div>
                        <div>
                          Brand: {unit.brand === 'Other' ? unit.other_brand : unit.brand}
                        </div>
                      </div>

                      <div className="__right">
                        <div>Model number: {unit.model_number}</div>
                        <div>Serial number: {unit.serial_number}</div>
                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div>Description: {unit.description} </div>
                      </div>

                      <div className="__right small">
                        <div>Price: {unit.price}</div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Financial</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Total:</span>
                <input type="text" defaultValue={jobData?.job[0].total} disabled={true} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Paid:</span>
                <input type="text" defaultValue={jobData?.job[0].paid} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, jobData?.job[0].created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={jobData?.job[0].created_by} disabled={true} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, jobData?.job[0].updated_at || '')}
                  disabled={true}
                />
              </div>
              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={jobData?.job[0].updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Clients</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Main Phone</th>
                <th>Source</th>
                <th>Jobs</th>
                <th>Appointments</th>
                <th>Recalls</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Unpaid</th>
              </tr>
            </thead>

            {jobData.clients.map((client, i) => (
              <tr
                style={{ cursor: jobData.permissions.client_show && client.client_id ? 'pointer' : 'inherit', position: 'relative' }}
                key={i}
                onClick={() =>
                  jobData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>{client.name}</td>
                <td>{formatPhoneNumber(client.main_phone)}</td>
                <td>{client.source}</td>
                <td>{client.jobs}</td>
                <td>{client.appointments}</td>
                <td>{client.recalls}</td>
                <td>{client.total}</td>
                <td>{client.paid}</td>
                <td>
                  {client.unpaid}
                  {
                    jobData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {jobData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: jobData.permissions.client_show && client.client_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  jobData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>
                  <div>{client.name}</div>
                  <div>{formatPhoneNumber(client.main_phone)}</div>
                </td>
                <td>
                  <div>{client.source}</div>
                  <div>Jobs: {client.jobs}</div>
                </td>
                <td>
                  <div>Appointments: {client.appointments}</div>
                  <div>Recalls: {client.recalls}</div>
                </td>
                <td>
                  <div>Total: {client.total}</div>
                  <div>Paid: {client.paid}</div>
                </td>
                <td>
                  <div>Unpaid: {client.unpaid}</div>
                  {
                    jobData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {jobData.clients.map((client, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    jobData.permissions.client_show &&
                    client.client_id &&
                    $router.router.navigate('clients.item', {
                      companyId: activeAccountId,
                      clientId: client.client_id,
                      fromPage: 'jobs.item',
                      nameId: 'jobId',
                      id: $router.router.getState().params.jobId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}>

                  <div className="__top">
                    <div className="__left">
                      <div><b>{client.name}</b></div>
                      <div>
                        {formatPhoneNumber(client.main_phone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{client.total}</b>
                      </div>
                      <div>
                        Paid: <b>{client.paid}</b>
                      </div>
                      <div>
                        Unpaid: <b>{client.unpaid}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Jobs:</span>
                        <span> {client.jobs}</span>
                      </div>
                      <div>
                        <span className="gray">Recalls:</span>
                        <span> {client.recalls}</span>
                      </div>
                      <div>
                        <span className="gray">Appointments:</span>
                        <span> {client.appointments}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{client.source}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Appointments</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Property type</th>
                <th>Area</th>
                <th>Created by</th>
                <th>Schedule time</th>
                <th>Service resource</th>
              </tr>
            </thead>

            {jobData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ cursor: jobData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  jobData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</td>
                <td>{appointment.name}</td>
                <td>
                  <span className={classNames({
                    red: ['Recall', 'RC'].includes(appointment.type),
                    green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                    blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                  })}
                  >
                    {appointment.type}
                  </span>
                </td>
                <td>
                  <span className={classNames({
                    red: appointment.status === 'Canceled',
                  })}
                  >
                    {appointment.status}
                  </span>
                </td>
                <td>{appointment.property_type}</td>
                <td>{appointment.area}</td>
                <td>{appointment.created_by}</td>
                <td>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</td>
                <td>
                  {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}
                  {
                    jobData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {jobData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ cursor: jobData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  jobData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>
                  <div>{appointment.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</div>
                </td>
                <td>
                  <div>{appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</div>
                  <div>{appointment.created_by}</div>
                </td>
                <td>
                  <div>{appointment.area}</div>
                  <div>
                    <span className={classNames({
                      red: ['Recall', 'RC'].includes(appointment.type),
                      green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                      blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                    })}
                    >
                      {appointment.type}
                    </span>
                  </div>
                </td>
                <td>
                  <div>{appointment.property_type}</div>
                  <div>
                    <span className={classNames({
                      red: appointment.status === 'Canceled',
                    })}
                    >
                      {appointment.status}
                    </span>
                  </div>
                </td>
                <td>
                  <div>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</div>
                  <div>&nbsp;</div>
                  {
                    jobData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {jobData.appointments.map((appointment, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    jobData.permissions.appointment_show &&
                    appointment.appointment_id &&
                    $router.router.navigate('appointments.item', {
                      companyId: activeAccountId,
                      appointmentId: appointment.appointment_id,
                      fromPage: 'jobs.item',
                      nameId: 'jobId',
                      id: $router.router.getState().params.jobId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}>

                  <div className="__top">
                    <div className="__left">
                      <div className="flex-container">
                        <strong>{appointment.name}</strong>
                      </div>
                      <div>
                        {getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        <span className={classNames({
                          red: ['Recall', 'RC'].includes(appointment.type),
                          green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                          blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                        })}
                        >
                          {appointment.type}
                        </span>
                      </div>
                      <div>
                        {appointment.property_type}
                      </div>
                      <div>
                        <div className="fw500">
                          <span className={classNames({
                            red: appointment.status === 'Canceled',
                          })}
                          >
                            {appointment.status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {appointment.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}
                      </div>
                      <div>
                        Created by {appointment.created_by}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Estimates</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th><span>Date</span></th>
                <th><span>Name</span></th>
                <th><span>Status</span></th>
                <th><span>Service Resource</span></th>
                <th><span>Area</span></th>
                <th><span>Total</span></th>
                <th><span>Comment</span></th>
              </tr>
            </thead>

            {jobData.estimates.map((estimate, i) => (
              <tr
                key={i}
                style={{ cursor: jobData.permissions.estimate_show && estimate.estimate_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  jobData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  $router.router.navigate('estimates.item', {
                    companyId: activeAccountId,
                    estimatesId: estimate.estimate_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  handleChangeMenu(event, estimate.estimate_id, 'estimates', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</td>
                <td>{estimate.name}</td>
                <td>{estimate.status}</td>
                <td>{estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</td>
                <td>{estimate.area}</td>
                <td>{estimate.total}</td>
                <td>
                  {estimate.comment}
                  {
                    jobData.permissions.estimate_show &&
                    showContext === `estimates_${estimate.estimate_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('estimates.item', {
                            companyId: activeAccountId,
                            estimatesId: estimate.estimate_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/estimates/${estimate.estimate_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">
              {jobData.estimates.map((estimate, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    jobData.permissions.estimate_show &&
                    estimate.estimate_id &&
                    $router.router.navigate('estimates.item', {
                      companyId: activeAccountId,
                      estimatesId: estimate.estimate_id,
                      fromPage: 'jobs.item',
                      nameId: 'jobId',
                      id: $router.router.getState().params.jobId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>
                        <strong>{estimate.name}</strong>
                      </div>
                      <div>
                        <strong>{estimate.status}</strong>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{estimate.total}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {estimate.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</b>
                      </div>
                    </div>
                  </div>

                  <div className="text">
                    {estimate.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Invoices</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th><span>Date</span></th>
                <th><span>Name</span></th>
                <th><span>Status</span></th>
                <th><span>Service Resource</span></th>
                <th><span>Area</span></th>
                <th><span>Total</span></th>
                <th><span>Paid</span></th>
                <th><span>Unpaid</span></th>
              </tr>
            </thead>

            {jobData.invoices.map((invoice, i) => (
              <tr
                key={i}
                style={{ cursor: jobData.permissions.invoice_show && invoice.invoice_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  jobData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  $router.router.navigate('invoices.item', {
                    companyId: activeAccountId,
                    invoicesId: invoice.invoice_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.invoice_show &&
                  invoice.invoice_id &&
                  handleChangeMenu(event, invoice.invoice_id, 'invoices', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}</td>
                <td>{invoice.name}</td>
                <td>{invoice.status}</td>
                <td>{invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</td>
                <td>{invoice.area}</td>
                <td>{invoice.total}</td>
                <td>{invoice.paid}</td>
                <td>
                  {invoice.unpaid}
                  {
                    jobData.permissions.invoice_show &&
                    showContext === `invoices_${invoice.invoice_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('invoices.item', {
                            companyId: activeAccountId,
                            invoicesId: invoice.invoice_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/invoices/${invoice.invoice_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {jobData.invoices.map((invoice, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    jobData.permissions.invoice_show &&
                    invoice.invoice_id &&
                    $router.router.navigate('invoices.item', {
                      companyId: activeAccountId,
                      invoicesId: invoice.invoice_id,
                      fromPage: 'jobs.item',
                      nameId: 'jobId',
                      id: $router.router.getState().params.jobId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >

                  <div className="__top">
                    <div className="__left">
                      <div><b>{invoice.name}</b></div>
                      <div>
                        {invoice.status}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{invoice.total}</b>
                      </div>
                      <div>
                        Paid: <b>{invoice.paid}</b>
                      </div>
                      <div>
                        Unpaid: <b>{invoice.unpaid}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {invoice.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resource:</span>
                        <span> {invoice.service_resource_code ? `${invoice.service_resource} (${invoice.service_resource_code})` : invoice.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoice.created_at)}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Payments</div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th><span>Date</span></th>
                <th><span>Type</span></th>
                <th><span>Number</span></th>
                <th><span>Service</span></th>
                <th><span>Status</span></th>
                <th><span>Net</span></th>
                <th><span>Total</span></th>
                <th><span>Fee</span></th>
              </tr>
            </thead>

            {jobData.payments.map((payment, i) => (
              <tr
                key={i}
                style={{ cursor: jobData.permissions.payment_show && payment.payment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  jobData.permissions.payment_show &&
                  payment.payment_id &&
                  $router.router.navigate('payments.item', {
                    companyId: activeAccountId,
                    paymentsId: payment.payment_id,
                    fromPage: 'jobs.item',
                    nameId: 'jobId',
                    id: $router.router.getState().params.jobId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  jobData.permissions.payment_show &&
                  payment.payment_id &&
                  handleChangeMenu(event, payment.payment_id, 'payments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</td>
                <td>{payment.type}</td>
                <td>{payment.number}</td>
                <td>{payment.service}</td>
                <td>{payment.status}</td>
                <td>{payment.net}</td>
                <td>{payment.total}</td>
                <td>
                  {payment.fee}
                  {
                    jobData.permissions.payment_show &&
                    showContext === `payments_${payment.payment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('payments.item', {
                            companyId: activeAccountId,
                            paymentsId: payment.payment_id,
                            fromPage: 'jobs.item',
                            nameId: 'jobId',
                            id: $router.router.getState().params.jobId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/payments/${payment.payment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {jobData.payments.map((payment, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    jobData.permissions.payment_show &&
                    payment.payment_id &&
                    $router.router.navigate('payments.item', {
                      companyId: activeAccountId,
                      paymentsId: payment.payment_id,
                      fromPage: 'jobs.item',
                      nameId: 'jobId',
                      id: $router.router.getState().params.jobId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >

                  <div className="__top">
                    <div className="__left">
                      <div><strong>{payment.number}</strong></div>
                      <div>{payment.service}</div>
                      <div>
                        <b>{payment.status}</b>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Net: <b>{payment.net}</b>
                      </div>
                      <div>
                        Total: <b>{payment.total}</b>
                      </div>
                      <div>
                        Fee: <b>{payment.fee}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                jobData.permissions.job_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Job</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {deleting && jobData.permissions.job_delete ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Job
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the job it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default JobsPage_Item
