interface GoogleAuthProps {
  disabled: boolean
  setSendGoogleLogin: (value: boolean) => void
  account_id: string
}

export default function GoogleAuth({ disabled, account_id, setSendGoogleLogin }: GoogleAuthProps) {

  const tabUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? `http://localhost:3000/88888/google_auth/${account_id}` : `https://2022front1.artemiudintsev.com/88888/google_auth/${account_id}`

  return (
    <div className="fields">
      <div className="__left">

        <div className="field">
          <span>Connect:</span>

          <button
            className="_bordered"
            disabled={disabled}
            style={{ width: 'fit-content', border: '1px solid #d0d3da' }}
            onClick={() => {
              setSendGoogleLogin(true)
              window.open(tabUrl, '_blank')
            }}
          >
            Sign in with Google 🚀
          </button>
        </div>
      </div>


      <div className="__right">
      </div>
    </div>
  )
}

