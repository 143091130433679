import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { Transition } from "react-transition-group";
import { useCookies } from "react-cookie";

import Icon from "../../../components/Icon"
import ReportFilters from "../../../components/reports/Filters"
import ReportTableControls from "../../../components/reports/TableControls"
import ReportTableField from "../../../components/reports/TableField"
import AddPhone from "./AddPhone";

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../../funcs";
import { useAppSelector } from "../../../store/hooks"

import { CallCenter_PhoneNumber } from "../../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/report-list.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PhoneNumbersReport
}

type FilterWord = 'type' | 'source' | 'area' | 'company' | 'call_flow' | 'active' | 'call_center'

export interface PhoneNumbersReport {
  interface: {
    filter_words: {
      type: string[],
      source: string[],
      area: string[],
      company: string[],
      call_flow: string[],
      active: string[],
      call_center: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    phone_number_add: boolean
    phone_number_delete: boolean
    phone_number_edit: boolean
    phone_number_report_show: boolean
    phone_number_show: boolean
  },

  edit: {
    types: string[],

    sources: {
      list_sources_id: string
      source: string
    }[],

    companies: {
      company_id: string
      name: string
    }[],

    areas: {
      area: string
      area_id: string
    }[],

    call_flows: {
      call_flow_id: string
      name: string
    }[]

    call_centers: {
      call_center_id: string
      name: string
    }[]
  }

  phone_numbers: CallCenter_PhoneNumber[]
}

function CallCenter_PhoneNumbersPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [btnsActionShow, setBtnsActionShow] = useState(false)
  const [addPhone, setAddPhone] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<PhoneNumbersReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.filter_field ? {
      type: [] as string[],
      source: [] as string[],
      area: [] as string[],
      company: [] as string[],
      call_flow: [] as string[],
      active: [] as string[],
      call_center: [] as string[],
      ...cookiesSetting.call_center_phone_numbers.filter_field,
    } : {
      type: [] as string[],
      source: [] as string[],
      area: [] as string[],
      company: [] as string[],
      call_flow: [] as string[],
      active: [] as string[],
      call_center: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.sort_field ? cookiesSetting.call_center_phone_numbers.sort_field : 'phone',
      direction: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.sort_type ? cookiesSetting.call_center_phone_numbers.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Phone Number',
      value: 'phone'
    }, {
      span: 'Call Center Name',
      value: 'call_center_name'
    }, {
      span: 'Friendly Name',
      value: 'friendly_name'
    }, {
      span: 'Type',
      value: 'type'
    }, {
      span: 'Source',
      value: 'source'
    }, {
      span: 'Area',
      value: 'area'
    }, {
      span: 'Company',
      value: 'company'
    }, {
      span: 'Call Flow',
      value: 'call_flow'
    }, {
      span: 'Available',
      value: 'active'
    }]

  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.filter_field ? {
        type: [] as string[],
        source: [] as string[],
        area: [] as string[],
        company: [] as string[],
        call_flow: [] as string[],
        active: [] as string[],
        call_center: [] as string[],
        ...cookiesSetting.call_center_phone_numbers.filter_field,
      } : {
        type: [] as string[],
        source: [] as string[],
        area: [] as string[],
        company: [] as string[],
        call_flow: [] as string[],
        active: [] as string[],
        call_center: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.sort_field ? cookiesSetting.call_center_phone_numbers.sort_field : 'phone',
        direction: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.sort_type ? cookiesSetting.call_center_phone_numbers.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load clients function
  async function loadClients() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, call_center_phone_numbers: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.limit_rows ? cookiesSetting?.call_center_phone_numbers.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: phoneNumbersData, success, error } } = (await httpClientUpdate.post('/callcenter/phonenumbers/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.call_center_phone_numbers?.limit_rows ? cookiesSetting?.call_center_phone_numbers?.limit_rows : reportsMaxRows,
        ...filterParams,
        filters: activeFilters,
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (phoneNumbersData.permissions.phone_number_report_show) {
          setReportData(phoneNumbersData)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, call_center_phone_numbers: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadClients()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="CallCenter_PhoneNumbersPage_List" >

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="page-header">
          <h1>Phone Numbers</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => {
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.call_center_phone_numbers?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, call_center_phone_numbers: { ...cookiesSetting.call_center_phone_numbers, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.phone_number_add ?
              (
                <div className="SelectComponent">
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => setBtnsActionShow(true)}
                      onFocus={() => setBtnsActionShow(true)}
                      onBlur={() => setBtnsActionShow(false)}
                    >
                      <Icon icon="plus-thin" />
                      <span>Add Phone</span>
                      <Icon icon="phone-1" />
                    </button>
                  </div>

                  <Transition in={btnsActionShow} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                    {(state) => (
                      <div
                        className={classNames("options-list-wrapper", `transition-fade-${state}`)}
                        style={{ zIndex: '10' }}
                      >
                        <button
                          className="_iconed"
                          style={{
                            color: '#8391AD',
                            fontSize: '11pt',
                            fontWeight: 'bold',
                          }}
                          onClick={() => $router.router.navigate('call_center.phoneNumbers.new', {
                            companyId: activeAccountId,
                            localInterface: localInterface,
                          }, { reload: true })}
                        >
                          <span>Add Phone</span>
                        </button>

                        <button
                          className="_iconed"
                          style={{
                            color: '#8391AD',
                            fontSize: '11pt',
                            fontWeight: 'bold',
                          }}
                          onClick={() => {
                            setAddPhone(true)
                            setBtnsActionShow(false)
                          }}
                        >
                          <span>Buy Phone</span>
                        </button>
                      </div>
                    )}
                  </Transition>
                </div>
              ) :
              <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Phone Number</span>)}

                  sortDirection={localInterface.sort.field === 'phone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Call Center</span>)}

                  allFiltersSelected={isAllFiltersSelected("call_center")}
                  onAllFiltersChange={(value) => switchFilter("call_center", "All", value)}

                  filterWords={reportData.interface.filter_words.call_center.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("call_center", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("call_center", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'call_center_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_center_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Friendly Name</span>)}

                  sortDirection={localInterface.sort.field === 'friendly_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'friendly_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Type</span>)}

                  allFiltersSelected={isAllFiltersSelected("type")}
                  onAllFiltersChange={(value) => switchFilter("type", "All", value)}

                  filterWords={reportData.interface.filter_words.type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("type", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Source</span>)}

                  allFiltersSelected={isAllFiltersSelected("source")}
                  onAllFiltersChange={(value) => switchFilter("source", "All", value)}

                  filterWords={reportData.interface.filter_words.source.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("source", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("source", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Area</span>)}

                  allFiltersSelected={isAllFiltersSelected("area")}
                  onAllFiltersChange={(value) => switchFilter("area", "All", value)}

                  filterWords={reportData.interface.filter_words.area.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("area", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("area", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'area' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Company</span>)}

                  allFiltersSelected={isAllFiltersSelected("company")}
                  onAllFiltersChange={(value) => switchFilter("company", "All", value)}

                  filterWords={reportData.interface.filter_words.company.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("company", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("company", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'company' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'company', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Call Flow</span>)}

                  allFiltersSelected={isAllFiltersSelected("call_flow")}
                  onAllFiltersChange={(value) => switchFilter("call_flow", "All", value)}

                  filterWords={reportData.interface.filter_words.call_flow.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("call_flow", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("call_flow", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'call_flow' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_flow', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Available</span>)}

                  allFiltersSelected={isAllFiltersSelected("active")}
                  onAllFiltersChange={(value) => switchFilter("active", "All", value)}

                  filterWords={reportData.interface.filter_words.active.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("active", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("active", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'active' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'active', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.phone_numbers.map((phoneNumber, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.phone_number_show && phoneNumber.phone_number_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.phone_number_show &&
                  phoneNumber.phone_number_id &&
                  $router.router.navigate('call_center.phoneNumbers.item', {
                    companyId: activeAccountId,
                    phoneNumbersId: phoneNumber.phone_number_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.phone_number_show &&
                  phoneNumber.phone_number_id &&
                  handleChangeMenu(event, phoneNumber.phone_number_id, 'phoneNumbers', i)}
              >
                <td>
                  <div className="flex-container">
                    <span>{formatPhoneNumber(phoneNumber.phone)}</span>
                    <button className="_zeroed _iconed">
                      <Icon icon="gear-1" />
                    </button>
                  </div>
                </td>
                <td>{phoneNumber.call_center_name}</td>
                <td>{phoneNumber.friendly_name}</td>

                <td className={classNames({
                  'red-text': phoneNumber.type === 'System',
                  'green-text': phoneNumber.type === 'Source',
                  'blue-text': !['System', 'Source'].includes(phoneNumber.type)
                })}>{phoneNumber.type}</td>

                <td>{phoneNumber.source}</td>
                <td>{phoneNumber.area}</td>
                <td>{phoneNumber.company}</td>
                <td>{phoneNumber.call_flow}</td>

                <td className={classNames({
                  'red-text': phoneNumber.active === 'Off',
                  'green-text': phoneNumber.active === 'On',
                })}>
                  {phoneNumber.active}
                  {
                    reportData.permissions.phone_number_show &&
                    showContext === `phoneNumbers_${phoneNumber.phone_number_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.phoneNumbers.item', {
                            companyId: activeAccountId,
                            phoneNumbersId: phoneNumber.phone_number_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/phoneNumbers/${phoneNumber.phone_number_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.phone_numbers.map((phoneNumber, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.phone_number_show && phoneNumber.phone_number_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.phone_number_show &&
                  phoneNumber.phone_number_id &&
                  $router.router.navigate('call_center.phoneNumbers.item', {
                    companyId: activeAccountId,
                    phoneNumbersId: phoneNumber.phone_number_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.phone_number_show &&
                  phoneNumber.phone_number_id &&
                  handleChangeMenu(event, phoneNumber.phone_number_id, 'phoneNumbers', i)}
              >
                <td>
                  <div>{formatPhoneNumber(phoneNumber.phone)}</div>
                  <div>{phoneNumber.friendly_name}</div>
                </td>
                <td>
                  <div> {phoneNumber.call_center_name} </div>
                </td>
                <td>
                  <div>{phoneNumber.source}</div>
                  <div>{phoneNumber.area}</div>
                </td>
                <td>
                  <div>{phoneNumber.company}</div>
                  <div className={classNames({
                    'red-text': phoneNumber.type === 'System',
                    'green-text': phoneNumber.type === 'Source',
                    'blue-text': !['System', 'Source'].includes(phoneNumber.type)
                  })}>{phoneNumber.type}</div>
                </td>
                <td>
                  <div>{phoneNumber.call_flow}</div>
                  <div className={classNames({
                    'red-text': phoneNumber.active === 'Off',
                    'green-text': phoneNumber.active === 'On',
                  })}>{phoneNumber.active}</div>
                  {
                    reportData.permissions.phone_number_show &&
                    showContext === `phoneNumbers_${phoneNumber.phone_number_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.phoneNumbers.item', {
                            companyId: activeAccountId,
                            phoneNumbersId: phoneNumber.phone_number_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/phoneNumbers/${phoneNumber.phone_number_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            {reportData.phone_numbers.map((phoneNumber, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.phone_number_show &&
                  phoneNumber.phone_number_id &&
                  $router.router.navigate('call_center.phoneNumbers.item', {
                    companyId: activeAccountId,
                    phoneNumbersId: phoneNumber.phone_number_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><b>{formatPhoneNumber(phoneNumber.phone)}</b></div>
                    <div> {phoneNumber.call_center_name} </div>
                    <div>
                      {phoneNumber.friendly_name}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      {phoneNumber.company}
                    </div>
                    <div className={classNames({
                      'red-text': phoneNumber.type === 'System',
                      'green-text': phoneNumber.type === 'Source',
                      'blue-text': !['System', 'Source'].includes(phoneNumber.type)
                    })}>
                      {phoneNumber.type}
                    </div>
                    <div className={classNames({
                      'red-text': phoneNumber.active === 'Off',
                      'green-text': phoneNumber.active === 'On',
                    })}>
                      {phoneNumber.active}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Area:</span>
                      <span> {phoneNumber.area}</span>
                    </div>
                    <div>
                      <span className="gray">Source:</span>
                      <span> {phoneNumber.source}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      <b>{phoneNumber.call_flow}</b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.call_center_phone_numbers?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, call_center_phone_numbers: { ...cookiesSetting.call_center_phone_numbers, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        {
          addPhone &&
          <AddPhone
            setOpen={setAddPhone}
            call_centers={reportData.edit.call_centers}
          />
        }
      </div>
    ) : null}
  </>)
}

export default CallCenter_PhoneNumbersPage_List
