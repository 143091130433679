import { useEffect } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import { useRoute } from 'react-router5';

export default function NewTabForGoogleAuth() {
  const router = useRoute()

  const account_id = router.route.params.accountId

  const googleLoginAuth = useGoogleLogin({
    scope: [
      "https://www.googleapis.com/auth/adwords"
    ].join(" "),
    state: account_id,
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    ux_mode: 'redirect',
    // overrideScope: true,
    include_granted_scopes: false,
    enable_serial_consent: false,
    onSuccess: (tokenResponse) => { },
    onError: () => { },
    flow: 'auth-code', // Use 'auth-code' for the authorization code flow
  });

  useEffect(() => {
    googleLoginAuth()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div></div>
  )
}
