import { useRef } from "react";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import SignatureCanvas from 'react-signature-canvas'
import moment from "moment";

import Icon from "../../components/Icon";

import { dateToInfoBlock } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

import { Invoice } from './InvoiceAdd'

import './PreviewInvoicePdf.sass'

interface PreviewInvoicePdfProps {
  setIsPreview: (value: boolean) => void
  newData: Invoice
  jobAddress: string
}

export default function PreviewInvoicePdf({ setIsPreview, newData, jobAddress }: PreviewInvoicePdfProps) {
  const pdfRef = useRef(null);

  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  async function handleDownloadPdf() {
    if (pdfRef.current) {
      // const element = pdfRef.current;
      // const canvas = await html2canvas(element);
      // const data = canvas.toDataURL('image/png');

      // const pdf = new jsPDF('p', 'pt', 'letter');
      // const imgProperties = pdf.getImageProperties(data);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight =
      //   (imgProperties.height * pdfWidth) / imgProperties.width;

      // pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
      // pdf.save('invoice.pdf');

      html2canvas(pdfRef.current).then((canvas: any) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        doc.save('Invoice.pdf');
      })
    }
  };

  function getTotal() {
    let sum = 0
    newData.data.forEach(item => {
      sum = sum + +item.price
    })
    return sum.toFixed(2)
  }

  return (
    <div className="preview-invoice" >
      <div className="title">
        <h1>Invoice</h1>

        <div className="title-btns">
          {/* download-sms */}
          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            disabled={pdfRef === null}
            onClick={() => handleDownloadPdf()}
          >
            <Icon
              style={{ width: '20px', height: '20px', fill: '#fff' }}
              viewBox="0 0 16 16"
              icon="download-1"
            />
          </button>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => setIsPreview(false)}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>
      </div>

      {/* CONTENT */}
      <div className="content-block">
        <div ref={pdfRef} className="content">
          <div className="header">
            <div className="header-item">
              <div className="logo">LOGO</div>
              <div className="logo-name">
                AA Home Services
              </div>

              <div className="header-name">
                Jane Doe
              </div>

              {/* <div className="header-name">
                1000 Nobel Dr
              </div> */}

              <div className="header-name">
                {jobAddress}
              </div>

              <div className="header-phone">
                {/* <Icon icon="phone-1" /> */}
                <span>
                  &#x1F4DE;
                </span>
                (555) 123-4567
              </div>
            </div>

            <div className="header-item">
              <div className="header-detail">
                <div className="header-detail-content">
                  <div className="header-detail-row">
                    <span className="header-detail-title">Ivoice</span>
                    <span>#1953</span>
                  </div>
                  <div className="header-detail-row">
                    <span className="header-detail-title">Service date</span>
                    <span>Date</span>
                  </div>
                  <div className="header-detail-row">
                    <span className="header-detail-title">Ivoice date</span>
                    <span>Date</span>
                  </div>
                  <div className="header-detail-row">
                    <span style={{ marginBottom: '0' }} className="header-detail-title">Due</span>
                    <span>Upon reject</span>
                  </div>
                </div>

                <div className="header-detail-result">
                  <div className="header-detail-row">
                    <span style={{ marginBottom: '0' }} className="header-detail-title">Due</span>
                    <span className="header-detail-price">$ 0.00</span>
                  </div>
                </div>
              </div>

              <div className="header-service-address">
                <div className="header-title-block">
                  Service address
                  <span style={{ marginTop: '5px' }}></span>
                </div>

                <div className="header-name">
                  {jobAddress}
                </div>

                {/* <div className="header-name">
                  San Diego, 92121
                </div> */}
              </div>

              <div className="header-contact">
                <div className="header-title-block">
                  Contact us
                  <span style={{ marginTop: '5px' }}></span>
                </div>

                <div className="header-name">
                  9808 Scranton Rd, Suite 3000
                </div>

                <div className="header-name">
                  San Diego, 92121
                </div>
              </div>

              <div className="header-contact-phone">
                <span>
                  &#x1F4DE;
                </span>
                {/* <Icon icon="phone-1" /> */}
                (555) 123-4567
              </div>

              <div className="header-contact-phone">
                {/* <Icon icon="email-1" /> */}
                <span style={{ fontSize: '22px' }}>
                  &#x2709;
                </span>
                VYakushin103@yandex.ru
              </div>

              <div className="header-footer">
                Service completed by: Anjel Amor
              </div>
            </div>
          </div>

          <div className="main">
            <div className="main-title">
              Invoice
            </div>

            <table>
              <thead>
                <tr>
                  <th>Services</th>
                  <th>type</th>
                  <th>unit price</th>
                  <th>amount</th>
                </tr>
              </thead>

              <tbody>
                {
                  newData.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.type} </td>
                      <td> {item.price} </td>
                      <td> {item.price}</td>
                    </tr>
                  ))
                }
              </tbody>

              <tfoot>
                <tr>
                  <th scope="row">Totals</th>
                  <td></td>
                  <td></td>
                  <td className="price">${getTotal()}</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="footer">
            <div className="signature">
              <div>
                <SignatureCanvas penColor='#646464'
                  canvasProps={{ width: 200, height: 80, className: 'sigCanvas' }} />
              </div>
              <span>
                {user?.first_name}{' '}{user?.last_name} {' '}
                {dateToInfoBlock('MM/dd/yyyy', timeZone, moment().toISOString())}
              </span>
            </div>
            <div className="footer-head">
              Thank you for trusting AA Home Services for your repair needs today
              <span style={{ marginTop: '5px' }}></span>
            </div>

            <div className="footer-end">
              <span>AA Home Services</span>
              <span>https://www.housecallpro.com/</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
