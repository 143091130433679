import { ReactElement } from "react";
import classNames from "classnames"

import Icon from "../Icon"
import Select from "../Select"
import SortSelectComponent from "../SortSelect"

import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setReportsMaxRows } from '../../store/reducer'

import "../../styles/components/reports/table-controls.sass"

interface Props {
  isUnder?: boolean,

  cookie_reportsMaxRows?: number

  onMaxRowsChange(value?: number): void,

  amount: {

    total: number,
    start: number,
    end: number
  },

  page: number,
  maxPages: number,
  onPagesStart(): void,
  onNextPage(): void,
  onPrevPage(): void,
  onPagesEnd(): void,

  sort: {

    field: string,
    direction: 'up' | 'down'
  },
  sortFields: {
    span: string,
    value: string
  }[],
  onSortFieldChange(value: string): void,
  onSortDirectionChange(value: 'up' | 'down'): void,
  onSortFire(): void,

  zIndex?: number,

  addButton?: ReactElement

  isSort?: boolean
}

function ReportTableControls({
  isUnder,
  onMaxRowsChange,
  amount,
  page,
  maxPages,
  onPagesStart,
  onNextPage,
  onPrevPage,
  onPagesEnd,
  sort,
  sortFields,
  onSortFieldChange,
  onSortDirectionChange,
  onSortFire,
  zIndex,
  addButton,
  isSort,
  cookie_reportsMaxRows,
}: Props) {
  const dispatch = useAppDispatch()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  return (
    <div className={classNames("ReportTableControls", {
      _under: isUnder,
      '__hide-on-mobile': isUnder,
    }, { __respectAsidePanel: navActive.is }
    )}>

      {addButton ? (
        <div className={classNames('__add-button', '__hide-on-wide', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>
          {addButton}
        </div>
      ) : null}

      <div className="table-controls" style={{ zIndex: zIndex }}>
        <div className="hint-text">
          Show rows:
        </div>
        <Select zeroed={true} options={[{
          span: "50",
          value: 50
        }, {
          span: "100",
          value: 100
        }, {
          span: "250",
          value: 250
        }, {
          span: "500",
          value: 500
        }]}
          selectedOption={cookie_reportsMaxRows ? cookie_reportsMaxRows : reportsMaxRows}
          onChange={async (value: number) => {

            await dispatch(setReportsMaxRows(value));
            onMaxRowsChange();
          }} />

        <div
          className={classNames('amount', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}
        >
          {amount.start}-{amount.end} of {amount.total}
        </div>

        <div
          className={classNames('table-pages-nav', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}
        >
          <button className="_zeroed _iconed" disabled={page === 1} onClick={() => onPagesStart()}>
            <Icon className="_rotated-180" icon="arrow-31" />
          </button>
          <button className="_zeroed _iconed" disabled={page <= 1} onClick={() => onPrevPage()}>
            <Icon className="_rotated-180" icon="arrow-25" />
          </button>
          <button className="_zeroed _iconed" disabled={page >= maxPages} onClick={() => onNextPage()}>
            <Icon icon="arrow-25" />
          </button>
          <button className="_zeroed _iconed" disabled={page >= maxPages} onClick={() => onPagesEnd()}>
            <Icon icon="arrow-31" />
          </button>
        </div>

        {addButton ? (
          <div className={classNames('__add-button', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            {addButton}
          </div>
        ) : null}

        {
          !isSort &&
          <div className={classNames('sort', '__hide-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <span>Sort:</span>
            <SortSelectComponent
              options={sortFields}
              selectedOption={sort.field}
              onValueChange={onSortFieldChange}
              zeroed={true}
              selectedDirection={sort.direction}
              onDirectionChange={onSortDirectionChange}
              onFire={onSortFire}
            />
          </div>
        }
      </div>

      <div className={classNames('table-controls-bottom-panel', '__show-on-mobile', {
        __respectAsidePanel: navActive.is && !phoneCall,
        __phoneCall: phoneCall && !navActive.is,
        __bothOpen: navActive.is && phoneCall,
        __nonePanel: !navActive.is && !phoneCall
      })}>
        <div className="results-amount">
          {amount.start}-{amount.end} of {amount.total}
        </div>
        <div className="pages-nav">
          <button className="_zeroed _iconed" disabled={page === 1} onClick={() => onPagesStart()}>
            <Icon className="_rotated-180" icon="arrow-31" />
          </button>
          <button className="_zeroed _iconed" disabled={page <= 1} onClick={() => onPrevPage()}>
            <Icon className="_rotated-180" icon="arrow-25" />
          </button>
          <button className="_zeroed _iconed" disabled={page >= maxPages} onClick={() => onNextPage()}>
            <Icon icon="arrow-25" />
          </button>
          <button className="_zeroed _iconed" disabled={page >= maxPages} onClick={() => onPagesEnd()}>
            <Icon icon="arrow-31" />
          </button>
        </div>
      </div>
    </div >
  )
}

export default ReportTableControls
