import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { BaseLink, useRoute } from 'react-router5'
import { DateTime } from "luxon"
import DateTimeComponent from "react-datetime"
import moment from 'moment'

import Icon from '../../../components/Icon'
import SelectComponent from '../../../components/Select'

import { useAppSelector } from '../../../store/hooks'
import { httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { DispatchersProps, HttpReport } from './List'

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [dispatchersOptions, setDispatchersOptions] = useState<DispatchersProps[]>([])
  const [isSaving, setIsSaving] = useState(false)

  const [newData, setNewData] = useState({
    additional_time_start: DateTime.now().plus({ hours: 0 }).set({ minute: 0 }).toJSDate(),
    additional_time_end: DateTime.now().plus({ hours: 1 }).set({ minute: 0 }).toJSDate(),
    dispatcher_id: '',
    area_name: '',
  })

  function handleChangeDispatcher(id: string) {
    dispatchersOptions.forEach(item => {
      if (item.dispatcher_id === id) {
        setNewData({
          ...newData,
          dispatcher_id: id,
        })
      }
    })
  }

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadData() {

    try {

      // https://2022back4.artemiudintsev.com/api/dispatchers/additional-times/report?account_id=88888&page=1&limit_rows=100
      const { data: { data: responseData, success, error } } = (await httpClientUpdate.get('/dispatchers/additional-times/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
        }
      })) as { data: HttpReport }
      if (success) {
        setDispatchersOptions(responseData.edit.dispatchers)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    setIsSaving(true)
    try {
      let start = moment(newData.additional_time_start).format('MM/DD/YYYY hh:mma')
      let end = moment(newData.additional_time_end).format('MM/DD/YYYY hh:mma')

      // https://2022back4.artemiudintsev.com/api/dispatchers/additional-times
      const response = await httpClientUpdate.post(`/dispatchers/additional-times`, {
        account_id: activeAccountId,
        dispatcher_id: newData.dispatcher_id,
        additional_time_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, start),
        additional_time_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, end),
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.additionalTime', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="CallCenter_CallGroupsPage_New entity-edit">

      {/* Top navigation */}
      <div className="top-nav">
        <BaseLink
          router={$router.router}
          routeName="call_center.phoneNumbers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
        >
          <Icon icon="phone-1" />
          <span>Phone Numbers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.dispatchers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
        >
          <Icon icon="user-1" />
          <span>Dispatchers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.additionalTime"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="time-22" />
          <span>Additional Time</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.absence"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.absence" })}
        >
          <Icon icon="time-16" />
          <span>Absence</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.groups" })}
        >
          <Icon icon="user-29" />
          <span>Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callFlows"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
        >
          <Icon icon="share-7" />
          <span>Call Flows</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.caller_groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
        >
          <Icon icon="user-29" />
          <span>Caller Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.temporaryClosed"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="close-664" />
          <span>Temporary Closed</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callCenters"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
        >
          <Icon icon="delivery-8" />
          <span>Call Centers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.audioLibrary"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="audio-15" />
          <span>Audio Library</span>
        </BaseLink>
      </div>

      <div className="wrapper flex-container sb">
        <h1>New Dispatcher Additional Time</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('call_center.additionalTime', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Dispatcher:</span>

              <SelectComponent
                options={dispatchersOptions.map((option) => ({
                  span: option.code ? `${option.nickname} (${option.code})` : option.nickname,
                  value: option.dispatcher_id
                }))}
                selectedOption={newData.dispatcher_id}
                onChange={(value) => handleChangeDispatcher(value as string)}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Start:</span>
              <DateTimeComponent
                value={newData.additional_time_start as Date}
                onChange={(value) => setNewData({ ...newData, additional_time_start: moment.isMoment(value) ? value.toDate() : newData.additional_time_start })}
              />
            </div>

            <div className="field">
              <span>End:</span>
              <DateTimeComponent
                value={newData.additional_time_end as Date}
                onChange={(value) => setNewData({ ...newData, additional_time_end: moment.isMoment(value) ? value.toDate() : newData.additional_time_end })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button className="_bordered _red" onClick={() => $router.router.navigate('call_center.additionalTime', {
            companyId: activeAccountId
          }, {
            reload: true
          })}>
            Cancel
          </button>
          <button
            disabled={isSaving || newData.dispatcher_id === ''}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
