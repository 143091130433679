import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import Select from '../../components/Select'
import Icon from '../../components/Icon'

import { useAppSelector } from "../../store/hooks"
import { dateToInfoBlock, updatedDateToReqServer, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ResponseData
}

interface ResponseData {
  appointment: Appointment
  edit: {
    appointment_status: {
      appointment_status: string
      appointment_status_id: number
    }[]
  }
  permissions: {
    technician_appointment_delete: boolean
    technician_appointment_edit: boolean
  }
}

interface Appointment {
  appointment_start: string
  appointment_finish: string
  appointment_id: string
  appointment_name: string
  appointment_status: string
  appointment_status_id: number
  contact_id: string
  job_id: string
  service_resource: string
  service_resource_code: number
  service_resource_id: string
  date: string
  appointment_type: string
  time_zone: string
}

interface AppointmentEditProps {
  switchPage: (name: string) => void
  edit: {
    appointment_status: string
    appointment_status_id: number
  }[]
  id: string
}

export default function AppointmentEdit({ switchPage, edit, id }: AppointmentEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [editing, setEditing] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [data, setData] = useState<ResponseData | null>(null)

  const [newData, setNewData] = useState<Partial<Appointment>>({})

  async function loadAppointment() {
    try {
      const { data: { data: appointmentData, success, error } } = await httpClientUpdate.get('/technician/appointments/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        let dateStart = dateToInfoBlock('hh:mma', appointmentData.appointment.time_zone, appointmentData.appointment.appointment_start as string)
        let dateEnd = dateToInfoBlock('hh:mma', appointmentData.appointment.time_zone, appointmentData.appointment.appointment_finish as string)
        let day = dateToInfoBlock('MM/dd/yyyy', appointmentData.appointment.time_zone, appointmentData.appointment.appointment_start as string)

        setData(appointmentData)
        setNewData({
          ...appointmentData.appointment,
          appointment_start: dateStart,
          appointment_finish: dateEnd,
          date: day
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function handleCancelEdit() {
    if (data) {
      let dateStart = dateToInfoBlock('hh:mma', data.appointment.time_zone, data.appointment.appointment_start as string)
      let dateEnd = dateToInfoBlock('hh:mma', data.appointment.time_zone, data.appointment.appointment_finish as string)
      let day = dateToInfoBlock('MM/dd/yyyy', data.appointment.time_zone, data.appointment.appointment_start as string)

      data && setNewData({
        ...data.appointment,
        appointment_start: dateStart,
        appointment_finish: dateEnd,
        date: day
      })
      setEditing(false)
    }
  }

  async function handleSave() {
    const response = await httpClientUpdate.put(`/technician/appointments/${id}`, qs.stringify({
      account_id: activeAccountId,
      appointment_finish: `${newData.date} ${updatedDateToReqServer('MM/dd/yyyy hh:mma', newData?.time_zone, newData.appointment_finish as string)}`,
      appointment_id: newData.appointment_id,
      appointment_start: `${newData.date} ${updatedDateToReqServer('MM/dd/yyyy hh:mma', newData?.time_zone, newData.appointment_start as string)}`,
      appointment_status_id: newData.appointment_status_id,
      contact_id: newData.contact_id,
      job_id: newData.job_id, newData,
      service_resource_id: newData.service_resource_id,
      appointment_type: newData.appointment_type

    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/appointments/${id}`, {
      data: {
        account_id: activeAccountId,
      }
    })
    if (response.data.success) {
      switchPage('main')
    }
  }

  useEffect(() => {
    loadAppointment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])


  return (
    <>
      {
        data ?
          <div className="EstimatePage_Edit entity-edit">
            <div className="wrapper flex-container sb">
              <div className="flex-container _gap-narrow">
                <h1>Appointment:</h1>
                <div className="item-name">
                  {newData.appointment_name}
                </div>
              </div>

              <div style={{ display: 'flex' }} >
                <button
                  disabled={editing}
                  style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  onClick={() => switchPage('main')}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>

                {
                  !editing &&
                    data.permissions.technician_appointment_edit ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      <Icon
                        style={{ width: '16px', height: '16px' }}
                        viewBox="0 0 16 16"
                        icon="pencil-1"
                      />
                    </button>
                  ) : null
                }
              </div>
            </div>

            <div className="fieldset">
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Service Resource:</span>
                    <input
                      type="text"
                      defaultValue={newData.service_resource_code ? `${newData.service_resource}(${newData.service_resource_code})` : newData.service_resource}
                      disabled={true}
                      onChange={({ target: { value } }) => setNewData({ ...newData, service_resource: value })}
                    />
                  </div>

                  <div className="field">
                    <span>Status:</span>
                    <Select
                      disabled={!editing}
                      options={data.edit.appointment_status.map((option) => ({
                        span: option.appointment_status,
                        value: option.appointment_status_id
                      }))}
                      selectedOption={newData.appointment_status_id as number}
                      onChange={(value) => setNewData({ ...newData, appointment_status_id: value as number })}
                    />
                  </div>

                  <div className="field">
                    <span>Type:</span>

                    <Select
                      disabled={!editing}
                      options={[{ value: 'SC', span: 'Service Call' }, { value: 'FU', span: 'Follow Up' }, { value: 'RC', span: 'Recall' }].map((type) => ({
                        span: type.span,
                        value: type.value
                      }))}
                      selectedOption={newData.appointment_type as string}
                      onChange={(value) => setNewData({ ...newData, appointment_type: value as string })}
                    />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>From:</span>

                    <Datetime
                      dateFormat={false}
                      timeFormat="hh:mma"
                      inputProps={{ disabled: !editing }}
                      value={newData.appointment_start}
                      onChange={(value) => setNewData({ ...newData, appointment_start: value as string })}
                    />
                  </div>

                  <div className="field">
                    <span>To:</span>

                    <Datetime
                      dateFormat={false}
                      timeFormat="hh:mma"
                      inputProps={{ disabled: !editing }}
                      value={newData.appointment_finish}
                      onChange={(value) => setNewData({ ...newData, appointment_finish: value as string })}
                    />
                  </div>

                  <div className='field'>
                    <span>Date:</span>

                    <Datetime
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      inputProps={{ disabled: !editing }}
                      value={newData.date}
                      onChange={(value) => setNewData({ ...newData, date: value as string })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.technician_appointment_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Appointment</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Appointment
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the appointment it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div> : null
      }
    </>
  )
}
