import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import qs from "qs";
import classNames from "classnames";
import moment from "moment";

import Select from "../../components/Select"
import Icon from "../../components/Icon";

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { DepositReport } from './List'
import { Deposit } from '../../models/Deposite'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DepositReport
}

interface PaymentsProps {
  payment_id: string,
  date: string,
  type: string,
  number: string,
  service: string,
  status: string,
  net: number,
  total: number,
  fee: number,
  check?: boolean,
}

interface EditProps {
  status: {
    id: number
    name: string
  }[],
  service: {
    name: string
    payment_services_id: string
  }[]
}

const paymentsData = [
  {
    payment_id: '1',
    date: '01/11/2023',
    type: 'complite',
    number: '123',
    service: 'Service',
    status: 'Status',
    net: 300,
    total: 310,
    fee: 10
  },
  {
    payment_id: '2',
    date: '01/21/2023',
    type: 'complite',
    number: '456',
    service: 'Service',
    status: 'Status',
    net: 500,
    total: 520,
    fee: 20
  },
  {
    payment_id: '3',
    date: '01/25/2023',
    type: 'complite',
    number: '789',
    service: 'Service',
    status: 'Status',
    net: 1300,
    total: 1343,
    fee: 43
  }
]

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [payments, setPayments] = useState<PaymentsProps[] | []>([])
  const [depositEdit, setDepositEdit] = useState<EditProps>({
    status: [],
    service: []
  })
  const [newDepositData, setNewDepositData] = useState<Partial<Deposit>>({
    bank_date: new Date(),
    status: '',
    type: '',
    service: '',
    service_id: ''
  })

  // save document
  async function handleSave() {
    // let sourceid = clientDataEdit && clientDataEdit?.source.filter(item => item.name === newClientData?.source)
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.post(`/deposits`, qs.stringify({
        account_id: activeAccountId,
      }))
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('deposits', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: depositsData, success, error } } = (await httpClientUpdate.post('/deposits/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        setDepositEdit(depositsData.edit)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load client data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newDepositData
  ])

  function handleDownloadPayments() {
    let download = paymentsData.map(item => {
      return {
        ...item,
        check: false,
      }
    })
    setPayments(download)
  }

  function getSum(name: string) {
    let sum = 0
    payments.length && payments.forEach(item => sum = sum + item[name])
    return sum
  }

  function handleChangeChecbox(id: string, name: string) {
    let updatedPayments = payments.map(item => {
      if (item.payment_id === id) {
        return {
          ...item,
          [name]: !item[name]
        }
      } else {
        return item
      }
    })
    setPayments(updatedPayments)
  }

  function handleRemovePayment(id: string) {
    let updatedPayments = payments.filter(item => item.payment_id !== id)
    setPayments(updatedPayments)
  }

  return (
    <div className="PayoutPage_Item PayoutPage_New entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Deposit</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('deposits', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Bank Date:</span>
              <Datetime
                value={newDepositData.bank_date}
                onChange={(value) => setNewDepositData({ ...newDepositData, bank_date: moment.isMoment(value) ? value.toDate() : new Date() })}
              />
            </div>

            <div className="field">
              <span>Status:</span>
              {
                depositEdit?.status.length ?
                  <Select
                    options={depositEdit.status.map((option) => ({
                      span: option.name,
                      value: option.name
                    }))}
                    selectedOption={newDepositData.status as string}
                    onChange={(value) => setNewDepositData({ ...newDepositData, status: value as string })}
                  /> :
                  <input
                    type="text"
                    defaultValue={newDepositData.status}
                    disabled={true}
                  />
              }
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Type:</span>
              <input
                type="text"
                value={newDepositData.type}
                onChange={(event) => setNewDepositData({
                  ...newDepositData, type: event.target.value
                })}
              />
            </div>

            <div className="field">
              <span>Service:</span>
              <Select
                options={depositEdit.service.map((option) => ({
                  span: option.name,
                  value: option.payment_services_id
                }))}
                selectedOption={newDepositData.service_id as string}
                onChange={(value) => setNewDepositData({ ...newDepositData, service_id: value as string })}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Payments */}
      <div className="fieldset">
        <div style={{ display: 'flex', alignItems: 'center' }} className="legend">
          Payments
          <span
            onClick={() => handleDownloadPayments()}
          >
            <Icon
              viewBox="0 0 16 16"
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
              icon="download-1"
            />
          </span>
        </div>

        <table className={classNames('table', '__show-on-wide', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Number</th>
              <th>Service</th>
              <th>Status</th>
              <th>Net</th>
              <th>Total</th>
              <th>Fee</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          {payments && payments.map((payment, i) => (
            <tr
              key={i}
            // onClick={() => $router.router.navigate('deposits.item', {
            //   companyId: activeAccountId, depositId: deposit.deposit_id
            // }, { reload: true })}
            >
              <td>{payment.date}</td>
              <td>{payment.type}</td>
              <td>{payment.number}</td>
              <td>{payment.service}</td>
              <td>{payment.status}</td>
              <td>${payment.net}</td>
              <td>${payment.total}</td>
              <td>${payment.fee}</td>
              <td
                onClick={() => handleChangeChecbox(payment.payment_id, 'check')}
              >
                <Icon
                  style={{ width: '20px', height: '20px', fill: payment.check ? '#01B41F' : '#C3C6CB' }}
                  icon="check-mark-1"
                />
              </td>
              <td
                onClick={() => handleRemovePayment(payment.payment_id)}
              >
                <Icon
                  style={{ width: '20px', height: '20px', fill: "#FF0000" }}
                  icon="x-mark-1"
                />
              </td>
            </tr>
          ))}

          {
            !!payments.length &&
            <tr>
              <td>In total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td> ${getSum('net')} </td>
              <td> ${getSum('total')} </td>
              <td> ${getSum('fee')} </td>

              <td></td>
              <td></td>
            </tr>
          }
          {
            !!payments.length &&
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td> </td>
              <td> </td>
              <td> Payments: 18 </td>
              <td> Net: $3500.34 </td>
              <td> Fee: $23.11 </td>
              <td></td>
              <td></td>
            </tr>
          }

        </table>

        <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>
          <tr></tr>
          {payments.map((payment, i) => (
            <tr
              key={i}
            // onClick={() => $router.router.navigate('deposits.item', {
            //   companyId: activeAccountId, depositId: deposit.deposit_id
            // }, { reload: true })}
            >
              <td>
                <div>{payment.date}</div>
                <div>{payment.type}</div>
              </td>
              <td>
                <div>{payment.number}</div>
                <div>{payment.service}</div>
              </td>
              <td>
                <div>{payment.status}</div>
              </td>
              <td>
                <div>Net: ${payment.net}</div>
                <div>Total: ${payment.total}</div>
                <div>Fee: ${payment.fee}</div>
              </td>
              <td>
                <div
                  onClick={() => handleChangeChecbox(payment.payment_id, 'check')}
                >
                  Check:
                  <Icon
                    style={{ width: '20px', height: '20px', fill: payment.check ? '#01B41F' : '#C3C6CB' }}
                    icon="check-mark-1"
                  />
                </div>
                <div
                  onClick={() => handleRemovePayment(payment.payment_id)}
                >
                  <Icon
                    style={{ width: '20px', height: '20px', fill: "#FF0000" }}
                    icon="x-mark-1"
                  />
                </div>
              </td>
            </tr>
          ))}

          {
            !!payments.length &&
            <tr>
              <td>In total</td>

              <td> </td>
              <td>Net: ${getSum('net')} </td>
              <td>Totla: ${getSum('total')} </td>
              <td>Fee: ${getSum('fee')} </td>
            </tr>
          }
          {
            !!payments.length &&
            <tr>
              <td></td>
              <td></td>
              <td> Payments: 18 </td>
              <td> Net: $3500.34 </td>
              <td> Fee: $23.11 </td>
            </tr>
          }
        </table>

        <div className={classNames('mobile-table', '__show-on-mobile', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })}>

          <div className="mobile-table-items">

            {payments.map((payment, i: number) => (
              <div
                className="item"
                key={i}
              //  onClick={() => $router.router.navigate('deposits.item', {
              //   companyId: activeAccountId, depositId: deposit.deposit_id
              // }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><strong>{payment.type}</strong> ({payment.date}) </div>
                    <div>
                      {payment.number}
                    </div>
                    <div>
                      {payment.service}
                    </div>
                    <div>
                      {payment.status}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Net: <b>${payment.net}</b>
                    </div>
                    <div>
                      Total: <b>${payment.total}</b>
                    </div>
                    <div>
                      Fee: <b>${payment.fee}</b>
                    </div>
                  </div>
                </div>

                <div className="__bottom">

                  <div
                    className="__left"
                    onClick={() => handleChangeChecbox(payment.payment_id, 'check')}
                  >
                    <Icon
                      style={{ width: '20px', height: '20px', fill: payment.check ? '#01B41F' : '#C3C6CB' }}
                      icon="check-mark-1"
                    />
                  </div>

                  <div
                    className="__right small"
                    onClick={() => handleRemovePayment(payment.payment_id)}
                  >
                    <Icon
                      style={{ width: '20px', height: '20px', fill: "#FF0000" }}
                      icon="x-mark-1"
                    />
                  </div>
                </div>
              </div>
            ))}
            {
              !!payments.length &&
              <div
                className="item"
              //  onClick={() => $router.router.navigate('deposits.item', {
              //   companyId: activeAccountId, depositId: deposit.deposit_id
              // }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div>
                      Net: ${getSum('net')}
                    </div>
                    <div>
                      Total: ${getSum('total')}
                    </div>
                    <div>
                      Fee: ${getSum('fee')}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Payments: <b>18</b>
                    </div>
                    <div>
                      Net: <b>$3500.34</b>
                    </div>
                    <div>
                      Fee: <b>$23.11</b>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('deposits', {
              companyId: activeAccountId,
            }, { reload: true })}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave || isSaving}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
