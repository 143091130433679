import { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
} from "use-places-autocomplete";

import useOnclickOutside from "react-cool-onclickoutside";

const addressOptions = {
  street_number: {
    value: 'street_number',
    getValue: 'short_name'
  },
  route: {
    value: 'address_street',
    getValue: 'short_name'
  },
  locality: {
    value: 'address_city',
    getValue: 'long_name'
  },
  administrative_area_level_1: {
    value: 'address_state',
    getValue: 'short_name'
  },
  postal_code: {
    value: 'address_zip',
    getValue: 'short_name'
  },
}

export interface AddressProps {
  address_zip: string
  address_street: string
  address_appatment: string
  address_city: string
  address_state: string
  address_arr: string
  address_note: string
  street_number: string
  address_id?: string
  apt?: string
}

interface AddressAutocompleteProps {
  addNewCity: AddressProps
  setAddNewCity: (value: AddressProps) => void
  clearAutoComplite: number
  setIsDifferentZip?: (value: boolean) => void
  setChangedZipCode?: (value: boolean) => void
  zipCheck?: string
}

export default function AddressAutocomplete({
  addNewCity,
  setAddNewCity,
  clearAutoComplite,
  setIsDifferentZip,
  setChangedZipCode,
  zipCheck,
}: AddressAutocompleteProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  useEffect(() => {
    setValue('')
    // clearSuggestions()
  }, [clearAutoComplite, setValue])

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any) =>
      () => {
        setValue(description, false);
        clearSuggestions();

        getGeocode({ address: description }).then((results) => {
          let updateAddress = { ...addNewCity }
          results && results[0].address_components.forEach(item => {

            let addressType = item.types[0]

            if (addressOptions[addressType]) {
              updateAddress[addressOptions[addressType].value] = item[addressOptions[addressType].getValue]
            }
          })
          if (zipCheck && zipCheck !== '' && updateAddress.address_zip !== zipCheck) {
            setIsDifferentZip && setIsDifferentZip(true)
            setChangedZipCode && setChangedZipCode(true)
          }

          setAddNewCity(updateAddress)
        });
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <input
        className='data-border call-contact_new-addresses_add-input street'
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter your address"
      />
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  )
}
