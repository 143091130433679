import { memo, useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import moment, { Moment } from 'moment'
import classNames from "classnames"

import Icon from "../../components/Icon"
import DateRangeCalendar from './DateRangeCalendarForTech'

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { setSelectedDayForTech } from '../../store/reducer'
import { DayOfWeekTechCalendar } from '../../constans'
import { useOuterClick, httpClientUpdate, nErrorUpdate, dateToInfoBlock } from "../../funcs";

import '../../styles/pages/appointmentCalendar.sass'
import '../../styles/pages/common/redirect-menu.sass'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

let calendarArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

interface HttpAppointmentReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CalendarData
}

interface HttpDotsReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DotsProps[]
}

interface CalendarData {
  permissions: string[] | []
  events: {
    event_id: string
    event_type: string
    from: string
    to: string
    time_zone: string
    appointment?: {
      status: string
      name: string
      client: string
      address: string
      type: string
      units: {
        appliance: string
        appliance_brand: string
        appliance_description: string
        appliance_type: string
      }[]
    }
  }[]
}

export interface AppointmentsProps {
  event_id: string
  event_type: string
  from: string
  to: string
  time_zone: string
  appointment?: {
    status: string
    name: string
    client: string
    address: string
    type: string
    units: {
      appliance: string
      appliance_brand: string
      appliance_description: string
      appliance_type: string
    }[]
  }
}

interface AbsencesProps {
  event_id: string
  event_type: string
  from: string
  to: string
  time_zone: string
}

interface DotsProps {
  absence: boolean
  appointment: boolean
  date: string
  week_day: string
}

function AppointmentCalendarPage({ updated }: { updated: number }) {
  const dispatch = useAppDispatch()
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const selectedDayForTech = useAppSelector((store) => store.selectedDayForTech)

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [showContext, setShowContext] = useState('')

  const [startDayWeek, setStartDayWeek] = useState(moment(selectedDayForTech).startOf('isoWeek'))
  const [calendar, setCalendar] = useState<Moment[]>(calendarArr.map((item, index) => moment(selectedDayForTech).startOf('isoWeek').clone().add(index, 'days')))
  const [dateRangeCalendarShown, setDateRangeCalendarShown] = useState(false)

  const [content, setContent] = useState<AppointmentsProps[] | []>([])
  const [absences, setAbsences] = useState<AbsencesProps[] | []>([])
  const [dots, setDots] = useState<DotsProps[] | []>([])

  // load Appointments
  async function loadAppointments() {
    try {
      const { data: { data: dateCalendar, success, error } } = await httpClientUpdate.get('/technician/events', {
        params: {
          account_id: activeAccountId,
          date: selectedDayForTech.format("YYYY-MM-DD")
        }
      }) as { data: HttpAppointmentReport }
      if (success) {
        let appointment = dateCalendar.events.filter(item => item.event_type === "appointment")
        let absence = dateCalendar.events.filter(item => item.event_type === "absence")

        setContent(appointment)
        setAbsences(absence)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // load dots for calendar
  async function loadDots(day = selectedDayForTech) {
    try {
      const { data: { data: dateDots, success, error } } = await httpClientUpdate.get('/technician/calendar', {
        params: {
          account_id: activeAccountId,
          date: day.format("YYYY-MM-DD"),
          type: 'two_weeks'
        }
      }) as { data: HttpDotsReport }
      if (success) {
        setDots(dateDots)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadAppointments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDayForTech])

  useEffect(() => {
    loadDots(startDayWeek)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar])

  useEffect(() => {
    updated && loadAppointments()
    updated && loadDots(startDayWeek)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  function handleSelectDay(numberDay: Moment) {
    dispatch(setSelectedDayForTech(moment(numberDay)))
  }

  function handleCalendarNavPrev() {
    let updateStartDay = startDayWeek.clone().subtract(7, 'days')

    setStartDayWeek(startDayWeek.subtract(7, 'days'))
    setCalendar(calendar.map((item, index) => updateStartDay.clone().add(index, 'days')))
  }

  function handleCalendarNavNext() {
    let updateStartDay = startDayWeek.clone().add(7, 'days')
    setStartDayWeek(startDayWeek.add(7, 'days'))
    setCalendar(calendar.map((item, index) => updateStartDay.clone().add(index, 'days')))
  }

  function handleChangeDateFromCalendar(date: Date) {
    setStartDayWeek(moment(date).startOf('isoWeek').clone())
    dispatch(setSelectedDayForTech(moment(date)))
    setCalendar(calendarArr.map((item, index) => moment(moment(date)).startOf('isoWeek').clone().add(index, 'days')))
    setDateRangeCalendarShown(false)
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
  });

  function getAppointmentToday(day: Moment) {
    let checkAppoint = dots.filter(item => item.appointment && item.date === day.format("MM/DD/YYYY"))
    if (checkAppoint.length) {
      return true
    } else {
      return false
    }
  }

  function getAbsenceToday(day: Moment) {
    let checkAbsence = dots.filter(item => item.absence && item.date === day.format("MM/DD/YYYY"))
    if (checkAbsence.length) {
      return true
    } else {
      return false
    }
  }

  function checkIsSomeDay(day: string, time: string) {
    let text = ''
    let selectedDay = selectedDayForTech.format("MM/DD/YYYY")

    if (selectedDay === day) {
      text = time
    } else if (selectedDay > day) {
      text = 'Start of the Day'
    } else {
      text = 'End of the Day'
    }

    return text
  }

  return (
    <div className='appointment-calendar'>
      <div className={classNames('choose-day', '__hide-on-mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}>
        <div className='grid-days'>
          {
            DayOfWeekTechCalendar.concat(DayOfWeekTechCalendar).map((item, index) => (
              <div
                key={index}
                className='grid-day'
                style={{ width: `calc(90% / ${calendarArr.length})` }}
              >
                {item}
              </div>
            ))
          }
        </div>
        <div className='choose-day-items'>
          {
            calendar.map((item, index) => (
              <div
                key={item.format('DD')}
                onClick={() => handleSelectDay(item)}
                style={{ width: `calc(90% / ${calendarArr.length})` }}
                className={classNames("choose-day-item", { selected: selectedDayForTech.format('DD') === item.format('DD') })}
              >
                {item.format('DD')}
                <div className='choose-day-item-month'>
                  {item.format('MMM')}
                </div>
                <div className='choose-day-item-row'>
                  {
                    getAppointmentToday(item) &&
                    <span className='appointment-today'></span>
                  }
                  {
                    !getAppointmentToday(item) && getAbsenceToday(item) &&
                    <span className='absence-today'></span>
                  }
                </div>
              </div>
            ))
          }
          <div
            style={{ width: '10%' }}
            className="clendar-nav"
          >
            <div
              className="clendar-nav-btn"
              style={{ width: '25%' }}
              onClick={() => handleCalendarNavPrev()}
            >
              <button style={{ width: '24px', height: '24px' }} className="_zeroed __noclick">
                <Icon viewBox="0 0 24 24" style={{ transform: 'rotate(180deg)', height: '20px' }} icon="arrow-25" />
              </button>
            </div>

            <div
              onFocus={() => setDateRangeCalendarShown(true)}
              onBlur={() => setDateRangeCalendarShown(false)}
              tabIndex={-1}
              style={{ width: '50%' }}
              className="date-range clendar-nav-btn"
            >
              <div style={{ width: '100%', justifyContent: 'center' }} className="form-field _iconed">
                <button
                  onClick={() => setDateRangeCalendarShown(true)}
                  style={{ width: '24px', height: '24px' }}
                  className="_zeroed __noclick"
                >
                  <Icon viewBox="0 0 24 24" icon="calendar-4" />
                </button>
              </div>

              <div style={{ width: '350px', left: '-100px' }} className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
                <DateRangeCalendar
                  startDate={new Date(selectedDayForTech.format("ddd, MMM DD YYYY"))}

                  onStartDateUpdate={(date) => handleChangeDateFromCalendar(date)}
                />
              </div>
            </div>

            <div
              className="clendar-nav-btn"
              style={{ width: '25%' }}
              onClick={() => handleCalendarNavNext()}
            >
              <button style={{ width: '24px', height: '24px' }} className="_zeroed __noclick">
                <Icon style={{ height: '20px' }} viewBox="0 0 24 24" icon="arrow-25" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ padding: 0 }}
        className={classNames('choose-day', '__show-on-mobile',
          {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          }
        )}>
        <div className='grid-days'>
          {
            DayOfWeekTechCalendar.map((item, index) => (
              <div
                key={index}
                className='grid-day'
                style={{ width: `calc(90% / ${DayOfWeekTechCalendar.length})` }}
              >
                {item}
              </div>
            ))
          }
        </div>
        <div className='choose-day-items'>
          <div
            style={{ width: '90%', display: 'flex', flexWrap: 'wrap' }}
          >
            {
              calendar.map((item, index) => (
                <div
                  key={item.format('DD')}
                  onClick={() => handleSelectDay(item)}
                  style={{
                    width: `calc(100% / ${calendarArr.length / 2})`,
                    borderTop: index > 6 ? '1px solid #0F1A34' : 'none'
                  }}
                  className={classNames("choose-day-item", { selected: selectedDayForTech.format('DD') === item.format('DD') })}
                >
                  {item.format('DD')}
                  <div className='choose-day-item-month'>
                    {item.format('MMM')}
                  </div>
                  <div className='choose-day-item-row'>
                    {
                      getAppointmentToday(item) &&
                      <span className='appointment-today'></span>
                    }
                    {
                      !getAppointmentToday(item) && getAbsenceToday(item) &&
                      <span className='absence-today'></span>
                    }
                  </div>
                </div>
              ))
            }

          </div>
          <div
            style={{
              width: '10%',
              height: '100%',
              justifyContent: 'space-around'
            }}
            className="clendar-nav-mobile"
          >
            <div
              className="clendar-nav-btn"
              style={{ width: '50%' }}
              onClick={() => handleCalendarNavPrev()}
            >
              <button style={{ width: '24px', height: '24px' }} className="_zeroed __noclick">
                <Icon
                  viewBox="0 0 24 24"
                  style={{ transform: 'rotate(270deg)', WebkitTransform: 'rotate(270deg)' }}
                  icon="arrow-25"
                />
              </button>
            </div>

            <div
              onFocus={() => setDateRangeCalendarShown(true)}
              onBlur={() => setDateRangeCalendarShown(false)}
              tabIndex={-1}
              style={{ width: '50%' }}
              className="date-range clendar-nav-btn"
            >
              <div style={{ width: '100%' }} className="form-field _iconed">
                <button
                  onClick={() => setDateRangeCalendarShown(true)}
                  style={{ width: '24px', height: '24px' }}
                  className="_zeroed __noclick"
                >
                  <Icon viewBox="0 0 24 24" icon="calendar-4" />
                </button>
              </div>

              <div style={{ width: '350px', left: '-150px' }} className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
                <DateRangeCalendar
                  startDate={new Date(selectedDayForTech.format("ddd, MMM DD YYYY"))}

                  onStartDateUpdate={(date) => handleChangeDateFromCalendar(date)}
                />
              </div>
            </div>

            <div
              className="clendar-nav-btn"
              style={{ width: '50%' }}
              onClick={() => handleCalendarNavNext()}
            >
              <button style={{ width: '24px', height: '24px' }} className="_zeroed __noclick">
                <Icon
                  style={{ transform: 'rotate(90deg)', WebkitTransform: 'rotate(90deg)' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('content', '__hide-on-mobile',
          {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          }
        )}>
        <div className='content-day'>
          {selectedDayForTech.format("ddd, MMM DD YYYY")}
        </div>

        <div className='content-items'>
          {
            !!content.length && content.map((appoint, index) => (
              <div
                key={index}
                className='content-item'
                onClick={() =>
                  $router.router.navigate('technician_job', {
                    companyId: activeAccountId, jobId: appoint.event_id
                  }, { reload: true })}
              >
                <div className='content-item-left'>
                  <div className='content-item-left-time'>
                    <span> {checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', appoint.time_zone, appoint.from), dateToInfoBlock('hh:mma', appoint.time_zone, appoint.from))} </span>

                    <span> {checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', appoint.time_zone, appoint.to), dateToInfoBlock('hh:mma', appoint.time_zone, appoint.to))} </span>

                    <span
                      className={classNames(
                        {
                          SC: appoint.appointment?.type === 'SC',
                          FU: appoint.appointment?.type === 'FU',
                          RC: appoint.appointment?.type === 'RC'
                        }
                      )}
                    >
                      {appoint.appointment?.type}
                    </span>
                  </div>


                  <span
                    className={classNames('content-item-status',
                      {
                        progress: appoint.appointment?.status === 'Open',
                        waiting: appoint.appointment?.status === 'Waiting',
                        completed: appoint.appointment?.status === 'Closed',
                        new: appoint.appointment?.status === 'New',
                      }
                    )}
                  >
                    {appoint.appointment?.status}
                  </span>
                </div>

                <div className='content-item-center'>
                  {
                    appoint.appointment?.units.map((unit, idx) => (
                      <span
                        key={idx}
                        className='content-item-divece'
                      >
                        {unit.appliance_type}: {unit.appliance_brand}, {unit.appliance}
                      </span>
                    ))
                  }

                  <div className='content-item-info'>
                    <Icon viewBox="3 0 22 20" icon="pin-1" />
                    <span> {appoint.appointment?.name}, <em>{appoint.appointment?.client}</em> </span>
                  </div>

                  <div className='content-item-address'>
                    <Icon viewBox="0 0 11 11" icon="location-1" />
                    <span> {appoint.appointment?.address} </span>
                  </div>
                </div>

                <div className='content-item-right'>
                  <button
                    onClick={() =>
                      $router.router.navigate('technician_job', {
                        companyId: activeAccountId, jobId: appoint.event_id
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      // reportData.permissions.job_show &&
                      handleChangeMenu(event, appoint.event_id, 'jobs', index)}
                  >
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>

                  <span
                    className={classNames('content-item-type',
                      {
                        SC: appoint.appointment?.type === 'SC',
                        FU: appoint.appointment?.type === 'FU',
                        RC: appoint.appointment?.type === 'RC'
                      }
                    )}
                  ></span>
                  {
                    // reportData.permissions.job_show &&
                    showContext === `jobs_${appoint.event_id}_${index}` &&
                    <div
                      style={{ top: '0', right: '0' }}
                      className="redirect-menu"
                      ref={contextMenuRef}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('technician_job', {
                            companyId: activeAccountId, jobId: appoint.event_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/technician/job/${appoint.event_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </div>
              </div>
            ))
          }

          {
            !!absences.length &&
            absences.map((absence, index) => (
              <div
                key={index}
                className='content-item'
                onClick={() =>
                  $router.router.navigate('technician_absence', {
                    companyId: activeAccountId, absenceId: absence.event_id
                  }, { reload: true })}
              >
                <div className='content-item-left'>
                  <div className='content-item-left-time'>
                    <span>
                      {
                        checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', absence.time_zone, absence.from), dateToInfoBlock('hh:mma', absence.time_zone, absence.from))
                      }
                    </span>

                    <span>
                      {
                        checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', absence.time_zone, absence.to), dateToInfoBlock('hh:mma', absence.time_zone, absence.to))
                      }
                    </span>
                  </div>

                  <span
                    className="content-item-status absence-time"
                  >
                    Absence
                  </span>
                </div>

                <div className='content-item-center'>
                  <span className='content-item-divece'> Info: </span>

                  <span> Start: {dateToInfoBlock('MM/dd/yyyy hh:mma', absence.time_zone, absence.from)} </span>

                  <span> End: {dateToInfoBlock('MM/dd/yyyy hh:mma', absence.time_zone, absence.to)} </span>
                </div>

                <div className='content-item-right'>
                  <button
                    onClick={() =>
                      $router.router.navigate('technician_absence', {
                        companyId: activeAccountId, absenceId: absence.event_id
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      // reportData.permissions.job_show &&
                      handleChangeMenu(event, absence.event_id, 'absence_jobs', index)}
                  >
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>
                  {
                    // reportData.permissions.job_show &&
                    showContext === `absence_jobs_${absence.event_id}_${index}` &&
                    <div
                      style={{ top: '0', right: '0' }}
                      className="redirect-menu"
                      ref={contextMenuRef}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('technician_absence', {
                            companyId: activeAccountId, absenceId: absence.event_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/technician/absence/${absence.event_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </div>
              </div>
            ))
          }
        </div>

      </div>

      <div
        className={classNames('content mobile', '__show-on-mobile',
          {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          }
        )}>
        <div className='content-day mobile'>
          {selectedDayForTech.format("ddd, MMM DD YYYY")}
        </div>

        <div className='content-items mobile'>
          {
            !!content.length && content.map((appoint, index) => (
              <div
                key={index}
                className='content-item'
                onClick={() =>
                  $router.router.navigate('technician_job', {
                    companyId: activeAccountId, jobId: appoint.event_id
                  }, { reload: true })}
              >
                <div className='content-item-left mobile'>
                  <div className='content-item-left-time'>
                    <span> {checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', appoint.time_zone, appoint.from), dateToInfoBlock('hh:mma', appoint.time_zone, appoint.from))} </span>

                    <span> {checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', appoint.time_zone, appoint.to), dateToInfoBlock('hh:mma', appoint.time_zone, appoint.to))} </span>

                    <span
                      className={classNames(
                        {
                          SC: appoint.appointment?.type === 'SC',
                          FU: appoint.appointment?.type === 'FU',
                          RC: appoint.appointment?.type === 'RC'
                        }
                      )}
                    >
                      {appoint.appointment?.type}
                    </span>
                  </div>

                  <span
                    className={classNames('content-item-status',
                      {
                        progress: appoint.appointment?.status === 'Open',
                        waiting: appoint.appointment?.status === 'Waiting',
                        completed: appoint.appointment?.status === 'Closed',
                        new: appoint.appointment?.status === 'New',
                      }
                    )}
                  >
                    {appoint.appointment?.status}
                  </span>
                </div>

                <div className='content-item-center'>
                  {
                    appoint.appointment?.units.map((unit, idx) => (
                      <span
                        key={idx}
                        className='content-item-divece'
                      >
                        {unit.appliance_type}: {unit.appliance_brand}, {unit.appliance}
                      </span>
                    ))
                  }

                  <div className='content-item-info'>
                    <Icon viewBox="3 0 22 20" icon="pin-1" />
                    <span> {appoint.appointment?.name}, <em>{appoint.appointment?.client}</em> </span>
                  </div>

                  <div className='content-item-address'>
                    <Icon viewBox="0 0 11 11" icon="location-1" />
                    <span> {appoint.appointment?.address} </span>
                  </div>
                </div>

                <div
                  className='content-item-right mobile'
                  onClick={() =>
                    $router.router.navigate('technician_job', {
                      companyId: activeAccountId, jobId: appoint.event_id
                    }, { reload: true })}
                >
                  <button style={{ padding: 0 }} >
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>

                  <span
                    className={classNames('content-item-type',
                      {
                        SC: appoint.appointment?.type === 'SC',
                        FU: appoint.appointment?.type === 'FU',
                        RC: appoint.appointment?.type === 'RC'
                      }
                    )}
                  ></span>
                </div>
              </div>
            ))
          }
          {
            !!absences.length &&
            absences.map((absence, index) => (
              <div
                key={index}
                className='content-item'
                onClick={() =>
                  $router.router.navigate('technician_absence', {
                    companyId: activeAccountId, absenceId: absence.event_id
                  }, { reload: true })}
              >
                <div className='content-item-left mobile'>
                  <span>
                    {
                      checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', absence.time_zone, absence.from), dateToInfoBlock('hh:mma', absence.time_zone, absence.from))
                    }
                  </span>

                  <span>
                    {
                      checkIsSomeDay(dateToInfoBlock('MM/dd/yyyy', absence.time_zone, absence.to), dateToInfoBlock('hh:mma', absence.time_zone, absence.to))
                    }
                  </span>

                  <span
                    className="content-item-status absence-time"
                  >
                    Absence
                  </span>
                </div>

                <div className='content-item-center'>
                  <span className='content-item-divece'> Info: </span>

                  <span> Start: {dateToInfoBlock('MM/dd/yyyy hh:mma', absence.time_zone, absence.from)} </span>

                  <span> End: {dateToInfoBlock('MM/dd/yyyy hh:mma', absence.time_zone, absence.to)} </span>
                </div>

                <div className='content-item-right mobile'>
                  <button
                    style={{ padding: 0 }}
                    onClick={() =>
                      $router.router.navigate('technician_absence', {
                        companyId: activeAccountId, absenceId: absence.event_id
                      }, { reload: true })}
                  >
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default memo(AppointmentCalendarPage)