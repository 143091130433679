import { memo, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import ReactInputMask from "react-input-mask"

import Select from '../../components/Select'
import Icon from '../../components/Icon'
import AddressAutocomplete from './AddressAutocomplete'

import { CallContactProps, PhonesProps, AddressProps, EditNewCityProps } from './Models'

import { formatPhoneNumber, formatPhoneNumberToServerString } from '../../funcs'

const addressOptions = ['Residential', 'Commercial', 'Industrial']

const API_KEY = process.env.REACT_APP_API_KEY
const libraries: "places"[] = ["places"]

interface BookingCallContactProps {
  callContact: CallContactProps
  setCallContact: (value: CallContactProps) => void
  checkNewContact: () => void
  checkNewContactError: string
  setCheckNewContactError: (value: string) => void

  phonesList: PhonesProps[] | []
  setPhonesList: (value: PhonesProps[] | []) => void

  addressesList: AddressProps[] | []
  setAddressesList: (value: AddressProps[] | []) => void

  callType: boolean
  isSaveClick: boolean

  setIsDifferentZip: (value: boolean) => void
  setChangedZipCode: (value: boolean) => void
  zipCheck: string
}

interface NewAddressProps {
  address_zip: string
  address_street: string
  address_appatment: string
  address_city: string
  address_state: string
  address_arr: string
  address_note: string
  street_number: string
  address_id?: string
  apt?: string
}

function BookingCallContact({
  callContact,
  setCallContact,
  checkNewContact,
  checkNewContactError,
  setCheckNewContactError,
  phonesList,
  setPhonesList,
  addressesList,
  setAddressesList,
  callType,
  isSaveClick,
  setIsDifferentZip,
  setChangedZipCode,
  zipCheck,
}: BookingCallContactProps) {
  const [isNewNumber, setIsNewNumber] = useState(true)
  const [isNewAddress, setIsNewAddress] = useState(true)

  const [editNewAddress, setEditNewAddress] = useState(0)

  const [addNewNumber, setAddNewNumber] = useState({
    name: 'main',
    phone: ''
  })

  const [addNewCity, setAddNewCity] = useState<NewAddressProps>({
    address_zip: '',
    address_street: '',
    address_appatment: '',
    address_city: '',
    address_state: '',
    address_arr: addressOptions[0],
    address_note: '',
    street_number: '',
    apt: 'Apt',
  })



  const [editNewCity, setEditNewCity] = useState<EditNewCityProps>({
    address_zip: '',
    address_street: '',
    address_appatment: '',
    address_city: '',
    address_state: '',
    address_arr: addressOptions[0],
    address_note: '',
    street_number: '',
    apt: 'Apt',
    new: true,
    use: false,
  })

  const [clearAutoComplite, setClearAutoComplite] = useState(0)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY as string,
    libraries: libraries as "places"[],
    language: 'en',
  })
  async function getNameFromClipboard() {
    try {
      let getName = localStorage.getItem('copy_name')
      let getPhone = localStorage.getItem('copy_phone')

      if (getName) {
        if (getName.includes(',')) {
          let splitName = getName.trim().split(',')
          callContact.client[callContact.client.length - 1].client_first_name === '' && handleChangeCreatedClient(splitName[1], 'client_first_name', callContact.client.length - 1)
          callContact.client[callContact.client.length - 1].client_last_name === '' && handleChangeCreatedClient(splitName[0], 'client_last_name', callContact.client.length - 1)
        } else if (getName.trim().split(' ')) {
          let splitName = getName.trim().split(' ')
          callContact.client[callContact.client.length - 1].client_first_name === '' && handleChangeCreatedClient(splitName[0], 'client_first_name', callContact.client.length - 1)
          callContact.client[callContact.client.length - 1].client_last_name === '' && handleChangeCreatedClient(splitName[1], 'client_last_name', callContact.client.length - 1)
        } else {
          callContact.client[callContact.client.length - 1].client_first_name === '' && handleChangeCreatedClient(getName, 'client_first_name', callContact.client.length - 1)
          callContact.client[callContact.client.length - 1].client_last_name === '' && handleChangeCreatedClient('', 'client_last_name', callContact.client.length - 1)
        }
      }
      if (getPhone) {
        let sliceName = getPhone.slice(2)
        callContact.client[callContact.client.length - 1].phone === '' && handleChangeCreatedClient(sliceName, 'phone', callContact.client.length - 1)
      }

    } catch (error) { }
  }

  function handleChange(event: any, index: number) {
    const { checked } = event.target

    let updateRadio = callContact.client.map(item => item)
    updateRadio = updateRadio.map((item, idx) => {
      if (index === idx) {
        if (!!addressesList.filter(address => address.use).length) {
          return {
            ...item,
            active: checked,
            address: item.address.map(address => {
              return {
                ...address,
                use: false
              }
            })
          }
        } else {
          return {
            ...item,
            active: checked
          }
        }
      } else {
        return {
          ...item,
          active: false
        }
      }
    })

    setCallContact({
      ...callContact,
      client: updateRadio
    })
  }

  function handleChangeCreatedClient(value: string, name: string, index: number) {
    let updateClient = callContact.client.map(item => item)
    updateClient[index][name] = value

    setCallContact({
      ...callContact,
      client: updateClient
    })
    setCheckNewContactError('')
  }

  function handleAddNewNumber() {
    let addedNewNumber = phonesList.map(item => item)
    addedNewNumber.push({
      id: null,
      name: addNewNumber.name,
      phone: addNewNumber.phone,
      subtitle: '',
      active: false,
      use: true,
      payer: false,
      sms: false,
      new: true,
      is_main: false
    })

    setPhonesList(addedNewNumber)

    setAddNewNumber({
      name: 'main',
      phone: ''
    })
    setIsNewNumber(false)

    setClearAutoComplite(Math.random())
  }

  function handleChangeNewNumber(name: string, checked: boolean, index: number) {
    let updatedNewNumber = phonesList.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          [name]: checked
        }
      } else {
        return { ...item }
      }
    })

    setPhonesList(updatedNewNumber)
  }

  function handleChangeNumber(name: string, checked: boolean, index: number) {
    let updatedCallContact = callContact.client.map(item => {
      if (item.active) {
        return {
          ...item,
          phones: item.phones.map((phone, idx) => {
            if (index === idx) {
              return {
                ...phone,
                [name]: checked
              }
            } else {
              return { ...phone }
            }
          })
        }
      } else {
        return { ...item }
      }
    })

    setCallContact({
      client: updatedCallContact
    })
  }

  function handleRemoveNumber(index: number) {
    setPhonesList(phonesList.filter((item, idx) => idx !== index))
  }

  function handleChangeUseAddress(checked: boolean, index: number) {
    let updatedNewAddresses = addressesList.map(item => {
      return { ...item, use: false }
    })

    let updatedAddresses = callContact.client.map(item => {
      if (item.active) {
        return {
          ...item,
          address: item.address.map((address, idx) => {
            if (idx === index) {
              return {
                ...address,
                use: checked
              }
            } else {
              return {
                ...address,
                use: false
              }
            }
          })
        }
      } else {
        return { ...item }
      }
    })

    setAddressesList(updatedNewAddresses)
    setCallContact({
      client: updatedAddresses
    })
  }

  function handleChangeNewUseAddress(checked: boolean, index: number) {
    let updatedNewAddresses = addressesList.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          use: checked
        }
      } else {
        return { ...item, use: false }
      }
    })
    let updatedAddresses = callContact.client.map(item => {
      if (item.active) {
        return {
          ...item,
          address: item.address.map(address => {
            return { ...address, use: false }
          })
        }
      } else {
        return { ...item }
      }
    })

    setAddressesList(updatedNewAddresses)
    setCallContact({
      client: updatedAddresses
    })
  }

  function handleAddNewCity() {
    let updatedAddresses = callContact.client.map(item => {
      if (item.active) {
        return {
          ...item,
          address: item.address.map(address => {
            return { ...address, use: false }
          })
        }
      } else {
        return { ...item }
      }
    })

    let updatedNewAddresses = addressesList.map((item) => {
      return {
        ...item,
        use: false
      }
    })

    updatedNewAddresses.push({
      address_zip: addNewCity.address_zip,
      address_street: addNewCity.street_number ? `${addNewCity.street_number} ${addNewCity.address_street}` : addNewCity.address_street,
      address_appatment: addNewCity.address_appatment,
      address_city: addNewCity.address_city,
      address_state: addNewCity.address_state,
      address_state_id: '2',
      address_arr: addNewCity.address_arr,
      address_note: addNewCity.address_note,
      address_id: null,
      apt: addNewCity.apt,
      new: true,
      use: true
    })

    setAddressesList(updatedNewAddresses)
    setCallContact({
      client: updatedAddresses
    })
    setAddNewCity({
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      apt: 'Apt',
    })
    setIsNewAddress(false)
  }

  function handleCancelNewCity() {
    setAddNewCity({
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      apt: 'Apt',
    })
    setIsNewAddress(false)
  }

  function handleUpdateNewCity() {

    let updatedNewAddresses = addressesList.map((item, index) => {
      if (index === editNewAddress - 1) {
        return {
          address_zip: editNewCity.address_zip,
          address_street: editNewCity.street_number ? `${editNewCity.street_number} ${editNewCity.address_street}` : editNewCity.address_street,
          address_appatment: editNewCity.address_appatment,
          address_city: editNewCity.address_city,
          address_state: editNewCity.address_state,
          address_state_id: '2',
          address_arr: editNewCity.address_arr,
          address_note: editNewCity.address_note,
          address_id: null,
          apt: editNewCity.apt,
          new: true,
          use: true
        }
      } else {
        return {
          ...item,
          use: false
        }
      }
    })

    setAddressesList(updatedNewAddresses)
    setEditNewCity({
      address_zip: '',
      address_street: '',
      address_appatment: '',
      address_city: '',
      address_state: '',
      address_arr: addressOptions[0],
      address_note: '',
      street_number: '',
      new: true,
      use: false
    })
    setEditNewAddress(0)
  }

  function checkContact() {
    let check = true

    let client = callContact.client.filter(item => item.active)

    if (client.length) {
      if (formatPhoneNumberToServerString(client[0].phone).length === 12 ||
        client[0].client_first_name !== '' || client[0].client_last_name !== '' ||
        client[0].client_company_name !== ''
      ) {
        check = false
      }
    } else {
      check = false
    }

    return check
  }

  function checkCreatedNewContact() {
    let check = true

    let client = callContact.client.filter(item => item.active)

    if (client.length) {
      if (client[0].new) {
        if (formatPhoneNumberToServerString(client[0].phone).length === 12 &&
          (client[0].client_first_name !== '' || client[0].client_last_name !== '' ||
            client[0].client_company_name !== '')
        ) {
          check = false
        }
      } else {
        if (formatPhoneNumberToServerString(client[0].phone).length === 12 ||
          client[0].client_first_name !== '' || client[0].client_last_name !== '' ||
          client[0].client_company_name !== ''
        ) {
          check = false
        }
      }
    } else {
      check = false
    }

    return check
  }

  function checkPhones() {
    let check = false
    let phones = callContact.client.filter(item => item.active)[0].phones.filter(item => item.use)

    !!phones.length && phones.forEach(item => {
      if (item.name === '') {
        check = true
      }
      if (item.phone === '') {
        check = true
      }
    })

    let usePhonesList = phonesList.filter(item => item.use)
    !!usePhonesList.length && usePhonesList.forEach(item => {
      if (item.name === '') {
        check = true
      }
      if (item.phone === '') {
        check = true
      }
    })

    if (!phones.length && !usePhonesList.length) {
      check = true
    }

    return check
  }

  function checkAddress() {
    let check = false

    let addresses = callContact.client.filter(item => item.active)[0].address.filter(item => item.use)

    addresses.forEach(item => {
      if (item.address_arr === '' || item.address_city === '' ||
        item.address_state === '' || item.address_street === '' || item.address_zip === ''
      ) {
        check = true
      }
    })
    let newAddresses = addressesList.filter(item => item.use)

    if (!addresses.length && !newAddresses.length) {
      check = true
    }

    newAddresses.forEach(item => {
      if (item.address_arr === '' || item.address_city === '' ||
        item.address_state === '' || item.address_street === '' || item.address_zip === ''
      ) {
        check = true
      }
    })

    return check
  }

  function getName(company: string, firstName: string, lastName: string) {
    let stringName = ''
    if (!firstName && !lastName && company) {
      stringName = company
    } else if (!company) {
      stringName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    } else if (firstName && lastName && company) {
      stringName = `${firstName} ${lastName} (${company})`
    } else if (!firstName && !lastName && !company) {
      stringName = 'Unknown'
    } else {
      stringName = `${firstName ? firstName : ''} ${lastName ? lastName : ''} (${company})`
    }

    return stringName
  }

  function checkFieldsAddPhone() {
    let check = false
    if (addNewNumber.name === '') {
      check = true
    }
    if (formatPhoneNumberToServerString(addNewNumber.phone).length < 12) {
      check = true
    }

    return check
  }

  function handleWithOutService(value: boolean) {
    if (value) {
      let updatedPhonesList = phonesList.map(item => {
        return {
          ...item,
          sms: false
        }
      })
      let updatedCallContact = callContact.client.map(item => {
        if (item.active) {
          return {
            ...item,
            phones: item.phones.map(phone => {
              return {
                ...phone,
                sms: false
              }
            })
          }
        } else {
          return { ...item }
        }
      })

      setPhonesList(updatedPhonesList)
      setCallContact({
        client: updatedCallContact
      })
    }
  }

  function handleEditNewAddress(index: number) {
    setEditNewCity({
      address_zip: addressesList[index].address_zip,
      address_street: addressesList[index].address_street,
      address_appatment: addressesList[index].address_appatment,
      address_city: addressesList[index].address_city,
      address_state: addressesList[index].address_state,
      address_arr: addressesList[index].address_arr,
      address_note: addressesList[index].address_note,
      street_number: addressesList[index].street_number ? addressesList[index].street_number : '',
      apt: addressesList[index].apt,
      use: addressesList[index].use,
      new: addressesList[index].new
    })
    setEditNewAddress(index + 1)
  }

  function checkWithOutService() {
    let check = true
    callContact.client.filter(item => item.active)[0].phones.forEach(item => {
      if (item.sms) {
        check = false
      }
    })
    phonesList.forEach(item => {
      if (item.sms) {
        check = false
      }
    })
    return check
  }

  return (
    <div className='call-contact'>
      <div className='block-content'>
        <div className='block-content-title'>
          <div className='block-title'>Contact</div>

          {callType ?
            !checkContact() ?
              !checkCreatedNewContact() ?
                <div className='btn-marking saved'>
                  <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                </div>
                :
                <div style={{ backgroundColor: '#FFB700' }} className='btn-marking unsaved'>
                  <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                </div> :
              <div className='btn-marking attention'>
                !
              </div> : null
          }
        </div>

        <div className='call-contact_content'>
          {
            callType &&
            <div className='call-contact_number'>
              {
                callContact.client.map((contact, index) => (
                  !contact.new &&
                  <div key={index} className='call-contact_number-item'>
                    <div className='call-contact_number-name'>
                      <p> {getName(contact.client_company_name, contact.client_first_name, contact.client_last_name)} </p>

                      <p> {formatPhoneNumber(contact.phone)} </p>
                    </div>

                    <div className='call-contact_number-subtitle'>
                      <span>	&bull;</span>

                      <span> {contact.subtitle} </span>
                    </div>

                    <div className='call-contact_number-radiobtn'>
                      <div className='radio'>
                        <input
                          name={contact.client_first_name}
                          className='input-radio'
                          type="radio"
                          id={`radio-${index}`}
                          checked={contact.active}
                          onChange={(event) => handleChange(event, index)}
                        />

                        <label className='radiobox' htmlFor={`radio-${index}`}>
                          {
                            contact.active &&
                            <span className='active'></span>
                          }
                        </label>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className='call-contact_number-item'>
                <div className='call-contact_number-name'>
                  <p> Create New Contact</p>

                  {
                    callContact.client[callContact.client.length - 1].active &&
                    <button
                      style={{ marginLeft: '5px', width: 'fit-content', backgroundColor: 'inherit', padding: '0' }}
                      onClick={() => getNameFromClipboard()}
                      title="Paste address"
                    >
                      <Icon
                        style={{
                          width: '16px',
                          height: '16px',
                          verticalAlign: 'middle',
                          overflow: 'hidden',
                          fill: 'inherit'
                        }}
                        viewBox="0 0 1024 1024"
                        icon="copy-1"
                      />
                    </button>
                  }
                </div>

                <div className='call-contact_number-subtitle'></div>

                <div className='call-contact_number-radiobtn'>
                  <div className='radio'>
                    <input
                      name="created_contact"
                      className='input-radio'
                      type="radio"
                      checked={callContact.client[callContact.client.length - 1].active}
                      onChange={(event) => handleChange(event, callContact.client.length - 1)}
                    />

                    <label className='radiobox'>
                      {
                        callContact.client[callContact.client.length - 1].active &&
                        <span className='active'></span>
                      }
                    </label>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            callType &&
            <div className='call-contact_new'>
              <div className='call-contact_new-title'>
                <div className='call-contact_new-item'>
                  <label> First Name: </label>

                  <input
                    disabled={!callContact.client[callContact.client.length - 1].active}
                    value={callContact.client[callContact.client.length - 1].client_first_name}
                    onChange={(event) => handleChangeCreatedClient(event.target.value, 'client_first_name', callContact.client.length - 1)}
                    className='data-border call-contact_new-input'
                    type="text"
                  />
                </div>

                <div className='call-contact_new-item'>
                  <label> Last Name: </label>

                  <input
                    value={callContact.client[callContact.client.length - 1].client_last_name}
                    disabled={!callContact.client[callContact.client.length - 1].active}
                    onChange={(event) => handleChangeCreatedClient(event.target.value, 'client_last_name', callContact.client.length - 1)}
                    className='data-border call-contact_new-input'
                    type="text"
                  />
                </div>

                <div className='call-contact_new-item'>
                  <label> Main Phone: </label>

                  <ReactInputMask
                    type="text"
                    className='data-border call-contact_new-input'
                    disabled={!callContact.client[callContact.client.length - 1].active}
                    mask="+1 (999) 999-9999"
                    value={callContact.client[callContact.client.length - 1].phone}
                    onChange={({ target: { value } }) => handleChangeCreatedClient(value, 'phone', callContact.client.length - 1)}
                  />
                </div>
              </div>

              <div className='call-contact_new-company'>
                <label
                  style={{ opacity: callType ? '1' : '0' }}
                >
                  <span>and/or{' '}</span>
                  Company Name:
                </label>

                <input
                  className='data-border call-contact_new-input'
                  type="text"
                  value={callContact.client[callContact.client.length - 1].client_company_name}
                  disabled={!callContact.client[callContact.client.length - 1].active}
                  onChange={(event) => handleChangeCreatedClient(event.target.value, 'client_company_name', callContact.client.length - 1)}
                />

                {
                  callContact.client[callContact.client.length - 1].active &&
                  <button
                    disabled={checkContact() || isSaveClick}
                    style={{ marginLeft: '20px', cursor: checkContact() ? 'inherit' : 'pointer' }}
                    className='btn call-zipCode_btn'
                    onClick={() => !isSaveClick && checkNewContact()}
                  >
                    Check
                  </button>
                }
                {
                  checkNewContactError &&
                  <span style={{ marginLeft: '10px' }} > {checkNewContactError} </span>
                }
              </div>

              <div className='call-contact_check-new-contact'>
              </div>
            </div>
          }

          {/* Phones block */}
          <div className='data-border call-contact_new-phones'>
            <div className='call-contact_new-topBlock'>
              <div className='call-contact_new-leftBlock'>
                <div className='call-contact_new-titleBlock'>
                  <p>Phones:</p>

                  {
                    !checkPhones() ?
                      <div className='btn-marking btn-marking-mini saved'>
                        <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                      </div> :
                      <div className='btn-marking btn-marking-mini attention'>
                        !
                      </div>
                  }
                </div>

                <div className='call-contact_new-payer'>
                  <p>Main</p>
                  <p style={{ margin: '0 10px' }}>Payer</p>
                  <p>SMS</p>
                </div>
              </div>

              <div className='call-contact_new-sms'>
                <p>Without Service SMS</p>

                <div className='check'>
                  <input
                    checked={checkWithOutService()}
                    className='input-checkbox'
                    type="checkbox"
                    onChange={(event) => handleWithOutService(event.target.checked)}
                  />

                  <label className='checkbox' htmlFor="">
                    {
                      checkWithOutService() &&
                      <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                    }
                  </label>
                </div>
              </div>

              <div className='call-contact_new-dontUse'>
                <p>Use</p>
              </div>
            </div>

            {
              callContact.client.filter(item => item.active)[0].phones.map((item, index) => (
                <div key={index} className='call-contact_new-block'>
                  <div className='call-contact_new-phone_item'>
                    <div className='call-contact_new-block_text'>
                      <p style={{ width: '120px' }}> {item.name} </p>

                      <p
                        style={{ width: '120px' }}
                      >{formatPhoneNumber(item.phone)}</p>
                    </div>

                    <div className='call-contact_new-checkboxes'>
                      <div className='call-contact_new-checkbox'>
                        <div className='check'>
                          <input
                            disabled={true}
                            name='is_main'
                            checked={!!item.is_main}
                            className='input-checkbox'
                            type="checkbox"
                            onChange={(event) => handleChangeNumber(event.target.name, event.target.checked, index)}
                          />

                          <label className='checkbox' htmlFor="">
                            {
                              !!item.is_main &&
                              <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                            }
                          </label>
                        </div>
                      </div>

                      <div style={{ margin: '0 20px' }} className='call-contact_new-checkbox'>
                        <div className='check'>
                          <input
                            name='payer'
                            checked={!!item.payer}
                            className='input-checkbox'
                            type="checkbox"
                            onChange={(event) => handleChangeNumber(event.target.name, event.target.checked, index)}
                          />

                          <label className='checkbox' htmlFor="">
                            {
                              !!item.payer &&
                              <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                            }
                          </label>
                        </div>
                      </div>

                      <div className='call-contact_new-checkbox'>
                        <div className='check'>
                          <input
                            name='sms'
                            checked={!!item.sms}
                            className='input-checkbox'
                            type="checkbox"
                            onChange={(event) => handleChangeNumber(event.target.name, event.target.checked, index)}
                          />

                          <label className='checkbox' htmlFor="">
                            {
                              !!item.sms &&
                              <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                            }
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='call-contact_new-block_delete'>
                    <div className='cancel'>
                      <input
                        name='use'
                        checked={item.use}
                        className='input-checkbox'
                        type="checkbox"
                        onChange={(event) => handleChangeNumber(event.target.name, event.target.checked, index)}
                      />

                      <label className='checkbox' htmlFor="">
                        {
                          item.use &&
                          <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                        }
                      </label>
                    </div>

                    {
                      item.new ?
                        <div
                          className='trash call-contact_new-block_trash inactive'
                          onClick={() => handleRemoveNumber(index)}
                        >
                          <Icon
                            style={{ fill: '#FF0000', width: '90%' }}
                            icon="x-mark-1"
                          />
                        </div> :
                        <div
                          className='trash call-contact_new-block_trash inactive'
                        >
                          <Icon style={{ fill: '#8E8E93', width: '90%' }} icon="x-mark-1" />
                        </div>
                    }
                  </div>
                </div>
              ))
            }

            {/* new phones */}
            {
              phonesList.map((item, index) => (
                <div key={index} className='call-contact_new-block'>
                  <div className='call-contact_new-phone_item'>
                    <div className='call-contact_new-block_text'>
                      <p style={{ width: '120px' }}> {item.name} </p>

                      <p
                        style={{ width: '120px' }}
                      >{formatPhoneNumber(item.phone)}</p>
                    </div>

                    <div className='call-contact_new-checkboxes'>
                      <div className='call-contact_new-checkbox'>
                        <div className='check'>
                          <input
                            disabled={true}
                            name='is_main'
                            checked={!!item.is_main}
                            className='input-checkbox'
                            type="checkbox"
                            onChange={(event) => handleChangeNewNumber(event.target.name, event.target.checked, index)}
                          />

                          <label className='checkbox' htmlFor="">
                            {
                              !!item.is_main &&
                              <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                            }
                          </label>
                        </div>
                      </div>

                      <div style={{ margin: '0 20px' }} className='call-contact_new-checkbox'>
                        <div className='check'>
                          <input
                            name='payer'
                            checked={!!item.payer}
                            className='input-checkbox'
                            type="checkbox"
                            onChange={(event) => handleChangeNewNumber(event.target.name, event.target.checked, index)}
                          />

                          <label className='checkbox' htmlFor="">
                            {
                              !!item.payer &&
                              <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                            }
                          </label>
                        </div>
                      </div>

                      <div className='call-contact_new-checkbox'>
                        <div className='check'>
                          <input
                            name='sms'
                            checked={!!item.sms}
                            className='input-checkbox'
                            type="checkbox"
                            onChange={(event) => handleChangeNewNumber(event.target.name, event.target.checked, index)}
                          />

                          <label className='checkbox' htmlFor="">
                            {
                              !!item.sms &&
                              <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                            }
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='call-contact_new-block_delete'>
                    <div className='cancel'>
                      <input
                        name='use'
                        checked={item.use}
                        className='input-checkbox'
                        type="checkbox"
                        onChange={(event) => handleChangeNewNumber(event.target.name, event.target.checked, index)}
                      />

                      <label className='checkbox' htmlFor="">
                        {
                          item.use &&
                          <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                        }
                      </label>
                    </div>

                    <div
                      className='trash call-contact_new-block_trash inactive'
                      onClick={() => handleRemoveNumber(index)}
                    >
                      <Icon
                        style={{ fill: '#FF0000', width: '90%' }}
                        icon="x-mark-1"
                      />
                    </div>
                  </div>
                </div>
              ))
            }

            <div className='call-contact_new-block'>
              <div
                className='call-contact_new-phone_add'
                style={{ justifyContent: isNewNumber ? 'space-between' : 'flex-end' }}
              >
                {
                  isNewNumber &&
                  <div className='call-contact_new-phone_data'>
                    <p>Add Phone Number:</p>

                    <div className='call-contact_new-phone_name'>
                      <label> Name: </label>

                      <input
                        value={addNewNumber.name}
                        onChange={(event) => setAddNewNumber({ ...addNewNumber, name: event.target.value })}
                        className='data-border call-contact_new-phone_input'
                        type="text"
                      />
                    </div>

                    <div className='call-contact_new-phone_phone'>
                      <label> Phone: </label>

                      <ReactInputMask
                        type="text"
                        className='data-border call-contact_new-phone_input'
                        mask="+1 (999) 999-9999"
                        value={addNewNumber.phone}
                        onChange={({ target: { value } }) => setAddNewNumber({ ...addNewNumber, phone: value })}
                      />
                    </div>
                  </div>
                }

                {
                  isNewNumber ?
                    <button
                      disabled={checkFieldsAddPhone()}
                      className='btn btn-add'
                      onClick={() => handleAddNewNumber()}
                    >
                      Save
                    </button> :
                    <button
                      className='btn btn-add'
                      onClick={() => setIsNewNumber(true)}
                    >
                      Add
                    </button>
                }
              </div>
            </div>
          </div>

          <div className='data-border call-contact_new-addresses'>
            <div className='call-contact_new-topBlock'>
              <div className='call-contact_new-titleBlock'>
                <p>Addresses:</p>

                {
                  !checkAddress() ?
                    <div className='btn-marking btn-marking-mini saved'>
                      <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                    </div> :
                    <div className='btn-marking btn-marking-mini attention'>
                      !
                    </div>
                }
              </div>

              <div className='call-contact_new-dontUse'>
                <p>Use</p>
              </div>
            </div>

            {
              callContact.client.filter(item => item.active)[0].address.map((address, index) => (
                <div key={index} className='call-contact_new-block'>
                  <div className='call-contact_new-addresses_item'>
                    <div className='call-contact_new-addresses_data'>
                      <p className='call-contact_new-addresses_data-text'>
                        <span> {address.address_street}, </span>
                        <span> {address.address_appatment ? `${address.apt}#${address.address_appatment}` : address.address_appatment} </span>
                        <span> {address.address_city}, </span>
                        <span> {address.address_state} </span>
                        <span> {address.address_zip} </span>
                      </p>

                      <div className='call-contact_new-addresses_data-block'>
                        <p>{address.address_arr}</p>

                        <p className='call-contact_new-addresses_data-note'> {address.address_note} </p>
                      </div>
                    </div>

                    <div className='call-contact_new-block_delete'>
                      <div className='call-contact_number-radiobtn'>
                        <div className='radio'>
                          <input
                            name={'use'}
                            className='input-radio'
                            type="radio"
                            id={`radio-${index}`}
                            checked={address.use}
                            onChange={(event) => handleChangeUseAddress(event.target.checked, index)}
                          />

                          <label className='radiobox' htmlFor={`radio-${index}`}>
                            {
                              address.use &&
                              <span className='active'></span>
                            }
                          </label>
                        </div>
                      </div>

                      {
                        address.new ?
                          <div
                            className='trash call-contact_new-block_trash inactive'
                          >
                            <Icon
                              style={{ fill: '#FF0000', width: '90%' }}
                              icon="x-mark-1"
                            />
                          </div> :
                          <div
                            className='trash call-contact_new-block_trash inactive'
                          >
                            <Icon style={{ fill: '#8E8E93', width: '90%' }} icon="x-mark-1" />

                          </div>
                      }
                    </div>
                  </div>
                </div>
              ))
            }

            {
              addressesList.map((address, index) => (
                <div key={index} className='call-contact_new-block'>
                  <div className='call-contact_new-addresses_item'>
                    <div className='call-contact_new-addresses_data'>
                      <p className='call-contact_new-addresses_data-text'>
                        <span> {!!editNewAddress && editNewAddress - 1 === index && '* '} </span>
                        <span> {address.address_street}, </span>
                        <span> {address.address_appatment ? `${address.apt}#${address.address_appatment}` : address.address_appatment} </span>
                        <span> {address.address_city}, </span>
                        <span> {address.address_state} </span>
                        <span> {address.address_zip} </span>
                      </p>

                      <div className='call-contact_new-addresses_data-block'>
                        <p>{address.address_arr}</p>

                        <p className='call-contact_new-addresses_data-note'> {address.address_note} </p>
                      </div>
                    </div>

                    <div className='call-contact_new-block_delete'>
                      <div className='call-contact_number-radiobtn'>
                        <div className='radio'>
                          <input
                            name={'use'}
                            className='input-radio'
                            type="radio"
                            id={`radio-${index}`}
                            checked={address.use}
                            onChange={(event) => handleChangeNewUseAddress(event.target.checked, index)}
                          />

                          <label className='radiobox' htmlFor={`radio-${index}`}>
                            {
                              address.use &&
                              <span className='active'></span>
                            }
                          </label>
                        </div>
                      </div>

                      <div
                        className='trash call-contact_new-block_trash inactive'
                        onClick={() => handleEditNewAddress(index)}
                      >
                        <Icon
                          style={{ fill: '#FFB700', width: '16px', cursor: 'pointer' }}
                          viewBox="0 0 16 16"
                          icon="pencil-1"
                        />
                      </div>

                      <div
                        className='trash call-contact_new-block_trash inactive'
                        onClick={() => setAddressesList(addressesList.filter((item, idx) => index !== idx))}
                      >
                        <Icon
                          style={{ fill: '#FF0000', width: '90%' }}
                          icon="x-mark-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }

            {
              isNewAddress && isLoaded &&
              <div className='call-contact_new-block'>
                <div className='call-contact_new-addresses_add'>
                  <p>Autocomplete: </p>

                  <div style={{ width: '75%' }} className='call-contact_new-addresses_add-data'>
                    <AddressAutocomplete
                      addNewCity={addNewCity}
                      setAddNewCity={setAddNewCity}
                      clearAutoComplite={clearAutoComplite}
                      setIsDifferentZip={setIsDifferentZip}
                      setChangedZipCode={setChangedZipCode}
                      zipCheck={zipCheck}
                    />
                  </div>
                </div>
              </div>
            }

            <div className='call-contact_new-block'>

              <div className='call-contact_new-addresses_add'>
                {
                  isNewAddress &&
                  <div className='call-contact_new-addresses_add-data'>
                    <div className='call-contact_new-addresses_add-block'>
                      <input
                        placeholder='Street'
                        value={addNewCity.street_number ? `${addNewCity.street_number} ${addNewCity.address_street}` : addNewCity.address_street}
                        onChange={(event) => setAddNewCity({ ...addNewCity, address_street: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input street'
                        type="text"
                      />

                      <div
                        className='select-apt'
                        style={{ marginRight: 0 }}
                      >
                        <Select
                          options={['Apt', 'Unit', 'Lot'].map((item) => ({
                            span: item,
                            value: item
                          }))} selectedOption={addNewCity.apt as string} onChange={(value: string) => setAddNewCity({
                            ...addNewCity,
                            apt: value
                          })}
                        />
                      </div>

                      <input
                        placeholder='Unit/Apt'
                        value={addNewCity.address_appatment}
                        onChange={(event) => setAddNewCity({ ...addNewCity, address_appatment: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input unit'
                        type="text"
                      />

                      <input
                        placeholder='City'
                        value={addNewCity.address_city}
                        onChange={(event) => setAddNewCity({ ...addNewCity, address_city: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input city'
                        type="text"
                      />

                      <input
                        placeholder='State'
                        value={addNewCity.address_state}
                        onChange={(event) => setAddNewCity({ ...addNewCity, address_state: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input state'
                        type="text"
                      />

                      <input
                        placeholder='Zip'
                        value={addNewCity.address_zip}
                        onChange={(event) => setAddNewCity({ ...addNewCity, address_zip: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input zip'
                        type="text"
                      />
                    </div>

                    <div className='call-contact_new-addresses_add-block'>
                      <Select options={addressOptions.map((item) => ({
                        span: item,
                        value: item
                      }))} selectedOption={addNewCity.address_arr} onChange={(value: string) => setAddNewCity({
                        ...addNewCity,
                        address_arr: value
                      })} />

                      <input
                        placeholder='Note'
                        value={addNewCity.address_note}
                        onChange={(event) => setAddNewCity({ ...addNewCity, address_note: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input note'
                        type="text"
                      />
                    </div>
                  </div>
                }

                {
                  !!editNewAddress &&
                  <div className='call-contact_new-addresses_add-data'>
                    <div className='call-contact_new-addresses_add-block'>
                      <input
                        placeholder='Street'
                        value={editNewCity.address_street}
                        onChange={(event) => setEditNewCity({ ...editNewCity, address_street: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input street'
                        type="text"
                      />

                      <div
                        className='select-apt'
                        style={{ marginRight: 0 }}
                      >
                        <Select
                          options={['Apt', 'Unit', 'Lot'].map((item) => ({
                            span: item,
                            value: item
                          }))}
                          selectedOption={editNewCity.apt as string}
                          onChange={(value: string) => setEditNewCity({
                            ...editNewCity,
                            apt: value
                          })}
                        />
                      </div>

                      <input
                        placeholder='Unit/Apt'
                        value={editNewCity.address_appatment}
                        onChange={(event) => setEditNewCity({ ...editNewCity, address_appatment: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input unit'
                        type="text"
                      />

                      <input
                        placeholder='City'
                        value={editNewCity.address_city}
                        onChange={(event) => setEditNewCity({ ...editNewCity, address_city: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input city'
                        type="text"
                      />

                      <input
                        placeholder='State'
                        value={editNewCity.address_state}
                        onChange={(event) => setEditNewCity({ ...editNewCity, address_state: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input state'
                        type="text"
                      />

                      <input
                        placeholder='Zip'
                        value={editNewCity.address_zip}
                        onChange={(event) => setEditNewCity({ ...editNewCity, address_zip: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input zip'
                        type="text"
                      />
                    </div>

                    <div className='call-contact_new-addresses_add-block'>
                      <Select options={addressOptions.map((item) => ({
                        span: item,
                        value: item
                      }))} selectedOption={editNewCity.address_arr} onChange={(value: string) => setEditNewCity({
                        ...editNewCity,
                        address_arr: value
                      })} />

                      <input
                        placeholder='Note'
                        value={editNewCity.address_note}
                        onChange={(event) => setEditNewCity({ ...editNewCity, address_note: event.target.value })}
                        className='data-border call-contact_new-addresses_add-input note'
                        type="text"
                      />
                    </div>
                  </div>
                }

                {
                  isNewAddress ?
                    <div className='call-contact_new-addresses_add-btn'>
                      <button
                        className='btn btn-add'
                        onClick={() => handleCancelNewCity()}
                        style={{ marginRight: '10px' }}
                      >
                        Cancel
                      </button>

                      <button
                        className='btn btn-add'
                        disabled={addNewCity.address_zip === '' || addNewCity.address_street === '' ||
                          addNewCity.address_city === '' ||
                          addNewCity.address_state === ''}
                        onClick={() => handleAddNewCity()}
                      >
                        Save
                      </button>
                    </div> :
                    !!editNewAddress ?
                      <div className='call-contact_new-addresses_add-btn'>
                        <button
                          disabled={editNewCity.address_zip === '' || editNewCity.address_street === '' ||
                            editNewCity.address_city === '' ||
                            editNewCity.address_state === ''}
                          onClick={() => handleUpdateNewCity()}
                          className='btn btn-add'
                        >
                          Update
                        </button>
                      </div> :
                      <div className='call-contact_new-addresses_add-btn'>
                        <button
                          onClick={() => setIsNewAddress(true)}
                          className='btn btn-add'
                        >
                          Add
                        </button>
                      </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(BookingCallContact)