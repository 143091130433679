import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { BaseLink, useRoute } from 'react-router5'
import { useCookies } from "react-cookie";

import Icon from '../../../components/Icon'
import ReportFilters from '../../../components/reports/Filters'
import ReportTableControls from '../../../components/reports/TableControls'
import ReportTableField from '../../../components/reports/TableField'
import AudioPlayer from '../../../components/AudioPlayer'

import { getDateRangeByPreset } from '../../../funcs/reports'
import { useAppSelector } from '../../../store/hooks'
import { getDateTimeForInterface, httpClientUpdate, nErrorUpdate, useOuterClick } from '../../../funcs'

import { DateRangePreset } from '../../../models/Misc'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../../styles/pages/common/report-list.sass"

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  audioLibrary: AudioLibrary[]
  edit: {
    user_groups: UserGroupsProps[]
  }
  interface: {

    min_date: Date,
    max_date: Date,

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  }
  permissions: PermissionsProps
}

interface PermissionsProps {
  audio_library_add: boolean
  audio_library_delete: boolean
  audio_library_edit: boolean
  audio_library_report_show: boolean
  audio_library_show: boolean
}

interface AudioLibrary {
  audio_library_id: string
  audio_url: string
  created_at: string
  created_by: string
  description: string
  name: string
  updated_at: string
  updated_by: string
}

export interface UserGroupsProps {
  name: string
  user_group_id: string
}

export default function List() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const user = useAppSelector((store) => store.user)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const navActive = useAppSelector((store) => store.navActive)

  let timeZone = user?.time_zone

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [$updater, $setUpdater] = useState<any>(Math.random())

  // eslint-disable-next-line
  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<DataProps | null>(null)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    callListenPopup: null as string | null,
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    sort: {
      field: cookiesSetting && cookiesSetting?.call_center_audio_library?.sort_field ? cookiesSetting.call_center_audio_library.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.call_center_audio_library?.sort_type ? cookiesSetting.call_center_audio_library.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [ {
      span: 'Name',
      value: 'name'
    }, {
      span: 'Description',
      value: 'description'
    }]
  })

  async function loadData() {
    try {
      setCookie('settings', { ...cookiesSetting, call_center_audio_library: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.call_center_audio_library?.limit_rows ? cookiesSetting?.call_center_audio_library.limit_rows : reportsMaxRows } })

      // https://2022back4.artemiudintsev.com/api/audio-library/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: responseData, success, error } } = (await httpClientUpdate.get('/audio-library/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.call_center_audio_library?.limit_rows ? cookiesSetting?.call_center_audio_library?.limit_rows : reportsMaxRows,
          page: 1,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }
      })) as { data: HttpReport }
      if (success) {
        setReportData({
          ...responseData,
          interface: {

            ...responseData.interface,
            min_date: getDateTimeForInterface(responseData.interface.min_date),
            max_date: getDateTimeForInterface(responseData.interface.max_date),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, call_center_audio_library: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {
        reportData &&
        <div className="CallCenter_PhoneNumbersPage_List">
          {/* Top navigation */}
          <div className="top-nav">
            <BaseLink
              router={$router.router}
              routeName="call_center.phoneNumbers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
            >
              <Icon icon="phone-1" />
              <span>Phone Numbers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.dispatchers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
            >
              <Icon icon="user-1" />
              <span>Dispatchers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.additionalTime"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="time-22" />
              <span>Additional Time</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.absence"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.absence" })}
            >
              <Icon icon="time-16" />
              <span>Absence</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.groups" })}
            >
              <Icon icon="user-29" />
              <span>Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callFlows"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
            >
              <Icon icon="share-7" />
              <span>Call Flows</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.caller_groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
            >
              <Icon icon="user-29" />
              <span>Caller Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.temporaryClosed"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="close-664" />
              <span>Temporary Closed</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callCenters"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
            >
              <Icon icon="delivery-8" />
              <span>Call Centers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.audioLibrary"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="audio-15" />
              <span>Audio Library</span>
            </BaseLink>
          </div>

          <div className="page-header">
            <h1>Audio Library</h1>

            <ReportFilters
              onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
              searchInput={localInterface.search}
              searchButtonActive={searchButtonActive}

              onUpdate={() => {
                $setUpdater(Math.random())
              }}

              phoneCall={phoneCall}
              navActive={navActive.is}
            />

            {/* Table controls */}
            <ReportTableControls
              zIndex={5}

              cookie_reportsMaxRows={cookiesSetting?.call_center_audio_library?.limit_rows}

              onMaxRowsChange={(value) => {
                setCookie('settings', { ...cookiesSetting, call_center_audio_library: { ...cookiesSetting.call_center_audio_library, limit_rows: value } })
                $setUpdater(Math.random())
              }}

              amount={{
                total: 1,
                start: 1,
                end: 1
              }}

              page={localInterface.page}
              maxPages={1}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}

              addButton={
                reportData.permissions.audio_library_add ?
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('call_center.audioLibrary.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      }, { reload: true })}>
                      <Icon icon="plus-thin" />
                      <span>Add Audio</span>
                      <Icon viewBox="0 0 1024 1024" icon="audio-15" />
                    </button>
                  </div> : <></>
              }
            />
          </div>

          <div className="contents">
            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Name</span>)}

                    sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Description</span>)}

                    sortDirection={localInterface.sort.field === 'description' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'description', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <th>
                    Audio
                  </th>
                </tr>
              </thead>

              {reportData.audioLibrary.map((audio, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative', cursor: reportData.permissions.audio_library_show && audio.audio_library_id ? 'pointer' : 'inherit' }}
                  onClick={() =>
                    reportData.permissions.audio_library_show &&
                    audio.audio_library_id &&
                    $router.router.navigate('call_center.audioLibrary.item', {
                      companyId: activeAccountId,
                      audioLibraryId: audio.audio_library_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.audio_library_show &&
                    audio.audio_library_id &&
                    handleChangeMenu(event, audio.audio_library_id, 'audio', i)}
                >
                  <td>{audio.name}</td>
                  <td>
                    {audio.description}
                  </td>
                  <td>
                    {
                      audio.audio_url &&
                      <button
                        className="_zeroed _iconed"
                        onClick={(event) => {
                          event.stopPropagation()
                          setLocalInterface({ ...localInterface, callListenPopup: audio.audio_url })
                        }}
                      >
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    }

                    {
                      reportData.permissions.audio_library_show &&
                      showContext === `audio_${audio.audio_library_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('call_center.audioLibrary.item', {
                              companyId: activeAccountId,
                              audioLibraryId: audio.audio_library_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/call_center/audio_library/${audio.audio_library_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Medium desktop table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>
              {reportData.audioLibrary.map((audio, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative', cursor: reportData.permissions.audio_library_show && audio.audio_library_id ? 'pointer' : 'inherit' }}
                  onClick={() =>
                    reportData.permissions.audio_library_show &&
                    audio.audio_library_id &&
                    $router.router.navigate('call_center.audioLibrary.item', {
                      companyId: activeAccountId,
                      audioLibraryId: audio.audio_library_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.audio_library_show &&
                    audio.audio_library_id &&
                    handleChangeMenu(event, audio.audio_library_id, 'audio', i)}
                >
                  <td>
                    <div>{audio.name}</div>
                  </td>
                  <td>
                    <div>{audio.description}</div>
                  </td>
                  <td>
                    {
                      audio.audio_url &&
                      <button
                        className="_zeroed _iconed"
                        onClick={(event) => {
                          event.stopPropagation()
                          setLocalInterface({ ...localInterface, callListenPopup: audio.audio_url })
                        }}
                      >
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    }

                    {
                      reportData.permissions.audio_library_show &&
                      showContext === `audio_${audio.audio_library_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('call_center.audioLibrary.item', {
                              companyId: activeAccountId,
                              audioLibraryId: audio.audio_library_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/call_center/audio_library/${audio.audio_library_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {reportData.audioLibrary.map((audio, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    reportData.permissions.audio_library_show &&
                    audio.audio_library_id &&
                    $router.router.navigate('call_center.audioLibrary.item', {
                      companyId: activeAccountId,
                      audioLibraryId: audio.audio_library_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                >

                  <div className="__top">

                    <div className="__left">
                      <span className="gray">Name:</span>
                      <span> {audio.name}</span>
                    </div>

                    <div className="__right">
                      <span className="gray">Description:</span>
                      <span> {audio.description}</span>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      {
                        audio.audio_url &&
                        <button
                          className="_zeroed _iconed"
                          onClick={(event) => {
                            event.stopPropagation()
                            setLocalInterface({ ...localInterface, callListenPopup: audio.audio_url })
                          }}
                        >
                          <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                        </button>
                      }
                    </div>

                    <div className="__right small">
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.call_center_audio_library?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, call_center_audio_library: { ...cookiesSetting.call_center_audio_library, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />

          {/* Call listen popup */}
          {localInterface.callListenPopup ? (
            <div className="popup callListenPopup" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: null })}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="call-player">
                  <AudioPlayer audioSrc={localInterface.callListenPopup} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
    </>
  )
}
