import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import { DateTime } from "luxon";
import { useLoadScript } from "@react-google-maps/api";
import { useCookies } from "react-cookie";

import AudioPlayer from "../../components/AudioPlayer"
import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import ViewMap from "./ViewMap";

import { getDateRangeByPreset } from "../../funcs/reports"
import { httpClientUpdate, nErrorUpdate, useOuterClick, formatPhoneNumber, dateToInfoBlock, getDateTimeForInterface } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { Call } from "../../models/Calls"
import { DateRangePreset } from "../../models/Misc"

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'source' | 'area' | 'dispatcher'
interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallsReport
}

interface CallsReport {

  interface: {

    filter_words: {
      source: string[],
      area: string[],
      dispatcher: string[]
    },

    tag_words: string[],

    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  check_available_trackers: CheckAvailableTrackersProps[]

  edit: {
    dispatchers: {
      code: number
      dispatcher_id: string
      nickname: string      
    }[]
  }
}

interface CheckAvailableTrackersProps {
  call_id: string
  check_available_tracker_id: string
  created_at: string
  created_by: string
  is_no_today: boolean
  is_out: boolean
  updated_at: string
  zip_code: string
  call: Call,
}

interface CallsPage_ListProps {
  handleCallExtension: (value: string) => void
  softphone_call_extension: boolean | undefined
  updated: number
}

interface SelectedPlaceProps {
  zip_code: string,
  isHave: boolean,
  place_id: string,
  lat: number,
  lng: number
}

const libraries: (
  | "geometry"
  | "places"
  | "drawing"
  | "localContext"
  | "visualization"
)[] = ["places"];

function CheckAvailableTrackerPage_List({
  handleCallExtension,
  softphone_call_extension,
  updated,
}: CallsPage_ListProps) {
  const $router = useRoute()

  const API_KEY = process.env.REACT_APP_API_KEY
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY as string,
    mapIds: ['ec4fc7638d3b957e'],
    libraries,
  });

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const phoneCallStatus = useAppSelector((store) => store.phoneCallStatus)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [extensionCall, setExtensionCall] = useState('')

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [selectedPlace, setSelectedPlace] = useState<SelectedPlaceProps | null>(null)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<CallsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    callListenPopup: null as string | null,
    dateRangeCalendarShown: false,
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.check_available_tracker?.filter_field ? {
      source: [] as string[],
      area: [] as string[],
      dispatcher: [] as string[],
      ...cookiesSetting.check_available_tracker.filter_field,
    } : {
      source: [] as string[],
      area: [] as string[],
      dispatcher: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.check_available_tracker?.sort_field ? cookiesSetting.check_available_tracker.sort_field : 'created_at',
      direction: cookiesSetting && cookiesSetting?.check_available_tracker?.sort_type ? cookiesSetting.check_available_tracker.sort_type : 'down' as 'down' | 'up'
    },
    tag_words: [] as string[],
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Zip Code',
      value: 'zip_code'
    }, {
      span: 'Dispatcher',
      value: 'dispatcher'
    }, {
      span: 'Caller Name',
      value: 'caller_name'
    }, {
      span: 'Client',
      value: 'client_id'
    }, {
      span: 'From',
      value: 'call_from'
    }, {
      span: 'To',
      value: 'call_to'
    }, {
      span: 'Friendly Name',
      value: 'friendly_name'
    }, {
      span: 'Extension',
      value: 'extension'
    }, {
      span: 'Status',
      value: 'status'
    }, {
      span: 'Duration',
      value: 'duration'
    }, {
      span: 'Source',
      value: 'source'
    }, {
      span: 'Area',
      value: 'area'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      callListenPopup: null as string | null,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.check_available_tracker?.filter_field ? {
        source: [] as string[],
        area: [] as string[],
        dispatcher: [] as string[],
        ...cookiesSetting.check_available_tracker.filter_field,
      } : {
        source: [] as string[],
        area: [] as string[],
        dispatcher: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.check_available_tracker?.sort_field ? cookiesSetting.check_available_tracker.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.check_available_tracker?.sort_type ? cookiesSetting.check_available_tracker.sort_type : 'down' as 'down' | 'up'
      },
      tag_words: [] as string[],
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load calls function
  async function loadCalls() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let reqData = {
      account_id: activeAccountId,
      limit_rows: cookiesSetting && cookiesSetting?.check_available_tracker?.limit_rows ? JSON.stringify(cookiesSetting?.check_available_tracker?.limit_rows) : JSON.stringify(reportsMaxRows),
      page: JSON.stringify(localInterface.page),
      date_start: localInterface.min_date,
      date_end: localInterface.max_date,
      sort_field: localInterface.sort.field,
      sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
      ...activeFilters,
    };

    setCookie('settings', { ...cookiesSetting, check_available_tracker: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.check_available_tracker?.limit_rows ? cookiesSetting?.check_available_tracker.limit_rows : reportsMaxRows } })

    try {
      // https://2022back4.artemiudintsev.com/api/check-available-trackers/report
      const { data: { data: report, success, error } } = (await httpClientUpdate.get('/check-available-trackers/report', {
        params: reqData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: HttpClientUpdateReport }
      if (success) {

        setReportData({
          ...report,
          interface: {
            ...report.interface,
            filter_words: {
              ...localInterface.filter_words,
              ...report.interface.filter_words,
            },
            min_date: getDateTimeForInterface(report.interface.min_date),
            max_date: getDateTimeForInterface(report.interface.max_date),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, check_available_tracker: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return

    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadCalls()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getDispatcherName(dispathcer: string, code: number, device: string) {
    let name = ''
    if (code) {
      if (device) {
        name = `${dispathcer} (${code}${device[0]})`
      } else {
        name = `${dispathcer} (${code})`
      }
    } else {
      if (device) {
        name = `${dispathcer} (${device})`
      } else {
        name = dispathcer
      }
    }
    return name
  }

  function handleCall() {
    handleCallExtension(extensionCall)
    setExtensionCall('')
  }

  function handleOpenZip(zip: string) {
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({
      'componentRestrictions': { postalCode: zip, country: 'USA' }
      // eslint-disable-next-line no-loop-func
    }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          setSelectedPlace({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            zip_code: zip,
            isHave: true,
            place_id: results[0].place_id,
          })
        } else {
          setSelectedPlace({
            lat: 0,
            lng: 0,
            zip_code: zip,
            isHave: false,
            place_id: '',
          })
        }
      } else {
        setSelectedPlace({
          lat: 0,
          lng: 0,
          zip_code: zip,
          isHave: false,
          place_id: '',
        })
      }
    })
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function getDispatchersName(id: string) {
    let name = ''

    reportData && reportData.edit.dispatchers.forEach(item => {
      if (item.dispatcher_id === id) {
        name = item.code ? `${item.nickname} (${item.code})` : item.nickname
      }
    })

    return name
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="CallsPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Check Available Tracker</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            setReportIsDeprecated(false);
            $setUpdater(Math.random());
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.check_available_tracker?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, check_available_tracker: { ...cookiesSetting.check_available_tracker, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Zip</span>)}

                  sortDirection={localInterface.sort.field === 'zip_code' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'zip_code', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <th></th>
                <th></th>
                {
                  // reportData.permissions.call_call_show_dispatcher &&
                  <ReportTableField
                    contents={(<span>Dispatcher</span>)}

                    allFiltersSelected={isAllFiltersSelected("dispatcher")}
                    onAllFiltersChange={(value) => switchFilter("dispatcher", "All", value)}

                    filterWords={reportData?.interface.filter_words.dispatcher.map((filterWord) => ({
                      word: filterWord,
                      word_name: getDispatchersName(filterWord),
                      selected: isFilterSelected("dispatcher", filterWord)
                    }))}

                    onFilterChange={(value) => switchFilter("dispatcher", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'dispatcher' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'dispatcher', direction: value } })}


                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                }

                <ReportTableField
                  contents={(<span>Caller Name</span>)}

                  sortDirection={localInterface.sort.field === 'caller_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'caller_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Client</span>)}

                  sortDirection={localInterface.sort.field === 'client_id' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'client_id', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>From</span>)}

                  sortDirection={localInterface.sort.field === 'call_from' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_from', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Friendly name</span>)}

                  sortDirection={localInterface.sort.field === 'friendly_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'friendly_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Source</span>)}

                  allFiltersSelected={isAllFiltersSelected("source")}
                  onAllFiltersChange={(value) => switchFilter("source", "All", value)}

                  filterWords={reportData?.interface.filter_words.source.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("source", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("source", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Area</span>)}

                  allFiltersSelected={isAllFiltersSelected("area")}
                  onAllFiltersChange={(value) => switchFilter("area", "All", value)}

                  filterWords={reportData?.interface.filter_words.area.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("area", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("area", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'area' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>To</span>)}

                  sortDirection={localInterface.sort.field === 'call_to' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_to', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Extension</span>)}

                  sortDirection={localInterface.sort.field === 'extension' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'extension', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <th></th>
                <ReportTableField
                  contents={(<span>Status</span>)}

                  sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Duration</span>)}

                  sortDirection={localInterface.sort.field === 'duration' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'duration', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                {
                  // reportData.permissions.call_call_record &&
                  <th></th>
                }
              </tr>
            </thead>

            {reportData.check_available_trackers.map((track, i) => (
              <tr
                style={{ cursor: 'inherit', position: 'relative' }}
                key={i}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, track.call.created_at)}</td>
                <td>
                  <div
                    className={classNames({
                      'blue-text': !!track.is_no_today && !track.is_out,
                      'red-text': !!track.is_out
                    })}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOpenZip(track.zip_code)}
                  >
                    {track.zip_code}
                  </div>
                </td>
                <td>
                  {
                    !!track.is_out &&
                    <Icon
                      style={{ fill: '#EB5757', width: '16px', minWidth: '16px' }}
                      icon="x-mark-1"
                    />
                  }
                  {
                    !!track.is_no_today && !track.is_out &&
                    <div style={{
                      color: '#fff',
                      width: '20px',
                      textAlign: 'center',
                      backgroundColor: '#6093de',
                      borderRadius: '50%',
                      padding: '2px'
                    }}>
                      !
                    </div>
                  }
                </td>
                <td>
                  <button
                    className={classNames('_zeroed', '_iconed', {
                      _red: ['no-answer', 'busy', 'canceled'].includes(track.call.status),
                      _green: ['completed', 'ringing', 'in-progress'].includes(track.call.status) && track.call.direction === 'inbound',
                      _blue: track.call.direction === 'outbound'
                    })}
                  >
                    <Icon
                      style={{ minWidth: '18px' }}
                      icon="arrow-20"
                      className={classNames({
                        '_rotated-180': track.call.direction === 'outbound'
                      })}
                    />
                  </button>
                </td>
                {
                  // reportData.permissions.call_call_show_dispatcher &&
                  <td>{getDispatcherName(track.call.dispatcher, track.call.dispatcher_code, track.call.dispatcher_device)}</td>
                }
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {track.call.caller_name}
                    </span>
                    {
                      track.call.caller_name &&
                      <button
                        style={{
                          marginLeft: '5px',
                          width: '16px',
                          height: '16px',
                          minHeight: '16px',
                          minWidth: '16px',
                          backgroundColor: 'inherit',
                          padding: '0'
                        }}
                        onClick={() => {
                          localStorage.setItem('copy_name', track.call.caller_name)
                          localStorage.setItem('copy_phone', track.call.call_from)
                        }}
                        title={track.call.caller_name}
                      >
                        <Icon
                          style={{
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            verticalAlign: 'middle',
                            overflow: 'hidden',
                            fill: 'inherit'
                          }}
                          viewBox="0 0 1024 1024"
                          icon="copy-1"
                        />
                      </button>
                    }
                  </div>
                </td>
                <td>{track.call.client ? (
                  <div
                    className="flex-container"
                    style={{ gap: '5px', cursor: track.call.client_id ? 'pointer' : 'inherit' }}
                    onClick={() =>
                      track.call.client_id &&
                      $router.router.navigate('clients.item', {
                        companyId: activeAccountId, clientId: track.call.client_id
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      track.call.client_id &&
                      handleChangeMenu(event, track.call.client_id, 'clients', i)}
                  >
                    <button
                      className="_zeroed _iconed _gray"
                      style={{ cursor: track.call.client_id ? 'pointer' : 'inherit' }}
                    >
                      <Icon style={{ minWidth: '18px' }} icon="user-32" />
                    </button>
                    <span>
                      {track.call.client}
                    </span>
                    {
                      track.call.client_id &&
                      showContext === `clients_${track.call.client_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId, clientId: track.call.client_id
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/clients/${track.call.client_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </div>
                ) : null}</td>
                <td>
                  <span>
                    {track.call.direction === 'outbound' ? formatPhoneNumber(track.call.call_to) : formatPhoneNumber(track.call.call_from)}
                  </span>
                </td>
                <td>{track.call.friendly_name}</td>
                {
                  // reportData.permissions.call_call_report_extended &&
                  <td>
                    {track.call.source}
                  </td>
                }
                {
                  // reportData.permissions.call_call_report_extended &&
                  <td>
                    {track.call.area}
                  </td>
                }
                <td>{track.call.direction === 'outbound' ? formatPhoneNumber(track.call.call_from) : formatPhoneNumber(track.call.call_to)}</td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(track.call.extension)}
                  >
                    <span>
                      {track.call.extension}
                    </span>
                    <Icon
                      style={{
                        width: '16px',
                        height: '16px',
                        minWidth: '16px',
                        marginBottom: '3px',
                        cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                        fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                      }}
                      icon="phone-1" />
                  </div>
                </td>
                <td
                  onClick={() =>
                    // reportData.permissions.appointment_show &&
                    track.call.appointment_id &&
                    $router.router.navigate('appointments.item', {
                      companyId: activeAccountId, appointmentId: track.call.appointment_id
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    // reportData.permissions.appointment_show &&
                    track.call.appointment_id &&
                    handleChangeMenu(event, track.call.appointment_id, 'appointments', i)}
                >
                  {track.call.is_appointment ? (
                    <button
                      style={{ cursor: 'inherit' }}
                      className={classNames('_zeroed', '_iconed', {
                        _green: track.call.is_appointment === 'main',
                        _blue: track.call.is_appointment === 'lead'
                      })}>
                      <Icon style={{ minWidth: '18px' }} icon="clipboard-5" />
                    </button>
                  ) : null}
                  {
                    !!track.call.is_first ?
                      <div
                        style={{
                          backgroundColor: '#6093de',
                          borderRadius: '50%',
                          width: '16px',
                          height: '16px',
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: track.call.is_appointment ? '5px' : '0',
                          fontSize: '12px',
                        }}
                      >
                        <span>1</span>
                      </div> : null
                  }
                  {
                    // reportData.permissions.appointment_show &&
                    showContext === `appointments_${track.call.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId, appointmentId: track.call.appointment_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${track.call.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
                <td className={classNames({
                  'red-text': ['no-answer', 'busy', 'canceled'].includes(track.call.status),
                  'green-text': track.call.status === 'completed',
                  'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes(track.call.status),
                })}>{track.call.status} {track.call.no_answered_reason ? `(${track.call.no_answered_reason})` : ''} </td>
                <td>{track.call.duration}</td>
                {
                  // reportData.permissions.call_call_record &&
                  <td>
                    {track.call.call_url ? (
                      <button
                        className="_zeroed _iconed"
                        onClick={() => setLocalInterface({ ...localInterface, callListenPopup: track.call.call_url })}
                      >
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    ) : null}
                  </td>
                }
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.check_available_trackers.map((track, i) => (
              <tr style={{ cursor: 'inherit', position: 'relative' }} key={i}>
                <td>
                  <div>
                    {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, track.call.created_at)}
                  </div>
                  <div className={classNames({
                    'blue-text': !!track.is_no_today && !track.is_out,
                    'red-text': !!track.is_out,
                  })}
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => handleOpenZip(track.zip_code)}
                  >
                    {track.zip_code}

                    <div>
                      {
                        !!track.is_out &&
                        <Icon
                          style={{ fill: '#EB5757', width: '16px', marginLeft: '10px', minWidth: '16px' }}
                          icon="x-mark-1"
                        />
                      }
                      {
                        !!track.is_no_today && !track.is_out &&
                        <div style={{
                          color: '#fff',
                          width: '20px',
                          textAlign: 'center',
                          backgroundColor: '#6093de',
                          borderRadius: '50%',
                          padding: '2px',
                          marginLeft: '10px'
                        }}>
                          !
                        </div>
                      }
                    </div>
                  </div>

                  <div className="flex-container _gap-narrow">
                    <button className={classNames('_zeroed', '_iconed', {
                      _red: ['no-answer', 'busy', 'canceled'].includes(track.call.status),
                      _green: ['completed', 'ringing', 'in-progress'].includes(track.call.status) && track.call.direction === 'inbound',
                      _blue: track.call.direction === 'outbound'
                    })}>
                      <Icon
                        style={{ minWidth: '18px' }}
                        icon="arrow-20"
                        className={classNames({
                          '_rotated-180': track.call.direction === 'outbound'
                        })}
                      />
                    </button>
                    {
                      // reportData.permissions.call_call_show_dispatcher &&
                      <span>Dispatcher: {getDispatcherName(track.call.dispatcher, track.call.dispatcher_code, track.call.dispatcher_device)}</span>
                    }
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {track.call.caller_name}
                    </span>
                    {
                      track.call.caller_name &&
                      <button
                        style={{
                          marginLeft: '5px',
                          width: '16px',
                          height: '16px',
                          minHeight: '16px',
                          minWidth: '16px',
                          backgroundColor: 'inherit',
                          padding: '0'
                        }}
                        onClick={() => {
                          localStorage.setItem('copy_name', track.call.caller_name)
                          localStorage.setItem('copy_phone', track.call.call_from)
                        }}
                        title={track.call.caller_name}
                      >
                        <Icon
                          style={{
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            verticalAlign: 'middle',
                            overflow: 'hidden',
                            fill: 'inherit'
                          }}
                          viewBox="0 0 1024 1024"
                          icon="copy-1"
                        />
                      </button>
                    }
                  </div>
                  {track.call.client ? (
                    <div
                      className="flex-container"
                      style={{ gap: '5px', cursor: track.call.client_id ? 'pointer' : 'inherit' }}
                      onClick={() =>
                        track.call.client_id &&
                        $router.router.navigate('clients.item', {
                          companyId: activeAccountId, clientId: track.call.client_id
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        track.call.client_id &&
                        handleChangeMenu(event, track.call.client_id, 'clients', i)}
                    >
                      <button
                        style={{ gap: '5px', cursor: track.call.client_id ? 'pointer' : 'inherit' }}
                        className="_zeroed _iconed _gray">
                        <Icon style={{ minWidth: '18px' }} icon="user-32" />
                      </button>
                      <span>{track.call.client}</span>
                      {
                        track.call.client_id &&
                        showContext === `clients_${track.call.client_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId, clientId: track.call.client_id
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/clients/${track.call.client_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </div>
                  ) : <span>&nbsp;</span>}
                </td>
                <td>
                  <div>
                    From: {track.call.direction === 'outbound' ? formatPhoneNumber(track.call.call_to) : formatPhoneNumber(track.call.call_from)}
                  </div>
                  <div>To: {track.call.direction === 'outbound' ? formatPhoneNumber(track.call.call_from) : formatPhoneNumber(track.call.call_to)}</div>
                </td>
                <td>
                  <div>{track.call.friendly_name}</div>
                  {
                    // reportData.permissions.call_call_report_extended &&
                    <div> {track.call.source} </div>
                  }

                  {
                    // reportData.permissions.call_call_report_extended &&
                    <div> {track.call.area} </div>
                  }
                  <div
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                    onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(track.call.extension)}
                  >
                    <span>
                      Extension: {track.call.extension}
                    </span>
                    <span style={{ marginBottom: '1px' }} >
                      <Icon
                        style={{
                          width: '17px',
                          height: '17px',
                          minWidth: '17px',
                          marginLeft: '5px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }}
                        icon="phone-1" />
                    </span>
                  </div>
                </td>
                <td>
                  {track.call.is_appointment ? (
                    <button
                      className={classNames('_zeroed', '_iconed', {
                        _green: track.call.is_appointment === 'main',
                        _blue: track.call.is_appointment === 'lead'
                      })}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        // reportData.permissions.appointment_show &&
                        track.call.appointment_id &&
                        $router.router.navigate('appointments.item', {
                          companyId: activeAccountId, appointmentId: track.call.appointment_id
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        // reportData.permissions.appointment_show &&
                        track.call.appointment_id &&
                        handleChangeMenu(event, track.call.appointment_id, 'appointments', i)}
                    >
                      <Icon style={{ minWidth: '18px' }} icon="clipboard-5" />
                    </button>
                  ) : null}
                  {
                    !!track.call.is_first ?
                      <div
                        style={{
                          backgroundColor: '#6093de',
                          borderRadius: '50%',
                          width: '16px',
                          height: '16px',
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '0 auto',
                          marginTop: track.call.is_appointment ? '5px' : '0',
                          fontSize: '12px',
                        }}
                      >
                        <span>1</span>
                      </div> : null
                  }
                  {
                    // reportData.permissions.appointment_show &&
                    showContext === `appointments_${track.call.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId, appointmentId: track.call.appointment_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${track.call.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
                <td>
                  <div className={classNames({
                    'red-text': ['no-answer', 'busy', 'canceled'].includes(track.call.status),
                    'green-text': track.call.status === 'completed',
                    'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes(track.call.status),
                  })}>{track.call.status} {track.call.no_answered_reason ? `(${track.call.no_answered_reason})` : ''}</div>
                  <div className="flex-container _gap-narrow">
                    <div>{track.call.duration}</div>
                    {track.call.call_url ? (
                      <button className="_zeroed _iconed" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: track.call.call_url })}>
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.check_available_trackers.map((track, i: number) => (
              <div className="item" key={i}>
                <div className="__top">
                  <div className="__left">
                    <div className="flex-container">
                      <button className={classNames('_zeroed', '_iconed', {
                        _red: ['no-answer', 'busy', 'canceled'].includes(track.call.status),
                        _green: ['completed', 'ringing', 'in-progress'].includes(track.call.status) && track.call.direction === 'inbound',
                        _blue: track.call.direction === 'outbound'
                      })}>
                        <Icon
                          style={{ minWidth: '18px' }}
                          icon="arrow-20"
                          className={classNames({
                            '_rotated-180': track.call.direction === 'outbound'
                          })}
                        />
                      </button>
                      <strong>{track.call.friendly_name}</strong>
                    </div>
                    {
                      // reportData.permissions.call_call_report_extended &&
                      <div> {track.call.source} </div>
                    }

                    {
                      // reportData.permissions.call_call_report_extended &&
                      <div> {track.call.area} </div>
                    }
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          <strong>{track.call.caller_name}</strong>
                        </span>
                        {
                          track.call.caller_name &&
                          <button
                            style={{
                              marginLeft: '5px',
                              width: '16px',
                              height: '16px',
                              minHeight: '16px',
                              minWidth: '16px',
                              backgroundColor: 'inherit',
                              padding: '0'
                            }}
                            onClick={() => {
                              localStorage.setItem('copy_name', track.call.caller_name)
                              localStorage.setItem('copy_phone', track.call.call_from)
                            }}
                            title={track.call.caller_name}
                          >
                            <Icon
                              style={{
                                width: '16px',
                                height: '16px',
                                minWidth: '16px',
                                verticalAlign: 'middle',
                                overflow: 'hidden',
                                fill: 'inherit'
                              }}
                              viewBox="0 0 1024 1024"
                              icon="copy-1"
                            />
                          </button>
                        }
                      </div>
                    </div>
                    {track.call.client ? (
                      <div
                        className="flex-container"
                        style={{ gap: '5px', cursor: track.call.client_id ? 'pointer' : 'inherit' }}
                        onClick={() =>
                          track.call.client_id &&
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId, clientId: track.call.client_id
                          }, { reload: true })}
                      >
                        <button
                          style={{ gap: '5px', cursor: track.call.client_id ? 'pointer' : 'inherit' }}
                          className="_zeroed _iconed _gray">
                          <Icon style={{ minWidth: '18px' }} icon="user-32" />
                        </button>
                        <span>{track.call.client}</span>
                      </div>
                    ) : (<span>&nbsp;</span>)}
                  </div>

                  <div className="__right">
                    <div className={classNames({
                      'red-text': ['no-answer', 'busy', 'canceled'].includes(track.call.status),
                      'green-text': track.call.status === 'completed',
                      'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes(track.call.status),
                    })}>
                      {track.call.status} {track.call.no_answered_reason ? `(${track.call.no_answered_reason})` : ''}
                    </div>
                    <div className="flex-container">
                      <span>{track.call.duration}</span>
                      {track.call.call_url ? (
                        <button className="_zeroed _iconed" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: track.call.call_url })}>
                          <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                        </button>
                      ) : null}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {track.call.is_appointment ? (
                        <button
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            // reportData.permissions.appointment_show &&
                            track.call.appointment_id &&
                            $router.router.navigate('appointments.item', {
                              companyId: activeAccountId, appointmentId: track.call.appointment_id
                            }, { reload: true })}
                          className={classNames('_zeroed', '_iconed', {
                            _green: track.call.is_appointment === 'main',
                            _blue: track.call.is_appointment === 'lead'
                          })}>
                          <Icon style={{ minWidth: '18px' }} icon="clipboard-5" />
                        </button>
                      ) : null}

                      {
                        !!track.call.is_first ?
                          <div
                            style={{
                              backgroundColor: '#6093de',
                              borderRadius: '50%',
                              width: '16px',
                              height: '16px',
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginLeft: track.call.is_appointment ? '5px' : '0',
                              fontSize: '12px',
                            }}
                          >
                            <span>1</span>
                          </div> : null
                      }
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">From:</span>
                      <span>
                        {track.call.direction === 'outbound' ? formatPhoneNumber(track.call.call_to) : formatPhoneNumber(track.call.call_from)}
                      </span>
                    </div>
                    <div>
                      <span className="gray">To:</span>
                      <span> {track.call.direction === 'outbound' ? formatPhoneNumber(track.call.call_from) : formatPhoneNumber(track.call.call_to)}</span>
                    </div>
                    <div
                      style={{ display: 'flex' }}
                      onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(track.call.extension)}
                    >
                      <span className="gray">Extension:</span>
                      <span style={{ margin: '0 5px' }} > {track.call.extension}</span>
                      <span>
                        <Icon style={{
                          width: '16px',
                          height: '16px',
                          minWidth: '16px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }} icon="phone-1" />
                      </span>
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, track.call.created_at)}
                    </div>
                    <div className={classNames({
                      'blue-text': !!track.is_no_today && !track.is_out,
                      'red-text': !!track.is_out
                    })}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => handleOpenZip(track.zip_code)}
                    >
                      {track.zip_code}

                      <div>
                        {
                          !!track.is_out &&
                          <Icon
                            style={{ fill: '#EB5757', width: '16px', marginLeft: '10px', minWidth: '16px' }}
                            icon="x-mark-1"
                          />
                        }
                        {
                          !!track.is_no_today && !track.is_out &&
                          <div style={{
                            color: '#fff',
                            width: '20px',
                            textAlign: 'center',
                            backgroundColor: '#6093de',
                            borderRadius: '50%',
                            padding: '2px',
                            marginLeft: '10px'
                          }}>
                            !
                          </div>
                        }
                      </div>
                    </div>
                    {
                      // reportData.permissions.call_call_show_dispatcher &&
                      <div>
                        Dispatcher: {getDispatcherName(track.call.dispatcher, track.call.dispatcher_code, track.call.dispatcher_device)}
                      </div>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.check_available_tracker?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, check_available_tracker: { ...cookiesSetting.check_available_tracker, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        {/* Call listen popup */}
        {localInterface.callListenPopup ? (
          <div className="popup callListenPopup" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: null })}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="call-player">
                <AudioPlayer audioSrc={localInterface.callListenPopup} />
              </div>
            </div>
          </div>
        ) : null}

        {/* View map */}
        {
          selectedPlace ?
            selectedPlace.isHave ?
              <div
                className="popup"
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => setSelectedPlace(null)}
              >
                <div
                  style={{
                    width: '65%',
                    textAlign: 'center',
                    height: 'calc(65vh - 65px)',
                    backgroundColor: '#fff',
                    borderRadius: '5px'
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{ fontWeight: 'bold', marginRight: '20px' }}
                    >
                      Zip: {selectedPlace.zip_code}
                    </p>

                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => setSelectedPlace(null)}
                    >
                      <span>Close</span>
                    </button>
                  </div>

                  <ViewMap
                    selectedZipCode={[selectedPlace]}
                    isLoaded={isLoaded}
                    loadError={loadError}
                  />
                </div>
              </div> :
              <div className="popup item-delete-popup">
                <div
                  style={{ maxWidth: '320px' }}
                  className="wrapper"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="title"
                    style={{
                      background: '#6093de',
                      color: '#fff',
                      fontSize: '16pt',
                      padding: '7px 12px',
                      margin: '0 -30px',
                      textAlign: 'center',
                    }}
                  >
                    Zip code not found!
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gridGap: '10px',
                      gap: '10px',
                      marginTop: '30px',
                      justifyContent: 'center'
                    }}
                  >
                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => setSelectedPlace(null)}
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            : null
        }

        {/* Call extentions */}
        {extensionCall ? (
          <div className="popup item-delete-popup">

            <div style={{ maxWidth: '350px', padding: '20px' }} className="wrapper" onClick={(e) => e.stopPropagation()}>
              <div
                className="title"
                style={{
                  background: '#6093de',
                  color: '#fff',
                  fontSize: '16pt',
                  padding: '7px 12px',
                  margin: '0 -20px',
                  textAlign: 'center'
                }}
              >
                <p style={{ margin: '0' }}>
                  Are you sure you want to call to
                </p>
                <p style={{ margin: '0' }}>
                  ext {extensionCall}
                </p>
              </div>

              <div
                className="buttons"
                style={{
                  display: 'flex',
                  gridGap: '10px',
                  gap: '10px',
                  marginTop: '30px',
                  justifyContent: 'space-around'
                }}
              >
                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => setExtensionCall('')}
                >
                  <span>No</span>
                </button>

                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => handleCall()}
                >
                  <span>Yes</span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default CheckAvailableTrackerPage_List
