import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'

import Icon from '../../components/Icon'
import Switcher from '../../components/Switcher'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    settings: SettingsProps
  }
}

interface SettingsProps {
  code_body_end: string
  code_body_start: string
  code_head_end: string
  code_head_start: string
  conversion_token: string
  conversion_url: string
  is_phone_code_by_ip: boolean
  is_send_actions: boolean
  is_send_conversions: boolean
  is_send_request_form: boolean
  request_token: string
  request_url: string
  template: string
  type: string
}

export default function Settings() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState<SettingsProps | null>(null)
  const [report, setReport] = useState<SettingsProps | null>(null)

  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    setReadyToSave(true)
  }, [data])

  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/websites/settings?account_id=88888&website_id=888888001111111111
      const { data: { data: response, success, error } } = (await httpClientUpdate.get(`/websites/settings`, {
        params: {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId
        }
      })) as { data: HttpReport }
      if (success) {
        setData(response.settings)
        setReport(response.settings)

        setTimeout(() => setReadyToSave(false), 100)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    try {
      if (data) {
        setIsSaving(true)
        // https://2022back4.artemiudintsev.com/api/websites/settings
        const response = await httpClientUpdate.put(`/websites/settings`, {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
          code_body_end: data.code_body_end,
          code_body_start: data.code_body_start,
          code_head_end: data.code_head_end,
          code_head_start: data.code_head_start,
          conversion_token: data.conversion_token,
          conversion_url: data.conversion_url,
          is_phone_code_by_ip: data.is_phone_code_by_ip ? 1 : 0,
          is_send_actions: data.is_send_actions ? 1 : 0,
          is_send_conversions: data.is_send_conversions ? 1 : 0,
          is_send_request_form: data.is_send_request_form ? 1 : 0,
          request_token: data.request_token,
          request_url: data.request_url,
          template: data.template,
          type: data.type
        })

        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadData()
        } else {
          setIsSaving(false)
        }
      }

    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleCancelEdit() {
    if (report) {
      setData(report)
      setEditing(false)
    }
  }

  return (
    <>
      <div className="ListsPage_Appliances">
        <div className="top-nav top-nav-list">
          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.main"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.main" })}
            >
              <Icon icon="task-1" />
              <span>Main</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.settings"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.settings" })}
            >
              <Icon icon="task-1" />
              <span>Settings</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.pages"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.pages" })}
            >
              <Icon icon="task-1" />
              <span>Pages</span>
            </BaseLink>
          </div>

          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.phone_codes"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "phone_codes" })}
            >
              <Icon icon="task-1" />
              <span>Phone Codes</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.phones"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.phones" })}
            >
              <Icon icon="task-1" />
              <span>Phones</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.links"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.links" })}
            >
              <Icon icon="task-1" />
              <span>Links</span>
            </BaseLink>
          </div>
        </div>
      </div>

      {
        data &&
        <div className="AccountsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Website (Settings):</h1>

              <div className="item-name">
                {$router.router.getState().params.domain}
              </div>
            </div>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'websites',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Template:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.template}
                    onChange={({ target: { value } }) => setData({ ...data, template: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Type:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.type}
                    onChange={({ target: { value } }) => setData({ ...data, type: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Code</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Head Start:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.code_head_start}
                    onChange={({ target: { value } }) => setData({ ...data, code_head_start: value })}
                  />
                </div>

                <div className="field">
                  <span>Head End:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.code_head_end}
                    onChange={({ target: { value } }) => setData({ ...data, code_head_end: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Body Start:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.code_body_start}
                    onChange={({ target: { value } }) => setData({ ...data, code_body_start: value })}
                  />
                </div>

                <div className="field">
                  <span>Body End:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.code_body_end}
                    onChange={({ target: { value } }) => setData({ ...data, code_body_end: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Request</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Token:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.request_token}
                    onChange={({ target: { value } }) => setData({ ...data, request_token: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Url:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.request_url}
                    onChange={({ target: { value } }) => setData({ ...data, request_url: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Conversion</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Token:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.conversion_token}
                    onChange={({ target: { value } }) => setData({ ...data, conversion_token: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Url:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.conversion_url}
                    onChange={({ target: { value } }) => setData({ ...data, conversion_url: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Switches</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Phone Code by Ip:</span>

                  <Switcher
                    disabled={!editing}
                    checked={!!data.is_phone_code_by_ip}
                    onChange={(value) => setData({ ...data, is_phone_code_by_ip: value })}
                  />
                </div>

                <div className="field">
                  <span>Send Actions:</span>

                  <Switcher
                    disabled={!editing}
                    checked={!!data.is_send_actions}
                    onChange={(value) => setData({ ...data, is_send_actions: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Send Conversions:</span>

                  <Switcher
                    disabled={!editing}
                    checked={!!data.is_send_conversions}
                    onChange={(value) => setData({ ...data, is_send_conversions: value })}
                  />
                </div>

                <div className="field">
                  <span>Send Request Form:</span>

                  <Switcher
                    disabled={!editing}
                    checked={!!data.is_send_request_form}
                    onChange={(value) => setData({ ...data, is_send_request_form: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}
        </div>
      }
    </>
  )
}
