import { useState, useEffect } from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'

import Select from '../../components/Select'
import Checkbox from "../../components/Checkbox"

import { EditProps, ServiceOtionsProps, TemplateProps } from './List'
import { capitalizeFirstLetter } from '../../funcs'

interface NewTicketBlockProps {
  ticketData: TicketProps
  setTicketData: (value: TicketProps) => void
  message: string
  setMessage: (value: string) => void
  template: TemplateProps[]
  jobName?: string
  serviceOptions: ServiceOtionsProps[]
  selectedService: ServiceProps
  setSelectedService: (value: ServiceProps) => void
  edit: EditProps | null
  sendTo: SendToProps
  setSendTo: (value: SendToProps) => void
  saveTicket: () => void
  sendToFromEdit: {}
  setSendToFromEdit: (value: {}) => void
  isReserve: boolean
  isSaving: boolean
}

interface ServiceProps {
  name: string
  code: number
  id: string
}

interface TicketProps {
  created_at: Date
  number: number
  status: string
  type: string
  theme: string
  author: string
}

interface SendToProps {
  service_resource: boolean
  author: boolean
}

export default function NewTicketBlock({
  ticketData,
  setTicketData,
  message,
  setMessage,
  template,
  jobName,
  serviceOptions,
  selectedService,
  setSelectedService,
  edit,
  sendTo,
  setSendTo,
  saveTicket,
  sendToFromEdit,
  setSendToFromEdit,
  isReserve,
  isSaving
}: NewTicketBlockProps) {
  const [tempateOptions, setTempateOptions] = useState<TemplateProps[] | []>([])

  const [useTemplate, setUseTemplate] = useState('')

  useEffect(() => {
    if (template.length) {
      let uniqTemplates: TemplateProps[] = []
      template.forEach(temp => {
        let partTemplates = uniqTemplates.map(item => item.type)
        if (!partTemplates.includes(temp.type)) {
          uniqTemplates.push({ ...temp })
        }
      })
      setTempateOptions(uniqTemplates)
    }

  }, [template])

  useEffect(() => {
    if (ticketData.type) {
      template.forEach(item => {
        if (item.type === ticketData.type) {
          if (item.responsibles) {
            let types = item.responsibles.split(', ')
            types = types.map(item => capitalizeFirstLetter(item))
            setSendTo({
              service_resource: serviceOptions.length ? types.includes('Service resource') : false,
              author: types.includes('Author'),
            })
            let updatedSend = {}
            Object.keys(sendToFromEdit).forEach(item => {
              if (types.includes(item)) {
                updatedSend[item] = true
              } else {
                updatedSend[item] = false
              }
            })
            setSendToFromEdit(updatedSend)
          } else {
            setSendTo({
              service_resource: false,
              author: false,
            })
            let updatedSend = {}
            Object.keys(sendToFromEdit).forEach(item => {
              updatedSend[item] = false
            })
            setSendToFromEdit(updatedSend)
          }
        }
      })
    } else {
      setSendTo({
        service_resource: false,
        author: false,
      })
      let updatedSend = {}
      Object.keys(sendToFromEdit).forEach(item => {
        updatedSend[item] = false
      })
      setSendToFromEdit(updatedSend)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData.type, serviceOptions])

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && saveTicket()
    }
  }

  function handleSelectTemplate(textTemplate: string) {
    if (useTemplate === '') {
      setMessage(`${textTemplate} ${message}`)
      setUseTemplate(textTemplate)
    } else {
      if (useTemplate !== textTemplate) {
        if (message.includes(useTemplate)) {
          setMessage(`${message.replace(useTemplate, textTemplate)}`)
          setUseTemplate(textTemplate)
        } else {
          setMessage(`${textTemplate} ${message}`)
          setUseTemplate(textTemplate)
        }
      }
    }
  }

  function handleClearMessage() {
    setMessage('')
    setUseTemplate('')
  }

  function checkSendTo() {
    let check = false
    Object.keys(sendTo).forEach(item => {
      if (sendTo[item]) {
        check = true
      }
    })
    Object.keys(sendToFromEdit).forEach(item => {
      if (sendToFromEdit[item]) {
        check = true
      }
    })

    return check
  }

  return (
    <div className='entity-edit'>
      <div className="fieldset">
        <div className="block-content-title">
          <div className="block-title">
            Create Ticket
          </div>
        </div>

        <div
          className="fieldset"
          style={{ margin: '0', padding: '0' }}
        >
          <div className="fields">
            <div className="__left">

              <div className="field">
                <span>Date:</span>
                <Datetime
                  value={ticketData.created_at}
                  onChange={(value) => setTicketData({ ...ticketData, created_at: moment.isMoment(value) ? value.toDate() : new Date() })}
                />
              </div>

              <div className="field">
                <span>Status:</span>
                {
                  edit?.status &&
                  <Select
                    disabled={true}
                    options={edit.status.map((option) => ({
                      span: option.name,
                      value: option.name
                    }))}
                    selectedOption={ticketData.status as string}
                    onChange={(value) => setTicketData({ ...ticketData, status: value as string })}
                  />
                }
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Type:</span>
                <Select
                  options={tempateOptions.map((option) => ({
                    span: option.type,
                    value: option.type
                  }))}
                  selectedOption={ticketData.type as string}
                  onChange={(value) => setTicketData({ ...ticketData, type: value as string })}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="fieldset ticket-checkbox"
          style={{ padding: '0' }}
        >
          <div className="block-title">
            Send To:
          </div>

          <div
            className="fields"
            style={{ gridGap: 'inherit' }}
          >
            <div className="__left">
              <div className="field">
                <span>Author:</span>
                <Checkbox
                  contents=""
                  value={sendTo.author}
                  onChange={(value) => setSendTo({ ...sendTo, author: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Service Resource:</span>
                <Checkbox
                  contents={serviceOptions.length > 1 ? '' : `${selectedService.code ? `${selectedService.name} (${selectedService.code})` : selectedService.name}`}
                  disabled={!serviceOptions.length}
                  value={sendTo.service_resource}
                  onChange={(value) => setSendTo({ ...sendTo, service_resource: value })}
                />
                {
                  serviceOptions.length > 1 &&
                  <Select
                    options={serviceOptions.map((option) => ({
                      span: option.service_resource_code ? `${option.name} (${option.service_resource_code})` : option.name,
                      value: option.id
                    }))}
                    selectedOption={selectedService.id as string}
                    onChange={(value) => setSelectedService({ ...selectedService, id: value as string })}
                  />
                }
              </div>
            </div>
          </div>

          <div
            className="fields"
            style={{ gridGap: 'inherit', marginTop: '0' }}
          >
            {
              edit?.user_groups.map((item, index) => {
                if (index === 0 || index % 2 === 0) {
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex', width: '50%' }}
                    >
                      <div className="field">
                        <span>{capitalizeFirstLetter(item.name)}:</span>
                        <Checkbox
                          contents=""
                          value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                          onChange={(value) => setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })}
                        />
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex', width: '50%' }}
                    >
                      <div className="field">
                        <span>{capitalizeFirstLetter(item.name)}:</span>
                        <Checkbox
                          contents=''
                          value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                          onChange={(value) => setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })}
                        />
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>

        <div
          className="fieldset"
          style={{
            margin: '0',
            border: '1px solid #d0d3da',
            padding: '10px 0 0 0',
          }}
        >
          <span style={{ margin: '10px' }} >Templates:</span>
          <div
            className="fields"
            style={{ flexDirection: 'column', gridGap: 'inherit' }}
          >
            {
              template.map((temp, index) => {
                if (temp.type === ticketData.type) {
                  return (
                    <div
                      key={index}
                      className="field template"
                    >
                      <p
                        onClick={() => handleSelectTemplate(temp.template.replaceAll('[job_name]', `${jobName ? jobName : ''}`))}
                      >
                        {temp.template.replaceAll('[job_name]', `${jobName ? jobName : ''}`)}
                      </p>
                    </div>
                  )
                } else {
                  return null
                }
              })
            }
          </div>
        </div>

        <div
          className="fieldset"
          style={{ margin: '0', padding: '0' }}
        >
          <div
            style={{
              justifyContent: 'space-between',
              gridGap: 'inherit',
              textAlign: 'center'
            }}
            className="fields"
          >
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              position: 'relative'
            }}>
              <textarea
                style={{ width: '100%', resize: 'none' }}
                placeholder='Your Message'
                value={message}
                onChange={({ target: { value } }) => setMessage(value)}
                onKeyDown={(event) => handleChangeMessage(event)}
              />

              <div
                style={{
                  position: 'absolute',
                  right: '5px',
                  bottom: '5px',
                  display: 'flex'
                }}
              >
                <button
                  className='clear'
                  disabled={message === ''}
                  onClick={() => handleClearMessage()}
                >
                  Clear
                </button>

                <button
                  className='send'
                  disabled={message === '' || ticketData.type === '' || !checkSendTo() || (sendTo.service_resource && selectedService.id === '') || isReserve || isSaving}
                  onClick={() => saveTicket()}
                >
                  Create
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

