import { memo, useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from '../Icon'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

interface HttpGetContactReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    clients: ContactProps[]
  }
}

interface ContactProps {
  company_name: string
  first_name: string
  last_name: string
  phone: string
}

interface ContactsContentProps {
  handleSelectedContact: (value: ContactProps) => void
  softphone_phonebook: boolean | undefined
}

function ContactsContent({ handleSelectedContact, softphone_phonebook }: ContactsContentProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [search, setSearch] = useState('')
  const [contactData, setContactData] = useState<ContactProps[] | []>([])

  const [time, setTime] = useState(0);

  // Get contacts
  async function getContacts() {
    // https://2022back4.artemiudintsev.com/api/callcenter/phonebooks?account_id=88888&search=18137898778
    try {
      const { data: { data: contactData, success, error } } = await httpClientUpdate.get('/callcenter/phonebooks',
        {
          params: {
            account_id: activeAccountId,
            search: search
          }
        }) as { data: HttpGetContactReport }
      if (success) {
        setContactData(contactData.clients)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    if (search.length > 1 && time === 1) {
      getContacts()
    }
    if (search.length < 2) {
      setContactData([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, time])

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (search.length > 1 && time < 2) {
      intervalId = setInterval(() => setTime(time + 1), 1000);
    }

    return () => clearInterval(intervalId);
  }, [search, time]);

  function getName(company: string, firstName: string, lastName: string) {
    let stringName = ''
    if (!firstName && !lastName && company) {
      stringName = company
    } else if (!company) {
      stringName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    } else if (firstName && lastName && company) {
      stringName = `${firstName} ${lastName} (${company})`
    } else if (!firstName && !lastName && !company) {
      stringName = 'Unknown'
    } else {
      stringName = `${firstName ? firstName : ''} ${lastName ? lastName : ''} (${company})`
    }

    return stringName
  }

  return (
    <div className='section__account_softphone-content_item section__account_softphone-content_contacts'>
      <div className='section__account_softphone-content_contacts-search'>
        <div className='section__account_softphone-content_contacts-icon'>
          <Icon viewBox="0 0 18 18" style={{ fill: '#8E8E93' }} icon="search-1" />

        </div>

        <input
          value={search}
          disabled={!softphone_phonebook}
          onChange={(event) => {
            setSearch(event.target.value)
            setTime(0)
          }
          }
          className='section__account_softphone-content_contacts-input'
          type="text"
        />
      </div>

      <div className='section__account_softphone-content_contacts-list'>
        {
          contactData.map((contact, index) => (
            <div
              key={index}
              className='section__account_softphone-content_contacts-item'
              style={{ fontSize: '12px' }}
              onClick={() => handleSelectedContact(contact)}
            >
              <div className='section__account_softphone-content_contacts-name'>
                <span
                  title={getName(contact.company_name, contact.first_name, contact.last_name)}>
                  {getName(contact.company_name, contact.first_name, contact.last_name)}
                </span>
              </div>

              <div className='section__account_softphone-content_contacts-number'>
                {contact.phone}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default memo(ContactsContent)