import { memo, useEffect, useState } from 'react'
import { useRoute } from "react-router5"
import { DateTime } from 'luxon'
import qs from 'qs'

import AccountSvg from '../AccountSvg'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { Call } from '../../models/Calls'
import classNames from 'classnames'
import Icon from '../Icon'
import { DateRangePreset } from '../../models/Misc'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallsReport
}

interface CallsReport {

  permissions: {
    appointment_show: boolean
    call_area_report: boolean
    call_call_record: boolean
    call_call_report: boolean
    call_call_show_dispatcher: boolean
    call_number_report: boolean
    call_source_report: boolean
    client_show: boolean
  },

  dashboard: {
    calls: string,
    callers: string,
    missed_calls: string,
    active_calls: string
  },

  calls: Call[]
}

interface CallsContactProps {
  phoneCallStatus: string
  handleCallExtension: (value: string) => void
  setTap: (value: string) => void
  call_call_report: boolean | undefined
  softphone_call_extension: boolean | undefined
}

function CallsContent({
  phoneCallStatus,
  handleCallExtension,
  setTap,
  call_call_report,
  softphone_call_extension
}: CallsContactProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [calls, setCalls] = useState<Call[] | []>([])
  const [selectedPhone, setSelectedPhone] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localInterface, setLocalInterface] = useState({

    search: '',

    callListenPopup: null as string | null,

    dateRangeCalendarShown: false,
    dateRangePreset: "custom" as DateRangePreset,


    min_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
    max_date: DateTime.now().setZone(timeZone).endOf('day').toJSDate(),
    page: 1,

    filter_words: {

      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
    },

    tag_words: [] as string[],

    searchFilters: [["source", "system"], ["inbound", "outbound"]],
    selectedSearchFilters: { type: ['source'] as string[], direction: [] as string[] },

    sort: {
      field: 'created_at',
      direction: 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Dispatcher',
      value: 'dispatcher'
    }, {
      span: 'Caller Name',
      value: 'caller_name'
    }, {
      span: 'Client',
      value: 'client'
    }, {
      span: 'From',
      value: 'call_from'
    }, {
      span: 'Friendly Name',
      value: 'friendly_name'
    }, {
      span: 'Extension',
      value: 'extension'
    }, {
      span: 'Status',
      value: 'status'
    }, {
      span: 'Duration',
      value: 'duration'
    }]

  })

  // Load calls function
  async function loadCalls() {
    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key]);

    let reqData = {
      account_id: activeAccountId,
      limit_rows: JSON.stringify(15),
      page: JSON.stringify(localInterface.page),
      date_start: localInterface.min_date,
      date_end: localInterface.max_date,
      filter_tag: JSON.stringify(localInterface.tag_words),
      main_filter: JSON.stringify(localInterface.selectedSearchFilters),
      sort_field: localInterface.sort.field,
      sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
      filter_field: JSON.stringify(activeFilters),
      ...(localInterface.search ? { search: localInterface.search } : {})
    };

    try {
      const { data: { data: calls, success, error } } = (await httpClientUpdate.post('/calls/report/calls', qs.stringify(reqData))) as { data: HttpClientUpdateReport }
      if (success) {
        if (calls.permissions.call_call_report) {
          setCalls(calls.calls)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load calls on page mount
  useEffect(() => {
    call_call_report && loadCalls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call_call_report])

  function handleCall() {
    handleCallExtension(selectedPhone)
    setTap('Home')
    setSelectedPhone('')
  }

  return (
    <div className='section__account_softphone-content_item section__account_softphone-content_calls active'>
      <div className='section__account_softphone-content_calls-list'>
        {
          calls.map((item, index) => (
            <div
              key={index}
              className={`section__account_softphone-content_calls-item ${item.direction}`}
              onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setSelectedPhone(item.extension)}
              style={{ cursor: softphone_call_extension && phoneCallStatus !== 'Not Available' ? 'pointer' : 'inherit' }}
            >
              <div className='section__account_softphone-content_calls-symbol'>
                <div
                  className={classNames('section__account_softphone-content_home-item_infoblock-item_icon', {
                    _red: ['no-answer', 'busy'].includes(item.status),
                    _green: ['completed', 'ringing', 'in-progress'].includes(item.status) && item.direction === 'inbound',
                    _blue: item.direction === 'outbound'
                  })}
                >
                  <Icon icon="arrow-20" style={{ transform: item.direction === 'outbound' ? 'rotate(180deg)' : 'inherit' }} />
                </div>
              </div>

              <div className='section__account_softphone-content_calls-data'>
                {
                  item.client &&
                  <div className='section__account_softphone-content_calls-contIcon'>
                    <AccountSvg />
                  </div>
                }

                {
                  item.direction === 'inbound' &&
                  <p> {item.client} {item.call_from} {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} </p>
                }

                {
                  item.direction === 'outbound' &&
                  <p> {item.client} {item.call_to} {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} </p>
                }
              </div>
            </div>
          ))
        }
      </div>

      {
        selectedPhone &&
        <div className="popup item-delete-popup">

          <div style={{ maxWidth: '350px', padding: '20px' }} className="wrapper" onClick={(e) => e.stopPropagation()}>
            <div
              className="title"
              style={{
                background: '#6093de',
                color: '#fff',
                fontSize: '16pt',
                padding: '7px 12px',
                margin: '0 -20px',
                textAlign: 'center'
              }}
            >
              <p style={{ margin: '0', fontSize: '16px' }}>
                Are you sure you want to call to
              </p>
              <p style={{ margin: '0', fontSize: '16px' }}>
                ext {selectedPhone}
              </p>
            </div>

            <div
              className="buttons"
              style={{
                display: 'flex',
                gridGap: '10px',
                gap: '10px',
                marginTop: '30px',
                justifyContent: 'space-around'
              }}
            >
              <button
                className="_wa _iconed _bordered _blue"
                onClick={() => setSelectedPhone('')}
              >
                <span>No</span>
              </button>

              <button
                className="_wa _iconed _bordered _blue"
                onClick={() => handleCall()}
              >
                <span>Yes</span>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default memo(CallsContent)