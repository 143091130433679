import { useEffect, useState, useCallback, useRef } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import ReactInputMask from "react-input-mask"
import ReactCrop, { Crop } from "react-image-crop"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Switcher from "../../components/Switcher"
import SelectWithSearchComponent from "../../components/SelectWithSearch";

// import User from "../../models/User"
import UserCustom from "../../models/UserCustom"
import { UsersReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, validateEmail } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UsersReport
}

interface ActionPermissionsProps {
  name: string
  users_permissions_id: string
}

interface ActionMenusProps {
  name: string
  users_menus_id: string
}

function generateDownload(canvas: {
  toDataURL(arg0: string, arg1: number): any; toBlob: (arg0: (blob: Blob | MediaSource) => void, arg1: string, arg2: number) => void;
}, crop: any) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob: Blob | MediaSource) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let file = new File([blob as Blob], "fileName.jpg", { type: "image/jpeg" })
    },
    'image/png',
    1
  );
  return canvas.toDataURL("image/jpeg", 0.95)
}

function setCanvasImage(image: { naturalWidth: number; width: number; naturalHeight: number; height: number; }, canvas: { getContext: (arg0: string) => any; width: number; height: number; }, crop: { width: number; height: number; x: number; y: number; }) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

}

function UsersPage_New() {
  const $router = useRoute()

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false)

  const [upImg, setUpImg] = useState('');

  const [crop, setCrop] = useState<Partial<Crop>>({ unit: 'px', width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [readyToSave, setReadyToSave] = useState(false)
  const [isExistUser, setIsExistUser] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [avatarPopup, setAvatarPopup] = useState(false)

  const [inputTypes, setInputTypes] = useState({
    password: 'password' as 'text' | 'password',
  })

  const [editions, setEditions] = useState<{
    time_zone: {
      id: number
      name: string
    }[],
    permissions: {
      name: string
      users_permissions_id: string
    }[],
    state: {
      id: number
      name: string
    }[],
    users_menus: {
      name: string
      users_menus_id: string
    }[]
  } | null>(null)

  const [newUserData, setNewUserData] = useState<Partial<UserCustom>>({

  })
  const [draging, setDraging] = useState(false)

  const [availablePermissions, setAvailablePermissions] = useState<ActionPermissionsProps[] | []>([])
  const [availableMenus, setAvailableMenus] = useState<ActionMenusProps[] | []>([])

  const [newPermissions, setNewPermissions] = useState<ActionPermissionsProps[] | []>([])
  const [newMenus, setNewMenus] = useState<ActionMenusProps[] | []>([])

  const [selectedPermissions, setSelectedPermissions] = useState('')
  const [selectedMenus, setSelectedMenus] = useState('')

  // save document
  async function handleSave() {
    try {
      setIsSaving(true)
      if (newUserData.email && !validateEmail(newUserData.email)) {
        let updatedErrors = errorFields.map(item => item)
        updatedErrors.push('email')
        setErrorFields(updatedErrors)
        setIsSaving(false)
      } else {

        httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

        let reqData: {
          account_id: string | null,
          email: string | undefined,
          first_name: string | undefined,
          last_name: string | undefined,
          time_zone_id: string | null,
          available: string | undefined,
          function: string | undefined,
          phone: string | undefined,
          user_permissions: string[],
          user_menus: string[],
          address_street: string | undefined,
          address_state_id: string | null,
          address_unit: string | undefined,
          address_zip: string | undefined,
          address_city: string | undefined,
          password?: string,
          photo_link?: string,
        } = {
          account_id: activeAccountId,
          email: newUserData.email,
          first_name: newUserData.first_name,
          last_name: newUserData.last_name,
          time_zone_id: newUserData.time_zone ? `${newUserData.time_zone}` : null,
          available: newUserData.active_status,
          function: newUserData.function,
          phone: newUserData.phone && formatPhoneNumberToServerString(newUserData.phone),
          user_permissions: newPermissions.map(item => item.users_permissions_id),
          user_menus: newMenus.map(item => item.users_menus_id),
          address_street: newUserData.street,
          address_state_id: newUserData.State ? `${newUserData.State}` : null,
          address_unit: newUserData.unit,
          address_zip: newUserData.zip,
          address_city: newUserData.city
        }

        if (newUserData.password) {
          reqData.password = newUserData.password
        }
        if (previewCanvasRef.current && upImg) {
          reqData.photo_link = generateDownload(previewCanvasRef.current, crop)
        }

        const response = await httpClientUpdate.post('/users', {
          ...reqData
        })

        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('users', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)

      if (createdError.content.errorText === 'User exist') {
        setIsExistUser(true)
        setReadyToSave(false)
      }
      if (createdError.content.errorFields && createdError.content.errorFields.length) {
        setErrorFields(createdError.content.errorFields)
        setReadyToSave(false)
      }
    }
  }

  // Load info function
  async function loadInfo() {
    try {

      const { data: { data: usersData, success, error } } = (await httpClientUpdate.post('/users/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 10,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (usersData.permissions.user_add) {
          setAvailablePermissions(usersData.edit.permissions)
          setAvailableMenus(usersData.edit.users_menus)
          setSelectedPermissions('')
          setSelectedMenus('')
          setNewPermissions([])
          setNewMenus([])

          setEditions(usersData.edit)

          setNewUserData({
            first_name: '',
            last_name: '',
            password: '',
            function: '',
            phone: '',
            street: '',
            unit: '',
            city: '',
            zip: '',
            time_zone: 0,
            State: '',
            active_status: 'Inactive'
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load user data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add permissions function
  function addAction() {
    if (!editions || !newUserData) return

    let updatePermissions = newPermissions.map(item => item)
    editions.permissions.forEach(item => item.users_permissions_id === selectedPermissions && updatePermissions.push(item))

    setNewPermissions(updatePermissions)

    let _availablePermissions = editions.permissions.filter(permission => !updatePermissions.map(item => item.users_permissions_id).includes(permission.users_permissions_id))
    setAvailablePermissions(_availablePermissions)

    setSelectedPermissions('')
    setReadyToSave(true)
  }

  // Remove action function
  function removeAction(i: number) {

    let _permissionActions = newPermissions.map(item => item)

    let _action = _permissionActions[i]

    _permissionActions.splice(i, 1)

    setNewPermissions(_permissionActions)

    setAvailablePermissions([
      ...availablePermissions,
      _action
    ])

    setReadyToSave(true)
  }

  // Add menus function
  function addMenusAction() {
    if (!editions || !newUserData) return

    let updateMenus = newMenus.map(item => item)
    editions.users_menus.forEach(item => item.users_menus_id === selectedMenus && updateMenus.push(item))

    setNewMenus(updateMenus)

    let _availableMenus = editions.users_menus.filter(menus => !updateMenus.map(item => item.users_menus_id).includes(menus.users_menus_id))
    setAvailableMenus(_availableMenus)

    setSelectedMenus('')
    setReadyToSave(true)
  }

  // Remove action function
  function removeMenusAction(i: number) {

    let _menusActions = newMenus.map(item => item)

    let _action = _menusActions[i]

    _menusActions.splice(i, 1)

    setNewMenus(_menusActions)

    setAvailableMenus([
      ...availableMenus,
      _action
    ])

    setReadyToSave(true)
  }

  const onDragEnter = useCallback((e) => {
    setDraging(true);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('mouseup', onDragLeave);
    window.addEventListener('dragover', onDragOver);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('mouseup', onDragLeave);
      window.removeEventListener('dragover', onDragOver);
    };
  }, [onDragEnter, onDragLeave, onDragOver]);

  // On select file function
  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setUpImg(reader.result as string)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => setReadyToSave(true), [
    newUserData
  ])

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (imgRef.current &&
      previewCanvasRef.current &&
      completedCrop) {

      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }

  }, [completedCrop]);

  function handleChangeEmail(value: string) {
    setNewUserData({ ...newUserData, email: value })
    if (errorFields.includes('email') && validateEmail(value)) {
      setErrorFields(errorFields.filter(item => item !== 'email'))
    }
  }

  // Render function
  return (<>
    {editions ? (
      <div className="UsersPage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>New user</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('users', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>First Name:</span>
                <input
                  className={classNames({
                    error: errorFields.includes('first_name')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewUserData({ ...newUserData, first_name: value })
                    errorFields.includes('first_name') && setErrorFields(errorFields.filter(item => item !== 'first_name'))
                  }}
                />
              </div>

              <div className="field">
                <span>Last Name:</span>
                <input
                  className={classNames({
                    error: errorFields.includes('last_name')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewUserData({ ...newUserData, last_name: value })
                    errorFields.includes('last_name') && setErrorFields(errorFields.filter(item => item !== 'last_name'))
                  }}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Active:</span>
                <Switcher
                  checked={newUserData.active_status === 'Active'}
                  onChange={(value) => setNewUserData({ ...newUserData, active_status: value ? 'Active' : 'Inactive' })}
                />
              </div>

              <div className="field">
                <span>Email:</span>
                <input
                  className={classNames({
                    error: errorFields.includes('email')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => handleChangeEmail(value)}
                />
              </div>
            </div>

            <div
              className="avatar-editing"
            >
              <button className="_zeroed _iconed" onClick={() => setAvatarPopup(true)}>
                {
                  upImg ?
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: '90px',
                        height: '90px',
                      }}
                    /> :
                    newUserData.photo_link ?
                      <img src={newUserData.photo_link} alt="User avatar" /> :
                      <Icon icon="user-20" />
                }

              </button>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Settings</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Function:</span>
                <input
                  className={classNames({
                    error: errorFields.includes('function')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewUserData({ ...newUserData, function: value })
                    errorFields.includes('function') && setErrorFields(errorFields.filter(item => item !== 'function'))
                  }}
                />
              </div>

              <div className="field">
                <span>Phone:</span>
                <ReactInputMask
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={newUserData.phone || ''}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, phone: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field" style={{ zIndex: 7 }}>
                <span>Time Zone:</span>
                <Select options={[{ name: '', id: 0 }].concat(editions.time_zone).map((option) => ({
                  span: option.name,
                  value: option.id
                }))}
                  selectedOption={newUserData.time_zone as number}
                  onChange={(value: string) => setNewUserData({ ...newUserData, time_zone: value })}
                />
              </div>

              <div className="field">
                <span>Password:</span>
                <div className="form-field _iconed">
                  <input
                    type={inputTypes.password}
                    value={newUserData.password || ''}
                    autoComplete='new-password'
                    onChange={({ target: { value } }) => setNewUserData({ ...newUserData, password: value as string })}
                  />
                  <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, password: inputTypes.password === 'text' ? 'password' : 'text' })}>
                    {
                      inputTypes.password === 'text' ?
                        <Icon icon="eye-4" /> :
                        <Icon
                          style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="eye-block"
                        />
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Permissions</div>

            <div className="legend-action-wrapper">
              <label>Permissions:</label>
              <div style={{ zIndex: '7' }} className="input-wrapper">
                <SelectWithSearchComponent
                  options={availablePermissions.map((action) => ({
                    span: action.name,
                    value: action.users_permissions_id
                  }))} selectedOption={selectedPermissions}
                  onChange={(value) => setSelectedPermissions(value as string)}
                />

                <button
                  disabled={!selectedPermissions}
                  className="_green"
                  onClick={() => addAction()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className="table som">
            <tr>
              <th style={{ width: '100%' }}>Permissions</th>
              <th></th>
            </tr>
            {(newPermissions as ActionPermissionsProps[]).map((action, i) => (
              <tr
                key={i}
                style={{ cursor: 'pointer' }}
              >
                <td>{action.name}</td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    onClick={() => removeAction(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Menus</div>

            <div className="legend-action-wrapper">
              <label>Menu:</label>
              <div className="input-wrapper">
                <SelectWithSearchComponent
                  options={availableMenus.map((action) => ({
                    span: action.name,
                    value: action.users_menus_id
                  }))} selectedOption={selectedMenus}
                  onChange={(value) => setSelectedMenus(value as string)}
                />

                <button
                  disabled={!selectedMenus}
                  className="_green"
                  onClick={() => addMenusAction()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className="table som">
            <tr>
              <th style={{ width: '100%' }}>Menu</th>
              <th></th>
            </tr>
            {(newMenus as ActionMenusProps[]).map((action, i) => (
              <tr
                key={i}
                style={{ cursor: 'pointer' }}
              >
                <td>{action.name}</td>
                <td>
                  <button
                    className="_zeroed _iconed _red"
                    onClick={() => removeMenusAction(i)}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <div className="fieldset">

          <div className="legend">Address</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Street:</span>
                <input type="text" onChange={({ target: { value } }) => setNewUserData({ ...newUserData, street: value })} />
              </div>

              <div className="field">
                <span>Unit/Apt:</span>
                <input type="text" onChange={({ target: { value } }) => setNewUserData({ ...newUserData, unit: value })} />
              </div>

              <div className="field">
                <span>City:</span>
                <input type="text" onChange={({ target: { value } }) => setNewUserData({ ...newUserData, city: value })} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>State:</span>
                <Select options={[{ name: '', id: 0 }].concat(editions.state).map((option) => ({
                  span: option.name,
                  value: option.id
                }))} selectedOption={newUserData.State as string} onChange={(value: string) => setNewUserData({ ...newUserData, State: value })} />
              </div>

              <div className="field">
                <span>Zip:</span>
                <input type="text" onChange={({ target: { value } }) => setNewUserData({ ...newUserData, zip: value })} />
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('users', {
              companyId: activeAccountId,
            }, {
              reload: true
            })}>
              Cancel
            </button>
            <button
              disabled={!readyToSave || newUserData.time_zone === 0 || !!errorFields.length || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>

        {avatarPopup ? (
          <div className="popup imageCrop" onClick={() => setAvatarPopup(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              {
                draging ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      name="myImage"
                      onDragLeave={onDragLeave}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      onChange={onSelectFile}
                      id="file-uploader"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader')?.click()}
                    >
                      <Icon icon="user-33" />
                      <span>Upload a File</span>
                    </button>
                  </div>
              }

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
                {
                  upImg &&
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                }
                {
                  upImg &&
                  <div
                    style={{ display: 'flex', marginTop: '10px' }}
                  >
                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => {
                        setUpImg('')
                        setAvatarPopup(false)
                      }}
                    >
                      <span>Cancel</span>
                    </button>

                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => {
                        setAvatarPopup(false)
                      }}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        ) : null}

        {
          isExistUser &&
          <div className="item-delete-popup" onClick={() => setIsExistUser(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                User exist
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setIsExistUser(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    ) : null}
  </>)
}

export default UsersPage_New
