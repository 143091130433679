import { useState, useEffect } from 'react';

interface CoordsProps {
  coords: {
    latitude: number,
    longitude: number
  }
}

interface PositionProps {
  latitude: number,
  longitude: number
}

export const usePosition = () => {
  const [position, setPosition] = useState<PositionProps | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onChange = ({ coords }: CoordsProps) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error: { message: string }) => {
    setError(error.message);
  };
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported');
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
}