import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../../components/Icon"
import ReportFilters from "../../../components/reports/Filters"
import ReportTableControls from "../../../components/reports/TableControls"
import ReportTableField from "../../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../../funcs";
import { useAppSelector } from "../../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/report-list.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallGroupsReport
}

export interface CallGroupsReport {

  interface: {

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  // permissions: {
  //   call_group_add: boolean
  //   call_group_delete: boolean
  //   call_group_edit: boolean
  //   call_group_report_show: boolean
  //   call_group_show: boolean
  // },

  edit: {
    users: []
    callflow: []
  },

  groups: {
    caller_group_id: string
    created_at: string
    name: string
    updated_at: string
    phone_numbers: number
    users: {
      first_name: string
      last_name: string
    }[]
  }[]
}

function CallCenter_CallerGroupsPage_List() {
  const $router = useRoute();

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random());

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<CallGroupsReport | null>(null);
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    sort: {
      field: cookiesSetting && cookiesSetting?.call_center_caller_groups?.sort_field ? cookiesSetting.call_center_caller_groups.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.call_center_caller_groups?.sort_type ? cookiesSetting.call_center_caller_groups.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Phone numbers',
      value: 'phone_numbers'
    }]
  })

  // Load callerGroups function
  async function loadCallerGroups() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    setCookie('settings', { ...cookiesSetting, call_center_caller_groups: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.call_center_caller_groups?.limit_rows ? cookiesSetting?.call_center_caller_groups.limit_rows : reportsMaxRows } })
    try {
      // https://2022back4.artemiudintsev.com/api/callcenter/caller-groups/report?account_id=88888&limit_rows=10&page=1
      const { data: { data: callerGroupData, success, error } } = (await httpClientUpdate.get('/callcenter/caller-groups/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.call_center_caller_groups?.limit_rows ? cookiesSetting?.call_center_caller_groups?.limit_rows : reportsMaxRows,
          page: 1,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(callerGroupData)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, call_center_caller_groups: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadCallerGroups()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="CallCenter_CallGroupsPage_List" >

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="page-header">
          <h1>Caller Groups</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.call_center_caller_groups?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, call_center_caller_groups: { ...cookiesSetting.call_center_caller_groups, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            // reportData.permissions.call_group_add ?
            //   (
            <div className="add-button-wrapper">
              <button
                className="_iconed _rounded add-button"
                onClick={() => $router.router.navigate('call_center.caller_groups.new', {
                  companyId: activeAccountId,
                  localInterface: localInterface,
                }, { reload: true })}>
                <Icon icon="plus-thin" />
                <span>Add Caller Group</span>
                <Icon icon="user-29" />
              </button>
            </div>
            // ) :
            // <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Phone numbers</span>)}

                  sortDirection={localInterface.sort.field === 'phone_numbers' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone_numbers', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <th>
                  Users
                </th>
              </tr>
            </thead>

            {reportData.groups.map((callerGroup, i) => (
              <tr
                key={i}
                style={{ cursor: callerGroup.caller_group_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  // reportData.permissions.call_group_show &&
                  callerGroup.caller_group_id &&
                  $router.router.navigate('call_center.caller_groups.item', {
                    companyId: activeAccountId,
                    callerId: callerGroup.caller_group_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  // reportData.permissions.call_group_show &&
                  callerGroup.caller_group_id &&
                  handleChangeMenu(event, callerGroup.caller_group_id, 'groups', i)}
              >
                <td>{callerGroup.name}</td>
                <td>
                  {callerGroup.phone_numbers}
                </td>
                <td>
                  {callerGroup.users.map(item => `${item.first_name} ${item.last_name}`).join(', ')}
                  {
                    // reportData.permissions.call_group_show &&
                    showContext === `groups_${callerGroup.caller_group_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.caller_groups.item', {
                            companyId: activeAccountId,
                            callerId: callerGroup.caller_group_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/caller_groups/${callerGroup.caller_group_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone numbers</th>
                <th>Users</th>
              </tr>
            </thead>

            {reportData.groups.map((callerGroup, i) => (
              <tr
                key={i}
                style={{ cursor: callerGroup.caller_group_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  // reportData.permissions.call_group_show &&
                  callerGroup.caller_group_id &&
                  $router.router.navigate('call_center.caller_groups.item', {
                    companyId: activeAccountId,
                    callerId: callerGroup.caller_group_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  // reportData.permissions.call_group_show &&
                  callerGroup.caller_group_id &&
                  handleChangeMenu(event, callerGroup.caller_group_id, 'groups', i)}
              >
                <td>{callerGroup.name}</td>
                <td>
                  {callerGroup.phone_numbers}
                </td>
                <td>
                  {callerGroup.users.map(item => `${item.first_name} ${item.last_name}`).join(', ')}
                  {
                    // reportData.permissions.call_group_show &&
                    showContext === `groups_${callerGroup.caller_group_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.caller_groups.item', {
                            companyId: activeAccountId,
                            callerId: callerGroup.caller_group_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/caller_groups/${callerGroup.caller_group_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.groups.map((callerGroup, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  // reportData.permissions.call_group_show &&
                  callerGroup.caller_group_id &&
                  $router.router.navigate('call_center.caller_groups.item', {
                    companyId: activeAccountId,
                    callerId: callerGroup.caller_group_id,
                    localInterface: localInterface,
                  }, { reload: true })}>

                <div className="__top">
                  <div className="__left">
                    <div><b>{callerGroup.name}</b></div>
                    <div>
                      {callerGroup.phone_numbers}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div></div>

                  <div className="__right">
                    <div>
                      <b>{callerGroup.users.map(item => `${item.first_name} ${item.last_name}`).join(', ')}</b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.call_center_caller_groups?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, call_center_caller_groups: { ...cookiesSetting.call_center_caller_groups, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default CallCenter_CallerGroupsPage_List
