
import React, { useState, useRef, useEffect, memo } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

interface zipCodesViewProps {
  zip_code: string,
  isHave: boolean,
  lat: number,
  lng: number,
  id: string,
}

const libraries: (
  | "geometry"
  | "places"
  | "drawing"
  | "localContext"
  | "visualization"
)[] = ["places"];

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "630px",
};

const center = {
  lat: 40.730610,
  lng: -73.935242,
};

interface RenderMapProps {
  selectedZipCode: string[]
  setSelectedZipCode: (value: string[]) => void
  viewAddress: { name: string, value: string } | null
  isCenterZips: number
}

const RenderMap = ({
  selectedZipCode,
  setSelectedZipCode,
  viewAddress,
  isCenterZips,
}: RenderMapProps) => {
  const API_KEY = process.env.REACT_APP_API_KEY
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY as string,
    mapIds: ['ec4fc7638d3b957e'],
    libraries,
  });

  const [originalZoom, setOriginalZoom] = useState(12);
  const mapRef = useRef<google.maps.Map | null>(null);

  const [updated, setUpdated] = useState(Math.random())

  const [isFindCenter, setIsFindCenter] = useState(false)

  const [selectedZips, setSelectedZips] = useState<zipCodesViewProps[]>([])

  const [selectedArea, setSelectedArea] = useState({
    lat: 0,
    lng: 0
  })

  useEffect(() => {
    if (mapRef.current !== null && isLoaded) {
      initialMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapRef.current, isLoaded, updated])

  useEffect(() => {
    if (mapRef.current !== null && isLoaded) {
      if (selectedZips.length) {
        drawSelectedZips()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZips])

  useEffect(() => {
    if (selectedArea.lat !== 0 && selectedArea.lng !== 0) {
      findPostalCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea])

  useEffect(() => {
    if (selectedZipCode.length) {
      findInitialCenter()
    } else {
      clearMap()
      setIsFindCenter(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZipCode])

  useEffect(() => {
    if (viewAddress) {
      getCenterViewItem()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAddress])

  useEffect(() => {
    if (isCenterZips) {
      findInitialCenter(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCenterZips])

  function findInitialCenter(check?: boolean) {
    const geocoder = new google.maps.Geocoder()
    let count = 0

    let selectedPlace: zipCodesViewProps[] = []

    for (const zipCode of selectedZipCode) {
      geocoder.geocode({
        'componentRestrictions': { postalCode: zipCode, country: 'USA' }
        // eslint-disable-next-line no-loop-func
      }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            count = count + 1
            selectedPlace.push({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              zip_code: zipCode,
              isHave: true,
              id: results[0].place_id
            })
          } else {
            count = count + 1
            selectedPlace.push({
              lat: 0,
              lng: 0,
              zip_code: zipCode,
              isHave: false,
              id: ''
            })
          }
        } else {
          count = count + 1
          selectedPlace.push({
            lat: 0,
            lng: 0,
            zip_code: zipCode,
            isHave: false,
            id: ''
          })
        }

        if (count === selectedZipCode.length) {
          setIsFindCenter(true)

          const bounds = new google.maps.LatLngBounds();

          selectedPlace.filter(item => item.isHave).forEach(item => {
            bounds.extend({ lat: item.lat, lng: item.lng })
          })

          setSelectedZips(selectedPlace.filter(item => item.isHave))

          if (!isFindCenter || check) {
            mapRef.current?.fitBounds(bounds)

            if (selectedPlace.filter(item => item.isHave).length < 2) {
              mapRef.current?.setZoom(12)
            }
          }
        }
      })
    }
  }

  function getCenterViewItem() {
    const geocoder = new google.maps.Geocoder()
    viewAddress &&
      geocoder.geocode({
        'componentRestrictions': { [viewAddress.name]: viewAddress.value, country: 'USA' }
        // eslint-disable-next-line no-loop-func
      }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            const bounds = new google.maps.LatLngBounds();

            bounds.extend({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() })

            mapRef.current?.fitBounds(bounds)
            mapRef.current?.setZoom(12)
          }
        }
      })
  }

  function initialMap() {
    //@ts-ignore
    const featureLayer = mapRef.current?.getFeatureLayer('POSTAL_CODE')
    const featureStyleOptions: google.maps.FeatureStyleOptions = {
      strokeColor: "#000000",
      strokeOpacity: 1.0,
      strokeWeight: 1.0,
      fillColor: "#000000",
      fillOpacity: 0.1,
    };

    if (featureLayer) {
      //@ts-ignore
      featureLayer.style = () => {
        // @ts-ignore
        return featureStyleOptions
      }
      featureLayer.addListener('click', handleClick)
    }
  }

  function clearMap() {
    //@ts-ignore
    const featureLayer = mapRef.current?.getFeatureLayer('POSTAL_CODE')
    const featureStyleOptions: google.maps.FeatureStyleOptions = {
      strokeColor: "#000000",
      strokeOpacity: 1.0,
      strokeWeight: 1.0,
      fillColor: "#000000",
      fillOpacity: 0.1,
    };

    if (featureLayer) {
      //@ts-ignore
      featureLayer.style = () => {
        // @ts-ignore
        return featureStyleOptions
      }
    }
    setSelectedZips([])
  }

  function drawSelectedZips() {
    if (mapRef.current) {
      //@ts-ignore
      const featureLayer = mapRef.current?.getFeatureLayer('POSTAL_CODE')

      const featureStyleOptions: google.maps.FeatureStyleOptions = {
        strokeColor: "#000000",
        strokeOpacity: 1.0,
        strokeWeight: 1.0,
        fillColor: "#000000",
        fillOpacity: 0.4,
      };

      if (featureLayer) {
        //@ts-ignore
        featureLayer.style = (options) => {

          // @ts-ignore
          if (selectedZips.map(item => item.id).includes(options.feature?.placeId)) {
            return featureStyleOptions
          } else {
            return {
              strokeColor: "#000000",
              strokeOpacity: 1.0,
              strokeWeight: 1.0,
              fillColor: "#000000",
              fillOpacity: 0.1,
            }
          }
        }
      }
    }
  }

  function handleClick(event: any) {
    setIsFindCenter(true)
    setSelectedArea({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    })
  }

  function findPostalCode() {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: selectedArea }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          for (let j = 0; j < results.length; j++) {
            if (results[j].types[0] === 'postal_code') {
              let updatedZips = selectedZipCode.map(item => item)
              if (updatedZips.includes(results[j].address_components[0].short_name)) {
                setSelectedZipCode(updatedZips.filter(item => item !== results[j].address_components[0].short_name))
              } else {
                updatedZips.push(results[j].address_components[0].short_name)
                setSelectedZipCode(updatedZips)
              }
            }
          }
        }
      }
    });
  }

  if (loadError) return <div>Error loading maps</div>;

  if (!isLoaded)
    return (
      <div className="flex h-full flex-col">
        loading
      </div>
    );

  return (
    <div className="w-full">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={originalZoom}
        center={center}
        onZoomChanged={() => setOriginalZoom(mapRef.current?.getZoom() ? mapRef.current?.getZoom() as number : 12)}
        onClick={(event) => handleClick(event)}
        onLoad={(map) => {
          setUpdated(Math.random())
          mapRef.current = map;
          setOriginalZoom(12);
        }}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapId: 'ec4fc7638d3b957e',
          clickableIcons: false,
        }}
      >
      </GoogleMap>
    </div>
  );
};

export default memo(RenderMap);