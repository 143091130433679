import { useEffect, useState } from "react"
import ReactInputMask from "react-input-mask"

import Icon from "../../components/Icon"

import { formatPhoneNumber, formatPhoneNumberToServerString } from "../../funcs"

import { AppointDataProps } from "./List"
import { PhonesProps } from "./Models"

interface BlockProps {
  editing: boolean
  setEditing: (value: string) => void
  data: PhonesProps[]
  setData: (value: PhonesProps[]) => void
  appointmentReport: Partial<AppointDataProps>
  disabledEditButton: boolean
  updatedPhonesItem: () => void
  status: boolean
  permmisions_phones_edit: boolean
  isSending: boolean
}

export default function PhoneBlock({
  editing,
  setEditing,
  data,
  setData,
  appointmentReport,
  disabledEditButton,
  updatedPhonesItem,
  status,
  permmisions_phones_edit,
  isSending,
}: BlockProps) {
  const [isNewPhone, setIsNewPhone] = useState(false)
  const [editPhone, setEditPhone] = useState(0)
  const [readyToSave, setReadyToSave] = useState(false)
  const [newPhone, setNewPhone] = useState({
    contact_phone_id: '',
    is_appointment: 0,
    is_main: 0,
    is_payer: 0,
    is_sms: 0,
    name: 'main',
    phone: '',
    use: true
  })

  useEffect(() => {
    editing && setReadyToSave(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleCancel() {
    setEditing('')
    setReadyToSave(false)
    setIsNewPhone(false)
    appointmentReport.phones && setData(appointmentReport.phones.map(item => {
      return {
        ...item,
        use: !!item.is_appointment
      }
    }))
    handleCancelEditPhone()
  }

  function handleSave(): void {
    setEditing('')
    setReadyToSave(false)
    updatedPhonesItem()
  }

  function handleRemovePhone(index: number) {
    setData(data.filter((item, idx) => idx !== index))
  }

  function handleAddPhone() {
    let updatedPhones = data.map(item => item)
    updatedPhones.push({
      contact_phone_id: newPhone.contact_phone_id,
      is_appointment: newPhone.is_appointment,
      is_main: newPhone.is_main,
      is_payer: newPhone.is_payer,
      is_sms: newPhone.is_sms,
      name: newPhone.name,
      phone: newPhone.phone,
      use: true
    })

    setIsNewPhone(false)
    setData(updatedPhones)
    setNewPhone({
      contact_phone_id: '',
      is_appointment: 0,
      is_main: 0,
      is_payer: 0,
      is_sms: 0,
      name: 'main',
      phone: '',
      use: false
    })
  }

  function handleChange(value: boolean, index: number, name: string) {
    let updatedData = data.map(item => item)
    updatedData[index][name] = value

    setData(updatedData)
  }

  function handleEditPhone(index: number) {
    setNewPhone({
      contact_phone_id: data[index].contact_phone_id,
      is_appointment: data[index].is_appointment,
      is_main: data[index].is_main,
      is_payer: data[index].is_payer,
      is_sms: data[index].is_sms,
      name: data[index].name,
      phone: data[index].phone,
      use: !!data[index].use
    })
    setEditPhone(index + 1)
  }

  function checkWithOutService() {
    let check = true
    data.forEach(item => {
      if (item.is_sms) {
        check = false
      }
    })
    return check
  }

  function handleWithOutService(value: boolean) {
    if (value) {
      let updatedData = data.map(item => {
        return {
          ...item,
          is_sms: 0
        }
      })

      setData(updatedData)
    }
  }

  function checkFieldsAddPhone() {
    let check = false
    if (newPhone.name === '') {
      check = true
    }
    if (formatPhoneNumberToServerString(newPhone.phone).length < 12) {
      check = true
    }

    return check
  }

  function handleCancelEditPhone() {
    setEditPhone(0)
    setNewPhone({
      contact_phone_id: '',
      is_appointment: 0,
      is_main: 0,
      is_payer: 0,
      is_sms: 0,
      name: 'main',
      phone: '',
      use: false
    })
  }

  function handleCancelAddPhone() {
    setNewPhone({
      contact_phone_id: '',
      is_appointment: 0,
      is_main: 0,
      is_payer: 0,
      is_sms: 0,
      name: 'main',
      phone: '',
      use: false
    })
    setIsNewPhone(false)
  }

  function handleAddEditPhone() {
    let updatedPhones = data.map((item, index) => {
      if (index === editPhone - 1) {
        return {
          ...item,
          contact_phone_id: newPhone.contact_phone_id,
          is_appointment: newPhone.is_appointment,
          is_main: newPhone.is_main,
          is_payer: newPhone.is_payer,
          is_sms: newPhone.is_sms,
          name: newPhone.name,
          phone: newPhone.phone,
          use: true
        }
      } else {
        return { ...item }
      }
    })

    setData(updatedPhones)
    setNewPhone({
      contact_phone_id: '',
      is_appointment: 0,
      is_main: 0,
      is_payer: 0,
      is_sms: 0,
      name: 'main',
      phone: '',
      use: false
    })
    setEditPhone(0)
  }

  function checkSavePhones() {
    let check = false

    let usePhones = data.filter(item => item.use)

    if (!usePhones.length) {
      check = true
    }

    return check
  }

  return (
    <>
      <div
        style={{ position: 'relative' }}
        className='call-contact_new-phones'
      >
        <div className="block-content-title">
          <div className="block-title">
            Phones
          </div>
        </div>

        <div
          className="action-buttons"
          style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '10px' }}
        >
          {
            !editing &&
            <button
              className="btn btn-add"
              disabled={disabledEditButton || status || !permmisions_phones_edit}
              onClick={() => setEditing('Phones')}
            >
              Edit
            </button>
          }

          {
            editing &&
            <>
              <button
                disabled={isNewPhone}
                className="btn btn-add btn-cancel"
                style={{ width: 'fit-content', marginRight: '15px' }}
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || checkSavePhones() || isSending}
                className="btn btn-add btn-save"
                style={{ width: 'fit-content' }}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </>
          }
        </div>

        <div className="data-border">
          <div className='call-contact_new-topBlock'>
            <div className='call-contact_new-leftBlock'>
              <div className='call-contact_new-titleBlock'>
                <p>Phones:</p>
              </div>

              <div className='call-contact_new-payer'>
                <p>Main</p>
                <p style={{ margin: '0 10px' }}>Payer</p>
                <p>SMS</p>
              </div>
            </div>

            <div className='call-contact_new-sms'>
              <p>Without Service SMS</p>

              <div className='check'>
                <input
                  className='input-checkbox'
                  type="checkbox"
                  style={{ cursor: editing ? 'pointer' : 'inherit' }}
                  disabled={!editing}
                  checked={checkWithOutService()}
                  onChange={(event) => handleWithOutService(event.target.checked)}
                />

                <label className='checkbox' htmlFor="">
                  {
                    checkWithOutService() &&
                    <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                  }
                </label>
              </div>
            </div>

            <div className='call-contact_new-dontUse'>
              <p>Use</p>
            </div>
          </div>

          {
            data.map((item, index) => (
              <div key={index} className='call-contact_new-block'>
                <div className='call-contact_new-phone_item'>
                  <div className='call-contact_new-block_text'>
                    <p style={{ width: '120px' }}> {!!editPhone && editPhone - 1 === index && '* '} {item.name} </p>

                    <p
                      style={{ width: '120px' }}
                    >{formatPhoneNumber(item.phone)}</p>
                  </div>

                  <div className='call-contact_new-checkboxes'>
                    <div className='call-contact_new-checkbox'>
                      <div className='check'>
                        <input
                          className='input-checkbox'
                          name='is_main'
                          type="checkbox"
                          disabled={true}
                          style={{ cursor: 'inherit' }}
                          checked={!!item.is_main}
                          onChange={(event) => handleChange(event.target.checked, index, 'is_main')}
                        />

                        <label className='checkbox' htmlFor="">
                          {
                            !!item.is_main &&
                            <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                          }
                        </label>
                      </div>
                    </div>

                    <div style={{ margin: '0 20px' }} className='call-contact_new-checkbox'>
                      <div className='check'>
                        <input
                          className='input-checkbox'
                          name='payer'
                          type="checkbox"
                          style={{ cursor: editing ? 'pointer' : 'inherit' }}
                          disabled={!editing}
                          checked={!!item.is_payer}
                          onChange={(event) => handleChange(event.target.checked, index, 'is_payer')}
                        />

                        <label className='checkbox' htmlFor="">
                          {
                            !!item.is_payer &&
                            <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                          }
                        </label>
                      </div>
                    </div>

                    <div className='call-contact_new-checkbox'>
                      <div className='check'>
                        <input
                          className='input-checkbox'
                          name='sms'
                          type="checkbox"
                          style={{ cursor: editing ? 'pointer' : 'inherit' }}
                          disabled={!editing}
                          checked={!!item.is_sms}
                          onChange={(event) => handleChange(event.target.checked, index, 'is_sms')}
                        />

                        <label className='checkbox' htmlFor="">
                          {
                            !!item.is_sms &&
                            <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                          }
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='call-contact_new-block_delete'>
                  <div className='cancel'>
                    <input
                      className='input-checkbox'
                      name='use'
                      type="checkbox"
                      style={{ cursor: editing ? 'pointer' : 'inherit' }}
                      disabled={!editing}
                      checked={item.use}
                      onChange={(event) => handleChange(event.target.checked, index, 'use')}
                    />

                    <label className='checkbox' htmlFor="">
                      {
                        item.use &&
                        <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                      }
                    </label>
                  </div>

                  <div style={{ display: 'flex' }}>
                    {
                      editing ?
                        <div
                          style={{ marginRight: '10px' }}
                          className='trash call-contact_new-block_trash inactive'
                          onClick={() => handleEditPhone(index)}
                        >
                          <Icon
                            style={{ fill: '#FFB700', width: '16px', cursor: 'pointer' }}
                            viewBox="0 0 16 16"
                            icon="pencil-1"
                          />
                        </div> :
                        <div
                          style={{ marginRight: '10px' }}
                          className='trash call-contact_new-block_trash inactive'
                        >
                          <Icon
                            style={{ fill: '#8e8e93', width: '16px', cursor: 'inherit' }}
                            viewBox="0 0 16 16"
                            icon="pencil-1"
                          />
                        </div>
                    }

                    {
                      editing && !item.contact_phone_id ?
                        <div
                          className='trash call-contact_new-block_trash inactive'
                          onClick={() => handleRemovePhone(index)}
                        >
                          <Icon
                            style={{ fill: '#FF0000', width: '90%', cursor: 'pointer' }}
                            icon="x-mark-1"
                          />
                        </div> :
                        <div
                          className='trash call-contact_new-block_trash inactive'
                        >
                          <Icon style={{ fill: '#8E8E93', width: '90%', cursor: 'inherit' }} icon="x-mark-1" />
                        </div>
                    }
                  </div>

                </div>
              </div>
            ))
          }

          {
            editing &&
            <div className='call-contact_new-block'>
              <div
                className='call-contact_new-phone_add'
                style={{ justifyContent: isNewPhone || !!editPhone ? 'space-between' : 'flex-end' }}
              >
                {
                  (isNewPhone || !!editPhone) &&
                  <div className='call-contact_new-phone_data'>
                    <p>
                      {
                        !!editPhone ?
                          'Edit Phone Number:' :
                          'Add Phone Number:'
                      }
                    </p>

                    <div className='call-contact_new-phone_name'>
                      <label> Name: </label>

                      <input
                        value={newPhone.name}
                        onChange={(event) => setNewPhone({ ...newPhone, name: event.target.value })}
                        className='data-border call-contact_new-phone_input'
                        type="text"
                      />
                    </div>

                    <div className='call-contact_new-phone_phone'>
                      <label> Phone: </label>

                      <ReactInputMask
                        type="text"
                        className='data-border call-contact_new-phone_input'
                        mask="+1 (999) 999-9999"
                        value={newPhone.phone}
                        onChange={({ target: { value } }) => setNewPhone({ ...newPhone, phone: value })}
                      />
                    </div>
                  </div>
                }

                {
                  !!editPhone &&
                  <div style={{ display: 'flex' }}>
                    <button
                      // disabled={checkFieldsAddPhone()}
                      className='btn btn-add btn-cancel'
                      style={{ marginRight: '15px' }}
                      onClick={() => handleCancelEditPhone()}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={checkFieldsAddPhone()}
                      className='btn btn-add btn-save'
                      onClick={() => handleAddEditPhone()}
                    >
                      Save
                    </button>
                  </div>
                }

                {
                  isNewPhone &&
                  <div style={{ display: 'flex' }}>
                    <button
                      // disabled={checkFieldsAddPhone()}
                      className='btn btn-add btn-cancel'
                      style={{ marginRight: '15px' }}
                      onClick={() => handleCancelAddPhone()}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={checkFieldsAddPhone()}
                      className='btn btn-add btn-save'
                      onClick={() => handleAddPhone()}
                    >
                      Save
                    </button>
                  </div>
                }

                {
                  !isNewPhone && !!!editPhone &&
                  <button
                    className='btn btn-add'
                    onClick={() => setIsNewPhone(true)}
                  >
                    Add
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
