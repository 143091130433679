import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Icon from '../../components/Icon'
import Select from '../../components/Select'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import "../../styles/pages/common/entity-edit.sass"

interface Estimate {
  status_id: number
  comment: string
  service_resource: string
  name: string
  data: {
    name: string
    price: number
    type: string
  }[]
}

interface EstimateAddProps {
  switchPage: (name: string) => void
  jobId: string
  client_id: string
  edit: {
    estimate_status: string
    estimate_status_id: number
  }[]
}

export default function EstimateAdd({ switchPage, jobId, client_id, edit }: EstimateAddProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newEstimateData, setNewEstimateData] = useState<Estimate>({
    status_id: 0,
    comment: '',
    service_resource: '',
    name: '',
    data: [{
      name: '',
      price: 0,
      type: 'part',
    }]
  })

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/estimates`, qs.stringify({
      account_id: activeAccountId,
      comment: newEstimateData.comment,
      status_id: newEstimateData.status_id,
      appointment_id: $router.router.getState().params.jobId,
      job_id: jobId,
      contact_id: client_id,
      total: getTotal()
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  useEffect(() => setReadyToSave(true), [
    newEstimateData
  ])

  function handleCancelEdit() {
    setNewEstimateData({
      status_id: 0,
      comment: '',
      service_resource: '',
      name: '',
      data: [{
        name: '',
        price: 0,
        type: 'part',
      }]
    })
  }

  function handleChange(value: string | number, name: string, index: number) {
    let updateData = newEstimateData.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function handleRemoveUnit(index: number) {
    let updateData = newEstimateData.data.filter((item, idx) => idx !== index)
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function handleAddUnit() {
    let updateData = newEstimateData.data.map(item => item)
    updateData.push({
      name: '', price: 0, type: 'part'
    })
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function getTotal() {
    let sum = 0.00
    newEstimateData.data.forEach(item => {
      sum = sum + +item.price
    })
    return sum.toFixed(2)
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newEstimateData.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            price: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewEstimateData({ ...newEstimateData, data: updateData })
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newEstimateData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewEstimateData({ ...newEstimateData, data: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newEstimateData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewEstimateData({ ...newEstimateData, data: updateData })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Add new Estimate</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => switchPage('main')}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Status:</span>
              {
                edit &&
                <Select
                  options={edit.map((option) => ({
                    span: option.estimate_status,
                    value: option.estimate_status_id
                  }))}
                  selectedOption={newEstimateData.status_id as number}
                  onChange={(value) => setNewEstimateData({ ...newEstimateData, status_id: value as number })}
                />
              }
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Comment:</span>
              <textarea
                style={{ marginRight: '0', marginLeft: '0' }}
                className='description'
                name='comment'
                value={newEstimateData.comment || ''}
                onChange={({ target: { value, name } }) => setNewEstimateData({ ...newEstimateData, comment: value })}
              >

              </textarea>
            </div>
          </div>
        </div>

        <div className="fields">
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {
                newEstimateData.data.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: '55%' }}>
                      <input
                        type="text"
                        value={item.name || ''}
                        onChange={({ target: { value } }) => handleChange(value, 'name', index)}
                      />
                    </td>

                    <td style={{ width: '20%' }}>
                      <Select
                        options={['part', 'labor'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={item.type as string}
                        onChange={(value) => handleChange(value as string, 'type', index)}
                      />
                    </td>

                    <td style={{ width: '20%' }}>
                      <input
                        style={{ textAlign: 'right' }}
                        type="number"
                        value={item.price.toFixed(2)}
                        onFocus={(event) => handleFocus(event)}
                        onChange={(event) => handleChangePrice(event, index)}
                        onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                      />
                    </td>

                    <td style={{ width: '5%' }}>
                      <button
                        onClick={() => handleRemoveUnit(index)}
                        style={{ background: 'inherit', width: 'fit-content', padding: '0', margin: '0 auto' }}
                      >
                        <Icon
                          style={{ fill: '#FF0000', width: '15px', height: '15px' }}
                          icon="x-mark-1"
                        />
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>


        <div className="field">
          <div style={{ marginTop: '10px' }} className='add-button'>
            <button
              className='_green'
              onClick={() => handleAddUnit()}
            >Add</button>
          </div>
        </div>

        <div style={{ flexWrap: 'nowrap' }} className="fields">
          <div style={{ width: '50%' }} ></div>
          <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>Total:</span>
            <input
              style={{ marginRight: '20px', textAlign: 'right' }}
              type="text"
              value={getTotal()}
              disabled={true}
            />
          </div>
        </div>
      </div>


      <div className="wrapper flex-container sb editing-buttons">
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => handleCancelEdit()}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
