import { useEffect, useState } from 'react'
import { Marker } from '@react-google-maps/api'

interface CurrentLocationMarkerProps {
  position: {
    lat: number
    lng: number
  }
}

interface PositionProps {
  lat: number,
  lng: number
}

export default function CurrentLocationMarker({ position }: CurrentLocationMarkerProps) {
  const [heading, setHeading] = useState(90)

  function getCurrentDirection(previousCoordinates: PositionProps, currentCoordinates: PositionProps) {
    const diffLat = currentCoordinates.lat - previousCoordinates.lat;
    const diffLng = currentCoordinates.lng - previousCoordinates.lng;
    const anticlockwiseAngleFromEast = Math.atan2(diffLat, diffLng);
    const clockwiseAngleFromNorth = 90 - anticlockwiseAngleFromEast;
    // helper function
    function convertToDegrees(radian: number) {
      return (radian * 180) / Math.PI;
    }

    return convertToDegrees(clockwiseAngleFromNorth);
  }

  useEffect(() => {
    let prevLocation = { lat: 0, lng: 0 }
    if (position) {

      if (prevLocation.lat !== position.lat && prevLocation.lng !== position.lng) {
        let clockwiseAngleFromNorth = getCurrentDirection(prevLocation, position)
        setHeading(clockwiseAngleFromNorth)
        prevLocation = position
      }
    }
  }, [position])

  return (
    <Marker
      position={position}
      icon={
        {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 6,
          fillColor: 'red',
          fillOpacity: 0.8,
          strokeWeight: 2,
          rotation: heading
        }
      }
    />
  )
}
