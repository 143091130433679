import { useRoute } from "react-router5";

import Icon from "./Icon";
import Checkbox from "./Checkbox";

import { capitalizeFirstLetter } from "../funcs";
import { useAppSelector } from "../store/hooks";

import "../styles/components/notifications-widget.sass"

interface NotificationsWidgetProps {
  notify: {
    today: number
    yesterday: number
    weekend: number
  }
  setActiveNavButton: (value: string | null) => void
  agreeSound: boolean
  handleBell: () => void
}

function NotificationsWidget({
  notify,
  setActiveNavButton,
  agreeSound,
  handleBell,
}: NotificationsWidgetProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  // Render function
  return (
    <div className="NotificationsWidget">

      <div className="header">
        <div className="title">
          Notifications
        </div>
      </div>

      <div
        className="notifications-allow"
      >
        <div>Sound Notifications</div>
        <div>
          <Checkbox
            contents=''
            value={agreeSound}
            onChange={() => handleBell()}
          />
        </div>
      </div>

      <div className="notifications-list">
        {
          Object.keys(notify).filter(item => notify[item]).map((item, i) => (
            <div
              key={i}
              className="notification"
              onClick={() => {
                setActiveNavButton(null);
                ['today', 'yesterday', 'weekend'].includes(item) && $router.router.navigate('website_requests', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
                ['need_confirmation', 'need_check'].includes(item) && $router.router.navigate('payout-dispatcher', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
                ['staff_need_check', 'staff_need_confirmation'].includes(item) && $router.router.navigate('staff', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
              }
              }>
              <div className="icon">
                <Icon icon="bell-1" />
              </div>

              <div className="contents">

                <div className="title">
                  {
                    ['today', 'yesterday', 'weekend'].includes(item) && `Website Requests / ${capitalizeFirstLetter(item)}`
                  }
                  {
                    ['need_confirmation', 'need_check'].includes(item) && 'Payout Dispatcher'
                  }
                  {
                    ['staff_need_check', 'staff_need_confirmation'].includes(item) && 'Payout Staff'
                  }
                </div>

                <div className="timestamp">
                  <Icon icon="speech-bubble-15" />
                  <span>
                    {
                      ['today', 'yesterday', 'weekend'].includes(item) && `${notify[item]} New Website Requests`
                    }
                    {
                      ['need_confirmation', 'staff_need_confirmation'].includes(item) && `${notify[item]} Document(s) Needs Confirmation`
                    }
                    {
                      ['need_check', 'staff_need_check'].includes(item) && `${notify[item]} Document(s) Needs Checking`
                    }
                  </span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default NotificationsWidget
