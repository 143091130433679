import { useEffect, useState } from "react"

import SelectComponent from "../../components/Select"
import Switcher from "../../components/Switcher"

import { getSchuduleTime } from "../../funcs"

import { AppointDataProps } from "./List"
import { AppointmentProps, SelectedAvalTime, SettingsProps, SelectedMenualTime } from "./Models"

interface BlockProps {
  editing: boolean
  setEditing: (value: string) => void
  data: AppointmentProps
  setData: (value: AppointmentProps) => void
  appointmentReport: Partial<AppointDataProps>
  handleChangeTime: () => void
  doubleSelected: string
  handleCancelChangeTime: () => void
  setAppointmentPopup: (value: boolean) => void
  updatedAppointmentItem: () => void
  disabledEditButton: boolean
  updatedChangeTime: () => void
  status: boolean
  setRestoreAppoinment: (value: boolean) => void
  restoreAppoinment: boolean
  handleSaveRestoreAppointment: () => void
  selectedAvalTime: SelectedAvalTime | null
  checkChangeTime: boolean
  handleCancelRestore: () => void
  settings: SettingsProps
  selectedMenualTime: boolean
  handleCancelChangeMenualTime: () => void
  updatedChangeMenualTime: () => void
  menualSettings: SelectedMenualTime
  handleSaveMenualRestoreAppointment: () => void
  permmisions_appointment_restore: boolean
  permmisions_appointment_change_time: boolean
  permmisions_appointment_appointment_cancel: boolean
  readyToSendUpdate: boolean
  handleSend: () => void
  isSend: boolean
  permmisions_appointment_edit: boolean
  isSending: boolean
}

export default function AppointmentBlock({
  editing,
  setEditing,
  data,
  setData,
  appointmentReport,
  handleChangeTime,
  doubleSelected,
  handleCancelChangeTime,
  setAppointmentPopup,
  updatedAppointmentItem,
  disabledEditButton,
  updatedChangeTime,
  status,
  setRestoreAppoinment,
  restoreAppoinment,
  handleSaveRestoreAppointment,
  selectedAvalTime,
  checkChangeTime,
  handleCancelRestore,
  settings,
  selectedMenualTime,
  handleCancelChangeMenualTime,
  updatedChangeMenualTime,
  menualSettings,
  handleSaveMenualRestoreAppointment,
  permmisions_appointment_restore,
  permmisions_appointment_change_time,
  permmisions_appointment_appointment_cancel,
  readyToSendUpdate,
  handleSend,
  isSend,
  permmisions_appointment_edit,
  isSending
}: BlockProps) {

  const [readyToSave, setReadyToSave] = useState(false)

  useEffect(() => {
    editing && setReadyToSave(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleCancel() {
    setEditing('')
    setReadyToSave(false)
    appointmentReport.appointment && setData(appointmentReport.appointment)
  }

  function handleSave() {
    updatedAppointmentItem()
    setReadyToSave(false)
  }

  return (
    <>
      <div
        className="fieldset"
        style={{ marginBottom: restoreAppoinment ? '10px' : '30px' }}
      >
        <div className="block-content-title">
          <div className="block-title">
            Appointment
          </div>
        </div>

        <div className="fields title">
          <div className="__left">
            <div className="flex-container _gap-narrow">
              <div className="item-name">
                {
                  data.is_sent ?
                    'Appointment Sent' :
                    'Appointment Not Sent'
                }
              </div>
            </div>
          </div>

          <div className="__right">
            {
              settings.availability ?
                <div
                  className="field"
                  style={{ justifyContent: 'flex-end' }}
                >
                  {
                    isSend &&
                    <button
                      className="btn btn-add"
                      disabled={!readyToSendUpdate || isSending}
                      style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                      onClick={() => handleSend()}
                    >
                      Send Update
                    </button>
                  }
                  {
                    !editing &&
                    <>
                      {
                        !restoreAppoinment && doubleSelected && !status &&
                        <button
                          className="btn btn-add btn-save"
                          style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                          disabled={isSending}
                          onClick={() => updatedChangeTime()}
                        >
                          Save New Time
                        </button>
                      }
                      {
                        !restoreAppoinment && !doubleSelected && !status &&
                        <button
                          className="btn btn-add"
                          disabled={status || checkChangeTime || !permmisions_appointment_change_time}
                          style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                          onClick={() => handleChangeTime()}
                        >
                          Change Time
                        </button>
                      }
                      {
                        !status && doubleSelected &&
                        <button
                          className="btn btn-add btn-cancel"
                          style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                          onClick={() => handleCancelChangeTime()}
                        >
                          Cancel Change
                        </button>
                      }
                      <button
                        className="btn btn-add"
                        disabled={disabledEditButton || status || !permmisions_appointment_edit}
                        style={{ width: 'fit-content', flex: 'inherit' }}
                        onClick={() => setEditing('Appointment')}
                      >
                        Edit
                      </button>
                    </>
                  }

                  {
                    editing &&
                    <>
                      {
                        doubleSelected ?
                          <button
                            className="btn btn-add btn-save"
                            style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                          >
                            Save New Time
                          </button> :
                          <button
                            className="btn btn-add"
                            disabled={status || checkChangeTime || !permmisions_appointment_change_time}
                            style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                            onClick={() => handleChangeTime()}
                          >
                            Change Time
                          </button>
                      }
                      {
                        doubleSelected &&
                        <button
                          className="btn btn-add btn-cancel"
                          style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                          onClick={() => handleCancelChangeTime()}
                        >
                          Cancel Change
                        </button>
                      }
                      <button
                        className="btn btn-add btn-cancel"
                        style={{ width: 'fit-content', flex: 'inherit' }}
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-add btn-save"
                        style={{ width: 'fit-content' }}
                        disabled={!readyToSave || isSending}
                        onClick={() => handleSave()}
                      >
                        Save
                      </button>
                    </>
                  }
                </div>
                :
                <div
                  className="field"
                  style={{ justifyContent: 'flex-end' }}
                >
                  {
                    isSend &&
                    <button
                      className="btn btn-add"
                      disabled={!readyToSendUpdate || isSending}
                      style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                      onClick={() => handleSend()}
                    >
                      Send Update
                    </button>
                  }
                  {
                    !status && selectedMenualTime &&
                    <button
                      className="btn btn-add btn-save"
                      style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                      disabled={isSending}
                      onClick={() => updatedChangeMenualTime()}
                    >
                      Save New Time
                    </button>
                  }
                  {
                    !status && selectedMenualTime &&
                    <button
                      className="btn btn-add btn-cancel"
                      style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                      onClick={() => handleCancelChangeMenualTime()}
                    >
                      Cancel Change
                    </button>
                  }
                  {
                    !editing &&
                    <button
                      className="btn btn-add"
                      disabled={disabledEditButton || status || !permmisions_appointment_edit}
                      style={{ width: 'fit-content', flex: 'inherit' }}
                      onClick={() => setEditing('Appointment')}
                    >
                      Edit
                    </button>
                  }
                  {
                    editing &&
                    <>
                      <button
                        className="btn btn-add btn-cancel"
                        style={{ width: 'fit-content', flex: 'inherit' }}
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-add btn-save"
                        style={{ width: 'fit-content' }}
                        disabled={!readyToSave || isSending}
                        onClick={() => handleSave()}
                      >
                        Save
                      </button>
                    </>
                  }
                </div>
            }
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>

              <input
                type="text"
                disabled={true}
                value={data.name}
                onChange={({ target: { value } }) => setData({ ...data, name: value })}
              />
            </div>

            <div className="field">
              <span>Type:</span>
              <SelectComponent
                disabled={!editing}
                options={[{ value: 'SC', span: 'Service Call' }, { value: 'FU', span: 'Follow Up' }, { value: 'RC', span: 'Recall' }].map((type) => ({
                  span: type.span,
                  value: type.value
                }))}
                selectedOption={data.type}
                onChange={(value: string) => setData({ ...data, type: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Status:</span>
              {
                appointmentReport?.edit?.appointment_statuses ?
                  <SelectComponent
                    disabled={true}
                    options={appointmentReport.edit.appointment_statuses.map((type) => ({
                      span: type.name,
                      value: type.status_id
                    }))}
                    selectedOption={data.status_id}
                    onChange={(value: number) => setData({ ...data, status_id: value })}
                  /> :
                  <input type="text"
                    disabled={true}
                    value={data.status}
                    onChange={({ target: { value } }) => setData({ ...data, status: value })}
                  />
              }
            </div>

            <div className="field">
              <span>None Schedule:</span>
              <Switcher
                disabled={!editing}
                checked={!!data.non_schedule}
                onChange={(value) => setData({ ...data, non_schedule: value ? 1 : 0 })}
              />
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Note:</span>
              <input
                type="text"
                disabled={!editing}
                value={data.note || ''}
                onChange={({ target: { value } }) => setData({ ...data, note: value })}
              />
            </div>
          </div>
        </div>

        {
          !restoreAppoinment ?
            settings.availability ?
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Schedule Time:</span>

                    {
                      selectedAvalTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(selectedAvalTime.time_slot_start, selectedAvalTime.time_slot_finish, selectedAvalTime.service_resource_time_zone as string)}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(data.appointment_start, data.appointment_finish, data.service_resource_time_zone as string)}
                        />
                    }
                  </div>
                </div>
                <div className="__right">
                  <div className="field">
                    <span>Service Resource:</span>

                    {
                      selectedAvalTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={selectedAvalTime.service_resource_name}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={data.service_resource_code ? `${data.service_resource}(${data.service_resource_code})` : data.service_resource}
                        />
                    }
                  </div>
                </div>
              </div> :
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Schedule Time:</span>

                    {
                      selectedMenualTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(menualSettings.start, menualSettings.end, data.service_resource_time_zone as string)}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(data.appointment_start, data.appointment_finish, data.service_resource_time_zone as string)}
                        />
                    }
                  </div>
                </div>
                <div className="__right">
                  <div className="field">
                    <span>Service Resource:</span>

                    {
                      selectedMenualTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={menualSettings.service_resource_name}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={data.service_resource_code ? `${data.service_resource}(${data.service_resource_code})` : data.service_resource}
                        />
                    }
                  </div>
                </div>
              </div> : null
        }

        {
          !restoreAppoinment &&
          <div className="fields">
            <div className="__left"></div>

            <div className="__right">
              <div style={{ justifyContent: 'flex-end' }} className="field">
                {
                  status ?
                    <button
                      className="btn btn-add btn-save"
                      style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                      disabled={restoreAppoinment || !permmisions_appointment_restore}
                      onClick={() => setRestoreAppoinment(true)}
                    >
                      Restore Appointment
                    </button> :
                    <button
                      className="btn btn-add btn-cancel"
                      style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                      disabled={!permmisions_appointment_appointment_cancel}
                      onClick={() => setAppointmentPopup(true)}
                    >
                      Cancel Appointment
                    </button>
                }
              </div>
            </div>
          </div>
        }
      </div>

      {
        restoreAppoinment &&
        <div
          className="fieldset"
          style={{ marginTop: '0px' }}
        >

          {
            settings.availability ?
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Schedule Time:</span>

                    {
                      selectedAvalTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(selectedAvalTime.time_slot_start, selectedAvalTime.time_slot_finish, selectedAvalTime.service_resource_time_zone as string)}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(data.appointment_start, data.appointment_finish, data.service_resource_time_zone as string)}
                        />
                    }
                  </div>
                </div>
                <div className="__right">
                  <div className="field">
                    <span>Service Resource:</span>

                    {
                      selectedAvalTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={selectedAvalTime.service_resource_name}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={data.service_resource_code ? `${data.service_resource}(${data.service_resource_code})` : data.service_resource}
                        />
                    }
                  </div>
                </div>
              </div> :
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Schedule Time:</span>

                    {
                      selectedMenualTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(menualSettings.start, menualSettings.end, data.service_resource_time_zone as string)}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={getSchuduleTime(data.appointment_start, data.appointment_finish, data.service_resource_time_zone as string)}
                        />
                    }
                  </div>
                </div>
                <div className="__right">
                  <div className="field">
                    <span>Service Resource:</span>

                    {
                      selectedMenualTime && permmisions_appointment_change_time ?
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={menualSettings.service_resource_name}
                        /> :
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          disabled={true}
                          value={data.service_resource_code ? `${data.service_resource}(${data.service_resource_code})` : data.service_resource}
                        />
                    }
                  </div>
                </div>
              </div>
          }

          <div className="fields">
            <div className="__left"></div>

            <div className="__right">
              <div style={{ justifyContent: 'flex-end' }} className="field">
                <button
                  className="btn btn-add btn-cancel"
                  style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                  onClick={() => handleCancelRestore()}
                >
                  Cancel
                </button>

                {
                  settings.availability &&
                  <button
                    className="btn btn-add"
                    style={{ width: 'fit-content', flex: 'inherit', marginRight: '15px' }}
                    disabled={checkChangeTime || !permmisions_appointment_change_time}
                    onClick={() => handleChangeTime()}
                  >
                    Change time
                  </button>
                }

                {
                  settings.availability ?
                    <button
                      className="btn btn-add btn-save"
                      style={{ width: 'fit-content', flex: 'inherit' }}
                      disabled={doubleSelected === '' || isSending}
                      onClick={() => handleSaveRestoreAppointment()}
                    >
                      Save
                    </button> :
                    <button
                      className="btn btn-add btn-save"
                      style={{ width: 'fit-content', flex: 'inherit' }}
                      disabled={!selectedMenualTime || isSending}
                      onClick={() => handleSaveMenualRestoreAppointment()}
                    >
                      Save
                    </button>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
