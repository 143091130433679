import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import moment from 'moment'
import qs from 'qs'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'

import { useAppSelector } from "../../store/hooks"
import { capitalizeFirstLetter, dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer, useOuterClick } from '../../funcs'

import Ticket from '../../models/Ticket'
import { HttpTicketViewReport, TicketViewProps } from './List'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

interface TicketsBlockProps {
  tickets: Ticket[] | []
  ticketView: TicketViewProps | null
  handleCloseViewTicket: () => void
  loadTicketView: (value: string) => void
  setTicketView: (value: TicketViewProps | null) => void
  handleUpdatedTickets: () => void
  isReserve: boolean
  setIsReserve: (value: boolean) => void
}

export default function TicketsBlock({
  tickets,
  ticketView,
  handleCloseViewTicket,
  loadTicketView,
  setTicketView,
  handleUpdatedTickets,
  isReserve,
  setIsReserve
}: TicketsBlockProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [errorText, setErrorText] = useState('')
  const [readyToSave, setReadyToSave] = useState(false)
  const [readyChangeStatus, setReadyChangeStatus] = useState(false)

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [message, setMessage] = useState('')
  const [reportData, setReportData] = useState<TicketViewProps | null>(null)

  const [sendTo, setSendTo] = useState({
    service_resource: false,
    author: false,
  })

  const [sendToFromEdit, setSendToFromEdit] = useState({})
  const [editStatus, setEditStatus] = useState('')

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    if (isReserve) {
      setMinutes(9)
      setSeconds(59)
    } else {
      setMinutes(0)
      setSeconds(0)
    }
  }, [isReserve])

  useEffect(() => {
    if (!isReserve) {
      changedType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketView, isReserve])

  useEffect(() => {
    if (editStatus === '') {
      setReadyChangeStatus(false)
    }
  }, [editStatus])

  function changedType() {
    if (ticketView) {
      let user_group: string[] = []
      let updatedSendTo: string[] = []
      ticketView.responsible.forEach(item => {
        if (item.type === 'user_group') {
          user_group.push(item.item_id)
        }
        updatedSendTo.push(capitalizeFirstLetter(item.type))
      })

      updatedSendTo.length && setSendTo({
        service_resource: updatedSendTo.includes('Service resource'),
        author: updatedSendTo.includes('Author'),
      })

      let updatedSendEdit = {}
      ticketView.edit.user_groups.forEach(item => {
        if (user_group.includes(item.user_group_id)) {
          updatedSendEdit[capitalizeFirstLetter(item.name)] = true
        } else {
          updatedSendEdit[capitalizeFirstLetter(item.name)] = false
        }
      })
      setSendToFromEdit(updatedSendEdit)
    }
    setErrorText('')
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  async function handleUpdatedStatus(id: string, type: number) {
    try {
      setEditStatus('')
      // https://2022back4.artemiudintsev.com/api/tickets/88888001fd67db3si1
      const response = await httpClientUpdate.put(`/tickets/${id}`,
        {
          account_id: activeAccountId,
          status: `${type}`,
        })
      if (response.data.success) {
        loadInfo(id)
      }
    } catch (error) {

    }
  }

  async function handleSave(id: string) {
    let created_at = moment(ticketView?.ticket?.date_time_start).format('MM/DD/YYYY hh:mma')

    let responsibles: { type: string; item_id: string | undefined }[] = []
    Object.keys(sendTo).forEach(item => {
      if (sendTo[item]) {
        if (item === 'service_resource') {
          responsibles.push({
            type: "service_resource",
            item_id: ticketView?.responsible && ticketView?.responsible.filter(item => capitalizeFirstLetter(item.type) === 'Service resource')[0].item_id
          })
        } else if (item === 'author') {
          responsibles.push({
            type: "Author",
            item_id: ticketView?.responsible.filter(item => capitalizeFirstLetter(item.type) === 'Author').length ? ticketView?.responsible.filter(item => capitalizeFirstLetter(item.type) === 'Author')[0].item_id : user?.user_id
          })
        }
      }
    })

    Object.keys(sendToFromEdit).forEach(item => {
      if (sendToFromEdit[item]) {
        responsibles.push({
          type: 'user_group',
          item_id: ticketView?.edit && ticketView.edit.user_groups.filter(group => capitalizeFirstLetter(group.name) === item)[0].user_group_id
        })
      }
    })

    try {
      // https://2022back4.artemiudintsev.com/api/tickets/88888001fd67db3si1
      const response = await httpClientUpdate.put(`/tickets/${id}`,
        {
          account_id: activeAccountId,
          type: ticketView?.ticket?.type,
          message: '',
          date: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, created_at as string),
          docs: [],
          responsibles: responsibles
        })
      if (response.data.success) {
        isReserve && toggleResrve('unblock', id, true)
        handleUpdatedTickets()
      }
    } catch (error) {

    }
  }

  // Load info function
  async function loadInfo(id: string) {
    try {
      const { data: { data: ticketData, success, error } } = await httpClientUpdate.get('/tickets/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpTicketViewReport }
      if (success) {
        setTicketView(ticketData)
        setReportData(ticketData)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function toggleResrve(type: string, id: string, isSave?: boolean) {
    try {
      // https://2022back4.artemiudintsev.com/api/tickets/888885011sm4yrn9bg/reserve
      const { data: { data: reserveData, success } } = await httpClientUpdate.post(`/tickets/${id}/reserve`, {
        account_id: activeAccountId,
        type
      }) as { data: any }
      if (success) {
        if (reserveData.message === 'Success block') {
          loadInfo(id)
          setIsReserve(true)
        } else {
          setIsReserve(false)
          setReadyToSave(false)

          !isSave && readyToSave && setTicketView(reportData)
          readyToSave && setMessage('')
          readyToSave && changedType()

          isSave && loadInfo(id)
        }
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      setIsReserve(false)
      setErrorText(createdError.content.errorText)
    }
  }

  async function handleSendMessage(id: string) {
    const response = await httpClientUpdate.post(`/tickets/messages/send`, qs.stringify({
      account_id: activeAccountId,
      message: message,
      ticket_id: ticketView?.ticket.ticket_id
    }))
    if (response.data.success) {
      loadInfo(id)
      setMessage('')
    }
  }

  function getServiceName() {
    let name = ''
    ticketView?.responsible.forEach(item => {
      if (item.type === 'service_resource') {
        name += item.service_resource_code ? `${item.service_resource_name} (${item.service_resource_code})` : item.service_resource_name
      }
    })
    return name
  }

  function handleChangeMessage(event: any, id: string) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && handleSendMessage(id)
    }
  }

  return (
    <div>
      <div className="fieldset">
        <div className="block-content-title">
          <div className="block-title">
            Tickets
          </div>
        </div>

        <div className="fields title">
          <div className="__left">
            <div className="flex-container _gap-narrow">
              <div className="item-name">
                Total Tickets: {tickets.length}
              </div>
            </div>
          </div>
        </div>

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Id</th>
                <th>Status</th>
                <th>Type</th>
                <th>Theme</th>
                <th>Author</th>
              </tr>
            </thead>

            {tickets.map((ticket, i) => (
              <tr
                key={i}
                style={{
                  position: 'relative',
                  cursor: !isReserve ? 'pointer' : 'inherit'
                }}
                onClick={() =>
                  !isReserve &&
                  ticket.ticket_id &&
                  loadTicketView(ticket.ticket_id)
                }
                onContextMenu={(event) => {
                  !isReserve &&
                    ticket.ticket_id &&
                    handleChangeMenu(event, ticket.ticket_id, 'tickets', i)
                }}
              >
                <td style={{ position: 'relative', paddingRight: '25px' }}>
                  {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticket.date_time_start)}
                  {
                    !!ticket.is_responsible &&
                    ticket.status !== 'Closed' &&
                    ticket.status !== 'Canceled' &&
                    <span
                      style={{
                        position: 'absolute',
                        right: '5px',
                        width: '20px',
                        height: '20px',
                        background: '#FF0000',
                        borderRadius: '50%',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '3px'
                      }}
                    >!</span>
                  }
                </td>
                <td>{ticket.number}</td>
                <td
                  className={classNames('status',
                    {
                      canceled: ticket.status === 'Canceled',
                      closed: ticket.status === 'Closed',
                    }
                  )}>
                  {ticket.status}
                </td>
                <td>{ticket.type}</td>
                <td>{ticket.theme}</td>
                <td>
                  {ticket.author_dispatcher || ticket.author_user}
                  {
                    // reportData.permissions.ticket_show &&
                    showContext === `tickets_${ticket.ticket_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('tickets.item', {
                            companyId: activeAccountId,
                            ticketId: ticket.ticket_id,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/tickets/${ticket.ticket_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium desktop table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {tickets.map((ticket, i) => (
              <tr
                key={i}
                style={{
                  position: 'relative',
                  cursor: !isReserve ? 'pointer' : 'inherit'
                }}
                onClick={() =>
                  !isReserve &&
                  ticket.ticket_id &&
                  loadTicketView(ticket.ticket_id)
                }
                onContextMenu={(event) =>
                  !isReserve &&
                  ticket.ticket_id &&
                  handleChangeMenu(event, ticket.ticket_id, 'tickets', i)}
              >
                <td style={{ position: 'relative', paddingRight: '25px' }}>
                  <div>Date: {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticket.date_time_start)}</div>
                  <div>Id: {ticket.number}</div>
                  {
                    !!ticket.is_responsible &&
                    ticket.status !== 'Closed' &&
                    ticket.status !== 'Canceled' &&
                    <span
                      style={{
                        position: 'absolute',
                        right: '5px',
                        width: '20px',
                        height: '20px',
                        background: '#FF0000',
                        borderRadius: '50%',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '3px'
                      }}
                    >!</span>
                  }
                </td>
                <td>
                  <div>
                    Status: {' '}
                    <span className={classNames('status',
                      {
                        canceled: ticket.status === 'Canceled',
                        closed: ticket.status === 'Closed',
                      }
                    )}>
                      {ticket.status}
                    </span>
                  </div>
                  <div>Type: {ticket.type}</div>
                </td>
                <td>
                  <div>Theme: {ticket.theme}</div>
                  <div>Author: {ticket.author_dispatcher || ticket.author_user}</div>
                  {
                    // reportData.permissions.ticket_show &&
                    showContext === `tickets_${ticket.ticket_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('tickets.item', {
                            companyId: activeAccountId,
                            ticketId: ticket.ticket_id,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/tickets/${ticket.ticket_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {tickets.map((ticket, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  !isReserve &&
                  ticket.ticket_id &&
                  loadTicketView(ticket.ticket_id)
                }
              >
                <div className="__top">
                  <div className="__left">
                    {
                      !!ticket.is_responsible &&
                      ticket.status !== 'Closed' &&
                      ticket.status !== 'Canceled' &&
                      <div>
                        <span
                          style={{
                            marginLeft: '10px',
                            marginBottom: '10px',
                            width: '20px',
                            height: '20px',
                            background: '#FF0000',
                            borderRadius: '50%',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >!</span>
                      </div>
                    }
                    <div>
                      <span className="gray">Status: </span>
                      <span
                        className={classNames('status',
                          {
                            canceled: ticket.status === 'Canceled',
                            closed: ticket.status === 'Closed',
                          }
                        )}>
                        {ticket.status}
                      </span>
                    </div>
                    <div>
                      <span className="gray">Id: </span>
                      <span> {ticket.number}</span>
                    </div>
                  </div>

                  <div className="__right">
                    <span className="gray">Type: </span>
                    <span> {ticket.type}</span>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      Theme: <b>{ticket.theme}</b>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      Date: {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticket.date_time_start)}
                    </div>
                    <div>
                      Author: {ticket.author_dispatcher || ticket.author_user}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {
        ticketView &&
        <div className="fieldset">
          <div
            className="block-content-title"
            style={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <div className="block-title">
              View Ticket
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                className="item-name"
                style={{
                  fontSize: '14px',
                  color: '#FF0000',
                  marginRight: '20px',
                  marginTop: '7px',
                }}
              >
                {
                  minutes === 0 && seconds === 0
                    ? null
                    : <>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>
                }
              </div>
              {
                !errorText ?
                  isReserve ?
                    <button
                      className='unclose'
                      style={{ marginRight: '20px', marginTop: '7px' }}
                      onClick={() => toggleResrve('unblock', ticketView.ticket.ticket_id)}
                    >
                      Remove The Reserve
                    </button> :
                    <button
                      className='close'
                      style={{ marginRight: '20px', marginTop: '7px' }}
                      onClick={() => toggleResrve('block', ticketView.ticket.ticket_id)}
                    >
                      Reserve
                    </button> :
                  <button
                    className='unclose'
                    style={{ marginRight: '20px', marginTop: '5px', cursor: 'inherit' }}
                  >
                    {errorText}
                  </button>
              }

              <button
                className='clear'
                style={{
                  marginTop: '7px',
                }}
                disabled={isReserve}
                onClick={() =>
                  !isReserve &&
                  ticketView.ticket.ticket_id &&
                  $router.router.navigate('tickets.item', {
                    companyId: activeAccountId,
                    ticketId: ticketView.ticket.ticket_id,
                  }, { reload: true })}
              >
                Open
              </button>

              <button
                style={{
                  width: '16px',
                  background: 'inherit',
                  marginTop: '7px',
                  marginRight: '-7px',
                }}
                onClick={() => {
                  isReserve && toggleResrve('unblock', ticketView.ticket.ticket_id)
                  handleCloseViewTicket()
                }}
              >
                <Icon
                  style={{ fill: '#FF0000' }}
                  icon="x-mark-1"
                />
              </button>
            </div>
          </div>

          <div className="fields title">
            <div className="__left">
              <div className="flex-container _gap-narrow">
                <div className="item-name">
                  Ticket: {ticketView.ticket.number}
                </div>
              </div>
            </div>
          </div>

          <div
            className="contents-view"
            style={{ position: 'relative' }}
          >
            {
              isReserve &&
              ticketView.ticket.type !== 'Closed' &&
              ticketView.ticket.type !== 'Canceled' &&
              <div style={{ position: 'absolute', top: '5px', right: '5px', display: 'flex' }}>
                <button
                  className='close'
                  onClick={() => setEditStatus('Close')}
                // onClick={() => handleSave(ticketView.ticket.ticket_id)}
                >
                  Close
                </button>
                <button
                  className='cancel'
                  style={{ marginLeft: '10px' }}
                  onClick={() => setEditStatus('Cancel')}
                >
                  Cancel
                </button>
              </div>
            }
            <div className='contents-view-row'>
              <div className='contents-view-item'>
                <span> Date: </span>
                <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, ticketView.ticket.date_time_start)} </span>
              </div>

              <div className='contents-view-item'>
                <span>Type:</span>
                <span> {ticketView.ticket.type} </span>
              </div>
            </div>

            <div className='contents-view-row'>
              <div className='contents-view-item'>
                <span>Status:</span>
                <span> {ticketView.ticket.status} </span>
              </div>

              <div className='contents-view-item'>
                <span>Author:</span>
                <span> {ticketView.ticket.name} </span>
              </div>
            </div>

            <div className='contents-view-row'>
              <span>Theme:</span>
              <span> {ticketView.ticket.theme} </span>
            </div>
          </div>

          <div
            style={{ marginTop: '10px', position: 'relative' }}
            className="contents-view contents-view-checkbox"
          >
            {
              isReserve &&
              <button
                className='close'
                disabled={!readyToSave}
                style={{ position: 'absolute', bottom: '5px', right: '5px' }}
                onClick={() => handleSave(ticketView.ticket.ticket_id)}
              >
                Updated
              </button>
            }
            <div className='contents-view-row'>
              <div className='contents-view-item'>
                <span>Author:</span>
                <Checkbox
                  contents=""
                  disabled={!isReserve}
                  value={sendTo.author}
                  onChange={(value) => {
                    setReadyToSave(true)
                    setSendTo({ ...sendTo, author: value })
                  }}
                />
              </div>
              <div className='contents-view-item'>
                <span>Service Resource:</span>
                <Checkbox
                  contents={getServiceName()}
                  disabled={!isReserve || getServiceName() === ''}
                  value={sendTo.service_resource}
                  onChange={(value) => {
                    setReadyToSave(true)
                    setSendTo({ ...sendTo, service_resource: value })
                  }}
                />
              </div>
            </div>

            <div style={{ flexWrap: 'wrap' }} className='contents-view-row'>
              {
                ticketView.edit.user_groups.map((item, index) => (
                  <div key={index} className='contents-view-item'>
                    <span>{capitalizeFirstLetter(item.name)}:</span>
                    <Checkbox
                      contents=""
                      disabled={!isReserve}
                      value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                      onChange={(value) => {
                        setReadyToSave(true)
                        setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })
                      }}
                    />
                  </div>
                ))
              }
            </div>
          </div>

          <div
            style={{ marginTop: '10px', position: 'relative' }}
            className="contents-view"
          >
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              position: 'relative'
            }}>

              <textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  padding: '0',
                  border: 'none',
                }}
                disabled={!isReserve}
                placeholder='Your Message'
                value={message}
                onChange={({ target: { value } }) => setMessage(value)}
                onKeyDown={(event) => handleChangeMessage(event, ticketView.ticket.ticket_id)}
              />

              <button
                style={{
                  position: 'absolute',
                  right: '0px',
                  bottom: '0px'
                }}
                className='send'
                disabled={message === '' || !isReserve}
                onClick={() => handleSendMessage(ticketView.ticket.ticket_id)}
              >
                Send
              </button>
            </div>
          </div>


          {
            !!ticketView.ticket_items.length &&
            <div
              className="contents-view"
              style={{
                marginTop: '10px',
                overflow: 'auto',
                maxHeight: '300px'
              }}
            >
              <div className='chat'
              >
                {
                  ticketView.ticket_items.map((message, i) => (
                    <div key={i} className='chat-row chat-update'>
                      <div className='chat-update-item'>
                        <p> {message.message} </p>
                        <p style={{ textAlign: 'right' }} >
                          <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)}</span>
                        </p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
      }

      {editStatus ? (
        <div className="item-delete-popup" onClick={() => setEditStatus('')}>

          <div className="wrapper" onClick={(e) => e.stopPropagation()}>

            <div className="title">
              {editStatus} Ticket
            </div>

            <div className="checkbox-wrapper">
              <Checkbox
                contents="I understand that after change status the ticket it will not be possible to recover."
                value={readyChangeStatus}
                onChange={(value) => setReadyChangeStatus(value)}
              />
            </div>

            <div className="buttons">

              <button className="_bordered _green" onClick={() => setEditStatus('')}>
                Cancel
              </button>

              <button
                disabled={!readyChangeStatus}
                className="_bordered _red"
                onClick={() => editStatus === 'Close' ?
                  ticketView && handleUpdatedStatus(ticketView.ticket.ticket_id, ticketView.edit?.status.filter(item => item.name === 'Closed').length ? ticketView.edit?.status.filter(item => item.name === 'Closed')[0].id : 0) :
                  ticketView && handleUpdatedStatus(ticketView.ticket.ticket_id, ticketView.edit?.status.filter(item => item.name === 'Canceled').length ? ticketView.edit?.status.filter(item => item.name === 'Canceled')[0].id : 0)
                }
              >
                Change Status
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
