import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UsersMenusReport
}

export interface UsersMenusEdit {
  id: number,
  name: string
}

export interface MenusRows {
  menu_id: string,
  name: string,
  users: string
}

export interface UsersMenusReport {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    user_menu_add: boolean
    user_menu_delete: boolean
    user_menu_edit: boolean
    user_menu_report_show: boolean
    user_menu_show: boolean
  },

  menus_rows: MenusRows[],

  edit: {
    menu_items: UsersMenusEdit[]
  }
}

function UsersMenusPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<UsersMenusReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    sort: {
      field: cookiesSetting && cookiesSetting?.users_menus?.sort_field ? cookiesSetting.users_menus.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.users_menus?.sort_type ? cookiesSetting.users_menus.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Accounts',
      value: 'accounts'
    }]

  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.users_menus?.sort_field ? cookiesSetting.users_menus.sort_field : 'name',
        direction: cookiesSetting && cookiesSetting?.users_menus?.sort_type ? cookiesSetting.users_menus.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load accountPermissions function
  async function loadAccountPermissions() {

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, users_menus: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.users_menus?.limit_rows ? cookiesSetting?.users_menus.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: usersMenus, success, error } } = (await httpClientUpdate.post('/menus/users/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.users_menus?.limit_rows ? cookiesSetting?.users_menus?.limit_rows : reportsMaxRows,
        ...filterParams,
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (usersMenus.permissions.user_menu_report_show) {
          setReportData(usersMenus)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, users_menus: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }


  // Load accountPermissions on page mount
  useEffect(() => {
    loadAccountPermissions()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {reportData &&
        <div className="AccountPermissionsPage_List" >

          { /* Page header */}
          <div className="page-header">
            <h1>User`s menus</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            onUpdate={() => {
              $setUpdater(Math.random());
            }}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.users_menus?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, users_menus: { ...cookiesSetting.users_menus, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.user_menu_add ?
                (
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('users_menus.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      })}>
                      <Icon icon="plus-thin" />
                      <span>Add User`s menus</span>
                      <Icon icon="shield-28" />
                    </button>
                  </div>
                ) : <></>
            }
          />

          <div className="contents">

            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Name</span>)}

                    sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Users</span>)}

                    sortDirection={localInterface.sort.field === 'accounts' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'accounts', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>

              {reportData.menus_rows.map((menu_item, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.user_menu_show && menu_item.menu_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.user_menu_show &&
                    menu_item.menu_id &&
                    $router.router.navigate('users_menus.item', {
                      companyId: activeAccountId,
                      usersMenusId: menu_item.menu_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.user_menu_show &&
                    menu_item.menu_id &&
                    handleChangeMenu(event, menu_item.menu_id, 'users_menus', i)}
                >
                  <td>{menu_item.name}</td>
                  <td>
                    {menu_item.users}
                    {
                      reportData.permissions.user_menu_show &&
                      showContext === `users_menus_${menu_item.menu_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('users_menus.item', {
                              companyId: activeAccountId,
                              usersMenusId: menu_item.menu_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/users_menus/${menu_item.menu_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Medium desktop table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr>
                <th>Name</th>
                <th>Users</th>
              </tr>
              {reportData.menus_rows.map((menu_item, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.user_menu_show && menu_item.menu_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.user_menu_show &&
                    menu_item.menu_id &&
                    $router.router.navigate('users_menus.item', {
                      companyId: activeAccountId,
                      usersMenusId: menu_item.menu_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.user_menu_show &&
                    menu_item.menu_id &&
                    handleChangeMenu(event, menu_item.menu_id, 'users_menus', i)}
                >
                  <td>{menu_item.name}</td>
                  <td>
                    {menu_item.users}
                    {
                      reportData.permissions.user_menu_show &&
                      showContext === `users_menus_${menu_item.menu_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('users_menus.item', {
                              companyId: activeAccountId,
                              usersMenusId: menu_item.menu_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/users_menus/${menu_item.menu_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {reportData.menus_rows.map((menu_item, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    reportData.permissions.user_menu_show &&
                    menu_item.menu_id &&
                    $router.router.navigate('users_menus.item', {
                      companyId: activeAccountId,
                      usersMenusId: menu_item.menu_id,
                      localInterface: localInterface,
                    }, {
                      reload: true
                    })}>

                  <div className="__top">
                    <div className="__left">
                      <div><b>{menu_item.name}</b></div>
                      <div>
                        {menu_item.users || <span>&nbsp;</span>}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.users_menus?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, users_menus: { ...cookiesSetting.users_menus, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}

export default UsersMenusPage_List