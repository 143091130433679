import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import Select from '../../components/Select'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  edit: {
    estimate_status: {
      estimate_status: string
      estimate_status_id: number
    }[]
  }
  permissions: {
    technician_estimate_delete: boolean
    technician_estimate_edit: boolean
    technician_invoice_add: boolean
  }
  estimate: EstimateProps
}

interface EstimateProps {
  comment: string
  contact_id: string
  estimate_id: string
  job_id: string
  name: string
  status: string
  status_id: number
  total: number
  service_resource_code: number
  service_resource_nickname: string
  data: {
    name: string
    price: number
    type: string
  }[]
}

interface EstimateEditProps {
  switchPage: (name: string) => void
  id: string
}

export default function EstimateEdit({ switchPage, id }: EstimateEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [editing, setEditing] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [data, setData] = useState<DataProps | null>(null)
  const [newEstimateData, setNewEstimateData] = useState<Partial<EstimateProps>>({
    data: [{
      name: '',
      price: 0,
      type: 'part',
    }]
  })

  async function loadEstimate() {
    try {
      const { data: { data: invoiceData, success, error } } = await httpClientUpdate.get('/technician/estimates/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setData(invoiceData)
        setNewEstimateData({
          ...newEstimateData,
          ...invoiceData.estimate
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadEstimate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newEstimateData
  ])

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/estimates/${id}`, {
      data: {
        account_id: activeAccountId,
      }
    })
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleCancelEdit() {
    data && setNewEstimateData({
      ...data.estimate,
      data: [{ name: '', price: 0, type: 'part' }]
    })
    setEditing(false)
  }

  async function handleSave() {
    const response = await httpClientUpdate.put(`/technician/estimates/${id}`, qs.stringify({
      account_id: activeAccountId,
      comment: newEstimateData.comment,
      status_id: newEstimateData.status_id,
      appointment_id: $router.router.getState().params.jobId,
      job_id: newEstimateData.job_id,
      client_id: newEstimateData.contact_id
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChange(value: string | number, name: string, index: number) {
    let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function handleRemoveUnit(index: number) {
    let updateData = newEstimateData.data && newEstimateData.data.filter((item, idx) => idx !== index)
    setNewEstimateData({ ...newEstimateData, data: updateData })
  }

  function handleAddUnit() {
    if (newEstimateData.data) {
      let updateData = newEstimateData.data.map(item => item)
      updateData.push({
        name: '', price: 0, type: 'part'
      })
      setNewEstimateData({ ...newEstimateData, data: updateData })
    }
  }

  // function getTotal() {
  //   let sum = 0.00
  //   newEstimateData.data && newEstimateData.data.forEach(item => {
  //     sum = sum + +item.price
  //   })
  //   return sum.toFixed(2)
  // }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            price: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewEstimateData({ ...newEstimateData, data: updateData })
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewEstimateData({ ...newEstimateData, data: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newEstimateData.data && newEstimateData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewEstimateData({ ...newEstimateData, data: updateData })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  return (
    <>
      {
        data ?
          <div className="EstimatePage_Edit entity-edit">
            <div className="wrapper flex-container sb">
              <div className="flex-container _gap-narrow">
                <h1>Estimate: </h1>
                <div className="item-name">
                  {newEstimateData.name}
                </div>
              </div>

              <div style={{ display: 'flex' }} >
                <button
                  disabled={editing}
                  style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  onClick={() => switchPage('main')}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>

                {
                  !editing &&
                    data.permissions.technician_estimate_edit ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      <Icon
                        style={{ width: '16px', height: '16px' }}
                        viewBox="0 0 16 16"
                        icon="pencil-1"
                      />
                    </button>
                  ) : null
                }
              </div>
            </div>

            <div className="fieldset">
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Service Resource:</span>
                    <input
                      type="text"
                      defaultValue={newEstimateData.service_resource_code ? `${newEstimateData.service_resource_nickname} (${newEstimateData.service_resource_code})` : newEstimateData.service_resource_nickname}
                      disabled={true}
                      onChange={({ target: { value } }) => setNewEstimateData({ ...newEstimateData, service_resource_nickname: value })}
                    />
                  </div>

                  <div className="field">
                    <span>Status:</span>
                    {
                      data.edit.estimate_status ?
                        <Select
                          disabled={!editing}
                          options={data.edit.estimate_status.map((option) => ({
                            span: option.estimate_status,
                            value: option.estimate_status_id
                          }))}
                          selectedOption={newEstimateData.status_id as number || 0}
                          onChange={(value) => setNewEstimateData({ ...newEstimateData, status_id: value as number })}
                        /> :
                        <input
                          type="text"
                          defaultValue={newEstimateData.status}
                          disabled={true}
                          onChange={({ target: { value } }) => setNewEstimateData({ ...newEstimateData, status: value })}
                        />
                    }
                  </div>


                </div>

                <div className="__right">
                  <div className="field">
                    <span>Comment:</span>
                    <textarea
                      style={{ marginRight: '0', marginLeft: '0' }}
                      className='description'
                      name='comment'
                      disabled={!editing}
                      value={newEstimateData.comment || ''}
                      onChange={({ target: { value, name } }) => setNewEstimateData({ ...newEstimateData, comment: value })}
                    >

                    </textarea>
                  </div>
                </div>
              </div>

              <div className="fields">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      newEstimateData.data && newEstimateData.data.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: '55%' }}>
                            <input
                              type="text"
                              value={item.name || ''}
                              onChange={({ target: { value } }) => handleChange(value, 'name', index)}
                              disabled={!editing}
                            />
                          </td>

                          <td style={{ width: '20%' }}>
                            <Select
                              disabled={!editing}
                              options={['part', 'labor'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={item.type as string}
                              onChange={(value) => handleChange(value as string, 'type', index)}
                            />
                          </td>

                          <td style={{ width: '20%' }}>
                            <input
                              style={{ textAlign: 'right' }}
                              type="number"
                              disabled={!editing}
                              value={item.price.toFixed(2)}
                              onFocus={(event) => handleFocus(event)}
                              onChange={(event) => handleChangePrice(event, index)}
                              onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                            />
                          </td>

                          <td style={{ width: '5%' }}>
                            {
                              editing &&
                              <button
                                onClick={() => handleRemoveUnit(index)}
                                style={{ background: 'inherit', width: 'fit-content', padding: '0', margin: '0 auto' }}
                              >
                                <Icon
                                  style={{ fill: '#FF0000', width: '15px', height: '15px' }}
                                  icon="x-mark-1"
                                />
                              </button>
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="field">
                {
                  editing &&
                  data.permissions.technician_invoice_add &&
                  <div style={{ marginTop: '10px' }} className='add-button'>
                    <button
                      className='_green'
                      onClick={() => handleAddUnit()}
                    >Add</button>
                  </div>
                }
              </div>

              <div style={{ flexWrap: 'nowrap' }} className="fields">
                <div style={{ width: '50%' }} ></div>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>Total:</span>
                  <input
                    style={{ marginRight: '20px', textAlign: 'right' }}
                    type="text"
                    // value={getTotal()}
                    value={newEstimateData.total?.toFixed(2) || ''}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.technician_estimate_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Estimate</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Estimate
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the estimate it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div> : null
      }
    </>
  )
}
