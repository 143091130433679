import { useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'

import Icon from '../../../components/Icon'
import Switcher from '../../../components/Switcher'

import { useAppSelector } from '../../../store/hooks'
import { httpClientUpdate } from '../../../funcs'

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [inputTypes, setInputTypes] = useState({
    twilio_account_sid: 'password' as 'text' | 'password',
    twilio_call_token: 'password' as 'text' | 'password',
    twilio_token: 'password' as 'text' | 'password',
    twilio_app_sid: 'password' as 'text' | 'password',
  })

  const [newData, setNewData] = useState({
    is_call_center: true,
    name: '',
    twilio_account_sid: '',
    twilio_app_sid: '',
    twilio_call_token: '',
    twilio_token: '',
    call_time_out: 10,
    voice: '',
  })

  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/call-center
      const response = await httpClientUpdate.post(`/call-center`, {
        account_id: activeAccountId,
        is_call_center: newData.is_call_center ? 1 : 0,
        name: newData.name,
        twilio_account_sid: newData.twilio_account_sid,
        app_sid: newData.twilio_app_sid,
        twilio_call_token: newData.twilio_call_token,
        twilio_token: newData.twilio_token,
        call_time_out: newData.call_time_out,
        voice: newData.voice,
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.callCenters', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="CallCenter_CallGroupsPage_New entity-edit">

      {/* Top navigation */}
      <div className="top-nav">
        <BaseLink
          router={$router.router}
          routeName="call_center.phoneNumbers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
        >
          <Icon icon="phone-1" />
          <span>Phone Numbers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.dispatchers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
        >
          <Icon icon="user-1" />
          <span>Dispatchers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.additionalTime"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="time-22" />
          <span>Additional Time</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.absence"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.absence" })}
        >
          <Icon icon="time-16" />
          <span>Absence</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.groups" })}
        >
          <Icon icon="user-29" />
          <span>Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callFlows"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
        >
          <Icon icon="share-7" />
          <span>Call Flows</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.caller_groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
        >
          <Icon icon="user-29" />
          <span>Caller Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.temporaryClosed"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="close-664" />
          <span>Temporary Closed</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callCenters"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
        >
          <Icon icon="delivery-8" />
          <span>Call Centers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.audioLibrary"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="audio-15" />
          <span>Audio Library</span>
        </BaseLink>
      </div>

      <div className="wrapper flex-container sb">
        <h1>New Call Center</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('call_center.callCenters', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>

              <input
                type="text"
                value={newData.name || ''}
                autoComplete="new-off"
                onChange={(event) => setNewData({ ...newData, name: event.target.value })}
              />

            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Active:</span>

              <Switcher
                checked={newData.is_call_center}
                onChange={(value) => setNewData({ ...newData, is_call_center: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Twilio</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Account SID:</span>
              <div className="form-field _iconed">
                <input
                  type={inputTypes.twilio_account_sid}
                  autoComplete="new-twilio"
                  value={newData.twilio_account_sid || ''}
                  onChange={({ target: { value } }) => setNewData({ ...newData, twilio_account_sid: value })}
                />
                <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_account_sid: inputTypes.twilio_account_sid === 'text' ? 'password' : 'text' })}>
                  {
                    inputTypes.twilio_account_sid === 'text' ?
                      <Icon icon="eye-4" /> :
                      <Icon
                        style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                        viewBox="0 0 1024 1024"
                        icon="eye-block"
                      />
                  }
                </button>
              </div>
            </div>

            <div className="field">
              <span>App SID:</span>
              <div className="form-field _iconed">
                <input
                  type={inputTypes.twilio_app_sid}
                  autoComplete="new_twilio_app_sid"
                  value={newData.twilio_app_sid || ''}
                  onChange={({ target: { value } }) => setNewData({ ...newData, twilio_app_sid: value })}
                />
                <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_app_sid: inputTypes.twilio_app_sid === 'text' ? 'password' : 'text' })}>
                  {
                    inputTypes.twilio_app_sid === 'text' ?
                      <Icon icon="eye-4" /> :
                      <Icon
                        style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                        viewBox="0 0 1024 1024"
                        icon="eye-block"
                      />
                  }
                </button>
              </div>
            </div>

            <div className="field">
              <span>Voice:</span>
              <input
                type="text"
                value={newData.voice || ''}
                autoComplete="new_voice"
                onChange={({ target: { value } }) => setNewData({ ...newData, voice: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Token:</span>
              <div className="form-field _iconed">
                <input
                  type={inputTypes.twilio_token}
                  autoComplete="new_twilio_token"
                  value={newData.twilio_token || ''}
                  onChange={({ target: { value } }) => setNewData({ ...newData, twilio_token: value })}
                />
                <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_token: inputTypes.twilio_token === 'text' ? 'password' : 'text' })}>
                  {
                    inputTypes.twilio_token === 'text' ?
                      <Icon icon="eye-4" /> :
                      <Icon
                        style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                        viewBox="0 0 1024 1024"
                        icon="eye-block"
                      />
                  }
                </button>
              </div>
            </div>

            <div className="field">
              <span>Call Token:</span>
              <div className="form-field _iconed">
                <input
                  type={inputTypes.twilio_call_token}
                  autoComplete="new_twilio_call_token"
                  value={newData.twilio_call_token || ''}
                  onChange={({ target: { value } }) => setNewData({ ...newData, twilio_call_token: value })}
                />
                <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_call_token: inputTypes.twilio_call_token === 'text' ? 'password' : 'text' })}>
                  {
                    inputTypes.twilio_call_token === 'text' ?
                      <Icon icon="eye-4" /> :
                      <Icon
                        style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                        viewBox="0 0 1024 1024"
                        icon="eye-block"
                      />
                  }
                </button>
              </div>
            </div>

            <div className="field">
              <span>Call Time Out:</span>

              <input
                type="number"
                className="appearance"
                value={newData.call_time_out || ''}
                onChange={({ target: { value } }) => setNewData({ ...newData, call_time_out: +value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button className="_bordered _red" onClick={() => $router.router.navigate('call_center.absence', {
            companyId: activeAccountId
          }, {
            reload: true
          })}>
            Cancel
          </button>
          <button
            disabled={isSaving}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
