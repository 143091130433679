import { useEffect, useState } from "react"

import { AppointDataProps } from "./List"
import { ClientProps } from "./Models"

interface ClientBlockProps {
  editing: boolean
  setEditing: (value: string) => void
  clientData: ClientProps
  setClientData: (value: ClientProps) => void
  appointmentReport: Partial<AppointDataProps>
  updatedClientItem: () => void
  disabledEditButton: boolean
  permissions_client_edit: boolean
}

export default function ClientBlock({
  editing,
  setEditing,
  clientData,
  setClientData,
  appointmentReport,
  updatedClientItem,
  disabledEditButton,
  permissions_client_edit
}: ClientBlockProps) {
  const [readyToSave, setReadyToSave] = useState(false)

  useEffect(() => {
    editing && setReadyToSave(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData])

  function handleCancel() {
    setEditing('')
    setReadyToSave(false)
    appointmentReport && appointmentReport.client && setClientData(appointmentReport.client)
  }

  function handleSave() {
    updatedClientItem()
    setReadyToSave(false)
  }

  return (
    <>
      <div className="fieldset">
        <div className="block-content-title">
          <div className="block-title">
            Client
          </div>
        </div>

        <div className="fields title">
          <div className="__left">
            <div className="flex-container _gap-narrow">
              <div className="item-name">
                Total Jobs: {clientData.jobs_count}
              </div>
            </div>
          </div>

          <div className="__right">
            <div
              className="field"
              style={{ justifyContent: 'flex-end' }}
            >
              {
                !editing &&
                <button
                  className="btn btn-add"
                  disabled={disabledEditButton || !permissions_client_edit}
                  onClick={() => setEditing('Client')}
                >
                  Edit
                </button>
              }
              {
                editing &&
                <>
                  <button
                    className="btn btn-add btn-cancel"
                    style={{ width: 'fit-content' }}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="btn btn-add btn-save"
                    style={{ width: 'fit-content' }}
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </>
              }
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>First Name:</span>

              <input
                type="text"
                disabled={!editing}
                value={clientData.first_name}
                onChange={({ target: { value } }) => setClientData({ ...clientData, first_name: value })}
              />
            </div>

            <div className="field">
              <span>Last Name:</span>
              <input
                type="text"
                disabled={!editing}
                value={clientData.last_name}
                onChange={({ target: { value } }) => setClientData({ ...clientData, last_name: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Company:</span>
              <input type="text"
                disabled={!editing}
                value={clientData.company_name}
                onChange={({ target: { value } }) => setClientData({ ...clientData, company_name: value })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
