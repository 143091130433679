import { memo } from 'react'

import Icon from '../../components/Icon'

interface ZipCodeProps {
  zipCheck: string
  changeZipCode: (value: string) => void
  checkZip: () => void
  status: { available: boolean; text: string }[]
  availability: boolean
  outAvailable: string
}

function ZipCode({
  zipCheck,
  changeZipCode,
  checkZip,
  status,
  availability,
  outAvailable,
}: ZipCodeProps) {

  return (
    <div
      className='call-zipCode'
      style={{ marginBottom: '10px' }}
    >
      <div className='block-content'>
        <div className='block-content-title'>
          <div className='block-title'>
            Zip Code
          </div>
          {
            availability ?
              status.length === 0 ?
                <div className='btn-marking attention'>
                  !
                </div> :
                <div className='btn-marking saved'>
                  <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                </div> : null
          }
        </div>

        <div className='call-zipCode_content'>
          <div className='call-zipCode_form'>
            <input
              className='data-border call-zipCode_input'
              type="text"
              value={zipCheck}
              onChange={({ target: { value } }) => changeZipCode(value)}
            />
            <button
              className='btn call-zipCode_btn'
              disabled={zipCheck === ''}
              onClick={() => checkZip()}
            >
              Check
            </button>
          </div>

          <div className='call-zipCode_status'>
            {
              outAvailable &&
              <span
                style={{
                  width: '100%',
                  textAlign: 'center',
                  color: '#FF0000'
                }}
              >
                {outAvailable}
              </span>
            }
            {
              status.map((item, index) => (
                <p
                  key={index}
                  className={
                    item.available ?
                      'green' : 'red'
                  }>
                  {item.text}
                </p>
              ))
            }
          </div>
        </div>
      </div>
    </div >
  )
}

export default memo(ZipCode)