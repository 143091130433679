import { useState } from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'
import classNames from 'classnames';

import { RescheduleAppoint } from './GantChart'
import Select from '../../components/Select'
import Checkbox from '../../components/Checkbox'

interface AppointProps {
  appoint: RescheduleAppoint
  setAppoint: (value: RescheduleAppoint | null) => void
}

export default function RescheduleAppointPopup({ appoint, setAppoint }: AppointProps) {
  const [readyToDelete, setReadyToDelete] = useState({
    customer: false,
    technician: false,
    client: false
  })

  function getAppointInfo() {
    let serviceName = appoint.service_resource_code ? `${appoint.service_resource_nickname}(${appoint.service_resource_code})` : appoint.service_resource_nickname
    return `${moment(appoint.appointment_date_start, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} - ${moment(appoint.appointment_date_end, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} ${serviceName}`
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '90vh',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99999',
      }}>
      <div className='appointment-reschedule-confirmation appointment-reschedule-confirmation-popup'>
        <div className='appointment-reschedule-confirmation__header'>
          <p className='appointment-reschedule-confirmation__job-number'>
            {appoint.appointment_job_number}
          </p>

          <div className='appointment-reschedule-confiramtion__header-text'>
            <p> Reschedule an Appointment </p>
          </div>

          <div className='appointment-reschedule-confirmation__appointment-info'>
            <p>
              {getAppointInfo()}
            </p>
          </div>

          <div className='appointment-reschedule-confirmation__select-wrapper'>
            <div className='appointment-reschedule-confirmation__select-wrapper-item'>
              <Datetime
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                // inputProps={{ disabled: !editing }}
                value={moment(appoint.appointment_date_start, "MMMM DD, YYYY, h:mmA").format("YYYY-MM-DD")}
              // onChange={(value) => setNewData({ ...newData, date: moment(value).format("YYYY-MM-DD") })}
              />
            </div>

            <div className='appointment-reschedule-confirmation__select-wrapper-item'>
              <Select
                options={appoint.service_resource_select.map((option) => ({
                  span: option.service_resource_code ? `${option.service_resource_nickname}(${option.service_resource_code})` : option.service_resource_nickname,
                  value: option.service_resource_id
                }))}
                selectedOption={appoint.service_resource_id as string}
                onChange={(value) => setAppoint({ ...appoint, service_resource_id: value as string })}
              />
            </div>
          </div>

          <div className='appointment-reschedule-confirmation__hour-select-list'>
            <div className='appointment-reschedule-confirmation__hour-select-list-item'>
              <Datetime
                dateFormat={false}
                timeFormat="hh:mm A"
                // value={appoint.appointment_date_start}
                value={moment(appoint.appointment_date_start, "MMMM DD, YYYY, h:mmA").format("hh:mm A")}
                onChange={(value) => setAppoint({ ...appoint, appointment_date_start: moment(value).format("hh:mm A") })}
              />
            </div>

            <span className='appointment-reschedule-confirmation__hour-select-list-span'></span>

            <div className='appointment-reschedule-confirmation__hour-select-list-item'>
              <Datetime
                dateFormat={false}
                timeFormat="hh:mm A"
                value={moment(appoint.appointment_date_end, "MMMM DD, YYYY, h:mmA").format("hh:mm A")}
                onChange={(value) => setAppoint({ ...appoint, appointment_date_end: moment(value).format("hh:mm A") })}
              />
            </div>
          </div>

          <div className='appointment-reschedule-confirmation__questions-wrapper'>
            <div className='appointment-reschedule-confirmation__questions'>
              <div className='appointment-reschedule-confirmation__questions-item appointment-reschedule-confirmation__questions-item_client-informed'>
                <Checkbox
                  contents="I understand that the customer must be informed of the changes"
                  value={readyToDelete.customer}
                  onChange={(value) => setReadyToDelete({
                    ...readyToDelete,
                    customer: value
                  })}
                />
              </div>

              <div className='appointment-reschedule-confirmation__questions-item appointment-reschedule-confirmation__questions-item_service-resource-informed'>
                <Checkbox
                  contents="I understand that the technician must be informed of the changes"
                  value={readyToDelete.technician}
                  onChange={(value) => setReadyToDelete({
                    ...readyToDelete,
                    technician: value
                  })}
                />
              </div>
            </div>

            <div className='appointment-reschedule-confirmation__questions'>
              <div className='appointment-reschedule-confirmation__questions-item appointment-reschedule-confirmation__questions-item_client-notification'>
                <Checkbox
                  contents="Send notification client"
                  value={readyToDelete.client}
                  onChange={(value) => setReadyToDelete({
                    ...readyToDelete,
                    client: value
                  })}
                />
              </div>
            </div>
          </div>

          <div className='appointment-reschedule-confirmation__btns'>
            <div
              className='appointment-reschedule-confirmation__deny-btn'
              onClick={() => setAppoint(null)}
            >
              Cancel
            </div>

            <div
              className={classNames('appointment-reschedule-confirmation__confirm-btn',
                {
                  btn_disabled: !readyToDelete.customer || !readyToDelete.technician
                }
              )}
              onClick={() => setAppoint(null)}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
