import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'template'

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: WebsiteDataProps
}

interface WebsiteDataProps {
  interface: {
    filter_words: {
      template: string[],
    },
    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  website: WebsiteProps[]

  permissions: {
    website_add: boolean
    website_show: boolean
  }
}

interface WebsiteProps {
  created_by: string
  db_host: string
  db_name: string
  db_user_name: string
  db_user_password: string
  domain: string
  template: string
  token: string
  updated_by: string
  website_id: string
  status: string
}

interface ListProps {
  updated: number
}

export default function List({ updated }: ListProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<WebsiteDataProps | null>(null)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.wesites?.filter_field ? {
      template: [] as string[],
      ...cookiesSetting.wesites.filter_field,
    } : {
      template: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.wesites?.sort_field ? cookiesSetting.wesites.sort_field : 'domain',
      direction: cookiesSetting && cookiesSetting?.wesites?.sort_type ? cookiesSetting.wesites.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Domain',
      value: 'domain'
    }, {
      span: 'Template',
      value: 'template'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.wesites?.filter_field ? {
        template: [] as string[],
        ...cookiesSetting.wesites.filter_field,
      } : {
        template: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.wesites?.sort_field ? cookiesSetting.wesites.sort_field : 'domain',
        direction: cookiesSetting && cookiesSetting?.wesites?.sort_type ? cookiesSetting.wesites.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load mailboxes function
  async function loadData() {
    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    setCookie('settings', { ...cookiesSetting, wesites: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.wesites?.limit_rows ? cookiesSetting?.wesites.limit_rows : reportsMaxRows } })
    try {
      // https://2022back4.artemiudintsev.com/api/websites/report
      const { data: { data: response, success, error } } = (await httpClientUpdate.get('/websites/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.wesites?.limit_rows ? JSON.stringify(cookiesSetting?.wesites?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: JSON.stringify(localInterface.page),
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
        }
      })) as { data: HttpReport }
      if (success) {
        setReportData({
          ...response,
          permissions: {
            website_add: true,
            website_show: true
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, wesites: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="CallsPage_List">

        { /* Page header */}
        <div className="page-header">
          <h1>Websites</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            setReportIsDeprecated(false);
            $setUpdater(Math.random());
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.wesites?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, wesites: { ...cookiesSetting.wesites, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.website_show ?
              <div className="add-button-wrapper">
                <button
                  className="_iconed _rounded add-button"
                  onClick={() => $router.router.navigate('websites.new', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                  }, { reload: true })}
                >
                  <Icon icon="plus-thin" />
                  <span>Add Website</span>
                  <Icon icon="user-29" />
                </button>
              </div> : <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th></th>
                <ReportTableField
                  contents={(<span>Domain</span>)}

                  sortDirection={localInterface.sort.field === 'domain' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'domain', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Template</span>)}

                  allFiltersSelected={isAllFiltersSelected("template")}
                  onAllFiltersChange={(value) => switchFilter("template", "All", value)}

                  filterWords={reportData.interface.filter_words.template.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("template", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("template", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'template' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'template', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {
              reportData.website.map((website, i) => (
                <tr
                  key={website.website_id}
                  style={{
                    cursor: reportData.permissions.website_show ? 'pointer' : 'inherit',
                    position: 'relative'
                  }}
                  onClick={() =>
                    reportData.permissions.website_show &&
                    website.website_id &&
                    $router.router.navigate('websites.main', {
                      companyId: activeAccountId,
                      websiteId: website.website_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) => {
                    reportData.permissions.website_show &&
                      website.website_id &&
                      handleChangeMenu(event, website.website_id, 'websites', i)
                  }}
                >
                  <td style={{ width: '40px' }}>
                    <div className={classNames('mailbox',
                      {
                        _red: website.status === 'inactive',
                        _blue: website.status === 'not_installed',
                        _green: website.status === 'active'
                      }
                    )}></div>
                  </td>
                  <td> {website.domain} </td>
                  <td>
                    {website.template}

                    {
                      reportData.permissions.website_show &&
                      showContext === `websites_${website.website_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('websites.main', {
                              companyId: activeAccountId,
                              websiteId: website.website_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/websites/${website.website_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))
            }
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.website.map((website, i) => (
              <tr
                key={i}
                style={{
                  position: 'relative',
                  cursor: reportData.permissions.website_show ? 'pointer' : 'inherit'
                }}
                onClick={() =>
                  reportData.permissions.website_show &&
                  website.website_id &&
                  $router.router.navigate('websites.main', {
                    companyId: activeAccountId,
                    websiteId: website.website_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) => {
                  reportData.permissions.website_show &&
                    website.website_id &&
                    handleChangeMenu(event, website.website_id, 'websites', i)
                }}
              >
                <td style={{ width: '40px' }}>
                  <div className={classNames('mailbox',
                    {
                      _red: website.status === 'inactive',
                      _blue: website.status === 'not_installed',
                      _green: website.status === 'active'
                    }
                  )}></div>
                </td>

                <td>
                  {website.domain}
                </td>

                <td>
                  {website.template}

                  {
                    reportData.permissions.website_show &&
                    showContext === `websites_${website.website_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('websites.main', {
                            companyId: activeAccountId,
                            websiteId: website.website_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/websites/${website.website_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.website.map((website, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.website_show &&
                  website.website_id &&
                  $router.router.navigate('websites.main', {
                    companyId: activeAccountId,
                    websiteId: website.website_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div className={classNames('mailbox-mobile',
                      {
                        _red: website.status === 'inactive',
                        _blue: website.status === 'not_installed',
                        _green: website.status === 'active'
                      }
                    )}
                      style={{ marginRight: '5px' }}
                    ></div>
                    <span className="gray">Domain: </span>
                    <span> {website.domain}</span>
                  </div>

                  <div className="__right">
                    <span className="gray">Template: </span>
                    <span> {website.template}</span>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                  </div>

                  <div className="__right small">
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.wesites?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, wesites: { ...cookiesSetting.wesites, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}
