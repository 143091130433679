import { useEffect, useState } from 'react'
import { useRoute } from "react-router5"
import moment from 'moment'
import { DateTime } from 'luxon'
import classNames from "classnames"

// import BookingLeftSide from './BookingLeftSide'
import BookingCenter from './BookingCenter'
import BookingRightSide from './BookingRightSide'
import ModalSave from './ModalSave'
import ModalErrorSave from './ModalErrorSave'
import Checkbox from '../../components/Checkbox'

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import {
  CallTypeProps,
  checkAvailableDataProps,
  SettingsProps,
  EditDataProps,
  UnitsListProps,
  CallContactProps,
  SelectedAvailabelTimeProps,
  NewContactProps,
  PhonesProps,
  AddressProps,
  ClientObjForServer
} from './Models'

import '../../styles/pages/booking.sass'
import ModalDifferentZip from './ModalDifferentZip'

interface HttpClientDataCompaniesReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: EditDataProps
}

interface HttpClientZipReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: checkAvailableDataProps
}

interface HttpClientCallTypeReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallTypeProps
}

interface HttpClientCheckContactReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    clients: NewContactProps[]
  }
}

export interface HttpReserveReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    reserved_at: string
  }
}

// const addressOptions = ['1234 Florida Rt, Fort Mayers, 34354 FL', 'Address 2', 'Address 3']
const fromActionOptions = ['', 'Outgoing Call - From: (712) 343-3344 TexaxGoogleAds - To: (712) 341-8898 Robert May - Dispatcher: Mike', 'Other']
const leadSourceOptions = ['', 'Incoming Missing Call - From: (712) 341-8898 Robert May - To: (712) 341-8898 (712) 343-3344 TexasGoogle', 'Other']

function BookingPage({ updated }: { updated: number }) {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [page, setPage] = useState('Main')

  const [isDifferentZip, setIsDifferentZip] = useState(false)
  const [changedZipCode, setChangedZipCode] = useState(false)

  const [zipCheck, setZipCheck] = useState('')
  const [note, setNote] = useState('Customer asked technician to call them before he arrived')
  const [settings, setSettings] = useState<SettingsProps>({
    showDays: 3,
    show_service: true,
    availability: true,
    area: false,
    duration: 30,
    on_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
    on_time_start: '',
    on_time_end: ''
  })
  const [status, setStatus] = useState<{ available: boolean, text: string }[] | []>([])

  const [checkAvailable, setCheckAvailable] = useState<checkAvailableDataProps>({
    available: [],
    status: []
  })
  const [outAvailable, setOutAvailable] = useState('')
  const [callTypeData, setCallTypeData] = useState<CallTypeProps | null>(null)
  const [editData, setEditData] = useState<Partial<EditDataProps>>({})

  const [phonesList, setPhonesList] = useState<PhonesProps[] | []>([])
  const [addressesList, setAddressesList] = useState<AddressProps[] | []>([])
  const [unitsList, setUnitsList] = useState<UnitsListProps[] | []>([])

  const [callContact, setCallContact] = useState<CallContactProps>({
    client: [
      {
        client_id: null,
        client_first_name: "",
        client_last_name: "",
        client_company_name: "",
        phone: '',
        subtitle: '',
        active: true,
        new: true,
        address: [],
        phones: [],
        units: []
      },
    ],
  })

  const [isAvalableOneService, setIsAvalableOneService] = useState<string[]>([])
  // const [selectedAddress, setSelectedAddress] = useState(addressOptions[0])
  const [selectedCompany, setSelectedCompany] = useState('')
  const [selectedFromAction, setSelectedFromAction] = useState(fromActionOptions[1])
  const [selectedLeadSource, setSelectedLeadSource] = useState(leadSourceOptions[1])
  const [selectedSource, setSelectedSource] = useState('')

  const [selectedJob, setSelectedJob] = useState('')

  const [openLeftSide, setOpenLeftSide] = useState(false)
  const [selectedAvailabelTime, setSelectedAvailabelTime] = useState<SelectedAvailabelTimeProps | null>(null)

  const [callType, setCallType] = useState('Service')
  const [callTypeSearch, setCallTypeSearch] = useState('')
  const [checkNewContactError, setCheckNewContactError] = useState('')
  const [modalSave, setModalSave] = useState(false)
  const [modalErrorSave, setModalErrorSave] = useState(false)

  const [startReserve, setStartReserve] = useState(false)

  const [isManualAval, setIsManualAval] = useState(false)
  const [selectedMenualTime, setSelectedMenualTime] = useState(false)
  const [readyToChangeManual, setReadyToChangeManual] = useState(false)
  const [cancelChangeTime, setCancelChangeTime] = useState(false)

  const [menualSettings, setMenualSettings] = useState({
    start: '',
    end: '',
    service_resource_id: '',
    service_resource_name: '',
    time_zone: ''
  })

  const [doubleSelected, setDoubleSelected] = useState('')

  const [isSaveClick, setIsSaveClick] = useState(false)

  function changeZipCode(value: string) {
    setZipCheck(value)
  }

  // function loading editData
  async function loadingCompanies() {
    try {
      const { data: { data: loadCompanies, success, error } } = await httpClientUpdate.get('/bookings', {
        params: {
          account_id: activeAccountId,
        }
      }) as { data: HttpClientDataCompaniesReport }
      if (success) {
        setEditData(loadCompanies)
        let { company_id } = $router.router.getState().params
        if (company_id) {
          setSelectedCompany(company_id)
        } else {
          setSelectedCompany(loadCompanies.companies[0].company_id)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // loading check-available zip
  async function checkZip(zip?: string) {
    try {
      const { data: { data: checkAvailableData, success, error } } = await httpClientUpdate.get('/bookings/check-available', {
        params: {
          account_id: activeAccountId,
          zip_code: zip ? zip : zipCheck,
          days: settings.showDays,
          date: moment(settings.on_date).format('YYYY-MM-DD')
        }
      }) as { data: HttpClientZipReport }
      if (success) {
        if (checkAvailableData.status.length) {
          setOutAvailable('')
        } else {
          setOutAvailable('Out Of Service Area')
        }
        setCheckAvailable(checkAvailableData)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  //  search input in callType component
  async function handleSearchOnCallType(phone?: string) {
    try {
      setIsAvalableOneService([])
      setIsSaveClick(true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: searchData, success, error } } = await httpClientUpdate.get('/bookings/client-search', {
        params: {
          account_id: activeAccountId,
          key_words: phone ? formatPhoneNumberToServerString(phone) : callTypeSearch,
          page: 1,
          limit_rows: 100,
        }
      }) as { data: HttpClientCallTypeReport }
      if (success) {
        setSelectedJob('')
        setCallTypeData(searchData)
        setCallContact({
          client: [
            {
              client_id: null,
              client_first_name: "",
              client_last_name: "",
              client_company_name: "",
              phone: '',
              subtitle: '',
              active: true,
              new: true,
              address: [],
              phones: [],
              units: []
            },
          ],
        })
        setIsSaveClick(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
        setIsSaveClick(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
      setIsSaveClick(false)
    }
  }

  // reserve time
  async function reserveAvalTime(start: string, end: string, service_resource_id: string, type: string, time_zone: string) {
    try {
      setIsSaveClick(true)

      // https://2022back4.artemiudintsev.com/api/bookings/appointments/reserve
      const { data: { data: reserveData, success } } = await httpClientUpdate.post('/bookings/appointments/reserve', {
        account_id: activeAccountId,
        start: updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, start as string),
        end: updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, end as string),
        service_resource_id,
        type,
      }) as { data: HttpReserveReport }
      if (success) {
        if (reserveData.reserved_at) {
          setStartReserve(true)
          setIsSaveClick(false)
        } else {
          setStartReserve(false)
          setCancelChangeTime(false)
          setDoubleSelected('')
          setIsSaveClick(false)
        }
      } else {
        setIsSaveClick(false)
        // $router.router.navigate(`${error.code}`, {
        //   reload: true
        // })
      }
    }
    catch (error) {
      setDoubleSelected('')
      setIsSaveClick(false)
    }
  }

  function getUnitsForServer() {
    let unitsArray: { unit_id: string | null; unit_type: string; appliance_id: string | null; brand_id: string; model_number: string; serial_number: string; other_appliance?: string | undefined; other_brand?: string | undefined }[] = []
    let unitsNewListUse = unitsList.filter(item => item.is_use)

    callContact.client.filter(item => item.active)[0].units.forEach(item => {
      if (item.is_use) {
        let obj: {
          unit_id: string | null
          unit_type: string
          appliance_id: string | null
          brand_id: string
          model_number: string
          serial_number: string
          description: string
          price: string
          other_appliance?: string
          other_brand?: string
        } = {
          unit_id: item.unit_id ? item.unit_id : null,
          unit_type: item.unit_type,
          appliance_id: item.appliance_id,
          brand_id: item.brand_id,
          model_number: item.model_number,
          serial_number: item.serial_number,
          description: item.description,
          price: item.diagnostic_fee
        }
        if (item.other_brand) {
          obj.other_brand = item.other_brand
        }
        if (item.other_unit) {
          obj.other_appliance = item.other_unit
        }

        unitsArray.push(obj)
      }
    })
    let unitsData = unitsNewListUse.map(item => {
      let obj: {
        unit_id: string | null
        unit_type: string
        appliance_id: string | null
        brand_id: string
        model_number: string
        serial_number: string
        description: string
        price: string
        other_appliance?: string
        other_brand?: string
      } = {
        unit_id: item.unit_id ? item.unit_id : null,
        unit_type: item.unit_type,
        appliance_id: item.appliance_id,
        brand_id: item.brand_id,
        model_number: item.model_number,
        serial_number: item.serial_number,
        description: item.description,
        price: item.diagnostic_fee
      }
      if (item.other_brand) {
        obj.other_brand = item.other_brand
      }
      if (item.other_unit) {
        obj.other_appliance = item.other_unit
      }

      return obj
    })

    return unitsArray.concat(unitsData)
  }

  function getClientForServer() {
    const client = callContact.client.filter(item => item.active)

    let clientObj: ClientObjForServer = {
      client_id: client[0].client_id,
      client_first_name: client[0].client_first_name,
      client_last_name: client[0].client_last_name,
      client_phone: formatPhoneNumberToServerString(client[0].phone),
    }

    if (callType === 'Service' && client[0].client_company_name) {
      clientObj.client_company_name = client[0].client_company_name
    }
    if (!clientObj.client_id) {
      delete clientObj.client_id
    }
    if (!clientObj.client_first_name) {
      delete clientObj.client_first_name
    }
    if (!clientObj.client_last_name) {
      delete clientObj.client_last_name
    }
    if (!clientObj.client_phone) {
      delete clientObj.client_phone
    }

    return clientObj
  }

  useEffect(() => {
    setChangedZipCode(false)
  }, [zipCheck, addressesList, callContact.client])

  function getAddressForServer() {
    let activeAddress = callContact.client.filter(item => item.active)[0].address.filter(item => item.use)
    let activeNewAddress = addressesList.filter(item => item.use)

    let clientObj: {
      address_id: string | null
      type: string
      street: string
      city: string
      state: string
      postal_code: string
      unit?: string
      note?: string
    } = {
      address_id: activeAddress.length ? activeAddress[0].address_id : activeNewAddress[0].address_id,
      type: activeAddress.length ? activeAddress[0].address_arr : activeNewAddress[0].address_arr,
      street: activeAddress.length ? activeAddress[0].address_street : activeNewAddress[0].address_street,
      city: activeAddress.length ? activeAddress[0].address_city : activeNewAddress[0].address_city,
      state: activeAddress.length ? activeAddress[0].address_state : activeNewAddress[0].address_state,
      postal_code: activeAddress.length ? activeAddress[0].address_zip : activeNewAddress[0].address_zip,
    }

    if (activeAddress.length) {
      if (activeAddress[0].address_appatment) {
        clientObj.unit = `${activeAddress[0].apt}#${activeAddress[0].address_appatment}`
      }
      if (activeAddress[0].address_note) {
        clientObj.note = activeAddress[0].address_note
      }
    }

    if (activeNewAddress.length) {
      if (activeNewAddress[0].address_appatment) {
        clientObj.unit = `${activeNewAddress[0].apt}#${activeNewAddress[0].address_appatment}`
      }
      if (activeNewAddress[0].address_note) {
        clientObj.note = activeNewAddress[0].address_note
      }
    }

    return clientObj
  }

  // save document
  async function handleSave(): Promise<void> {
    setIsSaveClick(true)
    try {
      let phonesActiveUse = callContact.client.filter(item => item.active)[0].phones.filter(item => item.use)
      let newPhonesUse = phonesList.filter(item => item.use)
      let time_zone = ''

      if (!settings.availability) {
        editData.edit?.service_resources.forEach(item => {
          if (item.service_resource_id === menualSettings.service_resource_id) {
            time_zone = item.time_zone
          }
        })
      }

      let start = settings.availability ?
        updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', selectedAvailabelTime?.time_zone, `${selectedAvailabelTime?.time_slot_start}` as string) :
        updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.start}` as string)
      let end = settings.availability ?
        updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', selectedAvailabelTime?.time_zone, `${selectedAvailabelTime?.time_slot_finish}` as string) :
        updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.end}` as string)

      let callReq: { source_id?: string, job_id?: string, company_id?: string } = {
        source_id: selectedSource,
        job_id: selectedJob,
        company_id: selectedCompany,
      }

      if (callType !== 'Service') {
        delete callReq.source_id
        delete callReq.company_id
      }
      if (callType === 'Service') {
        delete callReq.job_id
      }

      if (!changedZipCode && getAddressForServer().postal_code !== zipCheck) {
        setIsDifferentZip(true)
        setChangedZipCode(true)
        setIsSaveClick(false)
      } else {

        const response = await httpClientUpdate.post(`/bookings/make-appointment`, {
          account_id: activeAccountId,
          ...callReq,
          time_start: start,
          time_finish: end,
          service_resource_id: settings.availability ? selectedAvailabelTime?.service_resource_id : menualSettings.service_resource_id,
          is_manual: settings.availability ? 0 : 1,
          non_schedule: 0,
          type: callType === 'Service' ? 'SC' : callType === 'Follow' ? 'FU' : 'RC',
          note: note,
          client: getClientForServer(),
          phone_numbers: {
            ...phonesActiveUse.concat(newPhonesUse).map(item => {
              return {
                phone_id: item.id,
                name: item.name,
                phone: formatPhoneNumberToServerString(item.phone),
                is_main: item.is_main ? 1 : 0,
                is_payer: item.payer ? 1 : 0,
                is_sms: item.sms ? 1 : 0
              }
            })
          },
          address: {
            ...getAddressForServer()
          },
          units: {
            ...getUnitsForServer()
          }
        })

        if (response.data.success) {
          setModalSave(true)
          setIsSaveClick(false)
        } else {
          setModalErrorSave(true)
          setIsSaveClick(false)
        }
      }
    } catch (err) {
      setModalErrorSave(true)
      setIsSaveClick(false)
    }
  }

  async function checkNewContact(phone?: string) {
    try {
      setIsSaveClick(true)
      let client = callContact.client.filter(item => item.new)
      let params: {
        account_id: string | null,
        first_name?: string
        last_name?: string
        company_name?: string
        main_phone?: string
      } = {
        account_id: activeAccountId,
        first_name: client[0].client_first_name,
        last_name: client[0].client_last_name,
        company_name: client[0].client_company_name,
        main_phone: formatPhoneNumberToServerString(client[0].phone)
      }

      if (params.main_phone === '+' || params.main_phone === '') {
        delete params.main_phone
      }
      if (params.company_name === '') {
        delete params.company_name
      }
      if (params.first_name === '') {
        delete params.first_name
      }
      if (params.last_name === '') {
        delete params.last_name
      }

      let paramsFromCall = {
        account_id: activeAccountId,
        main_phone: phone
      }

      const { data: { data: clientData, success } } = await httpClientUpdate.get('/bookings/check-client', {
        params: phone ? paramsFromCall : params
      }) as { data: HttpClientCheckContactReport }

      if (success) {
        if (clientData.clients.length) {
          let clearClient = callContact.client.filter(item => item.new)

          let updateClient = clientData.clients.length === 0 ? clearClient.map(item => {
            return {
              ...item,
              active: false,
            }
          }) : clearClient.map(item => {
            if (params.main_phone) {
              return {
                ...item,
                phones: [{
                  id: null,
                  name: 'main',
                  phone: params.main_phone as string,
                  subtitle: 'From Check Client',
                  active: false,
                  use: false,
                  payer: false,
                  sms: false,
                  new: false,
                  is_main: false
                }]
              }
            } else {
              return { ...item, phones: [] }
            }
          })

          clientData.clients.forEach((item, index) => {
            let phones = item.phones.map(phone => {
              return {
                id: phone.contact_phone_id,
                name: phone.name,
                phone: phone.phone,
                subtitle: 'From Check Client',
                active: false,
                use: false,
                payer: false,
                sms: false,
                new: false,
                is_main: phone.is_main
              }
            })
            let addresses = item.addresses.map((address, idx) => {
              addressesList.length && setAddressesList(addressesList.map(item => {
                return {
                  ...item,
                  use: false
                }
              }))

              let unit = ''
              let apt = ''

              if (address.unit) {
                if (address.unit.includes('#')) {
                  let split = address.unit.split('#')

                  unit = split[1]
                  apt = split[0]
                } else {
                  unit = address.unit
                  apt = 'Apt'
                }
              } else {
                apt = 'Apt'
              }

              return {
                address_zip: address.postal_code,
                address_street: address.street,
                address_appatment: unit,
                address_city: address.city,
                address_state: address.state,
                address_state_id: '',
                address_arr: address.type,
                address_note: address.note,
                address_id: address.address_id,
                apt: apt,
                new: false,
                use: item.addresses.length - 1 === idx
              }
            })
            let units = item.units.map(unit => {
              return {
                unit_type: unit.unit_type,
                appliance: unit.appliance,
                appliance_id: unit.appliance_id,
                unit_id: unit.unit_id,
                brand: unit.brand,
                brand_id: unit.brand_id,
                model_number: unit.model_number,
                serial_number: unit.serial_number,
                description: unit.description,
                diagnostic_fee: unit.price,
                other_unit: unit.other_appliance,
                other_brand: unit.other_brand,
                is_new: false,
                is_use: false
              }
            })
            updateClient.unshift({
              client_id: item.client_id,
              client_first_name: item.first_name,
              client_last_name: item.last_name,
              client_company_name: item.company_name,
              phone: item.main_phone,
              subtitle: 'From Check Client',
              active: false,
              new: false,
              phones: phones,
              address: addresses,
              units: units
            })
          })

          setCallContact({
            client: updateClient,
          })
        } else {
          if (params.main_phone) {
            let clearClient = callContact.client.filter(item => item.new)
            let updateClient = clearClient.map(item => {
              return {
                ...item,
                phones: [{
                  id: null,
                  name: 'main',
                  phone: params.main_phone as string,
                  subtitle: 'From Check Client',
                  active: false,
                  use: false,
                  payer: false,
                  sms: false,
                  new: false,
                  is_main: false
                }]
              }
            })

            setCallContact({
              client: updateClient,
            })
          }
          setCheckNewContactError('Not Found')
        }
        setIsSaveClick(false)
      } else {
        setCheckNewContactError('Not Found')
        setIsSaveClick(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      setCheckNewContactError(createdError.content.errorText)
      setIsSaveClick(false)
    }
  }

  function handleClearBooking() {
    setIsAvalableOneService([])
    setIsSaveClick(false)
    setOutAvailable('')
    setSelectedJob('')
    setMenualSettings({
      start: '',
      end: '',
      service_resource_id: '',
      service_resource_name: '',
      time_zone: '',
    })
    setSelectedMenualTime(false)
    setZipCheck('')
    setSettings({
      showDays: 3,
      show_service: true,
      availability: true,
      area: false,
      duration: 30,
      on_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
      on_time_start: '',
      on_time_end: ''
    })
    setStatus([])
    setCallTypeData(null)
    setUnitsList([])
    setCallContact({
      client: [
        {
          client_id: null,
          client_first_name: "",
          client_last_name: "",
          client_company_name: "",
          phone: '',
          subtitle: '',
          active: true,
          new: true,
          address: [],
          phones: [],
          units: []
        },
      ],
    })
    setAddressesList([])
    setPhonesList([])
    // setSelectedAddress(addressOptions[0])
    setSelectedCompany(editData.companies ? editData.companies[0].company_id : '')
    setSelectedSource('')
    setSelectedAvailabelTime(null)
    setCallType('Service')
    setCallTypeSearch('')
    setModalSave(false)
    setCheckAvailable({
      available: [],
      status: []
    })
    setCheckNewContactError('')
    setNote('Customer asked technician to call them before he arrived')
  }

  // loading editData for booking
  useEffect(() => {
    loadingCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStatus(getStatusZip())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAvailable])

  useEffect(() => {
    let { caller_phone, lead_text, source_id } = $router.router.getState().params
    if (caller_phone) {
      setCallTypeSearch(caller_phone)
      checkNewContact(caller_phone)
    }

    if (lead_text) {
      fromActionOptions.push(lead_text)
      leadSourceOptions.push(lead_text)

      setSelectedFromAction(lead_text)
      setSelectedLeadSource(lead_text)
    }

    if (source_id) {
      setSelectedSource(source_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router])

  useEffect(() => {
    if (updated) {
      handleClearBooking()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    setMenualSettings({
      start: '',
      end: '',
      service_resource_id: '',
      service_resource_name: '',
      time_zone: '',
    })
    setSelectedMenualTime(false)
    setSettings({
      ...settings,
      availability: true,
    })
    setCheckAvailable({
      available: [],
      status: []
    })
    doubleSelected && handleCancelChangeTime()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.on_date])

  useEffect(() => {
    let phone = formatPhoneNumberToServerString(callContact.client[callContact.client.length - 1].phone)
    let phoneData = callContact.client[callContact.client.length - 1].phones
    if (phone.length === 12 && phoneData.length && phone !== formatPhoneNumberToServerString(phoneData[0].phone)) {
      setCallContact({
        client: callContact.client.map((item, idx) => {
          if (callContact.client.length - 1 === idx) {
            return {
              ...item,
              phones: item.phones.map((phone, index) => {
                if (index === 0) {
                  return {
                    ...phone,
                    phone: callContact.client[callContact.client.length - 1].phone
                  }
                } else {
                  return { ...phone }
                }
              })
            }
          } else {
            return { ...item }
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callContact.client[callContact.client.length - 1].phone])

  useEffect(() => {
    if (selectedJob && callTypeData) {
      let updatedData = callTypeData.jobs.filter(item => item.job_id === selectedJob)

      if (updatedData.length) {
        if (updatedData[0].appointments.length) {
          const serviceResurces: string[] = []
          updatedData[0].appointments.forEach(appoint => {
            if (!serviceResurces.includes(appoint.service_resource_id)) {
              serviceResurces.push(appoint.service_resource_id)
            }
          })
          if (serviceResurces.length === 1) {
            setIsAvalableOneService(serviceResurces)
          }
        }
      }

      setCallTypeData({
        jobs: updatedData
      })
      if (updatedData.length) {
        let clearClient = callContact.client.filter(item => item.new)
        let updateClient = clearClient.map(item => {
          return {
            ...item,
            active: false,
          }
        })

        updatedData.forEach((item, index) => {
          let phones = item.contact_phones.map(phone => {
            return {
              id: phone.contact_phone_id || null,
              name: phone.name,
              phone: phone.phone,
              subtitle: 'From Check Client',
              active: false,
              use: false,
              payer: false,
              sms: false,
              new: false,
              is_main: false
            }
          })
          let addresses = item.addresses.map((address, idx) => {
            addressesList.length && setAddressesList(addressesList.map(item => {
              return {
                ...item,
                use: false
              }
            }))

            let unit = ''
            let apt = ''

            if (address.unit) {
              if (address.unit.includes('#')) {
                let split = address.unit.split('#')

                unit = split[1]
                apt = split[0]
              } else {
                unit = address.unit
                apt = 'Apt'
              }
            } else {
              apt = 'Apt'
            }

            return {
              address_zip: address.postal_code,
              address_street: address.street,
              address_appatment: unit,
              address_city: address.city,
              address_state: address.state,
              address_state_id: '',
              address_arr: address.type,
              address_note: address.note,
              address_id: address.address_id,
              apt: apt,
              new: false,
              use: item.addresses.length - 1 === idx
            }
          })

          let units = item.units.map(unit => {
            return {
              unit_type: unit.unit_type,
              unit: '',
              appliance: unit.appliance,
              appliance_id: unit.appliance_id,
              unit_id: unit.unit_id,
              brand: unit.brand,
              brand_id: unit.brand_id,
              model_number: unit.model_number,
              serial_number: unit.serial_number,
              description: unit.description,
              diagnostic_fee: '',
              other_unit: unit.other_appliance,
              other_brand: unit.other_brand,
              is_new: false,
              is_use: false
            }
          })
          updateClient.unshift({
            client_id: item.client.client_id,
            client_first_name: item.client.first_name,
            client_last_name: item.client.last_name,
            client_company_name: item.client.company_name,
            phone: item.client.main_phone,
            subtitle: 'From Search Client',
            active: true,
            new: false,
            phones: phones,
            address: addresses,
            units: units
          })
        })

        setCallContact({
          client: updateClient,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob])

  useEffect(() => {
    if (callType === 'Follow' || callType === 'Recall') {
      callContact.client.forEach(item => {
        if (item.active) {
          item.address.forEach(address => {
            if (address.use) {
              setZipCheck(address.address_zip)
            }
          })
        }
      })
    }
  }, [callType, callContact])

  function getStatusZip() {
    let status: { available: boolean; text: string }[] = []

    if (checkAvailable) {
      let statusArr: { available: boolean, text: string }[] = []
      checkAvailable.status.forEach(item => {
        if (statusArr.length === 0) {
          statusArr.push({
            available: item.available,
            text: item.service_resource_code ? `${item.service_resource} (${item.service_resource_code})` : `${item.service_resource}`
          })
        } else {
          let name = item.service_resource_code ? `${item.service_resource} (${item.service_resource_code})` : `${item.service_resource}`
          let checkStatus = statusArr.filter(str => str.text === name)

          if (checkStatus.length === 0) {
            statusArr.push({
              available: item.available,
              text: item.service_resource_code ? `${item.service_resource} (${item.service_resource_code})` : `${item.service_resource}`
            })
          }
        }
      })

      if (statusArr.length) {
        status = statusArr
      }
    }
    return status
  }

  function checkDisabledSaveButton() {
    let check = false

    if (callType === 'Service' && selectedSource === '') {
      check = true
    }

    if (callType !== 'Service' && selectedJob === '') {
      check = true
    }

    if (settings.availability && !selectedAvailabelTime) {
      check = true
    }

    if (!settings.availability && !selectedMenualTime) {
      check = true
    }

    if (selectedCompany === '') {
      check = true
    }

    let newUnitsListUse = unitsList.filter(item => item.is_use)
    let unitsListUse = callContact.client.filter(item => item.active)[0].units.filter(item => item.is_use)

    if (!newUnitsListUse.length && !unitsListUse.length) {
      check = true
    } else {
      newUnitsListUse.forEach(item => {
        if (item.brand_id === '' ||
          item.appliance_id === '' ||
          item.unit_type === '') {
          check = true
        }
      })
      unitsListUse.forEach(item => {
        if (item.brand_id === '' ||
          item.appliance_id === '' ||
          item.unit_type === '') {
          check = true
        }
      })
    }

    let newAddressUse = addressesList.filter(item => item.use)
    let addressUse = callContact.client.filter(item => item.active)[0].address.filter(item => item.use)

    if (!newAddressUse.length && !addressUse.length) {
      check = true
    } else {
      addressUse.forEach(item => {
        if (item.address_id === '' || item.address_arr === '' || item.address_street === '' ||
          item.address_city === '' || item.address_state === '' ||
          item.address_zip === '') {
          check = true
        }
      })
      newAddressUse.forEach(item => {
        if (item.address_arr === '' || item.address_street === '' ||
          item.address_city === '' || item.address_state === '' ||
          item.address_zip === '') {
          check = true
        }
      })
    }

    let newPhonesUse = phonesList.filter(item => item.use)
    let phonesUse = callContact.client.filter(item => item.active)[0].phones.filter(item => item.use)

    if (!newPhonesUse.length && !phonesUse.length) {
      check = true
    } else {
      newPhonesUse.forEach(item => {
        if (item.phone === '' || item.name === '') {
          check = true
        }
      })
      phonesUse.forEach(item => {
        if (item.phone === '' || item.name === '') {
          check = true
        }
      })
    }

    const client = callContact.client.filter(item => item.active)

    if (client.length) {
      if (client[0].new) {
        if (client[0].phone === '' ||
          (client[0].client_first_name === '' && client[0].client_last_name === '' &&
            client[0].client_company_name === '')
        ) {
          check = true
        }
      } else {
        if (client[0].client_id === '' && client[0].phone === '' &&
          client[0].client_first_name === '' && client[0].client_last_name === '' &&
          client[0].client_company_name === ''
        ) {
          check = true
        }
      }
    } else {
      check = true
    }

    return check
  }

  function errorTerminal() {
    let errorsArray = []

    if (callType === 'Service' && selectedSource === '') {
      errorsArray.push('Select source')
    }

    if (callType !== 'Service' && selectedJob === '') {
      errorsArray.push('Select job')
    }

    if (selectedCompany === '') {
      errorsArray.push('Select company')
    }

    if (settings.availability && status.length === 0) {
      errorsArray.push('Check Zip-code')
    }

    if (settings.availability && !selectedAvailabelTime) {
      errorsArray.push('Select Availabel Time')
    }

    if (!settings.availability && !selectedMenualTime) {
      errorsArray.push('Select Menual Time')
    }

    let newUnitsListUse = unitsList.filter(item => item.is_use)
    let unitsListUse = callContact.client.filter(item => item.active)[0].units.filter(item => item.is_use)

    if (!newUnitsListUse.length && !unitsListUse.length) {
      errorsArray.push('You need add units')
    } else {
      newUnitsListUse.forEach(item => {
        if (item.brand_id === '' ||
          item.appliance_id === '' ||
          item.unit_type === '') {
          errorsArray.push('Fill in all the fields in Units Block')
        }
      })
      unitsListUse.forEach(item => {
        if (item.brand_id === '' ||
          item.appliance_id === '' ||
          item.unit_type === '') {
          errorsArray.push('Fill in all the fields in Units Block')
        }
      })
    }

    let newAddressUse = addressesList.filter(item => item.use)
    let addressUse = callContact.client.filter(item => item.active)[0].address.filter(item => item.use)

    if (!newAddressUse.length && !addressUse.length) {
      errorsArray.push('You need to add or select a address in Addresses Block')
    } else {
      addressUse.forEach(item => {
        if (item.address_id === '' || item.address_arr === '' || item.address_street === '' ||
          item.address_city === '' || item.address_state === '' ||
          item.address_zip === '') {
          errorsArray.push('Fill in all the fields in Addresses Block')
        }
      })
      newAddressUse.forEach(item => {
        if (item.address_arr === '' || item.address_street === '' ||
          item.address_city === '' || item.address_state === '' ||
          item.address_zip === '') {
          errorsArray.push('Fill in all the fields in Addresses Block')
        }
      })
    }

    let newPhonesUse = phonesList.filter(item => item.use)
    let phonesUse = callContact.client.filter(item => item.active)[0].phones.filter(item => item.use)

    if (!newPhonesUse.length && !phonesUse.length) {
      errorsArray.push('You need to add or select a phone number in Phones Block')
    } else {
      newPhonesUse.forEach(item => {
        if (item.phone === '' || item.name === '') {
          errorsArray.push('Fill in all the fields in Phones Block')
        }
      })
      phonesUse.forEach(item => {
        if (item.phone === '' || item.name === '') {
          errorsArray.push('Fill in all the fields in Phones Block')
        }
      })
    }

    const client = callContact.client.filter(item => item.active)

    if (client.length) {
      if (client[0].new) {
        if (client[0].phone === '' ||
          (client[0].client_first_name === '' && client[0].client_last_name === '' &&
            client[0].client_company_name === '')
        ) {
          errorsArray.push('Select contact or fill all the fields')
        }
      } else {
        if (client[0].client_id === '' && client[0].phone === '' &&
          client[0].client_first_name === '' && client[0].client_last_name === '' &&
          client[0].client_company_name === ''
        ) {
          errorsArray.push('Select contact or fill all the fields')
        }
      }
    }

    return errorsArray
  }

  function handleManualSchedule(availability: boolean) {
    if (availability) {
      setSettings({ ...settings, availability: availability })
      setIsManualAval(false)
      setReadyToChangeManual(false)
      setMenualSettings({
        start: '',
        end: '',
        service_resource_id: '',
        service_resource_name: '',
        time_zone: ''
      })
      setSelectedMenualTime(false)
    } else {
      setIsManualAval(true)
    }
  }

  function handleManualAvalTime() {
    setIsManualAval(false)
    setSettings({ ...settings, availability: false })
    setReadyToChangeManual(false)
    doubleSelected && handleCancelChangeTime()
  }

  function handleCancelChangeTime() {
    setZipCheck('')
    setDoubleSelected('')
    setCheckAvailable({
      available: [],
      status: []
    })
    setCancelChangeTime(true)
    setStartReserve(false)
    setSelectedMenualTime(false)
  }

  return (
    <div>
      {
        isDifferentZip &&
        <ModalDifferentZip
          setIsDifferentZip={setIsDifferentZip}
        />
      }
      {
        modalSave &&
        <ModalSave
          time_start={settings.availability ? selectedAvailabelTime?.time_slot_start : `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.start}`}
          time_finish={settings.availability ? selectedAvailabelTime?.time_slot_finish : `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.end}`}
          technician={settings.availability ? selectedAvailabelTime?.technician : menualSettings.service_resource_name}
          setModalSave={handleClearBooking}
        />
      }
      {
        modalErrorSave &&
        <ModalErrorSave
          setModal={setModalErrorSave}
        />
      }
      {/* Wide desktop and laptop */}
      < div className={
        classNames('booking_page', 'entity-edit', '__show-on-tablet', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })
      }>

        {/* {
          openLeftSide &&
          <BookingLeftSide
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            addressOptions={addressOptions}
          />
        } */}

        <BookingCenter
          openLeftSide={openLeftSide}
          setOpenLeftSide={setOpenLeftSide}

          selectedFromAction={selectedFromAction}
          setSelectedFromAction={setSelectedFromAction}
          fromActionOptions={fromActionOptions}

          selectedLeadSource={selectedLeadSource}
          setSelectedLeadSource={setSelectedLeadSource}
          leadSourceOptions={leadSourceOptions}

          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}

          callType={callType}
          setCallType={setCallType}
          callTypeSearch={callTypeSearch}
          setCallTypeSearch={setCallTypeSearch}
          handleSearchOnCallType={handleSearchOnCallType}
          callTypeData={callTypeData}

          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}

          companies={editData.companies || []}
          sources={editData.sources || []}
          unitOptions={editData.appliances || []}
          brandOptions={editData.appliance_brands || []}

          unitsList={unitsList}
          setUnitsList={setUnitsList}

          callContact={callContact}
          setCallContact={setCallContact}

          handleSave={handleSave}
          handleClearBooking={handleClearBooking}
          checkDisabledSaveButton={checkDisabledSaveButton}
          isSaveClick={isSaveClick}
          errorTerminal={errorTerminal}

          checkNewContact={checkNewContact}
          checkNewContactError={checkNewContactError}
          setCheckNewContactError={setCheckNewContactError}

          phonesList={phonesList}
          setPhonesList={setPhonesList}

          addressesList={addressesList}
          setAddressesList={setAddressesList}

          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}

          client_search_show={editData.permissions?.client_search_show || false}
          note={note}
          setNote={setNote}

          setIsDifferentZip={setIsDifferentZip}
          setChangedZipCode={setChangedZipCode}
          zipCheck={zipCheck}
        />

        <BookingRightSide
          settings={settings}
          setSettings={setSettings}
          checkAvailable={checkAvailable}
          setSelectedAvailabelTime={setSelectedAvailabelTime}
          selectedAvailabelTime={selectedAvailabelTime}

          zipCheck={zipCheck}
          changeZipCode={changeZipCode}
          checkZip={checkZip}
          status={status}
          outAvailable={outAvailable}

          startReserve={startReserve}
          reserveAvalTime={reserveAvalTime}
          isSaveClick={isSaveClick}

          serviceOptions={editData.edit?.service_resources || []}

          handleManualSchedule={handleManualSchedule}

          doubleSelected={doubleSelected}
          setDoubleSelected={setDoubleSelected}

          menualSettings={menualSettings}
          setMenualSettings={setMenualSettings}

          selectedMenualTime={selectedMenualTime}
          setSelectedMenualTime={setSelectedMenualTime}
          cancelChangeTime={cancelChangeTime}

          permissions={editData.permissions}
          isAvalableOneService={isAvalableOneService}
          appointment_reserve_time_set_duration={editData.permissions?.appointment_reserve_time_set_duration}
        />

        {/* Item share popup */}
        {isManualAval ? (
          <div
            className="item-delete-popup"
            onClick={() => {
              setIsManualAval(false)
              setReadyToChangeManual(false)
            }}
          >
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Manual schedule
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that I set the appointment manually without checking for available time slots"
                  value={readyToChangeManual}
                  onChange={(value) => setReadyToChangeManual(value)}
                />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => {
                    setReadyToChangeManual(false)
                    setIsManualAval(false)
                  }}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToChangeManual}
                  className="_bordered _red"
                  onClick={() => handleManualAvalTime()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div >

      {/* Wide on phone */}
      < div className={
        classNames('booking_page', 'entity-edit', 'mobile', '__hide-on-tablet', {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        })
      }>
        <div className='booking_adaptive-fixed'>
          <div className='booking_adaptive-buttons'>
            {/* <div
              onClick={() => setPage('Info')}
              className={`booking_adaptive-button booking_adaptive-button_left ${page === 'Info' ? 'selected' : ''}`}
            >
              <p>Info</p>
              <span></span>
            </div> */}

            <div
              onClick={() => setPage('Main')}
              className={`booking_adaptive-button booking_adaptive-button_center ${page === 'Main' ? 'selected' : ''}`}
            >
              <p>Main</p>
              <span></span>
            </div>

            <div
              onClick={() => setPage('Time')}
              className={`booking_adaptive-button booking_adaptive-button_right ${page === 'Time' ? 'selected' : ''}`}
            >
              <p>Time</p>
              <span></span>
            </div>
          </div>
        </div>

        <div className='booking_adaptive-block'></div>

        {/* {
          page === 'Info' &&
          <BookingLeftSide
            mobile={true}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            addressOptions={addressOptions}
          />
        } */}

        {
          page === 'Main' &&
          <BookingCenter
            mobile={true}
            openLeftSide={openLeftSide}
            setOpenLeftSide={setOpenLeftSide}

            selectedFromAction={selectedFromAction}
            setSelectedFromAction={setSelectedFromAction}
            fromActionOptions={fromActionOptions}

            selectedLeadSource={selectedLeadSource}
            setSelectedLeadSource={setSelectedLeadSource}
            leadSourceOptions={leadSourceOptions}

            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}

            callType={callType}
            setCallType={setCallType}
            callTypeSearch={callTypeSearch}
            setCallTypeSearch={setCallTypeSearch}
            handleSearchOnCallType={handleSearchOnCallType}
            callTypeData={callTypeData}

            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}

            companies={editData.companies || []}
            sources={editData.sources || []}
            unitOptions={editData.appliances || []}
            brandOptions={editData.appliance_brands || []}

            unitsList={unitsList}
            setUnitsList={setUnitsList}

            callContact={callContact}
            setCallContact={setCallContact}

            handleSave={handleSave}
            handleClearBooking={handleClearBooking}
            checkDisabledSaveButton={checkDisabledSaveButton}
            isSaveClick={isSaveClick}
            errorTerminal={errorTerminal}

            checkNewContact={checkNewContact}
            checkNewContactError={checkNewContactError}
            setCheckNewContactError={setCheckNewContactError}

            phonesList={phonesList}
            setPhonesList={setPhonesList}

            addressesList={addressesList}
            setAddressesList={setAddressesList}

            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}

            client_search_show={editData.permissions?.client_search_show || false}

            note={note}
            setNote={setNote}

            setIsDifferentZip={setIsDifferentZip}
            setChangedZipCode={setChangedZipCode}
            zipCheck={zipCheck}
          />
        }

        {
          page === 'Time' &&
          <BookingRightSide
            mobile={true}
            settings={settings}
            setSettings={setSettings}
            checkAvailable={checkAvailable}
            setSelectedAvailabelTime={setSelectedAvailabelTime}
            selectedAvailabelTime={selectedAvailabelTime}

            zipCheck={zipCheck}
            changeZipCode={changeZipCode}
            checkZip={checkZip}
            status={status}
            outAvailable={outAvailable}

            startReserve={startReserve}
            reserveAvalTime={reserveAvalTime}
            isSaveClick={isSaveClick}

            serviceOptions={editData.edit?.service_resources || []}
            handleManualSchedule={handleManualSchedule}

            doubleSelected={doubleSelected}
            setDoubleSelected={setDoubleSelected}

            menualSettings={menualSettings}
            setMenualSettings={setMenualSettings}
            selectedMenualTime={selectedMenualTime}
            setSelectedMenualTime={setSelectedMenualTime}
            cancelChangeTime={cancelChangeTime}

            permissions={editData.permissions}
            isAvalableOneService={isAvalableOneService}
            appointment_reserve_time_set_duration={editData.permissions?.appointment_reserve_time_set_duration}
          />
        }

        {/* Item share popup */}
        {isManualAval ? (
          <div
            className="item-delete-popup"
            onClick={() => {
              setIsManualAval(false)
              setReadyToChangeManual(false)
            }}
          >
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Manual schedule
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that I set the appointment manually without checking for available time slots"
                  value={readyToChangeManual}
                  onChange={(value) => setReadyToChangeManual(value)}
                />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => {
                    setReadyToChangeManual(false)
                    setIsManualAval(false)
                  }}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToChangeManual}
                  className="_bordered _red"
                  onClick={() => handleManualAvalTime()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default BookingPage
