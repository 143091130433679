import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import qs from 'qs'

import StripeComponent from './StripeComponent'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { DataProps, PaymentServicesProps } from './ItemTech'
import { SaveDataProps } from './PaymentCard'
import SquareComponent from './SquareComponent'

interface GetTokenForStripeProps {
  data: {
    stripe_pk: string
    stripe_fee_compensation: string
    permissions: {
      can_be_canceled_payment_fee_compensation: boolean
    }
  }
  success: boolean
  error: {
    code: number,
    message: string
  },
}

interface GetTokenForSquareProps {
  data: {
    square_payment_application_id: string
    square_payment_location_id: string
    square_payment_location_name: string
    square_fee_compensation: string
    permissions: {
      can_be_canceled_payment_fee_compensation: boolean
    }
  }
  success: boolean
  error: {
    code: number,
    message: string
  },
}

interface SaveReport {
  data: {
    email_notification_details: {
      notify_subject: string
      notify_text: string
    }
    message: string
    payment_id: string
    phone_notification_details: {
      notify_text: string
    }
  }
  success: boolean,
  error: {
    code: number,
    message: string
  },
}

interface PaymentFieldsProps {
  method: string
  setFeeCompensation: (value: number) => void
  setPermissionsFeeCompensation: (value: boolean) => void
  setPaymentPage: (value: string) => void
  sum: number
  feeCompensation: string
  data: DataProps[]
  job_id: string
  jobData: { amount: number, note: string, is_check: boolean } | null
  payment_services: PaymentServicesProps[]
  setSaveData: (value: SaveDataProps) => void
  company_id: string
  service_resource_id: string
}

export default function PaymentFields({
  method,
  setFeeCompensation,
  setPermissionsFeeCompensation,
  setPaymentPage,
  sum,
  feeCompensation,
  data,
  job_id,
  jobData,
  payment_services,
  setSaveData,
  company_id,
  service_resource_id,
}: PaymentFieldsProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [loading, setLoading] = useState(false)

  const [tokenStripe, setTokenStripe] = useState('')
  //@ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [tokenSquare, setTokenSquare] = useState({
    square_payment_application_id: '',
    square_payment_location_id: '',
    square_payment_location_name: '',
  })

  useEffect(() => {
    if (!!method) {
      if (method === 'stripe') {
        getTokenForStripe()
      } else {
        getTokenForSquare()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method])

  async function getTokenForStripe() {
    try {
      // https://2022back4.artemiudintsev.com/api/accounts/stripe?account_id=88888
      const { data: { data: token, success, error } } = (await httpClientUpdate.get(`/accounts/stripe`, {
        params: {
          account_id: activeAccountId,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: GetTokenForStripeProps }
      if (success) {
        setTokenStripe(token.stripe_pk)
        setFeeCompensation(+token.stripe_fee_compensation)
        setPermissionsFeeCompensation(token.permissions.can_be_canceled_payment_fee_compensation)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) { }
  }

  async function getTokenForSquare() {
    try {
      // https://2022back4.artemiudintsev.com/api/accounts/square?account_id=88888
      const { data: { data: token, success, error } } = (await httpClientUpdate.get(`/accounts/square`, {
        params: {
          account_id: activeAccountId,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: GetTokenForSquareProps }
      if (success) {
        setTokenSquare(token)
        setFeeCompensation(+token.square_fee_compensation)
        setPermissionsFeeCompensation(token.permissions.can_be_canceled_payment_fee_compensation)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) { }
  }

  async function handleSaveStripePay(id: string) {
    try {
      let payment_services_id = ''
      let items: DataProps[] = []

      payment_services.forEach(item => {
        if (item.name === 'Stripe') {
          payment_services_id = item.payment_services_id
        }
      })

      if (data.length) {
        items = data
      }

      if (jobData) {
        items.push({
          id: null,
          note: jobData.note,
          amount: jobData.amount,
          name: '',
          total: 0,
          paid: 0,
          is_check: true,
          is_add_invoice: false,
        })
      }

      // https://2022back4.artemiudintsev.com/api/payments
      const { data: { data: reportData, success, error } } = await httpClientUpdate.post(`/payments/pay/stripe`, qs.stringify({
        account_id: activeAccountId,
        service_id: payment_services_id,
        company_id: company_id,
        stripe_token: id,
        fee_compensation: feeCompensation,
        items: data.map(item => {
          if (item.is_add_invoice) {
            if (item.invoice_id) {
              return {
                service_resource_id: service_resource_id,
                note: item.note,
                total_item: item.amount,
                invoice_id: item.invoice_id,
                job_id: item.job_id
              }
            } else {
              return {
                service_resource_id: service_resource_id,
                note: item.note,
                total_item: item.amount,
                job_id: item.job_id
              }
            }
          } else {
            if (item.id) {
              return {
                job_id: job_id,
                invoice_id: item.id,
                total_item: item.amount,
                service_resource_id: service_resource_id,
                note: item.note,
              }
            } else {
              return {
                job_id: job_id,
                total_item: item.amount,
                service_resource_id: service_resource_id,
                note: item.note,
              }
            }
          }
        }),
      }, { skipNulls: true })) as { data: SaveReport }
      if (success) {
        setSaveData({
          isSendCheck: true,
          email: '',
          phone: '',
          is_repeated_email: false,
          is_repeated_phone: false,
          ...reportData,
          isErrorSendEmail: false,
          isErrorSendPhone: false,
          message_email: '',
          message_phone: '',
          isSendEmail: false,
          isSendPhone: false,
        })
        setTokenSquare({
          square_payment_application_id: '',
          square_payment_location_id: '',
          square_payment_location_name: '',
        })
        setFeeCompensation(0)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      setTokenSquare({
        square_payment_application_id: '',
        square_payment_location_id: '',
        square_payment_location_name: '',
      })
      setFeeCompensation(0)
    }
  }

  async function handlePaySquare(token: string) {
    setLoading(true)
    let payment_services_id = ''
    let items: DataProps[] = []

    payment_services.forEach(item => {
      if (item.name === 'Stripe') {
        payment_services_id = item.payment_services_id
      }
    })

    if (data.length) {
      items = data
    }

    if (jobData) {
      items.push({
        id: null,
        note: jobData.note,
        amount: jobData.amount,
        name: '',
        total: 0,
        paid: 0,
        is_check: true,
        is_add_invoice: false,
      })
    }

    try {
      // https://2022back4.artemiudintsev.com/api/payments/pay/square
      const { data: { data: reportData, success, error } } = await httpClientUpdate.post(`/payments/pay/square`, qs.stringify({
        account_id: activeAccountId,
        square_token: token,
        location_id: tokenSquare.square_payment_location_id,
        location_name: tokenSquare.square_payment_location_name,
        service_id: payment_services_id,
        company_id: company_id,
        fee_compensation: feeCompensation,
        items: data.map(item => {
          if (item.is_add_invoice) {
            if (item.invoice_id) {
              return {
                service_resource_id: service_resource_id,
                note: item.note,
                total_item: item.amount,
                invoice_id: item.invoice_id,
                job_id: item.job_id
              }
            } else {
              return {
                service_resource_id: service_resource_id,
                note: item.note,
                total_item: item.amount,
                job_id: item.job_id
              }
            }
          } else {
            if (item.id) {
              return {
                job_id: job_id,
                invoice_id: item.id,
                total_item: item.amount,
                service_resource_id: service_resource_id,
                note: item.note,
              }
            } else {
              return {
                job_id: job_id,
                total_item: item.amount,
                service_resource_id: service_resource_id,
                note: item.note,
              }
            }
          }
        }),
      }, { skipNulls: true })) as { data: SaveReport }
      if (success) {
        setSaveData({
          isSendCheck: true,
          email: '',
          phone: '',
          is_repeated_email: false,
          is_repeated_phone: false,
          ...reportData,
          isErrorSendEmail: false,
          isErrorSendPhone: false,
          message_email: '',
          message_phone: '',
          isSendEmail: false,
          isSendPhone: false,
        })
        setTokenSquare({
          square_payment_application_id: '',
          square_payment_location_id: '',
          square_payment_location_name: '',
        })
        setFeeCompensation(0)
        setLoading(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      setLoading(false)
      setTokenSquare({
        square_payment_application_id: '',
        square_payment_location_id: '',
        square_payment_location_name: '',
      })
      setFeeCompensation(0)
      setSaveData({
        isSendCheck: false,
        message: '',
        email_notification_details: {
          notify_subject: '',
          notify_text: '',
        },
        phone_notification_details: {
          notify_text: ''
        },
        payment_id: '',
        is_repeated_email: false,
        is_repeated_phone: false,
        email: '',
        phone: '',
        isErrorSendEmail: false,
        isErrorSendPhone: false,
        message_email: '',
        message_phone: '',
        isSendEmail: false,
        isSendPhone: false,
      })
    }
  }

  function handleCancel() {
    setPaymentPage('')
  }

  return (
    <div className='payment-block'>
      {
        method === 'stripe' &&
        !!tokenStripe &&
        <div className="fields">
          <StripeComponent
            tokenStripe={tokenStripe}
            handleSaveStripePay={handleSaveStripePay}
            cancel={handleCancel}
            disabled={sum <= 0 || loading}
            disabledCancel={loading}
            setLoading={setLoading}
          />
        </div>
      }

      {
        method === 'square' &&
        !!tokenSquare.square_payment_application_id &&
        <div className="fields">
          <SquareComponent
            square_payment_application_id={tokenSquare.square_payment_application_id}
            handlePaySquare={handlePaySquare}
            square_payment_location_id={tokenSquare.square_payment_location_id}
            disabled={sum <= 0 || loading}
            handleCancel={handleCancel}
          />
        </div>
      }
    </div>
  )
}
