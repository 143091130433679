import classNames from 'classnames'

import Icon from '../../components/Icon'

import { NavActive } from '../../store/reducer'

interface PaymentListProps {
  navActive: NavActive
  phoneCall: boolean
  paymentPage: string
  setPaymentPage: (value: string) => void
  setPaymentModal: (value: boolean) => void
  permissions_payment_make: boolean
}

export default function PaymentList({
  navActive,
  phoneCall,
  paymentPage,
  setPaymentPage,
  setPaymentModal,
  permissions_payment_make,
}: PaymentListProps) {
  return (
    <div
      className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
    >
      <div className='content-title'>
        <div
          className="content-title-row"
          style={{ alignItems: 'center' }}
        >
          <div></div>

          <div className='mobile-text'>
            Payment
          </div>

          <div
            onClick={() => setPaymentModal(false)}
            className="content-title-plus"
          >
            <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 60 60" icon="plus-job-tech-1" />
          </div>
        </div>
      </div>

      <div className='payment-button'>
        <button
          className={classNames('payment-button-credit',
            {
              __disabled: !permissions_payment_make
            }
          )}
          onClick={() => permissions_payment_make && setPaymentPage('credit_card')}
        >
          <Icon viewBox='0 0 108 78' icon="credit-card-1" />
          Credit card
        </button>
      </div>

      <div className='payment-button-items'>
        <button
          onClick={() => setPaymentPage('check')}
        >
          <Icon viewBox='0 0 90 90' icon="bank-check-36" />
          Check
        </button>

        <button
          onClick={() => setPaymentPage('cash')}
        >
          <Icon viewBox='0 0 100 58' icon="group-17" />
          Cash
        </button>

        <button
          onClick={() => setPaymentPage('other_list')}
        >
          <Icon style={{ fill: '#fff' }} viewBox='0 0 96 66' icon="other-job-tech-1" />
          Other
        </button>
      </div>
    </div>
  )
}
