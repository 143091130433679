import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5';
import { DateTime } from 'luxon';
import moment from 'moment';
import classNames from 'classnames';
import { useCookies } from "react-cookie";

import ReportFilters from '../../components/reports/Filters';
import ReportTableControls from '../../components/reports/TableControls';
import ReportTableField from '../../components/reports/TableField';

import { httpClientUpdate, nErrorUpdate } from '../../funcs';
import { getDateRangeByPreset } from '../../funcs/reports';
import { useAppSelector } from '../../store/hooks';

import { DateRangePreset } from '../../models/Misc';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';

interface ListProps {
  updated: number
}

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportDataProps
}

interface ReportDataProps {
  dashboard: {
    calls_received: string
    charged_leads: string
    total_calls: string
    total_cost: string
  }

  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  local_services: {
    current_period_charged_leads: string
    current_period_connected_phone_calls: string
    current_period_phone_calls: string
    current_period_total_cost: string
    google_local_service_id: string
    name: string
    is_connect: boolean
    last_daily_report: {
      average_five_star_rating: string
      average_weekly_budget: string
      impressions_last_two_days: number
      local_service_id: string
      phone_lead_responsiveness: string
      total_review: number
    }
  }[]
}

export default function List({ updated }: ListProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<ReportDataProps | null>(null)

  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_7_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: 'name',
        direction: 'down' as 'up' | 'down'
      },
      sortFields: [{
        span: 'Charged Leads',
        value: 'current_period_charged_leads'
      }, {
        span: 'Connected Phone Calls',
        value: 'current_period_connected_phone_calls'
      }, {
        span: 'Phone Calls',
        value: 'current_period_phone_calls'
      }, {
        span: 'Total Cost',
        value: 'current_period_total_cost'
      }, {
        span: 'Name',
        value: 'name'
      }, {
        span: 'Average Rating',
        value: 'average_five_star_rating'
      }, {
        span: 'Average Budget',
        value: 'average_weekly_budget'
      }, {
        span: 'Impressions',
        value: 'impressions_last_two_days'
      }, {
        span: 'Responsiveness',
        value: 'phone_lead_responsiveness'
      }, {
        span: 'Total Review',
        value: 'total_review'
      }, {
        span: 'Lead Cost',
        value: 'leadcost'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_7_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: 'name',
        direction: 'down' as 'up' | 'down'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load function
  async function loadReport() {
    try {
      setCookie('settings', { ...cookiesSetting, google_local_service_ads: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.google_local_service_ads?.limit_rows ? cookiesSetting?.google_local_service_ads.limit_rows : reportsMaxRows } })

      // https://2022back4.artemiudintsev.com/api/google/local-services/daily/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: report, success, error } } = (await httpClientUpdate.get('/google/local-services/daily/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.google_local_service_ads?.limit_rows ? cookiesSetting?.google_local_service_ads?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          date_start: DateTime.fromJSDate(localInterface.min_date).setZone('utc').startOf("day").toJSDate(),
          date_end: DateTime.fromJSDate(localInterface.max_date).setZone('utc').minus({ days: 1 }).endOf("day").toJSDate(),
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })) as { data: HttpReport }
      if (success) {

        setReportData({
          ...report,
          interface: {
            ...report.interface,
            min_date: moment(report.interface.min_date).startOf('day').toDate(),
            max_date: moment(report.interface.max_date).endOf('day').toDate(),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, google_local_service_ads: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load on page mount
  useEffect(() => {
    loadReport()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function getLeadCost(cost: string, leads: string) {
    let sum = 0

    if (+cost !== 0 && +leads !== 0) {
      sum = +cost / +leads
    }

    return sum.toFixed(2)
  }

  return (
    <>
      {
        reportData &&
        <div className="JobsPage_List">

          { /* Reports grid */}
          <div className="reports-grid">

            <div className="cell">
              <div className="amount">${reportData.dashboard.total_cost}</div>
              <div className="legend">Total Cost</div>
            </div>

            <div className="cell">
              <div className="amount">{reportData.dashboard.total_calls}</div>
              <div className="legend">Total Calls</div>
            </div>

            <div className="cell">
              <div className="amount">{reportData.dashboard.charged_leads}</div>
              <div className="legend">Charged Leads</div>
            </div>

            <div className="cell">
              <div className="amount">${getLeadCost(reportData.dashboard.total_cost, reportData.dashboard.charged_leads)}</div>
              <div className="legend">Lead Cost</div>

            </div>
          </div>

          { /* Page header */}
          <div className="page-header">
            <h1>Google Local Service Ads</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.google_local_service_ads?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, google_local_service_ads: { ...cookiesSetting.google_local_service_ads, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData?.interface?.rows_all ? reportData.interface.rows_all : 0,
              start: reportData?.interface?.rows_start ? reportData.interface.rows_start : 0,
              end: reportData?.interface?.rows_end ? reportData.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData?.interface?.max_pages ? reportData.interface.max_pages : 0}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={<></>
            }
          />

          <div className="contents">
            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Name</span>)}

                    sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Rating</span>)}

                    sortDirection={localInterface.sort.field === 'average_five_star_rating' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'average_five_star_rating', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Reviews</span>)}

                    sortDirection={localInterface.sort.field === 'total_review' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total_review', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Weekly Budget</span>)}

                    sortDirection={localInterface.sort.field === 'average_weekly_budget' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'average_weekly_budget', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Cost</span>)}

                    sortDirection={localInterface.sort.field === 'current_period_total_cost' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'current_period_total_cost', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Total Calls</span>)}

                    sortDirection={localInterface.sort.field === 'current_period_phone_calls' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'current_period_phone_calls', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Charged Leads</span>)}

                    sortDirection={localInterface.sort.field === 'current_period_charged_leads' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'current_period_charged_leads', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <th>
                    Lead Cost
                  </th>
                </tr>
              </thead>

              {
                reportData.local_services.map(item => (
                  <tr
                    key={item.google_local_service_id}
                    style={{ cursor: 'inherit' }}
                  >
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <div
                          style={{
                            width: '10px',
                            height: '10px',
                            background: !!item.is_connect ? '#01b41f' : '#FF0000',
                            borderRadius: '50%',
                            marginRight: '10px',
                          }}
                        ></div>
                        {item.name}
                      </div>
                    </td>
                    <td> {item.last_daily_report.average_five_star_rating} </td>
                    <td> {item.last_daily_report.total_review} </td>
                    <td> {item.last_daily_report.average_weekly_budget} </td>

                    <td> ${item.current_period_total_cost} </td>
                    <td> {item.current_period_phone_calls} </td>

                    <td> {item.current_period_charged_leads} </td>
                    <td> ${getLeadCost(item.current_period_total_cost, item.current_period_charged_leads)} </td>
                  </tr>
                ))
              }
            </table>

            {/* Medum screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              {
                reportData.local_services.map(item => (
                  <tr
                    key={item.google_local_service_id}
                    style={{ cursor: 'inherit' }}
                  >
                    <td>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          Name:
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              background: !!item.is_connect ? '#01b41f' : '#FF0000',
                              borderRadius: '50%',
                              marginRight: '10px',
                              marginLeft: '5px'
                            }}
                          ></div>
                          {item.name}
                        </div>
                      </div>

                      <div>
                        Rating: {item.last_daily_report.average_five_star_rating}
                      </div>
                    </td>

                    <td>
                      <div>
                        Reviews: {item.last_daily_report.total_review}
                      </div>

                      <div>Weekly Budget: {item.last_daily_report.average_weekly_budget} </div>
                    </td>

                    <td>
                      <div>Cost: ${item.current_period_total_cost} </div>

                      <div>Total Calls: {item.current_period_phone_calls} </div>
                    </td>

                    <td>
                      <div>Charged Leads: {item.current_period_charged_leads} </div>
                      <div> Lead Cost: ${getLeadCost(item.current_period_total_cost, item.current_period_charged_leads)} </div>
                    </td>
                  </tr>
                ))
              }
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>


              {
                reportData.local_services.map(item => (
                  <div
                    key={item.google_local_service_id}
                    className="item"
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                width: '10px',
                                height: '10px',
                                background: !!item.is_connect ? '#01b41f' : '#FF0000',
                                borderRadius: '50%',
                                marginRight: '10px',
                              }}
                            ></div>
                            <strong>{item.name}</strong>
                          </div>
                        </div>

                        <div>
                          Rating: {item.last_daily_report.average_five_star_rating}
                        </div>

                        <div>
                          Reviews: {item.last_daily_report.total_review}
                        </div>

                        <div>
                          Weekly Budget:{item.last_daily_report.average_weekly_budget}
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          Cost: <b>${item.current_period_total_cost}</b>
                        </div>

                        <div>
                          Total Calls: <b>{item.current_period_phone_calls}</b>
                        </div>
                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div>
                          Charged Leads: {item.current_period_charged_leads}
                        </div>
                      </div>

                      <div className="__right small">
                        <div> Lead Cost: ${getLeadCost(item.current_period_total_cost, item.current_period_charged_leads)} </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.google_local_service_ads?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, google_local_service_ads: { ...cookiesSetting.google_local_service_ads, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData?.interface?.rows_all ? reportData.interface.rows_all : 0,
              start: reportData?.interface?.rows_start ? reportData.interface.rows_start : 0,
              end: reportData?.interface?.rows_end ? reportData.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData?.interface?.max_pages ? reportData.interface.max_pages : 0}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}
