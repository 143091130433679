import { useState } from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'

import { ChangeAbsenceProps } from './GantChart'

interface AbsenceProps {
  absence: ChangeAbsenceProps
  setAbsence: (value: ChangeAbsenceProps | null) => void
}

export default function ChangeAbsence({ absence, setAbsence }: AbsenceProps) {
  const [check, setCheck] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editing, setEditing] = useState(false)

  function getAbsenceInfo() {
    let serviceName = absence.service_resource_code ? `${absence.service_resource_nickname}(${absence.service_resource_code})` : absence.service_resource_nickname
    return `${moment(absence.absence_start, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} - ${moment(absence.absence_end, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} ${serviceName}`
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '90vh',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99999',
      }}>
      <div className='absence-change-confirmation absence-change-confirmation-popup open visible' >
        <div className='absence-change-confirmation__header'>
          <div className='absence-change-confiramtion__header-text'>
            <p> Change an Absence </p>
          </div>

          <div className='absence-change-confirmation__appointment-info'>
            <div className='absence-change-confirmation__appointment-info-item'>
              <p> {getAbsenceInfo()} </p>
            </div>
          </div>

          <div className='absence-change-confirmation__select-date-wrapper'>
            <Datetime
              dateFormat="MM-DD-YYYY"
              timeFormat="hh:mm A"
              inputProps={{ disabled: !editing }}
              value={moment(absence.absence_start, "MMMM DD, YYYY, h:mmA").format("MM-DD-YYYY hh:mm A")}
            // onChange={(value) => setAbsenceData({ ...absenceData, absence_start: moment.isMoment(value) ? value.toDate() : value })}
            />

            <Datetime
              dateFormat="MM-DD-YYYY"
              timeFormat="hh:mm A"
              inputProps={{ disabled: !editing }}
              value={moment(absence.absence_end, "MMMM DD, YYYY, h:mmA").format("MM-DD-YYYY hh:mm A")}
            // onChange={(value) => setAbsenceData({ ...absenceData, absence_start: moment.isMoment(value) ? value.toDate() : value })}
            />
          </div>
        </div>

        <div className='absence-change-confirmation__date-blocked'>
          <p className='absence-change-confirmation__date-blocked-text'>
            This absence can't be changed
          </p>
        </div>

        <div className='absence-change-confirmation__questions-wrapper'>
          <div className='absence-change-confirmation__questions'>
            <div className='absence-change-confirmation__questions-item absence-change-confirmation__questions-item_service-resource-informed'>
              <label
                className='questions-item'
                onClick={() => setCheck(!check)}
              >
                I understand that the technician must be informed of the changes
                <span className='questions-item__checkmark' >
                  {
                    check &&
                    <span className='questions-item__checkmark-active'></span>
                  }
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className='absence-change-confirmation__btns'>
          <div
            className='absence-change-confirmation__deny-btn'
            onClick={() => setAbsence(null)}
          >
            Cancel
          </div>

          <div className='absence-change-confirmation__confirm-btn btn_disabled'>
            Save
          </div>
        </div>
      </div>
    </div>
  )
}
