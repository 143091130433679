import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import { googleLogout } from '@react-oauth/google'

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import GoogleAuth from './GoogleAuth'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from '../../funcs'
import useTabActive from '../../utils/useTabActive'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },

  data: ReportData
}

interface ReportData {
  google_account: GoogleAccountProps
  permissions: {
    google_account_edit: boolean
    google_account_delete: boolean
    google_account_authorize: boolean
  }
}

interface GoogleAccountProps {
  access_token: string
  created_at: string
  created_by: string
  email: string
  is_connect: boolean
  google_account_id: string
  name: string
  updated_at: string
  updated_by: string
}

export default function Item() {
  const $router = useRoute()

  const isTabActive = useTabActive();

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)
  const [sendGoogleLogin, setSendGoogleLogin] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)

  const [readyToDelete, setReadyToDelete] = useState(false)
  const [editing, setEditing] = useState(false)

  const [data, setData] = useState<GoogleAccountProps | null>(null)
  const [dataReport, setDataReport] = useState<ReportData | null>(null)

  async function handleSave() {
    setIsSaving(true)
    try {
      if (data) {
        // https://2022back4.artemiudintsev.com/api/google/accounts/88888900ya6h31quom
        const response = await httpClientUpdate.put(`/google/accounts/${$router.router.getState().params.googleAccountsId}`,
          {
            account_id: activeAccountId,
            email: data.email,
            name: data.name,
          })
        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadInfo()
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove ticket
  async function handleRemove() {
    try {
      // https://2022back4.artemiudintsev.com/api/google/accounts/88888301bkmgqr4h3o
      const response = await httpClientUpdate.delete(`/google/accounts/${$router.router.getState().params.googleAccountsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        $router.router.navigate('google-accounts', {
          companyId: activeAccountId,
        }, { reload: true })
      }
    } catch (error) {

    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/google/accounts/88888900ya6h31quom?account_id=88888
      const { data: { data: googleData, success } } = await httpClientUpdate.get('/google/accounts/' + $router.router.getState().params.googleAccountsId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setDataReport(googleData)
        setData(googleData.google_account)

        if (googleData.google_account.is_connect) {
          setSendGoogleLogin(false)
          editing && setEditing(false)
        }

      }
      setTimeout(() => setReadyToSave(false), 100)
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sendGoogleLogin && editing && isTabActive) {
      loadInfo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive, sendGoogleLogin, editing])

  useEffect(() => setReadyToSave(true), [
    data
  ])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  function handleCancelEdit() {
    setEditing(false)
    dataReport && setData(dataReport.google_account)
  }

  async function handleLogout() {
    setIsSaving(true)
    try {
      googleLogout()

      if (data) {
        // https://2022back4.artemiudintsev.com/api/google/accounts/88888900ya6h31quom
        const response = await httpClientUpdate.put(`/google/accounts/${$router.router.getState().params.googleAccountsId}`,
          {
            account_id: activeAccountId,
            email: data.email,
            name: data.name,
            is_connect: 0,
          })
        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('google-accounts', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <>
      {
        dataReport && data &&
        <div className="TicketsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Google Account:</h1>
              <div className="item-name">
                {data.email}
              </div>
            </div>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() => $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'google-accounts',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                dataReport.permissions.google_account_edit &&
                !editing &&
                <button className="_wa _green" onClick={() => {
                  setSendGoogleLogin(false)
                  setEditing(true)
                }}>
                  Edit
                </button>
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Name:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.name}
                    onChange={(event) => setData({ ...data, name: event.target.value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Email:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.email}
                    onChange={(event) => setData({ ...data, email: event.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div
              className="legend"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Google Auth

              <span className={classNames('google_auth',
                {
                  _connected: !!data.is_connect
                }
              )}></span>
            </div>

            {
              !!data.is_connect ?
                <div className="fields">
                  <div className="__left">

                    <div className="field">
                      <span>Connect:</span>

                      <button
                        className="_bordered"
                        style={{ width: 'fit-content', border: '1px solid #d0d3da' }}
                        disabled={!editing || !dataReport.permissions.google_account_authorize}
                        onClick={() => handleLogout()}
                      >
                        Logout from Google 🚀
                      </button>
                    </div>
                  </div>


                  <div className="__right">
                  </div>
                </div> :
                <GoogleAuth
                  setSendGoogleLogin={setSendGoogleLogin}
                  disabled={!editing || !dataReport.permissions.google_account_authorize}
                  account_id={data.google_account_id}
                />
            }
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Created at:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at)}
                    onChange={(event) => setData({ ...data, created_at: event.target.value })}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.created_by}
                    onChange={(event) => setData({ ...data, created_by: event.target.value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Updated at:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.updated_at)}
                    onChange={(event) => setData({ ...data, updated_at: event.target.value })}
                  />
                </div>
                <div className="field">
                  <span>Updated by:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.updated_by}
                    onChange={(event) => setData({ ...data, updated_by: event.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  dataReport.permissions.google_account_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Google Account</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {/* Item share popup */}
          {
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Google Account
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox
                    contents="I understand that after deleting the google account it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete}
                    className="_bordered _red"
                    onClick={() => handleRemove()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}