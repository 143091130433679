export default function NextSvg() {
  return (
    <svg viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="53" height="23" rx="11.5" stroke="#6093DE"></rect>
      <path
        fillRule="evenodd" clipRule="evenodd" fill="#6093DE"
        d="M34.5239 11.8999L27.5835 6V8.4316H20V15.3682H27.5835V17.7997L34.5239 11.8999Z"
      ></path>
    </svg>
  )
}
// transform: translateY(0.1rem);