import { useEffect, useState } from "react";
import moment from "moment-timezone"
import momentmoment from 'moment';
import classNames from "classnames"

import Icon from "./Icon"

import { useAppSelector } from "../store/hooks"

import "../styles/components/date-range-calendar.sass"

interface Props {

  startDate?: Date,
  endDate?: Date,

  onStartDateUpdate?(date: Date): void,
  onEndDateUpdate?(date: Date): void,

  debug?: any
}

function DateRangeCalendar({
  startDate,
  endDate,
  onStartDateUpdate,
  onEndDateUpdate,
  debug,
}: Props) {
  let user = useAppSelector((store) => store.user)
  let timeZone = user?.time_zone;

  const [activeDate, setActiveDate] = useState(moment(startDate).utc().startOf('day').toDate())
  const [startDateState, setStartDateState] = useState<Date | null>(startDate || null)
  const [endDateState, setEndDateState] = useState<Date | null>(endDate || null)

  useEffect(() => {
    if (timeZone) {
      setActiveDate(moment.tz(momentmoment(startDate), timeZone).startOf('day').toDate())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone])

  // onDayClick function
  function onDayClick(day: number) {
    if (!startDateState || (startDateState && endDateState)) {
      const date = moment(activeDate).date(day).toDate()

      onStartDateUpdate && onStartDateUpdate(date)

      setEndDateState(null)

      return
    }

    if (!endDateState) {
      const date = moment.tz(momentmoment(activeDate), timeZone as string).endOf('day').date(day).toDate()

      if (moment(startDateState).diff(moment(date, 'days')) > 0) {
        const date_start = moment(activeDate).date(day).toDate()

        onStartDateUpdate && onStartDateUpdate(date_start)

        setEndDateState(null)
      } else {

        onEndDateUpdate && onEndDateUpdate(date)
      }

      return
    }
  }

  // Add or take a month from activeDate function
  function updateActiveMonth(x: number) {

    setActiveDate(moment(activeDate).add(x, 'M').toDate())
  }

  // Get start date from props
  useEffect(() => {
    setStartDateState(startDate || null)
  }, [startDate])

  useEffect(() => {
    setEndDateState(endDate || null)
  }, [endDate])

  // Render function
  return (
    <div className="DateRangeCalendar">

      <div className="header">

        <button className="_zeroed _iconed" onClick={() => updateActiveMonth(-1)}>
          <Icon className="_rotated-180" icon="arrow-25" />
        </button>

        <div className="current-month">
          {moment(activeDate).format('MMMM YYYY')}
        </div>

        <button className="_zeroed _iconed" onClick={() => updateActiveMonth(1)}>
          <Icon icon="arrow-25" />
        </button>
      </div>

      <div className="calendar">

        <div className="days-grid week-days">

          <div className="day">S</div>
          <div className="day">M</div>
          <div className="day">T</div>
          <div className="day">W</div>
          <div className="day">T</div>
          <div className="day">F</div>
          <div className="day">S</div>
        </div>

        <div className="days-grid calendar-days">

          {[...Array(moment(activeDate).startOf('month').day())].map((day, i) => (
            <div className="day" key={`${i}`}></div>
          ))}


          {[...Array(moment(activeDate).daysInMonth())].map((day, i) => (
            <div
              className={classNames('day', {
                _selected: (
                  (i + 1 === moment.tz(momentmoment(startDateState), timeZone as string).date() &&
                    activeDate.getMonth() === moment.tz(momentmoment(startDateState), timeZone as string).month() &&
                    activeDate.getFullYear() === moment.tz(momentmoment(startDateState), timeZone as string).year()) ||
                  (i + 1 === moment.tz(momentmoment(endDateState), timeZone as string).date() &&
                    activeDate.getMonth() === moment.tz(momentmoment(endDateState), timeZone as string).month() &&
                    activeDate.getFullYear() === moment.tz(momentmoment(endDateState), timeZone as string).year())
                ),
                _inRange: (
                  startDateState && endDateState &&
                  moment.tz(momentmoment(activeDate), timeZone as string).date(i + 1).isAfter(moment.tz(momentmoment(startDateState), timeZone as string)) &&
                  moment.tz(momentmoment(activeDate), timeZone as string).date(i + 1).isBefore(moment.tz(momentmoment(endDateState), timeZone as string))
                ),
                _rangeStart: moment.tz(momentmoment(activeDate), timeZone as string).date(i + 1).isSame(moment.tz(momentmoment(startDateState), timeZone as string)),
                _rangeEnd: moment.tz(momentmoment(activeDate), timeZone as string).endOf('day').date(i + 1).isSame(moment.tz(momentmoment(endDateState), timeZone as string))
              })}
              key={i}
              onClick={() => onDayClick(i + 1)}
            >{i + 1}</div>
          ))}
        </div>
      </div>

      {debug ? (<>
        <div>{moment(startDateState).format()}</div>
        <div>{moment(activeDate).format()}</div>
        <div>{moment(endDateState).format()}</div>
        <div>{String(moment(startDateState).format() === moment(activeDate).format())}</div>
      </>) : null}
    </div>
  )
}

export default DateRangeCalendar
