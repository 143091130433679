import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'

import Icon from '../../components/Icon'

import { useAppSelector } from '../../store/hooks'
import { httpClientUpdate, nErrorUpdate, useOuterClick } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    pages: PagesProps[]
  }
}

interface PagesProps {
  active: boolean
  city: string
  page_id: string
  state: string
  title: string
  type: string
  url: string
}

export default function Pages() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [data, setData] = useState<PagesProps[]>([])

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/websites/pages/report
      const { data: { data: response, success, error } } = (await httpClientUpdate.get(`/websites/pages/report`, {
        params: {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
          limit_rows: 500,
          page: 1
        }
      })) as { data: HttpReport }
      if (success) {
        setData(response.pages)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      <div className="ListsPage_Appliances">
        <div className="top-nav top-nav-list">
          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.main"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.main" })}
            >
              <Icon icon="task-1" />
              <span>Main</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.settings"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.settings" })}
            >
              <Icon icon="task-1" />
              <span>Settings</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.pages"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.pages" })}
            >
              <Icon icon="task-1" />
              <span>Pages</span>
            </BaseLink>
          </div>

          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.phone_codes"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "phone_codes" })}
            >
              <Icon icon="task-1" />
              <span>Phone Codes</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.phones"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.phones" })}
            >
              <Icon icon="task-1" />
              <span>Phones</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.links"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.links" })}
            >
              <Icon icon="task-1" />
              <span>Links</span>
            </BaseLink>
          </div>
        </div>
      </div>

      <div className="AccountsPage_Item entity-edit">
        <div className="wrapper flex-container sb">
          <div className="flex-container _gap-narrow">
            <h1>Website (Pages):</h1>

            <div className="item-name">
              {$router.router.getState().params.domain}
            </div>
          </div>

          <div style={{ display: 'flex' }} >
            <button
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'websites',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              <button
                className="_wa _green"
                onClick={() => $router.router.navigate('websites.new_page', {
                  companyId: activeAccountId,
                  websiteId: $router.router.getState().params.websiteId,
                }, { reload: true })}
              >
                Add Page
              </button>
            }
          </div>
        </div>
      </div>

      <div className="CallsPage_List">
        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th>Type</th>
                <th>State</th>
                <th>City</th>
                <th>URL</th>
                <th>Active</th>
              </tr>
            </thead>

            {
              data.map((page, i) => (
                <tr
                  key={page.page_id}
                  style={{
                    cursor: page.page_id ? 'pointer' : 'inherit',
                    position: 'relative'
                  }}
                  onClick={() =>
                    page.page_id &&
                    $router.router.navigate('websites.item_page', {
                      companyId: activeAccountId,
                      websiteId: $router.router.getState().params.websiteId,
                      pageId: page.page_id,
                    }, { reload: true })}
                  onContextMenu={(event) => {
                    page.page_id &&
                      handleChangeMenu(event, page.page_id, 'pages', i)
                  }}
                >
                  <td> {page.type} </td>
                  <td> {page.state} </td>
                  <td> {page.city} </td>
                  <td> {page.url} </td>
                  <td>
                    {page.active ? 'Yes' : 'No'}

                    {
                      showContext === `pages_${page.page_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('websites.item_page', {
                              companyId: activeAccountId,
                              websiteId: $router.router.getState().params.websiteId,
                              pageId: page.page_id,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/websites/${$router.router.getState().params.websiteId}/item_page/${page.page_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))
            }
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {data.map((page, i) => (
              <tr
                key={page.page_id}
                style={{
                  position: 'relative',
                  cursor: page.page_id ? 'pointer' : 'inherit'
                }}
                onClick={() =>
                  page.page_id &&
                  $router.router.navigate('websites.item_page', {
                    companyId: activeAccountId,
                    websiteId: $router.router.getState().params.websiteId,
                    pageId: page.page_id,
                  }, { reload: true })}
                onContextMenu={(event) => {
                  page.page_id &&
                    handleChangeMenu(event, page.page_id, 'pages', i)
                }}
              >
                <td> {page.type} </td>
                <td> {page.state} </td>
                <td> {page.city} </td>
                <td> {page.url} </td>
                <td>
                  {page.active ? 'Yes' : 'No'}

                  {
                    showContext === `pages_${page.page_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('websites.item_page', {
                            companyId: activeAccountId,
                            websiteId: $router.router.getState().params.websiteId,
                            pageId: page.page_id,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/websites/${$router.router.getState().params.websiteId}/item_page/${page.page_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {data.map((page) => (
              <div
                className="item"
                key={page.page_id}
                onClick={() =>
                  page.page_id &&
                  $router.router.navigate('websites.item_page', {
                    companyId: activeAccountId,
                    websiteId: $router.router.getState().params.websiteId,
                    pageId: page.page_id,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div>
                      <span className="gray">Type: </span>
                      <span> {page.type}</span>
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      <span className="gray">State: </span>
                      <span> {page.state}</span>
                    </div>

                    <div>
                      <span className="gray">City: </span>
                      <span> {page.city}</span>
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <span className="gray">Url: </span>
                    <span> {page.url}</span>
                  </div>

                  <div className="__right small">
                    <span className="gray">Active: </span>
                    <span> {page.active ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
