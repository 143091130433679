import { useEffect, useState } from 'react'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import PaymentFields from './PaymentFields'
import SearchInvoices, { InvoiceAddProps } from './SearchInvoices'
import Icon from '../../components/Icon'

import { NavActive } from '../../store/reducer'
import { useAppSelector } from '../../store/hooks'
import { formatPhoneNumberToServerString, httpClientUpdate, validateEmail } from '../../funcs'

import { InvoicesProps, PaymentServicesProps } from './ItemTech'

interface SaveReport {
  data: {
    email_notification_details: {
      notify_subject: string
      notify_text: string
    }
    message: string
    payment_id: string
    phone_notification_details: {
      notify_text: string
    }
  }
  success: boolean,
  error: {
    code: number,
    message: string
  },
}

interface PaymentCardProps {
  navActive: NavActive
  phoneCall: boolean
  setPaymentPage: (value: string) => void
  invoices: InvoicesProps[]
  permission_search_show: boolean
  job_name: string
  payment_square_make: boolean
  payment_stripe_make: boolean
  job_id: string
  payment_services: PaymentServicesProps[]
  handleCloseCardModal: () => void
  company_id: string
  contact_id: string
  paid_job: number
  service_resource_id: string
}

interface DataProps {
  is_add_invoice: boolean
  job_id?: string
  invoice_id?: string
  contact_id?: string
  name: string
  note: string
  id: string | null
  total: number
  paid: number
  amount: number
  is_check: boolean
}

export interface SaveDataProps {
  isSendCheck: boolean
  message: string
  email_notification_details: {
    notify_subject: string
    notify_text: string
  },
  phone_notification_details: {
    notify_text: string
  },
  payment_id: string
  email: string
  is_repeated_email: boolean
  is_repeated_phone: boolean
  phone: string
  isErrorSendEmail: boolean
  isErrorSendPhone: boolean
  message_email: string
  message_phone: string
  isSendEmail: boolean
  isSendPhone: boolean
}

export default function PaymentCard({
  navActive,
  phoneCall,
  setPaymentPage,
  invoices,
  permission_search_show,
  job_name,
  payment_square_make,
  payment_stripe_make,
  job_id,
  payment_services,
  handleCloseCardModal,
  company_id,
  contact_id,
  paid_job,
  service_resource_id,
}: PaymentCardProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState<DataProps[]>([])
  const [addData, setAddData] = useState<DataProps[]>([])
  const [isJobCheck, setIsJobCheck] = useState({
    is_check: false,
    note: 'Deposit',
    amount: 0,
  })

  const [showSearchInvoices, setShowSearchInvoices] = useState(false)

  const [withFee, setWithFee] = useState(true)
  const [feeCompensation, setFeeCompensation] = useState(0)
  const [permissionsFeeCompensation, setPermissionsFeeCompensation] = useState(false)

  const [showEmailDetails, setShowEmailDetails] = useState(false)
  const [showPhoneDetails, setShowPhoneDetails] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState('')

  const [sending, setSending] = useState({
    phone: false,
    email: false
  })

  const [saveData, setSaveData] = useState({
    isSendCheck: false,
    message: '',
    email_notification_details: {
      notify_subject: '',
      notify_text: '',
    },
    phone_notification_details: {
      notify_text: ''
    },
    payment_id: '',
    email: '',
    is_repeated_email: false,
    is_repeated_phone: false,
    phone: '',
    isErrorSendEmail: false,
    isErrorSendPhone: false,
    message_email: '',
    message_phone: '',
    isSendEmail: false,
    isSendPhone: false,
  })

  async function handleSendEmail() {
    setSending({
      ...sending,
      email: true
    })
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${saveData.payment_id}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: saveData.email,
        type: 'email',
        is_repeated: saveData.is_repeated_email ? 1 : null
      }, { skipNulls: true })) as { data: SaveReport }

      if (success) {
        setSending({
          ...sending,
          email: false
        })
        setSaveData({
          ...saveData,
          isErrorSendEmail: false,
          message_email: 'Success send',
          isSendEmail: true,
        })
      } else {
        setSending({
          ...sending,
          email: false
        })
        setSaveData({
          ...saveData,
          isErrorSendEmail: true,
          message_email: 'The email has not been sent, try again later or change the email',
          isSendEmail: true,
        })
      }
    } catch (error) {
      setSending({
        ...sending,
        email: false
      })
      setSaveData({
        ...saveData,
        isErrorSendEmail: true,
        message_email: 'The email has not been sent, try again later or change the email',
        isSendEmail: true,
      })
    }
  }

  async function handleSendPhone() {
    setSending({
      ...sending,
      phone: true
    })
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${saveData.payment_id}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: formatPhoneNumberToServerString(saveData.phone),
        type: 'phone',
        is_repeated: saveData.is_repeated_phone ? 1 : null
      }, { skipNulls: true })) as { data: SaveReport }

      if (success) {
        setSending({
          ...sending,
          phone: false
        })
        setSaveData({
          ...saveData,
          isErrorSendPhone: false,
          message_phone: 'Success send',
          isSendPhone: true,
        })
      } else {
        setSending({
          ...sending,
          phone: false
        })
        setSaveData({
          ...saveData,
          isErrorSendPhone: true,
          message_phone: 'The receipt has not been sent, try again later or check the phone number',
          isSendPhone: true,
        })
      }
    } catch (error) {
      setSending({
        ...sending,
        phone: false
      })
      setSaveData({
        ...saveData,
        isErrorSendPhone: true,
        message_phone: 'The receipt has not been sent, try again later or check the phone number',
        isSendPhone: true,
      })
    }
  }

  useEffect(() => {
    if (payment_square_make && payment_stripe_make) {
      setPaymentMethod('stripe')
    } else if (!payment_square_make && payment_stripe_make) {
      setPaymentMethod('stripe')
    } else if (payment_square_make && !payment_stripe_make) {
      setPaymentMethod('square')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (invoices.length) {
      let updated = invoices.map(item => {
        let total_string = item.invoice_total?.replaceAll('$', '')
        let total = total_string ? Number(total_string) : 0

        let paid = item.invoice_paid ? Number(item.invoice_paid) : 0

        return {
          name: item.name,
          note: 'Deposit',
          id: item.invoice_id,
          total: total,
          paid: paid,
          amount: 0,
          is_check: false,
          is_add_invoice: false,
        }
      })

      setData(updated)
    }
  }, [invoices])

  function handleCheckedInvoice(value: boolean, index: number, is_add_invoice?: boolean) {
    setData(data.map((item, idx) => {
      if (idx === index) {
        let total = item.total

        let paid = item.paid

        let amount = 0

        if (value) {
          if (is_add_invoice) {
            amount = 0
          } else {
            amount = total - paid
          }
        }

        return {
          ...item,
          is_check: value,
          amount,
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleCheckedAddInvoice(value: boolean, index: number) {
    setAddData(addData.map((item, idx) => {
      if (idx === index) {
        let amount = 0

        if (value) {
          amount = 0
        }

        return {
          ...item,
          is_check: value,
          amount,
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeNote(value: string, index: number, is_add_data?: boolean) {
    if (is_add_data) {
      setAddData(addData.map((item, idx) => {
        if (idx === index) {
          return { ...item, note: value }
        } else {
          return { ...item }
        }
      }))
    } else {
      setData(data.map((item, idx) => {
        if (idx === index) {
          return { ...item, note: value }
        } else {
          return { ...item }
        }
      }))
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangeItem(event: any, index: number, is_add_invoice: boolean, is_add_data?: boolean) {
    let price = event.target.value
    if (is_add_data) {
      let numberWithoutDot = price.split('.')

      if (Number(price) >= 0) {
        if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
          let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          let newNumber = price

          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: newNumber }
            } else {
              return { ...item }
            }
          }))
        }
      }
    } else {
      let item = data[index]

      let total = item.total

      let paid = item.paid

      let max_price = total - paid

      let numberWithoutDot = price.split('.')

      if (Number(price) >= 0 && Number(price) <= max_price) {
        if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
          let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: max_price < Number(newNumber) ? max_price : Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          let newNumber = price

          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: newNumber }
            } else {
              return { ...item }
            }
          }))
        }
      }
    }
  }

  function handleChangeItemBackspace(event: any, index: number, is_add_data?: boolean) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (is_add_data) {
          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        }

      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        if (is_add_data) {
          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        }
      }
    }
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      } else {
        let newNumber = price

        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      }
    }
  }

  function handleJobChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      }
    }
  }

  function getPayByCheck() {
    let pay = 0

    data.forEach(item => {
      if (item.is_check) {
        pay = pay + Number(item.amount)
      }
    })

    addData.forEach(item => {
      if (item.is_check) {
        pay = pay + Number(item.amount)
      }
    })

    if (isJobCheck.is_check) {
      pay = pay + Number(isJobCheck.amount)
    }

    return pay
  }

  function totalIncludeFee() {
    let sum = getPayByCheck()
    let total = 0
    let compensation = 0

    if (withFee) {
      compensation = feeCompensation
    }

    if (sum > 0) {
      total = ((sum / 100) * compensation)
    }

    sum = sum + total

    return sum.toFixed(2)
  }

  function getFee() {
    let sum = getPayByCheck()
    let total = 0
    let compensation = 0

    if (withFee) {
      compensation = feeCompensation
    }

    if (sum > 0) {
      total = ((sum / 100) * compensation)
    }

    return total.toFixed(2)
  }

  function handleCloseSendInfo() {
    setSaveData({
      isSendCheck: false,
      message: '',
      email_notification_details: {
        notify_subject: '',
        notify_text: '',
      },
      phone_notification_details: {
        notify_text: ''
      },
      payment_id: '',
      is_repeated_email: false,
      is_repeated_phone: false,
      email: '',
      phone: '',
      isErrorSendEmail: false,
      isErrorSendPhone: false,
      message_email: '',
      message_phone: '',
      isSendEmail: false,
      isSendPhone: false,
    })
    setPaymentMethod('')
    setShowEmailDetails(false)
    setShowPhoneDetails(false)
    handleCloseCardModal()
  }

  function handleAddInvoice(data_invoice: InvoiceAddProps[]) {
    let invoices = data_invoice.map(item => {
      let total = item.total ? Number(item.total.replace('$', '')) : 0
      return {
        job_id: item.job_id,
        invoice_id: item.invoice_id,
        contact_id: item.contact_id,
        note: '',
        id: null,
        amount: 0,
        name: item.name,
        total: total,
        paid: Number(item.paid),
        is_check: false,
        is_add_invoice: true,
      }
    })

    setAddData(addData.concat(invoices))

    setShowSearchInvoices(false)
  }

  function getInvoicesIds() {
    let ids: string[] = []

    data.forEach(item => {
      item.id && ids.push(item.id)
    })

    addData.forEach(item => {
      item.invoice_id && ids.push(item.invoice_id)
    })

    return ids
  }

  function getJobsIds() {
    let ids: string[] = [job_id]

    addData.forEach(item => {
      !item.invoice_id && item.job_id && ids.push(item.job_id)
    })

    return ids
  }

  return (
    <>
      {
        showSearchInvoices ?
          <div
            className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}
          >
            <SearchInvoices
              handleClose={() => setShowSearchInvoices(false)}
              contact_id={contact_id}
              handleAddInvoice={handleAddInvoice}
              invoice_ids={getInvoicesIds()}
              job_ids={getJobsIds()}
            />
          </div> :
          <div
            className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}
          >
            {
              saveData.isSendCheck ?
                <>
                  <div className='send-check'>
                    {
                      <div className='send-check-content'>
                        <p style={{ fontWeight: '700', fontSize: '18px' }}>
                          Payment was successful. {saveData.email_notification_details.notify_text || saveData.phone_notification_details.notify_text ? 'Send a receipt?' : ''}
                        </p>

                        {
                          saveData.email_notification_details.notify_text &&
                          <button
                            className={classNames('_bordered', '_blue', {
                              __show: showEmailDetails
                            })}
                            style={{ width: 'fit-content' }}
                            onClick={() => setShowEmailDetails(!showEmailDetails)}
                          >
                            Send by Email
                          </button>
                        }

                        {
                          showEmailDetails &&
                          <>
                            {
                              saveData.isErrorSendEmail && saveData.isErrorSendEmail &&
                              <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                                {saveData.message_email}
                              </p>
                            }

                            {
                              saveData.isSendEmail && !saveData.isErrorSendEmail &&
                              <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {saveData.message_email} </p>
                            }

                            <div className='email-label'>
                              <span>Email: </span>
                              <input
                                type="text"
                                value={saveData.email}
                                onChange={(event) => setSaveData({ ...saveData, email: event.target.value })}
                              />
                            </div>

                            <div className='email-label'>
                              <span> Subject: </span>

                              <span className='span-input'>
                                {saveData.email_notification_details.notify_subject}
                              </span>
                            </div>

                            <div className='email-label'>
                              <div
                                className='text-block'
                              >
                                <div dangerouslySetInnerHTML={{ __html: saveData.email_notification_details.notify_text ? saveData.email_notification_details.notify_text : '' }}></div>
                              </div>
                            </div>

                            <div className='btns'>
                              <div
                                className={classNames({
                                  __is_repeated: !saveData.isSendEmail
                                })}
                              >
                                <Checkbox
                                  contents='Resend?'
                                  disabled={!saveData.isSendEmail}
                                  value={saveData.is_repeated_email}
                                  onChange={(value) => setSaveData({ ...saveData, is_repeated_email: value })}
                                />
                              </div>

                              <button
                                className="_bordered _green"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={sending.email || saveData.email === '' || !validateEmail(saveData.email) || (saveData.isSendEmail && !saveData.is_repeated_email)}
                                onClick={handleSendEmail}
                              >
                                Send
                              </button>
                            </div>
                          </>
                        }

                        {
                          saveData.phone_notification_details.notify_text &&
                          <button
                            style={{ marginTop: '10px', width: 'fit-content' }}
                            className={classNames('_bordered', '_blue', {
                              __show: showPhoneDetails
                            })}
                            onClick={() => setShowPhoneDetails(!showPhoneDetails)}
                          >
                            Send by SMS
                          </button>
                        }

                        {
                          showPhoneDetails &&
                          <>
                            {
                              saveData.isSendPhone && saveData.isErrorSendPhone &&
                              <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                                {saveData.message_phone}
                              </p>
                            }

                            {
                              saveData.isSendPhone && !saveData.isErrorSendPhone &&
                              <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {saveData.message_phone} </p>
                            }

                            <div className='email-label'>
                              <span>Phone: </span>

                              <ReactInputMask
                                type="text"
                                mask="+1 (999) 999-9999"
                                value={saveData.phone}
                                onChange={({ target: { value } }) => setSaveData({ ...saveData, phone: value })}
                              />
                            </div>

                            <div className='email-label'>
                              <div
                                className='text-block'
                              >
                                <div dangerouslySetInnerHTML={{ __html: saveData.phone_notification_details.notify_text ? saveData.phone_notification_details.notify_text : '' }}></div>
                              </div>
                            </div>

                            <div className='btns'>
                              <div
                                className={classNames({
                                  __is_repeated: !saveData.isSendPhone
                                })}
                              >
                                <Checkbox
                                  contents='Resend?'
                                  disabled={!saveData.isSendPhone}
                                  value={saveData.is_repeated_phone}
                                  onChange={(value) => setSaveData({ ...saveData, is_repeated_phone: value })}
                                />
                              </div>

                              <button
                                className="_bordered _green"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={sending.phone || formatPhoneNumberToServerString(saveData.phone).length !== 12 || (saveData.isSendPhone && !saveData.is_repeated_phone)}
                                onClick={handleSendPhone}
                              >
                                Send
                              </button>
                            </div>
                          </>
                        }

                        <div className='btns'>
                          <button
                            className="_bordered _red"
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={handleCloseSendInfo}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </> :
                <>
                  <div className='content-title'>
                    <div
                      className='content-title-row'
                      style={{ alignItems: 'center' }}
                    >
                      <div></div>

                      <div className='mobile-text'>
                        Add Cash Payment
                      </div>

                      <div></div>
                    </div>
                  </div>

                  <div className='payment-title'>
                    <Icon style={{ width: '20px' }} viewBox="0 0 78 85" icon="group-23" />

                    Invoices
                  </div>

                  <div className='payment-invoices-items'>
                    {
                      data.map((item, index) => (
                        <div
                          key={index}
                          className="payment-invoices-item"
                        >
                          <div className='payment-invoices-item-row'>
                            <div className='invoice-info'>
                              <div className='invoice-info-title'>
                                <span title={item.name}> {item.name} </span>

                                {
                                  item.total > 0 && item.total - item.paid === Number(item.amount) ?
                                    <div
                                      className='check-mark'
                                      onClick={() => handleCheckedInvoice(false, index)}
                                    >
                                      <Icon viewBox="0 0 60 60" icon="check-job-tech" />
                                    </div> :
                                    <Checkbox
                                      contents=""
                                      value={item.is_check}
                                      onChange={(value) => handleCheckedInvoice(value, index, item.is_add_invoice)}
                                    />
                                }
                              </div>

                              <div className='invoice-info-row'>
                                <div className='invoice-info-total'>
                                  Total: ${item.total.toFixed(2)}
                                </div>

                                <div className='invoice-info-paid'>
                                  Paid: ${item.paid.toFixed(2)}
                                </div>
                              </div>
                            </div>

                            <div className='invoice-arrow'>
                              <button>
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            </div>
                          </div>

                          {
                            item.is_check &&
                            <div className='payment-invoices-item-row'>
                              <div className='invoice-data'>
                                <div className='invoice-data-row'>
                                  <div className='title'>Note:</div>

                                  <div className='input'>
                                    <input
                                      type="text"
                                      value={item.note}
                                      onChange={(event) => handleChangeNote(event.target.value, index)}
                                    />
                                  </div>
                                </div>

                                <div className='invoice-data-row'>
                                  <div className='title'>Amount:</div>

                                  <div className='input'>
                                    <input
                                      className="appearance"
                                      type='number'
                                      value={`${Number(item.amount).toFixed(2)}`}
                                      step={0.01}
                                      onFocus={(event) => handleFocus(event)}
                                      onChange={(event) => handleChangeItem(event, index, item.is_add_invoice)}
                                      onKeyDown={(event) => handleChangeItemBackspace(event, index)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                    }

                    <div className="payment-invoices-item">
                      <div className='payment-invoices-item-row'>
                        <div className='invoice-info'>
                          <div className='invoice-info-title'>
                            <span title={job_name}> {job_name} </span>

                            <Checkbox
                              contents=""
                              value={isJobCheck.is_check}
                              onChange={() => setIsJobCheck({ ...isJobCheck, is_check: !isJobCheck.is_check })}
                            />
                          </div>

                          <div className='invoice-info-row'>
                            <div className='invoice-info-total'>
                            </div>

                            <div className='invoice-info-paid'>
                              Paid: ${paid_job.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>

                      {
                        isJobCheck.is_check &&
                        <div className='payment-invoices-item-row'>
                          <div className='invoice-data'>
                            <div className='invoice-data-row'>
                              <div className='title'>Note:</div>

                              <div className='input'>
                                <input
                                  type="text"
                                  value={isJobCheck.note}
                                  onChange={(event) => setIsJobCheck({ ...isJobCheck, note: event.target.value })}
                                />
                              </div>
                            </div>

                            <div className='invoice-data-row'>
                              <div className='title'>Amount:</div>

                              <div className='input'>
                                <input
                                  className="appearance"
                                  type='number'
                                  value={`${Number(isJobCheck.amount).toFixed(2)}`}
                                  step={0.01}
                                  onFocus={(event) => handleJobFocus(event)}
                                  onChange={(event) => handleJobChangeItem(event)}
                                  onKeyDown={(event) => handleJobChangeItemBackspace(event)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                    {
                      addData.map((item, index) => (
                        <div
                          key={index}
                          className="payment-invoices-item"
                        >
                          <div className='payment-invoices-item-row'>
                            <div className='invoice-info'>
                              <div className='invoice-info-title'>
                                <span title={item.name}> {item.name} </span>

                                {
                                  item.invoice_id ?
                                    item.total > 0 && item.total - item.paid === Number(item.amount) ?
                                      <div
                                        className='check-mark'
                                        onClick={() => handleCheckedAddInvoice(false, index)}
                                      >
                                        <Icon viewBox="0 0 60 60" icon="check-job-tech" />
                                      </div> :
                                      <Checkbox
                                        contents=""
                                        value={item.is_check}
                                        onChange={(value) => handleCheckedAddInvoice(value, index)}
                                      /> :
                                    <Checkbox
                                      contents=""
                                      value={item.is_check}
                                      onChange={(value) => handleCheckedAddInvoice(value, index)}
                                    />
                                }
                              </div>

                              <div className='invoice-info-row'>
                                {
                                  item.invoice_id ?
                                    <div className='invoice-info-total'>
                                      Total: ${item.total.toFixed(2)}
                                    </div> :
                                    <div className='invoice-info-total'></div>
                                }

                                <div className='invoice-info-paid'>
                                  Paid: ${item.paid.toFixed(2)}
                                </div>
                              </div>
                            </div>

                            <div className='invoice-arrow'>
                              {
                                item.invoice_id &&
                                <button>
                                  <Icon viewBox="0 0 24 24" icon="arrow-25" />
                                </button>
                              }
                            </div>
                          </div>

                          {
                            item.is_check &&
                            <div className='payment-invoices-item-row'>
                              <div className='invoice-data'>
                                <div className='invoice-data-row'>
                                  <div className='title'>Note:</div>

                                  <div className='input'>
                                    <input
                                      type="text"
                                      value={item.note}
                                      onChange={(event) => handleChangeNote(event.target.value, index, true)}
                                    />
                                  </div>
                                </div>

                                <div className='invoice-data-row'>
                                  <div className='title'>Amount:</div>

                                  <div className='input'>
                                    <input
                                      className="appearance"
                                      type='number'
                                      value={`${Number(item.amount).toFixed(2)}`}
                                      step={0.01}
                                      onFocus={(event) => handleFocus(event)}
                                      onChange={(event) => handleChangeItem(event, index, item.is_add_invoice, true)}
                                      onKeyDown={(event) => handleChangeItemBackspace(event, index, true)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                    }
                  </div>

                  {
                    permission_search_show &&
                    <div
                      className='add-invoice'
                      onClick={() => setShowSearchInvoices(true)}
                    >
                      Add invoices from another job

                      <button>
                        <Icon viewBox="0 0 24 24" icon="arrow-25" />
                      </button>
                    </div>
                  }

                  <div className='empty'></div>

                  <div className='payment-card-items'>
                    <div className='payment-card-info'>
                      Payment: <span className='sum'>${getPayByCheck().toFixed(2)}</span>
                    </div>

                    <div className='payment-card-info'>
                      <Checkbox
                        contents=""
                        value={withFee}
                        onChange={() => setWithFee(!withFee)}
                        disabled={!permissionsFeeCompensation}
                      />
                      Processing Fee ({feeCompensation}%): <span className='sum'>${getFee()}</span>
                    </div>

                    <div className='payment-card-input'>
                      Pay by Card (+Fee): <span className='sum'>${totalIncludeFee()}</span>
                    </div>
                  </div>

                  {
                    payment_square_make && payment_stripe_make &&
                    <div className='payment-tabs'>
                      <div
                        className={classNames('payment-tab',
                          {
                            __active: paymentMethod === 'square',
                          }
                        )}
                        onClick={() => setPaymentMethod('square')}
                      >
                        Square
                      </div>

                      <div
                        className={classNames('payment-tab',
                          {
                            __active: paymentMethod === 'stripe',
                          }
                        )}
                        onClick={() => setPaymentMethod('stripe')}
                      >
                        Stripe
                      </div>
                    </div>
                  }

                  <PaymentFields
                    method={paymentMethod}
                    setFeeCompensation={setFeeCompensation}
                    setPermissionsFeeCompensation={setPermissionsFeeCompensation}
                    setPaymentPage={setPaymentPage}
                    sum={Number(totalIncludeFee())}
                    feeCompensation={getFee()}
                    data={data.filter(item => item.is_check).map(item => {
                      return { ...item }
                    }).concat(addData.filter(item => item.is_check))}
                    jobData={isJobCheck.is_check ? isJobCheck : null}
                    job_id={job_id}
                    payment_services={payment_services}
                    setSaveData={setSaveData}
                    company_id={company_id}
                    service_resource_id={service_resource_id}
                  />
                </>
            }
          </div>
      }
    </>
  )
}
