import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { BaseLink, useRoute } from 'react-router5'

import Icon from '../../components/Icon'
import Checkbox from '../Tools/Checkbox'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    website: DataProps
  }
}

interface DataProps {
  created_by: string
  db_host: string
  db_name: string
  db_user_name: string
  db_user_password: string
  domain: string
  template: string
  token: string
  updated_by: string
  website_id: string
  status: string
}

export default function Main() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState<DataProps | null>(null)
  const [report, setReport] = useState<DataProps | null>(null)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    setReadyToSave(true)
  }, [data])

  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/websites/8888890356cg0l3aqm?account_id=88888
      const { data: { data: response, success, error } } = (await httpClientUpdate.get(`/websites/${$router.router.getState().params.websiteId}`, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpReport }
      if (success) {
        setData(response.website)
        setReport(response.website)

        setTimeout(() => setReadyToSave(false), 100)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    try {
      if (data) {
        setIsSaving(true)
        // https://2022back4.artemiudintsev.com/api/websites/8888890356cg0l3aqm
        const response = await httpClientUpdate.put(`/websites/${$router.router.getState().params.websiteId}`, {
          account_id: activeAccountId,
          domain: data.domain,
          template: data.template,
          token: data.token,
          db_host: data.db_host,
          db_name: data.db_name,
          db_user_name: data.db_user_name,
          db_user_password: data.db_user_password,
        })

        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadData()
        } else {
          setIsSaving(false)
        }
      }

    } catch (error) {
      setIsSaving(false)
    }
  }

  async function handleRemove() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites/8888890356cg0l3aqm
      const response = await httpClientUpdate.delete(`/websites/${$router.router.getState().params.websiteId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('websites', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function handleInstall() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites/888888001111111111/install?account_id=88888
      const response = await httpClientUpdate.get(`/websites/${$router.router.getState().params.websiteId}/install`, {
        params: {
          account_id: activeAccountId
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        loadData()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleCancelEdit() {
    if (report) {
      setData({ ...report })
      setEditing(false)
    }
  }

  return (
    <>
      <div className="ListsPage_Appliances">
        <div className="top-nav top-nav-list">
          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.main"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.main" })}
            >
              <Icon icon="task-1" />
              <span>Main</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.settings"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: data ? data.domain : '' }}
              className={classNames({ _active: $router.route.name === "websites.settings" })}
            >
              <Icon icon="task-1" />
              <span>Settings</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.pages"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: data ? data.domain : '' }}
              className={classNames({ _active: $router.route.name === "websites.pages" })}
            >
              <Icon icon="task-1" />
              <span>Pages</span>
            </BaseLink>
          </div>

          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.phone_codes"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: data ? data.domain : '' }}
              className={classNames({ _active: $router.route.name === "phone_codes" })}
            >
              <Icon icon="task-1" />
              <span>Phone Codes</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.phones"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: data ? data.domain : '' }}
              className={classNames({ _active: $router.route.name === "websites.phones" })}
            >
              <Icon icon="task-1" />
              <span>Phones</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.links"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: data ? data.domain : '' }}
              className={classNames({ _active: $router.route.name === "websites.links" })}
            >
              <Icon icon="task-1" />
              <span>Links</span>
            </BaseLink>
          </div>
        </div>
      </div>

      {
        data &&
        <div className="AccountsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Website (Main):</h1>

              <div className="item-name">
                {data.domain}
              </div>
            </div>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'websites',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', minWidth: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                data.status === 'not_installed' &&
                <button
                  style={{ background: 'rgb(96, 147, 222)', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  disabled={editing}
                  onClick={handleInstall}
                >
                  Install
                </button>
              }

              {
                !editing &&
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              }
            </div>
          </div>

          <div className="fieldset">
            <div
              className="legend"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Main

              <div className={classNames('google_auth',
                {
                  _red: data.status === 'inactive',
                  _blue: data.status === 'not_installed',
                  _green: data.status === 'active'
                }
              )}></div>
            </div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Domain:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.domain}
                    onChange={({ target: { value } }) => setData({ ...data, domain: value })}
                  />
                </div>

                <div className="field">
                  <span>Template:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.template}
                    onChange={({ target: { value } }) => setData({ ...data, template: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Token:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.token}
                    onChange={({ target: { value } }) => setData({ ...data, token: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Datebase</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>DB Host:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.db_host}
                    onChange={({ target: { value } }) => setData({ ...data, db_host: value })}
                  />
                </div>

                <div className="field">
                  <span>DB Table Name:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.db_name}
                    onChange={({ target: { value } }) => setData({ ...data, db_name: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>DB User Name:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.db_user_name}
                    onChange={({ target: { value } }) => setData({ ...data, db_user_name: value })}
                  />
                </div>

                <div className="field">
                  <span>DB User Password:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={data.db_user_password}
                    onChange={({ target: { value } }) => setData({ ...data, db_user_password: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.created_by}
                  />
                </div>
              </div>


              <div className="__right">
                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.updated_by}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Website</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>
              <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                <div className="title">
                  Delete Website
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox
                    contents="I understand that after deleting the website it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">
                  <button
                    className="_bordered _green"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemove()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
    </>
  )
}
