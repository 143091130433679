import { useEffect, useState } from "react"

import SelectComponent from "../../components/Select"

import { AppointDataProps } from "./List"
import { JobProps } from "./Models"

interface BlockProps {
  editing: boolean
  setEditing: (value: string) => void
  data: JobProps
  setData: (value: JobProps) => void
  appointmentReport: Partial<AppointDataProps>
  updatedJobItem: () => void
  disabledEditButton: boolean
  permissions_job_edit: boolean
}

export default function JobBlock({
  editing,
  setEditing,
  data,
  setData,
  appointmentReport,
  updatedJobItem,
  disabledEditButton,
  permissions_job_edit,
}: BlockProps) {
  const [readyToSave, setReadyToSave] = useState(false)

  useEffect(() => {
    editing && setReadyToSave(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleCancel() {
    setEditing('')
    setReadyToSave(false)
    appointmentReport && appointmentReport.job && setData(appointmentReport.job)
  }

  function handleSave() {
    updatedJobItem()
    setReadyToSave(false)
  }

  return (
    <>
      <div className="fieldset">
        <div className="block-content-title">
          <div className="block-title">
            Job
          </div>
        </div>

        <div className="fields title">
          <div className="__left">
            <div className="flex-container _gap-narrow">
              <div className="item-name">
                Total Appointments: {data.appointments_count}
              </div>
            </div>
          </div>

          <div className="__right">
            <div
              className="field"
              style={{ justifyContent: 'flex-end' }}
            >
              {
                !editing &&
                <button
                  className="btn btn-add"
                  disabled={disabledEditButton || !permissions_job_edit}
                  onClick={() => setEditing('Job')}
                >
                  Edit
                </button>
              }

              {
                editing &&
                <>
                  <button
                    className="btn btn-add btn-cancel"
                    style={{ width: 'fit-content' }}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="btn btn-add btn-save"
                    style={{ width: 'fit-content' }}
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </>
              }
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>

              <input
                type="text"
                disabled={true}
                value={data.name}
                onChange={({ target: { value } }) => setData({ ...data, name: value })}
              />
            </div>

            <div className="field">
              <span>Source:</span>
              {
                appointmentReport?.edit?.sources ?
                  <SelectComponent
                    disabled={!editing}
                    options={appointmentReport.edit.sources.map((type) => ({
                      span: type.source,
                      value: type.source_id
                    }))}
                    selectedOption={data.source_id}
                    onChange={(value: string) => setData({ ...data, source_id: value })}
                  /> :
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.source}
                    onChange={({ target: { value } }) => setData({ ...data, source: value })}
                  />
              }
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Company:</span>
              {
                appointmentReport?.edit?.companies ?
                  <SelectComponent
                    disabled={!editing}
                    options={appointmentReport.edit.companies.map((type) => ({
                      span: type.name,
                      value: type.company_id
                    }))}
                    selectedOption={data.company_id}
                    onChange={(value: string) => setData({ ...data, company_id: value })}
                  /> :
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.company}
                    onChange={({ target: { value } }) => setData({ ...data, company: value })}
                  />
              }
            </div>

            <div className="field">
              <span>Status:</span>
              {
                appointmentReport?.edit?.job_statuses ?
                  <SelectComponent
                    disabled={true}
                    options={appointmentReport.edit.job_statuses.map((type) => ({
                      span: type.name,
                      value: type.status_id
                    }))}
                    selectedOption={data.status_id}
                    onChange={(value: string) => setData({ ...data, status_id: value })}
                  /> :
                  <input type="text"
                    disabled={true}
                    value={data.status}
                    onChange={({ target: { value } }) => setData({ ...data, status: value })}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
