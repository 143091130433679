export default function PauseSvg() {
  return (
    <svg viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="53" height="23" rx="11.5" stroke="#6093DE"></rect>
      <path
        strokeWidth="2.6" stroke="#6093DE"
        d="M24 6V18M30 6V18"
      ></path>
    </svg>
  )
}
