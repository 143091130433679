import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment";

import ReportFilters from "../../components/reports/Filters"

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"

import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

interface HttpReportsData {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: Permissions
}

interface Permissions {
  permissions: Permission
}

interface Permission {
  reports_daily_orders_by_dispatchers: boolean
  reports_daily_orders_by_sources: boolean
}

interface DispatcherItem {
  canceled_jobs_count: number
  code: number
  jobs_count: number
  nickname: string
}

interface SourceItem {
  canceled_jobs_count: number
  jobs_count: number
  name: string
}

export default function Reports_Page_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const user = useAppSelector((store) => store.user)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [selectedSearch, setSelectedSearch] = useState('')
  const [reportData, setReportData] = useState<string[]>([])

  const [dispathcersReport, setDispathcersReport] = useState({})
  const [sourcesReport, setSourcesReport] = useState({})
  const [column, setColumn] = useState<string[]>([])

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "last_7_days" as DateRangePreset,

    ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),

    page: 1,

    filter_words: {
      area: [] as string[],
      created_by: [] as string[],
      service_resource: [] as string[]
    },

    selectedSearchFilters: { type: [] as string[], direction: [] as string[] },

    sort: {
      field: 'created_at',
      direction: 'down' as 'up' | 'down'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }]
  })

  async function loadReports() {
    // https://2022back4.artemiudintsev.com/api/reports?account_id=88888
    try {
      const { data: { data: permissionsData, success, error } } = (await httpClientUpdate.get('/reports', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpReportsData }
      if (success) {
        let permissionsOption: string[] = []
        Object.keys(permissionsData.permissions).forEach(item => {
          if (permissionsData.permissions[item]) {
            if (item === 'reports_daily_orders_by_dispatchers') {
              permissionsOption.push('Daily orders by dispatchers')
            } else if (item === 'reports_daily_orders_by_sources') {
              permissionsOption.push('Daily orders by sources')
            }
          }
        })
        setReportData(permissionsOption)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
      }, 100)
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function laodSelectReport() {
    try {
      // https://2022back4.artemiudintsev.com/api/reports/daily-orders-by-dispatchers?account_id=88888&date_start=2023-08-01&date_end=2023-08-10
      const { data: { data: dispatchersData, success } } = (await httpClientUpdate.get('/reports/daily-orders-by-dispatchers', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId,
          date_start: moment.tz(moment(localInterface.min_date), timeZone as string).format('YYYY-MM-DD'),
          date_end: moment.tz(moment(localInterface.max_date), timeZone as string).format('YYYY-MM-DD'),
        }
      })) as { data: any }
      if (success) {
        setDispathcersReport(dispatchersData)
      }

    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function laodSourcesReport() {
    try {
      // https://2022back4.artemiudintsev.com/api/reports/daily-orders-by-sources?account_id=88888&date_start=2023-08-04&date_end=2023-08-10
      const { data: { data: sourcesData, success } } = (await httpClientUpdate.get('/reports/daily-orders-by-sources', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId,
          date_start: moment.tz(moment(localInterface.min_date), timeZone as string).format('YYYY-MM-DD'),
          date_end: moment.tz(moment(localInterface.max_date), timeZone as string).format('YYYY-MM-DD'),
        }
      })) as { data: any }
      if (success) {
        setSourcesReport(sourcesData)
      }

    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadReports()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedSearch === 'Daily orders by dispatchers') {
      laodSelectReport()
      setSourcesReport({})
    } else if (selectedSearch === 'Daily orders by sources') {
      laodSourcesReport()
      setDispathcersReport({})
    } else {
      setDispathcersReport({})
      setSourcesReport({})
    }
    setReportIsDeprecated(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch, $updater])

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      dateRangeCalendarShown: false,
      dateRangeType: "schedule" as "created" | "schedule",
      dateRangePreset: "last_7_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: {
        area: [] as string[],
        created_by: [] as string[],
        service_resource: [] as string[]
      },
      selectedSearchFilters: { type: [] as string[], direction: [] as string[] },
      sort: {
        field: 'created_at',
        direction: 'down' as 'up' | 'down'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  useEffect(() => {
    if (Object.keys(dispathcersReport).length) {
      let updateColumn: string[] = []
      Object.keys(dispathcersReport).forEach(item => {
        dispathcersReport[item].forEach((dispatcher: DispatcherItem) => {
          if (!column.includes(dispatcher.code ? `${dispatcher.nickname} (${dispatcher.code})` : dispatcher.nickname)) {
            updateColumn.push(dispatcher.code ? `${dispatcher.nickname} (${dispatcher.code})` : dispatcher.nickname)
          }
        })
      })

      setColumn(updateColumn.filter((value, index, array) => array.indexOf(value) === index))
    } else if (Object.keys(sourcesReport).length) {
      let updateColumn: string[] = []
      Object.keys(sourcesReport).forEach(item => {
        sourcesReport[item].forEach((source: SourceItem) => {
          if (!column.includes(source.name)) {
            updateColumn.push(source.name)
          }
        })
      })

      setColumn(updateColumn.filter((value, index, array) => array.indexOf(value) === index))
    } else {
      setColumn([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispathcersReport, sourcesReport])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return

    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, localInterface?.min_date, localInterface?.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  function getDay(day: string) {
    return moment(day).format('ddd')
  }

  function dispatcherName(code: number, nickname: string) {
    return code ? `${nickname} (${code})` : nickname
  }

  return (
    <>
      {
        !!reportData.length &&
        <div className="ReportsPage_List">
          { /* Page header */}
          <div className="page-header">
            <h1>Reports</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            selectSearch={true}
            selectedSearch={selectedSearch}
            setSelectedSearch={setSelectedSearch}
            selectSearchOptions={reportData}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom' })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom' })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          <div className="contents">
            {
              !!Object.keys(dispathcersReport).length &&
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Date</th>
                    {
                      column.map(item => (
                        <th
                          key={item}
                          style={{ textAlign: 'center', width: `calc(80% / ${column.length})` }}
                        >
                          {item}
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                {
                  Object.keys(dispathcersReport).map((item, index) => (
                    <tr key={index}>
                      <td> {getDay(item)}, {item} </td>
                      {
                        column.map((col, i) => (
                          <td key={i} style={{ padding: 0 }}>
                            {
                              dispathcersReport[item].map((dispatcher: DispatcherItem, idx: number) => (
                                dispatcherName(dispatcher.code, dispatcher.nickname) === col &&
                                <div key={idx}
                                  style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                  <div style={{ borderRight: '1px solid #d0d3da', width: '50%', padding: '5px 10px', textAlign: 'right' }}>{dispatcher.jobs_count} </div>
                                  <div style={{ color: '#FF0000', width: '50%', padding: '5px 10px', textAlign: 'right' }}> {dispatcher.canceled_jobs_count} </div>
                                </div>
                              ))
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </table>
            }

            {
              !!Object.keys(sourcesReport).length &&
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Date</th>
                    {
                      column.map(item => (
                        <th
                          key={item}
                          style={{ textAlign: 'center', width: `calc(80% / ${column.length})` }}
                        >
                          {item}
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                {
                  Object.keys(sourcesReport).map((item, index) => (
                    <tr key={index}>
                      <td> {getDay(item)}, {item} </td>
                      {
                        column.map((col, i) => (
                          <td key={i} style={{ padding: 0 }}>
                            {
                              sourcesReport[item].map((source: SourceItem, idx: number) => (
                                source.name === col &&
                                <div key={idx}
                                  style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                  <div style={{ borderRight: '1px solid #d0d3da', width: '50%', padding: '5px 10px', textAlign: 'right' }}>{source.jobs_count} </div>
                                  <div style={{ color: '#FF0000', width: '50%', padding: '5px 10px', textAlign: 'right' }}> {source.canceled_jobs_count} </div>
                                </div>
                              ))

                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </table>
            }
          </div>
        </div>
      }
    </>
  )
}