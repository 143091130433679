export const arrTimesOfDay = [
  {
    span: "12AM",
    value: "00:00",
  },
  {
    span: "1AM",
    value: "01:00",
  },
  {
    span: "2AM",
    value: "02:00",
  },
  {
    span: "3AM",
    value: "03:00",
  },
  {
    span: "4AM",
    value: "04:00",
  },
  {
    span: "5AM",
    value: "05:00",
  },
  {
    span: "6AM",
    value: "06:00",
  },
  {
    span: "7AM",
    value: "07:00",
  },
  {
    span: "8AM",
    value: "08:00",
  },
  {
    span: "9AM",
    value: "09:00",
  },
  {
    span: "10AM",
    value: "10:00",
  },
  {
    span: "11AM",
    value: "11:00",
  },
  {
    span: "12PM",
    value: "12:00",
  },
  {
    span: "1PM",
    value: "13:00",
  },
  {
    span: "2PM",
    value: "14:00",
  },
  {
    span: "3PM",
    value: "15:00",
  },
  {
    span: "4PM",
    value: "16:00",
  },
  {
    span: "5PM",
    value: "17:00",
  },
  {
    span: "6PM",
    value: "18:00",
  },
  {
    span: "7PM",
    value: "19:00",
  },
  {
    span: "8PM",
    value: "20:00",
  },
  {
    span: "9PM",
    value: "21:00",
  },
  {
    span: "10PM",
    value: "22:00",
  },
  {
    span: "11PM",
    value: "23:00",
  },
  {
    span: "12AM",
    value: "24:00",
  },
];

export const arrGridOfDay = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "24:00",
];

export const DayOfWeekOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const DayOfWeekTechCalendar = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export const DayOfWeekOptionsForSchedule = [
  {
    value: "Monday",
    span: "monday",
  },
  {
    value: "Tuesday",
    span: "tuesday",
  },
  {
    value: "Wednesday",
    span: "wednesday",
  },
  {
    value: "Thursday",
    span: "thursday",
  },
  {
    value: "Friday",
    span: "friday",
  },
  {
    value: "Saturday",
    span: "saturday",
  },
  {
    value: "Sunday",
    span: "sunday",
  },
];

export const arrTimesForSchedule = [
  {
    value: "00:00:00",
    span: "12:00am",
  },
  {
    value: "01:00:00",
    span: "1:00am",
  },
  {
    value: "02:00:00",
    span: "2:00am",
  },
  {
    value: "03:00:00",
    span: "3:00am",
  },
  {
    value: "04:00:00",
    span: "4:00am",
  },
  {
    value: "05:00:00",
    span: "5:00am",
  },
  {
    value: "06:00:00",
    span: "6:00am",
  },
  {
    value: "07:00:00",
    span: "7:00am",
  },
  {
    value: "08:00:00",
    span: "8:00am",
  },
  {
    value: "09:00:00",
    span: "9:00am",
  },
  {
    value: "10:00:00",
    span: "10:00am",
  },
  {
    value: "11:00:00",
    span: "11:00am",
  },
  {
    value: "12:00:00",
    span: "12:00pm",
  },
  {
    value: "13:00:00",
    span: "1:00pm",
  },
  {
    value: "14:00:00",
    span: "2:00pm",
  },
  {
    value: "15:00:00",
    span: "3:00pm",
  },
  {
    value: "16:00:00",
    span: "4:00pm",
  },
  {
    value: "17:00:00",
    span: "5:00pm",
  },
  {
    value: "18:00:00",
    span: "6:00pm",
  },
  {
    value: "19:00:00",
    span: "7:00pm",
  },
  {
    value: "20:00:00",
    span: "8:00pm",
  },
  {
    value: "21:00:00",
    span: "9:00pm",
  },
  {
    value: "22:00:00",
    span: "10:00pm",
  },
  {
    value: "23:00:00",
    span: "11:00pm",
  },
  {
    value: "24:00:00",
    span: "12:00am",
  },
];

export const arrTimesWithMinutesForSchedule = [
  {
    value: "00:00:00",
    span: "12:00am",
  },
  {
    value: "00:30:00",
    span: "12:30am",
  },
  {
    value: "01:00:00",
    span: "1:00am",
  },
  {
    value: "01:30:00",
    span: "1:30am",
  },
  {
    value: "02:00:00",
    span: "2:00am",
  },
  {
    value: "02:30:00",
    span: "2:30am",
  },
  {
    value: "03:00:00",
    span: "3:00am",
  },
  {
    value: "03:30:00",
    span: "3:30am",
  },
  {
    value: "04:00:00",
    span: "4:00am",
  },
  {
    value: "04:30:00",
    span: "4:30am",
  },
  {
    value: "05:00:00",
    span: "5:00am",
  },
  {
    value: "05:30:00",
    span: "5:30am",
  },
  {
    value: "06:00:00",
    span: "6:00am",
  },
  {
    value: "06:30:00",
    span: "6:30am",
  },
  {
    value: "07:00:00",
    span: "7:00am",
  },
  {
    value: "07:30:00",
    span: "7:30am",
  },
  {
    value: "08:00:00",
    span: "8:00am",
  },
  {
    value: "08:30:00",
    span: "8:30am",
  },
  {
    value: "09:00:00",
    span: "9:00am",
  },
  {
    value: "09:30:00",
    span: "9:30am",
  },
  {
    value: "10:00:00",
    span: "10:00am",
  },
  {
    value: "10:30:00",
    span: "10:30am",
  },
  {
    value: "11:00:00",
    span: "11:00am",
  },
  {
    value: "11:30:00",
    span: "11:30am",
  },
  {
    value: "12:00:00",
    span: "12:00pm",
  },
  {
    value: "12:30:00",
    span: "12:30pm",
  },
  {
    value: "13:00:00",
    span: "1:00pm",
  },
  {
    value: "13:30:00",
    span: "1:30pm",
  },
  {
    value: "14:00:00",
    span: "2:00pm",
  },
  {
    value: "14:30:00",
    span: "2:30pm",
  },
  {
    value: "15:00:00",
    span: "3:00pm",
  },
  {
    value: "15:30:00",
    span: "3:30pm",
  },
  {
    value: "16:00:00",
    span: "4:00pm",
  },
  {
    value: "16:30:00",
    span: "4:30pm",
  },
  {
    value: "17:00:00",
    span: "5:00pm",
  },
  {
    value: "17:30:00",
    span: "5:30pm",
  },
  {
    value: "18:00:00",
    span: "6:00pm",
  },
  {
    value: "18:30:00",
    span: "6:30pm",
  },
  {
    value: "19:00:00",
    span: "7:00pm",
  },
  {
    value: "19:30:00",
    span: "7:30pm",
  },
  {
    value: "20:00:00",
    span: "8:00pm",
  },
  {
    value: "20:30:00",
    span: "8:30pm",
  },
  {
    value: "21:00:00",
    span: "9:00pm",
  },
  {
    value: "21:30:00",
    span: "9:30pm",
  },
  {
    value: "22:00:00",
    span: "10:00pm",
  },
  {
    value: "22:30:00",
    span: "10:30pm",
  },
  {
    value: "23:00:00",
    span: "11:00pm",
  },
  {
    value: "23:30:00",
    span: "11:30pm",
  },
  {
    value: "24:00:00",
    span: "12:00am",
  },
];

export const momentFormat = "MM/DD/YYYY hh:mma";

export const payoutDispatcherTimesOfDay = [
  {
    span: "12am - 1am",
    value: "00:00:00",
  },
  {
    span: "1am - 2am",
    value: "01:00:00",
  },
  {
    span: "2am - 3am",
    value: "02:00:00",
  },
  {
    span: "3am - 4am",
    value: "03:00:00",
  },
  {
    span: "4am - 5am",
    value: "04:00:00",
  },
  {
    span: "5am - 6am",
    value: "05:00:00",
  },
  {
    span: "6am - 7am",
    value: "06:00:00",
  },
  {
    span: "7am - 8am",
    value: "07:00:00",
  },
  {
    span: "8am - 9am",
    value: "08:00:00",
  },
  {
    span: "9am - 10am",
    value: "09:00:00",
  },
  {
    span: "10am - 11am",
    value: "10:00:00",
  },
  {
    span: "11am - 12pm",
    value: "11:00:00",
  },
  {
    span: "12pm - 1pm",
    value: "12:00:00",
  },
  {
    span: "1pm - 2pm",
    value: "13:00:00",
  },
  {
    span: "2pm - 3pm",
    value: "14:00:00",
  },
  {
    span: "3pm - 4pm",
    value: "15:00:00",
  },
  {
    span: "4pm - 5pm",
    value: "16:00:00",
  },
  {
    span: "5pm - 6pm",
    value: "17:00:00",
  },
  {
    span: "6pm - 7pm",
    value: "18:00:00",
  },
  {
    span: "7pm - 8pm",
    value: "19:00:00",
  },
  {
    span: "8pm - 9pm",
    value: "20:00:00",
  },
  {
    span: "9pm - 10pm",
    value: "21:00:00",
  },
  {
    span: "10pm - 11pm",
    value: "22:00:00",
  },
  {
    span: "11pm - 12am",
    value: "23:00:00",
  },
];
