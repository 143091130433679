import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../../components/Icon"
import ReportFilters from "../../../components/reports/Filters"
import ReportTableControls from "../../../components/reports/TableControls"
import ReportTableField from "../../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../../funcs";
import { useAppSelector } from "../../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/report-list.sass"

type FilterWord = 'is_call_center'

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallCentersReport
}

export interface CallCentersReport {
  interface: {
    filter_words: {
      is_call_center: string[]
    },
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  call_center: CallCenterProps[]

  edit: {
    user_groups: UserGroupsProps[]
  }

  permissions: PermissionsProps
}

interface PermissionsProps {
  call_center_add: boolean
  call_center_delete: boolean
  call_center_edit: boolean
  call_center_report_show: boolean
  call_center_show: boolean
}

export interface UserGroupsProps {
  name: string
  user_group_id: string
}

interface CallCenterProps {
  name: string
  is_call_center: boolean
  app_sid: string
  call_center_id: string
  call_time_out: number
  created_by: string
  twilio_account_sid: string
  twilio_call_token: string
  twilio_token: string
  updated_by: string
  voice: string
}

function CallCenter_PhoneNumbersPage_List() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<CallCentersReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.call_center_call_center?.filter_field ? {
      is_call_center: [] as string[],
      ...cookiesSetting.call_center_call_center.filter_field,
    } : {
      is_call_center: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.call_center_call_center?.sort_field ? cookiesSetting.call_center_call_center.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.call_center_call_center?.sort_type ? cookiesSetting.call_center_call_center.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Is call center',
      value: 'is_call_center'
    }, {
      span: 'Call time out',
      value: 'call_time_out'
    }, {
      span: 'Voice',
      value: 'voice'
    }]
  })

  // Load clients function
  async function loadData() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    setCookie('settings', { ...cookiesSetting, call_center_call_center: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.call_center_call_center?.limit_rows ? cookiesSetting?.call_center_call_center.limit_rows : reportsMaxRows } })

    try {
      // https://2022back4.artemiudintsev.com/api/call-center/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: dataResponse, success, error } } = (await httpClientUpdate.get('/call-center/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 100,
          page: 1,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
        }
      })) as { data: HttpReport }
      if (success) {
        setReportData({
          ...dataResponse,
          interface: {
            ...dataResponse.interface,
            filter_words: {
              ...localInterface.filter_words,
              ...dataResponse.interface.filter_words,
            }
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, call_center_call_center: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="CallCenter_PhoneNumbersPage_List" >

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="page-header">
          <h1>Call Centers</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => {
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          onMaxRowsChange={() => $setUpdater(Math.random())}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.call_center_add ?
              <div className="SelectComponent">
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    onClick={() => $router.router.navigate('call_center.callCenters.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                    }, { reload: true })}>
                    <Icon icon="plus-thin" />
                    <span>Add Call Center</span>
                    <Icon icon="delivery-8" />
                  </button>
                </div>
              </div> : <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Voice</span>)}

                  sortDirection={localInterface.sort.field === 'voice' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'voice', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Call Time Out</span>)}

                  sortDirection={localInterface.sort.field === 'call_time_out' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_time_out', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Active</span>)}

                  allFiltersSelected={isAllFiltersSelected("is_call_center")}
                  onAllFiltersChange={(value) => switchFilter("is_call_center", "All", value)}

                  filterWords={reportData?.interface.filter_words.is_call_center.map((filterWord) => ({
                    word: filterWord,
                    word_name: filterWord ? 'Yes' : 'No',
                    selected: isFilterSelected("is_call_center", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("is_call_center", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'is_call_center' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'is_call_center', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.call_center.map((center, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.call_center_show && center.call_center_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.call_center_show &&
                  center.call_center_id &&
                  $router.router.navigate('call_center.callCenters.item', {
                    companyId: activeAccountId,
                    callCenterId: center.call_center_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.call_center_show &&
                  center.call_center_id &&
                  handleChangeMenu(event, center.call_center_id, 'call_center', i)}
              >
                <td>
                  {center.name}
                </td>

                <td>
                  {center.voice}
                </td>

                <td>
                  {center.call_time_out}
                </td>

                <td className={classNames({
                  'red-text': !center.is_call_center,
                  'green-text': center.is_call_center,
                })}>
                  {center.is_call_center ? 'Yes' : 'No'}

                  {
                    reportData.permissions.call_center_show &&
                    showContext === `call_center_${center.call_center_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.callCenters.item', {
                            companyId: activeAccountId,
                            callCenterId: center.call_center_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/call_centers/${center.call_center_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.call_center.map((center, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.call_center_show && center.call_center_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.call_center_show &&
                  center.call_center_id &&
                  $router.router.navigate('call_center.callCenters.item', {
                    companyId: activeAccountId,
                    callCenterId: center.call_center_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.call_center_show &&
                  center.call_center_id &&
                  handleChangeMenu(event, center.call_center_id, 'call_center', i)}
              >
                <td>
                  {center.name}
                </td>

                <td>
                  <div>{center.voice}</div>
                </td>

                <td>
                  <div>{center.call_time_out}</div>
                </td>

                <td>
                  <div className={classNames({
                    'red-text': !center.is_call_center,
                    'green-text': center.is_call_center,
                  })}>
                    {center.is_call_center ? 'Yes' : 'No'}
                  </div>

                  {
                    reportData.permissions.call_center_show &&
                    showContext === `call_center_${center.call_center_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('call_center.callCenters.item', {
                            companyId: activeAccountId,
                            callCenterId: center.call_center_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/call_center/call_centers/${center.call_center_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            {reportData.call_center.map((center, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.call_center_show &&
                  center.call_center_id &&
                  $router.router.navigate('call_center.callCenters.item', {
                    companyId: activeAccountId,
                    callCenterId: center.call_center_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><b>{center.name}</b></div>
                  </div>

                  <div className="__right">
                    <div className={classNames({
                      'red-text': !center.is_call_center,
                      'green-text': center.is_call_center,
                    })}>
                      {center.is_call_center ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Voice:</span>
                      <span> {center.voice}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      <span className="gray">Call Time Out:</span>
                      <span> {center.call_time_out}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          onMaxRowsChange={() => $setUpdater(Math.random())}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default CallCenter_PhoneNumbersPage_List
