import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import Select from '../../components/Select'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { UserGroupsProps } from './List'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },

  data: ReportData
}

interface ReportData {
  mailbox: MailboxProps

  permissions: {
    mailbox_delete: boolean
    mailbox_edit: boolean
  }

  edit: {
    user_groups: UserGroupsProps[]
  }
}

interface MailboxProps {
  can_read_user_group_id: string
  can_write_user_group_id: string
  created_at: string
  created_by: string
  email: string
  password: string
  imap_domain: string
  imap_port: string
  is_connected: boolean
  mailbox_id: string
  mails_last_updated_at: string
  smtp_domain: string
  smtp_port: string
  updated_at: string
  updated_by: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)

  const [readyToDelete, setReadyToDelete] = useState(false)
  const [editing, setEditing] = useState(false)

  const [data, setData] = useState<MailboxProps | null>(null)
  const [dataReport, setDataReport] = useState<ReportData | null>(null)

  async function handleSave() {
    setIsSaving(true)
    try {
      if (data) {
        // https://2022back4.artemiudintsev.com/api/mailboxes/88888550yh0rpzq5tc
        const response = await httpClientUpdate.put(`/mailboxes/${$router.router.getState().params.mailboxesId}`,
          {
            account_id: activeAccountId,
            email: data.email,
            password: data.password,
            can_read_user_group_id: data.can_read_user_group_id,
            can_write_user_group_id: data.can_write_user_group_id,
            imap_domain: data.imap_domain,
            smtp_domain: data.smtp_domain,
            imap_port: data.imap_port,
            smtp_port: data.smtp_port,
          })
        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadInfo()
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove ticket
  async function handleRemove() {
    try {
      // https://2022back4.artemiudintsev.com/api/tickets/88888001fd67db3si1
      const response = await httpClientUpdate.delete(`/mailboxes/${$router.router.getState().params.mailboxesId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        $router.router.navigate('mailboxes', {
          companyId: activeAccountId,
        }, { reload: true })
      }
    } catch (error) {

    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/mailboxes/888885509yi1hp8tco?account_id=88888
      const { data: { data: mailboxData, success } } = await httpClientUpdate.get('/mailboxes/' + $router.router.getState().params.mailboxesId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setDataReport(mailboxData)
        setData(mailboxData.mailbox)
      }
      setTimeout(() => setReadyToSave(false), 100)
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    data
  ])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  function handleCancelEdit() {
    setEditing(false)
    dataReport && setData(dataReport.mailbox)
  }

  return (
    <>
      {
        dataReport && data &&

        <div className="TicketsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Mailbox:</h1>
              <div className="item-name">
                {data.email}
              </div>
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  padding: '4px'
                }}
              >
                <Icon style={{ fill: data.is_connected ? '#01b41f' : '#FF0000' }} icon="check-mark-1" />
              </div>
            </div>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() => $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'mailboxes',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                dataReport.permissions?.mailbox_edit && !editing &&
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Email:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.email}
                    onChange={(event) => setData({ ...data, email: event.target.value })}
                  />
                </div>
              </div>

              <div className="__right">
                {
                  editing &&
                  <div className="field">
                    <span>Password:</span>
                    <input
                      type="text"
                      disabled={!editing}
                      value={data.password}
                      onChange={(event) => setData({ ...data, password: event.target.value })}
                    />
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Settings</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>IMAP domain:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.imap_domain}
                    onChange={(event) => setData({ ...data, imap_domain: event.target.value })}
                  />
                </div>

                <div className="field">
                  <span>IMAP port:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.imap_port}
                    onChange={(event) => setData({ ...data, imap_port: event.target.value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>SMTP domain:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.smtp_domain}
                    onChange={(event) => setData({ ...data, smtp_domain: event.target.value })}
                  />
                </div>

                <div className="field">
                  <span>SMTP port:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    value={data.smtp_port}
                    onChange={(event) => setData({ ...data, smtp_port: event.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Access</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Can Read:</span>

                  <Select
                    disabled={!editing}
                    options={dataReport.edit.user_groups.map((group) => ({
                      span: group.name,
                      value: group.user_group_id
                    }))}
                    selectedOption={data.can_read_user_group_id}
                    onChange={(value: string) => setData({ ...data, can_read_user_group_id: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Can Send:</span>

                  <Select
                    disabled={!editing}
                    options={dataReport.edit.user_groups.map((group) => ({
                      span: group.name,
                      value: group.user_group_id
                    }))}
                    selectedOption={data.can_write_user_group_id}
                    onChange={(value: string) => setData({ ...data, can_write_user_group_id: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Created at:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at)}
                    onChange={(event) => setData({ ...data, created_at: event.target.value })}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.created_by}
                    onChange={(event) => setData({ ...data, created_by: event.target.value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Updated at:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.updated_at)}
                    onChange={(event) => setData({ ...data, updated_at: event.target.value })}
                  />
                </div>
                <div className="field">
                  <span>Updated by:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={data.updated_by}
                    onChange={(event) => setData({ ...data, updated_by: event.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  dataReport.permissions.mailbox_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Mailbox</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {/* Item share popup */}
          {
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Mailbox
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox
                    contents="I understand that after deleting the mailbox it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete}
                    className="_bordered _red"
                    onClick={() => handleRemove()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}
