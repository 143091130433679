import classNames from 'classnames'
import { useState } from 'react'

import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },

  data: ReportData
}

interface ReportData {
  jobs: JobsProps[]
}

interface JobsProps {
  job_id: string
  name: string
  not_invoice_payment_item_total: string
  contact_name: string
  contact_id: string
  incoices: IncoicesProps[]
}

interface IncoicesProps {
  comment: string
  created_at: string
  estimate_id: string
  invoice_id: string
  invoice_paid: string
  invoice_status: string
  invoice_total: string
  job_id: string
  name: string
  updated_at: string
}

export interface InvoiceAddProps {
  job_id: string
  invoice_id?: string
  contact_id: string
  name: string
  paid: string
  total?: string
  contact_name: string
  is_check: boolean
}

interface SearchInvoicesProps {
  handleClose: () => void
  contact_id: string
  handleAddInvoice: (data: InvoiceAddProps[]) => void
  invoice_ids: string[]
  job_ids: string[]
}

export default function SearchInvoices({
  handleClose,
  contact_id,
  handleAddInvoice,
  invoice_ids,
  job_ids,
}: SearchInvoicesProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [search, setSearch] = useState('')
  const [error, setError] = useState('')

  const [data, setData] = useState<InvoiceAddProps[]>([])

  async function getInvoices() {
    try {
      // https://2022back4.artemiudintsev.com/api/payments/invoices
      const { data: { data: report, success } } = await httpClientUpdate.get(`/payments/invoices`, {
        params: {
          account_id: activeAccountId,
          search: search
        }
      }) as { data: HttpReport }
      if (success) {
        if (report.jobs.length) {
          let items: InvoiceAddProps[] = []

          report.jobs.forEach((item) => {
            if (item.incoices.length) {
              item.incoices.forEach(item_invoice => {
                items.push({
                  job_id: item_invoice.job_id,
                  name: item_invoice.name,
                  invoice_id: item_invoice.invoice_id,
                  paid: item_invoice.invoice_paid,
                  total: item_invoice.invoice_total,
                  contact_id: item.contact_id,
                  contact_name: item.contact_name,
                  is_check: false,
                })
              })

              items.push({
                job_id: item.job_id,
                name: item.name,
                paid: item.not_invoice_payment_item_total ? item.not_invoice_payment_item_total : '0.00',
                contact_id: item.contact_id,
                contact_name: item.contact_name,
                is_check: false,
              })
            } else {
              items.push({
                job_id: item.job_id,
                name: item.name,
                paid: item.not_invoice_payment_item_total ? item.not_invoice_payment_item_total : '0.00',
                contact_id: item.contact_id,
                contact_name: item.contact_name,
                is_check: false,
              })
            }
          })

          setData(items)
          setError('')
        } else {
          setData([])
          setError('Nothing was found!')
        }
      }

    } catch (error) {
      setData([])
      setError('Something went wrong!')
    }
  }

  function handleCheck(value: boolean, job_id: string, invoice_id?: string) {
    if (value) {
      if (invoice_id) {
        setData(data.map(item => {
          if (item.job_id === job_id) {
            return { ...item, is_check: true }
          } else {
            return { ...item }
          }
        }))
      } else {
        setData(data.map(item => {
          if (item.job_id === job_id) {
            return { ...item, is_check: true }
          } else {
            return { ...item }
          }
        }))
      }
    } else {
      if (invoice_id) {
        setData(data.map(item => {
          if (item.invoice_id === invoice_id) {
            return { ...item, is_check: false }
          } else {
            return { ...item }
          }
        }))
      } else {
        setData(data.map(item => {
          if (item.job_id === job_id) {
            return { ...item, is_check: false }
          } else {
            return { ...item }
          }
        }))
      }
    }
  }

  return (
    <>
      <div className='content-title'>
        <div
          className='content-title-row'
          style={{ alignItems: 'center' }}
        >
          <div></div>

          <div className='mobile-text'>
            Add Invoices
          </div>

          <div
            onClick={() => handleClose()}
            className="content-title-plus"
          >
            <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 60 60" icon="plus-job-tech-1" />
          </div>
        </div>
      </div>

      <div className='search-invoices'>
        <input
          type="text"

          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <button
          disabled={search === ''}
          onClick={getInvoices}
        >
          <Icon viewBox="0 0 70 70" icon="search-job-tech-10" />
        </button>
      </div>

      {
        !!error &&
        <div className='error-message'>
          <p> {error} </p>
        </div>
      }

      <div className='invoices-items'>
        {
          data.map((item, index) => (
            <div
              key={index}
              className={classNames('invoices-item',
                {
                  __disabled: job_ids.includes(item.job_id) || (item.invoice_id && invoice_ids.includes(item.invoice_id)),
                }
              )}
            >
              <div className='invoices-item-job'>
                <div className='invoices-item-info'>
                  <Checkbox
                    contents={""}
                    disabled={job_ids.includes(item.job_id) || !!(item.invoice_id && invoice_ids.includes(item.invoice_id))}
                    value={item.is_check || job_ids.includes(item.job_id) || !!(item.invoice_id && invoice_ids.includes(item.invoice_id))}
                    onChange={(value) => handleCheck(value, item.job_id, item.invoice_id)}
                  />
                </div>

                <div className='invoices-item-info'>
                  <div className='bold'>
                    {item.name}
                    {
                      item.total && (Number(item.total.replace('$', '')) - Number(item.paid) === 0 || Number(item.paid) >= Number(item.total.replace('$', ''))) &&
                      <Icon viewBox="0 0 60 60" icon="check-job-tech" />
                    }
                  </div>

                  {
                    item.invoice_id &&
                    <div> Total: {item.total} </div>
                  }
                </div>
              </div>

              <div className='invoices-item-job max-width'>
                <div className='invoices-item-info'>
                  <div
                    className={classNames('contact_name bold',
                      {
                        __red: contact_id !== item.contact_id,
                      }
                    )}
                  >
                    {item.contact_name}
                  </div>

                  <div> Paid: {item.paid} </div>
                </div>

                <div className='invoice-arrow'>
                  <button
                  // onClick={() => handleClose()}
                  >
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <div className='empty'></div>

      <div className='payment-page-actions'>
        <button
          className="_bordered _red"
          // disabled={isSaving}
          onClick={() => handleClose()}
        >
          Cancel
        </button>

        <button
          className="_bordered _green"
          disabled={data.filter(item => item.is_check).length === 0}
          onClick={() => handleAddInvoice(data.filter(item => item.is_check))}
        >
          Add
        </button>
      </div>
    </>
  )
}
