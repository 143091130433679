import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment from "moment"
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import Switcher from "../../components/Switcher"

import {
  dateToInfoBlock,
  updatedDateToReqServer,
  formatPhoneNumber,
  httpClientUpdate,
  nErrorUpdate,
  typeAppointmentForServer,
  typeAppointmentForView,
  useOuterClick
} from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import Appointment from "../../models/Appointment"
import Client from "../../models/Client"
import Unit from "../../models/Unit"
import Address from "../../models/Address"
import Phones from "../../models/Phones"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AppointmentData
}

interface AppointmentData {

  permissions: {
    appointment_add: boolean
    appointment_delete: boolean
    appointment_edit: boolean
    appointment_report_show: boolean
    appointment_send: boolean
    appointment_show: boolean

    client_show: boolean
    job_show: boolean
  },

  appointment: Appointment[],

  clients: Client[],

  units: Unit[],

  address?: Address[],

  phones?: Phones[]

  edit: {
    type: string[],
    status: {
      id: number
      name: string
    }[],
    service_resources: {
      name: string
      service_resource_id: string
      service_resource_code: number
    }[]
  }
}

function AppointmentsPage_Item() {
  const $router = useRoute()

  const [isSending, setIsSending] = useState(false)

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [appointmentData, setAppointmentData] = useState<AppointmentData | null>(null)

  const [newAppointmentData, setNewAppointmentData] = useState<Partial<Appointment> | null>(null)
  const [newUnitData, setNewUnitData] = useState<Unit[] | []>([])

  function handleCancelEdit() {
    let dateStart = appointmentData && dateToInfoBlock('MM/dd/yyyy hh:mma', appointmentData.appointment[0].time_zone, appointmentData.appointment[0].appointment_start as string)
    let dateEnd = appointmentData && dateToInfoBlock('MM/dd/yyyy hh:mma', appointmentData.appointment[0].time_zone, appointmentData.appointment[0].appointment_finish as string)

    appointmentData && setNewAppointmentData({
      ...appointmentData.appointment[0],
      appointment_start: dateStart || '',
      appointment_finish: dateEnd || '',
      type: typeAppointmentForView(appointmentData.appointment[0].type)
    })
    setEditing(false)
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/appointments/${$router.router.getState().params.appointmentId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('appointments', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSending(true)
    let start = moment.isMoment(newAppointmentData?.appointment_start) ? moment(newAppointmentData?.appointment_start).format('MM/DD/YYYY hh:mma') : newAppointmentData?.appointment_start
    let end = moment.isMoment(newAppointmentData?.appointment_finish) ? moment(newAppointmentData?.appointment_finish).format('MM/DD/YYYY hh:mma') : newAppointmentData?.appointment_finish

    try {
      const response = await httpClientUpdate.put(`/appointments/${$router.router.getState().params.appointmentId}`, {
        account_id: activeAccountId,
        type: newAppointmentData?.type ? typeAppointmentForServer(newAppointmentData.type) : null,
        status: newAppointmentData?.status_id,
        appointment_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', newAppointmentData?.time_zone, start as string),
        appointment_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', newAppointmentData?.time_zone, end as string),
        service_resource: newAppointmentData?.service_resource_id ? newAppointmentData?.service_resource_id : null,
        non_schedule: newAppointmentData?.non_schedule ? 1 : 0,
        note: newAppointmentData?.note
      })
      setIsSending(false)

      if (response.data.success) {
        setEditing(false)
        loadInfo()
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: appointmentData, success, error } } = await httpClientUpdate.get('/appointments/' + $router.router.getState().params.appointmentId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (appointmentData.permissions.appointment_show) {
          let dateStart = dateToInfoBlock('MM/dd/yyyy hh:mma', appointmentData.appointment[0].time_zone, appointmentData.appointment[0].appointment_start as string)
          let dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mma', appointmentData.appointment[0].time_zone, appointmentData.appointment[0].appointment_finish as string)

          setAppointmentData(appointmentData)

          setNewAppointmentData({
            ...appointmentData.appointment[0],
            appointment_start: dateStart,
            appointment_finish: dateEnd,
            type: typeAppointmentForView(appointmentData.appointment[0].type)
          })

          setNewUnitData(appointmentData.units)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load appointment data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newAppointmentData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {appointmentData && newAppointmentData ? (
      <div className="AppointmentsPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Appointment</h1>

          {/* Temporarily removed the rights check on the field edit_appointment */}
          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'appointments',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  },
                  { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                appointmentData?.permissions.appointment_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">

              <div className="field">
                <span>Name:</span>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={newAppointmentData.name}
                />
              </div>

              <div className="field" style={{ zIndex: 6 }}>
                <span>Type:</span>
                <Select
                  disabled={!editing}
                  options={[""].concat(appointmentData.edit.type).map((option) => ({
                    span: option,
                    value: option
                  }))}
                  selectedOption={newAppointmentData.type as string}
                  onChange={(value: string) => setNewAppointmentData({ ...newAppointmentData, type: value })}
                />
              </div>

              <div className="field">
                <span>Status:</span>
                <Select
                  disabled={!editing}
                  options={appointmentData.edit.status.map((option) => ({
                    span: option.name,
                    value: option.id
                  }))}
                  selectedOption={newAppointmentData.status_id || 0 as number}
                  onChange={(value: number) => setNewAppointmentData({ ...newAppointmentData, status_id: value })}
                />
              </div>
            </div>


            <div className="__right">

              <div className="field">
                <span>Job:</span>
                {
                  appointmentData.permissions.job_show && newAppointmentData.job_id ?
                    <div
                      onClick={() => {
                        $router.router.navigate('jobs.item', {
                          companyId: activeAccountId,
                          jobId: newAppointmentData.job_id,
                        }, { reload: true })
                      }
                      }
                    >
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          padding: '7px 15px',
                          appearance: 'none',
                          border: '1px solid #d0d3da',
                          background: '#fff',
                          borderRadius: '5px',
                          color: '#C3C6CB',
                          font: '1em Roboto, sans-serif',
                          cursor: 'pointer'
                        }}
                      >
                        {newAppointmentData.job_name}
                      </span>
                    </div> :
                    <input type="text"
                      disabled={true}
                      defaultValue={newAppointmentData.job_name}
                    />
                }
              </div>

              <div className="field">
                <span>Property Type:</span>
                <input type="text"
                  disabled={!editing}
                  value={newAppointmentData.property_type || ''}
                  onChange={({ target: { value } }) => setNewAppointmentData({ ...newAppointmentData, property_type: value })}
                />
              </div>

              <div className="field">
                <span>Area:</span>
                <input type="text"
                  disabled={!editing}
                  value={newAppointmentData.area || ''}
                  onChange={({ target: { value } }) => setNewAppointmentData({ ...newAppointmentData, area: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Schedule</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Appointment start:</span>
                <Datetime
                  dateFormat="MM/DD/YYYY"
                  timeFormat="hh:mma"
                  inputProps={{ disabled: !editing }}
                  value={newAppointmentData.appointment_start}
                  onChange={(value) => setNewAppointmentData({ ...newAppointmentData, appointment_start: value })}
                />
              </div>

              <div className="field">
                <span>Appointment end:</span>
                <Datetime
                  dateFormat="MM/DD/YYYY"
                  timeFormat="hh:mma"
                  inputProps={{ disabled: !editing }}
                  value={newAppointmentData.appointment_finish}
                  onChange={(value) => setNewAppointmentData({ ...newAppointmentData, appointment_finish: value })}
                // onChange={(value) => setNewAppointmentData({ ...newAppointmentData, appointment_finish: moment.isMoment(value) ? value.toDate() : (appointmentData as AppointmentData).appointment[0].appointment_finish })}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Service Resource:</span>
                <Select
                  disabled={!editing}
                  options={[{ service_resource_code: 0, name: '', service_resource_id: '' }].concat(appointmentData.edit.service_resources).map((option) => ({
                    span: option.service_resource_code ? `${option.name}(${option.service_resource_code})` : option.name,
                    value: option.service_resource_id
                  }))}
                  selectedOption={newAppointmentData.service_resource_id as string}
                  onChange={(value: string) => setNewAppointmentData({ ...newAppointmentData, service_resource_id: value })}
                />
              </div>

              <div className="field">
                <span>Non schedule:</span>
                <Switcher
                  disabled={!editing}
                  checked={newAppointmentData.non_schedule}
                  onChange={(value) => setNewAppointmentData({ ...newAppointmentData, non_schedule: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Note</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Note:</span>
                <input type="text"
                  disabled={!editing}
                  value={newAppointmentData.note || ''}
                  onChange={({ target: { value } }) => setNewAppointmentData({ ...newAppointmentData, note: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Units</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Unit type</th>
                <th>Unit</th>
                <th>Brand</th>
                <th>Model Number</th>
                <th>Serial Number</th>
                <th>Price</th>
                <th>Description</th>
              </tr>
            </thead>

            {
              newUnitData.map((unit, i) => (
                <tr key={i}>
                  <td> {unit.unit_type} </td>
                  <td> {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance} </td>
                  <td> {unit.brand === 'Other' ? unit.other_brand : unit.brand} </td>
                  <td> {unit.model_number} </td>
                  <td> {unit.serial_number} </td>
                  <td> {unit.price} </td>
                  <td> {unit.description} </td>
                </tr>
              ))
            }
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {
              newUnitData.map((unit, i) => (
                <tr key={i}>
                  <td>
                    <div>Unit type: {unit.unit_type} </div>

                    <div>Unit: {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance} </div>
                  </td>

                  <td>
                    <div>Brand: {unit.brand === 'Other' ? unit.other_brand : unit.brand} </div>

                    <div>Model number: {unit.model_number} </div>
                  </td>

                  <td>
                    <div>Serial number: {unit.serial_number} </div>

                    <div>Price: {unit.price} </div>
                  </td>

                  <td>
                    <div>Description {unit.description} </div>
                  </td>
                </tr>
              ))
            }
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <div className="mobile-table-items">
              {
                newUnitData.map((unit, i) => (
                  <div
                    className="item"
                    key={i}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>Unit type: {unit.unit_type}</div>
                        <div>
                          Unit: {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}
                        </div>
                        <div>
                          Brand: {unit.brand === 'Other' ? unit.other_brand : unit.brand}
                        </div>
                      </div>

                      <div className="__right">
                        <div>Model number: {unit.model_number}</div>
                        <div>Serial number: {unit.serial_number}</div>
                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div>Description: {unit.description} </div>
                      </div>

                      <div className="__right small">
                        <div>Price: {unit.price}</div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Address</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <input type="text"
                  disabled={true}
                  value={newAppointmentData.address || ''}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Phones</div>
          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Main</th>
                <th>Payer</th>
                <th>SMS</th>
              </tr>
            </thead>

            {appointmentData?.phones && appointmentData?.phones.map((phone, i) => (
              <tr key={i}
                style={{ cursor: 'inherit' }}
              >
                <td>{phone.name}</td>
                <td>{formatPhoneNumber(phone.phone)}</td>
                <td>{
                  !!phone.is_main ?
                    <Icon style={{ fill: '#01b41f', width: '20px' }} icon="check-mark-1" />
                    :
                    <Icon
                      style={{ fill: '#FF0000', width: '16px' }}
                      icon="x-mark-1"
                    />
                }</td>
                <td>{
                  !!phone.is_payer ?
                    <Icon style={{ fill: '#01b41f', width: '20px' }} icon="check-mark-1" />
                    :
                    <Icon
                      style={{ fill: '#FF0000', width: '16px' }}
                      icon="x-mark-1"
                    />
                }</td>
                <td>{
                  !!phone.is_sms ?
                    <Icon style={{ fill: '#01b41f', width: '20px' }} icon="check-mark-1" />
                    :
                    <Icon
                      style={{ fill: '#FF0000', width: '16px' }}
                      icon="x-mark-1"
                    />
                }</td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {appointmentData?.phones && appointmentData?.phones.map((phone, i) => (
              <tr
                key={i}
                style={{ cursor: 'inherit' }}
              >
                <td>
                  <div>{phone.name}</div>
                </td>
                <td>
                  <div>{formatPhoneNumber(phone.phone)}</div>
                </td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    Main:
                    {!!phone.is_main ?
                      <Icon style={{ fill: '#01b41f', width: '20px', marginLeft: '10px' }} icon="check-mark-1" />
                      :
                      <Icon
                        style={{ fill: '#FF0000', width: '16px', marginLeft: '10px' }}
                        icon="x-mark-1"
                      />}
                  </div>
                </td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    Payer:
                    {!!phone.is_payer ?
                      <Icon style={{ fill: '#01b41f', width: '20px', marginLeft: '10px' }} icon="check-mark-1" />
                      :
                      <Icon
                        style={{ fill: '#FF0000', width: '16px', marginLeft: '10px' }}
                        icon="x-mark-1"
                      />}
                  </div>
                </td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    SMS:
                    {!!phone.is_sms ?
                      <Icon style={{ fill: '#01b41f', width: '20px', marginLeft: '10px' }} icon="check-mark-1" />
                      :
                      <Icon
                        style={{ fill: '#FF0000', width: '16px', marginLeft: '10px' }}
                        icon="x-mark-1"
                      />}
                  </div>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {appointmentData?.phones && appointmentData?.phones.map((phone, i: number) => (
                <div
                  key={i}
                  className="item"
                >

                  <div className="__top">
                    <div className="__left">
                      <div><b>{phone.name}</b></div>
                      <div>
                        {formatPhoneNumber(phone.phone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        Main:
                        {!!phone.is_main ?
                          <Icon style={{ fill: '#01b41f', width: '20px', marginLeft: '10px' }} icon="check-mark-1" />
                          :
                          <Icon
                            style={{ fill: '#FF0000', width: '16px', marginLeft: '10px' }}
                            icon="x-mark-1"
                          />}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        Payer:
                        {!!phone.is_payer ?
                          <Icon style={{ fill: '#01b41f', width: '20px', marginLeft: '10px' }} icon="check-mark-1" />
                          :
                          <Icon
                            style={{ fill: '#FF0000', width: '16px', marginLeft: '10px' }}
                            icon="x-mark-1"
                          />}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        SMS:
                        {!!phone.is_sms ?
                          <Icon style={{ fill: '#01b41f', width: '20px', marginLeft: '10px' }} icon="check-mark-1" />
                          :
                          <Icon
                            style={{ fill: '#FF0000', width: '16px', marginLeft: '10px' }}
                            icon="x-mark-1"
                          />}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointmentData?.appointment[0].created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={appointmentData?.appointment[0].created_by}
                  disabled={true}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointmentData?.appointment[0].updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={appointmentData?.appointment[0].updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Clients</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Main Phone</th>
                <th>Source</th>
                <th>Jobs</th>
                <th>Appointments</th>
                <th>Recalls</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Unpaid</th>
              </tr>
            </thead>

            {appointmentData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: appointmentData.permissions.client_show && client.client_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  appointmentData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'appointments.item',
                    nameId: 'appointmentId',
                    id: $router.router.getState().params.appointmentId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  appointmentData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>{client.name}</td>
                <td>{formatPhoneNumber(client.main_phone)}</td>
                <td>{client.source}</td>
                <td>{client.jobs}</td>
                <td>{client.appointments}</td>
                <td>{client.recalls}</td>
                <td>{client.total}</td>
                <td>{client.paid}</td>
                <td>
                  {client.unpaid}
                  {
                    appointmentData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'appointments.item',
                            nameId: 'appointmentId',
                            id: $router.router.getState().params.appointmentId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {appointmentData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: appointmentData.permissions.client_show && client.client_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  appointmentData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'appointments.item',
                    nameId: 'appointmentId',
                    id: $router.router.getState().params.appointmentId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  appointmentData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>
                  <div>{client.name}</div>
                  <div>{formatPhoneNumber(client.main_phone)}</div>
                </td>
                <td>
                  <div>{client.source}</div>
                  <div>Jobs: {client.jobs}</div>
                </td>
                <td>
                  <div>Appointments: {client.appointments}</div>
                  <div>Recalls: {client.recalls}</div>
                </td>
                <td>
                  <div>Total: {client.total}</div>
                  <div>Paid: {client.paid}</div>
                </td>
                <td>
                  <div>Unpaid: {client.unpaid}</div>
                  {
                    appointmentData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'appointments.item',
                            nameId: 'appointmentId',
                            id: $router.router.getState().params.appointmentId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">
              {appointmentData.clients.map((client, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    appointmentData.permissions.client_show &&
                    client.client_id &&
                    $router.router.navigate('clients.item', {
                      companyId: activeAccountId,
                      clientId: client.client_id,
                      fromPage: 'appointments.item',
                      nameId: 'appointmentId',
                      id: $router.router.getState().params.appointmentId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}>

                  <div className="__top">
                    <div className="__left">
                      <div><b>{client.name}</b></div>
                      <div>
                        {formatPhoneNumber(client.main_phone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{client.total}</b>
                      </div>
                      <div>
                        Paid: <b>{client.paid}</b>
                      </div>
                      <div>
                        Unpaid: <b>{client.unpaid}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Jobs:</span>
                        <span> {client.jobs}</span>
                      </div>
                      <div>
                        <span className="gray">Recalls:</span>
                        <span> {client.recalls}</span>
                      </div>
                      <div>
                        <span className="gray">Appointments:</span>
                        <span> {client.appointments}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{client.source}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                appointmentData.permissions.appointment_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Appointment</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSending}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {deleting && appointmentData.permissions.appointment_delete ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Appointment
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the appointment it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default AppointmentsPage_Item
