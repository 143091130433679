import { useState, useEffect } from "react"
import { useRoute } from "react-router5"

import BarChart from "./BarChart"

import { httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios"

import '../../styles/pages/dashboard.sass'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    permissions: {
      dashboard_weekly_orders_by_dispatchers: boolean
      dashboard_today_orders_by_dispatchers: boolean
    }
    dashboard_today_orders_by_dispatchers: TodayDispatchersProps[]
    dashboard_weekly_orders_by_dispatchers: WeeklyDispatchersProps[]
  }
}

interface WeeklyDispatchersProps {
  code: number
  dispatcher_id: string
  last_week_orders: number
  nickname: string
  this_week_orders: number
}

interface TodayDispatchersProps {
  code: string
  dispatcher_id: string
  nickname: string
  today_orders: number
}

function getScaleSize(dataForChart: { last?: number, this: number }[]) {
  let max = 50
  let setSize = 5
  dataForChart.forEach(item => {
    if (max < item.this) {
      max = item.this
    }
    if (item.last && max < item.last) {
      max = item.last
    }
  })

  if (max !== 50) {
    max = Number(`${max + (setSize * 2)}`.slice(0, -1) + '0')
  }

  if (max <= 50) {
    setSize = 5
  } else if (max > 50 && max <= 100) {
    setSize = 10
  } else {
    setSize = 20
  }

  return {
    max, setSize
  }
}

function DashboardPage() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [permissions, setPermissions] = useState({
    dashboard_weekly_orders_by_dispatchers: false,
    dashboard_today_orders_by_dispatchers: false,
  })

  const [weekDispatchersData, setWeekDispatchersData] = useState<{ labels: string[], data: { last: number, this: number }[], scales: { max: number, setSize: number } }>({
    labels: [],
    data: [],
    scales: {
      max: 50,
      setSize: 5
    }
  })

  const [dayDispatchersData, setDayDispatchersData] = useState<{ labels: string[], data: { last?: number, this: number }[], scales: { max: number, setSize: number } }>({
    labels: [],
    data: [],
    scales: {
      max: 50,
      setSize: 5
    }
  })

  async function loadData() {
    try {
      httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

      // https://2022back4.artemiudintsev.com/api/dashboards?account_id=88888
      const { data: { data: reportData, success, error } } = await httpClientUpdate.get('/dashboards', {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }

      if (success) {
        setPermissions(reportData.permissions)

        if (reportData.permissions.dashboard_weekly_orders_by_dispatchers) {
          let getSize = getScaleSize(reportData.dashboard_weekly_orders_by_dispatchers.map(item => {
            return { last: item.last_week_orders, this: item.this_week_orders }
          }))
          setWeekDispatchersData({
            labels: reportData.dashboard_weekly_orders_by_dispatchers.map(item => item.code ? `${item.nickname} (${item.code})` : item.nickname),
            data: reportData.dashboard_weekly_orders_by_dispatchers.map(item => {
              return { last: item.last_week_orders, this: item.this_week_orders }
            }),
            scales: getSize
          })
        }

        if (reportData.permissions.dashboard_today_orders_by_dispatchers) {
          let getSize = getScaleSize(reportData.dashboard_today_orders_by_dispatchers.map(item => {
            return { this: item.today_orders }
          }))
          setDayDispatchersData({
            labels: reportData.dashboard_today_orders_by_dispatchers.map(item => item.code ? `${item.nickname} (${item.code})` : item.nickname),
            data: reportData.dashboard_today_orders_by_dispatchers.map(item => {
              return { this: item.today_orders }
            }),
            scales: getSize
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="DashboardPage">
      {/* Dashboard page */}
      {
        permissions.dashboard_weekly_orders_by_dispatchers &&
        <div className="dashboard-modul">
          <div className="module-name">
            <h1>Orders by Dispatchers</h1>
          </div>

          <div className="chart">
            <BarChart
              colName="Week"
              labels={weekDispatchersData.labels}
              dataForChart={weekDispatchersData.data}
              isWeek={true}
              optionsScales={weekDispatchersData.scales}
            />
          </div>
        </div>
      }

      {
        permissions.dashboard_today_orders_by_dispatchers &&
        <div className="dashboard-modul">
          <div className="module-name">
            <h1>Orders by Dispatchers</h1>
          </div>

          <div className="chart">
            <BarChart
              colName="Today"
              labels={dayDispatchersData.labels}
              dataForChart={dayDispatchersData.data}
              isWeek={false}
              optionsScales={dayDispatchersData.scales}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default DashboardPage
