import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Select from "../../components/Select"

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { HttpNotifyReport } from './List'

import "../../styles/pages/common/entity-edit.sass"
import '../../styles/pages/sms.sass'

interface MailboxesProps {
  email: string
  mailbox_id: string
}

interface PhoneNumbersProps {
  friendly_name: string
  phone: string
  phonenumber_id: string
}

interface NotifyDataProps {
  type: string,
  notify_type: string,
  item_id: string,
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [mailboxes, setMailboxes] = useState<MailboxesProps[] | []>([])
  const [phone_numbers, setPhone_numbers] = useState<PhoneNumbersProps[] | []>([])

  const [notifyData, setNotifyData] = useState<NotifyDataProps>({
    type: '',
    notify_type: '',
    item_id: '',
  })

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  useEffect(() => {
    loadInfo()

    return (() => {
      setMailboxes([])
      setPhone_numbers([])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/notify-resources/report?account_id=88888
      const { data: { data: notifications, success, error } } = await httpClientUpdate.get('/notify-resources/report',
        {
          params: {
            account_id: activeAccountId,
            limit_rows: 1,
            page: 1,
            date_type: 'created',
            sort_field: 'service_resource',
            sort_type: 'asc',
            filter_field: JSON.stringify({})
          }
        }) as { data: HttpNotifyReport }

      if (success) {

        setMailboxes(notifications.edit.mailboxes)
        setPhone_numbers(notifications.edit.phone_numbers)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/notify-resources
      const response = await httpClientUpdate.post(`/notify-resources`, {
        account_id: activeAccountId,
        type: notifyData.type,
        notify_type: notifyData.notify_type,
        item_id: notifyData.item_id,
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('notify-resources', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Notify Resource</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('notify-resources', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Type:</span>

              <Select
                options={['phone', 'email'].map((action) => ({
                  span: action,
                  value: action
                }))}
                selectedOption={notifyData.type}
                onChange={(value) => setNotifyData({ ...notifyData, type: value as string, item_id: '' })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Notify Type:</span>

              <Select
                options={['', 'payment'].map((action) => ({
                  span: action,
                  value: action
                }))}
                selectedOption={notifyData.notify_type}
                onChange={(value) => setNotifyData({ ...notifyData, notify_type: value as string })}
              />
            </div>
          </div>

          <div className="__left">
            <div className="field">
              <span>Resource:</span>
              {
                notifyData.type === 'email' ?
                  <Select
                    options={mailboxes.map((action) => ({
                      span: action.email,
                      value: action.mailbox_id
                    }))}
                    selectedOption={notifyData.item_id}
                    onChange={(value) => setNotifyData({ ...notifyData, item_id: value as string })}
                  /> :
                  <Select
                    disabled={notifyData.type === ''}
                    options={phone_numbers.map((action) => ({
                      span: action.friendly_name ? `${formatPhoneNumber(action.phone)} (${action.friendly_name})` : formatPhoneNumber(action.phone),
                      value: action.phonenumber_id
                    }))}
                    selectedOption={notifyData.item_id}
                    onChange={(value) => setNotifyData({ ...notifyData, item_id: value as string })}
                  />
              }
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('notify-resources', {
              companyId: activeAccountId
            }, {
              reload: true
            })}
          >
            Cancel
          </button>

          <button
            className="_bordered _green"
            disabled={notifyData.item_id === '' || notifyData.notify_type === '' || notifyData.type === '' || isSaving}
            onClick={() => handleSave()}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  )
}
