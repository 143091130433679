import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import Select from '../../components/Select'
import Icon from '../../components/Icon'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  permissions: {
    technician_part_delete: boolean
    technician_part_edit: boolean
  }
  part: PartProps
  edit: {
    part_status: {
      part_status: string
      part_status_id: number
    }[]
    warehouses: {
      warehouse: string
      warehouse_id: string
    }[]
    invoices: {
      invoice_id: string
      invoice_name: string
      invoice_total: number
    }[]
    units: {
      appliance: string
      brand: string
      other_appliance: string
      other_brand: string
      unit_id: string
      unit_type: string
    }[]
  }
}

interface PartProps {
  part_id: string
  name: string
  price: string | number
  part_number: string | number
  warehouse_id: string
  warehouse: string
  other_warehouse: string
  owner: string
  status_id: number
  status: string
  job_id: string
  invoice_id: string
  invoice_name: string
  invoice_total: number
  unit_id: string | null
  unit_name: string | null
}

interface PartsEditProps {
  switchPage: (name: string) => void
  id: string
}

export default function PartsEdit({ switchPage, id }: PartsEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [editing, setEditing] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [data, setData] = useState<DataProps | null>(null)

  const [newData, setNewData] = useState<Partial<PartProps>>({})

  async function loadParts() {
    try {
      const { data: { data: partsData, success, error } } = await httpClientUpdate.get(`/technician/parts/${id}`, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setData(partsData)
        setNewData({
          ...partsData.part,
          price: +partsData.part.price
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadParts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/parts/${id}`, {
      data: {
        account_id: activeAccountId,
      }
    })
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleCancelEdit() {
    data && setNewData({
      ...data.part,
      price: +data.part.price
    })
    setEditing(false)
  }

  async function handleSave() {
    const response = await httpClientUpdate.put(`/technician/parts/${id}`, qs.stringify({
      account_id: activeAccountId,
      status_id: newData.status_id,
      price: newData.price,
      part_number: newData.part_number,
      name: newData.name,
      warehouse_id: newData.warehouse_id,
      other_warehouse: newData.other_warehouse,
      job_id: newData.job_id,
      owner: newData.owner,
      invoice_id: newData.invoice_id,
      unit_id: newData.unit_id
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChangePrice(event: React.SyntheticEvent<EventTarget>) {
    let price = (event.target as HTMLInputElement).value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewData({
        ...newData,
        price: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function checkOtherField() {
    let warehouses = data?.edit.warehouses.filter(item => item.warehouse_id === newData.warehouse_id)
    return warehouses?.length === 1 ? warehouses[0].warehouse : ''
  }

  return (
    <>
      {
        data ?
          <div className="EstimatePage_Edit entity-edit">
            <div className="wrapper flex-container sb">
              <div className="flex-container _gap-narrow">
                <h1>Part: </h1>
                <div className="item-name">
                  {newData.name}
                </div>
              </div>

              <div style={{ display: 'flex' }} >
                <button
                  disabled={editing}
                  style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  onClick={() => switchPage('main')}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>

                {
                  !editing && data.part.owner !== 'Company' &&
                    data.permissions.technician_part_edit ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      <Icon
                        style={{ width: '16px', height: '16px' }}
                        viewBox="0 0 16 16"
                        icon="pencil-1"
                      />
                    </button>
                  ) : null
                }
              </div>
            </div>

            <div className="fieldset">
              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Status:</span>
                    {
                      !!data.edit.part_status.length ?
                        <Select
                          disabled={!editing}
                          options={data.edit.part_status.sort((a, b) => a.part_status.localeCompare(b.part_status)).map((option) => ({
                            span: option.part_status,
                            value: option.part_status_id
                          }))}
                          selectedOption={newData.status_id as number}
                          onChange={(value) => setNewData({ ...newData, status_id: value as number })}
                        /> :
                        <input
                          type="number"
                          defaultValue={newData.status}
                          disabled={true}
                          onChange={({ target: { value } }) => setNewData({ ...newData, status: value })}
                        />
                    }
                  </div>

                  <div className="field">
                    <span>Part Number:</span>
                    <input
                      type="number"
                      defaultValue={newData.part_number}
                      disabled={!editing}
                      onChange={({ target: { value } }) => setNewData({ ...newData, part_number: value })}
                    />
                  </div>

                  <div className="field">
                    <span>Name:</span>
                    <input
                      type="text"
                      defaultValue={newData.name}
                      disabled={!editing}
                      onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                    />
                  </div>

                  <div className="field">
                    <span>Invoice:</span>
                    <Select
                      disabled={!editing}
                      options={data.edit.invoices.map((option) => ({
                        span: `[${option.invoice_name}, ${option.invoice_total}]`,
                        value: option.invoice_id
                      }))}
                      selectedOption={newData.invoice_id as string}
                      onChange={(value) => setNewData({ ...newData, invoice_id: value as string })}
                    />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Price:</span>
                    <input
                      type="number"
                      pattern="[0-9]*"
                      disabled={!editing}
                      style={{ textAlign: 'right' }}
                      value={newData?.price && typeof newData?.price === 'number' ? newData.price.toFixed(2) : '0.00'}
                      onFocus={(event) => handleFocus(event)}
                      onChange={(event) => handleChangePrice(event)}
                      onKeyDown={handleChangePriceBackspace}
                    />
                  </div>

                  <div className="field">
                    <span>Warehouse:</span>
                    {
                      !editing && !!data.edit.warehouses.filter(item => item.warehouse === 'Other' && item.warehouse_id === newData.warehouse_id).length ?
                        <input
                          type="text"
                          disabled={!editing}
                          defaultValue={newData.other_warehouse}
                          onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                        /> :
                        <Select
                          disabled={!editing}
                          options={data.edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                            span: option.warehouse,
                            value: option.warehouse_id
                          }))}
                          selectedOption={newData.warehouse_id as string}
                          onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
                        />
                    }

                  </div>
                  {
                    editing && checkOtherField() === "Other" &&
                    <div className="field">
                      <span>Other Warehouse:</span>

                      <input
                        type="text"
                        disabled={!editing}
                        defaultValue={newData.other_warehouse}
                        onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                      />
                    </div>
                  }

                  <div className="field">
                    <span>Part`s Owner:</span>
                    <Select
                      disabled={!editing}
                      options={['Technician', 'Company'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newData.owner as string}
                      onChange={(value) => setNewData({ ...newData, owner: value as string })}
                    />
                  </div>

                  <div className="field">
                    <span>Unit:</span>
                    <Select
                      disabled={!editing}
                      options={data.edit.units.map((option) => ({
                        span: `${option.unit_type}: ${option.appliance === 'Other' ? option.other_appliance : option.appliance}, ${option.brand === 'Other' ? option.other_brand : option.brand}`,
                        value: option.unit_id
                      }))}
                      selectedOption={newData.unit_id as string}
                      onChange={(value) => setNewData({ ...newData, unit_id: value as string })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.technician_part_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Part</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Part
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the part it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div> : null
      }
    </>
  )
}
