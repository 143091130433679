import { useEffect, useState, memo } from 'react'

import { TimeObjectProps } from '../pages/Jobs/ItemTech';

function TimeHasPassed({
  start_hours,
  start_minutes,
  start_seconds,
}: TimeObjectProps) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    setSeconds(start_seconds)
    setMinutes(start_minutes)
    setHours(start_hours)
  }, [start_seconds, start_minutes, start_hours])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds < 59) {
        setSeconds(seconds + 1);
      }
      if (seconds === 59) {
        if (minutes === 59) {
          setMinutes(0)
          setHours(hours + 1)
        } else {
          setMinutes(minutes + 1);
          setSeconds(0);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  function getTime() {
    let time = ''

    if (!!hours) {
      if (hours < 10) {
        time = `0${hours}:`
      } else {
        time = `${hours}:`
      }
    }

    if (minutes < 10) {
      time = `${time}0${minutes}:`
    } else {
      time = `${time}${minutes}:`
    }

    if (seconds < 10) {
      time = `${time}0${seconds}`
    } else {
      time = `${time}${seconds}`
    }

    return time
  }
  return (
    <div> {getTime()} </div>
  )
}

export default memo(TimeHasPassed)