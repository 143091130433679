import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRoute } from 'react-router5'
import qs from "qs";
import classNames from "classnames"
import emojiRegex from 'emoji-regex'
import { DateTime } from 'luxon';

// import EmojyPicker from './EmojyPicker'
import EmojyPicker from './PickerComponent';
import Icon from '../../components/Icon'
import EmojisRow from './EmojisRow';
import Editor from './Editor';
import EditText from './EditText';
import EditorReply from './EditorReply';
import AudioPlayer from '../../components/AudioPlayer';
import ModalImage from '../Sms/ModalImage';

import { dateToInfoBlock, formatPhoneNumber, getSchuduleTime, httpClientUpdate, nErrorUpdate, screenSizeWidth, useOuterClick } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { AppointmentsProps, CallsProps, JobsProps, TicketsProps } from './ClipPopup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import './Channel.sass'
import '../../styles/pages/sms.sass'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ChatReport
}

interface ChatReport {

  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  chat_messages: ChatMessages[]
  channel_user: ChannelUser
  edit: {
    users: EditUsersProps[]
  }
}

export interface EditUsersProps {
  channel_user_id: string
  user_name: string
}

interface ChannelUser {
  can_delete: boolean
  is_read_only: boolean
  time_for_edit: number
  channel_user_id: string
  user_name: string
}

interface ChatMessages {
  channel_name: string
  created_at: string
  is_read: boolean
  message: string
  message_id: string
  user_name: string
  emojis: EmojiProps[]
  documents: DocumentsProps[]
  child_messages_count: number
  attachments: AttachmentsProps[]
}

export interface AttachmentsProps {
  chat_message_id: string
  url: string
  details?: File
}

interface DocumentsProps {
  can_see_details: boolean
  document_details: JobsProps | CallsProps | TicketsProps | AppointmentsProps
  document_id: string
  document_type: string
  message_id: string
}

export interface EmojiProps {
  channel_user_id: string
  created_at: string
  emoji: string
  user_name: string
  message_emoji_id: string
}

export interface SelectedDocumentsProps {
  document_id: string
  document_type: string
  name: string
  details: JobsProps | CallsProps | TicketsProps | AppointmentsProps
}

export default function ChannelNew({ updated }: { updated: number }) {
  const $router = useRoute()

  const regex = emojiRegex();

  const mainContentRef = useRef(null)
  const textAreaMain = useRef(null)
  const textAreaRaply = useRef(null)

  const textEditRef = useRef(null)
  const textEditReplyRef = useRef(null)

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const time_zone = user?.time_zone

  const width = screenSizeWidth()

  const [callListenPopup, setCallListenPopup] = useState<string | null>(null)

  const [channelName, setChannelName] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [dragingMain, setDragingMain] = useState(false)
  const [dragingReply, setDragingReply] = useState(false)

  const [usersOption, setUsersOption] = useState<EditUsersProps[]>([])
  const [filterUser, setFilterUser] = useState('')

  const [permissions, setPermissions] = useState({
    can_delete: false,
    is_read_only: false,
    time_for_edit: 0,
    channel_user_id: '',
    user_name: '',
  })

  const [chatReport, setChatReport] = useState<ChatMessages[]>([])
  const [chatReplyReport, setChatReplyReport] = useState<ChatMessages[] | null>(null)
  const [selectedMessage, setSelectedMessage] = useState<ChatMessages | null>(null)

  const [updatedHeight, setUpdatedHeight] = useState(Math.random())

  const [mainText, setMainText] = useState('')
  const [replyText, setReplyText] = useState('')
  const [editText, setEditText] = useState('')

  const [updatedText, setUpdatedText] = useState(Math.random())

  const [editMainMessage, setEditMainMessage] = useState('')

  const [openChannelUsers, setOpenChannelUsers] = useState(false)
  const [openMenuChannelUsers, setOpenMenuChannelUsers] = useState(false)

  const [openReplyChannelUsers, setOpenReplyChannelUsers] = useState(false)
  const [openMenuReplyChannelUsers, setOpenMenuReplyChannelUsers] = useState(false)

  const [openClipMain, setOpenClipMain] = useState(false)
  const [openClipEditMain, setOpenClipEditMain] = useState(false)
  const [openClipReply, setOpenClipReply] = useState(false)

  const [showMainEmojiPikerReaction, setShowMainEmojiPikerReaction] = useState('')
  const [showReplyEmojiPikerReaction, setShowReplyEmojiPikerReaction] = useState('')
  const [showMainEmojiPiker, setShowMainEmojiPiker] = useState(false)
  const [showReplyEmojiPiker, setShowReplyEmojiPiker] = useState(false)
  const [showMainMenu, setShowMainMenu] = useState('')
  const [showAdditionalyMenu, setShowAdditionalyMenu] = useState('')
  const [showReplyMenu, setShowReplyMenu] = useState('')
  const [showReply, setShowReply] = useState(false)

  const [selectedImage, setSelectedImage] = useState({
    url: '',
    type: ''
  })

  const [openModal, setOpenModal] = useState(false)

  const [selectedDocuments, setSelectedDocuments] = useState<SelectedDocumentsProps[]>([])
  const [selectedEditDocuments, setSelectedEditDocuments] = useState<SelectedDocumentsProps[]>([])
  const [selectedEditAttachments, setSelectedEditAttachments] = useState<AttachmentsProps[]>([])
  const [selectedReplyDocuments, setSelectedReplyDocuments] = useState<SelectedDocumentsProps[]>([])

  useEffect(() => {
    setSelectedReplyDocuments([])
  }, [selectedMessage])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    setUpdatedHeight(Math.random())
    if (width < 920) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  // Load info function
  async function loadChannel(need_updated?: boolean) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/report
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/chats/channels/messages/report', {
        params: {
          account_id: activeAccountId,
          channel_id: $router.router.getState().params.channelId,
          page: 1,
          limit_rows: 500,
        }
      }) as { data: HttpReport }
      if (success) {
        setChatReport(loadData.chat_messages)
        setPermissions(loadData.channel_user)
        setUsersOption(loadData.edit.users)

        loadData.chat_messages.forEach(item => {
          if (channelName !== item.channel_name) {
            setChannelName(item.channel_name)
          }
        })
        if (selectedMessage) {
          loadData.chat_messages.forEach(item => {
            if (item.message_id === selectedMessage.message_id) {
              setSelectedMessage(item)
            }
          })
        }
        if (need_updated) {
          setUpdatedHeight(Math.random())
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadChannel()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (updated) {
      setShowReply(false)
      setChatReplyReport(null)
      setSelectedMessage(null)
      setChatReport([])
      loadChannel()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    if (seconds === 0 && editMainMessage !== '') {
      setUpdatedHeight(Math.random())
    }
    if (editMainMessage === '') {
      setUpdatedHeight(Math.random())
    }
  }, [seconds, editMainMessage])

  useEffect(() => {
    if (editMainMessage) {
      chatReport.forEach(item => {
        if (item.message_id === editMainMessage) {
          if (item.documents && item.documents.length) {
            setSelectedEditDocuments(item.documents.map(doc => {
              return {
                document_id: doc.document_id,
                document_type: doc.document_type,
                name: 'Name',
                details: doc.document_details,
              }
            }))
          } else if (item.attachments && item.attachments.length) {
            setSelectedEditAttachments(item.attachments)
          }
        }
      })
      chatReplyReport && chatReplyReport.forEach(item => {
        if (item.message_id === editMainMessage) {
          if (item.documents && item.documents.length) {
            setSelectedEditDocuments(item.documents.map(doc => {
              return {
                document_id: doc.document_id,
                document_type: doc.document_type,
                name: 'Name',
                details: doc.document_details,
              }
            }))
          } else if (item.attachments && item.attachments.length) {
            setSelectedEditAttachments(item.attachments)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMainMessage])

  useEffect(() => {
    if (mainContentRef.current) {
      // @ts-ignore
      mainContentRef.current.scrollTop = mainContentRef.current.scrollHeight
    }
  }, [mainContentRef, chatReport.length, updatedHeight])

  async function addReaction(message_id: string, emoji: string) {
    try {
      const addEmoji = parseInt(emoji, 16)

      chatReport.forEach(async (item, i) => {
        if (item.message_id === message_id) {
          const emojis = item.emojis.filter(item => getUserId(item.user_name) === permissions.channel_user_id).map(item => item.emoji)

          if (emojis.includes(`&#${addEmoji}`)) {
            let id = ''
            item.emojis.forEach(em => {
              if (em.emoji === `&#${addEmoji}`) {
                id = em.message_emoji_id
              }
            })

            removeReaction(item.message_id, id)
            setShowMainEmojiPikerReaction('')
          } else {
            // https://2022back4.artemiudintsev.com/api/chats/channels/messages/emojis
            const response = await httpClientUpdate.post('/chats/channels/messages/emojis', qs.stringify({
              account_id: activeAccountId,
              message_id: message_id,
              emoji: `&#${addEmoji}`,
            }))

            if (response.data.success) {
              setShowMainEmojiPikerReaction('')
              loadChannel(i === chatReport.length - 1)
            }
          }
        }
      })
    } catch (error) { }
  }

  async function addReplyReaction(message_id: string, emoji: string) {
    try {
      if (chatReplyReport) {
        const addEmoji = parseInt(emoji, 16)

        chatReplyReport.forEach(async (item) => {
          if (item.message_id === message_id) {
            const emojis = item.emojis.filter(item => getUserId(item.user_name) === permissions.channel_user_id).map(item => item.emoji)

            if (emojis.includes(`&#${addEmoji}`)) {
              let id = ''
              item.emojis.forEach(em => {
                if (em.emoji === `&#${addEmoji}`) {
                  id = em.message_emoji_id
                }
              })

              removeReaction(item.message_id, id)
              setShowReplyEmojiPikerReaction('')
            } else {
              // https://2022back4.artemiudintsev.com/api/chats/channels/messages/emojis
              const response = await httpClientUpdate.post('/chats/channels/messages/emojis', qs.stringify({
                account_id: activeAccountId,
                parent_message_id: selectedMessage?.message_id,
                message_id: message_id,
                emoji: `&#${addEmoji}`,
              }))

              if (response.data.success) {
                setShowReplyEmojiPikerReaction('')
                loadChannel()
                selectedMessage && getThreadMessage(selectedMessage.message_id)
              }
            }
          }
        })
      }
    } catch (error) { }
  }

  async function addSameReaction(message_id: string, emoji: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/emojis

      const response = await httpClientUpdate.post('/chats/channels/messages/emojis', qs.stringify({
        account_id: activeAccountId,
        message_id: message_id,
        emoji: emoji,
      }))

      if (response.data.success) {
        setShowMainEmojiPikerReaction('')
        loadChannel()
      }
    } catch (error) { }
  }

  async function addSameReplyReaction(message_id: string, emoji: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/emojis

      const response = await httpClientUpdate.post('/chats/channels/messages/emojis', qs.stringify({
        account_id: activeAccountId,
        parent_message_id: selectedMessage?.message_id,
        message_id: message_id,
        emoji: emoji,
      }))

      if (response.data.success) {
        setShowMainEmojiPikerReaction('')
        loadChannel()
        selectedMessage && getThreadMessage(selectedMessage.message_id)
      }
    } catch (error) { }
  }

  async function removeReplyReaction(message_id: string, id: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/emojis/{CHAT_MESSAGE_EMOJI_ID}
      const response = await httpClientUpdate.delete(`/chats/channels/messages/emojis/${id}`, {
        data: {
          account_id: activeAccountId,
          parent_message_id: selectedMessage?.message_id,
          message_id: message_id,
        }
      })

      if (response.data.success) {
        loadChannel()
        selectedMessage && getThreadMessage(selectedMessage.message_id)
      }
    } catch (error) { }
  }

  async function removeReaction(message_id: string, id: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/emojis/{CHAT_MESSAGE_EMOJI_ID}
      const response = await httpClientUpdate.delete(`/chats/channels/messages/emojis/${id}`, {
        data: {
          account_id: activeAccountId,
          message_id: message_id,
        }
      })

      if (response.data.success) {
        loadChannel()
      }
    } catch (error) { }
  }

  async function sendMessage() {
    try {
      const formData = new FormData();

      let usersIds: string[] = []
      let documents: SelectedDocumentsProps[] = []
      //@ts-ignore
      let text = textAreaMain.current?.editingArea?.innerText

      usersOption.forEach(item => {
        let checkUser = text.match(`@${item.user_name}`)

        if (checkUser) {
          usersIds.push(item.channel_user_id)
        }
      })

      if (selectedDocuments.filter(item => item.document_id !== 'custom').length) {
        documents = selectedDocuments.filter(item => item.document_id !== 'custom')

        documents.forEach((item, index) => {
          formData.append(
            `documents[${index}][document_id]`,
            item.document_id
          );
          formData.append(
            `documents[${index}][document_type]`,
            item.document_type
          );
        })
      }

      if (selectedDocuments.filter(item => item.document_id === 'custom').length) {
        selectedDocuments.filter(item => item.document_id === 'custom').forEach(item => {

          formData.append(
            `attachments[]`,
            //@ts-ignore
            item.details,
            item.name
          );
        })
      }

      activeAccountId && formData.append('account_id', activeAccountId);
      formData.append('channel_id', $router.router.getState().params.channelId);
      //@ts-ignore
      formData.append('message', emojiInDex(textAreaMain.current.value));

      if (usersIds.length) {
        usersIds.forEach(item => {
          formData.append(
            `channel_users[]`,
            item,
          );
        })
      }

      // https://2022back4.artemiudintsev.com/api/chats/channels/messages
      const response = await httpClientUpdate.post('/chats/channels/messages',
        formData,
      )

      if (response.data.success) {
        setMainText('')
        setSelectedDocuments([])
        setUpdatedText(Math.random())
        loadChannel()
      }
    } catch (error) { }
  }

  function emojiInDex(text: string) {

    let updatedText = text.replace(regex, (m, idx) => {
      const emoji = m.codePointAt(0)

      return `&#${emoji}`;
    })

    return updatedText
  }

  async function updatedMessage(is_reply: boolean, text: string) {
    try {
      const formData = new FormData();

      if (selectedEditDocuments.length) {
        selectedEditDocuments.forEach((item, index) => {
          formData.append(
            `documents[${index}][document_id]`,
            item.document_id
          );
          formData.append(
            `documents[${index}][document_type]`,
            item.document_type
          );
        })
      } else {
        formData.append(
          `documents[]`,
          JSON.stringify([])
        )
      }

      if (selectedEditAttachments.length) {
        selectedEditAttachments.forEach((item, index) => {
          if (item.details) {
            formData.append(
              `attachments[]`,
              //@ts-ignore
              item.details,
            );
          } else {
            formData.append(
              `attachments[${index}][chat_message_id]`,
              item.chat_message_id,
            );
            formData.append(
              `attachments[${index}][url]`,
              item.url,
            );
          }
        })
      } else {
        formData.append(
          `attachments[]`,
          JSON.stringify([]),
        );
      }

      activeAccountId && formData.append('account_id', activeAccountId);
      formData.append('channel_id', $router.router.getState().params.channelId);
      //@ts-ignore
      formData.append('message', text);

      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/{MESSAGE_ID}
      const response = await httpClientUpdate.put(`/chats/channels/messages/${editMainMessage}`,
        formData
        // {
        //   account_id: activeAccountId,
        //   channel_id: $router.router.getState().params.channelId,
        //   message: text,
        //   documents: selectedEditDocuments.length ? selectedEditDocuments.map(item => {
        //     return {
        //       document_id: item.document_id,
        //       document_type: item.document_type,
        //     }
        //   }) : []
        // }
      )

      if (response.data.success) {
        setEditText('')
        setEditMainMessage('')
        setSelectedEditDocuments([])
        setSelectedEditAttachments([])
        selectedMessage?.message_id && getThreadMessage(selectedMessage.message_id)
        loadChannel()
      }
    } catch (error) { }
  }

  async function deleteMessage(id: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/{MESSAGE_ID}
      const response = await httpClientUpdate.delete(`/chats/channels/messages/${id}`, {
        data: {
          account_id: activeAccountId,
          channel_id: $router.router.getState().params.channelId,
        }
      })

      if (response.data.success) {
        loadChannel()
        if (selectedMessage && selectedMessage.message_id === id) {
          setSelectedMessage(null)
          setShowReply(false)
          setChatReplyReport([])
        }
      }
    } catch (error) { }
  }

  async function deleteReplyMessage(id: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/{MESSAGE_ID}
      const response = await httpClientUpdate.delete(`/chats/channels/messages/${id}`, {
        data: {
          account_id: activeAccountId,
          channel_id: $router.router.getState().params.channelId,
          parent_message_id: selectedMessage?.message_id
        }
      })

      if (response.data.success) {
        loadChannel()
        selectedMessage?.message_id && getThreadMessage(selectedMessage.message_id)
      }
    } catch (error) { }
  }

  async function getThreadMessage(id: string) {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/report
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/chats/channels/messages/report', {
        params: {
          account_id: activeAccountId,
          channel_id: $router.router.getState().params.channelId,
          page: 1,
          limit_rows: 500,
          parent_message_id: id
        }
      }) as { data: HttpReport }
      if (success) {
        setShowReply(true)
        setChatReplyReport(loadData.chat_messages)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error) { }
  }

  async function sendReplyMessage() {
    try {
      const formData = new FormData();

      let usersIds: string[] = []
      let documents: SelectedDocumentsProps[] = []
      //@ts-ignore
      let text = textAreaRaply.current?.editingArea?.innerText

      usersOption.forEach(item => {
        let checkUser = text.match(`@${item.user_name}`)

        if (checkUser) {
          usersIds.push(item.channel_user_id)
        }
      })

      if (selectedReplyDocuments.filter(item => item.document_id !== 'custom').length) {
        documents = selectedReplyDocuments.filter(item => item.document_id !== 'custom')

        documents.forEach((item, index) => {
          formData.append(
            `documents[${index}][document_id]`,
            item.document_id
          );
          formData.append(
            `documents[${index}][document_type]`,
            item.document_type
          );
        })
      }

      if (selectedReplyDocuments.filter(item => item.document_id === 'custom').length) {
        selectedReplyDocuments.filter(item => item.document_id === 'custom').forEach(item => {

          formData.append(
            `attachments[]`,
            //@ts-ignore
            item.details,
            item.name
          );
        })
      }

      activeAccountId && formData.append('account_id', activeAccountId);
      formData.append('channel_id', $router.router.getState().params.channelId);
      //@ts-ignore
      formData.append('message', emojiInDex(textAreaRaply.current.value));
      selectedMessage && formData.append('parent_message_id', selectedMessage.message_id);

      if (usersIds.length) {
        usersIds.forEach(item => {
          formData.append(
            `channel_users[]`,
            item,
          );
        })
      }

      if (selectedMessage) {
        // https://2022back4.artemiudintsev.com/api/chats/channels/messages
        const response = await httpClientUpdate.post('/chats/channels/messages', formData)

        if (response.data.success) {
          setReplyText('')
          getThreadMessage(selectedMessage.message_id)
          setUpdatedText(Math.random())
          setSelectedReplyDocuments([])
          loadChannel()
        }
      }
    } catch (error) { }
  }

  async function readMessages(is_main: boolean, index: number) {
    try {
      const ids: string[] = []

      if (is_main) {
        chatReport.forEach((item, idx) => {
          if (idx <= index && !item.is_read) {
            ids.push(item.message_id)
          }
        })
      } else {
        chatReplyReport?.forEach((item, idx) => {
          if (idx <= index && !item.is_read) {
            ids.push(item.message_id)
          }
        })
      }

      // https://2022back4.artemiudintsev.com/api/chats/channels/messages/see
      const response = await httpClientUpdate.post('/chats/channels/messages/see', qs.stringify({
        account_id: activeAccountId,
        messages: ids
      }))

      if (response.data.success) {
        if (is_main) {
          loadChannel()
        } else {
          selectedMessage?.message_id && getThreadMessage(selectedMessage.message_id)
        }
      }
    } catch (error) { }
  }

  function handleAddEmojiInTextReply(emoji: string) {
    try {
      if (textAreaRaply.current) {
        //@ts-ignore
        let index = textAreaRaply.current.selectionStart
        if (index !== 0 && index !== replyText.length) {
          const startText = replyText.slice(0, index)
          const endText = replyText.slice(index)

          setMainText(`${startText}${emoji}${endText}`)
        } else {
          setReplyText(`${replyText}${emoji}`)
        }
      } else {
        setReplyText(`${replyText}${emoji}`)
      }


    } catch (error) { }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowAdditionalyMenu('')
  });

  function getTimeForEnd(time: string) {
    let can_edit = false

    const date1 = DateTime.now()
    const date2 = DateTime.fromISO(time)

    const diff = date1.diff(date2, ["years", "months", "days", "hours", "minutes", "seconds"])

    if (diff.years === 0 && diff.months === 0 && diff.days === 0 && diff.hours === 0 && diff.minutes === 0) {
      if (diff.seconds < permissions.time_for_edit) {
        // seconds === 0 && setSeconds(Math.round(permissions.time_for_edit - diff.seconds))
        seconds !== Math.round(permissions.time_for_edit - diff.seconds) && setSeconds(Math.round(permissions.time_for_edit - diff.seconds))
        can_edit = true
      } else {
        can_edit = false
        !!seconds && setSeconds(0)
      }
    } else {
      can_edit = false
      !!seconds && setSeconds(0)
    }

    return can_edit
  }

  function getUserId(name: string) {
    let id = ''

    usersOption.forEach(item => {
      if (item.user_name === name) {
        id = item.channel_user_id
      }
    })

    return id
  }

  function handleClickPopupMenu(event: any, id: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    setShowAdditionalyMenu(id)
    if (index === chatReport.length - 1) {
      setUpdatedHeight(Math.random())
    }
  }

  const onDragOver = useCallback((e, name: string) => {
    if (name === 'main') {
      setDragingMain(true)
    } else if (name === 'reply') {
      setDragingReply(true)
    } else if (name === 'edit') {
      setDragingMain(true)
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e, name: string) => {
    if (name === 'main') {
      setDragingMain(false)
    } else if (name === 'reply') {
      setDragingReply(false)
    } else if (name === 'edit') {
      setDragingMain(false)
    }

    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e, name: string) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (name === 'main') {
      const updated = selectedDocuments.map(item => item)
      updated.push({
        document_id: 'custom',
        document_type: file.type,
        name: file.name,
        details: file,
      })
      setSelectedDocuments(updated)
      setDragingMain(false);
    } else if (name === 'reply') {
      const updated = selectedReplyDocuments.map(item => item)
      updated.push({
        document_id: 'custom',
        document_type: file.type,
        name: file.name,
        details: file,
      })
      setSelectedReplyDocuments(updated)
      setDragingReply(false)
    } else if (name === 'edit') {
      const updated = selectedEditAttachments.map(item => item)
      updated.push({
        chat_message_id: '',
        url: '',
        details: file
      })
      setSelectedEditAttachments(updated)
      setDragingReply(false)
    } else if (name === 'edit_reply') {
      const updated = selectedEditAttachments.map(item => item)
      updated.push({
        chat_message_id: '',
        url: '',
        details: file
      })
      setSelectedEditAttachments(updated)
      setDragingReply(false)
    }

    return false;
  }, [selectedDocuments, selectedReplyDocuments, selectedEditAttachments]);

  function getHeight(documents: SelectedDocumentsProps[]) {
    let height = 0

    if (documents.filter(item => item.document_id !== 'custom').length) {
      height = (documents.length * 21) + 142
    } else {
      height = 122
    }

    if (documents.filter(item => item.document_id === 'custom').length) {
      height = height + 70
    }

    return `${height}px`
  }

  function getHeightWithOutPx(documents: SelectedDocumentsProps[]) {
    let height = 0

    if (documents.filter(item => item.document_id !== 'custom').length) {
      height = (documents.length * 21) + 142
    } else {
      height = 122
    }

    if (documents.filter(item => item.document_id === 'custom').length) {
      height = height + 70
    }

    return height
  }

  function handleOpenModal(url: string) {
    setSelectedImage({
      url: url,
      type: 'image'
    })
    setOpenModal(true)
  }

  const downloadImage = (url: string) => {
    const name = url.split('/')[url.split('/').length - 1]

    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    img.src = url;
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx && ctx.drawImage(img, 0, 0);
      const a = document.createElement('a');
      a.download = name;
      a.href = canvas.toDataURL('image/png');
      a.click();
    };
  }

  function getAttachmentName(url: string) {
    let name = ''

    const splitUrl = url.split('/')

    if (splitUrl.length) {
      name = splitUrl[splitUrl.length - 1]
    }

    return name
  }

  function calcMainHeaight() {
    let headerHeight = 61
    let height = 0

    if (document.getElementsByTagName("header").length) {
      headerHeight = document.getElementsByTagName("header")[0].offsetHeight
    }

    if (permissions.is_read_only) {
      height = headerHeight + 50
    } else {
      if (selectedDocuments.length) {
        const messageBlockHeight = getHeightWithOutPx(selectedDocuments)

        height = headerHeight + 50 + messageBlockHeight + 10

      } else {
        if (editMainMessage === '' || seconds === 0 || !chatReport.filter(item => item.message_id === editMainMessage).length) {
          height = headerHeight + 50 + 132
        } else {
          height = headerHeight + 50
        }
      }
    }

    return `calc(100vh - ${height}px)`
  }

  return (
    <div className='channel Mail_Item'>
      {
        isMobile ?
          <React.Fragment>
            {
              showReply ?
                <React.Fragment>
                  {
                    selectedMessage && chatReplyReport &&
                    <div
                      style={{ width: '100%' }}
                      className='channel-reply'
                      onDragLeave={(event) => onDragLeave(event, 'reply')}
                      onDragOver={(event) => onDragOver(event, 'reply')}
                    >
                      <div className='channel-main-title'>
                        <span> Thread </span>

                        <button
                          onClick={() => {
                            setShowReply(false)
                            setChatReplyReport(null)
                            setSelectedMessage(null)
                            setUpdatedHeight(Math.random())
                          }}
                        >
                          <Icon icon="x-mark-1" />
                        </button>
                      </div>

                      <div className='main-content'>
                        <div className='channel-main-items'>
                          <div className='item-date'>
                            <span className='name'> {selectedMessage.user_name} </span>
                            <span className='date'> {dateToInfoBlock('hh:mma', time_zone, selectedMessage.created_at)} </span>
                          </div>

                          <div
                            className='item-message'
                            dangerouslySetInnerHTML={{ __html: `${selectedMessage.message}` }}
                          ></div>

                          {
                            !!selectedMessage.emojis.length &&
                            <EmojisRow
                              emojis={selectedMessage.emojis}
                              getUserId={getUserId}
                              permissions={permissions}
                              message_id={selectedMessage.message_id}
                              removeReaction={removeReaction}
                              addSameReaction={addSameReaction}
                              setShowMainEmojiPikerReaction={setShowMainEmojiPikerReaction}
                            />
                          }
                        </div>

                        {
                          chatReplyReport.length === 0 &&
                          <div style={{ marginBottom: '25px' }}></div>
                        }

                        {
                          chatReplyReport.map((item, index) => (
                            <React.Fragment key={item.message_id}>
                              {
                                index === 0 ?
                                  <div className='date-line'>
                                    <span className='line'></span>
                                    <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                                    <span className='line'></span>
                                  </div> :
                                  dateToInfoBlock('MM/dd/yyyy', time_zone, item.created_at) !== dateToInfoBlock('MM/dd/yyyy', time_zone, chatReplyReport[index - 1].created_at) ?
                                    <div className='date-line'>
                                      <span className='line'></span>
                                      <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                                      <span className='line'></span>
                                    </div> : null
                              }

                              <div
                                className='channel-main-items'
                                onClick={() => {
                                  !item.is_read && readMessages(false, index)
                                }}
                                style={{ marginBottom: index === chatReplyReport.length - 1 ? '25px' : '0' }}
                                onMouseOver={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowReplyMenu(item.message_id)}
                                onMouseLeave={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowReplyMenu('')}
                              >
                                <div className='item-date'>
                                  <span className='name'> {item.user_name} </span>
                                  <span className='date'> {dateToInfoBlock('hh:mma', time_zone, item.created_at)} </span>
                                  {
                                    !item.is_read &&
                                    <span className='is_read'> New </span>
                                  }
                                </div>

                                {
                                  editMainMessage && editMainMessage === item.message_id ?
                                    <div
                                      className='item-message'
                                      onDrop={(event) => {
                                        dragingReply && onDrop(event, 'edit_reply')
                                      }}
                                    >
                                      {
                                        dragingReply ?
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              width: '100%',
                                              height: '100%'
                                            }}
                                          >
                                            Upload a File
                                          </div> :
                                          <EditText
                                            editText={editText}
                                            setEditText={setEditText}
                                            setEditMainMessage={setEditMainMessage}
                                            seconds={seconds}
                                            updatedMessage={updatedMessage}
                                            usersOption={usersOption}
                                            channel_user_id={permissions.channel_user_id}
                                            openClip={openClipEditMain}
                                            setOpenClip={setOpenClipEditMain}
                                            selectedDocuments={selectedEditDocuments}
                                            setSelectedDocuments={setSelectedEditDocuments}
                                            setUpdatedHeight={setUpdatedHeight}
                                            selectedEditAttachments={selectedEditAttachments}
                                            setSelectedEditAttachments={setSelectedEditAttachments}
                                          />
                                      }
                                    </div> :
                                    <div
                                      className='item-message'
                                      ref={textEditReplyRef}
                                      dangerouslySetInnerHTML={{ __html: `${item.message}` }}
                                    ></div>
                                }

                                {
                                  !!item.attachments.length && editMainMessage !== item.message_id &&
                                  <div className='attachment-row'>
                                    {
                                      item.attachments.map((attachment, idx) => (
                                        <img
                                          key={idx}
                                          src={attachment.url}
                                          alt="Attachment"
                                        />
                                      ))
                                    }
                                  </div>
                                }

                                {
                                  !!item.documents.length && editMainMessage !== item.message_id &&
                                  <div className='documents-table'>
                                    <table className='table'>
                                      <tbody>
                                        <tr style={{ height: 0 }}></tr>
                                        {
                                          item.documents.map(item => {
                                            if (item.document_type === 'appointment') {
                                              return (
                                                <tr key={item.document_id}>
                                                  <td>
                                                    <strong>{item.document_type}</strong>
                                                  </td>
                                                  <td
                                                    onClick={() => {
                                                      window.open(`${window.location.origin}/${activeAccountId}/appointments/${(item.document_details as AppointmentsProps).appointment_id}`, "_blank", 'noopener,noreferrer')
                                                    }}
                                                  >
                                                    <div className='doc-open'>
                                                      <Icon icon="magnifier-5" />
                                                    </div>
                                                  </td>
                                                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as AppointmentsProps).created_at)}</td>
                                                  <td>{(item.document_details as AppointmentsProps).name}</td>
                                                  <td>
                                                    {(item.document_details as AppointmentsProps).type === 'Recall' ? (
                                                      <span className="red">
                                                        {(item.document_details as AppointmentsProps).type}
                                                      </span>
                                                    ) : (item.document_details as AppointmentsProps).type === 'Service call' ? (
                                                      <span className="green">
                                                        {(item.document_details as AppointmentsProps).type}
                                                      </span>
                                                    ) : (
                                                      <span className="blue">
                                                        {(item.document_details as AppointmentsProps).type}
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {
                                                      item.document_details.status === 'Canceled' ?
                                                        <span className="red">
                                                          {item.document_details.status}
                                                        </span> :
                                                        <span>
                                                          {item.document_details.status}
                                                        </span>
                                                    }
                                                  </td>
                                                  <td>{(item.document_details as AppointmentsProps).property_type}</td>
                                                  <td>{(item.document_details as AppointmentsProps).area}</td>
                                                  <td>{(item.document_details as AppointmentsProps).dispatcher_code ? `${(item.document_details as AppointmentsProps).created_by} (${(item.document_details as AppointmentsProps).dispatcher_code})` : (item.document_details as AppointmentsProps).created_by}</td>
                                                  <td>{getSchuduleTime((item.document_details as AppointmentsProps).schedule_time_start as string, (item.document_details as AppointmentsProps).schedule_time_finish as string, (item.document_details as AppointmentsProps).time_zone)}</td>
                                                  <td>{(item.document_details as AppointmentsProps).service_resource_code ? `${(item.document_details as AppointmentsProps).service_resource}(${(item.document_details as AppointmentsProps).service_resource_code})` : (item.document_details as AppointmentsProps).service_resource}</td>
                                                </tr>
                                              )
                                            } else if (item.document_type === 'job') {
                                              return (
                                                <tr key={item.document_id}>
                                                  <td>
                                                    <strong>{item.document_type}</strong>
                                                  </td>
                                                  <td
                                                    onClick={() => {
                                                      window.open(`${window.location.origin}/${activeAccountId}/jobs/${(item.document_details as JobsProps).job_id}`, "_blank", 'noopener,noreferrer')
                                                    }}
                                                  >
                                                    <div className='doc-open'>
                                                      <Icon icon="magnifier-5" />
                                                    </div>
                                                  </td>
                                                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as JobsProps).created_at)}</td>
                                                  <td>{(item.document_details as JobsProps).name}</td>
                                                  <td>
                                                    {
                                                      item.document_details.status === 'Canceled' ?
                                                        <span className="red">
                                                          {item.document_details.status}
                                                        </span> :
                                                        <span>
                                                          {item.document_details.status}
                                                        </span>
                                                    }
                                                  </td>
                                                  <td>{(item.document_details as JobsProps).property_type}</td>
                                                  <td>{(item.document_details as JobsProps).area}</td>
                                                  <td>{(item.document_details as JobsProps).source}</td>
                                                  <td>{(item.document_details as JobsProps).created_by}</td>
                                                  <td>{(item.document_details as JobsProps).appointments}</td>
                                                  <td>{(item.document_details as JobsProps).total}</td>
                                                </tr>
                                              )
                                            } else if (item.document_type === 'ticket') {
                                              return (
                                                <tr key={item.document_id}>
                                                  <td>
                                                    <strong>{item.document_type}</strong>
                                                  </td>
                                                  <td
                                                    onClick={() => {
                                                      window.open(`${window.location.origin}/${activeAccountId}/tickets/${(item.document_details as TicketsProps).ticket_id}`, "_blank", 'noopener,noreferrer')
                                                    }}
                                                  >
                                                    <div className='doc-open'>
                                                      <Icon icon="magnifier-5" />
                                                    </div>
                                                  </td>
                                                  <td style={{ position: 'relative', paddingRight: '25px' }}>
                                                    {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as TicketsProps).date_time_start)}
                                                    {
                                                      item.document_details.status !== 'Closed' &&
                                                      item.document_details.status !== 'Canceled' &&
                                                      <span
                                                        style={{
                                                          position: 'absolute',
                                                          right: '5px',
                                                          width: '14px',
                                                          height: '14px',
                                                          background: item.document_details.status === 'In-progress' ? '#FFB700' : '#FF0000',
                                                          borderRadius: '50%',
                                                          color: 'white',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'center',
                                                          top: '3px'
                                                        }}
                                                      >!</span>
                                                    }
                                                  </td>
                                                  <td>{(item.document_details as TicketsProps).number}</td>
                                                  <td
                                                    className={classNames('status',
                                                      {
                                                        canceled: item.document_details.status === 'Canceled',
                                                        closed: item.document_details.status === 'Closed',
                                                        initial: item.document_details.status !== 'Canceled' && item.document_details.status !== 'Closed'
                                                      }
                                                    )}
                                                  >
                                                    {item.document_details.status}
                                                  </td>
                                                  <td>{(item.document_details as TicketsProps).type}</td>
                                                  <td>{(item.document_details as TicketsProps).theme}</td>
                                                  <td> {(item.document_details as TicketsProps).author_dispatcher || (item.document_details as TicketsProps).author_user}</td>
                                                </tr>
                                              )
                                            } else if (item.document_type === 'call') {
                                              return (
                                                <tr key={item.document_id}>
                                                  <td>
                                                    <strong>{item.document_type}</strong>
                                                  </td>
                                                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as CallsProps).created_at)}</td>
                                                  <td>
                                                    <button
                                                      className={classNames('_zeroed', '_iconed', {
                                                        _red: ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                                        _green: ['completed', 'ringing', 'in-progress'].includes((item.document_details as CallsProps).status) && (item.document_details as CallsProps).direction === 'inbound',
                                                        _blue: (item.document_details as CallsProps).direction === 'outbound'
                                                      })}
                                                    >
                                                      <Icon icon="arrow-20" className={classNames({
                                                        '_rotated-180': (item.document_details as CallsProps).direction === 'outbound'
                                                      })} />
                                                    </button>
                                                  </td>

                                                  <td>{(item.document_details as CallsProps).caller_name}</td>
                                                  <td>{(item.document_details as CallsProps).client ? (item.document_details as CallsProps).client : null}</td>
                                                  <td>
                                                    <span>
                                                      {(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_to) : formatPhoneNumber((item.document_details as CallsProps).call_from)}
                                                    </span>
                                                  </td>
                                                  <td>{(item.document_details as CallsProps).friendly_name}</td>
                                                  <td>{(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_from) : formatPhoneNumber((item.document_details as CallsProps).call_to)}</td>
                                                  <td>{(item.document_details as CallsProps).extension}</td>
                                                  <td>{(item.document_details as CallsProps).is_appointment}</td>
                                                  <td className={classNames({
                                                    'red-text': ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                                    'green-text': (item.document_details as CallsProps).status === 'completed',
                                                    'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes((item.document_details as CallsProps).status),
                                                  })}>{(item.document_details as CallsProps).status} {(item.document_details as CallsProps).no_answered_reason ? `(${(item.document_details as CallsProps).no_answered_reason})` : ''} </td>
                                                  <td>{(item.document_details as CallsProps).duration}</td>
                                                  <td>
                                                    {(item.document_details as CallsProps).call_url ? (
                                                      <button
                                                        className="_zeroed _iconed"
                                                        onClick={() => setCallListenPopup((item.document_details as CallsProps).call_url)}
                                                      >
                                                        <Icon icon="media-control-48" />
                                                      </button>
                                                    ) : null}
                                                  </td>
                                                </tr>
                                              )
                                            } else {
                                              return null
                                            }
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                }

                                {
                                  !!item.emojis.length &&
                                  <EmojisRow
                                    emojis={item.emojis}
                                    getUserId={getUserId}
                                    permissions={permissions}
                                    message_id={item.message_id}
                                    removeReaction={removeReplyReaction}
                                    addSameReaction={addSameReplyReaction}
                                    setShowMainEmojiPikerReaction={setShowReplyEmojiPikerReaction}
                                  />
                                }

                                {
                                  showReplyEmojiPikerReaction === item.message_id &&
                                  <EmojyPicker
                                    setShow={setShowReplyEmojiPikerReaction}
                                    hanldeAddReaction={(name) => addReplyReaction(item.message_id, name)}
                                  />
                                }

                                {
                                  showReplyMenu === item.message_id &&
                                  <div className='popup-menu'>
                                    {
                                      !permissions.is_read_only &&
                                      <button
                                        onClick={() => setShowReplyEmojiPikerReaction(item.message_id)}
                                      >
                                        <Icon viewBox="0 0 1177 1024" icon="emoji-111" />
                                      </button>
                                    }

                                    {
                                      !permissions.is_read_only &&
                                      <button
                                        onClick={(event) => {
                                          event.preventDefault()
                                          event.stopPropagation()
                                          setShowAdditionalyMenu(item.message_id)
                                        }}
                                      >
                                        <Icon viewBox="0 0 16 16" icon="dots-1" />
                                      </button>
                                    }

                                    {
                                      showAdditionalyMenu === item.message_id &&
                                      <div ref={contextMenuRef} className='popup-menu-additionaly'>
                                        <div
                                          className='additional-item'
                                          style={{
                                            color: getTimeForEnd(item.created_at) ? 'inherit' : '#FF0000',
                                            opacity: getTimeForEnd(item.created_at) ? '1' : '.5',
                                            cursor: getTimeForEnd(item.created_at) ? 'pointer' : 'inherit'
                                          }}
                                          onClick={() => {
                                            getTimeForEnd(item.created_at) && setShowAdditionalyMenu('')
                                            getTimeForEnd(item.created_at) && setShowMainMenu('')
                                            getTimeForEnd(item.created_at) && setEditMainMessage(item.message_id)
                                            //@ts-ignore
                                            getTimeForEnd(item.created_at) && setEditText(item.message)
                                          }}
                                        >
                                          Edit Message
                                          {
                                            !!seconds &&
                                            <span style={{ marginLeft: '2px', fontSize: '14px', color: '#FF0000' }}> ({seconds}) </span>
                                          }
                                        </div>

                                        {
                                          !!permissions.can_delete &&
                                          <div
                                            className='additional-item'
                                            onClick={() => {
                                              setShowAdditionalyMenu('')
                                              setShowMainMenu('')
                                              deleteReplyMessage(item.message_id)
                                            }}
                                          >
                                            Delete Message
                                          </div>
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </React.Fragment>
                          ))
                        }

                        {
                          !permissions.is_read_only && (editMainMessage === '' || seconds === 0 || !chatReplyReport.filter(item => item.message_id === editMainMessage).length) &&
                          <div
                            style={{ height: getHeight(selectedReplyDocuments) }}
                            className='message-block'
                            onDrop={(event) => {
                              dragingReply && onDrop(event, 'reply')
                            }}
                          >
                            {
                              showReplyEmojiPiker &&
                              <EmojyPicker
                                is_text={true}
                                setShow={() => setShowReplyEmojiPiker(false)}
                                hanldeAddReaction={(name) => handleAddEmojiInTextReply(name)}
                              />
                            }

                            {
                              dragingReply &&
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                                  height: '100%'
                                }}
                              >
                                Upload a File
                              </div>
                            }

                            <EditorReply
                              draging={dragingReply}
                              valueText={replyText}
                              updatedText={updatedText}
                              refComponent={textAreaRaply}
                              openChannelUsers={openReplyChannelUsers}
                              setOpenChannelUsers={setOpenReplyChannelUsers}
                              usersOption={usersOption}
                              filterUser={filterUser}
                              openMenuChannelUsers={openMenuReplyChannelUsers}
                              setOpenMenuChannelUsers={setOpenMenuReplyChannelUsers}
                              setFilterUser={setFilterUser}
                              showMainEmojiPiker={showReplyEmojiPiker}
                              setShowMainEmojiPiker={setShowReplyEmojiPiker}
                              channel_user_id={permissions.channel_user_id}
                              sendMessage={sendReplyMessage}
                              setOpenClipMain={setOpenClipReply}
                              openClipMain={openClipReply}
                              selectedDocuments={selectedReplyDocuments}
                              setSelectedDocuments={setSelectedReplyDocuments}
                              chatReplyReportLength={chatReplyReport.length}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  }
                </React.Fragment> :
                <div
                  className='channel-main'
                  onDragLeave={(event) => onDragLeave(event, 'main')}
                  onDragOver={(event) => onDragOver(event, 'main')}
                >
                  <div className='channel-main-title'>
                    <span> {channelName} </span>
                  </div>

                  <div
                    className='main-content'
                    style={{ height: calcMainHeaight() }}
                    ref={mainContentRef}
                  >
                    <div className='fix' style={{ flex: '1 1 auto' }}></div>

                    {
                      chatReport.map((item, index) => (
                        <React.Fragment key={item.message_id}>
                          {
                            index === 0 ?
                              <div className='date-line'>
                                <span className='line'></span>
                                <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                                <span className='line'></span>
                              </div> :
                              dateToInfoBlock('MM/dd/yyyy', time_zone, item.created_at) !== dateToInfoBlock('MM/dd/yyyy', time_zone, chatReport[index - 1].created_at) ?
                                <div className='date-line'>
                                  <span className='line'></span>
                                  <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                                  <span className='line'></span>
                                </div> : null
                          }

                          <div
                            className='channel-main-items'
                            onClick={() => {
                              !item.is_read && readMessages(true, index)
                            }}
                            onMouseOver={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowMainMenu(item.message_id)}
                            onMouseLeave={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowMainMenu('')}
                          >
                            <div className='item-date'>
                              <span className='name'> {item.user_name} </span>
                              <span className='date'> {dateToInfoBlock('hh:mma', time_zone, item.created_at)} </span>
                              {
                                !item.is_read &&
                                <span className='is_read'> New </span>
                              }
                            </div>

                            {
                              editMainMessage && editMainMessage === item.message_id ?
                                <div
                                  className='item-message'
                                  onDrop={(event) => {
                                    dragingMain && onDrop(event, 'edit')
                                  }}
                                >
                                  {
                                    dragingMain ?
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                          height: '100%'
                                        }}
                                      >
                                        Upload a File
                                      </div> :
                                      <EditText
                                        editText={editText}
                                        setEditText={setEditText}
                                        setEditMainMessage={setEditMainMessage}
                                        seconds={seconds}
                                        updatedMessage={updatedMessage}
                                        usersOption={usersOption}
                                        channel_user_id={permissions.channel_user_id}
                                        openClip={openClipEditMain}
                                        setOpenClip={setOpenClipEditMain}
                                        selectedDocuments={selectedEditDocuments}
                                        setSelectedDocuments={setSelectedEditDocuments}
                                        setUpdatedHeight={setUpdatedHeight}
                                        selectedEditAttachments={selectedEditAttachments}
                                        setSelectedEditAttachments={setSelectedEditAttachments}
                                      />
                                  }
                                </div>
                                :
                                <div
                                  className='item-message'
                                  ref={textEditRef}
                                  dangerouslySetInnerHTML={{ __html: `${item.message}` }}
                                ></div>
                            }

                            {
                              !!item.attachments.length && editMainMessage !== item.message_id &&
                              <div className='attachment-row'>
                                {
                                  item.attachments.map((attachment, idx) => (
                                    <img
                                      key={idx}
                                      src={attachment.url}
                                      alt="Attachment"
                                    />
                                  ))
                                }
                              </div>
                            }

                            {
                              !!item.documents.length && editMainMessage !== item.message_id &&
                              <div className='documents-table'>
                                <table className='table'>
                                  <tbody>
                                    <tr style={{ height: 0 }}></tr>
                                    {
                                      item.documents.map(item => {
                                        if (item.document_type === 'appointment') {
                                          return (
                                            <tr key={item.document_id}>
                                              <td>
                                                <strong>{item.document_type}</strong>
                                              </td>
                                              <td
                                                onClick={() => {
                                                  window.open(`${window.location.origin}/${activeAccountId}/appointments/${(item.document_details as AppointmentsProps).appointment_id}`, "_blank", 'noopener,noreferrer')
                                                }}
                                              >
                                                <div className='doc-open'>
                                                  <Icon icon="magnifier-5" />
                                                </div>
                                              </td>
                                              <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as AppointmentsProps).created_at)}</td>
                                              <td>{(item.document_details as AppointmentsProps).name}</td>
                                              <td>
                                                {(item.document_details as AppointmentsProps).type === 'Recall' ? (
                                                  <span className="red">
                                                    {(item.document_details as AppointmentsProps).type}
                                                  </span>
                                                ) : (item.document_details as AppointmentsProps).type === 'Service call' ? (
                                                  <span className="green">
                                                    {(item.document_details as AppointmentsProps).type}
                                                  </span>
                                                ) : (
                                                  <span className="blue">
                                                    {(item.document_details as AppointmentsProps).type}
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                {
                                                  item.document_details.status === 'Canceled' ?
                                                    <span className="red">
                                                      {item.document_details.status}
                                                    </span> :
                                                    <span>
                                                      {item.document_details.status}
                                                    </span>
                                                }
                                              </td>
                                              <td>{(item.document_details as AppointmentsProps).property_type}</td>
                                              <td>{(item.document_details as AppointmentsProps).area}</td>
                                              <td>{(item.document_details as AppointmentsProps).dispatcher_code ? `${(item.document_details as AppointmentsProps).created_by} (${(item.document_details as AppointmentsProps).dispatcher_code})` : (item.document_details as AppointmentsProps).created_by}</td>
                                              <td>{getSchuduleTime((item.document_details as AppointmentsProps).schedule_time_start as string, (item.document_details as AppointmentsProps).schedule_time_finish as string, (item.document_details as AppointmentsProps).time_zone)}</td>
                                              <td>{(item.document_details as AppointmentsProps).service_resource_code ? `${(item.document_details as AppointmentsProps).service_resource}(${(item.document_details as AppointmentsProps).service_resource_code})` : (item.document_details as AppointmentsProps).service_resource}</td>
                                            </tr>
                                          )
                                        } else if (item.document_type === 'job') {
                                          return (
                                            <tr key={item.document_id}>
                                              <td>
                                                <strong>{item.document_type}</strong>
                                              </td>
                                              <td
                                                onClick={() => {
                                                  window.open(`${window.location.origin}/${activeAccountId}/jobs/${(item.document_details as JobsProps).job_id}`, "_blank", 'noopener,noreferrer')
                                                }}
                                              >
                                                <div className='doc-open'>
                                                  <Icon icon="magnifier-5" />
                                                </div>
                                              </td>
                                              <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as JobsProps).created_at)}</td>
                                              <td>{(item.document_details as JobsProps).name}</td>
                                              <td>
                                                {
                                                  item.document_details.status === 'Canceled' ?
                                                    <span className="red">
                                                      {item.document_details.status}
                                                    </span> :
                                                    <span>
                                                      {item.document_details.status}
                                                    </span>
                                                }
                                              </td>
                                              <td>{(item.document_details as JobsProps).property_type}</td>
                                              <td>{(item.document_details as JobsProps).area}</td>
                                              <td>{(item.document_details as JobsProps).source}</td>
                                              <td>{(item.document_details as JobsProps).created_by}</td>
                                              <td>{(item.document_details as JobsProps).appointments}</td>
                                              <td>{(item.document_details as JobsProps).total}</td>
                                            </tr>
                                          )
                                        } else if (item.document_type === 'ticket') {
                                          return (
                                            <tr key={item.document_id}>
                                              <td>
                                                <strong>{item.document_type}</strong>
                                              </td>
                                              <td
                                                onClick={() => {
                                                  window.open(`${window.location.origin}/${activeAccountId}/tickets/${(item.document_details as TicketsProps).ticket_id}`, "_blank", 'noopener,noreferrer')
                                                }}
                                              >
                                                <div className='doc-open'>
                                                  <Icon icon="magnifier-5" />
                                                </div>
                                              </td>
                                              <td style={{ position: 'relative', paddingRight: '25px' }}>
                                                {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as TicketsProps).date_time_start)}
                                                {
                                                  item.document_details.status !== 'Closed' &&
                                                  item.document_details.status !== 'Canceled' &&
                                                  <span
                                                    style={{
                                                      position: 'absolute',
                                                      right: '5px',
                                                      width: '14px',
                                                      height: '14px',
                                                      background: item.document_details.status === 'In-progress' ? '#FFB700' : '#FF0000',
                                                      borderRadius: '50%',
                                                      color: 'white',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      top: '3px'
                                                    }}
                                                  >!</span>
                                                }
                                              </td>
                                              <td>{(item.document_details as TicketsProps).number}</td>
                                              <td
                                                className={classNames('status',
                                                  {
                                                    canceled: item.document_details.status === 'Canceled',
                                                    closed: item.document_details.status === 'Closed',
                                                    initial: item.document_details.status !== 'Canceled' && item.document_details.status !== 'Closed'
                                                  }
                                                )}
                                              >
                                                {item.document_details.status}
                                              </td>
                                              <td>{(item.document_details as TicketsProps).type}</td>
                                              <td>{(item.document_details as TicketsProps).theme}</td>
                                              <td> {(item.document_details as TicketsProps).author_dispatcher || (item.document_details as TicketsProps).author_user}</td>
                                            </tr>
                                          )
                                        } else if (item.document_type === 'call') {
                                          return (
                                            <tr key={item.document_id}>
                                              <td>
                                                <strong>{item.document_type}</strong>
                                              </td>
                                              <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as CallsProps).created_at)}</td>
                                              <td>
                                                <button
                                                  className={classNames('_zeroed', '_iconed', {
                                                    _red: ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                                    _green: ['completed', 'ringing', 'in-progress'].includes((item.document_details as CallsProps).status) && (item.document_details as CallsProps).direction === 'inbound',
                                                    _blue: (item.document_details as CallsProps).direction === 'outbound'
                                                  })}
                                                >
                                                  <Icon icon="arrow-20" className={classNames({
                                                    '_rotated-180': (item.document_details as CallsProps).direction === 'outbound'
                                                  })} />
                                                </button>
                                              </td>

                                              <td>{(item.document_details as CallsProps).caller_name}</td>
                                              <td>{(item.document_details as CallsProps).client ? (item.document_details as CallsProps).client : null}</td>
                                              <td>
                                                <span>
                                                  {(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_to) : formatPhoneNumber((item.document_details as CallsProps).call_from)}
                                                </span>
                                              </td>
                                              <td>{(item.document_details as CallsProps).friendly_name}</td>
                                              <td>{(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_from) : formatPhoneNumber((item.document_details as CallsProps).call_to)}</td>
                                              <td>{(item.document_details as CallsProps).extension}</td>
                                              <td>{(item.document_details as CallsProps).is_appointment}</td>
                                              <td className={classNames({
                                                'red-text': ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                                'green-text': (item.document_details as CallsProps).status === 'completed',
                                                'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes((item.document_details as CallsProps).status),
                                              })}>{(item.document_details as CallsProps).status} {(item.document_details as CallsProps).no_answered_reason ? `(${(item.document_details as CallsProps).no_answered_reason})` : ''} </td>
                                              <td>{(item.document_details as CallsProps).duration}</td>
                                              <td>
                                                {(item.document_details as CallsProps).call_url ? (
                                                  <button
                                                    className="_zeroed _iconed"
                                                    onClick={() => setCallListenPopup((item.document_details as CallsProps).call_url)}
                                                  >
                                                    <Icon icon="media-control-48" />
                                                  </button>
                                                ) : null}
                                              </td>
                                            </tr>
                                          )
                                        } else {
                                          return null
                                        }
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            }

                            {
                              !!item.emojis.length &&
                              <EmojisRow
                                emojis={item.emojis}
                                getUserId={getUserId}
                                permissions={permissions}
                                message_id={item.message_id}
                                removeReaction={removeReaction}
                                addSameReaction={addSameReaction}
                                setShowMainEmojiPikerReaction={setShowMainEmojiPikerReaction}
                              />
                            }

                            {
                              !!item.child_messages_count &&
                              <div
                                className='bottom-row'
                                onClick={() => {
                                  setSelectedMessage(item)
                                  getThreadMessage(item.message_id)
                                }}
                              >
                                <span className='count'> {item.child_messages_count} {item.child_messages_count > 1 ? ' replies' : ' reply'}  </span>
                              </div>
                            }
                            {
                              showMainEmojiPikerReaction === item.message_id &&
                              <EmojyPicker
                                setShow={setShowMainEmojiPikerReaction}
                                hanldeAddReaction={(name) => addReaction(item.message_id, name)}
                              />
                            }

                            {
                              showMainMenu === item.message_id &&
                              <div className='popup-menu'>
                                {
                                  !permissions.is_read_only &&
                                  <button
                                    onClick={() => setShowMainEmojiPikerReaction(item.message_id)}
                                  >
                                    <Icon viewBox="0 0 1177 1024" icon="emoji-111" />
                                  </button>
                                }

                                <button
                                  title='Reply to threads'
                                  onClick={() => {
                                    setSelectedMessage(item)
                                    getThreadMessage(item.message_id)
                                  }}
                                >
                                  <Icon viewBox="0 0 1024 1024" icon="chat-118" />
                                </button>

                                {
                                  getUserId(item.user_name) === permissions.channel_user_id &&
                                  !permissions.is_read_only &&
                                  <button
                                    onClick={(event) => handleClickPopupMenu(event, item.message_id, index)}
                                  >
                                    <Icon viewBox="0 0 16 16" icon="dots-1" />
                                  </button>
                                }

                                {
                                  showAdditionalyMenu === item.message_id &&
                                  <div ref={contextMenuRef} className='popup-menu-additionaly'>
                                    <div
                                      className='additional-item'
                                      style={{
                                        color: getTimeForEnd(item.created_at) ? 'inherit' : '#FF0000',
                                        opacity: getTimeForEnd(item.created_at) ? '1' : '.5',
                                        cursor: getTimeForEnd(item.created_at) ? 'pointer' : 'inherit'
                                      }}
                                      onClick={() => {
                                        getTimeForEnd(item.created_at) && setShowAdditionalyMenu('')
                                        getTimeForEnd(item.created_at) && setShowMainMenu('')
                                        getTimeForEnd(item.created_at) && setEditMainMessage(item.message_id)
                                        index === chatReport.length - 1 && setUpdatedHeight(Math.random())
                                        //@ts-ignore
                                        getTimeForEnd(item.created_at) && setEditText(item.message)
                                      }}
                                    >
                                      Edit Message
                                      {
                                        !!seconds &&
                                        <span style={{ marginLeft: '2px', fontSize: '14px', color: '#FF0000' }}> ({seconds}) </span>
                                      }
                                    </div>

                                    {
                                      !!permissions.can_delete &&
                                      < div
                                        className='additional-item'
                                        onClick={() => {
                                          setShowAdditionalyMenu('')
                                          setShowMainMenu('')
                                          deleteMessage(item.message_id)
                                        }}
                                      >
                                        Delete Message
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </React.Fragment>
                      ))
                    }
                  </div>

                  {
                    !permissions.is_read_only && (editMainMessage === '' || seconds === 0 || !chatReport.filter(item => item.message_id === editMainMessage).length) &&
                    <div
                      style={{ height: getHeight(selectedDocuments) }}
                      className='message-block'
                      onDrop={(event) => {
                        dragingMain && onDrop(event, 'main')
                      }}
                    >
                      {
                        dragingMain &&
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%'
                          }}
                        >
                          Upload a File
                        </div>
                      }

                      {
                        !!chatReport.length && channelName &&
                        <Editor
                          draging={dragingMain}
                          channelName={channelName}
                          valueText={mainText}
                          updatedText={updatedText}
                          refComponent={textAreaMain}
                          openChannelUsers={openChannelUsers}
                          usersOption={usersOption}
                          filterUser={filterUser}
                          setOpenChannelUsers={setOpenChannelUsers}
                          openMenuChannelUsers={openMenuChannelUsers}
                          setOpenMenuChannelUsers={setOpenMenuChannelUsers}
                          setFilterUser={setFilterUser}
                          showMainEmojiPiker={showMainEmojiPiker}
                          setShowMainEmojiPiker={setShowMainEmojiPiker}
                          channel_user_id={permissions.channel_user_id}
                          sendMessage={sendMessage}
                          setOpenClipMain={setOpenClipMain}
                          openClipMain={openClipMain}
                          selectedDocuments={selectedDocuments}
                          setSelectedDocuments={setSelectedDocuments}
                        />
                      }
                    </div>
                  }
                </div>
            }
          </React.Fragment> :
          <React.Fragment>
            <div
              className={classNames('channel-main',
                {
                  __reply: showReply,
                }
              )}
              onDragLeave={(event) => onDragLeave(event, 'main')}
              onDragOver={(event) => onDragOver(event, 'main')}
            >
              <div className='channel-main-title'>
                <span> {channelName} </span>
              </div>

              <div
                className='main-content'
                style={{ height: calcMainHeaight() }}
                ref={mainContentRef}
              >
                <div className='fix' style={{ flex: '1 1 auto' }}></div>
                {
                  chatReport.map((item, index) => (
                    <React.Fragment key={item.message_id}>
                      {
                        index === 0 ?
                          <div className='date-line'>
                            <span className='line'></span>
                            <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                            <span className='line'></span>
                          </div> :
                          dateToInfoBlock('MM/dd/yyyy', time_zone, item.created_at) !== dateToInfoBlock('MM/dd/yyyy', time_zone, chatReport[index - 1].created_at) ?
                            <div className='date-line'>
                              <span className='line'></span>
                              <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                              <span className='line'></span>
                            </div> : null
                      }

                      <div
                        className='channel-main-items'
                        onClick={() => {
                          !item.is_read && readMessages(true, index)
                        }}
                        onMouseOver={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowMainMenu(item.message_id)}
                        onMouseLeave={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowMainMenu('')}
                      >
                        <div className='item-date'>
                          <span className='name'> {item.user_name} </span>
                          <span className='date'> {dateToInfoBlock('hh:mma', time_zone, item.created_at)} </span>
                          {
                            !item.is_read &&
                            <span className='is_read'> New </span>
                          }
                        </div>

                        {
                          editMainMessage && editMainMessage === item.message_id ?
                            <div
                              className='item-message'
                              onDrop={(event) => {
                                dragingMain && onDrop(event, 'edit')
                              }}
                            >
                              {
                                dragingMain ?
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '100%',
                                      height: '100px',
                                      background: '#fff',
                                      border: '1px solid #d0d3da',
                                      borderRadius: '5px'
                                    }}
                                  >
                                    Upload a File 111
                                  </div> :
                                  <EditText
                                    editText={editText}
                                    setEditText={setEditText}
                                    setEditMainMessage={setEditMainMessage}
                                    seconds={seconds}
                                    updatedMessage={updatedMessage}
                                    usersOption={usersOption}
                                    channel_user_id={permissions.channel_user_id}
                                    openClip={openClipEditMain}
                                    setOpenClip={setOpenClipEditMain}
                                    selectedDocuments={selectedEditDocuments}
                                    setSelectedDocuments={setSelectedEditDocuments}
                                    setUpdatedHeight={setUpdatedHeight}
                                    selectedEditAttachments={selectedEditAttachments}
                                    setSelectedEditAttachments={setSelectedEditAttachments}
                                  />
                              }
                            </div>
                            :
                            <div
                              className='item-message'
                              ref={textEditRef}
                              dangerouslySetInnerHTML={{ __html: `${item.message}` }}
                            ></div>
                        }

                        {
                          !!item.attachments.length && editMainMessage !== item.message_id &&
                          <div className='attachment-row'>
                            {
                              item.attachments.map((attachment, idx) => (
                                <div key={idx} className='attachment-item'>
                                  <img
                                    src={attachment.url}
                                    alt="Attachment"
                                  />

                                  <div className='attachment-name'>
                                    <span>
                                      {getAttachmentName(attachment.url)}
                                    </span>
                                  </div>

                                  <div className='attachment-actions'>
                                    <button
                                      onClick={() => downloadImage(attachment.url)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>

                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenModal(attachment.url)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        }

                        {
                          !!item.documents.length && editMainMessage !== item.message_id &&
                          <div className='documents-table'>
                            <table className='table'>
                              <tbody>
                                <tr style={{ height: 0 }}></tr>
                                {
                                  item.documents.map(item => {
                                    if (item.document_type === 'appointment') {
                                      return (
                                        <tr key={item.document_id}>
                                          <td>
                                            <strong>{item.document_type}</strong>
                                          </td>
                                          <td
                                            onClick={() => {
                                              window.open(`${window.location.origin}/${activeAccountId}/appointments/${(item.document_details as AppointmentsProps).appointment_id}`, "_blank", 'noopener,noreferrer')
                                            }}
                                          >
                                            <div className='doc-open'>
                                              <Icon icon="magnifier-5" />
                                            </div>
                                          </td>
                                          <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as AppointmentsProps).created_at)}</td>
                                          <td>{(item.document_details as AppointmentsProps).name}</td>
                                          <td>
                                            {(item.document_details as AppointmentsProps).type === 'Recall' ? (
                                              <span className="red">
                                                {(item.document_details as AppointmentsProps).type}
                                              </span>
                                            ) : (item.document_details as AppointmentsProps).type === 'Service call' ? (
                                              <span className="green">
                                                {(item.document_details as AppointmentsProps).type}
                                              </span>
                                            ) : (
                                              <span className="blue">
                                                {(item.document_details as AppointmentsProps).type}
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {
                                              item.document_details.status === 'Canceled' ?
                                                <span className="red">
                                                  {item.document_details.status}
                                                </span> :
                                                <span>
                                                  {item.document_details.status}
                                                </span>
                                            }
                                          </td>
                                          <td>{(item.document_details as AppointmentsProps).property_type}</td>
                                          <td>{(item.document_details as AppointmentsProps).area}</td>
                                          <td>{(item.document_details as AppointmentsProps).dispatcher_code ? `${(item.document_details as AppointmentsProps).created_by} (${(item.document_details as AppointmentsProps).dispatcher_code})` : (item.document_details as AppointmentsProps).created_by}</td>
                                          <td>{getSchuduleTime((item.document_details as AppointmentsProps).schedule_time_start as string, (item.document_details as AppointmentsProps).schedule_time_finish as string, (item.document_details as AppointmentsProps).time_zone)}</td>
                                          <td>{(item.document_details as AppointmentsProps).service_resource_code ? `${(item.document_details as AppointmentsProps).service_resource}(${(item.document_details as AppointmentsProps).service_resource_code})` : (item.document_details as AppointmentsProps).service_resource}</td>
                                        </tr>
                                      )
                                    } else if (item.document_type === 'job') {
                                      return (
                                        <tr key={item.document_id}>
                                          <td>
                                            <strong>{item.document_type}</strong>
                                          </td>
                                          <td
                                            onClick={() => {
                                              window.open(`${window.location.origin}/${activeAccountId}/jobs/${(item.document_details as JobsProps).job_id}`, "_blank", 'noopener,noreferrer')
                                            }}
                                          >
                                            <div className='doc-open'>
                                              <Icon icon="magnifier-5" />
                                            </div>
                                          </td>
                                          <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as JobsProps).created_at)}</td>
                                          <td>{(item.document_details as JobsProps).name}</td>
                                          <td>
                                            {
                                              item.document_details.status === 'Canceled' ?
                                                <span className="red">
                                                  {item.document_details.status}
                                                </span> :
                                                <span>
                                                  {item.document_details.status}
                                                </span>
                                            }
                                          </td>
                                          <td>{(item.document_details as JobsProps).property_type}</td>
                                          <td>{(item.document_details as JobsProps).area}</td>
                                          <td>{(item.document_details as JobsProps).source}</td>
                                          <td>{(item.document_details as JobsProps).created_by}</td>
                                          <td>{(item.document_details as JobsProps).appointments}</td>
                                          <td>{(item.document_details as JobsProps).total}</td>
                                        </tr>
                                      )
                                    } else if (item.document_type === 'ticket') {
                                      return (
                                        <tr key={item.document_id}>
                                          <td>
                                            <strong>{item.document_type}</strong>
                                          </td>
                                          <td
                                            onClick={() => {
                                              window.open(`${window.location.origin}/${activeAccountId}/tickets/${(item.document_details as TicketsProps).ticket_id}`, "_blank", 'noopener,noreferrer')
                                            }}
                                          >
                                            <div className='doc-open'>
                                              <Icon icon="magnifier-5" />
                                            </div>
                                          </td>
                                          <td style={{ position: 'relative', paddingRight: '25px' }}>
                                            {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as TicketsProps).date_time_start)}
                                            {
                                              item.document_details.status !== 'Closed' &&
                                              item.document_details.status !== 'Canceled' &&
                                              <span
                                                style={{
                                                  position: 'absolute',
                                                  right: '5px',
                                                  width: '14px',
                                                  height: '14px',
                                                  background: item.document_details.status === 'In-progress' ? '#FFB700' : '#FF0000',
                                                  borderRadius: '50%',
                                                  color: 'white',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  top: '3px'
                                                }}
                                              >!</span>
                                            }
                                          </td>
                                          <td>{(item.document_details as TicketsProps).number}</td>
                                          <td
                                            className={classNames('status',
                                              {
                                                canceled: item.document_details.status === 'Canceled',
                                                closed: item.document_details.status === 'Closed',
                                                initial: item.document_details.status !== 'Canceled' && item.document_details.status !== 'Closed'
                                              }
                                            )}
                                          >
                                            {item.document_details.status}
                                          </td>
                                          <td>{(item.document_details as TicketsProps).type}</td>
                                          <td>{(item.document_details as TicketsProps).theme}</td>
                                          <td> {(item.document_details as TicketsProps).author_dispatcher || (item.document_details as TicketsProps).author_user}</td>
                                        </tr>
                                      )
                                    } else if (item.document_type === 'call') {
                                      return (
                                        <tr key={item.document_id}>
                                          <td>
                                            <strong>{item.document_type}</strong>
                                          </td>
                                          <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as CallsProps).created_at)}</td>
                                          <td>
                                            <button
                                              className={classNames('_zeroed', '_iconed', {
                                                _red: ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                                _green: ['completed', 'ringing', 'in-progress'].includes((item.document_details as CallsProps).status) && (item.document_details as CallsProps).direction === 'inbound',
                                                _blue: (item.document_details as CallsProps).direction === 'outbound'
                                              })}
                                            >
                                              <Icon icon="arrow-20" className={classNames({
                                                '_rotated-180': (item.document_details as CallsProps).direction === 'outbound'
                                              })} />
                                            </button>
                                          </td>

                                          <td>{(item.document_details as CallsProps).caller_name}</td>
                                          <td>{(item.document_details as CallsProps).client ? (item.document_details as CallsProps).client : null}</td>
                                          <td>
                                            <span>
                                              {(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_to) : formatPhoneNumber((item.document_details as CallsProps).call_from)}
                                            </span>
                                          </td>
                                          <td>{(item.document_details as CallsProps).friendly_name}</td>
                                          <td>{(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_from) : formatPhoneNumber((item.document_details as CallsProps).call_to)}</td>
                                          <td>{(item.document_details as CallsProps).extension}</td>
                                          <td>{(item.document_details as CallsProps).is_appointment}</td>
                                          <td className={classNames({
                                            'red-text': ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                            'green-text': (item.document_details as CallsProps).status === 'completed',
                                            'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes((item.document_details as CallsProps).status),
                                          })}>{(item.document_details as CallsProps).status} {(item.document_details as CallsProps).no_answered_reason ? `(${(item.document_details as CallsProps).no_answered_reason})` : ''} </td>
                                          <td>{(item.document_details as CallsProps).duration}</td>
                                          <td>
                                            {(item.document_details as CallsProps).call_url ? (
                                              <button
                                                className="_zeroed _iconed"
                                                onClick={() => setCallListenPopup((item.document_details as CallsProps).call_url)}
                                              >
                                                <Icon icon="media-control-48" />
                                              </button>
                                            ) : null}
                                          </td>
                                        </tr>
                                      )
                                    } else {
                                      return null
                                    }
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        }

                        {
                          !!item.emojis.length &&
                          <EmojisRow
                            emojis={item.emojis}
                            getUserId={getUserId}
                            permissions={permissions}
                            message_id={item.message_id}
                            removeReaction={removeReaction}
                            addSameReaction={addSameReaction}
                            setShowMainEmojiPikerReaction={setShowMainEmojiPikerReaction}
                          />
                        }

                        {
                          !!item.child_messages_count &&
                          <div
                            className='bottom-row'
                            onClick={() => {
                              setSelectedMessage(item)
                              getThreadMessage(item.message_id)
                            }}
                          >
                            <span className='count'> {item.child_messages_count} {item.child_messages_count > 1 ? ' replies' : ' reply'}  </span>
                          </div>
                        }
                        {
                          showMainEmojiPikerReaction === item.message_id &&
                          <EmojyPicker
                            setShow={setShowMainEmojiPikerReaction}
                            hanldeAddReaction={(name) => addReaction(item.message_id, name)}
                          />
                        }

                        {
                          showMainMenu === item.message_id &&
                          <div className='popup-menu'>
                            {
                              !permissions.is_read_only &&
                              <button
                                onClick={() => setShowMainEmojiPikerReaction(item.message_id)}
                              >
                                <Icon viewBox="0 0 1177 1024" icon="emoji-111" />
                              </button>
                            }

                            <button
                              title='Reply to threads'
                              onClick={() => {
                                setSelectedMessage(item)
                                getThreadMessage(item.message_id)
                              }}
                            >
                              <Icon viewBox="0 0 1024 1024" icon="chat-118" />
                            </button>

                            {
                              getUserId(item.user_name) === permissions.channel_user_id &&
                              !permissions.is_read_only &&
                              <button
                                onClick={(event) => handleClickPopupMenu(event, item.message_id, index)}
                              >
                                <Icon viewBox="0 0 16 16" icon="dots-1" />
                              </button>
                            }

                            {
                              showAdditionalyMenu === item.message_id &&
                              <div ref={contextMenuRef} className='popup-menu-additionaly'>
                                <div
                                  className='additional-item'
                                  style={{
                                    color: getTimeForEnd(item.created_at) ? 'inherit' : '#FF0000',
                                    opacity: getTimeForEnd(item.created_at) ? '1' : '.5',
                                    cursor: getTimeForEnd(item.created_at) ? 'pointer' : 'inherit'
                                  }}
                                  onClick={() => {
                                    getTimeForEnd(item.created_at) && setShowAdditionalyMenu('')
                                    getTimeForEnd(item.created_at) && setShowMainMenu('')
                                    getTimeForEnd(item.created_at) && setEditMainMessage(item.message_id)
                                    index === chatReport.length - 1 && setUpdatedHeight(Math.random())
                                    //@ts-ignore
                                    getTimeForEnd(item.created_at) && setEditText(item.message)
                                  }}
                                >
                                  Edit Message
                                  {
                                    !!seconds &&
                                    <span style={{ marginLeft: '2px', fontSize: '14px', color: '#FF0000' }}> ({seconds}) </span>
                                  }
                                </div>

                                {
                                  !!permissions.can_delete &&
                                  < div
                                    className='additional-item'
                                    onClick={() => {
                                      setShowAdditionalyMenu('')
                                      setShowMainMenu('')
                                      deleteMessage(item.message_id)
                                    }}
                                  >
                                    Delete Message
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>

              {
                !permissions.is_read_only && (editMainMessage === '' || seconds === 0 || !chatReport.filter(item => item.message_id === editMainMessage).length) &&
                <div
                  style={{ height: getHeight(selectedDocuments) }}
                  className='message-block'
                  onDrop={(event) => {
                    dragingMain && onDrop(event, 'main')
                  }}
                >
                  {
                    dragingMain &&
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                      }}
                    >
                      Upload a File 222
                    </div>
                  }

                  {
                    !!chatReport.length && channelName &&
                    <Editor
                      draging={dragingMain}
                      channelName={channelName}
                      valueText={mainText}
                      updatedText={updatedText}
                      refComponent={textAreaMain}
                      openChannelUsers={openChannelUsers}
                      usersOption={usersOption}
                      filterUser={filterUser}
                      setOpenChannelUsers={setOpenChannelUsers}
                      openMenuChannelUsers={openMenuChannelUsers}
                      setOpenMenuChannelUsers={setOpenMenuChannelUsers}
                      setFilterUser={setFilterUser}
                      showMainEmojiPiker={showMainEmojiPiker}
                      setShowMainEmojiPiker={setShowMainEmojiPiker}
                      channel_user_id={permissions.channel_user_id}
                      sendMessage={sendMessage}
                      setOpenClipMain={setOpenClipMain}
                      openClipMain={openClipMain}
                      selectedDocuments={selectedDocuments}
                      setSelectedDocuments={setSelectedDocuments}
                    />
                  }
                </div>
              }

            </div>
            {
              selectedMessage && chatReplyReport &&
              <div
                className='channel-reply'
                onDragLeave={(event) => onDragLeave(event, 'reply')}
                onDragOver={(event) => onDragOver(event, 'reply')}
              >
                <div className='channel-main-title'>
                  <span> Thread </span>

                  <button
                    onClick={() => {
                      setShowReply(false)
                      setChatReplyReport(null)
                      setSelectedMessage(null)
                    }}
                  >
                    <Icon icon="x-mark-1" />
                  </button>
                </div>

                <div className='main-content'>
                  <div className='channel-main-items'>
                    <div className='item-date'>
                      <span className='name'> {selectedMessage.user_name} </span>
                      <span className='date'> {dateToInfoBlock('hh:mma', time_zone, selectedMessage.created_at)} </span>
                    </div>

                    <div
                      className='item-message'
                      dangerouslySetInnerHTML={{ __html: `${selectedMessage.message}` }}
                    ></div>

                    {
                      !!selectedMessage.attachments.length && editMainMessage !== selectedMessage.message_id &&
                      <div className='attachment-row'>
                        {
                          selectedMessage.attachments.map((attachment, idx) => (
                            <div key={idx} className='attachment-item'>
                              <img
                                src={attachment.url}
                                alt="Attachment"
                              />

                              <div className='attachment-name'>
                                <span>
                                  {getAttachmentName(attachment.url)}
                                </span>
                              </div>

                              <div className='attachment-actions'>
                                <button
                                  onClick={() => downloadImage(attachment.url)}
                                >
                                  <Icon
                                    style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="download-sms"
                                  />
                                </button>

                                <button
                                  style={{ padding: '0' }}
                                  onClick={() => handleOpenModal(attachment.url)}
                                >
                                  <Icon
                                    style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                    viewBox="0 0 1024 1024"
                                    icon="zoom-in-1"
                                  />
                                </button>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    }

                    {
                      !!selectedMessage.emojis.length &&
                      <EmojisRow
                        emojis={selectedMessage.emojis}
                        getUserId={getUserId}
                        permissions={permissions}
                        message_id={selectedMessage.message_id}
                        removeReaction={removeReaction}
                        addSameReaction={addSameReaction}
                        setShowMainEmojiPikerReaction={setShowMainEmojiPikerReaction}
                      />
                    }
                  </div>

                  {
                    chatReplyReport.length === 0 &&
                    <div style={{ marginBottom: '25px' }}></div>
                  }

                  {
                    chatReplyReport.map((item, index) => (
                      <React.Fragment key={item.message_id}>
                        {
                          index === 0 ?
                            <div className='date-line'>
                              <span className='line'></span>
                              <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                              <span className='line'></span>
                            </div> :
                            dateToInfoBlock('MM/dd/yyyy', time_zone, item.created_at) !== dateToInfoBlock('MM/dd/yyyy', time_zone, chatReplyReport[index - 1].created_at) ?
                              <div className='date-line'>
                                <span className='line'></span>
                                <span className='date'>{dateToInfoBlock('cccc, LLLL d', time_zone, item.created_at)}th </span>
                                <span className='line'></span>
                              </div> : null
                        }

                        <div
                          className='channel-main-items'
                          onClick={() => {
                            !item.is_read && readMessages(false, index)
                          }}
                          style={{ marginBottom: index === chatReplyReport.length - 1 ? '25px' : '0' }}
                          onMouseOver={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowReplyMenu(item.message_id)}
                          onMouseLeave={() => showAdditionalyMenu === '' && editMainMessage === '' && showMainEmojiPikerReaction === '' && setShowReplyMenu('')}
                        >
                          <div className='item-date'>
                            <span className='name'> {item.user_name} </span>
                            <span className='date'> {dateToInfoBlock('hh:mma', time_zone, item.created_at)} </span>
                            {
                              !item.is_read &&
                              <span className='is_read'> New </span>
                            }
                          </div>

                          {
                            editMainMessage && editMainMessage === item.message_id ?
                              <div
                                className='item-message'
                                onDrop={(event) => {
                                  dragingReply && onDrop(event, 'edit_reply')
                                }}
                              >
                                {
                                  dragingReply ?
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: '100%'
                                      }}
                                    >
                                      Upload a File
                                    </div> :
                                    <EditText
                                      editText={editText}
                                      setEditText={setEditText}
                                      setEditMainMessage={setEditMainMessage}
                                      seconds={seconds}
                                      updatedMessage={updatedMessage}
                                      usersOption={usersOption}
                                      channel_user_id={permissions.channel_user_id}
                                      openClip={openClipEditMain}
                                      setOpenClip={setOpenClipEditMain}
                                      selectedDocuments={selectedEditDocuments}
                                      setSelectedDocuments={setSelectedEditDocuments}
                                      setUpdatedHeight={setUpdatedHeight}
                                      selectedEditAttachments={selectedEditAttachments}
                                      setSelectedEditAttachments={setSelectedEditAttachments}
                                    />
                                }
                              </div> :
                              <div
                                className='item-message'
                                ref={textEditReplyRef}
                                dangerouslySetInnerHTML={{ __html: `${item.message}` }}
                              ></div>
                          }

                          {
                            !!item.attachments.length && editMainMessage !== item.message_id &&
                            <div className='attachment-row'>
                              {
                                item.attachments.map((attachment, idx) => (
                                  <img
                                    key={idx}
                                    src={attachment.url}
                                    alt="Attachment"
                                  />
                                ))
                              }
                            </div>
                          }

                          {
                            !!item.documents.length && editMainMessage !== item.message_id &&
                            <div className='documents-table'>
                              <table className='table'>
                                <tbody>
                                  <tr style={{ height: 0 }}></tr>
                                  {
                                    item.documents.map(item => {
                                      if (item.document_type === 'appointment') {
                                        return (
                                          <tr key={item.document_id}>
                                            <td>
                                              <strong>{item.document_type}</strong>
                                            </td>
                                            <td
                                              onClick={() => {
                                                window.open(`${window.location.origin}/${activeAccountId}/appointments/${(item.document_details as AppointmentsProps).appointment_id}`, "_blank", 'noopener,noreferrer')
                                              }}
                                            >
                                              <div className='doc-open'>
                                                <Icon icon="magnifier-5" />
                                              </div>
                                            </td>
                                            <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as AppointmentsProps).created_at)}</td>
                                            <td>{(item.document_details as AppointmentsProps).name}</td>
                                            <td>
                                              {(item.document_details as AppointmentsProps).type === 'Recall' ? (
                                                <span className="red">
                                                  {(item.document_details as AppointmentsProps).type}
                                                </span>
                                              ) : (item.document_details as AppointmentsProps).type === 'Service call' ? (
                                                <span className="green">
                                                  {(item.document_details as AppointmentsProps).type}
                                                </span>
                                              ) : (
                                                <span className="blue">
                                                  {(item.document_details as AppointmentsProps).type}
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              {
                                                item.document_details.status === 'Canceled' ?
                                                  <span className="red">
                                                    {item.document_details.status}
                                                  </span> :
                                                  <span>
                                                    {item.document_details.status}
                                                  </span>
                                              }
                                            </td>
                                            <td>{(item.document_details as AppointmentsProps).property_type}</td>
                                            <td>{(item.document_details as AppointmentsProps).area}</td>
                                            <td>{(item.document_details as AppointmentsProps).dispatcher_code ? `${(item.document_details as AppointmentsProps).created_by} (${(item.document_details as AppointmentsProps).dispatcher_code})` : (item.document_details as AppointmentsProps).created_by}</td>
                                            <td>{getSchuduleTime((item.document_details as AppointmentsProps).schedule_time_start as string, (item.document_details as AppointmentsProps).schedule_time_finish as string, (item.document_details as AppointmentsProps).time_zone)}</td>
                                            <td>{(item.document_details as AppointmentsProps).service_resource_code ? `${(item.document_details as AppointmentsProps).service_resource}(${(item.document_details as AppointmentsProps).service_resource_code})` : (item.document_details as AppointmentsProps).service_resource}</td>
                                          </tr>
                                        )
                                      } else if (item.document_type === 'job') {
                                        return (
                                          <tr key={item.document_id}>
                                            <td>
                                              <strong>{item.document_type}</strong>
                                            </td>
                                            <td
                                              onClick={() => {
                                                window.open(`${window.location.origin}/${activeAccountId}/jobs/${(item.document_details as JobsProps).job_id}`, "_blank", 'noopener,noreferrer')
                                              }}
                                            >
                                              <div className='doc-open'>
                                                <Icon icon="magnifier-5" />
                                              </div>
                                            </td>
                                            <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as JobsProps).created_at)}</td>
                                            <td>{(item.document_details as JobsProps).name}</td>
                                            <td>
                                              {
                                                item.document_details.status === 'Canceled' ?
                                                  <span className="red">
                                                    {item.document_details.status}
                                                  </span> :
                                                  <span>
                                                    {item.document_details.status}
                                                  </span>
                                              }
                                            </td>
                                            <td>{(item.document_details as JobsProps).property_type}</td>
                                            <td>{(item.document_details as JobsProps).area}</td>
                                            <td>{(item.document_details as JobsProps).source}</td>
                                            <td>{(item.document_details as JobsProps).created_by}</td>
                                            <td>{(item.document_details as JobsProps).appointments}</td>
                                            <td>{(item.document_details as JobsProps).total}</td>
                                          </tr>
                                        )
                                      } else if (item.document_type === 'ticket') {
                                        return (
                                          <tr key={item.document_id}>
                                            <td>
                                              <strong>{item.document_type}</strong>
                                            </td>
                                            <td
                                              onClick={() => {
                                                window.open(`${window.location.origin}/${activeAccountId}/tickets/${(item.document_details as TicketsProps).ticket_id}`, "_blank", 'noopener,noreferrer')
                                              }}
                                            >
                                              <div className='doc-open'>
                                                <Icon icon="magnifier-5" />
                                              </div>
                                            </td>
                                            <td style={{ position: 'relative', paddingRight: '25px' }}>
                                              {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as TicketsProps).date_time_start)}
                                              {
                                                item.document_details.status !== 'Closed' &&
                                                item.document_details.status !== 'Canceled' &&
                                                <span
                                                  style={{
                                                    position: 'absolute',
                                                    right: '5px',
                                                    width: '14px',
                                                    height: '14px',
                                                    background: item.document_details.status === 'In-progress' ? '#FFB700' : '#FF0000',
                                                    borderRadius: '50%',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    top: '3px'
                                                  }}
                                                >!</span>
                                              }
                                            </td>
                                            <td>{(item.document_details as TicketsProps).number}</td>
                                            <td
                                              className={classNames('status',
                                                {
                                                  canceled: item.document_details.status === 'Canceled',
                                                  closed: item.document_details.status === 'Closed',
                                                  initial: item.document_details.status !== 'Canceled' && item.document_details.status !== 'Closed'
                                                }
                                              )}
                                            >
                                              {item.document_details.status}
                                            </td>
                                            <td>{(item.document_details as TicketsProps).type}</td>
                                            <td>{(item.document_details as TicketsProps).theme}</td>
                                            <td> {(item.document_details as TicketsProps).author_dispatcher || (item.document_details as TicketsProps).author_user}</td>
                                          </tr>
                                        )
                                      } else if (item.document_type === 'call') {
                                        return (
                                          <tr key={item.document_id}>
                                            <td>
                                              <strong>{item.document_type}</strong>
                                            </td>
                                            <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, (item.document_details as CallsProps).created_at)}</td>
                                            <td>
                                              <button
                                                className={classNames('_zeroed', '_iconed', {
                                                  _red: ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                                  _green: ['completed', 'ringing', 'in-progress'].includes((item.document_details as CallsProps).status) && (item.document_details as CallsProps).direction === 'inbound',
                                                  _blue: (item.document_details as CallsProps).direction === 'outbound'
                                                })}
                                              >
                                                <Icon icon="arrow-20" className={classNames({
                                                  '_rotated-180': (item.document_details as CallsProps).direction === 'outbound'
                                                })} />
                                              </button>
                                            </td>

                                            <td>{(item.document_details as CallsProps).caller_name}</td>
                                            <td>{(item.document_details as CallsProps).client ? (item.document_details as CallsProps).client : null}</td>
                                            <td>
                                              <span>
                                                {(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_to) : formatPhoneNumber((item.document_details as CallsProps).call_from)}
                                              </span>
                                            </td>
                                            <td>{(item.document_details as CallsProps).friendly_name}</td>
                                            <td>{(item.document_details as CallsProps).direction === 'outbound' ? formatPhoneNumber((item.document_details as CallsProps).call_from) : formatPhoneNumber((item.document_details as CallsProps).call_to)}</td>
                                            <td>{(item.document_details as CallsProps).extension}</td>
                                            <td>{(item.document_details as CallsProps).is_appointment}</td>
                                            <td className={classNames({
                                              'red-text': ['no-answer', 'busy', 'canceled'].includes((item.document_details as CallsProps).status),
                                              'green-text': (item.document_details as CallsProps).status === 'completed',
                                              'blue-text': !['no-answer', 'busy', 'completed', 'canceled'].includes((item.document_details as CallsProps).status),
                                            })}>{(item.document_details as CallsProps).status} {(item.document_details as CallsProps).no_answered_reason ? `(${(item.document_details as CallsProps).no_answered_reason})` : ''} </td>
                                            <td>{(item.document_details as CallsProps).duration}</td>
                                            <td>
                                              {(item.document_details as CallsProps).call_url ? (
                                                <button
                                                  className="_zeroed _iconed"
                                                  onClick={() => setCallListenPopup((item.document_details as CallsProps).call_url)}
                                                >
                                                  <Icon icon="media-control-48" />
                                                </button>
                                              ) : null}
                                            </td>
                                          </tr>
                                        )
                                      } else {
                                        return null
                                      }
                                    })
                                  }
                                </tbody>
                              </table>
                            </div>
                          }

                          {
                            !!item.emojis.length &&
                            <EmojisRow
                              emojis={item.emojis}
                              getUserId={getUserId}
                              permissions={permissions}
                              message_id={item.message_id}
                              removeReaction={removeReplyReaction}
                              addSameReaction={addSameReplyReaction}
                              setShowMainEmojiPikerReaction={setShowReplyEmojiPikerReaction}
                            />
                          }

                          {
                            showReplyEmojiPikerReaction === item.message_id &&
                            <EmojyPicker
                              setShow={setShowReplyEmojiPikerReaction}
                              hanldeAddReaction={(name) => addReplyReaction(item.message_id, name)}
                            />
                          }

                          {
                            showReplyMenu === item.message_id &&
                            <div className='popup-menu'>
                              {
                                !permissions.is_read_only &&
                                <button
                                  onClick={() => setShowReplyEmojiPikerReaction(item.message_id)}
                                >
                                  <Icon viewBox="0 0 1177 1024" icon="emoji-111" />
                                </button>
                              }

                              {
                                !permissions.is_read_only &&
                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    setShowAdditionalyMenu(item.message_id)
                                  }}
                                >
                                  <Icon viewBox="0 0 16 16" icon="dots-1" />
                                </button>
                              }

                              {
                                showAdditionalyMenu === item.message_id &&
                                <div ref={contextMenuRef} className='popup-menu-additionaly'>
                                  <div
                                    className='additional-item'
                                    style={{
                                      color: getTimeForEnd(item.created_at) ? 'inherit' : '#FF0000',
                                      opacity: getTimeForEnd(item.created_at) ? '1' : '.5',
                                      cursor: getTimeForEnd(item.created_at) ? 'pointer' : 'inherit'
                                    }}
                                    onClick={() => {
                                      getTimeForEnd(item.created_at) && setShowAdditionalyMenu('')
                                      getTimeForEnd(item.created_at) && setShowMainMenu('')
                                      getTimeForEnd(item.created_at) && setEditMainMessage(item.message_id)
                                      //@ts-ignore
                                      getTimeForEnd(item.created_at) && setEditText(item.message)
                                    }}
                                  >
                                    Edit Message
                                    {
                                      !!seconds &&
                                      <span style={{ marginLeft: '2px', fontSize: '14px', color: '#FF0000' }}> ({seconds}) </span>
                                    }
                                  </div>

                                  {
                                    !!permissions.can_delete &&
                                    <div
                                      className='additional-item'
                                      onClick={() => {
                                        setShowAdditionalyMenu('')
                                        setShowMainMenu('')
                                        deleteReplyMessage(item.message_id)
                                      }}
                                    >
                                      Delete Message
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          }
                        </div>
                      </React.Fragment>
                    ))
                  }

                  {
                    !permissions.is_read_only && (editMainMessage === '' || seconds === 0 || !chatReplyReport.filter(item => item.message_id === editMainMessage).length) &&
                    <div
                      style={{ height: getHeight(selectedReplyDocuments) }}
                      className='message-block'
                      onDrop={(event) => {
                        dragingReply && onDrop(event, 'reply')
                      }}
                    >
                      {
                        showReplyEmojiPiker &&
                        <EmojyPicker
                          is_text={true}
                          setShow={() => setShowReplyEmojiPiker(false)}
                          hanldeAddReaction={(name) => handleAddEmojiInTextReply(name)}
                        />
                      }

                      {
                        dragingReply &&
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%'
                          }}
                        >
                          Upload a File
                        </div>
                      }

                      <EditorReply
                        draging={dragingReply}
                        valueText={replyText}
                        updatedText={updatedText}
                        refComponent={textAreaRaply}
                        openChannelUsers={openReplyChannelUsers}
                        setOpenChannelUsers={setOpenReplyChannelUsers}
                        usersOption={usersOption}
                        filterUser={filterUser}
                        openMenuChannelUsers={openMenuReplyChannelUsers}
                        setOpenMenuChannelUsers={setOpenMenuReplyChannelUsers}
                        setFilterUser={setFilterUser}
                        showMainEmojiPiker={showReplyEmojiPiker}
                        setShowMainEmojiPiker={setShowReplyEmojiPiker}
                        channel_user_id={permissions.channel_user_id}
                        sendMessage={sendReplyMessage}
                        setOpenClipMain={setOpenClipReply}
                        openClipMain={openClipReply}
                        selectedDocuments={selectedReplyDocuments}
                        setSelectedDocuments={setSelectedReplyDocuments}
                        chatReplyReportLength={chatReplyReport.length}
                      />
                    </div>
                  }
                </div>
              </div>
            }
          </React.Fragment>
      }

      {
        openModal &&
        <ModalImage
          image={selectedImage}
          setOpen={setOpenModal}
        />
      }

      {callListenPopup ? (
        <div className="popup callListenPopup" onClick={() => setCallListenPopup(null)}>

          <div className="wrapper" onClick={(e) => e.stopPropagation()}>

            <div className="call-player">
              <AudioPlayer audioSrc={callListenPopup} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
