import { useEffect, useState } from "react";
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import Select from "../../components/Select"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: TamplateData
}

interface TamplateData {

  permissions: {
    notify_template_delete: boolean
    notify_template_edit: boolean
  },

  notify_template: NotifyTemplateProps,
}

interface NotifyTemplateProps {
  created_at: string
  created_by: string
  notify_subject: string
  notify_template_id: string
  notify_text: string
  notify_type: string
  type: string
  updated_at: string
  updated_by: string
}

function NotifyTemplatesPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<TamplateData | null>(null)

  const [newTemplateData, setNewTemplateData] = useState<Partial<NotifyTemplateProps>>({
    notify_type: '',
    type: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    if (reportData) {

      setNewTemplateData({
        ...reportData.notify_template,
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/notify-templates/88888601wgntio547d
      const response = await httpClientUpdate.delete(`/notify-templates/${$router.router.getState().params.notify_templates_id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('notify-templates', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/notify-templates/88888601wgntio547d
      const response = await httpClientUpdate.put(`/notify-templates/${$router.router.getState().params.notify_templates_id}`,
        {
          account_id: activeAccountId,
          type: newTemplateData.type,
          notify_text: newTemplateData.notify_text,
          notify_subject: newTemplateData.notify_subject,
          notify_type: newTemplateData.notify_type,
        })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/notify-templates/88888601wgntio547d?account_id=88888
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/notify-templates/' + $router.router.getState().params.notify_templates_id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(loadData)
        setNewTemplateData(loadData.notify_template)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newTemplateData
  ])

  // Render function
  return (<>
    {reportData ? (
      <div className="NotifyTemplatesPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Notify Template</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'notify-templates',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                reportData?.permissions.notify_template_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Type:</span>

                <Select
                  disabled={!editing}
                  options={['phone', 'email'].map((action) => ({
                    span: action,
                    value: action
                  }))}
                  selectedOption={newTemplateData.type ? newTemplateData.type : ''}
                  onChange={(value) => setNewTemplateData({ ...newTemplateData, type: value as string })}
                />
              </div>

              <div className="field">
                <span>Notify Subject:</span>

                <input
                  type="text"
                  disabled={!editing}
                  value={newTemplateData.notify_subject}
                  onChange={({ target: { value } }) => setNewTemplateData({ ...newTemplateData, notify_subject: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Notify Type:</span>

                <Select
                  disabled={!editing}
                  options={['payment'].map((action) => ({
                    span: action,
                    value: action
                  }))}
                  selectedOption={newTemplateData.notify_type ? newTemplateData.notify_type : ''}
                  onChange={(value) => setNewTemplateData({ ...newTemplateData, notify_type: value as string })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Template</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <textarea
                  style={{ resize: 'none' }}
                  disabled={!editing}
                  value={newTemplateData.notify_text || ''}
                  onChange={({ target: { value } }) => setNewTemplateData({ ...newTemplateData, notify_text: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Template View</div>

          <div
            style={{
              width: '100%',
              minHeight: '50px',
              padding: '10px',
              border: '1px solid #d0d3da',
              borderRadius: '5px',
              marginTop: '30px',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: newTemplateData.notify_text ? newTemplateData.notify_text : '' }}></div>
          </div>

        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.notify_template.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={reportData?.notify_template.created_by} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.notify_template.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={reportData?.notify_template.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.notify_template_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Template</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {reportData.permissions.notify_template_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Template
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the notify template it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default NotifyTemplatesPage_Item
