import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import { Permission } from "../../models/Permission"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

export interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PermissionsReport
}

export interface PermissionsReport {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    user_permission_add: boolean
    user_permission_delete: boolean
    user_permission_edit: boolean
    user_permission_report_show: boolean
    user_permission_show: boolean
  },

  permissions_rows: Permission[],

  edit: {
    actions: ActionProp[]
  }
}

interface ActionProp {
  id: string,
  show: string
}

function PermissionsPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<PermissionsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    sort: {
      field: cookiesSetting && cookiesSetting?.permissions?.sort_field ? cookiesSetting.permissions.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.permissions?.sort_type ? cookiesSetting.permissions.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Users',
      value: 'users'
    }]

  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.permissions?.sort_field ? cookiesSetting.permissions.sort_field : 'name',
        direction: cookiesSetting && cookiesSetting?.permissions?.sort_type ? cookiesSetting.permissions.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load permissions function
  async function loadPermissions() {
    try {
      let filterParams = {}

      if ($router.router.getState().params) {
        if ($router.router.getState().params.localInterface) {
          filterParams = {
            page: $router.router.getState().params.localInterface.page,
            sort_field: $router.router.getState().params.localInterface.sort.field,
            sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
            ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
          }
        } else {
          filterParams = {
            page: localInterface.page,
            sort_field: localInterface.sort.field,
            sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
            ...(localInterface.search ? { search: localInterface.search } : {})
          }

          setCookie('settings', { ...cookiesSetting, permissions: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.permissions?.limit_rows ? cookiesSetting?.permissions.limit_rows : reportsMaxRows } })
        }
      }

      const { data: { data: permissions, success, error } } = (await httpClientUpdate.post('/permissions/users/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.permissions?.limit_rows ? cookiesSetting?.permissions?.limit_rows : reportsMaxRows,
        ...filterParams,
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(permissions)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, permissions: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load permissions on page mount
  useEffect(() => {
    loadPermissions()

    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData && reportData.permissions.user_permission_report_show ? (
      <div className="PermissionsPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Permissions</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.permissions?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, permissions: { ...cookiesSetting.permissions, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.user_permission_add ? (
              <div className="add-button-wrapper">
                <button
                  className="_iconed _rounded add-button"
                  onClick={() => $router.router.navigate('permissions.new', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                  })}>
                  <Icon icon="plus-thin" />
                  <span>Add permission</span>
                  <Icon icon="shield-28" />
                </button>
              </div>
            ) : <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Users</span>)}

                  sortDirection={localInterface.sort.field === 'users' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'users', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.permissions.user_permission_report_show &&
              reportData.permissions_rows.map((permission, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.user_permission_show && permission.permission_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.user_permission_show &&
                    permission.permission_id &&
                    $router.router.navigate('permissions.item', {
                      companyId: activeAccountId,
                      permissionId: permission.permission_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.user_permission_show &&
                    permission.permission_id &&
                    handleChangeMenu(event, permission.permission_id, 'permissions', i)}
                >
                  <td>{permission.name}</td>
                  <td>
                    {permission.users}
                    {
                      reportData.permissions.user_permission_show &&
                      showContext === `permissions_${permission.permission_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('permissions.item', {
                              companyId: activeAccountId,
                              permissionId: permission.permission_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/permissions/${permission.permission_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
          </table>

          {/* Medium desktop table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Users</th>
              </tr>
            </thead>

            {reportData.permissions.user_permission_report_show &&
              reportData.permissions_rows.map((permission, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.user_permission_show && permission.permission_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.user_permission_show &&
                    permission.permission_id &&
                    $router.router.navigate('permissions.item', {
                      companyId: activeAccountId,
                      permissionId: permission.permission_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.user_permission_show &&
                    permission.permission_id &&
                    handleChangeMenu(event, permission.permission_id, 'permissions', i)}
                >
                  <td>{permission.name}</td>
                  <td>
                    {permission.users}
                    {
                      reportData.permissions.user_permission_show &&
                      showContext === `permissions_${permission.permission_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('permissions.item', {
                              companyId: activeAccountId,
                              permissionId: permission.permission_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/permissions/${permission.permission_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            {reportData.permissions.user_permission_report_show &&
              reportData.permissions_rows.map((permission, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    reportData.permissions.user_permission_show &&
                    permission.permission_id &&
                    $router.router.navigate('permissions.item', {
                      companyId: activeAccountId,
                      permissionId: permission.permission_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><b>{permission.name}</b></div>
                      <div>
                        {permission.users || <span>&nbsp;</span>}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.permissions?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, permissions: { ...cookiesSetting.permissions, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default PermissionsPage_List
