import { createAsyncThunk } from "@reduxjs/toolkit";

import { httpClientUpdate } from "../funcs";

import {
  setAccessToken,
  setAccounts,
  setActiveAccountId,
  setUser,
  setScheduleDay,
  setScheduleFrame,
} from "./reducer";

type ArgProps = {
  accessToken: string | null;
};

// signOut
export const signOut = createAsyncThunk(
  "user/signOut",
  async (arg: ArgProps, { dispatch }) => {
    try {
      let response = await httpClientUpdate.post("/logout");
      if (response.status === 200) {
        delete httpClientUpdate.defaults.headers["Authorization"];

        dispatch(setUser(null));
        dispatch(setAccessToken(null));
        dispatch(setAccounts([]));
        dispatch(setActiveAccountId(null));
        dispatch(setScheduleDay("one"));
        dispatch(
          setScheduleFrame({
            min_time: "8AM",
            max_time: "8PM",
          })
        );
      }
    } catch (error) {
      delete httpClientUpdate.defaults.headers["Authorization"];
      dispatch(setUser(null));
      dispatch(setAccessToken(null));
      dispatch(setAccounts([]));
      dispatch(setActiveAccountId(null));
      dispatch(setScheduleDay("one"));
      dispatch(
        setScheduleFrame({
          min_time: "8AM",
          max_time: "8PM",
        })
      );
    }
  }
);
