import { useEffect, useState } from 'react';
import { BaseLink, useRoute } from 'react-router5';
import classNames from 'classnames';

import Icon from '../../components/Icon';
import Switcher from '../../components/Switcher';
import Checkbox from '../../components/Checkbox';

import { useAppSelector } from '../../store/hooks';
import { httpClientUpdate, nErrorUpdate } from '../../funcs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    page: PageProps
  }
}

interface PageProps {
  active: boolean
  city: string
  created_at: string
  created_by: string
  deleted_at: string
  description: string
  header_1: string
  header_2_1: string
  header_2_2: string
  header_h1: string
  page_id: string
  service_areas: string
  service_city: string
  state: string
  text_1: string
  text_2: string
  title: string
  type: string
  updated_by: string
  url: string
}

export default function ItemPage() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState<PageProps | null>(null)
  const [report, setReport] = useState<PageProps | null>(null)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    setReadyToSave(true)
  }, [data])

  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/websites/pages/{PAGE_ID}
      const { data: { data: response, success, error } } = (await httpClientUpdate.get(`/websites/pages/${$router.router.getState().params.pageId}`, {
        params: {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
        }
      })) as { data: HttpReport }
      if (success) {
        setData(response.page)
        setReport(response.page)

        setTimeout(() => setReadyToSave(false), 100)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    try {
      if (data) {
        setIsSaving(true)
        // https://2022back4.artemiudintsev.com/api/websites/pages/{PAGE_ID}
        const response = await httpClientUpdate.put(`/websites/pages/${$router.router.getState().params.pageId}`, {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
          type: data.type,
          state: data.state,
          city: data.city,
          active: data.active ? 1 : 0,
          title: data.title,
          description: data.description,
          header_h1: data.header_h1,
          header_1: data.header_1,
          text_1: data.text_1,
          header_2_1: data.header_2_1,
          header_2_2: data.header_2_2,
          text_2: data.text_2,
          service_city: data.service_city,
          service_areas: data.service_areas,
        })

        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadData()
        } else {
          setIsSaving(false)
        }
      }

    } catch (error) {
      setIsSaving(false)
    }
  }

  async function handleRemove() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites/pages/{PAGE_ID}
      const response = await httpClientUpdate.delete(`/websites/pages/${$router.router.getState().params.pageId}`, {
        data: {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('websites.pages', {
          companyId: activeAccountId,
          websiteId: $router.router.getState().params.websiteId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleCancelEdit() {
    if (report) {
      setData({ ...report })
      setEditing(false)
    }
  }

  return (
    <>
      <div className="ListsPage_Appliances">
        <div className="top-nav top-nav-list">
          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.main"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.main" })}
            >
              <Icon icon="task-1" />
              <span>Main</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.settings"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.settings" })}
            >
              <Icon icon="task-1" />
              <span>Settings</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.pages"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.pages" })}
            >
              <Icon icon="task-1" />
              <span>Pages</span>
            </BaseLink>
          </div>

          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.phone_codes"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "phone_codes" })}
            >
              <Icon icon="task-1" />
              <span>Phone Codes</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.phones"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.phones" })}
            >
              <Icon icon="task-1" />
              <span>Phones</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.links"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.links" })}
            >
              <Icon icon="task-1" />
              <span>Links</span>
            </BaseLink>
          </div>
        </div>
      </div>

      {
        data &&
        <div className="AccountsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Page:</h1>
            </div>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() => $router.router.navigate('websites.pages', {
                  companyId: activeAccountId,
                  websiteId: $router.router.getState().params.websiteId,
                }, {
                  reload: true
                })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', minWidth: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>State:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.state}
                    onChange={({ target: { value } }) => setData({ ...data, state: value })}
                  />
                </div>

                <div className="field">
                  <span>City:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.city}
                    onChange={({ target: { value } }) => setData({ ...data, city: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Type:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.type}
                    onChange={({ target: { value } }) => setData({ ...data, type: value })}
                  />
                </div>

                <div className="field">
                  <span>Active:</span>

                  <Switcher
                    disabled={!editing}
                    checked={data.active}
                    onChange={(value) => setData({ ...data, active: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Service Area</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>City:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.service_city}
                    onChange={({ target: { value } }) => setData({ ...data, service_city: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Areas:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.service_areas}
                    onChange={({ target: { value } }) => setData({ ...data, service_areas: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Meta</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Title:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.title}
                    onChange={({ target: { value } }) => setData({ ...data, title: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Description:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.description}
                    onChange={({ target: { value } }) => setData({ ...data, description: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Content</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Header h1:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.header_h1}
                    onChange={({ target: { value } }) => setData({ ...data, header_h1: value })}
                  />
                </div>

                <div className="field">
                  <span>Header 1:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.header_1}
                    onChange={({ target: { value } }) => setData({ ...data, header_1: value })}
                  />
                </div>

                <div className="field">
                  <span>Text 1:</span>

                  <textarea
                    disabled={!editing}
                    defaultValue={data.text_1}
                    onChange={({ target: { value } }) => setData({ ...data, text_1: value })}
                    rows={20}
                    style={{ resize: 'none' }}
                  ></textarea>
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Header 2.1:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.header_2_1}
                    onChange={({ target: { value } }) => setData({ ...data, header_2_1: value })}
                  />
                </div>

                <div className="field">
                  <span>Header 2.2:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={data.header_2_2}
                    onChange={({ target: { value } }) => setData({ ...data, header_2_2: value })}
                  />
                </div>

                <div className="field">
                  <span>Text 2:</span>

                  <textarea
                    disabled={!editing}
                    defaultValue={data.text_2}
                    onChange={({ target: { value } }) => setData({ ...data, text_2: value })}
                    rows={20}
                    style={{ resize: 'none' }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Page</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>
              <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                <div className="title">
                  Delete Page
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox
                    contents="I understand that after deleting the page it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">
                  <button
                    className="_bordered _green"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemove()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
    </>
  )
}
