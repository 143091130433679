import { memo } from 'react'

import Select from '../Select'
import Icon from "../Icon"
import { useAppSelector } from '../../store/hooks'

interface StatusControlProps {
  statusOptions: string[],
  status: string,
  setStatus: (value: string) => void

  togglePhoneCall: () => void

  volume: string,
  handleVolume: (value: string) => void

  softphone_calls: boolean | undefined

  handleMuteCall: () => void

  setSelectedCallCenter: (value: string) => void
  selectedCallCenter: string
}

function StatusControl({
  statusOptions,
  status,
  setStatus,
  togglePhoneCall,
  volume,
  handleVolume,
  softphone_calls,
  handleMuteCall,
  setSelectedCallCenter,
  selectedCallCenter,
}: StatusControlProps) {
  const user = useAppSelector((store) => store.user)
  return (
    <div className='section__account_softphone-control'>
      {
        user?.call_centers && user?.call_centers.length > 1 &&
        <div
          className='section__account_softphone-control_block'
          style={{ justifyContent: 'flex-start' }}
        >
          <div className='section__account_softphone-control_call_center'>
            Call Center:
          </div>
          <Select
            zeroed={true}
            options={user.call_centers.map((item) => ({
              span: item.name,
              value: item.call_center_id
            }))}
            selectedOption={selectedCallCenter}
            onChange={(value: string) => setSelectedCallCenter(value)}
          />
        </div>
      }
      <div className='section__account_softphone-control_block'>
        <div className='section__account_softphone-control_status'>
          <div className='section__account_softphone-control_presence'>
            <div className='section__account_softphone-control_presence-symbol'>
              {
                status === 'Available' &&
                <Icon
                  style={{ fill: '#01B41F' }}
                  viewBox="0 0 16 16"
                  icon="check-3"
                />
              }
              {
                status === 'Outgoing Only' &&
                <Icon
                  style={{ fill: '#ffb700' }}
                  viewBox="0 0 16 16"
                  icon="check-3"
                />
              }
              {
                status === 'Not Available' &&
                <Icon
                  style={{ fill: '#FF0000', width: '90%' }}
                  icon="x-mark-1"
                />
              }
            </div>
            <Select
              zeroed={true}
              disabled={!softphone_calls}
              options={statusOptions.map((item) => ({
                span: item,
                value: item
              }))}
              selectedOption={status}
              onChange={(value: string) => setStatus(value)} />
          </div>
        </div>

        <div className='section__account_softphone-control_quit'>
          <button onClick={() => togglePhoneCall()} >
            <Icon viewBox="0 0 17 17" icon="quit-1" />
          </button>
        </div>
      </div>

      <div className='section__account_softphone-control_block'>
        <div className='section__account_softphone-control_volume'>
          <div
            className='section__account_softphone-control_volume-icon'
            onClick={handleMuteCall}
          >
            <Icon
              style={{ width: '6px', height: '10px', fill: '#8E8E93', cursor: 'pointer' }}
              viewBox="0 0 6 10"
              icon="volume-1"
            />

          </div>

          <div className='section__account_softphone-control_volume-container'>
            <span className='section__account_softphone-control_volume-bar'>
              <span
                style={{ width: `${volume}%` }}
                className='section__account_softphone-control_volume-fill'></span>
            </span>

            <input
              onChange={(event) => handleVolume(event.target.value)}
              value={volume}
              className='section__account_softphone-control_volume-input'
              type="range"
              min={0}
              max={100}
            />
          </div>

          <div
            className='section__account_softphone-control_volume-icon'
            onClick={handleMuteCall}
          >
            <Icon
              style={{ width: '14px', height: '14px', fill: '#8E8E93', cursor: 'pointer' }}
              viewBox="0 0 14 14"
              icon="volume-2"
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(StatusControl)
