import { useState } from 'react'
import moment from 'moment';
import classNames from 'classnames';

import Checkbox from '../../components/Checkbox'

import { ChangeAppointProps } from './GantChart';

interface CancelAppointmentProps {
  appointment: ChangeAppointProps,
  setCancel: (value: ChangeAppointProps | null) => void
}

export default function CancelAppointment({ setCancel, appointment }: CancelAppointmentProps) {
  const [readyToDelete, setReadyToDelete] = useState({
    customer: false,
    technician: false,
    client: false,
    service: false
  })
  const [reason, setReason] = useState('')

  function getAppointInfo() {
    let serviceName = appointment.service_resource_code ? `${appointment.service_resource_nickname}(${appointment.service_resource_code})` : appointment.service_resource_nickname
    return `${moment(appointment.appointment_date_start, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} - ${moment(appointment.appointment_date_end, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} ${serviceName}`
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '90vh',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99999',
      }}
      onClick={() => setCancel(null)}
    >

      <div className="appointment-cancel-confirmation appointment-cancel-confirmation-popup" onClick={(e) => e.stopPropagation()}>
        <div className='appointment-cancel-confirmation__header' >
          <p className='appointment-cancel-confirmation__job-number'>
            {appointment.appointment_job_number}
          </p>

          <div className='appointment-cancel-confiramtion__header-text'>
            <p>
              Cancel an Appointment
            </p>
          </div>

          <div className='appointment-cancel-confirmation__appointment-info'>
            <div className='appointment-cancel-confirmation__appointment-info-item'>
              <div className='appointment-cancel-confirmation__date'>
                {getAppointInfo()}
              </div>
            </div>
          </div>
        </div>

        <div className='appointment-cancel-confirmation__questions-wrapper'>
          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_cancel-reason'>
            <p className='appointment-cancel-confirmation__questions-item_cancel-reason'>
              Reason of cancelation:
            </p>

            <textarea
              rows={5}
              cols={30}
              className='appointment-cancel-confirmation__questions-item_cancel-reason-textarea'
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </div>
        </div>

        <div className='appointment-cancel-confirmation__questions'>
          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_client-informed'>
            <Checkbox
              contents="I understand that the customer must be informed of the changes"
              value={readyToDelete.customer}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                customer: value
              })}
            />
          </div>

          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_service-resource-informed'>
            <Checkbox
              contents="I understand that the technician must be informed of the changes"
              value={readyToDelete.technician}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                technician: value
              })}
            />
          </div>
        </div>

        <div className='appointment-cancel-confirmation__questions'>
          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_client-notification'>
            <Checkbox
              contents="Send notification client"
              value={readyToDelete.client}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                client: value
              })}
            />
          </div>

          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_service-resource-notification'>
            <Checkbox
              contents="Send notification service resource"
              value={readyToDelete.service}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                service: value
              })}
            />
          </div>
        </div>

        <div className='appointment-cancel-confirmation__btns'>
          <div
            className='appointment-cancel-confirmation__deny-btn'
            onClick={() => setCancel(null)}
          >
            Cancel
          </div>

          <div
            className={classNames('appointment-cancel-confirmation__confirm-btn',
            {
              btn_disabled: !readyToDelete.customer || !readyToDelete.technician || reason === ''
            }
            )}
            onClick={() => setCancel(null)}
          >
            Save
          </div>
        </div>
      </div>
    </div >
  )
}
