import classNames from "classnames"

import "../styles/components/switcher.sass"

interface SwitcherProps {

  checked?: boolean,
  onChange(value: boolean): void,

  disabled?: boolean
}

function Switcher({ checked, disabled, onChange }: SwitcherProps) {

  return (
    <div className={classNames('Switcher', {
      _active: checked
    })} onClick={() => !disabled && onChange(!checked)}>
      <div className="notch"></div>
    </div>
  )
}

export default Switcher
