import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import qs from "qs";

import Icon from "../../components/Icon"
import SelectWithSearchComponent from "../../components/SelectWithSearch";
import Switcher from "../../components/Switcher"
import Checkbox from "../../components/Checkbox"

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../funcs";
import { useAppSelector } from '../../store/hooks'

import ServiceResource from "../../models/ServiceResource"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ServiceResourcesReport
}

interface ServiceResourcesReport {

  interface: {

    filter_words: {

      area: string[],
      time_zone: string[],
      active: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    service_resource_add: false
    service_resource_delete: false
    service_resource_edit: false
    service_resource_report_show: false
    service_resource_show: false
  },

  service_resources: ServiceResource[],
  edit: {
    users: {
      user_id: string,
      user: string
      time_zone_id: number
    }[],
    time_zone: {
      id: number
      name: string
    }[],
    area: {
      area: string
      area_id: string
      area_time_zone: string
    }[]
    mentors: {
      service_resource: string
      user_name: string
      user_id: string
    }[]
  }
}

function ServiceResourcesPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [edit, setEdit] = useState<{
    users: {
      user_id: string,
      user: string
      time_zone_id: number
    }[],
    time_zone: {
      id: number
      name: string
    }[],
    area: {
      area: string
      area_id: string
      area_time_zone: string
    }[],
    mentors: {
      service_resource: string
      user_name: string
      user_id: string
    }[]
  } | null>(null)

  const [newServiceResourceData, setNewServiceResourceData] = useState<Partial<ServiceResource> | null>(null)

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`
      const response = await httpClientUpdate.post(`/serviceresources`, {
        account_id: activeAccountId,
        nickname: newServiceResourceData?.name,
        available: newServiceResourceData?.active ? 1 : 0,
        area_id: newServiceResourceData?.area ? newServiceResourceData?.area : null,
        user_id: newServiceResourceData?.user ? newServiceResourceData?.user : null,
        phone: newServiceResourceData?.phone && formatPhoneNumberToServerString(newServiceResourceData.phone),
        slack_channel: newServiceResourceData?.slack_channel,
        slack_member_id: newServiceResourceData?.slack_member_id,
        is_phone: newServiceResourceData?.is_phone ? 1 : 0,
        is_slack: newServiceResourceData?.is_slack ? 1 : 0,
        code: newServiceResourceData?.code,
        priority: newServiceResourceData?.priority,
        mentor_id: newServiceResourceData?.mentor_id ? newServiceResourceData?.mentor_id : '',
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('service_resources', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    try {
      const { data: { data: serviceResourcesData, success, error } } = (await httpClientUpdate.post('/serviceresources/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        sort_field: 'nickname',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (serviceResourcesData.permissions.service_resource_add) {
          setEdit(serviceResourcesData.edit)

          setNewServiceResourceData({
            ...newServiceResourceData,

            name: '',
            active: true,

            user: '',
            time_zone: '',
            area: '',
            code: 0,
            priority: 0,

            phone: '',
            slack_channel: '',
            slack_member_id: '',

            is_phone: false,
            is_slack: false,
            mentor_id: '',
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load serviceResource data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Watch changes
  useEffect(() => {
    setReadyToSave(true)
  }, [
    newServiceResourceData
  ])

  function handleSelectArea(id: string) {
    if (id === '') {
      setNewServiceResourceData({ ...newServiceResourceData, area: id, time_zone: '' })
    } else {
      edit && edit.area.forEach(item => {
        if (item.area_id === id) {
          setNewServiceResourceData({ ...newServiceResourceData, area: id, time_zone: item.area_time_zone })
        }
      })
    }
  }

  // Render function
  return (<>
    {newServiceResourceData && edit ? (
      <div className="ServiceResourcesPage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>New Service Resource</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('service_resources', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>NickName:</span>

                <input type="text" value={newServiceResourceData.name} onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, name: value })} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Active:</span>

                <Switcher checked={newServiceResourceData.active as boolean} onChange={(value) => setNewServiceResourceData({ ...newServiceResourceData, active: value })} />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Settings</div>

          <div className="fields">
            <div className="__left">
              <div className="field" style={{ zIndex: 6 }}>
                <span>User:</span>

                <SelectWithSearchComponent
                  options={[{ user: '', user_id: '' }].concat(edit.users).map((user) => ({
                    span: user.user,
                    value: user.user_id
                  }))}
                  selectedOption={newServiceResourceData.user as string}
                  onChange={(value: string) => setNewServiceResourceData({
                    ...newServiceResourceData,
                    user: value,
                    mentor_id: '',
                  })}
                />
              </div>

              <div className="field" style={{ zIndex: 5 }}>
                <span>Area:</span>
                <SelectWithSearchComponent
                  options={[{ area: '', area_id: '' }].concat(edit.area).map((option) => ({
                    span: option.area,
                    value: option.area_id
                  }))}
                  selectedOption={newServiceResourceData.area as string}
                  onChange={(value: string) => handleSelectArea(value)}
                />
              </div>

              <div className="field">
                <span>Priority:</span>

                <input
                  type="number"
                  value={newServiceResourceData.priority || 0}
                  onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, priority: +value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Code:</span>

                <input
                  type="number"
                  value={newServiceResourceData.code}
                  onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, code: +value })}
                />
              </div>

              <div className="field">
                <span>Time Zone:</span>

                <input
                  type="text"
                  disabled={true}
                  value={newServiceResourceData.time_zone}
                  onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, time_zone: value })}
                />
              </div>

              <div className="field" style={{ zIndex: 5 }}>
                <span>Mentor:</span>
                <SelectWithSearchComponent
                  disabled={newServiceResourceData === null || newServiceResourceData.user === ''}
                  options={
                    [{ user_name: '', user_id: '', service_resource: '' }]
                      .concat(edit.mentors.filter(item => item.user_id !== newServiceResourceData.user))
                      .map((option) => ({
                        span: option.service_resource ? option.service_resource : option.user_name,
                        value: option.user_id
                      }))}
                  selectedOption={newServiceResourceData.mentor_id as string}
                  onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, mentor_id: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Notifications</div>

          <div className="fields">

            <div className="__left">

              <div className="field">
                <span>Phone:</span>
                <ReactInputMask
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={newServiceResourceData.phone}
                  onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, phone: value })}
                />
              </div>

              <div className="field">
                <span>Slack Channel:</span>

                <input
                  type="text"
                  defaultValue={newServiceResourceData.slack_channel}
                  onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, slack_channel: value })}
                />
              </div>

              <div className="field">
                <span>Slack Member ID:</span>

                <input
                  type="text"
                  defaultValue={newServiceResourceData.slack_member_id}
                  onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, slack_member_id: value })}
                />
              </div>

            </div>

            <div className="__right">

              <div className="field">
                <span>Phone:</span>

                <Checkbox contents='' value={newServiceResourceData.is_phone} onChange={(value) => setNewServiceResourceData({ ...newServiceResourceData, is_phone: value })} />
              </div>

              <div className="field">
                <span>Is Slack:</span>

                <Checkbox contents='' value={newServiceResourceData.is_slack} onChange={(value) => setNewServiceResourceData({ ...newServiceResourceData, is_slack: value })} />
              </div>

            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('service_resources', {
              companyId: activeAccountId,
            }, {
              reload: true
            })}>
              Cancel
            </button>
            <button
              className="_bordered _green"
              disabled={!readyToSave || isSaving}
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default ServiceResourcesPage_New
