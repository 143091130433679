
import React, { useState, useRef, useEffect, memo } from "react";
import { GoogleMap } from "@react-google-maps/api";

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

interface zipCodesViewProps {
  zip_code: string,
  isHave: boolean,
  lat: number,
  lng: number,
  place_id: string
}

interface RenderMapProps {
  selectedZipCode: zipCodesViewProps[]
  isLoaded: boolean
  loadError: Error | undefined
}

function ViewMap({
  selectedZipCode,
  isLoaded,
  loadError,
}: RenderMapProps) {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [center, setCenter] = useState({
    lat: selectedZipCode[0].lat,
    lng: selectedZipCode[0].lng,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [originalZoom, setOriginalZoom] = useState(8);
  const mapRef = useRef<google.maps.Map | null>(null);

  const [updated, setUpdated] = useState(Math.random())

  useEffect(() => {
    if (mapRef.current !== null && isLoaded) {
      initialMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapRef.current, isLoaded, updated])

  function initialMap() {
    try {
      //@ts-ignore
      const featureLayer = mapRef.current?.getFeatureLayer('POSTAL_CODE')

      mapRef.current?.setClickableIcons(false)

      const featureStyleOptions: google.maps.FeatureStyleOptions = {
        strokeColor: "#000000",
        strokeOpacity: 1.0,
        strokeWeight: 1.0,
        fillColor: "#000000",
        fillOpacity: 0.4,
      };

      if (featureLayer) {
        //@ts-ignore
        featureLayer.style = (options) => {

          // @ts-ignore
          if (selectedZipCode.map(item => item.place_id).includes(options.feature?.placeId)) {

            return featureStyleOptions
          }
        }
      }
    } catch (error) { }
  }

  if (loadError) return <div>Error loading maps</div>;

  if (!isLoaded)
    return (
      <div className="flex h-full flex-col">
        loading
      </div>
    );

  return (
    <div style={{ height: '100%' }} className="w-full">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
        // onZoomChanged={() => changeZoom()}
        onBoundsChanged={() => setUpdated(Math.random())}
        onLoad={(map) => {
          mapRef.current = map;
          // setOriginalZoom(12);
        }}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapId: 'ec4fc7638d3b957e',
        }}
      >
      </GoogleMap>
    </div>
  );
}

export default memo(ViewMap, (prevProps, nextProps) => {
  let check = true

  if (prevProps.selectedZipCode.length === nextProps.selectedZipCode.length) {
    prevProps.selectedZipCode.forEach((prevItem, index) => {
      if (prevItem.place_id !== nextProps.selectedZipCode[index].place_id) {
        check = false
      }
    })
  } else {
    check = false
  }

  return check
})