import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5';
import moment from 'moment';
import classNames from 'classnames';
import { useCookies } from "react-cookie";

import ReportFilters from '../../components/reports/Filters';
import Icon from '../../components/Icon';
import ReportTableControls from '../../components/reports/TableControls';
import ReportTableField from '../../components/reports/TableField';

import { httpClientUpdate, useOuterClick } from '../../funcs';
import { useAppSelector } from '../../store/hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'created_by' | 'is_archive'

export interface HttpReportProps {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportProps
}

interface ReportProps {
  interface: {
    filter_words: {
      created_by: string[],
      is_archive: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    chat_channel_add: boolean
    chat_channel_show: boolean
  },

  chat_channels: ChatChannelsProps[]

  edit: {
    users: {
      first_name: string
      last_name: string
      service_resource_code: number
      service_resource_nickname: string
      user_id: string
    }[]
  }
}

interface ChatChannelsProps {
  channel_id: string
  created_by: string
  is_archive: number
  name: string
  updated_by: string
  users_count: number

  notifications_count: number
  unread_messages_with_notification: number
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute();

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<ReportProps | null>(null)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    max_rows: 100 as 50 | 100 | 250 | 500,
    filter_words: cookiesSetting && cookiesSetting?.chat_channels?.filter_field ? {
      created_by: [] as string[],
      is_archive: [] as string[],
      ...cookiesSetting.chat_channels.filter_field,
    } : {
      created_by: [] as string[],
      is_archive: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.chat_channels?.sort_field ? cookiesSetting.chat_channels.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.chat_channels?.sort_type ? cookiesSetting.chat_channels.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Users Count',
      value: 'users_count'
    }, {
      span: 'Is Archive',
      value: 'is_archive'
    }, {
      span: 'Created By',
      value: 'created_by'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      max_rows: 100 as 50 | 100 | 250 | 500,
      filter_words: cookiesSetting && cookiesSetting?.chat_channels?.filter_field ? {
        created_by: [] as string[],
        is_archive: [] as string[],
        ...cookiesSetting.chat_channels.filter_field,
      } : {
        created_by: [] as string[],
        is_archive: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.chat_channels?.sort_field ? cookiesSetting.chat_channels.sort_field : 'name',
        direction: cookiesSetting && cookiesSetting?.chat_channels?.sort_type ? cookiesSetting.chat_channels.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load clients function
  async function loadData() {
    let filterParams = {}

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.chat_channels?.limit_rows ? cookiesSetting?.chat_channels?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.chat_channels?.limit_rows ? cookiesSetting?.chat_channels?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }

        setCookie('settings', { ...cookiesSetting, chat_channels: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.chat_channels?.limit_rows ? cookiesSetting?.chat_channels.limit_rows : reportsMaxRows } })
      }
    }

    try {

      // https://2022back4.artemiudintsev.com/api/chats/channels/report?account_id=88888&page=2&limit_rows=1
      const { data: { data: report, success } } = (await httpClientUpdate.get(`/chats/channels/report`, {
        params: {
          ...filterParams,
          ...activeFilters,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: any }
      if (success) {

        setReportData({
          ...report,
          interface: {
            ...report.interface,
            min_date: moment(report.interface.min_date).startOf('day').toDate(),
            max_date: moment(report.interface.max_date).endOf('day').toDate(),
          },
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, chat_channels: {} })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getName(id: string) {
    let name = ''

    reportData && reportData.edit.users.forEach(item => {
      if (item.user_id === id) {
        name = item.service_resource_code ? `${item.service_resource_nickname} (${item.service_resource_code})` : item.service_resource_nickname
      }
    })

    return name
  }

  return (
    <>
      {
        reportData &&
        <div className="ReportsPage_List">
          { /* Page header */}
          <div className="page-header">
            <h1>Chat Channels</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            onUpdate={() => {
              $setUpdater(Math.random());
            }}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.chat_channels?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, chat_channels: { ...cookiesSetting.chat_channels, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.chat_channel_add ?
                (
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('chat-channels.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      }, { reload: true })}
                    >
                      <Icon icon="plus-thin" />
                      <span>Add Chat Channel</span>
                      <Icon viewBox="0 0 1024 1024" icon="chat-116" />
                    </button>
                  </div>
                ) : (
                  <></>
                )
            }
          />

          <div className="contents">
            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Name</span>)}

                    sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Users Count</span>)}

                    sortDirection={localInterface.sort.field === 'users_count' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'users_count', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Is Archive</span>)}

                    allFiltersSelected={isAllFiltersSelected("is_archive")}
                    onAllFiltersChange={(value) => switchFilter("is_archive", "All", value)}

                    filterWords={reportData.interface.filter_words.is_archive.map((filterWord) => ({
                      word: filterWord,
                      word_name: filterWord ? 'Yes' : 'No',
                      selected: isFilterSelected("is_archive", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("is_archive", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'is_archive' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'is_archive', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Created By</span>)}

                    allFiltersSelected={isAllFiltersSelected("created_by")}
                    onAllFiltersChange={(value) => switchFilter("created_by", "All", value)}

                    filterWords={reportData.interface.filter_words.created_by.map((filterWord) => ({
                      word: filterWord,
                      word_name: getName(filterWord),
                      selected: isFilterSelected("created_by", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("created_by", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'created_by' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_by', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>

              {reportData.chat_channels.map((chat, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative' }}
                  className={reportData.permissions.chat_channel_show && chat.channel_id ? "" : "permission-show"}
                  onClick={() =>
                    reportData.permissions.chat_channel_show &&
                    chat.channel_id &&
                    $router.router.navigate('chat-channels.item', {
                      companyId: activeAccountId,
                      chatChannelId: chat.channel_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.chat_channel_show &&
                    chat.channel_id &&
                    handleChangeMenu(event, chat.channel_id, 'chat', i)}
                >
                  <td>{chat.name}</td>
                  <td>{chat.users_count}</td>
                  <td>{!chat.is_archive ? '' : 'Archive'}</td>
                  <td>
                    {chat.created_by}
                    {
                      reportData.permissions.chat_channel_show &&
                      showContext === `chat_${chat.channel_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('chat-channels.item', {
                              companyId: activeAccountId,
                              chatChannelId: chat.channel_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/chat-channels/${chat.channel_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Medium screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <th> Name </th>
                  <th> Users Count </th>
                  <th> Is Archive </th>
                  <th> Created By </th>
                </tr>
              </thead>

              {
                reportData.chat_channels.map((chat, i) => (
                  <tr
                    key={chat.channel_id}
                    style={{ position: 'relative' }}
                    className={reportData.permissions.chat_channel_show && chat.channel_id ? "" : "permission-show"}
                    onClick={() =>
                      reportData.permissions.chat_channel_show &&
                      chat.channel_id &&
                      $router.router.navigate('chat-channels.item', {
                        companyId: activeAccountId,
                        chatChannelId: chat.channel_id,
                        localInterface: localInterface,
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      reportData.permissions.chat_channel_show &&
                      chat.channel_id &&
                      handleChangeMenu(event, chat.channel_id, 'chat', i)}
                  >
                    <td> {chat.name} </td>
                    <td> {chat.users_count} </td>
                    <td> {!chat.is_archive ? '' : 'Archive'} </td>
                    <td>
                      {chat.created_by}
                      {
                        reportData.permissions.chat_channel_show &&
                        showContext === `chat_${chat.channel_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('chat-channels.item', {
                                companyId: activeAccountId,
                                chatChannelId: chat.channel_id,
                                localInterface: localInterface,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/chat-channels/${chat.channel_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))
              }
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              {
                reportData.chat_channels.map(chat => (
                  <div className="item" key={chat.channel_id}>
                    <div className="__top">
                      <div className="__left">
                        <div>
                          <span className="gray">Name:</span>
                          <span>
                            {chat.name}
                          </span>
                        </div>

                        <div>
                          <span className="gray">Is Archive:</span>
                          <span>
                            {!chat.is_archive ? '' : 'Archive'}
                          </span>
                        </div>
                      </div>

                      <div className="__right">

                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div>
                          <span className="gray">Users Count:</span>
                          <span>
                            {chat.users_count}
                          </span>
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          <span className="gray">Created By:</span>
                          <span>
                            {chat.created_by}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.chat_channels?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, chat_channels: { ...cookiesSetting.chat_channels, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}
