import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5';
import qs from "qs";
import Datetime from 'react-datetime'
import moment, { Moment } from 'moment';

import Icon from '../../components/Icon';
import Checkbox from '../../components/Checkbox';
import SelectComponent from '../../components/Select';

import { dateToInfoBlock, formatCIDLocalService, httpClientUpdate, nErrorUpdate } from '../../funcs';
import { useAppSelector } from '../../store/hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },

  data: ReportData
}

interface ReportData {
  edit: {
    google_accounts: EditGoogleAccountsProps[]
  }
  google_account: GoogleAccountProps
  permissions: {
    google_local_service_edit: boolean
    google_local_service_delete: boolean
  }
}

interface EditGoogleAccountsProps {
  email: string
  google_account_id: string
  name: string
  is_connect: boolean
}

interface GoogleAccountProps {
  cid: string
  mcid: string
  created_at: string
  created_by: string
  google_account_id: string
  google_local_service_id: string
  date_start_stat: Moment | Date | string
  name: string
  updated_at: string
  updated_by: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)

  const [readyToDelete, setReadyToDelete] = useState(false)

  const [editing, setEditing] = useState(false)

  const [data, setData] = useState<GoogleAccountProps | null>(null)
  const [dataReport, setDataReport] = useState<ReportData | null>(null)

  async function handleSave() {
    setIsSaving(true)
    try {
      if (data) {
        // https://2022back4.artemiudintsev.com/api/google/local-services/88888901wl39ik0yps
        const response = await httpClientUpdate.put(`/google/local-services/${$router.router.getState().params.googleLocalServicesId}`, qs.stringify({
          account_id: activeAccountId,
          cid: data.cid,
          mcid: data.mcid,
          name: data.name,
          google_account_id: data.google_account_id,
          date_start_stat: data.date_start_stat ? moment(data.date_start_stat).format('YYYY-MM-DD') : null
        }, { skipNulls: true }))
        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadInfo()
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove ticket
  async function handleRemove() {
    try {
      // https://2022back4.artemiudintsev.com/api/google/local-services/88888901wl39ik0yps
      const response = await httpClientUpdate.delete(`/google/local-services/${$router.router.getState().params.googleLocalServicesId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        $router.router.navigate('google-local-services', {
          companyId: activeAccountId,
        }, { reload: true })
      }
    } catch (error) {

    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/google/local-services/88888901wl39ik0yps?account_id=88888
      const { data: { data: googleData, success } } = await httpClientUpdate.get('/google/local-services/' + $router.router.getState().params.googleLocalServicesId, {
        params: {
          account_id: activeAccountId,
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setDataReport(googleData)
        setData({
          ...googleData.google_account,
          date_start_stat: googleData.google_account.date_start_stat ? googleData.google_account.date_start_stat : ''
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    data
  ])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  function handleCancelEdit() {
    setEditing(false)
    dataReport && setData(dataReport.google_account)
  }

  return (
    <>
      {
        dataReport && data ?
          <div className="TicketsPage_Item entity-edit">
            <div className="wrapper flex-container sb">
              <div className="flex-container _gap-narrow">
                <h1>Google Local Services:</h1>

                <div className="item-name"></div>
              </div>

              <div style={{ display: 'flex' }} >
                <button
                  disabled={editing}
                  style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                  className="_wa"
                  onClick={() => $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'google-local-services',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <Icon
                    style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </button>

                {
                  dataReport.permissions.google_local_service_edit &&
                  !editing &&
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                }
              </div>
            </div>

            <div className="fieldset">
              <div className="legend">Main</div>

              <div className="fields">
                <div className="__left">

                  <div className="field">
                    <span>Name:</span>
                    <input
                      type="text"
                      disabled={!editing}
                      value={data.name}
                      onChange={(event) => setData({ ...data, name: event.target.value })}
                    />
                  </div>

                  <div className="field">
                    <span>Google Account</span>

                    <SelectComponent
                      disabled={!editing}
                      options={dataReport.edit.google_accounts.map((option) => ({
                        span: `${option.name} (${option.email})`,
                        value: option.google_account_id,
                        is_connected: !!option.is_connect,
                      }))}
                      selectedOption={data.google_account_id as string}
                      onChange={(value) => setData({ ...data, google_account_id: value as string })}
                      is_google={true}
                    />
                  </div>

                  <div className="field">
                    <span>Date Start:</span>
                    <Datetime
                      dateFormat={'YYYY-MM-DD'}
                      timeFormat={false}
                      inputProps={{ disabled: !editing }}
                      value={data.date_start_stat as Date}
                      onChange={(value) => setData({ ...data, date_start_stat: moment.isMoment(value) ? value.toDate() : data.date_start_stat })}
                    />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Manager CID:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={formatCIDLocalService(data.mcid)}
                      onChange={(event) => setData({ ...data, mcid: event.target.value })}
                    />
                  </div>


                  <div className="field">
                    <span>CID:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={formatCIDLocalService(data.cid)}
                      onChange={(event) => setData({ ...data, cid: event.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fieldset">
              <div className="legend">Info</div>

              <div className="fields">
                <div className="__left">

                  <div className="field">
                    <span>Created at:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.created_at)}
                      onChange={(event) => setData({ ...data, created_at: event.target.value })}
                    />
                  </div>

                  <div className="field">
                    <span>Created by:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={data.created_by}
                      onChange={(event) => setData({ ...data, created_by: event.target.value })}
                    />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Updated at:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.updated_at)}
                      onChange={(event) => setData({ ...data, updated_at: event.target.value })}
                    />
                  </div>
                  <div className="field">
                    <span>Updated by:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={data.updated_by}
                      onChange={(event) => setData({ ...data, updated_by: event.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    dataReport.permissions.google_local_service_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Google Local Services</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave || isSaving || data.name === '' || data.cid === '' || data.mcid === '' || data.google_account_id === ''}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {
              deleting &&
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Google Local Services
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox
                      contents="I understand that after deleting the google local services it will not be possible to recover."
                      value={readyToDelete}
                      onChange={(value) => setReadyToDelete(value)}
                    />
                  </div>

                  <div className="buttons">

                    <button className="_bordered _green" onClick={() => setDeleting(false)}>
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemove()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            }
          </div> : null
      }
    </>
  )
}
