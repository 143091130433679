import { useState, useEffect } from 'react'
import { DateTime, Interval } from 'luxon'

interface ReserveBlockProps {
  start: string
  width: number
  number: number
  created_by: string
  created_at: string
  time_zone: string
  duration: null | number
}

export default function ReserveBlock({
  start,
  width,
  number,
  created_by,
  created_at,
  time_zone,
  duration,
}: ReserveBlockProps) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    if (created_at) {
      let timeDuration = duration ? duration - 1 : 4
      let nowISO = DateTime.now()
        .setZone(time_zone)
        .toUTC()
        .toISO()

      const later = DateTime.fromISO(created_at).setZone(time_zone)
      const now = DateTime.fromISO(nowISO)
      const i = Interval.fromDateTimes(later, now)

      let mins = Math.floor(i.length('seconds') % 3600 / 60).toString().padStart(2, '0')
      let seconds = Math.floor(i.length('seconds') % 60).toString().padStart(2, '0')

      setMinutes(timeDuration - +mins)
      setSeconds(59 - +seconds)
    } else {
      setMinutes(0)
      setSeconds(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [created_at])

  return (
    <div
      style={{
        left: `${start}`,
        width: width,
        display: width === 0 ? 'none' : 'flex',
        top: number * 60,
        background: '#a4c4f3',
        flexDirection: 'column',
        fontSize: '13px',
        position: 'absolute',
        opacity: minutes === 0 && seconds === 0 ? '.6' : '1'
      }}
      className={`appointment`}
    >
      <div
        className="appointment__job"
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <p
          style={{ color: 'black', fontWeight: 'bold' }}
          className="appointment__job-number"
        >
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>

        <p
          style={{ color: 'black', fontSize: '14px', margin: '1px' }}
          className="appointment__job-info"
        >
          {created_by}
        </p>
      </div>
    </div>
  )
}
