import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import User from "../../models/User"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'function' | 'time_zone' | 'permissions' | 'active'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UsersReport
}

export interface UsersReport {
  interface: {
    filter_words: {
      function: string[],
      time_zone: string[],
      permissions: string[],
      active: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    user_add: boolean
    user_delete: boolean
    user_edit: boolean
    user_report_show: boolean
    user_show: boolean
  },

  users: User[],

  edit: {
    time_zone: {
      id: number
      name: string
    }[],
    permissions: {
      name: string
      users_permissions_id: string
    }[],
    state: {
      id: number
      name: string
    }[],
    users_menus: {
      name: string
      users_menus_id: string
    }[]
  }
}

function UsersPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const accessToken = useAppSelector((store) => store.accessToken)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<UsersReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.users?.filter_field ? {
      function: [] as string[],
      time_zone: [] as string[],
      permissions: [] as string[],
      active: [] as string[],
      ...cookiesSetting.users.filter_field,
    } : {
      function: [] as string[],
      time_zone: [] as string[],
      permissions: [] as string[],
      active: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.users?.sort_field ? cookiesSetting.users.sort_field : 'first_name',
      direction: cookiesSetting && cookiesSetting?.users?.sort_type ? cookiesSetting.users.sort_type : 'up' as 'down' | 'up'
    },
    sortFields: [{
      span: 'First Name',
      value: 'first_name'
    }, {
      span: 'Last Name',
      value: 'last_name'
    }, {
      span: 'Function',
      value: 'function'
    }, {
      span: 'Phone',
      value: 'phone'
    }, {
      span: 'Email',
      value: 'email'
    }, {
      span: 'Time Zone',
      value: 'time_zone'
    }, {
      span: 'Permissions',
      value: 'permissions'
    }, {
      span: 'Active',
      value: 'active'
    }]

  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.users?.filter_field ? {
        function: [] as string[],
        time_zone: [] as string[],
        permissions: [] as string[],
        active: [] as string[],
        ...cookiesSetting.users.filter_field,
      } : {
        function: [] as string[],
        time_zone: [] as string[],
        permissions: [] as string[],
        active: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.users?.sort_field ? cookiesSetting.users.sort_field : 'first_name',
        direction: cookiesSetting && cookiesSetting?.users?.sort_type ? cookiesSetting.users.sort_type : 'up' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load users function
  async function loadUsers() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, users: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.users?.limit_rows ? cookiesSetting?.users.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: users, success, error } } = (await httpClientUpdate.post('/users/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.users?.limit_rows ? cookiesSetting?.users?.limit_rows : reportsMaxRows,
        ...filterParams,
        filters: activeFilters,
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (users.permissions.user_report_show) {
          setReportData(users)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, users: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load users on page mount
  useEffect(() => {
    loadUsers()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="UsersPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Users</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => {
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.users?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, users: { ...cookiesSetting.users, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.user_add ?
              (
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    onClick={() => $router.router.navigate('users.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                    })}>
                    <Icon icon="plus-thin" />
                    <span>Add user</span>
                    <Icon icon="user-1" />
                  </button>
                </div>
              ) :
              <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>First Name</span>)}

                  sortDirection={localInterface.sort.field === 'first_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'first_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Last Name</span>)}

                  sortDirection={localInterface.sort.field === 'last_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'last_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Function</span>)}

                  sortDirection={localInterface.sort.field === 'function' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'function', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Phone</span>)}

                  sortDirection={localInterface.sort.field === 'phone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Email</span>)}

                  sortDirection={localInterface.sort.field === 'email' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'email', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Time zone</span>)}

                  allFiltersSelected={isAllFiltersSelected("time_zone")}
                  onAllFiltersChange={(value) => switchFilter("time_zone", "All", value)}

                  filterWords={reportData.interface.filter_words.time_zone.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("time_zone", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("time_zone", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'time_zone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'time_zone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Permissions</span>)}

                  allFiltersSelected={isAllFiltersSelected("permissions")}
                  onAllFiltersChange={(value) => switchFilter("permissions", "All", value)}

                  filterWords={reportData.interface.filter_words.permissions.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("permissions", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("permissions", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'permissions' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'permissions', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Active</span>)}

                  allFiltersSelected={isAllFiltersSelected("active")}
                  onAllFiltersChange={(value) => switchFilter("active", "All", value)}

                  filterWords={reportData.interface.filter_words.active.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("active", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("active", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'active' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'active', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.users.map((user, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.user_show && user.user_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.user_show &&
                  user.user_id &&
                  $router.router.navigate('users.item', {
                    companyId: activeAccountId,
                    userId: user.user_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.user_show &&
                  user.user_id &&
                  handleChangeMenu(event, user.user_id, 'users', i)}
              >
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.function}</td>
                <td>{user.phone && formatPhoneNumber(user.phone)}</td>
                <td>{user.email}</td>
                <td>{user.time_zone}</td>
                <td>{user.permissions}</td>
                <td className={classNames({
                  'red-text': user.active === 'Inactive',
                  'green-text': user.active === 'Active',
                  'blue-text': user.active === 'Waiting',
                })}>
                  {user.active}
                  {
                    reportData.permissions.user_show &&
                    showContext === `users_${user.user_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('users.item', {
                            companyId: activeAccountId,
                            userId: user.user_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/users/${user.user_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.users.map((user, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.user_show && user.user_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.user_show &&
                  user.user_id &&
                  $router.router.navigate('users.item', {
                    companyId: activeAccountId,
                    userId: user.user_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.user_show &&
                  user.user_id &&
                  handleChangeMenu(event, user.user_id, 'users', i)}
              >
                <td>
                  <div>{user.first_name} {user.last_name}</div>
                  <div>{user.email}</div>
                </td>
                <td>
                  <div>{user.phone && formatPhoneNumber(user.phone)}</div>
                  <div>{user.time_zone}</div>
                </td>
                <td>
                  <div>Function: {user.function}</div>
                  <div>Permissions: {user.permissions}</div>
                </td>
                <td>
                  <div className={classNames({
                    'red-text': user.active === 'Inactive',
                    'green-text': user.active === 'Active',
                    'blue-text': user.active === 'Waiting',
                  })}>
                    {user.active}
                  </div>
                  <div>&nbsp;</div>
                  {
                    reportData.permissions.user_show &&
                    showContext === `users_${user.user_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('users.item', {
                            companyId: activeAccountId,
                            userId: user.user_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/users/${user.user_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.users.map((user, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.user_show &&
                  user.user_id &&
                  $router.router.navigate('users.item', {
                    companyId: activeAccountId,
                    userId: user.user_id,
                    localInterface: localInterface,
                  }, { reload: true })}>

                <div className="__top">
                  <div className="__left">
                    <div><b>{user.first_name} {user.last_name}</b></div>
                    <div>
                      {user.email}
                    </div>
                  </div>

                  <div className="__right">
                    <div className={classNames({
                      'red-text': user.active === 'Inactive',
                      'green-text': user.active === 'Active',
                      'blue-text': user.active === 'Waiting',
                    })}>
                      {user.active}
                    </div>
                    <div>
                      {user.phone && formatPhoneNumber(user.phone)}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Functions:</span>
                      <span> {user.function}</span>
                    </div>
                    <div>
                      <span className="gray">Permissions:</span>
                      <span> {user.permissions}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      {user.time_zone}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.users?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, users: { ...cookiesSetting.users, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default UsersPage_List
