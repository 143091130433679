import classNames from 'classnames'
import { useRef } from 'react'

interface PopupInfoProps {
  setShowPopup: (value: string) => void
  showPopup: string
  note_yes: string
  note_no: string
  tableRef: React.RefObject<HTMLDivElement>
  service_name: string
}

export default function PopupInfo({
  setShowPopup,
  showPopup,
  note_yes,
  note_no,
  service_name,
  tableRef
}: PopupInfoProps) {
  const popupRef = useRef(null)

  function getPosition() {
    let top = '0'
    let bottom = 'auto'

    if (showPopup && tableRef.current && popupRef.current) {
      const tableTop = tableRef.current.getBoundingClientRect().top
      const tableBottom = tableRef.current.getBoundingClientRect().bottom

      // @ts-ignore
      const popupTop = popupRef.current.getBoundingClientRect().top
      // @ts-ignore
      const popupBottom = popupRef.current.getBoundingClientRect().bottom

      if (popupTop - tableTop > tableBottom - popupBottom) {
        top = 'auto'
        bottom = '0'

      } else {
        top = '0'
        bottom = 'auto'
      }
    }

    return {
      top, bottom
    }
  }


  return (
    <div
      ref={popupRef}
      style={{ position: 'relative' }}
    >
      <div
        className={classNames('board__worker-popup',
          {
            saved: note_yes,
            inherit: !note_yes
          }
        )}
        onMouseEnter={() => note_yes && setShowPopup(service_name)}
        onMouseLeave={() => setShowPopup('')}
      >
        !
      </div>

      <div
        className={classNames('board__worker-popup',
          {
            attention: note_no,
            inherit: !note_no
          }
        )}
        onMouseEnter={() => note_no && setShowPopup(service_name)}
        onMouseLeave={() => setShowPopup('')}
      >
        !
      </div>

      {
        showPopup === service_name &&
        <div
          className="worker-popup"
          style={{ ...getPosition() }}
        >
          {
            note_yes &&
            <div className='popup-item'>
              <div
                className={classNames('board__worker-popup', 'saved')}
              >
                !
              </div>

              <p>
                {note_yes}
              </p>
            </div>
          }

          {
            note_no && note_yes &&
            <div className='separator'></div>
          }

          {
            note_no &&
            <div className='popup-item'>
              <div
                className={classNames('board__worker-popup', 'attention')}
              >
                !
              </div>

              <p>
                {note_no}
              </p>
            </div>
          }
        </div>
      }
    </div>
  )
}
