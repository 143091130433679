import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon'
import SelectComponent from '../../components/Select'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { GoogleAccountsProps, HttpReport } from './List'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [data, setData] = useState({
    name: '',
    cid: '',
    mcid: '',
    google_account_id: ''
  })

  const [googleOptions, setGoogleOptions] = useState<GoogleAccountsProps[]>([])
  const [cidOptions, setCidOptions] = useState<string[]>([])

  const [isSaving, setIsSaving] = useState(false)
  const [errorCid, setErrorCid] = useState(false)

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  useEffect(() => {
    if (errorCid) {
      setErrorCid(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.cid])

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Load data function
  async function loadData() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    try {
      // https://2022back4.artemiudintsev.com/api/google/local-services/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: response, success, error } } = (await httpClientUpdate.get('/google/local-services/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 500,
          page: JSON.stringify(1),
        }
      })) as { data: HttpReport }
      if (success) {
        const options: string[] = []

        response.google_local_services.forEach(item => {
          if (!options.includes(item.cid)) {
            options.push(item.cid)
          }
        })
        setCidOptions(options)
        setGoogleOptions(response.edit.google_accounts)

      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {

      if (cidOptions.includes(data.cid)) {
        setErrorCid(true)
        setIsSaving(false)
      } else {
        // https://2022back4.artemiudintsev.com/api/google/local-services
        const response = await httpClientUpdate.post(`/google/local-services`, {
          account_id: activeAccountId,
          google_account_id: data.google_account_id,
          cid: data.cid,
          mcid: data.mcid,
          name: data.name,
        })
        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('google-local-services', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      }

    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Local Services</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('google-local-services', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div
          className="legend"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Main
          {
            errorCid &&
            <span style={{ color: '#FF0000', marginLeft: '10px', fontSize: '16px' }}>
              This cid is already in use!
            </span>
          }
        </div>

        <div className="fields">
          <div className="__left">

            <div className="field">
              <span>Name:</span>
              <input
                type="text"
                value={data.name}
                onChange={(event) => setData({ ...data, name: event.target.value })}
              />
            </div>

            <div className="field">
              <span>Google Account</span>

              <SelectComponent
                options={googleOptions.map((option) => ({
                  span: `${option.name} (${option.email})`,
                  value: option.google_account_id,
                  is_connected: !!option.is_connect,
                }))}
                selectedOption={data.google_account_id as string}
                onChange={(value) => setData({ ...data, google_account_id: value as string })}
                is_google={true}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Manager CID:</span>
              <input
                type="text"
                value={data.mcid}
                onChange={(event) => setData({ ...data, mcid: event.target.value })}
              />
            </div>

            <div className="field">
              <span>Client ID:</span>
              <input
                type="text"
                value={data.cid}
                onChange={(event) => setData({ ...data, cid: event.target.value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('google-local-services', {
              companyId: activeAccountId,
            }, { reload: true })}
          >
            Cancel
          </button>
          <button
            disabled={data.name === '' || data.cid === '' || data.mcid === '' || data.google_account_id === '' || isSaving || errorCid}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
