import React, { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'

import Icon from '../../components/Icon'
import Select from "../../components/Select"

import { useAppSelector } from '../../store/hooks'
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface RestrictionsProps {
  type: string
  level: string
  item_id: string
  service_resource_restriction_id: string
  editing?: boolean
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    service_resource_restrictions: RestrictionsProps[]
    edit: EditProps
    permissions: PermissionsProps
  }
}

interface EditProps {
  brands: Brands[]
  appliances: Appliances[]
}

interface Appliances {
  appliance_id: string
  name: string
}

interface Brands {
  brand_id: string
  name: string
}

interface PermissionsProps {
  service_resource_restriction_show: boolean
  service_resource_restriction_add: boolean
  service_resource_restriction_edit: boolean
  service_resource_restriction_delete: boolean
}

interface ServiceResourcesScheduleProps {
  handleBackServiceResources: () => void,
  service_resources: {
    code: number
    nickname: string
    service_resource_id: string
  }[] | []
  permissions?: {
    service_resource_add: boolean
    service_resource_delete: boolean
    service_resource_edit: boolean
    service_resource_report_show: boolean
    service_resource_schedule_add: boolean
    service_resource_schedule_delete: boolean
    service_resource_schedule_edit: boolean
    service_resource_schedule_show: boolean
    service_resource_show: boolean
  },
  name?: string
}

export default function ServiceResourceRestrictions({
  handleBackServiceResources,
  permissions,
  name,
}: ServiceResourcesScheduleProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [restrictionsData, setRestrictionsData] = useState<RestrictionsProps[] | []>([])

  const [newRestrictionsData, setNewRestrictionsData] = useState<RestrictionsProps[] | []>([])

  const [edit, setEdit] = useState<EditProps>({
    brands: [],
    appliances: []
  })
  const [permissionsRestrictions, setPermissionsRestrictions] = useState<PermissionsProps>({
    service_resource_restriction_show: true,
    service_resource_restriction_add: true,
    service_resource_restriction_edit: true,
    service_resource_restriction_delete: true,
  })

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/serviceresources/restrictions?account_id=88888&service_resource_id=88888010ty7gjfyh90
      const { data: { data: restrictionsData, success, error } } = (await httpClientUpdate.get('/serviceresources/restrictions', {
        params: {
          account_id: activeAccountId,
          service_resource_id: $router.router.getState().params.serviceResourceId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setRestrictionsData(restrictionsData.service_resource_restrictions)
        setEdit(restrictionsData.edit)

        if (restrictionsData.permissions) {
          setPermissionsRestrictions(restrictionsData.permissions)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load schedule data
  useEffect(() => {
    loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // add new schedule
  async function handleAddNewSchedule(i: number) {
    setIsSaving(true)
    try {
      const { level, type, item_id } = newRestrictionsData[i]
      // https://2022back4.artemiudintsev.com/api/serviceresources/restrictions
      const response = await httpClientUpdate.post(`/serviceresources/restrictions`, qs.stringify({
        account_id: activeAccountId,
        service_resource_id: $router.router.getState().params.serviceResourceId,
        level,
        type,
        item_id
      }))
      if (response.data.success) {
        setIsSaving(false)
        let updatedSchedule = newRestrictionsData.filter((item, index) => i !== index)
        setNewRestrictionsData(updatedSchedule)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove schedule
  async function removeRestrictions(i: number) {
    setIsSaving(true)
    try {
      const { service_resource_restriction_id } = restrictionsData[i]
      // https://2022back4.artemiudintsev.com/api/serviceresources/restrictions/88888016s9m30vjqa1?account_id=88888
      const response = await httpClientUpdate.delete(`/serviceresources/restrictions/${service_resource_restriction_id}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // change schedule
  async function handleSaveChangeSchedule(i: number) {
    setIsSaving(true)
    try {
      const { type, item_id, level, service_resource_restriction_id } = restrictionsData[i]
      // https://2022back4.artemiudintsev.com/api/serviceresources/restrictions/88888016s9m30vjqa1
      const response = await httpClientUpdate.put(`/serviceresources/restrictions/${service_resource_restriction_id}`, qs.stringify({
        account_id: activeAccountId,
        service_resource_id: $router.router.getState().params.serviceResourceId,
        type,
        item_id,
        level
      }))
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }


  function handleChangeScheduleData(value: string, name: string, idx: number) {
    let updateRestrictionsData = restrictionsData.map((item, index) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    setRestrictionsData(updateRestrictionsData)
  }

  function handleChangeNewScheduleData(value: string, name: string, idx: number) {
    let updateRestrictionsData = newRestrictionsData.map((item, index) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    })
    setNewRestrictionsData(updateRestrictionsData)
  }

  function handleAddSchedule() {
    let updateRestrictionsData = newRestrictionsData.map(item => item)
    updateRestrictionsData.push({
      type: '',
      item_id: '',
      level: 'No',
      service_resource_restriction_id: '',
      editing: true
    })
    setNewRestrictionsData(updateRestrictionsData)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...restrictionsData]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setRestrictionsData(_items)
  }

  // Set editing function
  function setNewEditing(i: number, flag: boolean) {

    let _items = [...newRestrictionsData]
    _items = _items.map((item) => ({ ...item }))
    _items[i].editing = flag
    setNewRestrictionsData(_items)
  }

  function removeNewSchedule(index: number) {
    let updateRestrictionsData = newRestrictionsData.filter((item, idx) => idx !== index)
    setNewRestrictionsData(updateRestrictionsData)
  }

  function getName(type: string, id: string) {
    let name = ''
    if (type === 'Unit Type') {
      name = id
    } else if (type === 'Unit') {
      edit.appliances.forEach(item => {
        if (item.appliance_id === id) {
          name = item.name
        }
      })
    } else {
      edit.brands.forEach(item => {
        if (item.brand_id === id) {
          name = item.name
        }
      })
    }

    return name
  }

  return (
    <div className="SchedulePageList_List entity-edit">

      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Service Resource Restrictions:</h1>
          <div className="item-name">
            {name}
          </div>
        </div>
        {/* Temporarily removed the rights check on the field edit_service_resource */}
        <div style={{ display: 'flex' }}>
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
            className="_wa"
            onClick={() => handleBackServiceResources()}
          >
            Back
          </button>

          {
            permissionsRestrictions.service_resource_restriction_add &&
            <button className="_wa _green" onClick={() => handleAddSchedule()}>
              Add
            </button>
          }
        </div>
      </div>

      {/* Table */}
      <div className="fieldset">
        {(!!restrictionsData.length || !!newRestrictionsData.length) &&
          < table className="table">
            <thead>
              <tr>
                <th style={{ width: 'calc(90% / 3)' }}>Type</th>
                <th style={{ width: 'calc(90% / 3)' }}>Level</th>
                <th style={{ width: 'calc(90% / 3)' }}>Item</th>
                <th style={{ width: '5%' }}></th>
                <th style={{ width: '5%' }}></th>
              </tr>
            </thead>

            {restrictionsData.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={['Unit', 'Brand', 'Unit Type'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={item.type as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'type', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={['No', 'Maybe'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={item.level as string || ''}
                        onChange={(value: string) => handleChangeScheduleData(value, 'level', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      {
                        (item.type === 'Unit Type' || item.type === '') &&
                        <Select
                          disabled={item.type === ''}
                          options={['Residential', 'Commercial', 'Industrial'].sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={item.item_id as string || ''}
                          onChange={(value: string) => handleChangeScheduleData(value, 'item_id', i)}
                        />
                      }

                      {
                        item.type === 'Unit' &&
                        <Select
                          options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((option) => ({
                            span: option.name,
                            value: option.appliance_id
                          }))}
                          selectedOption={item.item_id as string || ''}
                          onChange={(value: string) => handleChangeScheduleData(value, 'item_id', i)}
                        />
                      }

                      {
                        item.type === 'Brand' &&
                        <Select
                          options={edit.brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((option) => ({
                            span: option.name,
                            value: option.brand_id
                          }))}
                          selectedOption={item.item_id as string || ''}
                          onChange={(value: string) => handleChangeScheduleData(value, 'item_id', i)}
                        />
                      }
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={item.type === '' || item.level === '' || item.item_id === '' || isSaving}
                      onClick={() => handleSaveChangeSchedule(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissionsRestrictions.service_resource_restriction_delete || isSaving}
                      onClick={() => removeRestrictions(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.type}</td>
                  <td>{item.level}</td>
                  <td>{getName(item.type, item.item_id)}</td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissionsRestrictions.service_resource_restriction_edit}
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissionsRestrictions.service_resource_restriction_delete || isSaving}
                      onClick={() => removeRestrictions(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}

            {newRestrictionsData.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={['Unit', 'Brand', 'Unit Type'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={item.type as string || ''}
                        onChange={(value: string) => handleChangeNewScheduleData(value, 'type', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      <Select
                        options={['No', 'Maybe'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={item.level as string || ''}
                        onChange={(value: string) => handleChangeNewScheduleData(value, 'level', i)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="editing-form">
                      {
                        (item.type === 'Unit Type' || item.type === '') &&
                        <Select
                          disabled={item.type === ''}
                          options={['Residential', 'Commercial', 'Industrial'].sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={item.item_id as string || ''}
                          onChange={(value: string) => handleChangeNewScheduleData(value, 'item_id', i)}
                        />
                      }

                      {
                        item.type === 'Unit' &&
                        <Select
                          options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((option) => ({
                            span: option.name,
                            value: option.appliance_id
                          }))}
                          selectedOption={item.item_id as string || ''}
                          onChange={(value: string) => handleChangeNewScheduleData(value, 'item_id', i)}
                        />
                      }

                      {
                        item.type === 'Brand' &&
                        <Select
                          options={edit.brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((option) => ({
                            span: option.name,
                            value: option.brand_id
                          }))}
                          selectedOption={item.item_id as string || ''}
                          onChange={(value: string) => handleChangeNewScheduleData(value, 'item_id', i)}
                        />
                      }
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={item.type === '' || item.level === '' || item.item_id === '' || isSaving}
                      onClick={() => handleAddNewSchedule(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissionsRestrictions.service_resource_restriction_delete}
                      onClick={() => removeNewSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.type}</td>
                  <td>{item.level}</td>
                  <td>{getName(item.type, item.item_id)}</td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissionsRestrictions.service_resource_restriction_edit}
                      onClick={() => setNewEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissionsRestrictions.service_resource_restriction_delete}
                      onClick={() => removeNewSchedule(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>}
      </div>
    </div>
  )
}
