import ReactDOM from "react-dom/client"
import { Provider as StorageProvider } from "react-redux"
import { RouterProvider } from "react-router5"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from 'redux-persist'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';

import App from "./App"
import router from "./router"
import { store } from "./store/store"

import "react-datetime/css/react-datetime.css"
import 'react-image-crop/dist/ReactCrop.css';
import "./styles/main.sass"

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

router.start(() => {
  root.render(
    <StorageProvider store={store}>
      <CookiesProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router}>
              <App />
            </RouterProvider>
          </PersistGate>
        </GoogleOAuthProvider>
      </CookiesProvider>
    </StorageProvider>
  )
})
