import { useEffect, useState } from "react";
import moment from "moment-timezone"
import momentmoment from 'moment';
import classNames from "classnames"

import Icon from "../../components/Icon"

import { useAppSelector } from "../../store/hooks"


import "../../styles/components/date-range-calendar.sass"

interface Props {

  startDate?: Date,
  endDate?: Date,

  dateRangeType?: string,
  dateRangePreset?: string,

  onStartDateUpdate?(date: Date): void,
  onEndDateUpdate?(date: Date): void,

  debug?: any
}

function DateRangeCalendar({
  startDate,
  endDate,
  onStartDateUpdate,
  onEndDateUpdate,
  dateRangeType,
  dateRangePreset,
  debug
}: Props) {


  let user = useAppSelector((store) => store.user)
  let timeZone = user?.time_zone;
  const [activeDate, setActiveDate] = useState(moment().startOf('day').toDate())
  const [startDateState, setStartDateState] = useState<Date | null>(startDate || null)
  const [endDateState, setEndDateState] = useState<Date | null>(null)

  useEffect(() => {
    if (timeZone) {
      setActiveDate(moment.tz(momentmoment(), timeZone).startOf('day').toDate())
    }

  }, [timeZone])
  // onDayClick function
  function onDayClick(day: number) {
    const dateStart = moment(activeDate).date(day).toDate()
    onStartDateUpdate && onStartDateUpdate(dateStart)
    setStartDateState(dateStart)
    if (dateRangeType === 'one') {
      setEndDateState(dateStart)
    } else if (dateRangeType === 'two') {
      let dateEnd = moment(activeDate).date(day + 1).toDate()
      setEndDateState(dateEnd)
    } else {
      let dateEnd = moment(activeDate).date(day + 2).toDate()
      setEndDateState(dateEnd)
    }
  }

  // Add or take a month from activeDate function
  function updateActiveMonth(x: number) {
    setActiveDate(moment(activeDate).add(x, 'M').toDate())
  }

  useEffect(() => {
    dateRangePreset === 'custom' &&
      endDateState &&
      onEndDateUpdate &&
      onEndDateUpdate(endDateState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateState])

  useEffect(() => {
    startDate && setStartDateState(startDate)
    startDate && setActiveDate(startDate)
  }, [startDate])

  useEffect(() => {
    endDate && setEndDateState(endDate)
  }, [endDate])

  // Render function
  return (
    <div className="DateRangeCalendar">

      <div className="header">

        <button className="_zeroed _iconed" onClick={() => updateActiveMonth(-1)}>
          <Icon className="_rotated-180" icon="arrow-25" />
        </button>

        <div className="current-month">
          {moment(activeDate).format('MMMM YYYY')}
        </div>

        <button className="_zeroed _iconed" onClick={() => updateActiveMonth(1)}>
          <Icon icon="arrow-25" />
        </button>
      </div>

      <div className="calendar">

        <div className="days-grid week-days">

          <div className="day">S</div>
          <div className="day">M</div>
          <div className="day">T</div>
          <div className="day">W</div>
          <div className="day">T</div>
          <div className="day">F</div>
          <div className="day">S</div>
        </div>

        <div className="days-grid calendar-days">

          {[...Array(moment(activeDate).startOf('month').day())].map((day, i) => (
            <div className="day" key={`${i}`}></div>
          ))}


          {[...Array(moment(activeDate).daysInMonth())].map((day, i) => (
            <div
              className={classNames('day', {
                _selected: (
                  i + 1 === moment(startDateState).date() &&
                  activeDate?.getMonth() === startDateState?.getMonth() &&
                  activeDate?.getFullYear() === startDateState?.getFullYear()
                ),
                _inRange: (
                  startDateState && endDateState &&
                  moment(activeDate).date(i + 1).isAfter(moment(startDateState)) &&
                  moment(activeDate).date(i + 2).isBefore(moment(endDateState))
                ),
                _rangeStart: moment(activeDate).date(i + 1).isSame(moment(startDateState)),
                _rangeEnd: moment(activeDate).endOf('day').date(i + 1).isSame(moment(endDateState))
              })}
              key={i}
              onClick={() => onDayClick(i + 1)}
            >{i + 1}</div>
          ))}
        </div>
      </div>

      {debug ? (<>
        <div>{moment(startDateState).format()}</div>
        <div>{moment(activeDate).format()}</div>
        <div>{moment(endDateState).format()}</div>
        <div>{String(moment(startDateState).format() === moment(activeDate).format())}</div>
      </>) : null}
    </div>
  )
}

export default DateRangeCalendar
