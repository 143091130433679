import { useEffect, useState } from 'react'
import { useRoute } from "react-router5"
import moment from 'moment'
import qs from 'qs'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'
import { payoutDispatcherTimesOfDay } from '../../constans'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PayoutDispatcherData
}

interface PayoutDispatcherData {
  permissions: {
    payout_dispatcher_delete: boolean
    payout_dispatcher_report_show: boolean
    payout_dispatcher_report_show_own: boolean
    payout_dispatcher_show: boolean
    payout_dispatcher_show_own: boolean
    payout_dispatcher_edit: boolean
    payout_dispatcher_edit_own: boolean
  }
  edit: {
    status: {
      id: number
      name: string
    }[]
  }
  payout_dispatcher: PayoutDispatcher
}

interface PayoutDispatcher {
  created_at: string
  created_by: string
  date_finish: string
  date_start: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  payout_dispatcher_hours: PayoutDispatcherHoursProps[]
  payout_dispatcher_id: string
  payout_dispatcher_mentor_orders: PayoutDispatcherMentorOrdersProps[]
  payout_dispatcher_orders: PayoutDispatcherOrdersProps[]
  payout_dispatcher: PayoutsProps[]
  status_id: string
  status_name: string
  total_amount: string
  total_hours: string
  total_hours_amount: string
  total_mentor_orders: string
  total_mentor_orders_amount: string
  total_orders: string
  total_orders_amount: string
  updated_at: string
  updated_by: string
  type: string
  salary_per_hour: string
  paid: number
}

interface PayoutDispatcherMentorOrdersProps {
  date: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  orders: number
}

interface PayoutDispatcherOrdersProps {
  date: string
  orders: number
}

interface PayoutsProps {
  created_at: string
  date_finish: string
  date_start: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  payout_dispatcher_id: string
  status_id: number
  status_name: string
  total_amount: number
  total_hours: string
  total_hours_amount: string
  total_mentor_orders: string
  total_mentor_orders_amount: string
  total_orders: string
  total_orders_amount: string
  type: string
  updated_at: string
  updated_by: string
  isPay: boolean
  amount: number
}

interface PayoutDispatcherHoursProps {
  date: string
  hour_finish: string
  hour_start: string
  hours: number
  payout_dispatcher_hour_id?: string
  status?: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isLoading, setIsLoading] = useState(false)
  const [editing, setEditing] = useState(false)

  const [reportData, setReportData] = useState<PayoutDispatcherData | null>(null)
  const [payoutDispetcher, setPayoutDispetcher] = useState<PayoutsProps[]>([])
  const [payoutDispatcherHours, setPayoutDispatcherHours] = useState<PayoutDispatcherHoursProps[]>([])
  const [payoutDispatcherOrders, setPayoutDispatcherOrders] = useState<PayoutDispatcherOrdersProps[]>([])
  const [payoutDispatcherMentorOrders, setPayoutDispatcherMentorOrders] = useState<PayoutDispatcherMentorOrdersProps[]>([])

  const [dispatcherOptions, setDispatcherOptions] = useState<string[]>([])
  const [daysOptions, setDaysOptions] = useState<string[]>([])

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  useEffect(() => {
    let dispatchers: string[] = []

    payoutDispatcherMentorOrders.forEach(mentors => {
      const dispatcherName = mentors.dispatcher_code ? `${mentors.dispatcher_nickname} (${mentors.dispatcher_code})` : mentors.dispatcher_nickname
      if (!dispatchers.includes(dispatcherName)) {
        dispatchers.push(dispatcherName)
      }
    })



    setDispatcherOptions(dispatchers)
  }, [payoutDispatcherMentorOrders])

  useEffect(() => {
    if (reportData) {
      let days: string[] = []

      for (let i = 0; i < 7; i++) {
        let day = moment(reportData.payout_dispatcher.date_start, 'YYYY-MM-DD').add(i, 'd').format('DD-MM-YYYY')

        days.push(day)
      }

      setDaysOptions(days)
    }
  }, [reportData])

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/88888311audsyguy?account_id=88888
      const { data: { data: report, success, error } } = await httpClientUpdate.get('/payouts/dispatchers/' + $router.router.getState().params.payoutDispatcherId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }
      if (success) {
        setReportData(report)

        setPayoutDispetcher(report.payout_dispatcher.payout_dispatcher.map(item => {
          return {
            ...item,
            isPay: false,
            amount: 0,
          }
        }))

        setPayoutDispatcherOrders(report.payout_dispatcher.payout_dispatcher_orders.map(item => {
          return {
            ...item,
            date: moment(item.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
          }
        }))

        setPayoutDispatcherMentorOrders(report.payout_dispatcher.payout_dispatcher_mentor_orders.map(item => {
          return {
            ...item,
            date: moment(item.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
          }
        }))

        setPayoutDispatcherHours(report.payout_dispatcher.payout_dispatcher_hours.map(item => {
          return {
            ...item,
            date: moment(item.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
          }
        }))
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSaveEditingHours() {
    let payouts = payoutDispatcherHours.filter(item => item.payout_dispatcher_hour_id || (!item.payout_dispatcher_hour_id && !!item.hours))

    try {
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/{PAYOUT_DISPATCHER_ID}/hours
      const response = await httpClientUpdate.put(`/payouts/dispatchers/${$router.router.getState().params.payoutDispatcherId}/hours`,
        {
          account_id: activeAccountId,
          payout_dispatcher_hours: payouts.map(item => {
            if (item.payout_dispatcher_hour_id) {
              return {
                date: moment(item.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                hour_start: item.hour_start,
                hour_finish: item.hour_finish,
                hours: item.hours,
                payout_dispatcher_hour_id: item.payout_dispatcher_hour_id,
              }
            } else {
              return {
                date: moment(item.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                hour_start: item.hour_start,
                hour_finish: item.hour_finish,
                hours: item.hours,
              }
            }
          })
        })
      if (response.data.success) {
        setIsLoading(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsLoading(false)
        setEditing(false)
      }
    } catch (error) {
      setIsLoading(false)
      setEditing(false)
    }
  }

  async function handleRemoveItem() {
    try {
      setIsLoading(true)
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/88888311audsyguy?account_id=88888
      const response = await httpClientUpdate.delete(`/payouts/dispatchers/${$router.router.getState().params.payoutDispatcherId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsLoading(false)
        $router.router.navigate('payout-dispatcher', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface,
        }, { reload: true })
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  async function handleToggleStatus(status_name: string) {
    try {
      setIsLoading(true)
      let status_id = reportData?.edit.status.filter(item => item.name === status_name)
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/88888311audsyguy
      const response = await httpClientUpdate.put(`/payouts/dispatchers/${$router.router.getState().params.payoutDispatcherId}`, qs.stringify({
        account_id: activeAccountId,
        status_id: status_id?.length ? status_id[0].id : null
      }))
      if (response.data.success) {
        setIsLoading(false)
        loadInfo()
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function sumHoursDay(day: string) {
    let sum = 0

    payoutDispatcherHours.forEach(days => {
      if (days.date === day) {
        sum = sum + +days.hours
      }
    })

    return sum.toFixed(2)
  }

  function sumDispatcher(day: string) {
    let sum = 0

    payoutDispatcherMentorOrders.forEach(mentors => {
      if (mentors.date === day) {
        sum = sum + mentors.orders
      }
    })

    return sum
  }

  function getPayoutDispatcherHours(day: string, hour: string) {
    let hours = 0

    payoutDispatcherHours.forEach(item => {
      if (item.date === day && item.hour_start === hour) {
        hours = +item.hours
      }
    })

    return hours.toFixed(2)
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(day: string, hour: string, price: string) {
    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`

      if (+newNumber >= 0 && +newNumber <= 1) {
        let updatedHours = payoutDispatcherHours.map(item => {
          let status = ''

          if (reportData) {
            if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
              status = 'dispatcher'
            } else if (reportData.permissions.payout_dispatcher_edit) {
              status = 'company'
            }
          }

          if (item.date === day && item.hour_start === hour) {
            return {
              ...item,
              hours: +newNumber,
              status: status ? status : item.status
            }
          } else {
            return { ...item }
          }
        })

        if (!payoutDispatcherHours.filter(item => item.date === day && item.hour_start === hour).length) {
          let status = ''

          if (reportData) {
            if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
              status = 'dispatcher'
            } else if (reportData.permissions.payout_dispatcher_edit) {
              status = 'company'
            }
          }

          updatedHours.push({
            date: day,
            hour_start: hour,
            hour_finish: moment(hour, 'hh:mm:ss').add(1, 'h').format('HH:mm:ss'),
            hours: +newNumber,
            status: status
          })
        }

        setPayoutDispatcherHours(updatedHours)
      }
    } else if (price.length === 1) {
      let newNumber = `0.0${price}`

      if (+newNumber >= 0 && +newNumber <= 1) {
        let updatedHours = payoutDispatcherHours.map(item => {
          let status = ''

          if (reportData) {
            if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
              status = 'dispatcher'
            } else if (reportData.permissions.payout_dispatcher_edit) {
              status = 'company'
            }
          }

          if (item.date === day && item.hour_start === hour) {
            return {
              ...item,
              hours: +newNumber,
              status: status ? status : item.status
            }
          } else {
            return { ...item }
          }
        })

        if (!payoutDispatcherHours.filter(item => item.date === day && item.hour_start === hour).length) {
          let status = ''

          if (reportData) {
            if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
              status = 'dispatcher'
            } else if (reportData.permissions.payout_dispatcher_edit) {
              status = 'company'
            }
          }

          updatedHours.push({
            date: day,
            hour_start: hour,
            hour_finish: moment(hour, 'hh:mm:ss').add(1, 'h').format('HH:mm:ss'),
            hours: +newNumber,
            status: status
          })
        }

        setPayoutDispatcherHours(updatedHours)
      }
    }
  }

  function handleChangePriceBackspace(day: string, hour: string, event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {

        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (+newNumber >= 0 && +newNumber <= 1) {
          let updatedHours = payoutDispatcherHours.map(item => {
            if (item.date === day && item.hour_start === hour) {
              let status = ''

              if (reportData) {
                if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
                  status = 'dispatcher'
                } else if (reportData.permissions.payout_dispatcher_edit) {
                  status = 'company'
                }
              }

              return {
                ...item,
                hours: +newNumber,
                status: status ? status : item.status
              }
            } else {
              return { ...item }
            }
          })

          if (!payoutDispatcherHours.filter(item => item.date === day && item.hour_start === hour).length) {
            let status = ''

            if (reportData) {
              if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
                status = 'dispatcher'
              } else if (reportData.permissions.payout_dispatcher_edit) {
                status = 'company'
              }
            }

            updatedHours.push({
              date: day,
              hour_start: hour,
              hour_finish: moment(hour, 'hh:mm:ss').add(1, 'h').format('HH:mm:ss'),
              hours: +newNumber,
              status: status,
            })
          }

          setPayoutDispatcherHours(updatedHours)
        }
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`

        if (+newNumber >= 0 && +newNumber <= 1) {
          let updatedHours = payoutDispatcherHours.map(item => {
            if (item.date === day && item.hour_start === hour) {
              let status = ''

              if (reportData) {
                if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
                  status = 'dispatcher'
                } else if (reportData.permissions.payout_dispatcher_edit) {
                  status = 'company'
                }
              }

              return {
                ...item,
                hours: +newNumber,
                status: status ? status : item.status
              }
            } else {
              return { ...item }
            }
          })

          if (!payoutDispatcherHours.filter(item => item.date === day && item.hour_start === hour).length) {
            let status = ''

            if (reportData) {
              if (reportData.permissions.payout_dispatcher_edit_own && !reportData.permissions.payout_dispatcher_edit) {
                status = 'dispatcher'
              } else if (reportData.permissions.payout_dispatcher_edit) {
                status = 'company'
              }
            }

            updatedHours.push({
              date: day,
              hour_start: hour,
              hour_finish: moment(hour, 'hh:mm:ss').add(1, 'h').format('HH:mm:ss'),
              hours: +newNumber,
              status: status
            })
          }

          setPayoutDispatcherHours(updatedHours)
        }
      }
    }
  }

  function handleCancelEditingHours() {
    reportData && setPayoutDispatcherHours(reportData.payout_dispatcher.payout_dispatcher_hours.map(item => {
      return {
        ...item,
        date: moment(item.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      }
    }))

    setEditing(false)
  }

  function getTotalHours() {
    let sum = 0

    payoutDispatcherHours.forEach(item => {
      if (item.hours) {
        sum = sum + +item.hours
      }
    })
    return sum.toFixed(2)
  }

  function getTotalAmountHoursBlock() {
    let sum = 0
    if (reportData) {
      sum = +getTotalHours() * +reportData.payout_dispatcher.salary_per_hour
    }

    return sum.toFixed(2)
  }

  function getPayuot() {
    let sum = 0
    if (reportData) {
      sum = +getTotalAmountHoursBlock() + +reportData.payout_dispatcher.total_orders_amount + +reportData.payout_dispatcher.total_mentor_orders_amount
    }

    return sum.toFixed(2)
  }

  function getDispatcherName(date: string, mentor: string) {
    let orders = 0

    payoutDispatcherMentorOrders.forEach(item => {
      const dispatcher = item.dispatcher_code ? `${item.dispatcher_nickname} (${item.dispatcher_code})` : item.dispatcher_nickname
      if (item.date === date && mentor === dispatcher) {
        orders = item.orders
      }
    })

    return orders
  }

  function getSumPayoutDispatcher() {
    let sum = 0
    if (reportData) {
      reportData.payout_dispatcher.payout_dispatcher.forEach((item) => {
        sum = sum + +item.total_amount
      })
    }

    return sum
  }

  function getStatusName(id: number) {
    let name = ''

    if (reportData) {
      reportData.edit.status.forEach(item => {
        if (item.id === id) {
          name = item.name
        }
      })
    }

    return name
  }

  function getBGForHours(day: string, hour: { span: string, value: string }) {
    let background = 'inherit'

    payoutDispatcherHours.forEach(item => {
      if ((item.date === day && item.hour_start === hour.value && item.payout_dispatcher_hour_id) || (item.date === day && item.hour_start === hour.value && item.hours)) {
        if (item.status === 'company') {
          if (Number(item.hours) < 1) {
            background = '#bb0000'
          } else {
            background = '#43be58'
          }
        } else if (item.status === 'system') {
          background = '#00ff2b'
        } else if (item.status === 'dispatcher') {
          if (Number(item.hours) < 1) {
            background = '#ff6969'
          } else {
            background = '#a1ffb0'
          }
        }
      }
    })

    return background
  }

  return (
    <>
      {
        reportData &&
        <div className="JobsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Payout Dispatcher</h1>

            <div style={{ display: 'flex' }} >
              <button
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'payout-dispatcher',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>
            </div>
          </div>

          <div className="fieldset">
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div className="legend">Main</div>

              <div>
                {
                  !reportData.permissions.payout_dispatcher_edit &&
                  reportData.permissions.payout_dispatcher_edit_own &&
                  (reportData.payout_dispatcher.status_name === 'Need Confirmation' ||
                    reportData.payout_dispatcher.status_name === 'Final Confirmation')
                  &&
                  <button
                    className='close'
                    style={{
                      backgroundColor: '#01B41F',
                      border: '1px solid #01B41F',
                      padding: '4px 20px',
                      color: 'white',
                      height: 'fit-content',
                      width: 'fit-content'
                    }}
                    disabled={isLoading}
                    onClick={() => handleToggleStatus(reportData.payout_dispatcher.status_name === 'Need Confirmation' ? 'Need to Check' : 'Need Payment')}
                  >
                    Confirm
                  </button>
                }

                {
                  reportData.permissions.payout_dispatcher_edit &&
                  (reportData.payout_dispatcher.status_name === 'Need to Check' || reportData.payout_dispatcher.status_name === 'Need Payment') &&
                  <button
                    className='close'
                    style={{
                      backgroundColor: '#01B41F',
                      border: '1px solid #01B41F',
                      padding: '4px 20px',
                      color: 'white',
                      height: 'fit-content',
                      width: 'fit-content'
                    }}
                    disabled={isLoading}
                    onClick={() => handleToggleStatus("Checked")}
                  >
                    Check
                  </button>
                }
              </div>
            </div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.payout_dispatcher.created_at)}
                  />
                </div>

                <div className="field">
                  <span>Dispatcher:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={reportData.payout_dispatcher.dispatcher_code ? `${reportData.payout_dispatcher.dispatcher_nickname} (${reportData.payout_dispatcher.dispatcher_code})` : reportData.payout_dispatcher.dispatcher_nickname}
                  />
                </div>

                {
                  reportData.payout_dispatcher.type === 'payout' &&
                  <div className="field">
                    <span>Payout:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`$${editing ? getPayuot() : reportData.payout_dispatcher.total_amount}`}
                    />
                  </div>
                }
              </div>

              <div className="__right">
                {
                  reportData.payout_dispatcher.type === 'payout' &&
                  <div className="field">
                    <span>Status:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={reportData.payout_dispatcher.status_name}
                    />
                  </div>
                }

                {
                  reportData.payout_dispatcher.type === 'payout' &&
                  <div className="field">
                    <span>Range:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`${reportData.payout_dispatcher.date_start} - ${reportData.payout_dispatcher.date_finish}`}
                    />
                  </div>
                }

                {
                  reportData.payout_dispatcher.type === 'payout' &&
                  <div className="field">
                    <span>Paid:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`$${Number(reportData.payout_dispatcher.paid).toFixed(2)}`}
                    />
                  </div>
                }

                {
                  reportData.payout_dispatcher.type === 'payment' &&
                  <div className="field">
                    <span>Paid:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`$${reportData.payout_dispatcher.total_amount}`}
                    />
                  </div>
                }
              </div>
            </div>
          </div>

          {
            reportData.payout_dispatcher.type === 'payment' &&
            <div className="fieldset">

              <div className="legend">Payouts</div>

              <table className='table'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Range</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                {payoutDispetcher.map((payout, i) => (
                  <tr
                    key={i}
                  >
                    <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payout.created_at)}</td>
                    <td>
                      {getStatusName(payout.status_id)}
                    </td>
                    <td>{payout.date_start}{' - '}{payout.date_finish}</td>
                    <td>${payout.total_amount}</td>
                  </tr>
                ))}
              </table>
            </div>
          }

          {
            reportData.payout_dispatcher.type === 'payout' &&
            <div className="fieldset">
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div className="legend">Hours</div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    !editing &&
                    ((!reportData.permissions.payout_dispatcher_edit &&
                      reportData.permissions.payout_dispatcher_edit_own &&
                      reportData.payout_dispatcher.status_name === 'Need Confirmation') ||
                      (reportData.permissions.payout_dispatcher_edit && reportData.payout_dispatcher.status_name === 'Need Confirmation') ||
                      (reportData.permissions.payout_dispatcher_edit &&
                        (reportData.payout_dispatcher.status_name === 'Need to Check' || reportData.payout_dispatcher.status_name === 'Need Payment')
                      ))
                    &&
                    <button
                      className='close'
                      style={{
                        backgroundColor: '#01B41F',
                        border: '1px solid #01B41F',
                        padding: '4px 20px',
                        color: 'white',
                        height: 'fit-content',
                        width: 'fit-content'
                      }}
                      disabled={isLoading}
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </button>
                  }
                  {
                    editing &&
                    <button
                      className='close'
                      style={{
                        backgroundColor: '#FF0000',
                        border: '1px solid #FF0000',
                        padding: '4px 20px',
                        color: 'white',
                        height: 'fit-content',
                        width: 'fit-content',
                        marginRight: '10px'
                      }}
                      disabled={isLoading}
                      onClick={() => handleCancelEditingHours()}
                    >
                      Cancel
                    </button>
                  }
                  {
                    editing &&
                    <button
                      className='close'
                      style={{
                        backgroundColor: '#01B41F',
                        border: '1px solid #01B41F',
                        padding: '4px 20px',
                        color: 'white',
                        height: 'fit-content',
                        width: 'fit-content'
                      }}
                      disabled={isLoading}
                      onClick={() => handleSaveEditingHours()}
                    >
                      Save
                    </button>
                  }
                </div>
              </div>

              <div style={{ marginTop: '0' }} className="fields">
                <table className='table'>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'right', width: '175px' }}>Time</th>
                      {
                        daysOptions.map((day, index) => (
                          <th key={index}>
                            <div
                              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                              <span>
                                {moment(day, 'DD-MM-YYYY').format('DD MMM YYYY')}
                              </span>
                              <span>
                                {moment(day, 'DD-MM-YYYY').format('dddd')}
                              </span>
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  </thead>

                  {
                    payoutDispatcherTimesOfDay.map((hour, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: 'right' }}> {hour.span} </td>
                        {
                          daysOptions.map((day, i) => (
                            <td
                              key={i}
                              style={{
                                backgroundColor: getBGForHours(day, hour),
                                textAlign: 'center'
                              }}
                            >
                              <input
                                className='amount-field'
                                style={{
                                  backgroundColor: 'inherit',
                                  border: 'none',
                                  color: payoutDispatcherHours.filter(item => (item.date === day && item.hour_start === hour.value && item.payout_dispatcher_hour_id) || (item.date === day && item.hour_start === hour.value && item.hours)).length ? '#fff' : '#C3C6CB',
                                  appearance: 'textfield'
                                }}
                                type="number"
                                disabled={!editing}
                                value={getPayoutDispatcherHours(day, hour.value)}
                                onFocus={(event) => handleFocus(event)}
                                onChange={(event) => handleChangePrice(day, hour.value, event.target.value,)}
                                onKeyDown={(event) => handleChangePriceBackspace(day, hour.value, event)}
                              />
                            </td>
                          ))
                        }
                      </tr>
                    ))
                  }

                  <tfoot>
                    <tr>
                      <th style={{ textAlign: 'right' }}>Total:</th>
                      {
                        daysOptions.map((day, index) => (
                          <th style={{ textAlign: 'center' }} key={index}>
                            {sumHoursDay(day)}
                          </th>
                        ))
                      }
                    </tr>
                  </tfoot>
                </table>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <div>
                    <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Hours: </span>
                    <span style={{ marginRight: '30px', fontSize: '15pt' }}> {getTotalHours()} </span>
                  </div>

                  <div>
                    <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Amount: </span>
                    <span style={{ fontSize: '15pt' }}> ${editing ? getTotalAmountHoursBlock() : reportData.payout_dispatcher.total_hours_amount} </span>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '30px' }} className="legend">Orders</div>

              <div style={{ marginTop: '0' }} className="fields">
                <table className='table'>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'right', width: '175px' }}>Orders</th>
                      {
                        daysOptions.map((order, index) => (
                          <th key={index}>
                            <div
                              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                              <span>
                                {moment(order, 'DD-MM-YYYY').format('DD MMM YYYY')}
                              </span>
                              <span>
                                {moment(order, 'DD-MM-YYYY').format('dddd')}
                              </span>
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  </thead>

                  <tr>
                    <td style={{ textAlign: 'right' }}>Order</td>
                    {
                      payoutDispatcherOrders.map((order, index) => (
                        <td style={{ textAlign: 'center' }} key={index}>
                          {order.orders}
                        </td>
                      ))
                    }
                  </tr>
                </table>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <div>
                    <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Order: </span>
                    <span style={{ marginRight: '30px', fontSize: '15pt' }}> {reportData.payout_dispatcher.total_orders} </span>
                  </div>

                  <div>
                    <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Amount: </span>
                    <span style={{ fontSize: '15pt' }}> ${reportData.payout_dispatcher.total_orders_amount} </span>
                  </div>
                </div>
              </div>

              {
                !!reportData.payout_dispatcher.payout_dispatcher_mentor_orders.length &&
                <>
                  <div style={{ marginTop: '30px' }} className="legend">Dispatchers</div>

                  <div style={{ marginTop: '0' }} className="fields">
                    <table className='table'>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'right', width: '175px' }}>Dispatcher</th>
                          {
                            daysOptions.map((mentor, index) => (
                              <th key={index}>
                                <div
                                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                >
                                  <span>
                                    {moment(mentor, 'DD-MM-YYYY').format('DD MMM YYYY')}
                                  </span>
                                  <span>
                                    {moment(mentor, 'DD-MM-YYYY').format('dddd')}
                                  </span>
                                </div>
                              </th>
                            ))
                          }
                        </tr>
                      </thead>

                      {
                        dispatcherOptions.map((dispatcher, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: 'right' }}> {dispatcher} </td>

                            {
                              daysOptions.map(date => (
                                <td style={{ textAlign: 'center' }} key={date}>
                                  {
                                    getDispatcherName(date, dispatcher)
                                  }
                                </td>
                              ))
                            }
                          </tr>
                        ))
                      }

                      <tfoot>
                        <tr>
                          <th style={{ alignItems: 'right' }}>Total</th>
                          {
                            daysOptions.map(date => (
                              <th style={{ textAlign: 'center' }} key={date}>
                                {sumDispatcher(date)}
                              </th>
                            ))
                          }
                        </tr>
                      </tfoot>
                    </table>

                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <div>
                        <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Order: </span>
                        <span style={{ marginRight: '30px', fontSize: '15pt' }}> {reportData.payout_dispatcher.total_mentor_orders} </span>
                      </div>

                      <div>
                        <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Amount: </span>
                        <span style={{ fontSize: '15pt' }}> ${reportData.payout_dispatcher.total_mentor_orders_amount} </span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          }

          {
            reportData.payout_dispatcher.type === 'payout' &&
            <div className="fieldset">
              <div className="legend">Payments</div>

              <div style={{ marginTop: '0' }} className="fields">
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th style={{ textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>

                  {
                    reportData.payout_dispatcher.payout_dispatcher.map(payment => (
                      <tr key={payment.payout_dispatcher_id}>
                        <td> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)} </td>

                        <td style={{ textAlign: 'right' }}> ${payment.total_amount} </td>
                      </tr>
                    ))
                  }
                </table>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <div>
                    <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Amount: </span>
                    <span style={{ fontSize: '15pt' }}> ${getSumPayoutDispatcher().toFixed(2)} </span>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.payout_dispatcher.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.payout_dispatcher.created_by ? reportData.payout_dispatcher.created_by : 'System'}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.payout_dispatcher.updated_at)}
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.payout_dispatcher.updated_by}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {
            reportData.permissions.payout_dispatcher_delete &&
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                <button className="_red" onClick={() => setDeleting(true)}>Delete Payout Dispatcher</button>
              </div>
            </div>
          }

          {
            reportData.permissions.payout_dispatcher_delete &&
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Payout Dispatcher
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the payout dispatcher it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isLoading}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}
