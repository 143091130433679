import { memo } from 'react'

import Icon from '../../components/Icon'
import Select from '../../components/Select'

import { CompaniesProps } from './Models'

interface BookingCenterSecondBlockProps {
  selectedCompany: string
  setSelectedCompany: (value: string) => void
  companies: CompaniesProps[] | []
}

function BookingCenterSecondBlock({
  selectedCompany,
  setSelectedCompany,
  companies
}: BookingCenterSecondBlockProps) {
  return (
    <div className='booking-center_second-block'>
      <div className='call-company'>
        <div className='block-content'>
          <div className='block-content-title'>
            <div className='block-title'>
              Company Name
            </div>

            {
              selectedCompany === '' ?
                <div className='btn-marking attention'>
                  !
                </div> :
                <div className='btn-marking saved'>
                  <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                </div>
            }
          </div>

          <div className='call-company_name'>
            <p style={{ marginRight: '.5rem' }} >Company:</p>

            <div className='call-company_selector'>
              <Select options={companies.map((company) => ({
                span: company.name,
                value: company.company_id
              }))} selectedOption={selectedCompany} onChange={(value: string) => setSelectedCompany(value)} />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default memo(BookingCenterSecondBlock)
