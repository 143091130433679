import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Select from '../../components/Select'

import { httpClientUpdate, nErrorUpdate, validateEmail } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { HttpMailboxesReport, UserGroupsProps } from './List'

import "../../styles/pages/common/entity-edit.sass"

export interface TemplateProps {
  responsibles: string
  template: string
  type: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [data, setData] = useState({
    email: '',
    password: '',
    can_read_user_group_id: '',
    can_write_user_group_id: '',
    imap_domain: '',
    smtp_domain: '',
    imap_port: '',
    smtp_port: '',
  })

  const [isSaving, setIsSaving] = useState(false)
  const [edit, setEdit] = useState<UserGroupsProps[]>([])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  useEffect(() => {
    loadMailboxes()

    return () => {
      setEdit([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadMailboxes() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    try {
      // https://2022back4.artemiudintsev.com/api/mailboxes/report?account_id=88888&limit_rows=2&page=1
      const { data: { data: mailboxes, success, error } } = (await httpClientUpdate.get('/mailboxes/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: '1',
          page: '1',
        }
      })) as { data: HttpMailboxesReport }
      if (success) {
        setEdit(mailboxes.edit.user_groups)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/mailboxes
      const response = await httpClientUpdate.post(`/mailboxes`, {
        account_id: activeAccountId,
        email: data.email,
        password: data.password,
        imap_domain: data.imap_domain,
        smtp_domain: data.smtp_domain,
        imap_port: data.imap_port,
        smtp_port: data.smtp_port,
        can_read_user_group_id: data.can_read_user_group_id,
        can_write_user_group_id: data.can_write_user_group_id,
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('mailboxes', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function checkDisabled() {
    let check = false

    let checkEmail = validateEmail(data.email)

    if (!checkEmail) {
      check = true
    }

    if (data.password === '') {
      check = true
    }
    if (data.imap_domain === '') {
      check = true
    }
    if (data.smtp_domain === '') {
      check = true
    }
    if (data.imap_port === '') {
      check = true
    }
    if (data.smtp_port === '') {
      check = true
    }
    if (data.can_read_user_group_id === '') {
      check = true
    }
    if (data.can_write_user_group_id === '') {
      check = true
    }

    return check
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Mailbox</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('mailboxes', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">

            <div className="field">
              <span>Email:</span>
              <input
                type="text"
                value={data.email}
                onChange={(event) => setData({ ...data, email: event.target.value })}
              />
            </div>

          </div>

          <div className="__right">
            <div className="field">
              <span>Password:</span>
              <input
                type="text"
                value={data.password}
                onChange={(event) => setData({ ...data, password: event.target.value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Settings</div>

        <div className="fields">
          <div className="__left">

            <div className="field">
              <span>IMAP domain:</span>
              <input
                type="text"
                value={data.imap_domain}
                onChange={(event) => setData({ ...data, imap_domain: event.target.value })}
              />
            </div>

            <div className="field">
              <span>IMAP port:</span>
              <input
                type="text"
                value={data.imap_port}
                onChange={(event) => setData({ ...data, imap_port: event.target.value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>SMTP domain:</span>
              <input
                type="text"
                value={data.smtp_domain}
                onChange={(event) => setData({ ...data, smtp_domain: event.target.value })}
              />
            </div>

            <div className="field">
              <span>SMTP port:</span>
              <input
                type="text"
                value={data.smtp_port}
                onChange={(event) => setData({ ...data, smtp_port: event.target.value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Access</div>

        <div className="fields">
          <div className="__left">

            <div className="field">
              <span>Can Read:</span>

              <Select
                options={edit.map((group) => ({
                  span: group.name,
                  value: group.user_group_id
                }))}
                selectedOption={data.can_read_user_group_id}
                onChange={(value: string) => setData({ ...data, can_read_user_group_id: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Can Send:</span>

              <Select
                options={edit.map((group) => ({
                  span: group.name,
                  value: group.user_group_id
                }))}
                selectedOption={data.can_write_user_group_id}
                onChange={(value: string) => setData({ ...data, can_write_user_group_id: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('mailboxes', {
              companyId: activeAccountId,
            }, { reload: true })}
          >
            Cancel
          </button>
          <button
            disabled={checkDisabled() || isSaving}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
