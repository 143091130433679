import { useState } from "react";
import classNames from "classnames"
import { Transition } from 'react-transition-group'

import Icon from "../../components/Icon";

import "../../styles/components/select.sass"

export interface Option {
  span: string,
  value: any
}

interface Props {

  options: Option[],

  selectedOption: string | number | boolean,
  onChange(value: string | number | boolean, span?: any): void,

  zeroed?: boolean,
  disabled?: boolean

  error?: boolean
}

function SelectComponent({
  options,
  selectedOption,
  onChange,
  zeroed,
  disabled,
  error
}: Props) {

  const [active, setActive] = useState(false)

  // Get selected option function
  function getSelectedOption() {
    return options.find((option) => option.value === selectedOption) || {
      span: '',
      value: ''
    }
  }

  // Render function
  return (
    <div className="SelectComponent">
      <button
        disabled={disabled}
        className={classNames({ _zeroed: zeroed, error: error }, '_iconed', 'sb')}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setActive(true)
        }}
        onFocus={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setActive(true)
        }}
        onBlur={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setActive(false)
        }}
      >
        <span>{getSelectedOption().span}</span>
        <Icon className={classNames({ _flipped: active })} icon="arrow-65" />
      </button>

      <Transition in={active} mountOnEnter={true} unmountOnExit={true} timeout={210}>
        {(state) => (
          <div className={classNames("options-list-wrapper", `transition-fade-${state}`)}>

            {options.map((option, i) => (
              <button
                className={classNames("_zeroed", "option",
                  {
                    _active: option.value === getSelectedOption().value,
                  }
                )}
                key={i}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  setActive(false);
                  onChange(option.value, option.span)
                }}
              >
                {option.span || <span>&nbsp;</span>}
              </button>
            ))}
          </div>
        )}
      </Transition>
    </div>
  )
}

export default SelectComponent
