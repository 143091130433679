import { useState, useRef } from 'react'

import Icon from '../../components/Icon'

import { useAppSelector } from '../../store/hooks'
import { useOuterClick } from '../../funcs'

interface ModalImageProps {
  image: {
    url: string
    type: string
  }
  setOpen: (value: boolean) => void
}

export default function ModalImage({ image, setOpen }: ModalImageProps) {
  let firstLoading = true

  const imageBlockRef = useRef<HTMLDivElement | null>(null)

  const navActive = useAppSelector((store) => store.navActive)

  const [rotate, setRotate] = useState<number>(0)
  const [imageView, setImageView] = useState(100)

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [draging, setDraging] = useState(false)
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  const downloadImage = async () => {
    const name = image.url.split('/')[image.url.split('/').length - 1]

    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    img.src = image.url;
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx && ctx.drawImage(img, 0, 0);
      const a = document.createElement('a');
      a.download = name;
      a.href = canvas.toDataURL('image/png');
      a.click();
    };
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    !!ev && !firstLoading && setOpen(false)
    if (firstLoading) {
      firstLoading = false
    }
  });

  function handleRotate() {
    rotate < 270 ? setRotate(rotate + 90) : setRotate(0)
  }

  function handleRefreshZoom(event: any) {
    event.stopPropagation()
    setImageView(100)
  }

  function handleImageZoom(e: any) {
    if (!isMouseDown) {
      imageView < 110 ? setImageView(120) : setImageView(100)
    }
  }

  const handleDragStart = (e: any) => {
    if (!imageBlockRef.current) return
    const slider = imageBlockRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true)
  }

  const handleDragEnd = (e: any) => {
    if (!imageBlockRef.current) return
    e.preventDefault()
    e.stopPropagation()

    setIsMouseDown(false)
    setTimeout(() => {
      setDraging(false)
    }, 100)
  }

  const handleDrag = (e: any) => {
    if (!isMouseDown || !imageBlockRef.current) return;
    e.preventDefault();
    e.stopPropagation();
    const slider = imageBlockRef.current;
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    setDraging(true)
  }

  return (
    <div
      style={{
        width: '100vw',
        height: 'calc(100vh + 20px)',
        display: 'flex',
        position: 'fixed',
        top: '-20px',
        zIndex: '99',
        background: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <div
        className='sms-modal'
        style={{ margin: `${navActive.is ? '0 115px' : '0 auto'}` }}
      >
        <div
          className='sms-modal-container'
          ref={contextMenuRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '60%',
            alignItems: 'center',
            height: '70vh',
            maxHeight: '70vh',
            backgroundImage: 'linear-gradient(rgb(10, 10, 10) 10%, rgb(45, 45, 45), rgb(10, 10, 10) 90%)',
            padding: '5px',
            position: 'relative',
            border: '1px solid rgb(46, 46, 46)',
            borderRadius: '5px'
          }}
        >
          <div className='btns-top'>
            <button
              title='Close'
              onClick={() => setOpen(false)}
            >
              <Icon viewBox="0 0 24 24" icon="x-mark-1" />
            </button>
          </div>
          <div
            className='scrollbar-container'
            ref={imageBlockRef}
            style={{ width: '100%', height: 'calc(100% - 70px)', margin: '20px', overflow: 'scroll' }}
            onMouseDown={handleDragStart}
            onMouseUp={(event) => handleDragEnd(event)}
            onMouseMove={handleDrag}
            onClick={(event) => !draging && handleImageZoom(event)}
          >
            <div
              style={{
                width: `${imageView}%`,
                // height: imageView < 101 ? `100%` : `auto`,
                // height: '100%',
                height: `${imageView}%`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto'
              }}
            >
              <img
                style={{
                  // width: imageView < 101 ? 'auto' : '100%',
                  width: `${imageView}%`,
                  // objectFit: 'cover',
                  objectFit: 'contain',
                  transform: `rotate(${rotate}deg)`,
                  cursor: imageView < 101 ? 'zoom-in' : isMouseDown ? 'grabbing' : 'zoom-out',
                  margin: '0'
                }}
                src={image.url}
                alt="Client_image"
              />
            </div>
          </div>

          <div className='btns-bottom'>
            <div>
              <button
                title='Turn'
                onClick={() => handleRotate()}
              >
                <Icon
                  style={{ width: '1em', height: '1em', verticalAlign: 'middle', overflow: 'hidden' }}
                  viewBox="0 0 1024 1024"
                  icon="rotate-90"
                />
              </button>

              <div className='view-control'>
                <span className='view-control-bar'>
                  <span
                    style={{ width: `${imageView - 100}%` }}
                    className='view-control-fill'></span>
                </span>

                <input
                  onChange={(event) => setImageView(+event.target.value)}
                  value={imageView}
                  className='view-control-input'
                  type="range"
                  min={100}
                  max={200}
                />
              </div>

              {
                imageView > 100 ?
                  <button
                    title='Refresh'
                    onClick={(e) => handleRefreshZoom(e)}
                  >
                    <Icon
                      style={{ width: '1em', height: '1em', verticalAlign: 'middle', overflow: 'hidden' }}
                      viewBox="0 0 1024 1024"
                      icon="refresh-sms"
                    />
                  </button> : null
              }
            </div>
            <div>
              <button
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0', border: 'none' }}
                title='Download'
                onClick={() => downloadImage()}
              >
                <Icon
                  style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                  viewBox="0 0 1024 1024"
                  icon="download-sms"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
