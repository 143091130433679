export default function DeniedCallSvg() {
  return (
    <svg viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="#2C7FFA" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
        d="M12.4829 4.73077L10.4829 4.73077M1.48291 4.73077L5.48291 7.73077M1.48291 4.73077L5.48291 1.73077M1.48291 4.73077L7.48291 4.73077"
      ></path>

      <path
        stroke="#FF0000" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
        d="M10.2085 2.23083L7.77588 7.23077"
      ></path>
    </svg>
  )
}
