import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

export interface HttpMailboxesReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: MailboxesReport
}

interface MailboxesReport {

  interface: {

    filter_words: {

      dispatcher: string[],
      friendly_name: string[],
      status: string[],
      source: string[],
      area: string[],
    },

    tag_words: string[],

    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  mailboxes: MailboxesProps[]

  permissions: {
    mailbox_add: boolean
    mailbox_show: boolean
  }

  edit: {
    user_groups: UserGroupsProps[]
  }
}

export interface UserGroupsProps {
  name: string
  user_group_id: string
}

interface MailboxesProps {
  can_read_user_group_id: string
  can_write_user_group_id: string
  created_at: string
  created_by: string
  email: string
  is_connected: boolean
  mailbox_id: string
  mails_last_updated_at: string
  updated_at: string
  updated_by: string
  imap_domain: string
  imap_port: string
  smtp_domain: string
  smtp_port: string
}

interface MailBoxesPage_ListProps {
  updated: number
}

function MailboxesPage_List({ updated }: MailBoxesPage_ListProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<MailboxesReport | null>(null)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    sort: {
      field: cookiesSetting && cookiesSetting?.mailboxes?.sort_field ? cookiesSetting.mailboxes.sort_field : 'email',
      direction: cookiesSetting && cookiesSetting?.mailboxes?.sort_type ? cookiesSetting.mailboxes.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Email',
      value: 'email'
    }, {
      span: 'IMAP Domain',
      value: 'IMAP_domain'
    }, {
      span: 'IMAP Port',
      value: 'IMAP_port'
    }, {
      span: 'SMTP Domain',
      value: 'SMTP_domain'
    }, {
      span: 'SMTP Port',
      value: 'SMTP_port'
    }, {
      span: 'Can Read',
      value: 'read_user_group'
    }, {
      span: 'Can Send',
      value: 'send_user_group'
    }, {
      span: '',
      value: ''
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.mailboxes?.sort_field ? cookiesSetting.mailboxes.sort_field : 'email',
        direction: cookiesSetting && cookiesSetting?.mailboxes?.sort_type ? cookiesSetting.mailboxes.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load mailboxes function
  async function loadMailboxes() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    setCookie('settings', { ...cookiesSetting, mailboxes: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.mailboxes?.limit_rows ? cookiesSetting?.mailboxes.limit_rows : reportsMaxRows } })
    try {
      // https://2022back4.artemiudintsev.com/api/mailboxes/report?account_id=88888&limit_rows=2&page=1
      const { data: { data: mailboxes, success, error } } = (await httpClientUpdate.get('/mailboxes/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.mailboxes?.limit_rows ? JSON.stringify(cookiesSetting?.mailboxes?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: JSON.stringify(localInterface.page),
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
        }
      })) as { data: HttpMailboxesReport }
      if (success) {

        setReportData({
          ...mailboxes,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, mailboxes: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadMailboxes()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getNameGroup(id: string) {
    let name = ''

    reportData?.edit.user_groups.forEach(item => {
      if (item.user_group_id === id) {
        name = item.name
      }
    })

    return name
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="CallsPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Mailboxes</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            setReportIsDeprecated(false);
            $setUpdater(Math.random());
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.mailboxes?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, mailboxes: { ...cookiesSetting.mailboxes, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            <div className="add-button-wrapper">
              {
                reportData.permissions.mailbox_add &&
                <button
                  className="_iconed _rounded add-button"
                  onClick={() => $router.router.navigate('mailboxes.new', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                  }, { reload: true })}
                >
                  <Icon icon="plus-thin" />
                  <span>Add Mailbox</span>
                  <Icon icon="user-29" />
                </button>
              }
            </div>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <th></th>
                <ReportTableField
                  contents={(<span>Email</span>)}

                  sortDirection={localInterface.sort.field === 'email' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'email', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>IMAP Domain</span>)}

                  sortDirection={localInterface.sort.field === 'IMAP_domain' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'IMAP_domain', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>IMAP Port</span>)}

                  sortDirection={localInterface.sort.field === 'IMAP_port' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'IMAP_port', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>SMTP Domain</span>)}

                  sortDirection={localInterface.sort.field === 'SMTP_domain' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'SMTP_domain', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>SMTP Port</span>)}

                  sortDirection={localInterface.sort.field === 'SMTP_port' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'SMTP_port', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Can Read</span>)}

                  sortDirection={localInterface.sort.field === 'read_user_group' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'read_user_group', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Can Send</span>)}

                  sortDirection={localInterface.sort.field === 'send_user_group' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'send_user_group', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {
              reportData.mailboxes.map((mail, i) => (
                <tr
                  key={mail.mailbox_id}
                  style={{ cursor: reportData.permissions.mailbox_show ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.mailbox_show &&
                    mail.mailbox_id &&
                    $router.router.navigate('mailboxes.item', {
                      companyId: activeAccountId,
                      mailboxesId: mail.mailbox_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) => {
                    reportData.permissions.mailbox_show &&
                      mail.mailbox_id &&
                      handleChangeMenu(event, mail.mailbox_id, 'mailboxes', i)
                  }}
                >
                  <td style={{ width: '40px' }}>
                    <div className={classNames('mailbox',
                      {
                        _connected: mail.is_connected,
                      }
                    )}></div>
                  </td>
                  <td> {mail.email} </td>
                  <td> {mail.imap_domain} </td>
                  <td> {mail.imap_port} </td>
                  <td> {mail.smtp_domain} </td>
                  <td> {mail.smtp_port} </td>
                  <td> {getNameGroup(mail.can_read_user_group_id)} </td>
                  <td>
                    {getNameGroup(mail.can_write_user_group_id)}

                    {
                      reportData.permissions.mailbox_show &&
                      showContext === `mailboxes_${mail.mailbox_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('mailboxes.item', {
                              companyId: activeAccountId,
                              mailboxesId: mail.mailbox_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/mailboxes/${mail.mailbox_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))
            }
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.mailboxes.map((mail, i) => (
              <tr
                key={i}
                style={{ position: 'relative', cursor: reportData.permissions.mailbox_show ? 'pointer' : 'inherit' }}
                onClick={() =>
                  reportData.permissions.mailbox_show &&
                  mail.mailbox_id &&
                  $router.router.navigate('mailboxes.item', {
                    companyId: activeAccountId,
                    mailboxesId: mail.mailbox_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.mailbox_show &&
                  mail.mailbox_id &&
                  handleChangeMenu(event, mail.mailbox_id, 'mailboxes', i)}
              >
                <td style={{ width: '40px' }}>
                  <div className={classNames('mailbox',
                    {
                      _connected: mail.is_connected,
                    }
                  )}></div>
                </td>

                <td>
                  {mail.email}
                </td>

                <td>
                  <div>SMTP Domain: {mail.smtp_domain}  </div>
                  <div>SMTP Port: {mail.smtp_port}  </div>
                </td>

                <td>
                  <div>IMAP Domain: {mail.imap_domain} </div>
                  <div>IMAP Port: {mail.imap_port} </div>
                </td>

                <td>

                  <div>Can Read: {getNameGroup(mail.can_read_user_group_id)} </div>
                  <div>Can Send: {getNameGroup(mail.can_write_user_group_id)} </div>
                  {
                    reportData.permissions.mailbox_show &&
                    showContext === `mailboxes_${mail.mailbox_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('mailboxes.item', {
                            companyId: activeAccountId,
                            mailboxesId: mail.mailbox_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/mailboxes/${mail.mailbox_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.mailboxes.map((mail, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.mailbox_show &&
                  mail.mailbox_id &&
                  $router.router.navigate('mailboxes.item', {
                    companyId: activeAccountId,
                    mailboxesId: mail.mailbox_id,
                    localInterface: localInterface,
                  }, { reload: true })}>
                <div className="__top">
                  <div className="__left">
                    <div className={classNames('mailbox-mobile',
                      {
                        _connected: mail.is_connected,
                      }
                    )}
                      style={{ marginRight: '5px' }}
                    ></div>
                    <span className="gray">Email: </span>
                    <span> {mail.email}</span>
                  </div>

                  <div className="__right">
                    <div>
                      Can Read: {getNameGroup(mail.can_read_user_group_id)}
                    </div>
                    <div>
                      Can Send: {getNameGroup(mail.can_write_user_group_id)}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      IMAP Domain: <b> {mail.imap_domain} </b>
                    </div>
                    <div>
                      SMTP Domain: <b> {mail.smtp_domain} </b>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      IMAP Port: <b> {mail.imap_port} </b>
                    </div>
                    <div>
                      SMTP Port: <b> {mail.smtp_port} </b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.mailboxes?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, mailboxes: { ...cookiesSetting.mailboxes, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default MailboxesPage_List
