import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import classNames from "classnames"
import Datetime from 'react-datetime'

import Icon from "../../../components/Icon"
import Select from "../../../components/Select";
import Checkbox from "../../../components/Checkbox"
import Switcher from "../../../components/Switcher"
import DispatcherSchedule from './DispatcherSchedule'

import { dateToInfoBlock, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

import { CallCenter_CallGroup, CallCenter_Dispatcher } from "../../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"
import moment from "moment";

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DispatcherData
}

export interface DispatcherData {

  permissions: {
    call_group_show: boolean
    dispatcher_add: boolean
    dispatcher_delete: boolean
    dispatcher_edit: boolean
    dispatcher_report_show: boolean
    dispatcher_schedule_add: boolean
    dispatcher_schedule_delete: boolean
    dispatcher_schedule_edit: boolean
    dispatcher_schedule_show: boolean
    dispatcher_show: boolean
    dispatcher_salary_show: boolean
  },

  dispatcher: CallCenter_Dispatcher[],

  call_groups: CallCenter_CallGroup[],

  edit: {
    users: [
      {
        user_id: string,
        user: string
      }[]
    ],
    mentors: {
      dispatcher: string
      user_name: string
      user_id: string
    }[]
  }
}

function CallCenter_DispatchersPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [page, setPage] = useState('main')

  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [dispatcherData, setDispatcherData] = useState<DispatcherData | null>(null)

  const [newDispatcherData, setNewDispatcherData] = useState<Partial<CallCenter_Dispatcher>>({

  })

  function handleCancelEdit() {
    setEditing(false)
    if (dispatcherData) {
      if (dispatcherData.permissions.dispatcher_salary_show) {
        setNewDispatcherData({
          name: dispatcherData.dispatcher[0].name,
          active: dispatcherData.dispatcher[0].active,
          user: dispatcherData.edit.users[0][0].user_id,
          phone: dispatcherData.dispatcher[0].phone,
          is_phone: dispatcherData.dispatcher[0].is_phone,
          is_softphone: dispatcherData.dispatcher[0].is_softphone,
          mentor_id: dispatcherData.dispatcher[0].mentor_id,
          salary_per_hour: dispatcherData.dispatcher[0].salary_per_hour ? Number(dispatcherData.dispatcher[0].salary_per_hour) : 0.00,
          salary_per_order: dispatcherData.dispatcher[0].salary_per_order ? Number(dispatcherData.dispatcher[0].salary_per_order) : 0.00,
          start_from_date: dispatcherData.dispatcher[0].start_from_date ? dispatcherData.dispatcher[0].start_from_date : moment.utc().format('MM/DD/YYYY'),
          is_auto_calculation: dispatcherData.dispatcher[0].is_auto_calculation ? true : false,
          mentor_salary_per_order: dispatcherData.dispatcher[0].mentor_salary_per_order ? Number(dispatcherData.dispatcher[0].mentor_salary_per_order) : 0.00,
        })
      } else {
        setNewDispatcherData({
          name: dispatcherData.dispatcher[0].name,
          active: dispatcherData.dispatcher[0].active,
          user: dispatcherData.edit.users[0][0].user_id,
          phone: dispatcherData.dispatcher[0].phone,
          is_phone: dispatcherData.dispatcher[0].is_phone,
          is_softphone: dispatcherData.dispatcher[0].is_softphone,
          mentor_id: dispatcherData.dispatcher[0].mentor_id,
        })
      }
    }
  }

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/callcenter/dispatchers/${$router.router.getState().params.dispatchersId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.dispatchers', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const { name, active, phone, is_phone, is_softphone, dispatcher_code, mentor_id } = newDispatcherData

      let salaryData: {
        salary_per_hour?: string
        salary_per_order?: string;
        start_from_date?: string;
        is_auto_calculation?: number;
        mentor_salary_per_order?: string;
      } = {}
      if (dispatcherData?.permissions.dispatcher_salary_show) {
        salaryData.salary_per_hour = newDispatcherData?.salary_per_hour?.toFixed(2)
        salaryData.salary_per_order = newDispatcherData?.salary_per_order?.toFixed(2)
        salaryData.start_from_date = moment(newDispatcherData?.start_from_date).format('YYYY-MM-DD')
        salaryData.is_auto_calculation = newDispatcherData?.is_auto_calculation ? 1 : 0
        salaryData.mentor_salary_per_order = newDispatcherData?.mentor_salary_per_order?.toFixed(2)
      }

      const response = await httpClientUpdate.put(`/callcenter/dispatchers/${$router.router.getState().params.dispatchersId}`, {
        account_id: activeAccountId,
        name,
        phone: phone && formatPhoneNumberToServerString(phone),
        active: active ? 1 : 0,
        is_phone: is_phone ? 1 : 0,
        is_softphone: is_softphone ? 1 : 0,
        code: dispatcher_code,
        mentor_id: mentor_id ? mentor_id : '',
        ...salaryData,
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: dispatcherData, success, error } } = (await httpClientUpdate.get('/callcenter/dispatchers/' + $router.router.getState().params.dispatchersId, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (dispatcherData.permissions.dispatcher_show) {
          setDispatcherData(dispatcherData)

          if (dispatcherData.permissions.dispatcher_salary_show) {
            setNewDispatcherData({
              name: dispatcherData.dispatcher[0].name,
              active: dispatcherData.dispatcher[0].active,
              user: dispatcherData.dispatcher[0].user,
              phone: dispatcherData.dispatcher[0].phone,
              is_phone: dispatcherData.dispatcher[0].is_phone,
              is_softphone: dispatcherData.dispatcher[0].is_softphone,
              dispatcher_code: dispatcherData.dispatcher[0].dispatcher_code,
              mentor_id: dispatcherData.dispatcher[0].mentor_id,
              salary_per_hour: dispatcherData.dispatcher[0].salary_per_hour ? Number(dispatcherData.dispatcher[0].salary_per_hour) : 0.00,
              salary_per_order: dispatcherData.dispatcher[0].salary_per_order ? Number(dispatcherData.dispatcher[0].salary_per_order) : 0.00,
              start_from_date: dispatcherData.dispatcher[0].start_from_date ? dispatcherData.dispatcher[0].start_from_date : moment.utc().format('MM/DD/YYYY'),
              is_auto_calculation: dispatcherData.dispatcher[0].is_auto_calculation ? true : false,
              mentor_salary_per_order: dispatcherData.dispatcher[0].mentor_salary_per_order ? Number(dispatcherData.dispatcher[0].mentor_salary_per_order) : 0.00,
            })
          } else {
            setNewDispatcherData({
              name: dispatcherData.dispatcher[0].name,
              active: dispatcherData.dispatcher[0].active,
              user: dispatcherData.dispatcher[0].user,
              phone: dispatcherData.dispatcher[0].phone,
              is_phone: dispatcherData.dispatcher[0].is_phone,
              is_softphone: dispatcherData.dispatcher[0].is_softphone,
              dispatcher_code: dispatcherData.dispatcher[0].dispatcher_code,
              mentor_id: dispatcherData.dispatcher[0].mentor_id
            })
          }
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load dispatcher data
  useEffect(() => {
    page === 'main' && loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => setReadyToSave(true), [
    newDispatcherData
  ])

  function handleBackDispatchersItem() {
    setPage('main')
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(event: any, name: string) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewDispatcherData({
        ...newDispatcherData,
        [name]: +newNumber
      })
    } else if (price.length === 1) {
      let newNumber = `0.0${price}`
      setNewDispatcherData({
        ...newDispatcherData,
        [name]: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any, name: string) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {

        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewDispatcherData({
          ...newDispatcherData,
          [name]: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewDispatcherData({
          ...newDispatcherData,
          [name]: +newNumber
        })
      }
    }
  }

  // Render function
  return (<>
    {
      page === 'main' ?
        <>
          {dispatcherData && newDispatcherData.active !== undefined ? (
            <div className="CallCenter_DispatchersPage_Item entity-edit">

              {/* Top navigation */}
              <div className="top-nav">
                <BaseLink
                  router={$router.router}
                  routeName="call_center.phoneNumbers"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
                >
                  <Icon icon="phone-1" />
                  <span>Phone Numbers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.dispatchers"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
                >
                  <Icon icon="user-1" />
                  <span>Dispatchers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.additionalTime"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="time-22" />
                  <span>Additional Time</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.absence"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.absence" })}
                >
                  <Icon icon="time-16" />
                  <span>Absence</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.groups"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.groups" })}
                >
                  <Icon icon="user-29" />
                  <span>Groups</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.callFlows"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
                >
                  <Icon icon="share-7" />
                  <span>Call Flows</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.caller_groups"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
                >
                  <Icon icon="user-29" />
                  <span>Caller Groups</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.temporaryClosed"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="close-664" />
                  <span>Temporary Closed</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.callCenters"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
                >
                  <Icon icon="delivery-8" />
                  <span>Call Centers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.audioLibrary"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="audio-15" />
                  <span>Audio Library</span>
                </BaseLink>
              </div>

              <div className="wrapper flex-container sb">
                <h1>Dispatcher</h1>

                {/* Temporarily removed the rights check on the field edit_dispatcher */}
                <div style={{ display: 'flex' }}>
                  <button
                    disabled={editing}
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    className="_wa"
                    onClick={() =>
                      $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.dispatchers',
                        {
                          companyId: activeAccountId,
                          [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  {
                    dispatcherData?.permissions.dispatcher_schedule_show &&
                    <button
                      className="_wa"
                      style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                      disabled={editing}
                      onClick={() => setPage('schedule')}
                    >
                      Schedule
                    </button>
                  }

                  {dispatcherData?.permissions.dispatcher_edit && !editing ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      Edit
                    </button>
                  ) : null}
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field" style={{ zIndex: 6 }}>
                      <span>Name:</span>

                      <input
                        className={classNames({
                          _error: errorFields.includes('name')
                        })}
                        disabled={!editing}
                        type="text"
                        defaultValue={newDispatcherData.name}
                        onChange={({ target: { value } }) => {
                          setNewDispatcherData({ ...newDispatcherData, name: value })
                          errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                        }}
                      />
                    </div>
                  </div>


                  <div className="__right">
                    <div className="field" style={{ zIndex: 6 }}>
                      <span>Active:</span>

                      <Switcher
                        disabled={!editing}
                        checked={newDispatcherData.active as boolean}
                        onChange={(value) => setNewDispatcherData({ ...newDispatcherData, active: value })} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Settings</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>User:</span>

                      <input
                        disabled={true}
                        type="text"
                        defaultValue={newDispatcherData.user}
                      />
                    </div>

                    <div className="field">
                      <span>Phone:</span>

                      <ReactInputMask
                        className={classNames({
                          _error: errorFields.includes('phone')
                        })}
                        disabled={!editing}
                        type="text"
                        mask="+1 (999) 999-9999"
                        value={newDispatcherData.phone}
                        onChange={({ target: { value } }) => {
                          setNewDispatcherData({ ...newDispatcherData, phone: value })
                          errorFields.includes('phone') && setErrorFields(errorFields.filter(item => item !== 'phone'))
                        }}
                      />
                    </div>

                    <div className="field">
                      <span>Code:</span>

                      <input
                        className={classNames({
                          _error: errorFields.includes('code')
                        })}
                        type="number"
                        disabled={!editing}
                        value={newDispatcherData.dispatcher_code}
                        onChange={({ target: { value } }) => {
                          setNewDispatcherData({ ...newDispatcherData, dispatcher_code: +value })
                          errorFields.includes('code') && setErrorFields(errorFields.filter(item => item !== 'code'))
                        }}
                      />
                    </div>
                  </div>

                  <div className="__right">
                    <div className="field">
                      <span>Softphone:</span>

                      <Checkbox disabled={!editing} contents='' value={newDispatcherData.is_softphone} onChange={(value) => setNewDispatcherData({ ...newDispatcherData, is_softphone: value })} />
                    </div>

                    <div className="field">
                      <span>Phone:</span>

                      <Checkbox disabled={!editing} contents='' value={newDispatcherData.is_phone} onChange={(value) => setNewDispatcherData({ ...newDispatcherData, is_phone: value })} />
                    </div>

                    <div className="field">
                      <span>Mentor:</span>

                      <div className="input-wrapper">
                        <Select
                          error={(errorFields as string[]).includes('mentor_id')}
                          options={
                            [{ user_name: '', user_id: '', dispatcher: '' }]
                              .concat(dispatcherData.edit.mentors.filter(item => item.user_id !== dispatcherData.dispatcher[0].user_id))
                              .map((mentor) => ({
                                span: mentor.dispatcher ? mentor.dispatcher : mentor.user_name,
                                value: mentor.user_id
                              }))}
                          disabled={!editing}
                          selectedOption={newDispatcherData.mentor_id as string}
                          onChange={(value: string) => {
                            setNewDispatcherData({ ...newDispatcherData, mentor_id: value });
                            errorFields.includes('mentor_id') && setErrorFields(errorFields.filter(item => item !== 'mentor_id'))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                dispatcherData.permissions.dispatcher_salary_show &&
                <div className="fieldset">
                  <div className="legend">Salary</div>

                  <div className="fields">
                    <div className="__left">

                      <div className="field">
                        <span>Salary Per Hour:</span>

                        <input
                          className="appearance"
                          type="number"
                          disabled={!editing}
                          value={newDispatcherData.salary_per_hour ? newDispatcherData.salary_per_hour.toFixed(2) : Number().toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangePrice(event, 'salary_per_hour')}
                          onKeyDown={(event) => handleChangePriceBackspace(event, 'salary_per_hour')}
                        />
                      </div>

                      <div className="field">
                        <span>Salary Per Order:</span>

                        <input
                          className="appearance"
                          type="number"
                          disabled={!editing}
                          value={newDispatcherData.salary_per_order ? newDispatcherData.salary_per_order.toFixed(2) : Number().toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangePrice(event, 'salary_per_order')}
                          onKeyDown={(event) => handleChangePriceBackspace(event, 'salary_per_order')}
                        />
                      </div>

                      <div className="field">
                        <span>Mentor Salary Per Order:</span>

                        <input
                          className="appearance"
                          type="number"
                          disabled={!editing}
                          value={newDispatcherData.mentor_salary_per_order ? newDispatcherData.mentor_salary_per_order.toFixed(2) : Number().toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangePrice(event, 'mentor_salary_per_order')}
                          onKeyDown={(event) => handleChangePriceBackspace(event, 'mentor_salary_per_order')}
                        />
                      </div>
                    </div>

                    <div className="__right">
                      <div className="field">
                        <span>Start Date:</span>

                        <Datetime
                          dateFormat={true}
                          timeFormat={false}
                          inputProps={{ disabled: !editing }}
                          value={newDispatcherData.start_from_date as Date}
                          onChange={(value) => setNewDispatcherData({ ...newDispatcherData, start_from_date: moment.isMoment(value) ? value.toDate() : newDispatcherData.start_from_date })}
                        />
                      </div>

                      <div className="field">
                        <span>Auto Calculation:</span>

                        <Switcher
                          disabled={!editing}
                          checked={newDispatcherData.is_auto_calculation}
                          onChange={(value) => setNewDispatcherData({ ...newDispatcherData, is_auto_calculation: value })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }

              <div className="fieldset">
                <div className="legend">Info</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Date Created:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, dispatcherData.dispatcher[0].created_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Created by:</span>
                      <input type="text" defaultValue={dispatcherData.dispatcher[0].created_by} disabled={true} />
                    </div>
                  </div>


                  <div className="__right">
                    <div className="field">
                      <span>Last Edited:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, dispatcherData.dispatcher[0].updated_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Last Edited by:</span>
                      <input type="text" defaultValue={dispatcherData.dispatcher[0].updated_by} disabled={true} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Groups</div>

                <table className={classNames('table', '__hide-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Dispatchers</th>
                      <th>Call Flow</th>
                    </tr>
                  </thead>

                  {dispatcherData.call_groups.map((callGroup, i) => (
                    <tr
                      key={i}
                      style={{ cursor: dispatcherData.permissions.call_group_show && callGroup.call_group_id ? 'pointer' : 'inherit' }}
                      onClick={() =>
                        dispatcherData.permissions.call_group_show &&
                        callGroup.call_group_id &&
                        $router.router.navigate('call_center.groups.item', {
                          companyId: activeAccountId, groupsId: callGroup.call_group_id
                        }, { reload: true })}
                    >
                      <td>{callGroup.name}</td>
                      <td>{callGroup.dispatchers.map((item, index) => (
                        index === 0 ?
                          item.code ? `${item.nickname} (${item.code})` : item.nickname :
                          item.code ? `, ${item.nickname} (${item.code})` : `, ${item.nickname}`
                      ))}</td>
                      <td>{callGroup.call_flows}</td>
                    </tr>
                  ))}
                </table>

                <div className={classNames('mobile-table', '__show-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>

                  <div className="mobile-table-items">

                    {dispatcherData.call_groups.map((callGroup, i: number) => (
                      <div
                        className="item"
                        key={i}
                        onClick={() =>
                          dispatcherData.permissions.call_group_show &&
                          callGroup.call_group_id &&
                          $router.router.navigate('call_center.groups.item', {
                            companyId: activeAccountId, groupsId: callGroup.call_group_id
                          }, { reload: true })}
                      >
                        <div className="__top">
                          <div className="__left">
                            <div><b>{callGroup.name}</b></div>
                            <div>
                              {callGroup.dispatchers.map((item, index) => (
                                index === 0 ?
                                  item.code ? `${item.nickname} (${item.code})` : item.nickname :
                                  item.code ? `, ${item.nickname} (${item.code})` : `, ${item.nickname}`
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="__bottom">
                          <div className="__left">
                          </div>

                          <div className="__right">
                            <div>
                              {callGroup.call_flows}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {editing ? (
                <div className="wrapper flex-container sb editing-buttons">
                  <div>
                    {
                      dispatcherData?.permissions.dispatcher_delete &&
                      <button className="_red" onClick={() => setDeleting(true)}>Delete Dispatcher</button>
                    }
                  </div>
                  <div className="buttons">
                    <button
                      className="_bordered _red"
                      onClick={() => handleCancelEdit()}
                    >
                      Cancel
                    </button>
                    <button
                      className="_bordered _green"
                      disabled={!readyToSave || !!errorFields.length || isSaving}
                      onClick={() => handleSave()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : null}

              {/* Item share popup */}
              {dispatcherData?.permissions.dispatcher_delete && deleting ? (
                <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                  <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                    <div className="title">
                      Delete Dispatcher
                    </div>

                    <div className="checkbox-wrapper">
                      <Checkbox contents="I understand that after deleting the dispatcher it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                    </div>

                    <div className="buttons">

                      <button
                        className="_bordered _green"
                        onClick={() => setDeleting(false)}
                      >
                        Cancel
                      </button>

                      <button
                        className="_bordered _red"
                        disabled={!readyToDelete || isSaving}
                        onClick={() => handleRemoveItem()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </> :
        <DispatcherSchedule
          handleBackDispatchersItem={handleBackDispatchersItem}
          permissions={dispatcherData?.permissions}
          name={newDispatcherData.name}
        />
    }

  </>)
}

export default CallCenter_DispatchersPage_Item
