import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs"

import Icon from "../components/Icon"
import businessAccountIconImg from "../images/business-account_icon.svg"
import logoImg from "../images/logo.svg"

import { httpClientUpdate, nErrorUpdate } from "../funcs"
import { useAppSelector, useAppDispatch } from '../store/hooks'
import { setAccessToken, setAccounts, setActiveAccountId } from '../store/reducer'

import Account from "../models/Account"
import { StoreState } from '../store/reducer'

import "../styles/pages/auth.sass"

function AuthPage() {
  const $router = useRoute()
  const dispatch = useAppDispatch()

  const accessToken = useAppSelector((store) => store.accessToken)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [mode, setMode] = useState<"auth" | "password-reset" | "account-select">($router.route.params.mode)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [authData, setAuthData] = useState<Partial<StoreState>>({
    accessToken: null,
    accounts: [],
    activeAccountId: null,
    user: null
  })
  const [authForm, setAuthForm] = useState({
    $disabled: false,
    email: "",
    password: ""
  })

  // Auth function
  async function auth() {

    // Reset error text
    setErrorText(null)

    // Prevent from sending form again
    setAuthForm({ ...authForm, $disabled: false })

    // Fetch form data
    const { email, password } = authForm

    try {
      // Validate input data
      // const validationErrors = ([
      //   { displayFieldText: "E-Mail", entity: email, rule: "email" },
      //   { displayFieldText: "Password", entity: password, rule: "password" }
      // ])
      // if (validationErrors.length)
      //   throw new Error(validationErrors.join("\n"))

      // Perform autorization
      const { data: { data: { token: accessToken }, success } } = await httpClientUpdate.post('/login', qs.stringify({ email, password }), {
        headers: {
          'Accept': 'application/jsons',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      if (success) {

        httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

        // Get accounts list

        const { data: { data: { accounts }, success, error } } = await httpClientUpdate.get('/accounts', {
          headers: {
            'Accept': 'application/jsons',
            'Authorization': `Bearer ${accessToken}`
          }
        })

        if (success) {
          // Save info
          setAuthData({
            accessToken: accessToken,
            accounts
          })

          // Show select account menu
          $router.router.navigate('auth', {
            mode: "account-select"
          }, { reload: true })
        } else {
          setErrorText(nErrorUpdate(error).content.errorText)
        }
      }
    }
    catch (error) {
      setErrorText(nErrorUpdate(error).content.errorText)
    }
    finally {
      setAuthForm({ ...authForm, $disabled: false })
    }
  }

  // Select account function
  async function selectAccount(id: string) {
    dispatch(
      setAccessToken(authData.accessToken as string | null)
    );
    dispatch(
      setAccounts(authData.accounts as Account[])
    )
    dispatch(
      setActiveAccountId(id)
    )
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    if (activeAccountId === id) {
      $router.router.navigate('dashboard', { companyId: activeAccountId }, { reload: true })
    }
  }

  // Handle mode
  useEffect(() => {
    setMode($router.route.params.mode)
  }, [$router.route.params.mode])

  // Handle account select on page mount
  useEffect(() => {

    if (accessToken) {
      httpClientUpdate.get('/accounts', {
        headers: {
          'Accept': 'application/jsons',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then(({ data: { data: { accounts }, success } }) => {
        success && setAuthData({
          ...authData,
          accessToken: accessToken,
          accounts
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render function
  return (
    <div className="AuthPage">

      { /* Project about */}
      <div className="ProjectHeaders" onClick={() => mode !== 'account-select' && $router.router.navigate('auth', { mode: 'auth' }, { reload: true })}>
        <img src={logoImg} alt="" />

        <div className="headers">
          <h1> {process.env.REACT_APP_TITLE} </h1>
          <h2>Manage your business!</h2>
        </div>
      </div>

      { /* Auth wrapper */}
      <div className="auth-wrapper">

        { /* Auth form */}
        {mode === "auth" ? (
          <form onSubmit={(e) => { e.preventDefault(); auth() }}>
            <h2>Sign In</h2>

            <div className="__form-field">
              <div className="label">E-Mail:</div>
              <input type="text" onChange={({ target: { value } }) => setAuthForm({ ...authForm, email: value })} />
            </div>
            <div className="__form-field">
              <div className="label">Password:</div>
              <input
                type="password"
                onChange={({ target: { value } }) => setAuthForm({ ...authForm, password: value })}
              />
            </div>

            {errorText ? (
              <div className="errorText">{errorText}</div>
            ) : null}

            <button
              type="button"
              className="_zeroed password-reset-link"
              onClick={() => $router.router.navigate('auth', { mode: 'password-reset' }, { reload: true })}
            >
              Forgot password?
            </button>

            <button className="_wa __submit" >
              Sign In
            </button>
          </form>
        ) : null}

        { /* Password reset form */}
        {mode === "password-reset" ? (
          <form onSubmit={(e) => e.preventDefault()}>
            <h2>Reset Password</h2>

            <p>
              Enter your E-Mail and we will send the link:
            </p>

            <div className="__form-field">
              <input type="text" placeholder="Your E-Mail..." />
            </div>

            <button className="_wa __submit">
              Submit
            </button>
          </form>
        ) : null}

        { /* Select account form */}
        {mode === "account-select" ? (
          <form onSubmit={(e) => e.preventDefault()}>

            <div className="account-select">

              {authData.accounts?.map((account, i) => (
                <div
                  className="account"
                  key={i}
                  onClick={() => {
                    selectAccount(account.account_id)
                    // $router.router.navigate('dashboard', { companyId: account.account_id }, { reload: true })
                  }
                  }
                  aria-hidden="true"
                >
                  {
                    !!account.photo_link ?
                      <img src={account.photo_link} alt="Account Pic" /> :
                      <img src={businessAccountIconImg} alt="Account Default" />
                  }
                  <span>{account.name}</span>

                  <div className="stats-signs">
                    <Icon icon="bell-1" />
                    <Icon icon="help-1" />
                  </div>
                </div>
              ))}
            </div>
          </form>
        ) : null}
      </div>
    </div>
  )
}

export default AuthPage
