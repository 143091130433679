// import Icon from "../components/Icon"

import '../styles/pages/page403.sass'

function Page404() {

  return (
    <div className="Page403">
      <p>
        Document not found
      </p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect fill="none" /><circle cx="128" cy="128" r="96" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" /><circle cx="92" cy="108" r="8" /><circle cx="164" cy="108" r="8" /><path d="M169.6,176a48.1,48.1,0,0,0-83.2,0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" /></svg>
    </div>
  )
}

export default Page404
