import { CallTypeProps } from './Models'

import { formatPhoneNumber, getName, getSchuduleTimeWithName } from '../../funcs'

interface SearchJobProps {
  appointmentSearch: string
  setAppointmentSearch: (value: string) => void
  selectedAppointment: string
  handleSearchAppointment: () => void
  appointmentSearchData: CallTypeProps | null
  setSelectedAppointment: (value: string) => void
  handleEditButton: () => void
  loading: boolean
}

export default function SearchJob({
  appointmentSearch,
  setAppointmentSearch,
  selectedAppointment,
  handleSearchAppointment,
  appointmentSearchData,
  setSelectedAppointment,
  handleEditButton,
  loading,
}: SearchJobProps) {

  function handleRadio(id: string) {
    if (id === selectedAppointment) {
      setSelectedAppointment('')
    } else {
      setSelectedAppointment(id)
    }
  }

  function getType(type: string) {
    let typeString = ''
    if (type === 'RC') {
      typeString = 'Recall'
    } else if (type === 'FU') {
      typeString = 'Follow Up'
    } else if (type === 'SC') {
      typeString = 'Service Call'
    } else {
      typeString = type
    }

    return typeString
  }

  return (
    <div className={'search'}>
      <div className='call-variant_search-form'>
        <input
          value={appointmentSearch}
          className='data-border call-variant_search-input'
          type="text"
          onChange={(event) => setAppointmentSearch(event.target.value)}
        />

        <button
          disabled={appointmentSearch === '' || loading}
          className='btn call-variant_search-button'
          style={{ marginRight: '5px' }}
          onClick={() => !loading && handleSearchAppointment()}
        >
          Search
        </button>

        <button
          disabled={!selectedAppointment.length || loading}
          className='btn call-variant_search-button'
          onClick={() => !loading && handleEditButton()}
        >
          Edit
        </button>
      </div>

      {
        appointmentSearchData && appointmentSearchData.jobs.map((job, index) => (
          <div
            key={index}
            className='call-variant_search-type'
          >
            <div className='call-variant_search-type_data'>
              <div className='job-info'>
                <p
                  className='call-variant_search-type_id'
                  style={{ marginBottom: 0 }}
                >
                  Job#{job.job_name} - {getName(job.company_name, job.first_name, job.last_name)} - {job.contact_phones.map(item => `${formatPhoneNumber(item.phone)} (${item.name})`).join(', ')}
                </p>
              </div>

              {
                !!job.appointments.length &&
                <div className='appointment-info'>
                  {
                    job.appointments.map((appoint, idx) => (
                      <div
                        key={idx}
                        className="appointment-info-item"
                      >
                        <div
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                          className='call-variant_search-type_product'
                        >
                          <p style={{ fontSize: '13px' }}>
                            {appoint.appointment_name}: {getType(appoint.appointment_type)} - <span style={{ color: appoint.status === 'Canceled' ? '#FF0000' : appoint.status === 'Closed' ? '#01b41f' : 'inherit' }}> {appoint.status} </span> -
                            {' '}{getSchuduleTimeWithName(appoint.appointment_start, appoint.appointment_end, appoint.service_resource_time_zone)} -
                            {' '}{appoint.service_resource_code ? `${appoint.service_resource_name} (${appoint.service_resource_code})` : appoint.service_resource_name}
                          </p>
                          <div className='call-variant_search-type_radiobtn'>
                            <div className='radio'>
                              <input
                                checked={selectedAppointment === appoint.appointment_id}
                                className='input-radio'
                                type="radio"
                                onClick={() => handleRadio(appoint.appointment_id)}
                                onChange={() => handleRadio(appoint.appointment_id)}
                              />
                              <label className='radiobox' htmlFor="radio-type-1"></label>
                            </div>
                          </div>
                        </div>

                        <div
                          className='call-variant_search-type_product'
                          style={{ marginBottom: '10px', color: 'rgb(142, 142, 147)' }}
                        >
                          <p>
                            {appoint.address}
                          </p>
                        </div>

                        {
                          appoint.units.map((unit, idx) => (
                            <div
                              key={idx}
                              className='call-variant_search-type_product'
                            >
                              <p>
                                {unit.unit_type}: {unit.appliance === 'Other' ? unit.other_appliance : unit.appliance}, {unit.brand === 'Other' ? unit.other_brand : unit.brand}
                              </p>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          </div>
        ))
      }

      {
        appointmentSearchData && !appointmentSearchData.jobs.length &&
        <div>
          <p style={{ textAlign: 'center', marginBottom: '20px' }}>Not Found</p>
        </div>
      }

      <div className='call-variant_search-type'></div>
    </div>
  )
}
