import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

interface SquareComponentProps {
  handlePaySquare: (value: string) => void
  square_payment_application_id: string
  square_payment_location_id: string
  disabled: boolean
  handleCancel: () => void
}

export default function SquareComponent({
  handlePaySquare,
  square_payment_application_id,
  square_payment_location_id,
  disabled,
  handleCancel,
}: SquareComponentProps) {
  return (
    <div style={{ width: '100%' }} >
      <PaymentForm
        applicationId={square_payment_application_id}
        cardTokenizeResponseReceived={(token, verifiedBuyer) => {
          !disabled && handlePaySquare(token.token as string)
        }}
        locationId={square_payment_location_id}
      >
        <CreditCard
          buttonProps={{
            css: {
              opacity: disabled ? '.5' : '1',
              border: '2px solid #219653',
              background: 'transparent',
              width: 'fit-content',
              padding: '5px 30px!important',
              fontSize: '15pt',
              color: '#219653',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          }}
          style={{
            input: {
              fontSize: '16px',
              color: '#2B2B2B'
            },
            '.input-container': {
              borderColor: '#b7b7b7',
              borderWidth: '1px',
              borderRadius: '5px',
            },
            '.input-container.is-focus': {
              borderColor: '#b7b7b7',
              borderWidth: '1px',
            },
            'input::placeholder': {
              color: '#2B2B2B',
            },
            'input.is-focus': {
              color: '#2B2B2B',
            },
            'input.is-error': {
              color: '#FF0000',
            },
            'input.is-error::placeholder': {
              color: '#FF0000',
            },
            '.input-container.is-error': {
              borderColor: '#b7b7b7',
              borderWidth: '1px',
            }
          }}
        />

        <button
          className="_bordered _red"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </PaymentForm>
    </div>
  )
}
