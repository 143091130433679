import { useEffect, useState } from 'react'
import classNames from 'classnames'

import Icon from '../../components/Icon'

import { dateToInfoBlock } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { EmojiProps } from './ChannelNew'

interface EmojisRowProps {
  emojis: EmojiProps[]
  getUserId: (value: string) => string
  permissions: PermissionsProps
  message_id: string
  removeReaction: (message_id: string, message_emoji_id: string) => void
  addSameReaction: (message_id: string, emoji: string) => void
  setShowMainEmojiPikerReaction: (message_id: string) => void
}

interface PermissionsProps {
  can_delete: boolean
  is_read_only: boolean
  time_for_edit: number
  channel_user_id: string
  user_name: string
}

interface CountsProps {
  emoji: string
  info: InfoProps[]
}

interface InfoProps {
  channel_user_id: string
  created_at: string
  message_emoji_id: string
  user_name: string
}

export default function EmojisRow({
  emojis,
  getUserId,
  permissions,
  message_id,
  removeReaction,
  addSameReaction,
  setShowMainEmojiPikerReaction,
}: EmojisRowProps) {
  const user = useAppSelector((store) => store.user)

  const time_zone = user?.time_zone

  const [counts, setCounts] = useState<CountsProps[]>([])

  const [emojiHover, setEmojiHover] = useState('')

  useEffect(() => {
    let emogiCounts: CountsProps[] = []

    for (const emoji of emojis) {
      const emojisName = emogiCounts.map(item => item.emoji)
      if (emojisName.includes(emoji.emoji)) {
        emogiCounts = emogiCounts.map(item => {
          if (item.emoji === emoji.emoji) {
            const updatedInfo: InfoProps[] = item.info.map(itemInfo => itemInfo)
            updatedInfo.push(emoji)
            return { ...item, info: updatedInfo }
          } else {
            return { ...item }
          }
        })
      } else {
        emogiCounts.push({
          emoji: emoji.emoji,
          info: [{ ...emoji }]
        })
      }
    }

    setCounts(emogiCounts)
  }, [emojis])

  function findOwnerReaction(info: InfoProps[]) {
    let message_emoji_id = ''

    info.forEach(item => {
      if (getUserId(item.user_name) === permissions.channel_user_id) {
        message_emoji_id = item.message_emoji_id
      }
    })

    return message_emoji_id
  }

  function getPupop(info: InfoProps[], is_show: boolean) {
    let string = ''
    let top = -25 - (20 * info.length)

    info.forEach(item => {
      string += `<div class='user-name'> ${item.channel_user_id === permissions.channel_user_id ? 'You' : item.user_name} - ${dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, item.created_at)} </div>`
    })

    return `<div class='emoji-popup ${is_show ? '' : 'disabled'}' style="top: ${top}px" >${string}</div>`
  }

  return (
    <div className='bottom-row'>
      {
        counts.map((emoj, i) => (
          <div
            key={i}
            dangerouslySetInnerHTML={{ __html: `${emoj.emoji}; <span>${emoj.info.length}</span> ${getPupop(emoj.info, emojiHover === emoj.emoji)}` }}
            onMouseOver={() => setEmojiHover(emoj.emoji)}
            onMouseLeave={() => setEmojiHover('')}
            onClick={() => {
              findOwnerReaction(emoj.info) ?
                !permissions.is_read_only && removeReaction(message_id, findOwnerReaction(emoj.info)) :
                !permissions.is_read_only && addSameReaction(message_id, emoj.emoji)
            }}
            className={classNames('emoji',
              {
                __owner: !!findOwnerReaction(emoj.info),
              }
            )}
          ></div>
        ))
      }

      {
        !permissions.is_read_only &&
        <button
          onClick={() => setShowMainEmojiPikerReaction(message_id)}
        >
          <Icon viewBox="0 0 1177 1024" icon="emoji-111" />
        </button>
      }
    </div>
  )
}
