import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment from "moment";
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import IncomingCallSvg from '../../components/IncomingCallSvg'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { Payout } from '../../models/Payouts'

import "../../styles/pages/common/entity-edit.sass"
import "../../styles/pages/payouts.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PayoutData
}

interface PayoutData {

  permissions: {
    payout_add: boolean
    payout_delete: boolean
    payout_edit: boolean
    payout_report_show: boolean
    payout_show: boolean
  },
  payout: Payout

  edit: {
    status: {
      id: number,
      name: string
    }[],
    service_resources: {
      code: number
      nickname: string
      service_resource_id: string
    }[]
  }
}
const invoicesData = [
  {
    invoice_id: '1',
    date: '01/11/2023',
    name: 'Mike',
    status: 'Complete',
    total: 215,
    paid: 100,
    unpaid: 50,
    deposited: 65,
    parts_technician: 50,
    parts_company: 0,
  },
  {
    invoice_id: '2',
    date: '01/21/2023',
    name: 'Mike',
    status: 'Complete',
    total: 815,
    paid: 315,
    unpaid: 0,
    deposited: 500,
    parts_technician: 0,
    parts_company: 37,
  },
  {
    invoice_id: '3',
    date: '01/25/2023',
    name: 'Mike',
    status: 'Complete',
    total: 400,
    paid: 200,
    unpaid: 150,
    deposited: 50,
    parts_technician: 0,
    parts_company: 91,
  }
]

interface InvoicesProps {
  invoice_id: string,
  date: string,
  name: string,
  status: string,
  total: number,
  paid: number,
  unpaid: number,
  deposited: number,
  parts_technician: number,
  parts_company: number,
  check?: boolean,
  next?: boolean
}

export default function PayoutPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [invoices, setInvoices] = useState<InvoicesProps[] | []>([])
  const [payoutData, setPayoutData] = useState<PayoutData | null>(null)
  const [newPayoutData, setNewPayoutData] = useState<Partial<Payout>>({
    status: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    payoutData && setNewPayoutData(payoutData.payout[0])
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/payouts/${$router.router.getState().params.payoutsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('payouts', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/payouts/${$router.router.getState().params.payoutsId}`, qs.stringify({
        account_id: activeAccountId,
        service_resource_id: newPayoutData.service_resource_id,
        status_id: newPayoutData.status_id,
        date_start: newPayoutData.date_start,
        date_end: newPayoutData.date_end,
        type: newPayoutData.type
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: payoutsData, success, error } } = await httpClientUpdate.get('/payouts/' +
        $router.router.getState().params.payoutsId,
        {
          params: {
            account_id: activeAccountId
          }
        }) as { data: HttpClientUpdateReport }

      if (success) {
        setPayoutData(payoutsData)
        setNewPayoutData(payoutsData.payout[0])
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newPayoutData, invoices
  ])

  function handleDownloadInvoices() {
    let download = invoicesData.map(item => {
      return {
        ...item,
        check: false,
        next: false
      }
    })
    setInvoices(download)
  }

  function getSum(name: string) {
    let sum = 0
    invoices.length && invoices.forEach(item => sum = sum + item[name])
    return sum
  }

  function getSumCheck(name: string) {
    let sum = 0
    invoices.length && invoices.forEach(item => {
      if (item.check) {
        sum = sum + item[name]
      }
    })
    return sum
  }

  function handleChangeChecbox(id: string, name: string) {
    let updatedInvoices = invoices.map(item => {
      if (item.invoice_id === id) {
        return {
          ...item,
          [name]: !item[name]
        }
      } else {
        return item
      }
    })
    setInvoices(updatedInvoices)
  }

  return (
    <>
      {
        payoutData &&
        payoutData.permissions.payout_show &&
        <div className="PayoutPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Payout</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'payouts',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  payoutData.permissions.payout_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Name:</span>
                  {
                    payoutData?.edit?.service_resources.length ?
                      <Select disabled={!editing} options={payoutData.edit.service_resources.map((option) => ({
                        span: option.code ? `${option.nickname}(${option.code})` : option.nickname,
                        value: option.service_resource_id
                      }))}
                        selectedOption={newPayoutData.service_resource_id as string}
                        onChange={(value) => setNewPayoutData({ ...newPayoutData, service_resource_id: value as string })}
                      /> :
                      <input
                        type="text"
                        value={newPayoutData.service_resource_code ? `${newPayoutData.service_resource}(${newPayoutData.service_resource_code})` : newPayoutData.service_resource}
                        disabled={true}
                      />
                  }
                </div>

                <div className="field">
                  <span>Type:</span>
                  <input
                    type="text"
                    defaultValue={newPayoutData.type}
                    disabled={!editing}
                    onChange={(event) => setNewPayoutData({
                      ...newPayoutData, type: event.target.value
                    })}
                  />
                </div>

                <div className="field">
                  <span>Status:</span>
                  {
                    payoutData?.edit?.status.length ?
                      <Select disabled={!editing} options={payoutData.edit.status.map((option) => ({
                        span: option.name,
                        value: option.id
                      }))}
                        selectedOption={newPayoutData.status_id as string}
                        onChange={(value) => setNewPayoutData({ ...newPayoutData, status_id: value as string })}
                      /> :
                      <input
                        type="text"
                        defaultValue={newPayoutData.status}
                        disabled={true}
                      />
                  }
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>From:</span>
                  {
                    editing ?
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={newPayoutData.date_start}
                        onChange={(value) => setNewPayoutData({ ...newPayoutData, date_start: moment.isMoment(value) ? value.toDate() : new Date() })}
                      /> :
                      <input
                        type="text"
                        value={moment(newPayoutData.date_start).format('MM/DD/YYYY ddd').toString()}
                        disabled={true}
                      />
                  }
                </div>

                <div className="field">
                  <span>To:</span>
                  {
                    editing ?
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={newPayoutData.date_end}
                        onChange={(value) => setNewPayoutData({ ...newPayoutData, date_end: moment.isMoment(value) ? value.toDate() : new Date() })}
                      /> :
                      <input
                        type="text"
                        value={moment(newPayoutData.date_end).format('MM/DD/YYYY ddd').toString()}
                        disabled={true}
                      />
                  }
                </div>
              </div>
            </div>
          </div>

          {/* Invoices */}
          <div className="fieldset">
            <div style={{ display: 'flex', alignItems: 'center' }} className="legend">
              Invoices
              {
                editing &&
                <span
                  onClick={() => handleDownloadInvoices()}
                >
                  <Icon
                    viewBox="0 0 16 16"
                    style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                    icon="download-1"
                  />
                </span>
              }
            </div>

            <table className={classNames('table', '__show-on-wide', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Paid</th>
                  <th>Unpaid</th>
                  <th>Deposited</th>
                  <th>Parts (Technician)</th>
                  <th>Parts (Company)</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              {invoices && invoices.map((invoice, i) => (
                <tr
                  key={i}
                // onClick={() => $router.router.navigate('deposits.item', {
                //   companyId: activeAccountId, depositId: deposit.deposit_id
                // }, { reload: true })}
                >
                  <td>{invoice.date}</td>
                  <td>{invoice.name}</td>
                  <td>{invoice.status}</td>
                  <td>${invoice.total}</td>
                  <td>${invoice.paid}</td>
                  <td>${invoice.unpaid}</td>
                  <td>${invoice.deposited}</td>
                  <td>${invoice.parts_technician}</td>
                  <td>${invoice.parts_company}</td>
                  <td
                    onClick={() => handleChangeChecbox(invoice.invoice_id, 'check')}
                  >
                    <Icon
                      style={{ width: '20px', height: '20px', fill: invoice.check ? '#01B41F' : '#C3C6CB' }}
                      icon="check-mark-1"
                    />
                  </td>
                  <td
                    onClick={() => handleChangeChecbox(invoice.invoice_id, 'next')}
                  >
                    <IncomingCallSvg width='20px' height='20px' color={invoice.next ? '#FFB700' : '#C3C6CB'} />
                  </td>
                </tr>
              ))}

              {
                !!invoices.length &&
                <tr>
                  <td>In total</td>
                  <td></td>
                  <td></td>

                  <td> ${getSum('total')} </td>
                  <td> ${getSum('paid')} </td>
                  <td> ${getSum('unpaid')} </td>
                  <td> ${getSum('deposited')} </td>
                  <td> ${getSum('parts_technician')} </td>
                  <td> ${getSum('parts_company')} </td>
                  <td></td>
                  <td></td>
                </tr>
              }
            </table>

            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
              <tr></tr>
              {invoices.map((invoice, i) => (
                <tr
                  key={i}
                // onClick={() => $router.router.navigate('deposits.item', {
                //   companyId: activeAccountId, depositId: deposit.deposit_id
                // }, { reload: true })}
                >
                  <td>
                    <div>{invoice.date}</div>
                    <div>{invoice.name}</div>
                  </td>
                  <td>
                    <div>{invoice.status}</div>
                    <div>Total: ${invoice.total}</div>
                  </td>
                  <td>
                    <div>Paid: ${invoice.paid}</div>
                    <div>Unpaid: ${invoice.unpaid}</div>
                  </td>
                  <td>
                    <div>Deposited: ${invoice.deposited}</div>
                  </td>
                  <td>
                    <div>Parts (Technician): ${invoice.parts_technician}</div>
                    <div>Parts (Company): ${invoice.parts_company}</div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleChangeChecbox(invoice.invoice_id, 'check')}
                    >
                      <Icon
                        style={{ width: '20px', height: '20px', fill: invoice.check ? '#01B41F' : '#C3C6CB' }}
                        icon="check-mark-1"
                      />
                    </div>
                    <div
                      onClick={() => handleChangeChecbox(invoice.invoice_id, 'next')}
                    >
                      <IncomingCallSvg width='20px' height='20px' color={invoice.next ? '#FFB700' : '#C3C6CB'} />
                    </div>
                  </td>
                </tr>
              ))}

              {
                !!invoices.length &&
                <tr>
                  <td> ${getSum('total')} </td>
                  <td> ${getSum('paid')} </td>
                  <td> ${getSum('unpaid')} </td>
                  <td> ${getSum('deposited')} </td>
                  <td> ${getSum('parts_technician')} </td>
                  <td> ${getSum('parts_company')} </td>
                </tr>
              }
            </table>

            {
              !!invoices.length &&
              <div
                className={classNames('__hide-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  padding: '10px',
                  fontWeight: 'bold'
                }}
              >
                <div>Orders:  {invoices.filter(item => item.check).length} </div>
                <div>Avg. Labor : ${Math.floor(((getSumCheck('total') - (getSumCheck('parts_technician') + getSumCheck('parts_company'))) / invoices.filter(item => item.check).length)) || 0}</div>
                <div>Labor: ${getSumCheck('total') - (getSumCheck('parts_technician') + getSumCheck('parts_company'))}</div>
                <div>Not Deposited: ${getSumCheck('paid') - getSumCheck('deposited')} </div>
                <div>Payout: ${getSumCheck('paid')}</div>
              </div>
            }

            <div className={classNames('mobile-table', '__show-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>

              <div className="mobile-table-items">

                {invoices.map((invoice, i: number) => (
                  <div
                    className="item"
                    key={i}
                  //  onClick={() => $router.router.navigate('deposits.item', {
                  //   companyId: activeAccountId, depositId: deposit.deposit_id
                  // }, { reload: true })}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div><strong>{invoice.name}</strong> ({invoice.date}) </div>
                        <div>
                          {invoice.status}
                        </div>
                        <div>
                          <strong>${invoice.total}</strong>
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          Paid: <b>${invoice.paid}</b>
                        </div>
                        <div>
                          Unpaid: <b>${invoice.unpaid}</b>
                        </div>
                        <div>
                          Deposited: <b>${invoice.deposited}</b>
                        </div>
                        <div>
                          Parts (Technician): <b>${invoice.parts_technician}</b>
                        </div>
                        <div>
                          Parts (Company): <b>${invoice.parts_company}</b>
                        </div>
                      </div>
                    </div>

                    <div className="__bottom">

                      <div
                        className="__left"
                        onClick={() => handleChangeChecbox(invoice.invoice_id, 'check')}
                      >
                        <Icon
                          style={{ width: '20px', height: '20px', fill: invoice.check ? '#01B41F' : '#C3C6CB' }}
                          icon="check-mark-1"
                        />
                      </div>

                      <div
                        className="__right small"
                        onClick={() => handleChangeChecbox(invoice.invoice_id, 'next')}
                      >
                        <IncomingCallSvg width='20px' height='20px' color={invoice.next ? '#FFB700' : '#C3C6CB'} />
                      </div>
                    </div>
                  </div>
                ))}
                {
                  !!invoices.length &&
                  <div
                    className="item"
                  //  onClick={() => $router.router.navigate('deposits.item', {
                  //   companyId: activeAccountId, depositId: deposit.deposit_id
                  // }, { reload: true })}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>Total: ${getSum('total')} </div>
                        <div>
                          Paid: ${getSum('paid')}
                        </div>
                        <div>
                          Unpaid: ${getSum('unpaid')}
                        </div>
                        <div>
                          Deposited: ${getSum('deposited')}
                        </div>
                        <div>
                          Parts (Technician): <b>${getSum('parts_technician')}</b>
                        </div>
                        <div>
                          Parts (Company): <b>${getSum('parts_company')}</b>
                        </div>
                      </div>

                      <div className="__right">
                        <div>Orders:  {invoices.filter(item => item.check).length} </div>
                        <div>Avg. Labor : ${Math.floor(((getSumCheck('total') - (getSumCheck('parts_technician') + getSumCheck('parts_company'))) / invoices.filter(item => item.check).length)) || 0}</div>
                        <div>Labor: ${getSumCheck('total') - (getSumCheck('parts_technician') + getSumCheck('parts_company'))}</div>
                        <div>Not Deposited: ${getSumCheck('paid') - getSumCheck('deposited')} </div>
                        <div>Payout: ${getSumCheck('paid')}</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payoutData.payout.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input type="text" defaultValue={payoutData.payout.created_by} disabled={true} />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payoutData.payout.updated_at)}
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <span>Last Edited by:</span>
                  <input type="text" defaultValue={payoutData.payout.updated_by} disabled={true} />
                </div>
              </div>
            </div>
          </div>

          {editing &&
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  payoutData.permissions.payout_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Payout</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>}

          {/* Item share popup */}
          {
            payoutData.permissions.payout_delete &&
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Payout
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the payout it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    disabled={!readyToDelete || isSaving}
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}
