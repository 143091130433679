import { memo, useState } from 'react'
import { DateTime } from 'luxon'
import classNames from 'classnames'

import Icon from '../../components/Icon'
import DateRangeCalendar from '../Schedule/DateRangeCalendar'
// import Select from '../../components/Select'

import { SettingsProps } from './Models'

// const durationOptions = ['30 min', '1:00 hour', '1:30 hour', '2:00 hours', '2:30 hours', '3:00 hours', '3:30 hours', '4:00 hours', '4:30 hours', '5:00 hours', '5:30 hours', '6:00 hours', '6:30 hours', '7:00 hours', '7:30 hours', '8:00 hours']
// const timeOptions = ['12:00AM', '12:30AM', '01:00AM', '01:30AM', '02:00AM', '02:30AM', '03:00AM', '03:30AM', '04:00AM', '04:30AM', '05:00AM', '05:30AM', '06:00AM', '06:30AM', '07:00AM', '07:30AM', '08:00AM', '08:30AM', '09:00AM', '09:30AM', '10:00AM', '10:30AM', '11:00AM', '11:30AM', '12:00PM', '12:30PM', '01:00PM', '01:30PM', '02:00PM', '02:30PM', '03:00PM', '03:30PM', '04:00PM', '04:30PM', '05:00PM', '05:30PM', '06:00PM', '06:30PM', '07:00PM', '07:30PM', '08:00PM', '08:30PM', '09:00PM', '09:30PM', '10:00PM', '10:30AM', '11:00PM', '11:30PM', '12:00AM']

interface AppointmentSettingsProps {
  settings: SettingsProps
  setSettings: (value: SettingsProps) => void
  handleManualSchedule: (value: boolean) => void
  permissions_schedule_manual: boolean
}

function AppointmentSettings({ settings, setSettings, handleManualSchedule, permissions_schedule_manual }: AppointmentSettingsProps) {
  const [dateRangeCalendarShown, setDateRangeCalendarShown] = useState(false)

  return (
    <div className='call-bookingSettings'>
      <div className='block-content'>
        <div className='block-content-title'>
          <div className='block-title'>
            Booking Settings
          </div>
        </div>

        <div className='call-bookingSettings_row call-bookingSettings_showDays'>
          <label className='call-bookingSettings_row-subtitle' htmlFor=""> Show (days): </label>

          <input
            value={settings.showDays}
            onChange={(event) => setSettings({ ...settings, showDays: +event.target.value })}
            className='data-border call-bookingSettings_input'
            style={{ width: '50px' }}
            type="number"
            min={1}
            max={10}
          />
        </div>

        <div className='call-bookingSettings_row call-bookingSettings_showServRes'>
          <p className='call-bookingSettings_row-subtitle'>
            Show Service Resource:
          </p>

          <div className='check'>
            <input
              checked={settings.show_service}
              className='input-checkbox'
              type="checkbox"
              onChange={(event) => setSettings({ ...settings, show_service: event.target.checked })}
            />

            <label className='checkbox' htmlFor="">
              {
                settings.show_service &&
                <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
              }
            </label>
          </div>
        </div>

        <div className='call-bookingSettings_row call-bookingSettings_checkAvail'>
          <p className='call-bookingSettings_row-subtitle'>
            Check Availability:
          </p>

          <div className='check'>
            <input
              className='input-checkbox'
              type="checkbox"
              style={{ cursor: permissions_schedule_manual ? 'pointer' : 'inherit' }}
              disabled={!permissions_schedule_manual}
              checked={settings.availability}
              onChange={(event) => handleManualSchedule(event.target.checked)}
            />

            <label className='checkbox' htmlFor="">
              {
                settings.availability &&
                <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
              }
            </label>
          </div>
        </div>

        {/* <div className='call-bookingSettings_row call-bookingSettings_checkArea'>
          <p className='call-bookingSettings_row-subtitle'>
            Check Area:
          </p>

          <div className='check'>
            <input
              checked={settings.area}
              className='input-checkbox'
              type="checkbox"
              onChange={(event) => setSettings({ ...settings, area: event.target.checked })}
            />

            <label className='checkbox' htmlFor="">
              {
                settings.area &&
                <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
              }
            </label>
          </div>
        </div> */}

        {/* <div className='call-bookingSettings_row call-bookingSettings_duration'>
          <p className='call-bookingSettings_row-subtitle'>
            Duration:
          </p>

          <Select options={durationOptions.map((time) => ({
            span: time,
            value: time
          }))}
            selectedOption={settings.duration}
            onChange={(value: string) => setSettings({ ...settings, duration: value })}
          />
        </div> */}

        <div className='call-bookingSettings_row call-bookingSettings_onDate'>
          <p className='call-bookingSettings_row-subtitle'>
            On Date:
          </p>
          <div
            onFocus={() => setDateRangeCalendarShown(true)}
            onBlur={() => setDateRangeCalendarShown(false)}
            tabIndex={-1}
            className="form-field date-range"
          >
            <div
              className="form-field _iconed"
            >
              <input
                type="text"
                className='data-border call-bookingSettings_input'
                readOnly
                value={DateTime.fromJSDate(settings.on_date).toFormat('yyyy-MM-dd')}
              />
              <button style={{ width: '15px' }} className="_zeroed _iconed __noclick">
                <Icon style={{ width: '14px' }} icon="calendar-4" />
              </button>
            </div>

            <div style={{ width: '350px' }} className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
              <DateRangeCalendar
                startDate={settings.on_date}

                onStartDateUpdate={(date) => {
                  (setSettings as any)({ ...settings, on_date: date });
                  setDateRangeCalendarShown(false)
                }}
              />
            </div>
          </div>
        </div>

        {/* <div className='call-bookingSettings_row call-bookingSettings_ontime'>
          <p className='call-bookingSettings_row-subtitle'>
            On Time:
          </p>

          <div className='call-bookingSettings_listBlock call-bookingSettings_ontime-listBlock'>
            <Select options={timeOptions.map((time) => ({
              span: time,
              value: time
            }))}
              selectedOption={settings.on_time_start}
              onChange={(value: string) => setSettings({ ...settings, on_time_start: value })}
            />
            <p>-</p>
            <Select options={timeOptions.map((time) => ({
              span: time,
              value: time
            }))}
              selectedOption={settings.on_time_end}
              onChange={(value: string) => setSettings({ ...settings, on_time_end: value })}
            />
          </div>
        </div> */}

        {/* <button className='btn btn-apply'>
          Apply
        </button> */}
      </div>
    </div>
  )
}

export default memo(AppointmentSettings)
