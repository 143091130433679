import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

interface EmojiPickerProps {
  setShow: (value: string) => void
  hanldeAddReaction: (value: string) => void
  is_text?: boolean
}

interface EmojiObjectProps {
  id: string
  keywords: string[]
  name: string
  native: string
  shortcodes: string
  unified: string
}

export default function PickerComponent({ setShow, hanldeAddReaction, is_text }: EmojiPickerProps) {
  let firstLoad = true

  function handleSelect(emojiObject: EmojiObjectProps) {
    if (is_text) {
      hanldeAddReaction(emojiObject.native)
    } else {
      hanldeAddReaction(emojiObject.unified)
    }
  }

  function handleClickOutside(event: any) {
    event.preventDefault()
    event.stopPropagation()

    if (!firstLoad) {
      setShow('')
    }

    if (firstLoad) {
      firstLoad = false
    }
  }

  return (
    <div
      className="picker"
    >
      <Picker
        theme="light"
        data={data}
        onEmojiSelect={handleSelect}
        onClickOutside={handleClickOutside}
      />
    </div>
  )
}