interface ModalDifferentZipProps {
  setIsDifferentZip: (value: boolean) => void
}

export default function ModalDifferentZip({ setIsDifferentZip }: ModalDifferentZipProps) {
  return (
    <div className='modal-save'>
      <div style={{ maxWidth: '400px' }} className='modal-save-content'>
        <div style={{ textAlign: 'center' }} className='header-error'>
          Warning!
        </div>

        <div>Zip code for checking available time does not match the address zip code.</div>

        <div className='btns'>
          <button
            className='btn btn-clear'
            onClick={() => setIsDifferentZip(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
