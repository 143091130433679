import { BaseLink, useRoute } from "react-router5"

import "../styles/components/help-menu.sass"

interface Props {
  companyId?: string | null
  setActiveNavButton: (value: string | null) => void
}

function HelpMenu({ companyId, setActiveNavButton }: Props) {
  const $router = useRoute()

  // Render function
  return (
    <div className="HelpMenu">

      <div className="title">
        Support
      </div>

      <nav>
        <BaseLink
          router={$router.router}
          routeParams={{ companyId }}
          routeName="support.faq"
        >
          F.A.Q.
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeParams={{ companyId }}
          routeName="tickets"
          onClick={() => setActiveNavButton(null)}
        >
          Tickets
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeParams={{ companyId }}
          routeName="support.contacts"
        >
          Contacts
        </BaseLink>
      </nav>
    </div>
  )
}

export default HelpMenu
