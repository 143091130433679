import { memo } from 'react'

interface CallNoteProps {
  note: string
  setNote: (value: string) => void
}

function CallNote({ note, setNote }: CallNoteProps) {

  return (
    <div className='call-note'>
      <div className='block-content'>
        <div className='block-content-title'>
          <div className='block-title'>
            Note
          </div>
        </div>

        <textarea
          value={note}
          onChange={(event) => setNote(event.target.value)}
          className='data-border call-note_textarea'
        ></textarea>
      </div>
    </div>
  )
}

export default memo(CallNote)
