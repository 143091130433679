import { useRef, useEffect, LegacyRef, useState, MutableRefObject } from "react";
import axios, { AxiosError } from "axios";
import { DateTime } from "luxon";
import moment from "moment";

interface ScheduleProps {
  day: string;
  from: string;
  schedule_id: string;
  to: string;
  limit?: number;
  editing?: boolean;
}

export function nError(error: Error | AxiosError | unknown): {
  error: Error | AxiosError | unknown;
  content: {
    code?: number;
    errorText: string;
  };
} {
  // HTTP error (axios)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
  if ((<any>error).isAxiosError) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const _error = <AxiosError>error;
    // eslint-disable-next-line no-console
    console.warn(_error.request, _error.response);

    if (!_error.response)
      return {
        content: {
          errorText: "Can`t connect to server",
        },
        error,
      };

    const errorText = _error.response.data.message;

    return {
      content: {
        errorText,
      },
      error,
    };
  }

  // Another error
  else {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  // Return error content
  return {
    content: {
      errorText: String(error),
    },
    error,
  };
}

export interface nErrorUpdateProps {
  error: Error | AxiosError | unknown;
  content: {
    code?: number;
    errorText: string;
  };
}

export function nErrorUpdate(error: Error | AxiosError | unknown): {
  error: Error | AxiosError | unknown;
  content: {
    code?: number;
    errorText: string;
    errorFields?: string[] | [];
  };
} {
  // HTTP error (axios)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
  if ((<any>error).isAxiosError) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const _error = <AxiosError>error;
    // eslint-disable-next-line no-console
    console.warn(_error.request, _error.response);

    if (!_error.response)
      return {
        content: {
          errorText: "Can`t connect to server",
        },
        error,
      };

    const errorText = _error.response.data.error?.message || "unknown error";
    const errorCode = _error.response.data.error?.code || "500";
    const errorFields = _error.response.data.errors
      ? Object.keys(_error.response.data.errors)
      : [];

    return {
      content: {
        errorText,
        code: errorCode,
        errorFields: errorFields,
      },
      error,
    };
  }

  // Another error
  else {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  // Return error content
  return {
    content: {
      errorText: String(error),
    },
    error,
  };
}

// export const httpClient = axios.create({
//   baseURL: "https://crm56new.artemiudintsev.com/api",
// });
//node - 'https://crm57api.artemiudintsev.com'  laravel - 'http://crm56new.artemiudintsev.com/api'

export const httpClientUpdate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export function leadingZero(int: number) {
  return (int <= 9 ? "0" : "") + int;
}

export function formatTime(seconds: number) {
  return [0, Math.floor(seconds / 60), Math.floor(seconds % 60)]
    .map((x) => x.toString())
    .map((x) => (x.length === 1 ? `0${x}` : x))
    .join(":");
}

export function calculatePercent(min: number, max: number) {
  return (min / max) * 100;
}

export function calculateFromPercent(value: number, min: number, max: number) {
  return (max / 100) * value;
}

export function useOuterClick(callback: any) {
  const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLDivElement>(); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef as LegacyRef<HTMLDivElement> | undefined; // convenience for client (doesn't need to init ref himself)
}

export const useOutsideClick = (callback: any) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event: any) => {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ref;
};

export function useOutsideClickUpdate(callback: any) {
  const ref = useRef();

  useEffect(() => {
    function handleClick(event: any) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ref as LegacyRef<HTMLDivElement> | undefined | MutableRefObject<undefined>;
}

export function getDateTimeForInterface(day: Date) {
  let firstDayOfYear = "1900-01-01";
  let firstDayOfYearISO = DateTime.fromISO(firstDayOfYear as unknown as string)
    .startOf("day")
    .toJSDate();
  let checkDay: string | Date = new Date(day);
  if (checkDay.getDate()) {
    firstDayOfYearISO = DateTime.fromISO(day as unknown as string)
      .startOf("day")
      .toJSDate();
  }
  return firstDayOfYearISO;
}

export function getStartDateUTC(day: Date) {
  return DateTime.fromJSDate(day).setZone("utc").startOf("day").toJSDate();
}

export function getEndDateUTC(day: Date) {
  return DateTime.fromJSDate(day).setZone("utc").endOf("day").toJSDate();
}

export function sortServiceResourceSchedule(schedule: ScheduleProps[]) {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  let sortScheduleByTyme = schedule.sort((a, b) =>
    a.from.localeCompare(b.from)
  );

  return sortScheduleByTyme.sort(
    (a, b) => days.indexOf(capitalize(a.day)) - days.indexOf(capitalize(b.day))
  );
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^([1-9]|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return phoneNumberString
      ? phoneNumberString.replace("+", "")
      : phoneNumberString;
  }
}

export function formatPhoneNumberForItemTech(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^([1-9]|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return phoneNumberString
      ? phoneNumberString.replace("+", "")
      : phoneNumberString;
  }
}

export function formatCIDLocalService(cid: string) {
  const firstPart = cid.slice(0, 3);
  const secondPart = cid.slice(3, 6);
  const thirdPart = cid.slice(6);

  return `${firstPart}-${secondPart}-${thirdPart}`;
}

export function formatPhoneNumberToServerString(phoneNumberString: string) {
  return phoneNumberString ? `+${phoneNumberString.replace(/[^\d]/g, "")}` : "";
}

export function isValidURL(string: string) {
  let res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g
  );
  return res !== null;
}

export function validateEmail(email: string) {
  let res = String(email)
    .toLowerCase()
    .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  return res !== null;
}

export function dateToInfoBlock(
  toFormat: string,
  timeZone?: string,
  day?: string
) {
  if (!day || !timeZone) {
    return "";
  }

  let updateDay = DateTime.fromISO(day).setZone(timeZone).toFormat(toFormat);

  return updateDay.replace("AM", "am").replace("PM", "pm");
}

export function dateToReqServer(
  fFormat: string,
  timeZone?: string,
  day?: string
) {
  if (!day || !timeZone) {
    return "";
  }

  let updateDay = DateTime.fromFormat(day, fFormat)
    .toUTC()
    .setZone(timeZone)
    .toISO();

  return updateDay;
}

export function updatedDateToReqServer(
  fFormat: string,
  timeZone?: string,
  day?: string
) {
  if (!day || !timeZone) {
    return "";
  }

  let updateDay = DateTime.fromFormat(day, fFormat, { zone: timeZone }).toUTC();

  return updateDay;
}

export function startOfDateToReqServer(
  fFormat: string,
  timeZone?: string,
  day?: string
) {
  if (!day || !timeZone) {
    return "";
  }

  let updateDay = DateTime.fromFormat(day, fFormat)
    .setZone(timeZone)
    .startOf("day")
    .toUTC()
    .toISO();

  return updateDay;
}

export function getSchuduleTime(
  start: string,
  end: string,
  timeZoneService: string
) {
  let scheduleTime = "";
  let timeStart = dateToInfoBlock("hh:mma", timeZoneService, start);
  let timeEnd = dateToInfoBlock("hh:mma", timeZoneService, end);

  let isSomeDay =
    DateTime.fromISO(start)
      .setZone(timeZoneService)
      .startOf("day")
      .toFormat("MM/dd/yyyy") ===
    DateTime.fromISO(end)
      .setZone(timeZoneService)
      .startOf("day")
      .toFormat("MM/dd/yyyy");

  if (isSomeDay) {
    let day = dateToInfoBlock("MM/dd/yyyy", timeZoneService, start);
    scheduleTime = `${day} (${timeStart} - ${timeEnd})`;
  } else {
    let dayStart = dateToInfoBlock("MM/dd/yyyy hh:mma", timeZoneService, start);
    let dayEnd = dateToInfoBlock("MM/dd/yyyy hh:mma", timeZoneService, end);
    scheduleTime = `${dayStart} - ${dayEnd}`;
  }

  return scheduleTime;
}

export function getSchuduleTimeWithName(
  start: string,
  end: string,
  timeZoneService: string
) {
  let scheduleTime = "";
  let timeStart = dateToInfoBlock("hh:mma", timeZoneService, start);
  let timeEnd = dateToInfoBlock("hh:mma", timeZoneService, end);
  let isSomeDay =
    DateTime.fromISO(start)
      .setZone(timeZoneService)
      .startOf("day")
      .toFormat("MM/dd/yyyy") ===
    DateTime.fromISO(end)
      .setZone(timeZoneService)
      .startOf("day")
      .toFormat("MM/dd/yyyy");

  if (isSomeDay) {
    let day = dateToInfoBlock("dd MMM yyyy", timeZoneService, start);

    scheduleTime = `${day} (${timeStart}-${timeEnd})`;
  } else {
    let dayStart = dateToInfoBlock(
      "dd MMM yyyy hh:mma",
      timeZoneService,
      start
    );
    let dayEnd = dateToInfoBlock("dd MMM yyyy hh:mma", timeZoneService, end);
    scheduleTime = `${dayStart} - ${dayEnd}`;
  }
  return scheduleTime;
}

export function typeAppointmentForView(type: string) {
  let typeString = "";
  if (type === "SC") {
    typeString = "Service call";
  } else if (type === "FU") {
    typeString = "Follow up";
  } else if (type === "RC") {
    typeString = "Recall";
  } else {
    typeString = type;
  }
  return typeString;
}

export function typeAppointmentForServer(type: string) {
  let typeString = "";
  if (type === "Service call") {
    typeString = "SC";
  } else if (type === "Follow up") {
    typeString = "FU";
  } else if (type === "Recall") {
    typeString = "RC";
  } else {
    typeString = type;
  }
  return typeString;
}

export function getName(company: string, firstName: string, lastName: string) {
  let stringName = "";
  if (!firstName && !lastName && company) {
    stringName = company;
  } else if (!company) {
    stringName = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`;
  } else if (firstName && lastName && company) {
    stringName = `${firstName} ${lastName} (${company})`;
  } else if (!firstName && !lastName && !company) {
    stringName = "Unknown";
  } else {
    stringName = `${firstName ? firstName : ""} ${
      lastName ? lastName : ""
    } (${company})`;
  }

  return stringName;
}

export function capitalizeFirstLetter(string: string) {
  let stringLower = string.toLocaleLowerCase().replaceAll("_", " ");
  return stringLower.charAt(0).toUpperCase() + stringLower.slice(1);
}

export function screenSizeWidth() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return screenSize.width;
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function getBase64FromRington(file: File) {
  return new Promise((resolve) => {
    let baseURL: any = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

export function getTimeHasPassed(time_from: string) {
  let time_before = moment(time_from).unix();
  let time_now = moment().unix();

  let secs = Math.round(time_now - time_before);

  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}
