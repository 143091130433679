import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import AccountCustom from "../../models/AccountCustom"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'source' | 'status'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AccountsReport
}

export interface AccountsReport {

  interface: {

    filter_words: {
      source: string[],
      status: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    account_add: boolean
    account_delete: boolean
    account_edit: boolean
    account_report_show: boolean
    account_show: boolean
    twilio_info_edit: boolean
    twilio_info_show: boolean
  },

  accounts: AccountCustom[],

  edit: {
    status: {
      id: number
      name: string
    }[],
    owners: {
      user_id: string,
      user: string
    }[],
    source: {
      list_sources_id: string
      name: string
    }[],
    time_zone: {
      id: number
      name: string
    }[],
    permissions: {
      accounts_permissions_id: string
      name: string
    }[],
    state: {
      id: number
      name: string
    }[],
    accounts_menus: {
      accounts_menus_id: string
      name: string
    }[]
  }
}

function AccountsPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<AccountsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.accounts?.filter_field ? {
      source: [] as string[],
      status: [] as string[],
      ...cookiesSetting.accounts.filter_field,
    } : {
      source: [] as string[],
      status: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.accounts?.sort_field ? cookiesSetting.accounts.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.accounts?.sort_type ? cookiesSetting.accounts.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Email',
      value: 'email'
    }, {
      span: 'Owner',
      value: 'owner'
    }, {
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Users',
      value: 'users'
    }, {
      span: 'Phone Numbers',
      value: 'phone_numbers'
    }, {
      span: 'Source',
      value: 'source'
    }, {
      span: 'Status',
      value: 'status'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.accounts?.filter_field ? {
        source: [] as string[],
        status: [] as string[],
        ...cookiesSetting.accounts.filter_field,
      } : {
        source: [] as string[],
        status: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.accounts?.sort_field ? cookiesSetting.accounts.sort_field : 'name',
        direction: cookiesSetting && cookiesSetting?.accounts?.sort_type ? cookiesSetting.accounts.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load accounts function
  async function loadAccounts() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, accounts: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.accounts?.limit_rows ? cookiesSetting?.accounts.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: accounts, success, error } } = (await httpClientUpdate.post('/accounts/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.accounts?.limit_rows ? cookiesSetting?.accounts?.limit_rows : reportsMaxRows,
        ...filterParams,
        filters: activeFilters,
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (accounts?.permissions.account_report_show) {
          setReportData(accounts)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, accounts: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load accounts on page mount
  useEffect(() => {
    loadAccounts()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="AccountsPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Accounts</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => {
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.accounts?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, accounts: { ...cookiesSetting.accounts, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.account_add ?
              (
                <div className="add-button-wrapper">
                  <button className="_iconed _rounded add-button" onClick={() => $router.router.navigate('accounts.new', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                  })}>
                    <Icon icon="plus-thin" />
                    <span>Add Account</span>
                    <Icon icon="user-1" />
                  </button>
                </div>
              ) :
              <></>
          }
        />

        <div className="contents">
          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Email</span>)}

                  sortDirection={localInterface.sort.field === 'email' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'email', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Owner</span>)}

                  sortDirection={localInterface.sort.field === 'owner' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'owner', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Users</span>)}

                  sortDirection={localInterface.sort.field === 'users' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'users', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Phone Numbers</span>)}

                  sortDirection={localInterface.sort.field === 'phone_numbers' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone_numbers', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Source</span>)}

                  allFiltersSelected={isAllFiltersSelected("source")}
                  onAllFiltersChange={(value) => switchFilter("source", "All", value)}

                  filterWords={reportData.interface.filter_words.source.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("source", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("source", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Status</span>)}

                  allFiltersSelected={isAllFiltersSelected("status")}
                  onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                  filterWords={reportData.interface.filter_words.status.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("status", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("status", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.accounts.map((account, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.account_show && account.account_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.account_show &&
                  account.account_id &&
                  $router.router.navigate('accounts.item', {
                    companyId: activeAccountId,
                    accountId: account.account_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.account_show &&
                  account.account_id &&
                  handleChangeMenu(event, account.account_id, 'accounts', i)}
              >
                <td>{account.name}</td>
                <td>{account.email}</td>
                <td>{account.owner}</td>
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, account.created_at)}</td>
                <td>{account.users}</td>
                <td>{account.phone_numbers}</td>
                <td>{account.source}</td>
                <td className={classNames({
                  'red-text': account.status === 'Inactive',
                  'green-text': account.status === 'Active',
                  'blue-text': !['Inactive', 'Active'].includes(account.status as string),
                })}>
                  {account.status}
                  {
                    reportData.permissions.account_show &&
                    showContext === `accounts_${account.account_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('accounts.item', {
                            companyId: activeAccountId,
                            accountId: account.account_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/accounts/${account.account_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.accounts.map((account, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.account_show && account.account_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.account_show &&
                  account.account_id &&
                  $router.router.navigate('accounts.item', {
                    companyId: activeAccountId,
                    accountId: account.account_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.account_show &&
                  account.account_id &&
                  handleChangeMenu(event, account.account_id, 'accounts', i)}
              >
                <td>
                  <div>{account.name}</div>
                  <div>{account.email}</div>
                </td>
                <td>
                  <div>{account.owner}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, account.created_at)}</div>
                </td>
                <td>
                  <div>Users: {account.users}</div>
                  <div>Phone Numbers: {account.phone_numbers}</div>
                </td>
                <td>
                  <div>{account.source}</div>
                  <div className={classNames({
                    'red-text': account.status === 'Inactive',
                    'green-text': account.status === 'Active',
                    'blue-text': !['Inactive', 'Active'].includes(account.status as string),
                  })}>
                    {account.status}
                  </div>
                  {
                    reportData.permissions.account_show &&
                    showContext === `accounts_${account.account_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('accounts.item', {
                            companyId: activeAccountId,
                            accountId: account.account_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/accounts/${account.account_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.accounts.map((account, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.account_show &&
                  account.account_id &&
                  $router.router.navigate('accounts.item', {
                    companyId: activeAccountId,
                    accountId: account.account_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><b>{account.name}</b></div>
                    <div>
                      {account.email}
                    </div>
                  </div>

                  <div className="__right">
                    <div className={classNames({
                      'red-text': account.status === 'Inactive',
                      'green-text': account.status === 'Active',
                      'blue-text': !['Inactive', 'Active'].includes(account.status as string),
                    })}>
                      {account.status}
                    </div>
                    <div>
                      {account.owner}
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Users:</span>
                      <span> {account.users}</span>
                    </div>
                    <div>
                      <span className="gray">Phone Numbers:</span>
                      <span> {account.phone_numbers}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      <b>{account.source}</b>
                    </div>
                    <div>
                      <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, account.created_at)}</b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.accounts?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, accounts: { ...cookiesSetting.accounts, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default AccountsPage_List
