
interface ModalErrorSaveProps {
  setModal: (value: boolean) => void
}

export default function ModalErrorSave({
  setModal,
}: ModalErrorSaveProps) {

  return (
    <div className='modal-save'>
      <div className='modal-save-content'>
        <div className='header-error'>
          Something went wrong
        </div>

        <div className='btns'>
          <button
            className='btn btn-clear'
            onClick={() => setModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
