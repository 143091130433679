import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useLoadScript } from '@react-google-maps/api'
import copy from 'copy-to-clipboard';

import Icon from '../../components/Icon'
import ViewMap from '../ServiceResources/ViewMap'
import Select from '../../components/Select'
import Checkbox from '../../components/Checkbox'

import { httpClientUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

interface ServiceResourcesMapProps {
  activeAccountId: string
}

interface HttpData {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    service_resources: ServiceResourcesProps[]
  }
}

interface ServiceResourcesProps {
  area: string
  available: boolean
  code: number
  nickname: string
  service_resource_id: string
  zip_codes: string[]
  isCheck: boolean
}

interface zipCodesViewProps {
  zip_code: string,
  isHave: boolean,
  place_id: string,
  lat: number,
  lng: number
}

const libraries: (
  | "geometry"
  | "places"
  | "drawing"
  | "localContext"
  | "visualization"
)[] = ["places"];

export default function ServiceResourcesMap({ activeAccountId }: ServiceResourcesMapProps) {
  const API_KEY = process.env.REACT_APP_API_KEY
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY as string,
    mapIds: ['ec4fc7638d3b957e'],
    libraries,
  });

  const [selectedOption, setSelectedOption] = useState('')
  const [loading, setLoading] = useState(false)

  const [reportData, setReportData] = useState<ServiceResourcesProps[]>([])
  const [openPostalCodeMapModal, setOpenPostalCodeMapModal] = useState(false)

  const [activeGroups, setActiveGroups] = useState({
    group_a: true,
    group_b: true,
  })

  const [areaOptions, setAreaOptions] = useState<string[]>([])
  const [areaOptionsB, setAreaOptionsB] = useState<string[]>([])

  const [resultZips, setResultZips] = useState<string[]>([])
  const [zipCodesView, setZipCodesView] = useState<zipCodesViewProps[]>([])

  const [selectedArea, setSelectedArea] = useState('')
  const [selectedAreaB, setSelectedAreaB] = useState('')

  const [groupA, setGroupA] = useState<ServiceResourcesProps[]>([])
  const [groupB, setGroupB] = useState<ServiceResourcesProps[]>([])

  useEffect(() => {
    loadList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setResultZips([])
  }, [selectedOption])

  useEffect(() => {
    if (reportData) {
      if (selectedArea) {
        let updated = groupA.map(item => item)
        updated.sort((a, b) => a.area !== selectedArea ? 1 : -1)
        updated.sort((a, b) => a.isCheck < b.isCheck ? 1 : a.isCheck > b.isCheck ? -1 : 0)

        setGroupA(updated)
      } else {
        let updated = groupA.map(item => item)
        updated.sort((a, b) => a.isCheck < b.isCheck ? 1 : a.isCheck > b.isCheck ? -1 : 0)
        setGroupA(updated)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea])

  useEffect(() => {
    if (reportData) {
      if (selectedAreaB) {
        let updated = groupB.map(item => item)
        updated.sort((a, b) => a.area !== selectedAreaB ? 1 : -1)
        updated.sort((a, b) => a.isCheck < b.isCheck ? 1 : a.isCheck > b.isCheck ? -1 : 0)

        setGroupB(updated)
      } else {
        let updated = groupB.map(item => item)
        updated.sort((a, b) => a.isCheck < b.isCheck ? 1 : a.isCheck > b.isCheck ? -1 : 0)
        setGroupB(updated)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAreaB])

  useEffect(() => {
    if (zipCodesView.length) {
      setOpenPostalCodeMapModal(true)
    }
  }, [zipCodesView])

  async function loadList() {
    setLoading(true)
    // https://2022back4.artemiudintsev.com/api/tools/service-resources-areas
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = (await httpClientUpdate.get('/tools/service-resources-areas', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpData }
      if (success) {
        const areas: string[] = ['']

        report.service_resources.forEach(item => {
          if (!areas.includes(item.area)) {
            areas.push(item.area)
          }
        })

        setAreaOptions(areas)
        setAreaOptionsB(areas)
        setGroupA(report.service_resources.sort((a, b) => a.code > b.code ? 1 : -1).map(item => {
          return {
            ...item,
            isCheck: false
          }
        }))
        setGroupB(report.service_resources.sort((a, b) => a.code > b.code ? 1 : -1).map(item => {
          return {
            ...item,
            isCheck: false
          }
        }))
        setReportData(report.service_resources.sort((a, b) => a.code > b.code ? 1 : -1).map(item => {
          return {
            ...item,
            isCheck: false
          }
        }))
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setLoading(false)
    }
  }

  function handleSelectServiceGroupA(value: boolean, id: string) {
    let updatedData = groupA.map(item => {
      if (item.service_resource_id === id) {
        return {
          ...item,
          isCheck: value
        }
      } else {
        return { ...item }
      }
    })

    updatedData.sort((a, b) => a.isCheck < b.isCheck ? 1 : a.isCheck > b.isCheck ? -1 : 0)

    setResultZips([])
    setGroupA(updatedData)
  }

  function handleSelectServiceGroupB(value: boolean, id: string) {
    let updatedData = groupB.map(item => {
      if (item.service_resource_id === id) {
        return {
          ...item,
          isCheck: value
        }
      } else {
        return { ...item }
      }
    })

    updatedData.sort((a, b) => a.isCheck < b.isCheck ? 1 : a.isCheck > b.isCheck ? -1 : 0)

    setResultZips([])
    setGroupB(updatedData)
  }

  function handleStart() {
    setLoading(true)
    if (selectedOption === 'Only Group A') {
      let zips: string[] = []

      groupA.forEach(item => {
        if (item.isCheck) {
          item.zip_codes.forEach(zip => {
            if (!zips.includes(zip)) {
              zips.push(zip)
            }
          })
        }
      })

      setResultZips(zips.sort((a, b) => a > b ? 1 : -1))
      setLoading(false)
    } else if (selectedOption === 'Covered by Group A, not Covered by Group B') {
      let zipsA: string[] = []
      let zipsB: string[] = []

      groupA.forEach(item => {
        if (item.isCheck) {
          item.zip_codes.forEach(zip => {
            if (!zipsA.includes(zip)) {
              zipsA.push(zip)
            }
          })
        }
      })

      groupB.forEach(item => {
        if (item.isCheck) {
          item.zip_codes.forEach(zip => {
            if (!zipsB.includes(zip)) {
              zipsB.push(zip)
            }
          })
        }
      })

      setResultZips(zipsA.filter(zip => !zipsB.includes(zip)).sort((a, b) => a > b ? 1 : -1))
      setLoading(false)
    }
  }

  async function handleCopy() {
    copy(resultZips.join(', '))
  }

  function handleOpenModal() {
    if (resultZips.length) {
      const geocoder = new google.maps.Geocoder()
      let count = 0

      let selectedPlace: zipCodesViewProps[] = []

      for (const zipCode of resultZips) {
        geocoder.geocode({
          'componentRestrictions': { postalCode: zipCode, country: 'USA' }
          // eslint-disable-next-line no-loop-func
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results) {
              count = count + 1
              selectedPlace.push({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                zip_code: zipCode,
                isHave: true,
                place_id: results[0].place_id,
              })
            } else {
              count = count + 1
              selectedPlace.push({
                lat: 0,
                lng: 0,
                zip_code: zipCode,
                isHave: false,
                place_id: '',
              })
            }
          } else {
            count = count + 1
            selectedPlace.push({
              lat: 0,
              lng: 0,
              zip_code: zipCode,
              isHave: false,
              place_id: '',
            })
          }

          if (count === resultZips.length) {
            setZipCodesView(selectedPlace)
          }
        })
      }
    }
  }

  return (
    <>
      {
        !!reportData.length &&
        <div className="tools-calculations">
          {
            openPostalCodeMapModal &&
            !!zipCodesView.length &&
            <div
              className='modal-map'
              onClick={() => setOpenPostalCodeMapModal(false)}
            >
              <div
                className='view-map'
                onClick={(e) => e.stopPropagation()}
              >
                <ViewMap
                  selectedZipCode={zipCodesView.filter(item => item.isHave)}
                  loadError={loadError}
                  isLoaded={isLoaded}
                />
              </div>

            </div>
          }
          <div className='calculations-items'>
            <div className='calculations-item'>
              <p className='title'>Group A</p>

              <div className='calculations-item-row'>
                <Select
                  options={areaOptions.map((item) => ({
                    span: item,
                    value: item
                  }))}
                  disabled={loading}
                  selectedOption={selectedArea}
                  onChange={(value: string) => setSelectedArea(value)}
                />

                <Checkbox
                  contents={''}
                  value={activeGroups.group_a}
                  onChange={(value) => setActiveGroups({ ...activeGroups, group_a: value })}
                />

                <div>Active</div>
              </div>

              <div className='service-row-items'>
                {
                  activeGroups.group_a ?
                    groupA
                      .filter(item => item.isCheck || item.available)
                      .map((item) => (
                        <div key={item.service_resource_id} className='service-row'>
                          <Checkbox
                            contents=''
                            value={item.isCheck}
                            onChange={(value) => handleSelectServiceGroupA(value, item.service_resource_id)}
                          />

                          <span
                            style={{ color: item.available ? 'inherit' : '#FF0000' }}
                          >
                            {item.code ? `${item.nickname} (${item.code})` : item.nickname} - {item.area}
                          </span>
                        </div>
                      )) : groupA.map((item) => (
                        <div key={item.service_resource_id} className='service-row'>
                          <Checkbox
                            contents=''
                            value={item.isCheck}
                            onChange={(value) => handleSelectServiceGroupA(value, item.service_resource_id)}
                          />

                          <span
                            style={{ color: item.available ? 'inherit' : '#FF0000' }}
                          >
                            {item.code ? `${item.nickname} (${item.code})` : item.nickname} - {item.area}
                          </span>
                        </div>
                      ))
                }
              </div>
            </div>

            <div
              className={classNames('calculations-item', {
                not_available: ['Only Group A'].includes(selectedOption)
              })}
            >
              <p className='title'>Group B</p>

              <div className='calculations-item-row'>
                <Select
                  options={areaOptionsB.map((item) => ({
                    span: item,
                    value: item
                  }))}
                  disabled={loading || ['Only Group A'].includes(selectedOption)}
                  selectedOption={selectedAreaB}
                  onChange={(value: string) => setSelectedAreaB(value)}
                />

                <Checkbox
                  contents={''}
                  value={activeGroups.group_b}
                  onChange={(value) => setActiveGroups({ ...activeGroups, group_b: value })}
                />

                <div>Active</div>
              </div>

              <div className='service-row-items'>
                {
                  activeGroups.group_b ?
                    groupB
                      .filter(item => item.isCheck || item.available)
                      .map((item) => (
                        <div key={item.service_resource_id} className='service-row'>
                          <Checkbox
                            contents=''
                            value={item.isCheck}
                            disabled={['Only Group A'].includes(selectedOption)}
                            onChange={(value) => handleSelectServiceGroupB(value, item.service_resource_id)}
                          />

                          <span
                            style={{ color: item.available ? 'inherit' : '#FF0000' }}
                          >
                            {item.code ? `${item.nickname} (${item.code})` : item.nickname} - {item.area}
                          </span>
                        </div>
                      )) :
                    groupB.map((item) => (
                      <div key={item.service_resource_id} className='service-row'>
                        <Checkbox
                          contents=''
                          value={item.isCheck}
                          disabled={['Only Group A'].includes(selectedOption)}
                          onChange={(value) => handleSelectServiceGroupB(value, item.service_resource_id)}
                        />

                        <span
                          style={{ color: item.available ? 'inherit' : '#FF0000' }}
                        >
                          {item.code ? `${item.nickname} (${item.code})` : item.nickname} - {item.area}
                        </span>
                      </div>
                    ))
                }
              </div>
            </div>

            <div className='calculations-item'>
              <div className='select-calculations'>
                <Select
                  options={['', 'Only Group A', 'Covered by Group A, not Covered by Group B'].map((item) => ({
                    span: item,
                    value: item
                  }))}
                  selectedOption={selectedOption}
                  onChange={(value: string) => setSelectedOption(value)}
                />

                <button
                  disabled={loading || !groupA.filter(item => item.isCheck).length || selectedOption === ''}
                  className="_bordered _green calculation"
                  onClick={() => handleStart()}
                >
                  Start
                </button>
              </div>

              {
                !!resultZips.length &&
                <div
                  className='select-calculations'
                  style={{
                    justifyContent: 'space-between',
                    margin: '10px 0'
                  }}
                >
                  <div>
                    <span style={{ opacity: '.4' }}>Count: {resultZips.length}</span>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      disabled={loading || !groupA.filter(item => item.isCheck).length}
                      className="_bordered _green calculation"
                      style={{ border: 'none', padding: '0', marginRight: '20px' }}
                      onClick={() => handleCopy()}
                    >
                      <Icon
                        style={{
                          width: '20px',
                          height: '20px',
                          verticalAlign: 'middle',
                          overflow: 'hidden',
                          fill: 'inherit'
                        }}
                        viewBox="0 0 1024 1024"
                        icon="copy-1"
                      />
                    </button>

                    <button
                      disabled={loading || !groupA.filter(item => item.isCheck).length}
                      className="_bordered _green calculation"
                      style={{ border: 'none', padding: '0' }}
                      onClick={() => handleOpenModal()}
                    >
                      <Icon
                        style={{ width: '20px', height: '20px', fill: '#000' }}
                        viewBox="0 0 1024 1024"
                        icon="map-1"
                      />
                    </button>
                  </div>
                </div>
              }

              <div className='zip-view'>

                {
                  resultZips.map((item, i) => (
                    <div key={i} className='zip-view-item'>
                      <span>{item}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
