import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment, { Moment } from "moment";
import qs from "qs";

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import Switcher from "../../components/Switcher";

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: StaffData
}

interface StaffData {
  permissions: {
    staff_delete: boolean
    staff_edit: boolean
    staff_salary_show: boolean
  },

  staff: StaffProps,
}

interface StaffProps {
  available: boolean
  created_at: string
  created_by: string
  nickname: string
  staff_id: string
  updated_at: string
  updated_by: string
  user_name: string
  is_auto_calculation: boolean
  salary_per_hour: number
  start_from_date: string | Moment
}

function StaffPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<StaffData | null>(null)

  const [newStaffData, setNewStaffData] = useState<Partial<StaffProps>>({
    available: false,
    nickname: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    if (reportData) {

      setNewStaffData({
        ...reportData.staff,
        salary_per_hour: reportData.staff.salary_per_hour ? Number(reportData.staff.salary_per_hour) : 0
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/staff/888880089ndbv4lug2
      const response = await httpClientUpdate.delete(`/staff/${$router.router.getState().params.staffId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('staff', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/staff/888880089ndbv4lug2
      const response = await httpClientUpdate.put(`/staff/${$router.router.getState().params.staffId}`, qs.stringify({
        account_id: activeAccountId,
        nickname: newStaffData.nickname,
        user_id: newStaffData.user_name,
        available: newStaffData.available ? 1 : 0,
        is_auto_calculation: reportData?.permissions.staff_salary_show ? newStaffData.is_auto_calculation ? 1 : 0 : null,
        salary_per_hour: reportData?.permissions.staff_salary_show ? newStaffData.salary_per_hour?.toFixed(2) : null,
        start_from_date: reportData?.permissions.staff_salary_show ? moment(newStaffData.start_from_date).format('YYYY-MM-DD') : null,
      }, { skipNulls: true }))
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/staff/888880089ndbv4lug2?account_id=88888
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/staff/' + $router.router.getState().params.staffId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(loadData)
        setNewStaffData({
          ...loadData.staff,
          salary_per_hour: loadData.staff.salary_per_hour ? Number(loadData.staff.salary_per_hour) : 0
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newStaffData
  ])

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(event: React.SyntheticEvent<EventTarget>) {
    let price = (event.target as HTMLInputElement).value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewStaffData({
        ...newStaffData,
        salary_per_hour: +newNumber
      })
    } else if (price.length === 1) {
      let newNumber = `0.0${price}`
      setNewStaffData({
        ...newStaffData,
        salary_per_hour: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewStaffData({
          ...newStaffData,
          salary_per_hour: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewStaffData({
          ...newStaffData,
          salary_per_hour: +newNumber
        })
      }
    }
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="StaffPage_Item entity-edit">
        <div className="wrapper flex-container sb">
          <h1>Staff</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'staff',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                reportData?.permissions.staff_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>

                <input
                  type="text"
                  disabled={!editing}
                  value={newStaffData.nickname}
                  onChange={({ target: { value } }) => setNewStaffData({ ...newStaffData, nickname: value })}
                />
              </div>

              <div className="field">
                <span>User:</span>

                <input
                  type="text"
                  disabled={true}
                  value={newStaffData.user_name}
                  onChange={({ target: { value } }) => setNewStaffData({ ...newStaffData, user_name: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Active:</span>

                <Switcher
                  disabled={!editing}
                  checked={newStaffData.available}
                  onChange={(value) => setNewStaffData({ ...newStaffData, available: value })}
                />
              </div>
            </div>
          </div>
        </div>

        {
          reportData.permissions.staff_salary_show &&
          <div className="fieldset">
            <div className="legend">Salary</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Salary Per Hour:</span>

                  <input
                    className='amount-field'
                    type="number"
                    disabled={!editing}
                    value={newStaffData.salary_per_hour?.toFixed(2)}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangePrice(event)}
                    onKeyDown={(event) => handleChangePriceBackspace(event)}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Start Date:</span>

                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    inputProps={{ disabled: !editing }}
                    value={newStaffData.start_from_date}
                    onChange={(value) => setNewStaffData({ ...newStaffData, start_from_date: value })}
                  />
                </div>

                <div className="field">
                  <span>Auto Calculation:</span>

                  <Switcher
                    disabled={!editing}
                    checked={newStaffData.is_auto_calculation}
                    onChange={(value) => setNewStaffData({ ...newStaffData, is_auto_calculation: value })}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.staff.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={reportData?.staff.created_by} disabled={true} />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.staff.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={reportData?.staff.updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.staff_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Staff</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {reportData.permissions.staff_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Staff
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the staff it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default StaffPage_Item
