import { memo } from 'react'

import Icon from '../Icon'

interface HoleDataProps {
  status: string
  call_by: string
  client_name: string
  client_tel: string
  client_location: string
  operator_name: string
  operator_tel: string
  operator_location: string
}

interface HoleItemProps {
  holeData: HoleDataProps[]
}

function HoleItem({ holeData }: HoleItemProps) {
  return (
    <>
      {
        holeData.map((hole, index) => (
          <div key={index} className='section__account_softphone-content_home-item section__account_softphone-content_home-onhold'>
            <div className='section__account_softphone-content_home-item_icon'>
              <Icon
                viewBox="0 0 14 14"
                style={{ fill: '#6093DE', width: '20px', height: '20px', strokeWidth: '2px', stroke: '#8E8E93' }}
                icon="pause-1"
              />

              <p> {hole.status} </p>

              <Icon
                viewBox="0 0 17 17"
                style={{ fill: '#8E8E93', strokeWidth: '1.6px', stroke: '#8E8E93' }}
                icon="quit-1"
              />

              <p> {hole.call_by} </p>
            </div>

            <div className='section__account_softphone-content_home-item_data'>
              <div className='section__account_softphone-content_home-item_text'>
                <div className='section__account_softphone-content_home-item_client-name'>
                  <div className='section__account_softphone-content_calls-contIcon visible'>
                    <Icon viewBox="0 0 11 13" style={{ fill: '#8E8E93' }} icon="account-1" />
                  </div>

                  <p> {hole.client_name} </p>
                </div>

                <div className='section__account_softphone-content_home-item_client-tel'>
                  {hole.client_tel}
                </div>

                <div className='section__account_softphone-content_home-item_client-location'>
                  <div className='section__account_softphone-content_home-item_location-icon'>
                    <Icon viewBox="0 0 6 8" style={{ fill: '#8E8E93' }} icon="location-1" />
                  </div>

                  <p> {hole.client_location} </p>
                </div>
              </div>

              <div className='section__account_softphone-content_home-item_text'>
                <div className='section__account_softphone-content_home-item_operator-name'>
                  {hole.operator_name}
                </div>

                <div className='section__account_softphone-content_home-item_operator-tel'>
                  {hole.operator_tel}
                </div>

                <div className='section__account_softphone-content_home-item_operator-location'>
                  <div className='section__account_softphone-content_home-item_location-icon'>
                    <Icon viewBox="0 0 6 8" style={{ fill: '#8E8E93' }} icon="location-1" />
                  </div>

                  <p> {hole.operator_location} </p>
                </div>
              </div>
            </div>

            <div className='section__account_softphone-content_home-item_buttons'>
              <div className='section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-info'>
                <p>i</p>
              </div>

              <div className='section__account_softphone-content_home-item_button-callsBlock'>
                <div className='section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-takeCall'>
                  <Icon viewBox="0 0 16 17" style={{ fill: '#ffffff' }} icon="call-1" />
                </div>

                <div className='section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-rejectCall'>
                  <Icon viewBox="0 0 16 17" style={{ fill: '#ffffff', transform: 'rotate(135deg)' }} icon="call-1" />
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </>
  )
}

export default memo(HoleItem)