import { memo, useState } from 'react'

import CompletedCallItem from './CompletedCallItem'
import ActiveCallItem from './ActiveCallItem'
import IncomingCallItem from './IncomingCallItem'
import HoleItem from './HoleItem'
import OutgoingCallItem from './OutgoingCallItem'

import { CallsDataProps, DispatchersProps, PermissionsProps } from '../../App'

interface HomeContentProps {
  actionMenu: string[]
  setActionMenu: (value: string[]) => void

  actionMenuCompetedCall: string[]
  setActionMenuCompetedCall: (value: string[]) => void

  infoBlock: string[]
  setInfoBlock: (value: string[]) => void

  infoBlockCompetedCall: string[]
  setInfoBlockCompetedCall: (value: string[]) => void

  infoBlockIncoming: string[]
  setInfoBlockIncoming: (value: string[]) => void

  pin: boolean
  setPin: (value: boolean) => void

  callsData: CallsDataProps[]
  stop: (value: string) => void
  disconnectedCall: (value: string) => void
  handleAnswer: (value: string) => void
  stoptalk: (value: string) => void
  handleRemoveCompletedCall: (value: string) => void

  handleAddComment: (sid: string, tags: string, comment: string) => void

  permissions: Partial<PermissionsProps>

  dispatcherTwillio: DispatchersProps[]

  handleRedirectCall: (call_sid: string, dispatcher_id: string) => void
  handlePauseCall: (call_sid: string) => void
  handleResumeCall: (call_sid: string) => void
}

const actionsMenus = ['Make an Appointment', 'Reschedule an Appointment', 'Make a Ticket', 'Mark for Blacklist', 'Add a Comment']

const holeData = [
  {
    status: 'On Hold',
    call_by: 'by Mike',
    client_name: 'John Ryder',
    client_tel: '+1(234)567-89-00',
    client_location: 'Los Angeles, CA',
    operator_name: 'TexasGoogleAds',
    operator_tel: '+1(234)567-89-00',
    operator_location: 'Houston, TX'
  }
]

function HomeContent({
  actionMenu,
  setActionMenu,
  infoBlock,
  setInfoBlock,
  pin,
  setPin,
  actionMenuCompetedCall,
  setActionMenuCompetedCall,
  infoBlockCompetedCall,
  setInfoBlockCompetedCall,
  callsData,
  stop,
  handleAnswer,
  stoptalk,
  handleRemoveCompletedCall,
  infoBlockIncoming,
  setInfoBlockIncoming,
  handleAddComment,
  permissions,
  disconnectedCall,
  dispatcherTwillio,
  handleRedirectCall,
  handlePauseCall,
  handleResumeCall,
}: HomeContentProps) {
  const [blackList, setBlackList] = useState(false)
  const [blackListCom, setBlackListCom] = useState(false)

  function handleActionMenus(id: string) {
    if (actionMenu.includes(id)) {
      setActionMenu(actionMenu.filter(item => item !== id))
    } else {
      let updatedCommentBlock = actionMenu.map(item => item)
      updatedCommentBlock.push(id)
      setActionMenu(updatedCommentBlock)
    }
  }

  function handleInfoBlock(id: string) {
    if (infoBlock.includes(id)) {
      setInfoBlock(infoBlock.filter(item => item !== id))
    } else {
      let updatedInfoBlock = infoBlock.map(item => item)
      updatedInfoBlock.push(id)
      setInfoBlock(updatedInfoBlock)
    }
  }

  function handleSubmit(sid: string, tags: string, comment: string) {
    handleAddComment(sid, tags, comment)
  }

  return (
    <div className='section__account_softphone-content_item section__account_softphone-content_home'>
      <div className='section__account_softphone-content_home-block'>
        {
          !!callsData.length &&
          callsData.map((item, index) => (
            <div key={index}>
              {
                item.current_call.status === 'active' &&
                <ActiveCallItem
                  handleActionMenus={handleActionMenus}
                  actionMenu={actionMenu}
                  handleInfoBlock={handleInfoBlock}
                  handleSubmit={handleSubmit}
                  pin={pin}
                  setPin={setPin}
                  infoBlock={infoBlock}
                  actionsMenus={actionsMenus}
                  blackList={blackList}
                  setBlackList={setBlackList}
                  stoptalk={stoptalk}
                  callData={item}
                  permissions={permissions}
                  dispatcherTwillio={dispatcherTwillio}
                  handleRedirectCall={handleRedirectCall}
                  handlePauseCall={handlePauseCall}
                  handleResumeCall={handleResumeCall}
                />
              }

              {/* Completed call Item */}
              {
                item.current_call.status === 'completed' &&
                < CompletedCallItem
                  actionMenuCompetedCall={actionMenuCompetedCall}
                  setActionMenuCompetedCall={setActionMenuCompetedCall}
                  infoBlockCompetedCall={infoBlockCompetedCall}
                  setInfoBlockCompetedCall={setInfoBlockCompetedCall}
                  actionsMenus={actionsMenus}
                  blackList={blackListCom}
                  setBlackList={setBlackListCom}
                  handleSubmit={handleSubmit}
                  callData={item}
                  handleRemoveCompletedCall={handleRemoveCompletedCall}
                  permissions={permissions}
                />

              }

              {/* Incoming call Item */}
              {
                item.current_call.status === 'Incoming Call' &&
                <IncomingCallItem
                  stop={stop}
                  callData={item}
                  handleAnswer={handleAnswer}
                  infoBlockIncoming={infoBlockIncoming}
                  setInfoBlockIncoming={setInfoBlockIncoming}
                />
              }

              {/* Outgoing call Item */}
              {
                item.current_call.status === 'Outgoing Call' &&
                <OutgoingCallItem
                  stop={disconnectedCall}
                  callData={item}
                  infoBlockIncoming={infoBlockIncoming}
                  setInfoBlockIncoming={setInfoBlockIncoming}
                />
              }

              {/* On hole Item */}
              {
                item.current_call.status === 'hold' &&
                <HoleItem holeData={holeData} />
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default memo(HomeContent)