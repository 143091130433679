import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import { useLoadScript } from "@react-google-maps/api";
import copy from 'copy-to-clipboard';

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import Switcher from "../../components/Switcher"
import ServiceResourceSchedule from './ServiceResourceSchedule'
import ServiceResourceRestrictions from './ServiceResourceRestrictions'
import ViewMap from "./ViewMap";

import { dateToInfoBlock, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import ServiceResource from "../../models/ServiceResource"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ServiceResourceData
}

interface ServiceResourceData {

  permissions: {
    service_resource_add: boolean
    service_resource_delete: boolean
    service_resource_edit: boolean
    service_resource_report_show: boolean

    service_resource_schedule_add: boolean
    service_resource_schedule_delete: boolean
    service_resource_schedule_edit: boolean
    service_resource_schedule_show: boolean
    service_resource_show: boolean
  },

  service_resource: ServiceResource[],

  edit: {
    time_zone: {
      id: number
      name: string
    }[],
    area: {
      area: string
      area_id: string
      area_time_zone: string
    }[]
    service_resources: {
      code: number
      nickname: string
      service_resource_id: string
    }[]
    mentors: {
      service_resource: string
      user_name: string
      user_id: string
    }[]
  }
}

interface zipCodesViewProps {
  zip_code: string,
  isHave: boolean,
  place_id: string,
  lat: number,
  lng: number
}

const libraries: (
  | "geometry"
  | "places"
  | "drawing"
  | "localContext"
  | "visualization"
)[] = ["places"];

function ServiceResourcesPage_Item() {
  const $router = useRoute()

  const API_KEY = process.env.REACT_APP_API_KEY
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY as string,
    mapIds: ['ec4fc7638d3b957e'],
    libraries,
  });

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [page, setPage] = useState('main')

  const [readyToSave, setReadyToSave] = useState(false)
  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [serviceResourceData, setServiceResourceData] = useState<ServiceResourceData | null>(null)
  const [zips, setZips] = useState<string>('')

  const [newServiceResourceData, setNewServiceResourceData] = useState<Partial<ServiceResource>>({

  })

  const [zipCodesView, setZipCodesView] = useState<zipCodesViewProps[]>([])

  const [openPostalCodeMapModal, setOpenPostalCodeMapModal] = useState(false)

  function handleCancelEdit() {
    let area = serviceResourceData && serviceResourceData.service_resource[0].area ? serviceResourceData.edit.area.filter(item => item.area === serviceResourceData.service_resource[0].area)[0].area_id : ''
    let time_zone = serviceResourceData && serviceResourceData.service_resource[0].area_id ? serviceResourceData.edit.area.filter(item => item.area === serviceResourceData.service_resource[0].area)[0].area_time_zone : ''
    serviceResourceData && setNewServiceResourceData({
      ...serviceResourceData.service_resource[0],
      area,
      time_zone: time_zone,
    })
    serviceResourceData && setZips(serviceResourceData.service_resource[0].service_area_zips.join(', '))
    setEditing(false)
  }

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/serviceresources/${$router.router.getState().params.serviceResourceId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('service_resources', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      let phone = newServiceResourceData.phone ? formatPhoneNumberToServerString(newServiceResourceData.phone) : ''

      const response = await httpClientUpdate.put(`/serviceresources/${$router.router.getState().params.serviceResourceId}`, {
        account_id: activeAccountId,
        nickname: newServiceResourceData.name,
        available: !!newServiceResourceData.active ? 1 : 0,
        area_id: newServiceResourceData.area ? newServiceResourceData.area : null,
        phone: phone,
        slack_channel: newServiceResourceData.slack_channel,
        slack_member_id: newServiceResourceData.slack_member_id,
        is_phone: newServiceResourceData.is_phone ? 1 : 0,
        is_slack: newServiceResourceData.is_slack ? 1 : 0,
        service_area_zips: zips.replace(/,/g, " ").split(' ').filter(item => item && item.trim()),
        day_limit_friday: newServiceResourceData.day_limit_friday,
        day_limit_friday_type: newServiceResourceData.day_limit_friday_type,
        day_limit_monday: newServiceResourceData.day_limit_monday,
        day_limit_monday_type: newServiceResourceData.day_limit_monday_type,
        day_limit_saturday: newServiceResourceData.day_limit_saturday,
        day_limit_saturday_type: newServiceResourceData.day_limit_saturday_type,
        day_limit_sunday: newServiceResourceData.day_limit_sunday,
        day_limit_sunday_type: newServiceResourceData.day_limit_sunday_type,
        day_limit_thursday: newServiceResourceData.day_limit_thursday,
        day_limit_thursday_type: newServiceResourceData.day_limit_thursday_type,
        day_limit_tuesday: newServiceResourceData.day_limit_tuesday,
        day_limit_tuesday_type: newServiceResourceData.day_limit_tuesday_type,
        day_limit_wednesday: newServiceResourceData.day_limit_wednesday,
        day_limit_wednesday_type: newServiceResourceData.day_limit_wednesday_type,
        note_no: newServiceResourceData.note_no,
        note_yes: newServiceResourceData.note_yes,
        code: newServiceResourceData.code,
        priority: newServiceResourceData.priority,
        mentor_id: newServiceResourceData.mentor_id ? newServiceResourceData.mentor_id : ''
      })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: serviceResourceData, success, error } } = await httpClientUpdate.get('/serviceresources/' + $router.router.getState().params.serviceResourceId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (serviceResourceData.permissions.service_resource_show) {
          let area = serviceResourceData.service_resource[0].area ? serviceResourceData.edit.area.filter(item => item.area === serviceResourceData.service_resource[0].area)[0].area_id : ''
          let time_zone = serviceResourceData && serviceResourceData.service_resource[0].area_id ? serviceResourceData.edit.area.filter(item => item.area === serviceResourceData.service_resource[0].area)[0].area_time_zone : ''

          setServiceResourceData(serviceResourceData)
          setNewServiceResourceData({
            ...serviceResourceData.service_resource[0],
            area,
            time_zone
          })
          setZips(serviceResourceData.service_resource[0].service_area_zips.join(', '))
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load service_resource data
  useEffect(() => {
    page === 'main' && loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => setReadyToSave(true), [
    newServiceResourceData, zips
  ])

  useEffect(() => {
    let postal_codes = zips.replace(/,/g, " ").split(' ').filter(item => item && item.trim())
    if (postal_codes.length) {
      const geocoder = new google.maps.Geocoder()
      let count = 0

      let selectedPlace: zipCodesViewProps[] = []

      for (const zipCode of postal_codes) {
        geocoder.geocode({
          'componentRestrictions': { postalCode: zipCode, country: 'USA' }
          // eslint-disable-next-line no-loop-func
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results) {
              count = count + 1
              selectedPlace.push({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                zip_code: zipCode,
                isHave: true,
                place_id: results[0].place_id,
              })
            } else {
              count = count + 1
              selectedPlace.push({
                lat: 0,
                lng: 0,
                zip_code: zipCode,
                isHave: false,
                place_id: '',
              })
            }
          } else {
            count = count + 1
            selectedPlace.push({
              lat: 0,
              lng: 0,
              zip_code: zipCode,
              isHave: false,
              place_id: '',
            })
          }

          if (count === postal_codes.length) {
            setZipCodesView(selectedPlace.sort((a, b) => Number(a.zip_code) - Number(b.zip_code)))
          }
        })
      }
    }
  }, [zips])

  function handleBackServiceResources() {
    setPage('main')
  }

  function handleSelectArea(id: string) {
    if (id === '') {
      setNewServiceResourceData({ ...newServiceResourceData, area: id, time_zone: '' })
    } else {
      serviceResourceData?.edit.area.forEach(item => {
        if (item.area_id === id) {
          setNewServiceResourceData({ ...newServiceResourceData, area: id, time_zone: item.area_time_zone })
        }
      })
    }
  }

  async function handleCopy() {
    copy(zipCodesView.filter(item => item.isHave).map(item => item.zip_code).join(', '))
  }

  // Render function
  return (<>
    {
      page === 'main' ?
        <>
          {serviceResourceData ? (
            <div className="ServiceResourcesPage_Item entity-edit">

              <div className="wrapper flex-container sb">
                <div className="flex-container _gap-narrow">
                  <h1>Service Resource:</h1>

                  <div className="item-name">
                    {newServiceResourceData.code ? `${newServiceResourceData.name} (${newServiceResourceData.code})` : newServiceResourceData.name}
                  </div>
                </div>

                {/* Temporarily removed the rights check on the field edit_service_resource */}
                <div style={{ display: 'flex' }} >
                  <button
                    className="_wa"
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    disabled={editing}
                    onClick={() =>
                      $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'service_resources',
                        {
                          companyId: activeAccountId,
                          [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  <button
                    className="_wa"
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    disabled={editing}
                    onClick={() => setPage('restrictions')}
                  >
                    Restrictions
                  </button>

                  {serviceResourceData?.permissions.service_resource_schedule_show ? (
                    <button
                      className="_wa"
                      style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                      disabled={editing}
                      onClick={() => setPage('schedule')}
                    >
                      Schedule
                    </button>
                  ) : null}

                  {serviceResourceData?.permissions.service_resource_edit && !editing ? (
                    <button className="_wa _green" onClick={() => setEditing(true)}>
                      Edit
                    </button>
                  ) : null}
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>NickName:</span>

                      <input
                        disabled={!editing}
                        type="text"
                        value={newServiceResourceData.name || ''}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, name: value })}
                      />
                    </div>
                  </div>


                  <div className="__right">
                    <div className="field">
                      <span>Active:</span>

                      <Switcher
                        disabled={!editing}
                        checked={!!newServiceResourceData.active as boolean}
                        onChange={(value) => setNewServiceResourceData({ ...newServiceResourceData, active: value })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Settings</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>User:</span>

                      <input
                        disabled={true}
                        type="text"
                        defaultValue={serviceResourceData.service_resource[0].user || ''}
                      />
                    </div>

                    <div className="field" style={{ zIndex: 5 }}>
                      <span>Area:</span>
                      <Select
                        disabled={!editing}
                        options={[{ area: '', area_id: '' }].concat(serviceResourceData.edit.area).map((option) => ({
                          span: option.area,
                          value: option.area_id
                        }))}
                        selectedOption={newServiceResourceData.area as string}
                        onChange={(value: string) => handleSelectArea(value)}
                      />
                    </div>

                    <div className="field">
                      <span>Priority:</span>

                      <input
                        disabled={!editing}
                        type="number"
                        value={newServiceResourceData.priority || 0}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, priority: +value })}
                      />
                    </div>
                  </div>

                  <div className="__right">
                    <div className="field">
                      <span>Code:</span>

                      <input
                        disabled={!editing}
                        type="number"
                        value={newServiceResourceData.code || ''}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, code: +value })}
                      />
                    </div>

                    <div className="field">
                      <span>Time Zone:</span>
                      <input
                        disabled={true}
                        type="text"
                        value={newServiceResourceData.time_zone || ''}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, time_zone: value })}
                      />
                    </div>

                    <div className="field" style={{ zIndex: 5 }}>
                      <span>Mentor:</span>
                      <Select
                        disabled={!editing}
                        options={
                          [{ user_name: '', user_id: '', service_resource: '' }]
                            .concat(serviceResourceData.edit.mentors.filter(item => item.user_name !== newServiceResourceData.user))
                            .map((option) => ({
                              span: option.service_resource ? option.service_resource : option.user_name,
                              value: option.user_id
                            }))}
                        selectedOption={newServiceResourceData.mentor_id as string}
                        onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, mentor_id: value })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Notifications</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Phone:</span>
                      <ReactInputMask
                        disabled={!editing}
                        type="text"
                        mask="+1 (999) 999-9999"
                        value={newServiceResourceData.phone || ''}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, phone: value })}
                      />
                    </div>

                    <div className="field">
                      <span>Slack Channel:</span>

                      <input
                        disabled={!editing}
                        type="text"
                        defaultValue={newServiceResourceData.slack_channel || ''}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, slack_channel: value })}
                      />
                    </div>

                    <div className="field">
                      <span>Slack Member ID:</span>

                      <input
                        disabled={!editing}
                        type="text"
                        defaultValue={newServiceResourceData.slack_member_id || ''}
                        onChange={({ target: { value } }) => setNewServiceResourceData({ ...newServiceResourceData, slack_member_id: value })}
                      />
                    </div>

                  </div>

                  <div className="__right">
                    <div className="field">
                      <span>Phone:</span>

                      <Checkbox
                        disabled={!editing}
                        contents=''
                        value={newServiceResourceData.is_phone}
                        onChange={(value) => setNewServiceResourceData({ ...newServiceResourceData, is_phone: value })}
                      />
                    </div>

                    <div className="field">
                      <span>Is Slack:</span>

                      <Checkbox
                        disabled={!editing}
                        contents=''
                        value={newServiceResourceData.is_slack}
                        onChange={(value) => setNewServiceResourceData({ ...newServiceResourceData, is_slack: value })}
                      />
                    </div>

                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div className="legend">Service Area Zips</div>

                  {
                    !!zipCodesView.length && !!zipCodesView.filter(item => item.isHave).length &&
                    <div style={{ display: 'flex' }}>
                      <button
                        style={{ padding: '5px', marginRight: '10px' }}
                        onClick={() => handleCopy()}
                        title="Copy zips"
                      >
                        <Icon
                          style={{
                            width: '24px',
                            height: '24px',
                            verticalAlign: 'middle',
                            overflow: 'hidden',
                            fill: 'inherit'
                          }}
                          viewBox="0 0 1024 1024"
                          icon="copy-1"
                        />
                      </button>

                      <button
                        style={{ padding: '5px' }}
                        onClick={() => setOpenPostalCodeMapModal(true)}
                      >
                        <Icon
                          style={{ width: '20px', height: '20px' }}
                          viewBox="0 0 1024 1024"
                          icon="map-1"
                        />
                      </button>
                    </div>
                  }
                </div>

                <div
                  style={{
                    maxHeight: '180px',
                    overflow: 'auto'
                  }}
                  className="fields">
                  {
                    editing ?
                      <textarea
                        style={{ resize: 'none' }}
                        disabled={!editing}
                        value={zips || ''}
                        onChange={({ target: { value } }) => setZips(value)}
                      /> :
                      zipCodesView.map((item, i) => (
                        <div
                          style={{
                            padding: '5px',
                            border: '1px solid #d0d3da',
                            borderRadius: '5px',
                            color: item.isHave ? 'inherit' : '#FF0000'
                          }}
                          key={i}
                        >
                          {item.zip_code}
                        </div>
                      ))
                  }
                </div>
              </div>

              <div className="fieldset fieldset-limits">
                <div className="legend">Day Limits</div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Monday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_monday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_monday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_monday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_monday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_monday_type: value })}
                    />
                  </div>
                </div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Tuesday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_tuesday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_tuesday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_tuesday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_tuesday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_tuesday_type: value })}
                    />
                  </div>
                </div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Wednesday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_wednesday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_wednesday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_wednesday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_wednesday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_wednesday_type: value })}
                    />
                  </div>
                </div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Thursday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_thursday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_thursday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_thursday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_thursday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_thursday_type: value })}
                    />
                  </div>
                </div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Friday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_friday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_friday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_friday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_friday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_friday_type: value })}
                    />
                  </div>
                </div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Saturday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_saturday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_saturday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_saturday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_saturday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_saturday_type: value })}
                    />
                  </div>
                </div>

                <div
                  style={{ justifyContent: 'space-between' }}
                  className="fields"
                >
                  <div style={{ width: '10%' }} className="field">
                    <span>Sunday:</span>
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Limit:</span>
                    <input
                      type="number"
                      min={0}
                      value={newServiceResourceData.day_limit_sunday || 0}
                      disabled={!editing || newServiceResourceData.day_limit_sunday_type === 'no limit'}
                      onChange={(event) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_sunday: +event.target.value })}
                    />
                  </div>

                  <div style={{ width: '30%' }} className="field">
                    <span>Type:</span>
                    <Select
                      disabled={!editing}
                      options={['all', 'new', 'no limit'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newServiceResourceData.day_limit_sunday_type as string || ''}
                      onChange={(value: string) => setNewServiceResourceData({ ...newServiceResourceData, day_limit_sunday_type: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Notes</div>

                <div className="fields">
                  <div style={{ width: '100%' }} className="field">
                    <span
                      style={{
                        width: '40px',
                        marginRight: '20px'
                      }}
                    >
                      Yes:
                    </span>

                    <textarea
                      disabled={!editing}
                      style={{ resize: 'none', minHeight: '75px' }}
                      value={newServiceResourceData.note_yes || ''}
                      onChange={(event) =>
                        setNewServiceResourceData({ ...newServiceResourceData, note_yes: event.target.value })
                      }
                    />
                  </div>

                  <div style={{ width: '100%' }} className="field">
                    <span
                      style={{
                        width: '40px',
                        marginRight: '20px'
                      }}
                    >
                      No:
                    </span>

                    <textarea
                      disabled={!editing}
                      style={{ resize: 'none', minHeight: '75px' }}
                      value={newServiceResourceData.note_no || ''}
                      onChange={(event) =>
                        setNewServiceResourceData({ ...newServiceResourceData, note_no: event.target.value })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Info</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Date Created:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, serviceResourceData.service_resource[0].created_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Created by:</span>
                      <input type="text" defaultValue={serviceResourceData.service_resource[0].created_by} disabled={true} />
                    </div>
                  </div>


                  <div className="__right">
                    <div className="field">
                      <span>Last Edited:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, serviceResourceData.service_resource[0].updated_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Last Edited by:</span>
                      <input type="text" defaultValue={serviceResourceData.service_resource[0].updated_by} disabled={true} />
                    </div>
                  </div>
                </div>
              </div>

              {editing ? (
                <div className="wrapper flex-container sb editing-buttons">
                  <div>
                    {
                      serviceResourceData.permissions.service_resource_delete &&
                      <button className="_red" onClick={() => setDeleting(true)}>Delete Service Resource</button>
                    }
                  </div>
                  <div className="buttons">
                    <button
                      className="_bordered _red"
                      onClick={() => handleCancelEdit()}
                    >
                      Cancel
                    </button>

                    <button
                      className="_bordered _green"
                      disabled={!readyToSave || isSaving}
                      onClick={() => handleSave()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : null}

              {/* Item share popup */}
              {deleting && serviceResourceData.permissions.service_resource_delete ? (
                <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                  <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                    <div className="title">
                      Delete ServiceResource
                    </div>

                    <div className="checkbox-wrapper">
                      <Checkbox contents="I understand that after deleting the service resource it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                    </div>

                    <div className="buttons">

                      <button className="_bordered _green" onClick={() => setDeleting(false)}>
                        Cancel
                      </button>

                      <button
                        className="_bordered _red"
                        disabled={!readyToDelete || isSaving}
                        onClick={() => handleRemoveItem()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Map Modal */}
              {
                openPostalCodeMapModal &&
                <div
                  className="item-delete-popup"
                  style={{ zIndex: '99' }}
                  onClick={() => setOpenPostalCodeMapModal(false)}
                >
                  <div
                    className="wrapper"
                    style={{ maxWidth: '65%' }}
                    onClick={(e) => e.stopPropagation()}
                  >

                    <ViewMap
                      selectedZipCode={zipCodesView.filter(item => item.isHave)}
                      isLoaded={isLoaded}
                      loadError={loadError}
                    />
                  </div>
                </div>
              }
            </div>
          ) : null}
        </> :
        page === 'schedule' ?
          <ServiceResourceSchedule
            service_resources={serviceResourceData?.edit.service_resources || []}
            handleBackServiceResources={handleBackServiceResources}
            permissions={serviceResourceData?.permissions}
            name={newServiceResourceData.code ? `${newServiceResourceData.name} (${newServiceResourceData.code})` : newServiceResourceData.name}
          /> :
          <ServiceResourceRestrictions
            service_resources={serviceResourceData?.edit.service_resources || []}
            handleBackServiceResources={handleBackServiceResources}
            permissions={serviceResourceData?.permissions}
            name={newServiceResourceData.code ? `${newServiceResourceData.name} (${newServiceResourceData.code})` : newServiceResourceData.name}
          />
    }
  </>)
}

export default ServiceResourcesPage_Item
