import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'
import moment, { Moment } from 'moment'
import Datetime from 'react-datetime'

import Icon from '../../../components/Icon'
import Checkbox from '../../../components/Checkbox'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../../funcs'
import { useAppSelector } from '../../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ItemProps
}

interface ItemProps {
  dispatcher_absence: DispatcherAbsenceProps
  permissions: {
    absence_dispatcher_delete: boolean
    absence_dispatcher_edit: boolean
  }
}

interface DispatcherAbsenceProps {
  absence_end: Date | Moment | string
  absence_start: Date | Moment | string
  area_id: string
  created_at: string
  created_by: string
  dispatcher_absence_id: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  note: string
  updated_at: string
  updated_by: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [loading, setLoading] = useState(false)

  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<ItemProps | null>(null)
  const [dispatcherAbsence, setDispatcherAbsence] = useState<Partial<DispatcherAbsenceProps>>({
  })

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Load info function
  async function loadInfo() {
    try {
      setLoading(true)
      // https://2022back4.artemiudintsev.com/api/dispatchers/absences/{ABSENCE_DISPATCHER_ID}?account_id=88888
      const { data: { data: absenceData, success, error } } = await httpClientUpdate.get('/dispatchers/absences/' + $router.router.getState().params.absenceId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }
      if (success) {
        let dateStart = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.dispatcher_absence.absence_start as string)
        let dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absenceData.dispatcher_absence.absence_end as string)

        setReportData(absenceData)
        setDispatcherAbsence({
          ...absenceData.dispatcher_absence,
          absence_start: dateStart,
          absence_end: dateEnd,
        })
        setLoading(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
        setLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setLoading(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    setLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/dispatchers/absences/{ABSENCE_DISPATCHER_ID}
      const response = await httpClientUpdate.delete(`/dispatchers/absences/${$router.router.getState().params.absenceId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setLoading(false)
        $router.router.navigate('call_center.absence', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  // update document
  async function handleSave() {
    setLoading(true)
    try {
      const start = moment.isMoment(dispatcherAbsence.absence_start) ? moment(dispatcherAbsence.absence_start).format('MM/DD/YYYY hh:mma') : dispatcherAbsence.absence_start
      const end = moment.isMoment(dispatcherAbsence.absence_end) ? moment(dispatcherAbsence.absence_end).format('MM/DD/YYYY hh:mma') : dispatcherAbsence.absence_end

      // https://2022back4.artemiudintsev.com/api/dispatchers/absences/{ABSENCE_DISPATCHER_ID}
      const response = await httpClientUpdate.put(`/dispatchers/absences/${$router.router.getState().params.absenceId}`, {
        account_id: activeAccountId,
        area_id: dispatcherAbsence.area_id,
        dispatcher_id: dispatcherAbsence.dispatcher_id,
        absence_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, start as string),
        absence_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, end as string),
        note: dispatcherAbsence.note,
      })

      if (response.data.success) {
        setLoading(false)
        setEditing(false)
        loadInfo()
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      {
        reportData &&
        <div className="CallCenter_CallGroupsPage_Item entity-edit">

          {/* Top navigation */}
          <div className="top-nav">
            <BaseLink
              router={$router.router}
              routeName="call_center.phoneNumbers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
            >
              <Icon icon="phone-1" />
              <span>Phone Numbers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.dispatchers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
            >
              <Icon icon="user-1" />
              <span>Dispatchers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.additionalTime"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="time-22" />
              <span>Additional Time</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.absence"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.absence" })}
            >
              <Icon icon="time-16" />
              <span>Absence</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.groups" })}
            >
              <Icon icon="user-29" />
              <span>Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callFlows"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
            >
              <Icon icon="share-7" />
              <span>Call Flows</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.caller_groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
            >
              <Icon icon="user-29" />
              <span>Caller Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.temporaryClosed"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="close-664" />
              <span>Temporary Closed</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callCenters"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
            >
              <Icon icon="delivery-8" />
              <span>Call Centers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.audioLibrary"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="audio-15" />
              <span>Audio Library</span>
            </BaseLink>
          </div>

          <div className="wrapper flex-container sb">
            <h1>Dispatcher Absence</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.absence',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  reportData?.permissions.absence_dispatcher_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Dispatcher:</span>

                  <input
                    type="text"
                    defaultValue={dispatcherAbsence.dispatcher_nickname ? `${dispatcherAbsence.dispatcher_nickname} (${dispatcherAbsence.dispatcher_code})` : dispatcherAbsence.dispatcher_nickname}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Absence start:</span>
                  <Datetime
                    dateFormat="MM/DD/YYYY"
                    timeFormat="hh:mma"
                    inputProps={{ disabled: !editing }}
                    value={dispatcherAbsence.absence_start as Date}
                    onChange={(value) => setDispatcherAbsence({ ...dispatcherAbsence, absence_start: value })}
                  />
                </div>

                <div className="field">
                  <span>Absence end:</span>

                  <Datetime
                    dateFormat="MM/DD/YYYY"
                    timeFormat="hh:mma"
                    inputProps={{ disabled: !editing }}
                    value={dispatcherAbsence.absence_end as Date}
                    onChange={(value) => setDispatcherAbsence({ ...dispatcherAbsence, absence_end: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Note</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Note:</span>

                  <input type="text"
                    disabled={!editing}
                    value={dispatcherAbsence.note || ''}
                    onChange={({ target: { value } }) => setDispatcherAbsence({ ...dispatcherAbsence, note: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.dispatcher_absence.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.dispatcher_absence.created_by}
                    disabled={true}
                  />
                </div>
              </div>


              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.dispatcher_absence.updated_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.dispatcher_absence.updated_by}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  // callGroupData.permissions.call_group_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Dispatcher Absence</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => $router.router.navigate('call_center.absence', {
                    companyId: activeAccountId
                  }, { reload: true })}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _green"
                  disabled={loading}
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {/* Item deleting popup */}
          {/* {callGroupData.permissions.call_group_delete && */}
          {deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Dispatcher Absence
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the dispatcher absence it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    disabled={loading}
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}

        </div>
      }
    </>
  )
}