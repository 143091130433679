import { useState } from 'react'
import moment from 'moment'

import { ChangeAbsenceProps } from './GantChart'

interface AbsenceProps {
  absence: ChangeAbsenceProps
  setAbsence: (value: ChangeAbsenceProps | null) => void
}

export default function CancelAbsence({ absence, setAbsence }: AbsenceProps) {
  const [check, setCheck] = useState(false)

  function getAbsenceInfo() {
    let serviceName = absence.service_resource_code ? `${absence.service_resource_nickname}(${absence.service_resource_code})` : absence.service_resource_nickname
    return `${moment(absence.absence_start, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} - ${moment(absence.absence_end, "MMMM DD, YYYY, h:mmA").format("hh:mma D MMM")} ${serviceName}`
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '90vh',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99999',
      }}>
      <div className='absence-cancel-confirmation absence-cancel-confirmation-popup open visible'>
        <div className='absence-cancel-confirmation__header'>
          <div className='absence-cancel-confiramtion__header-text'>
            <p>Cancel an Absence</p>
          </div>

          <div className='absence-cancel-confirmation__absence-info'>
            <div className='absence-cancel-confirmation__absence-info-item'>
              <p> {getAbsenceInfo()} </p>
            </div>
          </div>
        </div>

        <div className='absence-cancel-confirmation__cancel-failed'>
          <p className='absence-cancel-confirmation__cancel-failed-text'>
            This absence can't be canceled
          </p>
        </div>

        <div className='absence-cancel-confirmation__questions-wrapper'>
          <div className='absence-cancel-confirmation__questions-item absence-cancel-confirmation__questions-item_cancel-reason'>
            <p className='absence-cancel-confirmation__questions-item_cancel-reason'>
              Reason of cancelation:
            </p>

            <textarea
              className='absence-cancel-confirmation__questions-item_cancel-reason-textarea'
            >

            </textarea>
          </div>

          <div className='absence-cancel-confirmation__questions'>
            <div className='absence-cancel-confirmation__questions-item absence-cancel-confirmation__questions-item_service-resource-informed'>
              <label
                className='questions-item'
                onClick={() => setCheck(!check)}
              >
                I understand that the technician must be informed of the changes
                <span className='questions-item__checkmark'>
                  {
                    check &&
                    <span className='questions-item__checkmark-active'></span>
                  }
                </span>
              </label>
            </div>
          </div>

          <div className='absence-cancel-confirmation__btns'>
            <div
              className='absence-cancel-confirmation__deny-btn'
              onClick={() => setAbsence(null)}
            >
              Cancel
            </div>

            <div className='absence-cancel-confirmation__confirm-btn btn_disabled'>
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
