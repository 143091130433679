import { useState, memo } from 'react';
import copy from 'copy-to-clipboard';

import Icon from '../../components/Icon';
import NewMap from './RenderMap';

import './Tools.sass'

function ServiceAreaMap() {
  const [selectedZipCode, setSelectedZipCode] = useState<string[]>([])

  const [addNewZip, setAddNewZip] = useState('')
  const [viewAddress, setViewAddress] = useState<{ name: string, value: string } | null>(null)

  const [isCenterZips, setIsCenterZips] = useState(0)

  function handleAddNewZip() {
    let updated = selectedZipCode.map(item => item)

    if (addNewZip.includes(',')) {
      let zips = addNewZip.replace(/,/g, " ").split(' ').filter(item => item && item.trim())

      zips.forEach(item => {
        if (!updated.includes(item) && item.length === 5 && !isNumeric(item)) {
          updated.push(item)
        }
      })

      setSelectedZipCode(updated)
    } else {
      if (addNewZip.length === 5 && !isNumeric(addNewZip)) {
        updated.push(addNewZip)
        setSelectedZipCode(updated)
      }
    }

    setAddNewZip('')
  }

  function removeZip(i: number) {
    setSelectedZipCode(selectedZipCode.filter((item, index) => index !== i))
  }

  function handleClear() {
    setSelectedZipCode([])
  }


  function isNumeric(str: any) {
    return isNaN(str);
  }

  function handleAddView() {
    if (isNumeric(addNewZip)) {
      setViewAddress({ name: 'locality', value: addNewZip })
    } else {
      setViewAddress({ name: 'postalCode', value: addNewZip })
    }
    setAddNewZip('')
  }

  async function handleCopy() {
    copy(selectedZipCode.join(', '))
  }

  async function handleCheckView() {
    setIsCenterZips(Math.random())
  }

  return (
    <div className="tools">
      <div className='tools-item'>
        <NewMap
          selectedZipCode={selectedZipCode}
          setSelectedZipCode={setSelectedZipCode}
          viewAddress={viewAddress}
          isCenterZips={isCenterZips}
        />
      </div>

      <div className='tools-item'>
        <div className='add-zip'>
          <input
            type="text"
            value={addNewZip}
            onChange={(event) => setAddNewZip(event.target.value)}
          />

        </div>
        <div className='add-zip-btns'>
          <button
            disabled={addNewZip === '' || addNewZip.length < 5 || selectedZipCode.includes(addNewZip)}
            className="_bordered _green"
            onClick={() => handleAddNewZip()}
          >
            Add
          </button>

          <button
            disabled={addNewZip === ''}
            className="_bordered _green"
            onClick={() => handleAddView()}
          >
            View
          </button>

          <button
            disabled={!selectedZipCode.length}
            className="_bordered _green"
            onClick={() => handleCheckView()}
          >
            Check
          </button>

          <button
            disabled={!selectedZipCode.length}
            className="_bordered _red"
            onClick={() => handleClear()}
          >
            Clear
          </button>

          <button
            className='copy_zips'
            disabled={!selectedZipCode.length}
            onClick={() => handleCopy()}
            title="Copy zips"
          >
            <Icon
              style={{
                width: '24px',
                height: '24px',
                verticalAlign: 'middle',
                overflow: 'hidden',
                fill: 'inherit'
              }}
              viewBox="0 0 1024 1024"
              icon="copy-1"
            />
          </button>
        </div>


        <div className='zip-view'>

          {
            selectedZipCode.map((item, i) => (
              <div key={i} className='zip-view-item'>
                <span>{item}</span>

                <button
                  className="_zeroed _iconed _red"
                  onClick={() => removeZip(i)}
                >
                  <Icon icon="x-mark-1" />
                </button>
              </div>
            ))
          }
        </div>
      </div>

    </div>
  )
}

export default memo(ServiceAreaMap)