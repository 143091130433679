import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../../components/Icon"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"

import { httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from "../../../store/hooks"

import { CallCenter_Dispatcher } from "../../../models/CallCenter"
import { CallGroupsReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallGroupsReport
}

const emptyDispatcher: CallCenter_Dispatcher = {
  dispatcher_id: '',
  name: '',
  user: '',
  user_id: '',
  phone: '',
  is_phone: false,
  is_softphone: false,
  active: 'Active',
  call_groups: '',
  "created_at": "05/04/2021 09:32am",
  "created_by": "Alex",
  "updated_at": "05/04/2021 10:34am",
  "updated_by": "Alex",
  dispatcher_code: 0,
  mentor: '',
  mentor_id: ''
}

function CallCenter_CallGroupsPage_New() {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dispatchers, setDispatchers] = useState<CallCenter_Dispatcher[] | null>(null)
  const [availableDispatchers, setAvailableDispatchers] = useState<CallCenter_Dispatcher[]>([])
  const [selectedDispatcher, setSelectedDispatcher] = useState<CallCenter_Dispatcher>(emptyDispatcher)
  const [newCallGroupData, setNewCallGroupData] = useState({
    name: '',
    dispatchers: [] as CallCenter_Dispatcher[]
  })

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.post(`/callcenter/callgroups`, qs.stringify({
        account_id: activeAccountId,
        name: newCallGroupData.name,
        dispatchers: JSON.stringify(newCallGroupData.dispatchers.map(item => item.dispatcher_id))
      }))
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.groups', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: callGroupsReport, success, error } } = (await httpClientUpdate.post('/callcenter/callgroups/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (callGroupsReport.permissions.call_group_add) {
          setDispatchers(callGroupsReport.edit.dispatchers)
          setAvailableDispatchers([emptyDispatcher].concat(callGroupsReport.edit.dispatchers))
          setSelectedDispatcher([emptyDispatcher].concat(callGroupsReport.edit.dispatchers)[0])
        } else {
          $router.router.navigate(`${error.code}`, {
            reload: true
          })
        }
        setTimeout(() => setReadyToSave(false), 100)
      } else {
        $router.router.navigate(`403`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load callGroup data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add dispatcher function
  function addDispatcher() {

    if (!newCallGroupData.dispatchers || !newCallGroupData) return

    const newDispatchersList = [
      ...newCallGroupData.dispatchers,
      selectedDispatcher
    ]

    setNewCallGroupData({
      ...newCallGroupData,
      dispatchers: newDispatchersList
    })

    let _availableDispatchers = [...availableDispatchers]

    _availableDispatchers = _availableDispatchers.filter(($dispatcher) => {

      return newDispatchersList.findIndex((dispatcher) => {
        return dispatcher.name === $dispatcher.name
      }) === -1
    })

    setAvailableDispatchers([
      ..._availableDispatchers
    ])

    setSelectedDispatcher(emptyDispatcher)

    setReadyToSave(true)
  }

  // Remove dispatcher function
  function removeDispatcher(i: number) {

    let _callGroupDispatchers = [...newCallGroupData.dispatchers]

    let _dispatcher = _callGroupDispatchers[i]

    _callGroupDispatchers.splice(i, 1)

    setNewCallGroupData({
      ...newCallGroupData,
      dispatchers: _callGroupDispatchers
    })

    setAvailableDispatchers([
      ...availableDispatchers,
      _dispatcher
    ])

    setReadyToSave(true)
  }

  useEffect(() => setReadyToSave(true), [
    newCallGroupData
  ])

  // Render function
  return (<>
    {true ? (
      <div className="CallCenter_CallGroupsPage_New entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>New Call Group</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('call_center.groups', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewCallGroupData({ ...newCallGroupData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="flex-container sb wrap">
            <div className="legend">Dispatchers</div>

            <div className="legend-action-wrapper">
              <label>Dispatcher:</label>
              <div className="input-wrapper">
                <Select options={availableDispatchers.map((dispatcher) => ({
                  span: dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name,
                  value: dispatcher.name
                }))} selectedOption={selectedDispatcher.name} onChange={(value) => setSelectedDispatcher(availableDispatchers.find((_dispatcher) => _dispatcher.name === value) as CallCenter_Dispatcher)} />
                <button disabled={!selectedDispatcher.name} className="_green" onClick={() => addDispatcher()}>
                  Add
                </button>
              </div>
            </div>
          </div>

          <table className={classNames('table', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr>
              <th>Name</th>
              <th>User</th>
              <th>Phone</th>
              <th>Phone</th>
              <th>Softphone</th>
              <th>Active</th>
              <th></th>
            </tr>
            {(newCallGroupData.dispatchers as CallCenter_Dispatcher[]).map((dispatcher, i) => (
              <tr key={i}>
                <td>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</td>
                <td>{dispatcher.user}</td>
                <td>{dispatcher.phone}</td>
                <td>
                  <Checkbox contents={''} value={dispatcher.is_phone} />
                </td>
                <td>
                  <Checkbox contents={''} value={dispatcher.is_softphone} />
                </td>
                <td className={classNames({
                  'red-text': dispatcher.active === 'Off',
                  'green-text': dispatcher.active === 'On',
                })}>{dispatcher.active}</td>
                <td>
                  <button className="_zeroed _iconed _red" onClick={(event) => {
                    event.stopPropagation()
                    removeDispatcher(i)
                  }
                  }>
                    <Icon icon="x-mark-1" />
                  </button>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {newCallGroupData.dispatchers.map((dispatcher, i: number) => (
                <div className="item" key={i} onClick={() => $router.router.navigate('call_center.dispatchers.item', {}, { reload: true })}>

                  <div className="__top">

                    <div className="__left">
                      <div><b>{dispatcher.dispatcher_code ? `${dispatcher.name} (${dispatcher.dispatcher_code})` : dispatcher.name}</b></div>
                      <div>
                        {dispatcher.user}
                      </div>
                    </div>

                    <div className="__right small">
                      <div className={classNames({
                        'red-text': dispatcher.active === 'Inactive',
                        'green-text': dispatcher.active === 'Active',
                      })}>
                        {dispatcher.active}
                      </div>
                      <div>
                        {dispatcher.phone}
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">

                    <div className="__left">
                      <div className="flex-container">
                        <span className="gray">Phone:</span>
                        <span> <Checkbox contents='' value={dispatcher.is_phone} /></span>
                      </div>
                      <div className="flex-container">
                        <span className="gray">Softphone:</span>
                        <span> <Checkbox contents='' value={dispatcher.is_softphone} /></span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <button
                          className="_zeroed _iconed _red"
                          onClick={(e) => { e.stopPropagation(); removeDispatcher(i) }}
                        >
                          <Icon icon="x-mark-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('call_center.groups', {
              companyId: activeAccountId
            }, {
              reload: true
            })}>
              Cancel
            </button>
            <button
              className="_bordered _green"
              disabled={!readyToSave || !!errorFields.length || isSaving}
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null
    }
  </>)
}

export default CallCenter_CallGroupsPage_New
