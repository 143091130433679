import { useState } from 'react'

import Checkbox from '../../components/Checkbox'
import { dateToInfoBlock } from '../../funcs';

interface DragAppointmentProps {
  handleSaveDragAppointment: () => void,
  handleCancelDragAppoinyment: () => void,
  appointment: {
    appointment_date_end: string,
    appointment_date_start: string,
    appointment_id: string | null,
    appointment_job_number: string,
    nameFrom?: string,
    appointment_update_time: string,
    nameTo: string,
    timeZone: string,
  },
}

export default function DragAppointment({
  appointment,
  handleSaveDragAppointment,
  handleCancelDragAppoinyment
}: DragAppointmentProps) {
  const [readyToDelete, setReadyToDelete] = useState({
    customer: false,
    technician: false,
    client: false,
    service: false
  })

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '90vh',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99999',
      }}
      className="item-delete-popup"
      onClick={() => handleCancelDragAppoinyment()}
    >

      <div
        style={{
          backgroundColor: 'white',
          // padding: '1em',
          width: '400px',
        }}
        className="wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className='drag-and-drop-confirmation__header' >
          <p className='drag-and-drop-confirmation__job-number'>
            {appointment.appointment_job_number}
          </p>

          <div className='drag-and-drop-confiramtion__header-text'>
            <p>
              Reschedule an Appointment
            </p>
          </div>

          <div className='drag-and-drop-confirmation__appointment-info'>
            <div className='drag-and-drop-confirmation__appointment-info-item'>
              <span>From:</span>
              <div className='drag-and-drop-confirmation__date-old'>
                {dateToInfoBlock('HH:mm, dd LLL', appointment.timeZone, appointment.appointment_date_start)} - {dateToInfoBlock('HH:mm, dd LLL', appointment.timeZone, appointment.appointment_date_end)}
              </div>
              <p className='drag-and-drop-confirmation__service-resource-nickname-old'>
                ({appointment.nameFrom})
              </p>
            </div>

            <div className='drag-and-drop-confirmation__appointment-info-item'>
              <span>To:</span>
              <div className='drag-and-drop-confirmation__date-new'>
                {appointment.appointment_update_time}
              </div>
              <p className='drag-and-drop-confirmation__service-resource-nickname-new'>
                ({appointment.nameTo})
              </p>
            </div>
          </div>
        </div>

        <div className='appointment-cancel-confirmation__questions'>
          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_client-informed'>
            <Checkbox
              contents="I understand that the customer must be informed of the changes"
              value={readyToDelete.customer}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                customer: value
              })}
            />
          </div>

          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_service-resource-informed'>
            <Checkbox
              contents="I understand that the technician must be informed of the changes"
              value={readyToDelete.technician}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                technician: value
              })}
            />
          </div>
        </div>

        <div className='appointment-cancel-confirmation__questions'>
          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_client-notification'>
            <Checkbox
              contents="Send notification client"
              value={readyToDelete.client}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                client: value
              })}
            />
          </div>

          <div className='appointment-cancel-confirmation__questions-item appointment-cancel-confirmation__questions-item_service-resource-notification'>
            <Checkbox
              contents="Send notification service resource"
              value={readyToDelete.service}
              onChange={(value) => setReadyToDelete({
                ...readyToDelete,
                service: value
              })}
            />
          </div>
        </div>

        <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', width: '80%' }} className="buttons">
          <button
            style={{ width: '40%' }}
            className="_bordered _green"
            onClick={() => handleCancelDragAppoinyment()}
          >
            Cancel
          </button>

          <button
            style={{ width: '40%' }}
            disabled={!readyToDelete.customer || !readyToDelete.technician}
            className="_bordered _red"
            onClick={() => handleSaveDragAppointment()}
          >
            Save
          </button>
        </div>
      </div>
    </div >
  )
}
