import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"
import Switcher from "../../components/Switcher";
import ReportTableControls from "../../components/reports/TableControls";
import SelectComponent from "../../components/Select";

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ItemData
}

interface HttploadUsersReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UsersData
}

interface ItemData {
  permissions: {
    chat_channel_delete: boolean
    chat_channel_edit: boolean
  },

  chat_channel: ChatChannelProps,

  edit: {
    users: EditUserProps[]
  }
}

interface EditUserProps {
  first_name: string
  last_name: string
  user_id: string
}

interface UsersData {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    chat_channel_user_add: boolean
    chat_channel_user_show: boolean
  },

  chat_channel_users: ChatChannelUsersProps[]
}

interface ChatChannelUsersProps {
  channel_id: string
  channel_user_id: string
  created_at: string
  created_by: string
  is_read_only: boolean
  time_for_edit: number
  can_delete: boolean
  updated_at: string
  updated_by: string
  user_id: string
  user_name: string
}

interface ChatChannelProps {
  channel_id: string
  created_at: string
  created_by: string
  is_archive: boolean
  name: string
  updated_at: string
  updated_by: string
}

function StaffPage_Item({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [$updater, $setUpdater] = useState<any>(Math.random())
  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [usersOptions, setUsersOptions] = useState<EditUserProps[]>([])

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [isAddNewUser, setIsAddNewUser] = useState(false)
  const [editUser, setEditUser] = useState<ChatChannelUsersProps | null>(null)

  const [newUser, setNewUser] = useState({
    id: '',
    chat_name: '',
    read_only: false,
    time_for_edit: 30,
    can_delete: false,
  })

  const [reportData, setReportData] = useState<ItemData | null>(null)
  const [reportUsersData, setReportUsersData] = useState<UsersData | null>(null)

  const [newData, setNewData] = useState<Partial<ChatChannelProps>>({
    name: '',
  })

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    max_rows: 100 as 50 | 100 | 250 | 500,
    filter_words: {
      source: [] as string[],
    },
    sort: {
      field: 'name',
      direction: 'down' as 'up' | 'down'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Users Count',
      value: 'users_count'
    }, {
      span: 'Is Archive',
      value: 'is_archive'
    }, {
      span: 'Created By',
      value: 'created_by'
    }]
  })

  function handleCancelEdit() {
    setEditing(false)

    if (reportData) {

      setNewData({
        ...reportData.chat_channel
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/chats/channels/88888021ohz4dtlk85
      const response = await httpClientUpdate.delete(`/chats/channels/${$router.router.getState().params.chatChannelId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('chat-channels', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/staff/888880089ndbv4lug2
      const response = await httpClientUpdate.put(`/chats/channels/${$router.router.getState().params.chatChannelId}`, qs.stringify({
        account_id: activeAccountId,
        nickname: newData.name,
        is_archive: newData.is_archive ? 1 : 0,
      }, { skipNulls: true }))
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadChannel()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadChannel() {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/888887101peh4cknvy?account_id=88888
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/chats/channels/' + $router.router.getState().params.chatChannelId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(loadData)
        setNewData({
          ...loadData.chat_channel
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function loadUsers() {
    try {
      // https://2022back4.artemiudintsev.com/api/chats/channels/users/report
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/chats/channels/users/report', {
        params: {
          account_id: activeAccountId,
          channel_id: $router.router.getState().params.chatChannelId,
          page: 1,
          limit_rows: 500,
        }
      }) as { data: HttploadUsersReport }
      if (success) {
        setReportUsersData({
          ...loadData,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleAddNewUser() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/chats/channels/users
      const response = await httpClientUpdate.post(`/chats/channels/users`, {
        account_id: activeAccountId,
        channel_id: $router.router.getState().params.chatChannelId,
        user_id: newUser.id,
        user_name: newUser.chat_name,
        is_read_only: newUser.read_only ? 1 : 0,
        can_delete: newUser.can_delete ? 1 : 0,
        time_for_edit: newUser.time_for_edit,
      })
      if (response.data.success) {
        setIsSaving(false)
        setIsAddNewUser(false)
        setNewUser({
          id: '',
          chat_name: '',
          read_only: false,
          time_for_edit: 0,
          can_delete: false,
        })
        loadUsers()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleUpdateUser() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/chats/channels/users/{{CHANNEL_USER_ID}}
      const response = await httpClientUpdate.put(`/chats/channels/users/${editUser?.channel_user_id}`, {
        account_id: activeAccountId,
        user_name: editUser?.user_name,
        is_read_only: editUser?.is_read_only ? 1 : 0,
        can_delete: editUser?.can_delete ? 1 : 0,
        time_for_edit: editUser?.time_for_edit,
      })
      if (response.data.success) {
        setIsSaving(false)
        setEditUser(null)
        loadUsers()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function removeUser(id: string) {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/chats/channels/users/{{CHANNEL_USER_ID}}
      const response = await httpClientUpdate.delete(`/chats/channels/users/${id}`, {
        data: {
          account_id: activeAccountId
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        loadUsers()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    if (updated) {
      setReportData(null)
      setReportUsersData(null)

      loadChannel()
      loadUsers()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    setReportData(null)
    setReportUsersData(null)

    loadChannel()
    loadUsers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  useEffect(() => {
    if (reportData && reportUsersData) {
      const activeUsers = reportUsersData.chat_channel_users.map(item => item.user_id)

      if (reportData) {
        setUsersOptions(reportData.edit.users.filter(item => !activeUsers.includes(item.user_id)))
      }
    }
  }, [reportData, reportUsersData])

  function handleCancelAddNewUser() {
    setIsAddNewUser(false)
    setNewUser({
      id: '',
      chat_name: '',
      read_only: false,
      time_for_edit: 0,
      can_delete: false,
    })
  }

  function handleCancelEditUser() {
    setEditUser(null)
  }

  function getName(id: string) {
    let name = ''

    reportData && reportData.edit.users.forEach(item => {
      if (item.user_id === id) {
        name = `${item.first_name} ${item.last_name}`
      }
    })

    return name
  }

  // Render function
  return (<>
    {reportData && reportUsersData ? (
      <div className="StaffPage_Item entity-edit">
        <div className="wrapper flex-container sb">
          <h1>Chat Channel</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'chat-channels',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                reportData?.permissions.chat_channel_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>

                <input
                  type="text"
                  disabled={!editing}
                  value={newData.name}
                  onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Is Archive:</span>

                <Switcher
                  disabled={!editing}
                  checked={newData.is_archive}
                  onChange={(value) => setNewData({ ...newData, is_archive: value })}
                />
              </div>
            </div>
          </div>
        </div>

        {
          editUser &&
          <div className="fieldset">
            <div className="legend">User: {getName(editUser.user_id)} </div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Chat Name:</span>

                  <input
                    type="text"
                    value={editUser.user_name}
                    onChange={({ target: { value } }) => setEditUser({ ...editUser, user_name: value })}
                  />
                </div>

                <div className="field">
                  <span>Read Only:</span>

                  <Switcher
                    checked={editUser.is_read_only}
                    onChange={(value) => setEditUser({ ...editUser, is_read_only: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Time For Edit:</span>

                  <input
                    type="number"
                    className="appearance"
                    value={editUser.time_for_edit ? editUser.time_for_edit : 0}
                    onChange={({ target: { value } }) => setEditUser({ ...editUser, time_for_edit: +value })}
                  />
                </div>

                <div className="field">
                  <span>Can Delete:</span>

                  <Switcher
                    checked={editUser.can_delete}
                    onChange={(value) => setEditUser({ ...editUser, can_delete: value })}
                  />
                </div>
              </div>
            </div>

            <div
              style={{ display: 'flex', marginTop: '10px', justifyContent: 'flex-end' }}
            >
              <button
                className="_bordered _red"
                disabled={isSaving}
                style={{ width: 'fit-content', marginRight: '20px' }}
                onClick={handleCancelEditUser}
              >
                <span>Cancel</span>
              </button>

              <button
                className="_bordered _green"
                disabled={isSaving || editUser.user_name === ''}
                style={{ width: 'fit-content' }}
                onClick={handleUpdateUser}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        }

        {
          isAddNewUser &&
          <div className="fieldset">
            <div className="legend">Add User</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>User:</span>

                  <SelectComponent
                    options={usersOptions.map((option) => ({
                      span: `${option.first_name} ${option.last_name}`,
                      value: option.user_id
                    }))}
                    selectedOption={newUser.id}
                    onChange={(value: string) => setNewUser({ ...newUser, id: value })}
                  />
                </div>

                <div className="field">
                  <span>Chat Name:</span>

                  <input
                    type="text"
                    value={newUser.chat_name}
                    onChange={({ target: { value } }) => setNewUser({ ...newUser, chat_name: value })}
                  />
                </div>

                <div className="field">
                  <span>Read Only:</span>

                  <Switcher
                    checked={newUser.read_only}
                    onChange={(value) => setNewUser({ ...newUser, read_only: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Time For Edit:</span>

                  <input
                    type="number"
                    className="appearance"
                    value={newUser.time_for_edit}
                    onChange={({ target: { value } }) => setNewUser({ ...newUser, time_for_edit: +value })}
                  />
                </div>

                <div className="field">
                  <span>Can Delete:</span>

                  <Switcher
                    checked={newUser.can_delete}
                    onChange={(value) => setNewUser({ ...newUser, can_delete: value })}
                  />
                </div>
              </div>
            </div>

            <div
              style={{ display: 'flex', marginTop: '10px', justifyContent: 'flex-end' }}
            >
              <button
                className="_bordered _red"
                disabled={isSaving}
                style={{ width: 'fit-content', marginRight: '20px' }}
                onClick={handleCancelAddNewUser}
              >
                <span>Cancel</span>
              </button>

              <button
                className="_bordered _green"
                disabled={newUser.id === '' || newUser.chat_name === '' || isSaving}
                style={{ width: 'fit-content' }}
                onClick={handleAddNewUser}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        }

        <div className="fieldset">
          <div className="legend">Users</div>

          <ReportTableControls
            zIndex={5}

            onMaxRowsChange={() => {
              $setUpdater(Math.random());
            }}

            amount={{
              total: reportUsersData.interface.rows_all,
              start: reportUsersData.interface.rows_start,
              end: reportUsersData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportUsersData.interface.max_pages}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportUsersData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportUsersData.permissions.chat_channel_user_add && !isAddNewUser && editUser === null ?
                (
                  <div className="add-button-wrapper">
                    <button
                      disabled={isSaving}
                      className="_iconed _rounded add-button"
                      onClick={() => setIsAddNewUser(true)}
                    >
                      <Icon icon="plus-thin" />
                      <span>Add User</span>
                      <Icon icon="user-1" />
                    </button>
                  </div>
                ) : (
                  <></>
                )
            }
          />

          <div className="contents">
            <table
              className="table"
              style={{ marginBottom: '30px' }}
            >
              <thead>
                <tr>
                  <th>Name For Channel</th>
                  <th> Name </th>
                  <th> Can Delete </th>
                  <th> Read Only </th>
                  <th> Time For Edit </th>
                  <th>  </th>
                  <th>  </th>
                </tr>
              </thead>

              {
                reportUsersData.chat_channel_users.map(user => (
                  <tr
                    key={user.user_id}
                  >
                    <td> {user.user_name} </td>
                    <td> {getName(user.user_id)} </td>

                    <td style={{ color: !!user.can_delete ? '#01B41F' : '#EB5757' }}>
                      {user.can_delete ? 'On' : 'Off'}
                    </td>

                    <td style={{ color: !!user.is_read_only ? '#01B41F' : '#EB5757' }}>
                      {user.is_read_only ? 'On' : 'Off'}
                    </td>

                    <td> {user.time_for_edit} </td>

                    <td>
                      <button
                        disabled={editUser !== null || isSaving || isAddNewUser}
                        className="_zeroed _iconed _blue"
                        onClick={() => setEditUser(user)}
                      >
                        <Icon icon="pencil-14" />
                      </button>
                    </td>

                    <td>
                      <button
                        disabled={editUser !== null || isSaving || isAddNewUser}
                        className="_zeroed _iconed _red"
                        onClick={() => removeUser(user.channel_user_id)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  </tr>
                ))
              }
            </table>
          </div>

          <ReportTableControls
            isUnder={true}

            onMaxRowsChange={() => $setUpdater(Math.random())}

            amount={{
              total: reportUsersData.interface.rows_all,
              start: reportUsersData.interface.rows_start,
              end: reportUsersData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportUsersData.interface.max_pages}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportUsersData.interface.max_pages })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.chat_channel.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={reportData?.chat_channel.created_by}
                  disabled={true}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.chat_channel.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={reportData?.chat_channel.updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.chat_channel_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Chat Channel</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {reportData.permissions.chat_channel_delete &&
          deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete Chat Channel
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the chat channel it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default StaffPage_Item
