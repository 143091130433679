import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'

import Icon from '../../../components/Icon'
import Checkbox from '../../../components/Checkbox'
import AudioPlayer from '../../../components/AudioPlayer'
import SelectComponent from '../../../components/Select'

import { dateToInfoBlock, getBase64FromRington, httpClientUpdate, nErrorUpdate } from '../../../funcs'
import { useAppSelector } from '../../../store/hooks'

import { UserGroupsProps } from './List'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  edit: {
    user_groups: UserGroupsProps[]
  }
  audio_library: AudioLibraryProps
  permissions: PermissionsProps
}

interface PermissionsProps {
  audio_library_add: boolean
  audio_library_delete: boolean
  audio_library_edit: boolean
  audio_library_report_show: boolean
  audio_library_show: boolean
}

interface AudioLibraryProps {
  audio_library_id: string
  audio_url: string
  created_at: string
  created_by: string
  description: string
  name: string
  updated_at: string
  updated_by: string
  user_groups: {
    audio_library_id: string
    laravel_through_key: string
    name: string
  }[]
}

const emptyUserGroups: UserGroupsProps = {
  name: '',
  user_group_id: '',
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [loading, setLoading] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isChangeFile, setIsChangeFile] = useState(false)

  const [audioPlayerShow, setAudioPlayerShow] = useState(false)

  const [reportData, setReportData] = useState<DataProps | null>(null)

  const [userGroups, setUserGroups] = useState<UserGroupsProps[]>([])
  const [availableUserGroups, setAvailableUserGroups] = useState<UserGroupsProps[]>([])
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupsProps>(emptyUserGroups)

  const [readyToDelete, setReadyToDelete] = useState(false)

  const [newData, setNewData] = useState<{ name: string, description: string, userGroups: UserGroupsProps[], audio_file: string | null, audio_url: string | null }>({
    name: '',
    description: '',
    userGroups: [],
    audio_file: null,
    audio_url: null,
  })

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Load info function
  async function loadInfo() {
    try {
      setLoading(true)
      // https://2022back4.artemiudintsev.com/api/audio-library/888889031dtz0rky8l?account_id=88888
      const { data: { data: responseData, success, error } } = await httpClientUpdate.get('/audio-library/' + $router.router.getState().params.audioLibraryId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }
      if (success) {
        let usersGroups = []

        usersGroups = responseData.edit.user_groups.filter((item) => {
          return responseData.audio_library.user_groups.filter((user) => {
            return user.name === item.name
          }).length === 1
        })

        setReportData(responseData)
        setNewData({
          ...newData,
          name: responseData.audio_library.name,
          description: responseData.audio_library.description,
          userGroups: usersGroups,
          audio_url: responseData.audio_library.audio_url,
          audio_file: null,
        })

        let _availableUsers = []

        _availableUsers = responseData.edit.user_groups.filter((item) => {
          return responseData.audio_library.user_groups.findIndex((user) => {
            return user.name === item.name
          }) === -1
        })

        setUserGroups(responseData.edit.user_groups)
        setAvailableUserGroups([emptyUserGroups].concat(_availableUsers))
        setSelectedUserGroups(emptyUserGroups)

        setLoading(false)

        setTimeout(() => setReadyToSave(false), 100)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
        setLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setLoading(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleRemoveItem() {
    setLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/audio-library/888889031dtz0rky8l
      const response = await httpClientUpdate.delete(`/audio-library/${$router.router.getState().params.audioLibraryId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setLoading(false)
        $router.router.navigate('call_center.audioLibrary', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleSave() {
    setLoading(true)
    try {
      let responseData: {
        account_id: string | null
        name: string
        description: string
        user_groups: string[]
        audio_file?: string | null
      } = {
        account_id: activeAccountId,
        name: newData.name,
        description: newData.description,
        user_groups: newData.userGroups.map(item => item.user_group_id)
      }

      if (!!newData.audio_file) {
        responseData.audio_file = newData.audio_file
      }

      // https://2022back4.artemiudintsev.com/api/audio-library/888889031dtz0rky8l
      const response = await httpClientUpdate.put(`/audio-library/${$router.router.getState().params.audioLibraryId}`, responseData)

      if (response.data.success) {
        setLoading(false)
        setEditing(false)
        setIsChangeFile(false)
        loadInfo()
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    setReadyToSave(true)
  }, [
    newData, userGroups
  ])

  function addUserGroups() {
    if (!userGroups.length) return

    const newUsersList = [
      ...newData.userGroups,
      selectedUserGroups
    ]

    setNewData({
      ...newData,
      userGroups: newUsersList
    })

    let _availableUserGroups = [...availableUserGroups]

    _availableUserGroups = _availableUserGroups.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user.name === $user.name
      }) === -1
    })

    setAvailableUserGroups([
      ..._availableUserGroups
    ])

    setSelectedUserGroups(emptyUserGroups)
  }

  // Remove dispatcher function
  function removeUserGroup(i: number) {

    let _callerGroupUsers = [...newData.userGroups]

    let _user = _callerGroupUsers[i]

    _callerGroupUsers.splice(i, 1)

    setNewData({
      ...newData,
      userGroups: _callerGroupUsers
    })

    setAvailableUserGroups([
      ...availableUserGroups,
      _user
    ])
  }

  function addFile(event: any) {
    if (event.target.files[0]) {
      setReadyToSave(true)

      getBase64FromRington(event.target.files[0])
        .then(result => {
          let file = result;
          setNewData({
            ...newData,
            audio_file: file as string
          })
        })
        .catch(err => { });
    }
  }

  return (
    <>
      {
        reportData &&
        <div className="CallCenter_CallGroupsPage_Item entity-edit">
          {/* Top navigation */}
          <div className="top-nav">
            <BaseLink
              router={$router.router}
              routeName="call_center.phoneNumbers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
            >
              <Icon icon="phone-1" />
              <span>Phone Numbers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.dispatchers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
            >
              <Icon icon="user-1" />
              <span>Dispatchers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.additionalTime"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="time-22" />
              <span>Additional Time</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.absence"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.absence" })}
            >
              <Icon icon="time-16" />
              <span>Absence</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.groups" })}
            >
              <Icon icon="user-29" />
              <span>Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callFlows"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
            >
              <Icon icon="share-7" />
              <span>Call Flows</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.caller_groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
            >
              <Icon icon="user-29" />
              <span>Caller Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.temporaryClosed"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="close-664" />
              <span>Temporary Closed</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callCenters"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
            >
              <Icon icon="delivery-8" />
              <span>Call Centers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.audioLibrary"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="audio-15" />
              <span>Audio Library</span>
            </BaseLink>
          </div>

          <div className="wrapper flex-container sb">
            <h1>Audio Library</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.audioLibrary',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  reportData.permissions.audio_library_edit ?
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button> : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Name:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={newData.name}
                    onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Description:</span>

                  <input
                    type="text"
                    disabled={!editing}
                    value={newData.description}
                    onChange={({ target: { value } }) => setNewData({ ...newData, description: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Audio File</div>

            <div className="fields">
              <div className="__left">
                {
                  editing ?
                    <div className="field">
                      <span>Audio:</span>

                      {
                        newData.audio_url ?
                          <AudioPlayer audioSrc={newData.audio_url} /> :
                          <input
                            type="file"
                            style={{ display: 'block' }}
                            onChange={addFile}
                          />
                      }

                      {
                        (newData.audio_url || newData.audio_file) &&
                        <div
                          style={{ width: '16px', cursor: 'pointer', marginLeft: '20px' }}
                          onClick={() => {
                            setIsChangeFile(true)
                            setNewData({
                              ...newData,
                              audio_file: null,
                              audio_url: null
                            })
                          }}
                        >
                          <Icon icon="x-mark-1" />
                        </div>
                      }
                    </div> :
                    <div className="field">
                      <span>Audio:</span>

                      <button
                        className="_zeroed _iconed"
                        onClick={() => setAudioPlayerShow(true)}
                      >
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    </div>
                }
              </div>

              <div className="__right"></div>
            </div>
          </div>

          <div className="fieldset">
            <div className="flex-container sb wrap">
              <div className="legend">User Groups</div>

              <div className="legend-action-wrapper">
                <div className="input-wrapper">
                  <SelectComponent
                    disabled={!editing}
                    options={availableUserGroups.map((user) => ({
                      span: user.name,
                      value: user.user_group_id
                    }))}
                    selectedOption={selectedUserGroups.user_group_id}
                    onChange={(value) => setSelectedUserGroups(availableUserGroups.find((user) => user.user_group_id === value) as UserGroupsProps)}
                  />
                  <button
                    disabled={!selectedUserGroups.user_group_id}
                    className="_green"
                    onClick={() => addUserGroups()}
                  >
                    Add
                  </button>
                </div>
              </div>

            </div>

            <table className="table">
              <tr>
                <th>Name</th>

                {
                  editing &&
                  <th></th>
                }
              </tr>

              {newData.userGroups.map((user, i) => (
                <tr
                  key={i}
                >
                  <td>{user.name}</td>

                  {
                    editing &&
                    <td>
                      <button
                        disabled={!editing}
                        className="_zeroed _iconed _red"
                        onClick={() => removeUserGroup(i)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  }
                </tr>
              ))}
            </table>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData?.audio_library.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.audio_library.created_by}
                    disabled={true}
                  />
                </div>
              </div>


              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.audio_library.updated_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.audio_library.updated_by}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  reportData.permissions.audio_library_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Audio Library</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => $router.router.navigate('call_center.audioLibrary', {
                    companyId: activeAccountId
                  }, { reload: true })}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _green"
                  disabled={loading || !readyToSave || (!newData.audio_file && !newData.audio_url)}
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {/* Item deleting popup */}
          {reportData.permissions.audio_library_delete && deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Audio Library
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the audio library it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setDeleting(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    disabled={loading}
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {/* Call listen popup */}
          {audioPlayerShow ? (
            <div className="popup callListenPopup" onClick={() => setAudioPlayerShow(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="call-player">
                  <AudioPlayer audioSrc={reportData.audio_library.audio_url} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
    </>
  )
}
