import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from 'react-router5'
import qs from "qs";

import Icon from '../../components/Icon'

import { useAppSelector } from '../../store/hooks'
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    phone_code_by_ip: PhoneCodeByIpProps[]
  }
}

interface PhoneCodeByIpProps {
  city: string
  code: string
  id: number
  state: string
}

export default function PhoneCodes() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [data, setData] = useState<PhoneCodeByIpProps[]>([])

  const [newItemForm, setNewItemForm] = useState({
    city: '',
    state: '',
    code: '',
  })

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/websites/phone-code-by-ip/report
      const { data: { data: response, success, error } } = (await httpClientUpdate.get(`/websites/phone-code-by-ip/report`, {
        params: {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
          limit_rows: 500,
          page: 1
        }
      })) as { data: HttpReport }
      if (success) {
        setData(response.phone_code_by_ip)

      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function addPhoneCodes(city: string, code: string, state: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites/phone-code-by-ip
      const response = await httpClientUpdate.post(`/websites/phone-code-by-ip`, qs.stringify({
        account_id: activeAccountId,
        website_id: $router.router.getState().params.websiteId,
        city,
        code,
        state,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setNewItemForm({
          city: '',
          state: '',
          code: '',
        })
        return loadData()
      } else {
        setIsSaving(false)
        setNewItemForm({
          city: '',
          state: '',
          code: '',
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function removeItem(id: number) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/websites/phone-code-by-ip/{id}
      const response = await httpClientUpdate.delete(`/websites/phone-code-by-ip/${id}`, {
        data: {
          account_id: activeAccountId,
          website_id: $router.router.getState().params.websiteId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        return loadData()
      } else {
        setIsSaving(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Add item function
  function addItem() {

    let { city, code, state } = newItemForm

    addPhoneCodes(city, code, state)
  }

  function checkEmptyField() {
    let is_empty = false

    Object.keys(newItemForm).forEach(item => {
      if (newItemForm[item] === '') {
        is_empty = true
      }
    })

    return is_empty
  }

  return (
    <>
      <div className="ListsPage_Appliances">
        <div className="top-nav top-nav-list">
          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.main"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId }}
              className={classNames({ _active: $router.route.name === "websites.main" })}
            >
              <Icon icon="task-1" />
              <span>Main</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.settings"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.settings" })}
            >
              <Icon icon="task-1" />
              <span>Settings</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.pages"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.pages" })}
            >
              <Icon icon="task-1" />
              <span>Pages</span>
            </BaseLink>
          </div>

          <div className="top-nav-list-item">
            <BaseLink
              router={$router.router}
              routeName="websites.phone_codes"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "phone_codes" })}
            >
              <Icon icon="task-1" />
              <span>Phone Codes</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.phones"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.phones" })}
            >
              <Icon icon="task-1" />
              <span>Phones</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="websites.links"
              routeParams={{ companyId: activeAccountId, websiteId: $router.router.getState().params.websiteId, domain: $router.router.getState().params.domain }}
              className={classNames({ _active: $router.route.name === "websites.links" })}
            >
              <Icon icon="task-1" />
              <span>Links</span>
            </BaseLink>
          </div>
        </div>
      </div>

      <div className="ListsPage_Appliances">
        <div className="wrapper">
          <div className="flex-container sb header">
            <div className="flex-container _gap-narrow">
              <h1>Website (Phone Codes):</h1>

              <div
                className="item-name"
                style={{
                  fontSize: '16pt',
                  color: '#8391AD'
                }}
              >
                {$router.router.getState().params.domain}
              </div>
            </div>

            <div style={{ display: 'flex' }}>

              <button
                style={{ background: '#d0d3da', color: 'white', marginLeft: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'websites',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{
                    width: '16px',
                    height: '16px',
                    minWidth: '16px',
                    transform: 'rotate(180deg)',
                    fill: '#fff'
                  }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>
            </div>
          </div>

          <div style={{ marginTop: '20px' }} className='flex-container sb header'>
            {
              // permissions.list_appliance_add &&
              <form
                onSubmit={(e) => { e.preventDefault(); addItem() }}
                style={{
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '28%',
                  }}
                >
                  <div style={{ marginRight: '20px', width: '100px' }} className="label">City:</div>
                  <input
                    type="text"
                    value={newItemForm.city}
                    onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, city: value })}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '28%',
                  }}
                >
                  <div style={{ marginRight: '20px', width: '100px' }} className="label">State:</div>
                  <input
                    type="text"
                    value={newItemForm.state}
                    onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, state: value })}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '28%',
                  }}
                >
                  <div style={{ marginRight: '20px', width: '100px' }} className="label">Code:</div>
                  <input
                    type="text"
                    value={newItemForm.code}
                    onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, code: value })}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '12%'
                  }}
                >
                  <button
                    className="_wa _green"
                    disabled={isSaving || checkEmptyField()}
                  >
                    Add
                  </button>
                </div>
              </form>
            }
          </div>

          {
            !!data.length &&
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '33%' }}>City</th>
                  <th style={{ width: '33%' }}>State</th>
                  <th style={{ width: '33%' }}>Code</th>
                  <th></th>
                </tr>
              </thead>

              {
                data.map(item => (
                  <tr key={item.id}>
                    <td>{item.city}</td>
                    <td>{item.state}</td>
                    <td>{item.code}</td>
                    <td>
                      <button
                        className="_zeroed _iconed _red"
                        disabled={isSaving}
                        onClick={() => removeItem(item.id)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    </td>
                  </tr>
                ))
              }
            </table>
          }
        </div>
      </div>
    </>
  )
}
