import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'
import moment from 'moment'

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"

import { useAppSelector } from '../../store/hooks'
import { capitalizeFirstLetter, dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"
import '../../styles/pages/sms.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: TicketDataProps
}

interface TicketDataProps {
  ticket: TicketProps
  ticket_items: TicketItemsProps[]
  responsible: {
    item_id: string
    type: string
    service_resource_code?: number
    service_resource_name?: string
  }[]
  permissions: {
    ticket_add: boolean
    ticket_delete: boolean
    ticket_edit: boolean
    ticket_report_show: boolean
    ticket_send_message: boolean
    ticket_show: boolean
    ticket_close: boolean
    ticket_cancel: boolean
  }
  edit: {
    user_groups: {
      name: string
      user_group_id: string
    }[]
    status: {
      id: number
      name: string
    }[]
  }
}

interface TicketProps {
  author: string
  author_id: string
  author_user: string
  date_time_start: string
  number: string
  status: string
  theme: string
  ticket_id: string
  type: string
}

interface TicketItemsProps {
  created_by: string
  created_at: string
  message: string
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [errorText, setErrorText] = useState('')

  const [isReserve, setIsReserve] = useState(false)
  const [isSendType, setIsSendType] = useState(false)
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [deleting, setDeleting] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [readyChangeStatus, setReadyChangeStatus] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)
  const [message, setMessage] = useState('')
  const [editing, setEditing] = useState(false)

  const [data, setData] = useState<Partial<TicketDataProps>>({})
  const [dataReport, setDataReport] = useState<Partial<TicketDataProps>>({})

  const [chat, setChat] = useState<TicketItemsProps[] | []>([])
  const [permissions, setPermissions] = useState({
    ticket_add: false,
    ticket_delete: false,
    ticket_edit: false,
    ticket_report_show: false,
    ticket_send_message: false,
    ticket_show: false,
  })
  const [sendTo, setSendTo] = useState({
    service_resource: false,
    author: false,
  })

  const [sendToFromEdit, setSendToFromEdit] = useState({})
  const [editStatus, setEditStatus] = useState('')

  useEffect(() => {
    if (editStatus === '') {
      setReadyChangeStatus(false)
    }
  }, [editStatus])

  async function handleSave(type?: number) {
    setIsSaving(true)
    try {
      let created_at = moment(data.ticket?.date_time_start).format('MM/DD/YYYY hh:mma')

      let responsibles: { type: string; item_id: string | undefined }[] = []
      Object.keys(sendTo).forEach(item => {
        if (sendTo[item]) {
          if (item === 'service_resource') {
            responsibles.push({
              type: "service_resource",
              item_id: data.responsible && data.responsible.filter(item => capitalizeFirstLetter(item.type) === 'Service resource')[0].item_id
            })
          } else if (item === 'author') {
            responsibles.push({
              type: "author",
              item_id: data.ticket?.author_id
            })
          }
        }
      })

      Object.keys(sendToFromEdit).forEach(item => {
        if (sendToFromEdit[item]) {
          responsibles.push({
            type: 'user_group',
            item_id: data.edit && data.edit.user_groups.filter(group => capitalizeFirstLetter(group.name) === item)[0].user_group_id
          })
        }
      })

      let statusSend = {}

      if (type) {
        statusSend = { status_id: type }
      }

      // https://2022back4.artemiudintsev.com/api/tickets/88888001fd67db3si1
      const response = await httpClientUpdate.put(`/tickets/${$router.router.getState().params.ticketId}`,
        {
          account_id: activeAccountId,
          type: data.ticket?.type,
          ...statusSend,
          message: message,
          date: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone, created_at as string),
          docs: [],
          responsibles: responsibles
        })
      if (response.data.success) {
        setIsSaving(false)
        if (type) {
          loadInfo(true)
          setIsSendType(true)
        } else {
          isReserve && toggleResrve('unblock')
          setEditing(false)
          loadInfo()
        }
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function handleUpdatedStatus(type: number) {
    setIsSaving(true)
    try {
      setEditStatus('')
      // https://2022back4.artemiudintsev.com/api/tickets/88888001fd67db3si1
      const response = await httpClientUpdate.put(`/tickets/${$router.router.getState().params.ticketId}`,
        {
          account_id: activeAccountId,
          status: `${type}`
        })
      if (response.data.success) {
        setIsSaving(false)
        loadInfo(true)
        setIsSendType(true)
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove ticket
  async function handleRemove() {
    try {
      // https://2022back4.artemiudintsev.com/api/tickets/88888001fd67db3si1
      const response = await httpClientUpdate.delete(`/tickets/${$router.router.getState().params.ticketId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        isReserve && toggleResrve('unblock')
        $router.router.navigate('tickets', {
          companyId: activeAccountId,
        }, { reload: true })
      }
    } catch (error) {

    }
  }

  // Load info function
  async function loadInfo(isSendMessage?: boolean) {
    try {
      const { data: { data: ticketData, success, error } } = await httpClientUpdate.get('/tickets/' + $router.router.getState().params.ticketId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setDataReport(ticketData)
        setData(ticketData)
        setChat(ticketData.ticket_items)
        setPermissions(ticketData.permissions)
        if (!isSendMessage) {
          let updatedSendTo: string[] = []
          let user_group: string[] = []
          ticketData.responsible.forEach(item => {
            if (item.type === 'user_group') {
              user_group.push(item.item_id)
            }
            updatedSendTo.push(capitalizeFirstLetter(item.type))
          })

          updatedSendTo.length && setSendTo({
            service_resource: updatedSendTo.includes('Service resource'),
            author: updatedSendTo.includes('Author'),
          })

          let updatedSendEdit = {}
          ticketData.edit.user_groups.forEach(item => {
            if (user_group.includes(item.user_group_id)) {
              updatedSendEdit[capitalizeFirstLetter(item.name)] = true
            } else {
              updatedSendEdit[capitalizeFirstLetter(item.name)] = false
            }
          })
          setSendToFromEdit(updatedSendEdit)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  useEffect(() => {
    if (editing) {
      setReadyToSave(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendTo, sendToFromEdit])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    if (isReserve) {
      setMinutes(9)
      setSeconds(59)
    } else {
      setMinutes(0)
      setSeconds(0)
    }
  }, [isReserve])

  function handleCancelEdit() {
    setIsReserve(false)
    setEditing(false)

    let updatedSendTo: string[] = []
    let user_group: string[] = []

    dataReport.responsible && dataReport.responsible.forEach(item => {
      if (item.type === 'user_group') {
        user_group.push(item.item_id)
      }
      updatedSendTo.push(item.type)
    })

    updatedSendTo.length && setSendTo({
      service_resource: updatedSendTo.includes('service_resource'),
      author: updatedSendTo.includes('author'),
    })

    let updatedSendEdit = {}
    dataReport.edit && dataReport.edit.user_groups.forEach(item => {
      if (user_group.includes(item.user_group_id)) {
        updatedSendEdit[item.name] = true
      } else {
        updatedSendEdit[item.name] = false
      }
    })
    setSendToFromEdit(updatedSendEdit)

    dataReport && setData(dataReport)
    dataReport.ticket_items && setChat(dataReport.ticket_items)
    isReserve && toggleResrve('unblock')
  }

  async function handleSendMessage() {
    const response = await httpClientUpdate.post(`/tickets/messages/send`, qs.stringify({
      account_id: activeAccountId,
      message: message,
      ticket_id: data.ticket?.ticket_id
    }))
    if (response.data.success) {
      loadInfo(true)
      setMessage('')
    }
  }

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && handleSendMessage()
    }
  }

  async function toggleResrve(type: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/tickets/888885011sm4yrn9bg/reserve
      const { data: { data: reserveData, success } } = await httpClientUpdate.post(`/tickets/${$router.router.getState().params.ticketId}/reserve`, {
        account_id: activeAccountId,
        type
      }) as { data: any }
      if (success) {
        setIsSaving(false)
        if (reserveData.message === 'Success block') {
          loadInfo()
          setIsReserve(true)
        } else {
          setIsReserve(false)
          setEditing(false)
          dataReport && setData(dataReport)
          dataReport.ticket_items && setChat(dataReport.ticket_items)
        }
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      setErrorText(createdError.content.errorText)
    }
  }

  function getServiceName() {
    let name = ''
    data.responsible && data.responsible.forEach(item => {
      if (capitalizeFirstLetter(item.type) === 'Service resource') {
        name += item.service_resource_code ? `${item.service_resource_name} (${item.service_resource_code})` : item.service_resource_name
      }
    })
    return name === 'undefined' ? '' : name
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Ticket:</h1>
          <div className="item-name">
            {data.ticket?.number}
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
            className="_wa"
            disabled={editing || isReserve}
            onClick={() =>
              $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'tickets',
                {
                  companyId: activeAccountId,
                  [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                  localInterface: $router.router.getState().params.localInterface
                }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>

          {
            !editing &&
              permissions.ticket_edit ? (
              <button
                className="_wa _green"
                disabled={!isReserve}
                onClick={() => setEditing(true)}
              >
                Edit
              </button>
            ) : null
          }
        </div>
      </div>

      <div className="fieldset">
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div
            className="legend"
          >
            Main
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className="item-name"
              style={{
                fontSize: '14pt',
                color: '#FF0000',
                marginLeft: '20px'
              }}
            >
              {
                minutes === 0 && seconds === 0
                  ? null
                  : <>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>
              }
            </div>
            {
              !errorText ?
                isReserve ?
                  <button
                    className='unclose'
                    style={{ marginLeft: '20px' }}
                    disabled={isSaving}
                    onClick={() => toggleResrve('unblock')}
                  >
                    Remove The Reserve
                  </button> :
                  <button
                    className='close'
                    style={{ marginLeft: '20px' }}
                    disabled={isSaving}
                    onClick={() => toggleResrve('block')}
                  >
                    Reserve
                  </button> :
                <button
                  className='unclose'
                  style={{ marginLeft: '20px', cursor: 'inherit' }}
                >
                  {errorText}
                </button>
            }
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Date:</span>
              <input
                type="text"
                disabled={true}
                defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, data.ticket?.date_time_start)}
              />
            </div>

            <div className="field">
              <span>Status:</span>
              <input
                type="text"
                disabled={true}
                defaultValue={data.ticket?.status}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Type:</span>
              <input
                type="text"
                defaultValue={data.ticket?.type}
                disabled={true}
              />
            </div>

            <div className="field">
              <span>Author:</span>
              <input
                type="text"
                defaultValue={data.ticket?.author}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Send To</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Author:</span>
              <Checkbox
                contents=""
                disabled={!editing}
                value={sendTo.author}
                onChange={(value) => setSendTo({ ...sendTo, author: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Service Resource:</span>
              <Checkbox
                contents={getServiceName()}
                disabled={!editing || getServiceName() === ''}
                value={sendTo.service_resource}
                onChange={(value) => setSendTo({ ...sendTo, service_resource: value })}
              />
            </div>
          </div>
        </div>

        <div
          className="fields"
          style={{ gridGap: 'inherit', marginTop: '0' }}
        >
          {
            data.edit?.user_groups.map((item, index) => {
              if (index === 0 || index % 2 === 0) {
                return (
                  <div
                    key={index}
                    style={{ display: 'flex', width: '50%' }}
                  >
                    <div className="field">
                      <span>{capitalizeFirstLetter(item.name)}:</span>
                      <Checkbox
                        contents=""
                        disabled={!editing}
                        value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                        onChange={(value) => setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })}
                      />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    style={{ display: 'flex', width: '50%', paddingLeft: '15px' }}
                  >
                    <div className="field">
                      <span>{capitalizeFirstLetter(item.name)}:</span>
                      <Checkbox
                        contents=''
                        disabled={!editing}
                        value={sendToFromEdit[capitalizeFirstLetter(item.name)]}
                        onChange={(value) => setSendToFromEdit({ ...sendToFromEdit, [capitalizeFirstLetter(item.name)]: value })}
                      />
                    </div>
                  </div>
                )
              }
            })
          }
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Theme</div>

        <div className="fields">
          {data.ticket?.theme}
        </div>
      </div>

      <div className="fieldset">
        {/* <div className="legend">Update</div> */}

        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div
            className="legend"
          >
            Update
          </div>

          {
            isReserve &&
            data.ticket?.status !== 'Canceled' &&
            data.ticket?.status !== 'Closed' &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                // data.permissions?.ticket_close &&
                data.ticket?.status !== 'In-progress' &&
                <button
                  className='cancel'
                  style={{ marginLeft: '20px', backgroundColor: '#FFB700', borderColor: '#FFB700' }}
                  disabled={isSendType || !data.edit?.status.filter(item => item.name === 'In-progress').length}
                  onClick={() => setEditStatus('In-progress')
                  }
                >
                  In Progress
                </button>
              }

              {
                // data.permissions?.ticket_close &&
                <button
                  className='close'
                  style={{ marginLeft: '20px' }}
                  disabled={isSendType || !data.edit?.status.filter(item => item.name === 'Closed').length}
                  onClick={() => setEditStatus('Close')
                  }
                >
                  Close
                </button>
              }

              {
                // data.permissions?.ticket_cancel &&
                <button
                  className='cancel'
                  style={{ marginLeft: '20px', backgroundColor: '#EB5757', borderColor: '#EB5757' }}
                  disabled={isSendType || !data.edit?.status.filter(item => item.name === 'Canceled').length}
                  onClick={() => setEditStatus('Cancel')
                  }
                >
                  Cancel
                </button>
              }
            </div>
          }
        </div>

        {
          data.permissions?.ticket_send_message &&
          <div
            style={{
              justifyContent: 'space-between',
              gridGap: 'inherit',
              textAlign: 'center'
            }}
            className="fields"
          >
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              position: 'relative'
            }}>

              <textarea
                style={{ width: '100%' }}
                disabled={!isReserve}
                placeholder='Your Message'
                value={message}
                onChange={({ target: { value } }) => setMessage(value)}
                onKeyDown={(event) => handleChangeMessage(event)}
              />

              <button
                style={{
                  position: 'absolute',
                  right: '5px',
                  bottom: '5px'
                }}
                className='send'
                disabled={message === '' || !isReserve}
                onClick={() => handleSendMessage()}
              >
                Send
              </button>
            </div>
          </div>
        }

        {/* show all screen */}
        <div className='chat'
        >
          {
            chat.map((message, i) => (
              <div key={i} className='chat-row chat-update'>
                <div className='chat-update-item'>
                  <p> {message.message} </p>
                  <p style={{ textAlign: 'right' }} >
                    <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      {editing ? (
        <div className="wrapper flex-container sb editing-buttons">
          <div>
            {
              permissions.ticket_delete &&
              <button className="_red" onClick={() => setDeleting(true)}>Delete Ticket</button>
            }
          </div>
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => handleCancelEdit()}
            >
              Cancel
            </button>
            <button
              disabled={!readyToSave || isSaving}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}

      {/* Item share popup */}
      {deleting && permissions.ticket_delete ? (
        <div className="item-delete-popup" onClick={() => setDeleting(false)}>

          <div className="wrapper" onClick={(e) => e.stopPropagation()}>

            <div className="title">
              Delete Ticket
            </div>

            <div className="checkbox-wrapper">
              <Checkbox
                contents="I understand that after deleting the ticket it will not be possible to recover."
                value={readyToDelete}
                onChange={(value) => setReadyToDelete(value)}
              />
            </div>

            <div className="buttons">

              <button className="_bordered _green" onClick={() => setDeleting(false)}>
                Cancel
              </button>

              <button
                disabled={!readyToDelete}
                className="_bordered _red"
                onClick={() => handleRemove()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/* Item share popup */}
      {editStatus ? (
        <div className="item-delete-popup" onClick={() => setEditStatus('')}>

          <div className="wrapper" onClick={(e) => e.stopPropagation()}>

            <div className="title">
              {editStatus} Ticket
            </div>

            <div className="checkbox-wrapper">
              <Checkbox
                contents="I understand that after change status the ticket it will not be possible to recover."
                value={readyChangeStatus}
                onChange={(value) => setReadyChangeStatus(value)}
              />
            </div>

            <div className="buttons">

              <button className="_bordered _green" onClick={() => setEditStatus('')}>
                Cancel
              </button>

              <button
                disabled={!readyChangeStatus || isSaving}
                className="_bordered _red"
                onClick={() => {
                  editStatus === 'Close' && handleUpdatedStatus(data.edit?.status.filter(item => item.name === 'Closed').length ? data.edit?.status.filter(item => item.name === 'Closed')[0].id : 0)
                  editStatus === 'Canceled' && handleUpdatedStatus(data.edit?.status.filter(item => item.name === 'Canceled').length ? data.edit?.status.filter(item => item.name === 'Canceled')[0].id : 0)
                  editStatus === 'In-progress' && handleUpdatedStatus(data.edit?.status.filter(item => item.name === 'In-progress').length ? data.edit?.status.filter(item => item.name === 'In-progress')[0].id : 0)
                }
                }
              >
                Change Status
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
