import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5';
import qs from "qs";

import Checkbox from '../../../components/Checkbox';
import SelectComponent from '../../../components/Select';

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from '../../../funcs';
import { useAppSelector } from '../../../store/hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

const initialData = {
  country_code: 'US',
  sms: true,
  mms: true,
  voice: true,
  fax: true,
  search_criteria: 'number',
  search: '',
  match_to: 'Anywhere',
  call_center_id: '',
}

interface AddPhoneProps {
  setOpen: (value: boolean) => void
  call_centers: {
    call_center_id: string
    name: string
  }[]
}

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    available_numbers: AvailableNumbersProps[]
  }
}

interface AvailableNumbersProps {
  addressRequirements: string
  beta: boolean
  fax: boolean
  friendly_name: string
  isoCountry: string
  lata: string
  latitude: string
  locality: string
  longitude: string
  mms: boolean
  phone_number: string
  postalCode: string
  rateCenter: string
  region: string
  sms: boolean
  voice: boolean
}

export default function AddPhone({ setOpen, call_centers }: AddPhoneProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState(initialData)
  const [configurePhone, setConfigurePhone] = useState({
    phone: '',
    phone_number_sid: '',
    sms_application_sid: '',
    voice_application_sid: '',
    friendly_name: '',
    sending: false,
  })
  const [isLoading, setIsLoading] = useState(false)

  const [report, setReport] = useState<AvailableNumbersProps[]>([])

  const [selectedPhone, setSelectedPhone] = useState<string>('')

  const [addedPhone, setAddedPhone] = useState(false)

  let first_load = false

  useEffect(() => {
    if (call_centers.length === 1) {
      setData({
        ...data,
        call_center_id: call_centers[0].call_center_id
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call_centers])

  async function searchPhone() {
    setIsLoading(true)
    setReport([])
    try {
      // https://2022back4.artemiudintsev.com/api/twilio/search
      const { data: { data: phoneNumbersData, success } } = (await httpClientUpdate.get('/twilio/search', {
        params: {
          account_id: activeAccountId,
          country_code: data.country_code,
          sms: data.sms ? '1' : '0',
          mms: data.mms ? '1' : '0',
          voice: data.voice ? '1' : '0',
          fax: data.fax ? '1' : '0',
          search_criteria: data.search_criteria,
          search: data.search,
          match_to: data.match_to,
          call_center_id: data.call_center_id,
        }
      })) as { data: HttpReport }
      if (success) {
        setReport(phoneNumbersData.available_numbers)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsLoading(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleBuyPhone() {
    setIsLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/twilio/purchase
      const { data: { success } } = (await httpClientUpdate.post('/twilio/purchase', qs.stringify({
        phone_number: selectedPhone,
      }))) as { data: HttpReport }
      if (success) {
        setAddedPhone(true)
        setIsLoading(false)

        report.forEach(item => {
          if (item.phone_number === selectedPhone) {
            setConfigurePhone({
              ...configurePhone,
              phone: item.phone_number,
              phone_number_sid: '',
            })
          }
        })

        setSelectedPhone('')
        setData(initialData)
        setReport([])
      } else {
        setIsLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsLoading(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSetConfig() {
    setIsLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/twilio/configure
      const { data: { success } } = (await httpClientUpdate.post('/twilio/configure', qs.stringify({
        phone_number_sid: configurePhone.phone_number_sid,
        friendly_name: configurePhone.friendly_name,
        sms_application_sid: configurePhone.sms_application_sid,
        voice_application_sid: configurePhone.voice_application_sid,
      }))) as { data: HttpReport }
      if (success) {
        setIsLoading(false)
        $router.router.navigate('call_center.phoneNumbers.new', {
          companyId: activeAccountId,
          phone_number: configurePhone.phone,
          friendly_name: configurePhone.friendly_name,
        }, { reload: true })
      } else {
        setIsLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsLoading(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function handleReset() {
    if (call_centers.length === 1) {
      setData({
        ...initialData,
        call_center_id: call_centers[0].call_center_id
      })
    } else {
      setData({
        ...initialData
      })
    }
    setReport([])
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    if (first_load) {
      if (!!ev) {
        setOpen(false)
      }
    } else {
      first_load = true
    }
  });

  return (
    <div className='popup'>
      {
        addedPhone ?
          <div
            ref={contextMenuRef}
            className='wrapper'
            style={{ maxWidth: '90vw' }}
          >
            <div style={{ marginBottom: '20px' }}>
              <span
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '13pt',
                }}
              >
                Configure Phone
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: '20px',
                marginBottom: '20px',
              }}
            >
              <div style={{ width: '50%' }}>
                <div style={{ marginBottom: '10px' }}>Phone</div>

                <input
                  type="text"
                  disabled={true}
                  value={formatPhoneNumber(configurePhone.phone)}
                  onChange={(event) => setConfigurePhone({ ...configurePhone, phone: event.target.value })}
                />
              </div>

              <div style={{ width: '50%' }}>
                <div style={{ marginBottom: '10px' }}>Friendly Name</div>

                <input
                  type="text"
                  value={configurePhone.friendly_name}
                  onChange={(event) => setConfigurePhone({ ...configurePhone, friendly_name: event.target.value })}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: '20px',
                marginBottom: '20px',
              }}
            >
              <div style={{ width: '50%' }}>
                <div style={{ marginBottom: '10px' }}>SMS Application Sid</div>

                <input
                  type="text"
                  value={configurePhone.sms_application_sid}
                  onChange={(event) => setConfigurePhone({ ...configurePhone, sms_application_sid: event.target.value })}
                />
              </div>

              <div style={{ width: '50%' }}>
                <div style={{ marginBottom: '10px' }}>Voice Application Sid</div>

                <input
                  type="text"
                  value={configurePhone.voice_application_sid}
                  onChange={(event) => setConfigurePhone({ ...configurePhone, voice_application_sid: event.target.value })}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '20px',
              }}
            >
              <button
                className=" _green"
                style={{ width: 'fit-content' }}
                disabled={isLoading}
                onClick={handleSetConfig}
              >
                Set Config
              </button>
              <button
                className="_bordered _red"
                style={{ width: 'fit-content' }}
                disabled={isLoading}
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>

          </div>
          :
          <>
            {
              !!selectedPhone ?
                <div
                  ref={contextMenuRef}
                  className='wrapper'
                  style={{ maxWidth: '90vw' }}
                >
                  <div style={{ marginBottom: '20px' }}>
                    <span
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '13pt',
                      }}
                    >
                      Are you sure you want to buy a phone {formatPhoneNumber(selectedPhone)}?
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className=" _green"
                      style={{ width: 'fit-content' }}
                      disabled={isLoading}
                      onClick={handleBuyPhone}
                    >
                      Buy Phone
                    </button>
                    <button
                      className="_bordered _red"
                      style={{ width: 'fit-content' }}
                      disabled={isLoading}
                      onClick={() => setSelectedPhone('')}
                    >
                      Cancel
                    </button>
                  </div>
                </div> :
                <div
                  ref={contextMenuRef}
                  className='wrapper'
                  style={{ maxWidth: '90vw' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      gap: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    <div style={{ width: '50%' }}>
                      <div style={{ marginBottom: '10px' }}>Country</div>

                      <SelectComponent
                        disabled={true}
                        options={[{ span: '(+1) United State - US', value: 'US' }]}
                        selectedOption={data.country_code}
                        onChange={(value) => setData({ ...data, country_code: value as string })}
                      />
                    </div>
                    <div style={{ width: '50%' }}>
                      <div style={{ marginBottom: '10px' }}>Capabilities</div>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                        <Checkbox
                          contents="Voice"
                          value={data.voice}
                          onChange={(value) => setData({ ...data, voice: value })}
                        />

                        <Checkbox
                          contents="SMS"
                          value={data.sms}
                          onChange={(value) => setData({ ...data, sms: value })}
                        />

                        <Checkbox
                          contents="MMS"
                          value={data.mms}
                          onChange={(value) => setData({ ...data, mms: value })}
                        />

                        <Checkbox
                          contents="Fax"
                          value={data.fax}
                          onChange={(value) => setData({ ...data, fax: value })}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      gap: '20px',
                    }}
                  >
                    <div style={{ width: '50%' }}>
                      <div style={{ marginBottom: '10px' }}>Search Criteria</div>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                        <SelectComponent
                          options={[{ span: 'Number', value: 'number' }, { span: 'Locality', value: 'locality' }]}
                          selectedOption={data.search_criteria}
                          onChange={(value) => setData({ ...data, search_criteria: value as string })}
                        />

                        <input
                          type="text"
                          placeholder='Search by digits or phrases'
                          value={data.search}
                          onChange={(event) => setData({ ...data, search: event.target.value })}
                        />
                      </div>
                    </div>

                    <div style={{ width: '25%' }}>
                      <div style={{ marginBottom: '10px' }}>Match to</div>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                        <SelectComponent
                          options={[{ span: 'First part of number', value: 'First' }, { span: 'Last part of number', value: 'Last' }, { span: 'Anywhere in number', value: 'Anywhere' }]}
                          selectedOption={data.match_to}
                          onChange={(value) => setData({ ...data, match_to: value as string })}
                        />
                      </div>
                    </div>

                    <div style={{ width: '25%' }}>
                      <div style={{ marginBottom: '10px' }}>Call Center</div>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                        <SelectComponent
                          disabled={call_centers.length < 2}
                          options={call_centers.map(item => {
                            return {
                              span: item.name,
                              value: item.call_center_id
                            }
                          })}
                          selectedOption={data.call_center_id}
                          onChange={(value) => setData({ ...data, call_center_id: value as string })}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <span
                      style={{ color: '#8391AD', fontSize: '11pt' }}
                    >
                      Search by area code, prefix, or characters you want in your phone number.
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className=" _green"
                      style={{ width: 'fit-content' }}
                      disabled={isLoading || data.call_center_id === ''}
                      onClick={searchPhone}
                    >
                      Search
                    </button>
                    <button
                      className="_bordered _red"
                      style={{ width: 'fit-content' }}
                      disabled={isLoading}
                      onClick={handleReset}
                    >
                      Reset filters
                    </button>
                  </div>

                  <div
                    style={{
                      maxHeight: '400px',
                      overflow: 'auto'
                    }}
                  >
                    {
                      report &&
                      <table
                        className="table"
                        style={{
                          marginTop: '20px',
                          maxHeight: '400px',
                          overflow: 'auto'
                        }}
                      >
                        <tr></tr>
                        {report.map((phoneNumber, i) => (
                          <tr
                            key={i}
                            onClick={() => setSelectedPhone(phoneNumber.phone_number)}
                          >
                            <td>
                              <div>{formatPhoneNumber(phoneNumber.phone_number)}</div>
                              <div>{phoneNumber.friendly_name}</div>
                            </td>
                            <td>
                              <div> {phoneNumber.isoCountry} </div>
                              <div> {phoneNumber.addressRequirements} </div>
                            </td>
                            <td>
                              <div>{phoneNumber.locality}</div>
                              <div>{phoneNumber.postalCode}</div>
                            </td>
                            <td>
                              <div>{phoneNumber.rateCenter}</div>
                              <div>{phoneNumber.region}</div>
                            </td>
                            <td>
                              <div> Voice: {phoneNumber.voice ? 'Yes' : 'No'} </div>
                              <div> SMS: {phoneNumber.sms ? 'Yes' : 'No'} </div>
                            </td>

                            <td>
                              <div> MMS: {phoneNumber.mms ? 'Yes' : 'No'} </div>
                              <div> Fax: {phoneNumber.fax ? 'Yes' : 'No'} </div>
                            </td>
                          </tr>
                        ))}
                      </table>
                    }
                  </div>
                </div>
            }
          </>
      }

    </div>
  )
}
