import { LegacyRef } from 'react'

// @ts-ignore
import Sound from '../assets/bell.mp3'

import './Audio.sass'

export default function Audio({ $player }: {
  $player: LegacyRef<HTMLAudioElement> | undefined
}) {

  return (
    <div
      className='audio-controll'
      style={{ display: 'none' }}
    >
      <audio
        ref={$player}
        controls
        className='bell-audio'
      >
        <source src={Sound} type="audio/mpeg" />
      </audio>
    </div>
  )
}
