import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { BaseLink, useRoute } from 'react-router5'
import { DateTime } from "luxon"
import { Moment } from 'moment'
import { useCookies } from "react-cookie";

import Icon from '../../../components/Icon'
import ReportFilters from '../../../components/reports/Filters'
import ReportTableControls from '../../../components/reports/TableControls'
import ReportTableField from '../../../components/reports/TableField'

import { dateToInfoBlock, getDateTimeForInterface, httpClientUpdate, nErrorUpdate, useOuterClick } from '../../../funcs'
import { useAppSelector } from '../../../store/hooks'
import { getDateRangeByPreset } from '../../../funcs/reports'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { DateRangePreset } from '../../../models/Misc'

type FilterWord = 'dispatcher_id'

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ListProps
}

interface ListProps {
  dispatcher_absences: DispatcherAbsencesProps[]

  interface: {
    filter_words: {
      dispatcher_id: string[]
    },

    min_date: Date,
    max_date: Date,

    date_schedule_end: Date,
    date_schedule_start: Date,

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  }

  permissions: {
    absence_dispatcher_add: boolean
    absence_dispatcher_show: boolean
  }

  edit: {
    dispatchers: DispatchersProps[]
  }
}

export interface DispatchersProps {
  code: number
  dispatcher_id: string
  nickname: string
}

interface DispatcherAbsencesProps {
  absence_end: string
  absence_start: string
  area_id: string
  created_at: string
  created_by: string
  dispatcher_absence_id: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  note: string
  updated_at: Moment | Date | string
  updated_by: string
}

export default function List() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const navActive = useAppSelector((store) => store.navActive)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<ListProps | null>(null)

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.call_center_absence?.filter_field ? {
      dispatcher_id: [] as string[],
      ...cookiesSetting.call_center_absence.filter_field,
    } : {
      dispatcher_id: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.call_center_absence?.sort_field ? cookiesSetting.call_center_absence.sort_field : 'created_at',
      direction: cookiesSetting && cookiesSetting?.call_center_absence?.sort_type ? cookiesSetting.call_center_absence.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Dispatcher',
      value: 'dispatcher_nickname'
    }, {
      span: 'Absence Start',
      value: 'absence_start'
    }, {
      span: 'Absence End',
      value: 'absence_end'
    }, {
      span: 'Created by',
      value: 'created_by'
    }]
  })

  // Load callerGroups function
  async function loadData() {

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          date_type: $router.router.getState().params.localInterface.dateRangeType,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          date_type: localInterface.dateRangeType,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, call_center_absence: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.call_center_absence?.limit_rows ? cookiesSetting?.call_center_absence.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/dispatchers/absences/report
      const { data: { data: responseData, success, error } } = (await httpClientUpdate.get('/dispatchers/absences/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.call_center_absence?.limit_rows ? cookiesSetting?.call_center_absence?.limit_rows : reportsMaxRows,
          ...activeFilters,
          ...filterParams,
        }
      })) as { data: HttpReport }
      if (success) {
        setReportData({
          ...responseData,
          interface: {
            ...responseData.interface,
            filter_words: {
              ...localInterface.filter_words,
              ...responseData.interface.filter_words,
            },
            min_date: getDateTimeForInterface(responseData.interface.min_date),
            max_date: getDateTimeForInterface(responseData.interface.max_date),
            date_schedule_start: getDateTimeForInterface(responseData.interface.date_schedule_start),
            date_schedule_end: getDateTimeForInterface(responseData.interface.date_schedule_end),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, call_center_absence: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    if (localInterface.dateRangePreset === 'all') {
      if (localInterface.dateRangeType === 'schedule') {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.date_schedule_start, reportData?.interface.date_schedule_end))
        })
      } else {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
        })
      }
    } else {
      setLocalInterface({
        ...localInterface,
        ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'all') {
      if (localInterface.dateRangeType === 'schedule') {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.date_schedule_start, reportData?.interface.date_schedule_end))
        })
      } else {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangeType])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {
        reportData &&
        <div className="CallCenter_CallGroupsPage_List" >

          {/* Top navigation */}
          <div className="top-nav">
            <BaseLink
              router={$router.router}
              routeName="call_center.phoneNumbers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
            >
              <Icon icon="phone-1" />
              <span>Phone Numbers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.dispatchers"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
            >
              <Icon icon="user-1" />
              <span>Dispatchers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.additionalTime"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="time-22" />
              <span>Additional Time</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.absence"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.absence" })}
            >
              <Icon icon="time-16" />
              <span>Absence</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.groups" })}
            >
              <Icon icon="user-29" />
              <span>Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callFlows"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
            >
              <Icon icon="share-7" />
              <span>Call Flows</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.caller_groups"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
            >
              <Icon icon="user-29" />
              <span>Caller Groups</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.temporaryClosed"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="close-664" />
              <span>Temporary Closed</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.callCenters"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
            >
              <Icon icon="delivery-8" />
              <span>Call Centers</span>
            </BaseLink>

            <BaseLink
              router={$router.router}
              routeName="call_center.audioLibrary"
              routeParams={{ companyId: activeAccountId }}
              className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
            >
              <Icon viewBox="0 0 1024 1024" icon="audio-15" />
              <span>Audio Library</span>
            </BaseLink>
          </div>

          <div className="page-header">
            <h1>Absence</h1>
          </div>

          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            dateRangeType={localInterface.dateRangeType}
            onDateRangeTypeChange={(value) => setLocalInterface({ ...localInterface, dateRangeType: value as any, page: 1 })}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.call_center_absence?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, call_center_absence: { ...cookiesSetting.call_center_absence, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.absence_dispatcher_add ?
                (
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('call_center.absence.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      }, { reload: true })}>
                      <Icon icon="plus-thin" />
                      <span>Add Absence</span>
                      <Icon icon="time-16" />
                    </button>
                  </div>
                ) :
                <></>
            }
          />

          <div className="contents">
            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Date</span>)}

                    sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Dispatcher</span>)}

                    allFiltersSelected={isAllFiltersSelected("dispatcher_id")}
                    onAllFiltersChange={(value) => switchFilter("dispatcher_id", "All", value)}

                    filterWords={reportData?.interface.filter_words.dispatcher_id.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("dispatcher_id", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("dispatcher_id", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'dispatcher_nickname' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'dispatcher_nickname', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Absence Start</span>)}

                    sortDirection={localInterface.sort.field === 'absence_start' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'absence_start', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Absence End</span>)}

                    sortDirection={localInterface.sort.field === 'absence_end' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'absence_end', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Created by</span>)}

                    sortDirection={localInterface.sort.field === 'created_by' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_by', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>

              {reportData.dispatcher_absences.map((absence, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative' }}
                  className={reportData.permissions.absence_dispatcher_show && absence.dispatcher_absence_id ? "" : "permission-show"}
                  onClick={() =>
                    reportData.permissions.absence_dispatcher_show &&
                    absence.dispatcher_absence_id &&
                    $router.router.navigate('call_center.absence.item', {
                      companyId: activeAccountId,
                      absenceId: absence.dispatcher_absence_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.absence_dispatcher_show &&
                    absence.dispatcher_absence_id &&
                    handleChangeMenu(event, absence.dispatcher_absence_id, 'absences', i)}
                >
                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.created_at)}</td>
                  <td>{absence.dispatcher_nickname ? `${absence.dispatcher_nickname} (${absence.dispatcher_code})` : absence.dispatcher_code}</td>
                  <td>
                    {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.absence_start)}
                  </td>
                  <td>
                    {
                      dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.absence_end)
                    }
                  </td>
                  <td>
                    {absence.created_by}
                    {
                      reportData.permissions.absence_dispatcher_show &&
                      showContext === `absences_${absence.dispatcher_absence_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('call_center.absence.item', {
                              companyId: activeAccountId,
                              absenceId: absence.dispatcher_absence_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/call_center/absence/${absence.dispatcher_absence_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Medium desktop table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>
              {reportData.dispatcher_absences.map((absence, i) => (
                <tr
                  key={i}
                  style={{ position: 'relative' }}
                  className={reportData.permissions.absence_dispatcher_show ? "" : "permission-show"}
                  onClick={() =>
                    reportData.permissions.absence_dispatcher_show &&
                    absence.dispatcher_absence_id &&
                    $router.router.navigate('call_center.absence.item', {
                      companyId: activeAccountId,
                      absenceId: absence.dispatcher_absence_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    absence.dispatcher_absence_id &&
                    reportData.permissions.absence_dispatcher_show &&
                    handleChangeMenu(event, absence.dispatcher_absence_id, 'absences', i)}
                >
                  <td>
                    <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.created_at)}</div>
                  </td>
                  <td>
                    <div>{absence.dispatcher_nickname ? `${absence.dispatcher_nickname} (${absence.dispatcher_code})` : absence.dispatcher_nickname}</div>
                    <div>{absence.created_by}</div>
                  </td>
                  <td>
                    <div>
                      {
                        dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.absence_start)
                      }
                    </div>
                    <div>
                      {
                        dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.absence_end)
                      }
                    </div>
                    {
                      reportData.permissions.absence_dispatcher_show &&
                      showContext === `absences_${absence.dispatcher_absence_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('absences.item', {
                              companyId: activeAccountId,
                              absenceId: absence.dispatcher_absence_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/call_center/absence/${absence.dispatcher_absence_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {reportData.dispatcher_absences.map((absence, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    reportData.permissions.absence_dispatcher_show &&
                    absence.dispatcher_absence_id &&
                    $router.router.navigate('call_center.absence.item', {
                      companyId: activeAccountId,
                      absenceId: absence.dispatcher_absence_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                >

                  <div className="__top">

                    <div className="__left">
                      <span className="gray">Service resource:</span>
                      <span> {absence.dispatcher_nickname ? `${absence.dispatcher_nickname} (${absence.dispatcher_code})` : absence.dispatcher_nickname}</span>
                    </div>

                    <div className="__right">
                    </div>
                  </div>

                  <div className="__bottom">

                    <div className="__left">
                      <div>
                        <b>
                          {
                            dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.absence_start)
                          }
                        </b>
                      </div>
                      <div>
                        <b>
                          {
                            dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.absence_end)
                          }
                        </b>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, absence.created_at)}
                      </div>
                      <div>
                        Created by {absence.created_by}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.call_center_absence?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, call_center_absence: { ...cookiesSetting.call_center_absence, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}
