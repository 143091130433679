import React from 'react'

import "../../styles/pages/common/report-list.sass"

export default function Drafts() {
  return (
    <div className="ClientsPage_List">
      <div className="page-header">
        <h1>Drafts & sent</h1>
      </div>

    </div>
  )
}
