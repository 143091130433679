import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'type' | 'notify_type'

interface HttpNotifyReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: NotifyReport
}

export interface NotifyReport {

  interface: {

    filter_words: {
      type: string[],
      notify_type: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    notify_template_add: boolean
    notify_template_show: boolean
  },

  notify_templates: NotifyTemplatesProps[],
}

interface NotifyTemplatesProps {
  created_at: string
  created_by: string
  notify_subject: string
  notify_template_id: string
  notify_text: string
  notify_type: string
  type: string
  updated_at: string
  updated_by: string
}

function NotifyTemplatesPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<NotifyReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.notify_templates?.filter_field ? {
      type: [] as string[],
      notify_type: [] as string[],
      ...cookiesSetting.notify_templates.filter_field,
    } : {
      type: [] as string[],
      notify_type: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.notify_templates?.sort_field ? cookiesSetting.notify_templates.sort_field : 'type',
      direction: cookiesSetting && cookiesSetting?.notify_templates?.sort_type ? cookiesSetting.notify_templates.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Notify Subject',
      value: 'notify_subject'
    }, {
      span: 'Notify Type',
      value: 'notify_type'
    }, {
      span: 'Type',
      value: 'type'
    },]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.notify_templates?.filter_field ? {
        type: [] as string[],
        notify_type: [] as string[],
        ...cookiesSetting.notify_templates.filter_field,
      } : {
        type: [] as string[],
        notify_type: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.notify_templates?.sort_field ? cookiesSetting.notify_templates.sort_field : 'type',
        direction: cookiesSetting && cookiesSetting?.notify_templates?.sort_type ? cookiesSetting.notify_templates.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load accounts function
  async function loadNotify() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, notify_templates: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.notify_templates?.limit_rows ? cookiesSetting?.notify_templates.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/notify-templates/report?account_id=88888
      const { data: { data: notifications, success, error } } = await httpClientUpdate.get('/notify-templates/report',
        {
          params: {
            account_id: activeAccountId,
            limit_rows: cookiesSetting && cookiesSetting?.notify_templates?.limit_rows ? cookiesSetting?.notify_templates?.limit_rows : reportsMaxRows,
            ...filterParams,
          }
        }) as { data: HttpNotifyReport }

      if (success) {
        if (notifications?.permissions.notify_template_show) {
          setReportData({
            ...notifications,
            interface: {
              ...notifications.interface,
              filter_words: {
                ...localInterface.filter_words,
                ...notifications.interface.filter_words,
              }
            }
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, notify_templates: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load accounts on page mount
  useEffect(() => {
    loadNotify()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="AccountsPage_List" >

        { /* Page header */}
        <div className="page-header">
          <h1>Notify Templates</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => {
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.notify_templates?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, notify_templates: { ...cookiesSetting.notify_templates, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.notify_template_add ?
              (
                <div className="add-button-wrapper">
                  <button className="_iconed _rounded add-button" onClick={() => $router.router.navigate('notify-templates.new', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                  })}>
                    <Icon icon="plus-thin" />
                    <span>Add Template</span>
                    <Icon icon="user-1" />
                  </button>
                </div>
              ) :
              <></>
          }
        />

        <div className="contents">
          {/* Wide desktop table */}
          <table className={classNames('table',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Type</span>)}

                  allFiltersSelected={isAllFiltersSelected("type")}
                  onAllFiltersChange={(value) => switchFilter("type", "All", value)}

                  filterWords={reportData?.interface.filter_words.type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("type", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Notify Type</span>)}

                  allFiltersSelected={isAllFiltersSelected("notify_type")}
                  onAllFiltersChange={(value) => switchFilter("notify_type", "All", value)}

                  filterWords={reportData?.interface.filter_words.notify_type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("notify_type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("notify_type", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'notify_type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'notify_type', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                <ReportTableField
                  contents={(<span>Notify Subject</span>)}

                  sortDirection={localInterface.sort.field === 'notify_subject' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'notify_subject', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {
              reportData.notify_templates.map((template, i) => (
                <tr
                  key={template.notify_template_id}
                  style={{ cursor: reportData.permissions.notify_template_show && template.notify_template_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.notify_template_show &&
                    template.notify_template_id &&
                    $router.router.navigate('notify-templates.item', {
                      companyId: activeAccountId,
                      notify_templates_id: template.notify_template_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.notify_template_show &&
                    template.notify_template_id &&
                    handleChangeMenu(event, template.notify_template_id, 'notify-templates', i)}
                >
                  <td>
                    {template.type}
                  </td>

                  <td>
                    {template.notify_type}
                  </td>

                  <td>
                    {template.notify_subject}
                    {
                      reportData.permissions.notify_template_show &&
                      showContext === `notify-templates_${template.notify_template_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('notify-templates.item', {
                              companyId: activeAccountId,
                              notify_templates_id: template.notify_template_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/notify-templates/${template.notify_template_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>

                </tr>
              ))
            }
          </table>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.notify_templates?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, notify_templates: { ...cookiesSetting.notify_templates, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default NotifyTemplatesPage_List
