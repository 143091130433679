function SupportPage__Contacts() {

  return (
    <div className="SupportPage__Contacts">
      Contacts page (was not created yet)
    </div>
  )
}

export default SupportPage__Contacts
