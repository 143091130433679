function SupportPage__Faq() {

  return (
    <div className="SupportPage__Faq">
      FAQ page (was not created yet)
    </div>
  )
}

export default SupportPage__Faq
