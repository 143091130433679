import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import Icon from "../../components/Icon";

import { dateToInfoBlock, getDateTimeForInterface, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'status' | 'dispatcher'

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PayoutDispatcherReport
}

interface PayoutDispatcherReport {
  interface: {
    filter_words: {
      status: string[],
      dispatcher: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    payout_dispatcher_report_show: boolean
    payout_dispatcher_report_show_own: boolean
    payout_dispatcher_show: boolean
    payout_dispatcher_show_own: boolean
    payout_dispatcher_edit: boolean
    payout_dispatcher_edit_own: boolean
    payout_dispatcher_payment_new: boolean
  },

  payout_dispatchers: PayoutDispatcher[]

  edit: {
    dispatchers: {
      code: number
      dispatcher_id: string
      nickname: string
    }[]
  }
}

interface PayoutDispatcher {
  created_at: string
  date_finish: string
  date_start: string
  dispatcher_code: string
  dispatcher_id: string
  dispatcher_nickname: string
  payout_dispatcher_id: string
  status_id: string
  status_name: string
  total_amount: string
  total_hours: string
  total_orders: string
  type: string
  paid: number;
}

function PayoutDispatcherPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<PayoutDispatcherReport | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_30_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_30_days', DateTime.now().setZone(timeZone).minus({ days: 29 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.payout_dispatcher?.filter_field ? {
        status: [] as string[],
        dispatcher: [] as string[],
        ...cookiesSetting.payout_dispatcher.filter_field,
      } : {
        status: [] as string[],
        dispatcher: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.payout_dispatcher?.sort_field ? cookiesSetting.payout_dispatcher.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.payout_dispatcher?.sort_type ? cookiesSetting.payout_dispatcher.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Dispatcher',
        value: 'dispatcher_nickname'
      }, {
        span: 'Range',
        value: 'date_start'
      }, {
        span: 'Hours',
        value: 'total_hours'
      }, {
        span: 'Orders',
        value: 'total_orders'
      }, {
        span: 'Amount',
        value: 'total_amount'
      }, {
        span: 'Status',
        value: 'status_name'
      }, {
        span: 'Paid',
        value: 'paid'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_30_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_30_days', DateTime.now().setZone(timeZone).minus({ days: 29 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.payout_dispatcher?.filter_field ? {
        status: [] as string[],
        dispatcher: [] as string[],
        ...cookiesSetting.payout_dispatcher.filter_field,
      } : {
        status: [] as string[],
        dispatcher: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.payout_dispatcher?.sort_field ? cookiesSetting.payout_dispatcher.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.payout_dispatcher?.sort_type ? cookiesSetting.payout_dispatcher.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load PayoutDispatcher function
  async function loadPayoutDispatcher() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.payout_dispatcher?.limit_rows ? JSON.stringify(cookiesSetting?.payout_dispatcher?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.payout_dispatcher?.limit_rows ? JSON.stringify(cookiesSetting?.payout_dispatcher?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: localInterface.page,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, payout_dispatcher: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.payout_dispatcher?.limit_rows ? cookiesSetting?.payout_dispatcher.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/payouts/dispatchers/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: payoutDispatcher, success, error } } = (await httpClientUpdate.get('/payouts/dispatchers/report', {
        params: filterParams,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })) as { data: HttpReport }
      if (success) {

        if (payoutDispatcher.permissions.payout_dispatcher_report_show || payoutDispatcher.permissions.payout_dispatcher_report_show_own) {
          setReportData({
            ...payoutDispatcher,
            interface: {
              ...payoutDispatcher.interface,
              filter_words: {
                ...localInterface.filter_words,
                ...payoutDispatcher.interface.filter_words
              },
              min_date: getDateTimeForInterface(payoutDispatcher.interface.min_date),
              max_date: getDateTimeForInterface(payoutDispatcher.interface.max_date),
            }
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, payout_dispatcher: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load PayoutDispatcher on page mount
  useEffect(() => {
    loadPayoutDispatcher()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getDispatchersName(id: string) {
    let name = ''

    reportData && reportData.edit.dispatchers.forEach(item => {
      if (item.dispatcher_id === id) {
        name = item.code ? `${item.nickname} (${item.code})` : item.nickname
      }
    })

    return name
  }

  // Render function
  return (
    <>
      {
        reportData &&
        <div className="JobsPage_List">

          { /* Page header */}
          <div className="page-header">
            <h1>Payout Dispatcher</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.payout_dispatcher?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, payout_dispatcher: { ...cookiesSetting.payout_dispatcher, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData?.interface?.rows_all ? reportData.interface.rows_all : 0,
              start: reportData?.interface?.rows_start ? reportData.interface.rows_start : 0,
              end: reportData?.interface?.rows_end ? reportData.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData?.interface?.max_pages ? reportData.interface.max_pages : 0}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.payout_dispatcher_payment_new ?
                (
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('payout-dispatcher.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      }, { reload: true })}>
                      <Icon icon="plus-thin" />
                      <span>Make Payment</span>
                      <Icon icon="construction-3" />
                    </button>
                  </div>
                ) : <></>
            }
          />

          <div className="contents">

            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Date</span>)}

                    sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Dispatcher</span>)}

                    allFiltersSelected={isAllFiltersSelected("dispatcher")}
                    onAllFiltersChange={(value) => switchFilter("dispatcher", "All", value)}

                    filterWords={reportData?.interface.filter_words.dispatcher.map((filterWord) => ({
                      word: filterWord,
                      word_name: getDispatchersName(filterWord),
                      selected: isFilterSelected("dispatcher", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("dispatcher", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'dispatcher_nickname' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'dispatcher_nickname', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Status</span>)}

                    allFiltersSelected={isAllFiltersSelected("status")}
                    onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                    filterWords={reportData?.interface.filter_words.status.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("status", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("status", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'status_name' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status_name', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Range</span>)}

                    sortDirection={localInterface.sort.field === 'date_start' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'date_start', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Hours</span>)}

                    sortDirection={localInterface.sort.field === 'total_hours' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total_hours', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Orders</span>)}

                    sortDirection={localInterface.sort.field === 'total_orders' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total_orders', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Amount</span>)}

                    sortDirection={localInterface.sort.field === 'total_amount' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total_amount', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Paid</span>)}

                    sortDirection={localInterface.sort.field === 'paid' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'paid', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>
              {reportData.payout_dispatchers.map((pay, i) => (
                <tr
                  key={i}
                  style={{ cursor: (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) && pay.payout_dispatcher_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                    pay.payout_dispatcher_id &&
                    $router.router.navigate('payout-dispatcher.item', {
                      companyId: activeAccountId,
                      payoutDispatcherId: pay.payout_dispatcher_id,
                      localInterface: localInterface,
                    }, { reload: true })}

                  onContextMenu={(event) =>
                    (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                    pay.payout_dispatcher_id &&
                    handleChangeMenu(event, pay.payout_dispatcher_id, 'payout_dispatcher', i)}
                >
                  <td style={{ position: 'relative', paddingRight: '25px' }}>
                    {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}
                    {
                      !reportData.permissions.payout_dispatcher_edit &&
                      reportData.permissions.payout_dispatcher_edit_own &&
                      (pay.status_name === 'Need Confirmation' || pay.status_name === 'Final Confirmation') &&
                      <span
                        style={{
                          position: 'absolute',
                          right: '5px',
                          width: '20px',
                          height: '20px',
                          background: '#FF0000',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '3px'
                        }}
                      >!</span>
                    }
                    {
                      reportData.permissions.payout_dispatcher_edit &&
                      !reportData.permissions.payout_dispatcher_edit_own &&
                      (pay.status_name === 'Need to Check' || pay.status_name === 'Need Payment') &&
                      <span
                        style={{
                          position: 'absolute',
                          right: '5px',
                          width: '20px',
                          height: '20px',
                          background: '#FF0000',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '3px'
                        }}
                      >!</span>
                    }
                  </td>
                  <td>{pay.dispatcher_code ? `${pay.dispatcher_nickname} (${pay.dispatcher_code})` : pay.dispatcher_nickname}</td>
                  <td>{pay.type === 'payout' && pay.status_name}</td>
                  <td>{pay.type === 'payout' && `${pay.date_start} - ${pay.date_finish}`}</td>
                  <td>{pay.type === 'payout' && pay.total_hours}</td>
                  <td>{pay.type === 'payout' && pay.total_orders}</td>
                  <td
                    style={{ color: pay.type === 'payout' ? 'inherit' : '#FF0000' }}
                  >
                    {
                      pay.type === 'payout' ?
                        `$${pay.total_amount}` :
                        `-$${pay.total_amount}`
                    }
                  </td>

                  <td>
                    {pay.type === 'payout' && `$${Number(pay.paid).toFixed(2)}`}
                    {
                      (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                      showContext === `payout_dispatcher_${pay.payout_dispatcher_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('payout_dispatcher.item', {
                              companyId: activeAccountId,
                              paymentsId: pay.payout_dispatcher_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/payout-dispatcher/${pay.payout_dispatcher_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Medum screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>
              {reportData.payout_dispatchers.map((pay, i) => (
                <tr
                  key={i}
                  style={{ cursor: (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) && pay.payout_dispatcher_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                    pay.payout_dispatcher_id &&
                    $router.router.navigate('payout-dispatcher.item', {
                      companyId: activeAccountId,
                      payoutDispatcherId: pay.payout_dispatcher_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                    pay.payout_dispatcher_id &&
                    handleChangeMenu(event, pay.payout_dispatcher_id, 'payout_dispatcher', i)}
                >
                  <td style={{ position: 'relative', paddingRight: '25px' }}>
                    <div>{pay.dispatcher_code ? `${pay.dispatcher_nickname} (${pay.dispatcher_code})` : pay.dispatcher_nickname}</div>
                    <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}</div>
                    {
                      !reportData.permissions.payout_dispatcher_edit &&
                      reportData.permissions.payout_dispatcher_edit_own &&
                      (pay.status_name === 'Need Confirmation' || pay.status_name === 'Final Confirmation') &&
                      <span
                        style={{
                          position: 'absolute',
                          right: '5px',
                          width: '20px',
                          height: '20px',
                          background: '#FF0000',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '3px'
                        }}
                      >!</span>
                    }
                    {
                      reportData.permissions.payout_dispatcher_edit &&
                      !reportData.permissions.payout_dispatcher_edit_own &&
                      (pay.status_name === 'Need to Check' || pay.status_name === 'Need Payment') &&
                      <span
                        style={{
                          position: 'absolute',
                          right: '5px',
                          width: '20px',
                          height: '20px',
                          background: '#FF0000',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '3px'
                        }}
                      >!</span>
                    }
                  </td>
                  <td>
                    <div>{pay.type === 'payout' && pay.status_name}</div>
                  </td>
                  <td>
                    <div>{pay.type === 'payout' && `${pay.date_start} - ${pay.date_finish}`}</div>
                  </td>
                  <td>
                    <div>
                      Amount:
                      <span style={{ color: pay.type === 'payout' ? 'inherit' : '#FF0000' }}>
                        {
                          pay.type === 'payout' ?
                            ` $${pay.total_amount}` :
                            ` -$${pay.total_amount}`
                        }
                      </span>
                    </div>
                    {
                      pay.type === 'payout' &&
                      <div>
                        Paid:
                        <span style={{ marginLeft: '10px' }}>
                          {`$${Number(pay.paid).toFixed(2)}`}
                        </span>
                      </div>
                    }
                  </td>
                  <td>
                    {
                      pay.type === 'payout' &&
                      <div>Hours: {pay.total_hours}</div>
                    }
                    {
                      pay.type === 'payout' &&
                      <div>Orders: {pay.total_orders}</div>
                    }
                    {
                      (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                      showContext === `payout_dispatcher_${pay.payout_dispatcher_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('payout-dispatcher.item', {
                              companyId: activeAccountId,
                              payoutDispatcherId: pay.payout_dispatcher_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/payout-dispatcher/${pay.payout_dispatcher_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {reportData.payout_dispatchers.map((pay, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    (reportData.permissions.payout_dispatcher_show || reportData.permissions.payout_dispatcher_report_show_own) &&
                    pay.payout_dispatcher_id &&
                    $router.router.navigate('payout-dispatcher.item', {
                      companyId: activeAccountId,
                      payoutDispatcherId: pay.payout_dispatcher_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      {
                        !reportData.permissions.payout_dispatcher_edit &&
                        reportData.permissions.payout_dispatcher_edit_own &&
                        (pay.status_name === 'Need Confirmation' || pay.status_name === 'Final Confirmation') &&
                        <div>
                          <span
                            style={{
                              marginLeft: '10px',
                              marginBottom: '10px',
                              width: '20px',
                              height: '20px',
                              background: '#FF0000',
                              borderRadius: '50%',
                              color: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >!</span>
                        </div>
                      }
                      {
                        reportData.permissions.payout_dispatcher_edit &&
                        !reportData.permissions.payout_dispatcher_edit_own &&
                        (pay.status_name === 'Need to Check' || pay.status_name === 'Need Payment') &&
                        <div>
                          <span
                            style={{
                              marginLeft: '10px',
                              marginBottom: '10px',
                              width: '20px',
                              height: '20px',
                              background: '#FF0000',
                              borderRadius: '50%',
                              color: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >!</span>
                        </div>
                      }
                      <div>
                        <strong>{pay.dispatcher_code ? `${pay.dispatcher_nickname} (${pay.dispatcher_code})` : pay.dispatcher_nickname}</strong>
                      </div>
                      <div>{pay.type === 'payout' && `${pay.date_start} - ${pay.date_finish}`}</div>
                      <div>{pay.type === 'payout' && pay.status_name}</div>
                    </div>

                    <div className="__right">
                      <div>
                        Amount: <b style={{ color: pay.type === 'payout' ? 'inherit' : '#FF0000' }}>
                          {
                            pay.type === 'payout' ?
                              ` $${pay.total_amount}` :
                              ` -$${pay.total_amount}`
                          }
                        </b>
                      </div>
                      {
                        pay.type === 'payout' &&
                        <div>
                          Paid: <b>{`$${Number(pay.paid).toFixed(2)}`}</b>
                        </div>
                      }
                      {
                        pay.type === 'payout' &&
                        <div>
                          Hours: <b>{pay.total_hours}</b>
                        </div>
                      }
                      {
                        pay.type === 'payout' &&
                        <div>
                          Orders: <b>{pay.total_orders}</b>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">

                    </div>
                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.payout_dispatcher?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, payout_dispatcher: { ...cookiesSetting.payout_dispatcher, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData?.interface?.rows_all ? reportData.interface.rows_all : 0,
              start: reportData?.interface?.rows_start ? reportData.interface.rows_start : 0,
              end: reportData?.interface?.rows_end ? reportData.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={reportData?.interface?.max_pages ? reportData.interface.max_pages : 0}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}

export default PayoutDispatcherPage_List
