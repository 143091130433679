import { useEffect, useState } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import qs from 'qs'
import { DateTime } from 'luxon'
import Datetime from 'react-datetime'
import moment from 'moment'

import Icon from '../../components/Icon'
import Select from '../../components/Select'
import Checkbox from '../../components/Checkbox'
import SelectComponent from '../../components/Select'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"
import "./Item.sass"

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PayoutProps
}

interface PayoutProps {
  edit: {
    status: {
      id: number
      name: string
    }[]
  }

  account: {
    time_zone: string
  }

  payout_staff: PayoutStaffProps

  permissions: {
    payout_staff_delete: boolean
    payout_staff_edit: boolean
    payout_staff_edit_own: boolean
    payout_staff_show: boolean
    payout_staff_show_own: boolean
    staff_tracker_add: boolean
    staff_tracker_add_own: boolean
  }
}

interface PayoutStaffProps {
  created_at: string
  created_by: string
  date_finish: string
  date_start: string
  payout_staff_id: string
  salary_per_hour: string
  staff_id: string
  staff_nickname: string
  staff_tracker: StaffTrackerProps[]
  payout_staff: PayoutsProps[]
  status_id: number
  status_name: string
  total_amount: string
  total_bonus_amount: number
  total_hours: string
  total_hours_amount: string
  type: string
  updated_at: string
  updated_by: string
  paid: string
  need_to_recalculate: boolean
}

interface StaffTrackerProps {
  created_at: string
  created_by: string
  date: string
  deleted_at: string
  id: number
  note: string
  payout_staff_id: string
  payout_staff_tracker_id: string
  is_manual: boolean
  is_updated_by_staff: boolean
  staff_id: string
  is_staff: boolean
  type: string
  updated_at: string
  updated_by: string
}

interface PayoutsProps {
  created_at: string
  date_finish: string
  date_start: string
  dispatcher_code: number
  dispatcher_id: string
  dispatcher_nickname: string
  payout_dispatcher_id: string
  status_id: number
  status_name: string
  total_amount: number
  total_hours: string
  total_hours_amount: string
  total_mentor_orders: string
  total_mentor_orders_amount: string
  total_orders: string
  total_orders_amount: string
  type: string
  updated_at: string
  updated_by: string
  isPay: boolean
  amount: number
}

interface AddTimeProps {
  date: string | Date
  type: string
  note: string
}

const initialAddTime = {
  date: DateTime.now().toJSDate(),
  type: '',
  note: '',
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [recalculate, setRecalculate] = useState(false)
  const [addTime, setAddTime] = useState<AddTimeProps | null>(null)

  const [hover, setHover] = useState('')

  const [deleteTracker, setDeleteTracker] = useState('')

  const [reportData, setReportData] = useState<PayoutProps | null>(null)

  const [payoutStaff, setPayoutStaff] = useState<PayoutsProps[]>([])

  const [newPayoutData, setNewPayoutData] = useState<PayoutStaffProps | null>(null)

  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/payouts/staff/88888321o2m87l5jup?account_id=88888
      const { data: { data: report, success, error } } = await httpClientUpdate.get('/payouts/staff/' + $router.router.getState().params.payoutStaffId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }
      if (success) {
        setReportData(report)
        setNewPayoutData({
          ...report.payout_staff,
          total_bonus_amount: Number(report.payout_staff.total_bonus_amount)
        })
        setPayoutStaff(report.payout_staff?.payout_staff ? report.payout_staff.payout_staff.map(item => {
          return {
            ...item,
            isPay: false,
            amount: 0,
          }
        }) : [])
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleToggleStatus(status_name: string) {
    try {
      setIsLoading(true)
      let status_id = reportData?.edit.status.filter(item => item.name === status_name)
      // https://2022back4.artemiudintsev.com/api/payouts/staff/88888321wsq5jt9cmi/status
      const response = await httpClientUpdate.put(`/payouts/staff/${$router.router.getState().params.payoutStaffId}/status`, qs.stringify({
        account_id: activeAccountId,
        status_id: status_id?.length ? status_id[0].id : null
      }))
      if (response.data.success) {
        setIsLoading(false)
        loadInfo()
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  async function handleAddTime() {
    setIsLoading(true)
    try {
      if (addTime) {
        const date = moment(addTime.date).format('MM/DD/YYYY hh:mma')
        // https://2022back4.artemiudintsev.com/api/payouts/staff/tracker
        const response = await httpClientUpdate.post(`/payouts/staff/tracker`, {
          account_id: activeAccountId,
          note: addTime.note,
          type: addTime.type,
          date: updatedDateToReqServer('MM/dd/yyyy hh:mma', reportData?.account.time_zone, date),
          staff_id: reportData?.payout_staff.staff_id,
          payout_staff_id: reportData?.payout_staff.payout_staff_id,
        })

        if (response.data.success) {
          setAddTime(null)
          setIsLoading(false)
          loadInfo()
        } else {
          setIsLoading(false)
        }
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  async function handleRemoveTracker() {
    try {
      setIsLoading(true)
      // https://2022back4.artemiudintsev.com/api/payouts/staff/tracker/88888322c0nw67m8dp?account_id=88888
      const response = await httpClientUpdate.delete(`/payouts/staff/tracker/${deleteTracker}`, {
        params: {
          account_id: activeAccountId
        }
      })
      if (response.data.success) {
        setIsLoading(false)
        setDeleteTracker('')
        loadInfo()
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  async function handleRecalculateStaff() {
    try {
      setIsLoading(true)
      // https://2022back4.artemiudintsev.com/api/payouts/staff/{PAYOUT_STAFF_ID}/recalculate
      const response = await httpClientUpdate.post(`/payouts/staff/${$router.router.getState().params.payoutStaffId}/recalculate`, qs.stringify({
        account_id: activeAccountId
      }))
      if (response.data.success) {
        setIsLoading(false)
        setRecalculate(false)
        loadInfo()
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  function handleCancelEdit() {
    if (reportData) {
      setNewPayoutData({
        ...reportData.payout_staff,
        total_bonus_amount: Number(reportData.payout_staff.total_bonus_amount)
      })
      setEditing(false)
    }
  }

  async function handleSave() {
    try {
      setIsLoading(true)
      // https://2022back4.artemiudintsev.com/api/payouts/staff/88888321wsq5jt9cmi
      const response = await httpClientUpdate.put(`/payouts/staff/${$router.router.getState().params.payoutStaffId}`, qs.stringify({
        account_id: activeAccountId,
        total_bonus_amount: newPayoutData?.total_bonus_amount ? +newPayoutData.total_bonus_amount : 0
      }))
      if (response.data.success) {
        $router.router.navigate('payout-staff', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  async function handleRemoveItem() {
    try {
      setIsLoading(true)
      // https://2022back4.artemiudintsev.com/api/payouts/staff/asd?account_id=88888
      const response = await httpClientUpdate.delete(`/payouts/staff/${$router.router.getState().params.payoutStaffId}`, {
        params: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        $router.router.navigate('payout-staff', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(event: any) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`

      newPayoutData && setNewPayoutData({
        ...newPayoutData,
        total_bonus_amount: +newNumber
      })

    } else if (price.length === 1) {
      let newNumber = `0.0${price}`

      newPayoutData && setNewPayoutData({
        ...newPayoutData,
        total_bonus_amount: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {

        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`

        newPayoutData && setNewPayoutData({
          ...newPayoutData,
          total_bonus_amount: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`

        newPayoutData && setNewPayoutData({
          ...newPayoutData,
          total_bonus_amount: +newNumber
        })
      }
    }
  }

  function getSumPayoutStaff() {
    let sum = 0
    if (reportData) {
      payoutStaff.forEach((item) => {
        sum = sum + +item.total_amount
      })
    }

    return sum
  }

  function getStatusName(id: number) {
    let name = ''

    if (reportData) {
      reportData.edit.status.forEach(item => {
        if (item.id === id) {
          name = item.name
        }
      })
    }

    return name
  }

  return (
    <>
      {
        reportData && newPayoutData &&
        <div className="PayoutPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Payout Staff</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'payout-staff',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !recalculate &&
                  !!reportData.payout_staff.need_to_recalculate &&
                  !!reportData.permissions.staff_tracker_add &&
                  newPayoutData.type === 'payout' &&
                  (reportData.payout_staff.status_name === 'Need Confirmation' ||
                    reportData.payout_staff.status_name === 'Need to Check' ||
                    reportData.payout_staff.status_name === 'Need Payment') ? (
                  <button
                    className="_wa _green"
                    style={{ marginRight: '10px' }}
                    onClick={() => setRecalculate(true)}
                  >
                    Calculate
                  </button>
                ) : null
              }

              {
                !editing &&
                  reportData.permissions.payout_staff_edit &&
                  newPayoutData.type === 'payout' &&
                  (reportData.payout_staff.status_name === 'Need Confirmation' ||
                    reportData.payout_staff.status_name === 'Need to Check' ||
                    reportData.payout_staff.status_name === 'Need Payment') ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div className="legend">Main</div>

              <div>
                {
                  !reportData.permissions.payout_staff_edit &&
                  reportData.permissions.payout_staff_edit_own &&
                  newPayoutData.type === 'payout' &&
                  (reportData.payout_staff.status_name === 'Need Confirmation' ||
                    reportData.payout_staff.status_name === 'Final Confirmation')
                  &&
                  <button
                    className='close'
                    style={{
                      backgroundColor: '#01B41F',
                      border: '1px solid #01B41F',
                      padding: '4px 20px',
                      color: 'white',
                      height: 'fit-content',
                      width: 'fit-content'
                    }}
                    disabled={isLoading}
                    onClick={() => handleToggleStatus(reportData.payout_staff.status_name === 'Need Confirmation' ? 'Need to Check' : 'Need Payment')}
                  >
                    Confirm
                  </button>
                }

                {
                  reportData.permissions.payout_staff_edit &&
                  newPayoutData.type === 'payout' &&
                  (reportData.payout_staff.status_name === 'Need to Check' || reportData.payout_staff.status_name === 'Need Payment') &&
                  <button
                    className='close'
                    style={{
                      backgroundColor: '#01B41F',
                      border: '1px solid #01B41F',
                      padding: '4px 20px',
                      color: 'white',
                      height: 'fit-content',
                      width: 'fit-content'
                    }}
                    disabled={isLoading}
                    onClick={() => handleToggleStatus('Checked')}
                  >
                    Check
                  </button>
                }
              </div>
            </div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date:</span>
                  <input
                    type="text"
                    disabled={true}
                    value={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newPayoutData.created_at)}
                  />
                </div>

                <div className="field">
                  <span>Staff:</span>

                  <input
                    type="text"
                    value={newPayoutData.staff_nickname}
                    disabled={true}
                    onChange={(event) => setNewPayoutData({ ...newPayoutData, staff_nickname: event.target.value })}
                  />
                </div>
                {
                  reportData.payout_staff.type === 'payout' &&
                  <div className="field">
                    <span>Payout:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`$${reportData.payout_staff.total_amount}`}
                    />
                  </div>
                }
              </div>

              <div className="__right">
                {
                  reportData.payout_staff.type === 'payment' &&
                  <div className="field">
                    <span>Paid:</span>

                    <input
                      type="text"
                      value={`$${newPayoutData.total_amount}`}
                      disabled={true}
                      onChange={(event) => setNewPayoutData({ ...newPayoutData, total_amount: event.target.value })}
                    />
                  </div>
                }

                {
                  reportData.payout_staff.type === 'payout' &&
                  <div className="field">
                    <span>Status:</span>

                    <Select
                      disabled={true}
                      options={reportData.edit.status.map((option) => ({
                        span: option.name,
                        value: option.id
                      }))}
                      selectedOption={newPayoutData.status_id}
                      onChange={(value) => setNewPayoutData({ ...newPayoutData, status_id: value as number })}
                    />
                  </div>
                }

                {
                  reportData.payout_staff.type === 'payout' &&
                  <div className="field">
                    <span>Range:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`${newPayoutData.date_start} - ${newPayoutData.date_finish}`}
                    />
                  </div>
                }

                {
                  reportData.payout_staff.type === 'payout' &&
                  <div className="field">
                    <span>Paid:</span>
                    <input
                      type="text"
                      disabled={true}
                      value={`$${newPayoutData.paid ? Number(newPayoutData.paid).toFixed(2) : Number(0).toFixed(2)}`}
                    />
                  </div>
                }
              </div>
            </div>
          </div>

          {
            reportData.payout_staff.type === 'payment' &&
            <div className="fieldset">

              <div className="legend">Payouts</div>

              <table className='table'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Range</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                {payoutStaff.map((payout, i) => (
                  <tr
                    key={i}
                  >
                    <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payout.created_at)}</td>
                    <td>
                      {getStatusName(payout.status_id)}
                    </td>
                    <td>{payout.date_start}{' - '}{payout.date_finish}</td>
                    <td>${payout.total_amount}</td>
                  </tr>
                ))}
              </table>
            </div>
          }

          {
            reportData.payout_staff.type === 'payout' &&
            <div className="fieldset">
              <div className="legend">Hours & Bonus</div>

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Total Hours Amount:</span>

                    <input
                      type="text"
                      disabled={true}
                      value={newPayoutData.total_hours_amount}
                      onChange={(event) => setNewPayoutData({ ...newPayoutData, total_hours_amount: event.target.value })}
                    />
                  </div>

                  <div className="field">
                    <span>Total Hours:</span>

                    <input
                      type="text"
                      disabled={true}
                      value={newPayoutData.total_hours}
                      onChange={(event) => setNewPayoutData({ ...newPayoutData, total_hours: event.target.value })}
                    />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Total Bonus Amount:</span>

                    <input
                      className='amount-field'
                      type="number"
                      disabled={!editing}
                      value={newPayoutData.total_bonus_amount.toFixed(2)}
                      onFocus={(event) => handleFocus(event)}
                      onChange={(event) => handleChangePrice(event)}
                      onKeyDown={(event) => handleChangePriceBackspace(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }

          {
            reportData.payout_staff.type === 'payout' &&
            <div className="fieldset">
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div className="legend">Tracker</div>

                {
                  ((!reportData.permissions.staff_tracker_add && !!reportData.permissions.staff_tracker_add_own && reportData.payout_staff.status_name === 'Need Confirmation') ||
                    (!!reportData.permissions.staff_tracker_add && (reportData.payout_staff.status_name === 'Need Confirmation' || reportData.payout_staff.status_name === 'Need to Check' || reportData.payout_staff.status_name === 'Need Payment'))) &&
                  <div>
                    {
                      !addTime ?
                        <button
                          className="_wa _green"
                          disabled={!!addTime}
                          onClick={() => setAddTime(initialAddTime)}
                        >
                          <span>Add Line</span>
                        </button> :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <button
                            className="_bordered _red"
                            style={{ marginRight: '20px' }}
                            disabled={isLoading}
                            onClick={() => setAddTime(null)}
                          >
                            Cancel
                          </button>

                          <button
                            className="_bordered _green"
                            disabled={isLoading || addTime.type === ''}
                            onClick={() => handleAddTime()}
                          >
                            Save
                          </button>
                        </div>
                    }
                  </div>
                }
              </div>

              {
                !!addTime &&
                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Date:</span>

                      <Datetime
                        dateFormat="MM/DD/YYYY"
                        timeFormat="hh:mm A"
                        value={addTime.date}
                        onChange={(value) => setAddTime({ ...addTime, date: moment.isMoment(value) ? value.toDate() : addTime.date })}
                      />
                    </div>

                    <div className="field">
                      <span>Note:</span>

                      <input
                        type="text"
                        value={addTime.note}
                        onChange={(event) => setAddTime({ ...addTime, note: event.target.value })}
                      />
                    </div>
                  </div>

                  <div className="__right">
                    <div className="field">
                      <span>Type:</span>

                      <SelectComponent
                        options={['start', 'pause', 'stop', 'comment'].map((type) => ({
                          span: type,
                          value: type,
                        }))} selectedOption={addTime.type}
                        onChange={(value) => setAddTime({ ...addTime, type: value as string })}
                      />
                    </div>
                  </div>
                </div>

              }

              <div className="fields">
                <div className="tracking-content">
                  {
                    newPayoutData.staff_tracker.map((item, index) => (
                      <div
                        key={index}
                        className={classNames('tracking-content-row', {
                          _can_edit: (!reportData.permissions.staff_tracker_add && !!reportData.permissions.staff_tracker_add_own && reportData.payout_staff.status_name === 'Need Confirmation') ||
                            (!!reportData.permissions.staff_tracker_add && (reportData.payout_staff.status_name === 'Need Confirmation' || reportData.payout_staff.status_name === 'Need to Check' || reportData.payout_staff.status_name === 'Need Payment')),
                          _deleted: !!item.deleted_at
                        })}
                      >
                        <div
                          className="tracking-content-items"
                        >
                          <div
                            className="tracking-content-item"
                          >

                            <span
                              className={classNames('__date', { __red: !item.is_staff })}
                              onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_date`)}
                              onMouseLeave={() => setHover('')}
                            >
                              {
                                dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.date)
                              }

                              {
                                `${item.payout_staff_tracker_id}_date` === hover &&
                                <span className="hover-title">
                                  {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                                </span>
                              }
                            </span>

                            {
                              !item.is_staff &&
                              <span
                                className="__red"
                                style={{ fontWeight: 'normal' }}
                                onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_status`)}
                                onMouseLeave={() => setHover('')}
                              >
                                (Administrator)

                                {
                                  `${item.payout_staff_tracker_id}_status` === hover &&
                                  <span className="hover-title">
                                    {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                                  </span>
                                }
                              </span>
                            }
                            {' / '}
                            <span
                              className={classNames(
                                {
                                  __green: item.type === 'start',
                                  __yellow: item.type === 'pause',
                                  __red: item.type === 'stop',
                                  __blue: item.type === 'comment'
                                }
                              )}
                              onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_type`)}
                              onMouseLeave={() => setHover('')}
                            >
                              {item.type}

                              {
                                `${item.payout_staff_tracker_id}_type` === hover &&
                                <span className="hover-title">
                                  {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                                </span>
                              }
                            </span>
                            {' / '}
                            <span
                              onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_note`)}
                              onMouseLeave={() => setHover('')}
                            >
                              {item.note}

                              {
                                `${item.payout_staff_tracker_id}_note` === hover &&
                                <span className="hover-title">
                                  {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                                </span>
                              }
                            </span>

                            {
                              !!item.is_manual &&
                              <>
                                {' / '}
                                <span
                                  className="__green"
                                  onMouseEnter={() => !item.deleted_at && setHover(`${item.payout_staff_tracker_id}_manual`)}
                                  onMouseLeave={() => setHover('')}
                                >
                                  [Manual Added]

                                  {
                                    `${item.payout_staff_tracker_id}_manual` === hover &&
                                    <span className="hover-title">
                                      {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.created_at)}
                                    </span>
                                  }
                                </span>
                              </>
                            }
                          </div>

                          {
                            !item.is_updated_by_staff && !!item.deleted_at &&
                            <div>
                              {' / '}
                              <span
                                className="__red"
                                style={{ fontWeight: 'normal' }}
                              >
                                (Administrator)
                              </span>
                            </div>
                          }

                        </div>
                        {
                          !item.deleted_at &&
                          ((!reportData.permissions.staff_tracker_add && !!reportData.permissions.staff_tracker_add_own && reportData.payout_staff.status_name === 'Need Confirmation') ||
                            (!!reportData.permissions.staff_tracker_add && (reportData.payout_staff.status_name === 'Need Confirmation' || reportData.payout_staff.status_name === 'Need to Check' || reportData.payout_staff.status_name === 'Need Payment'))) &&
                          <button
                            className="_zeroed _iconed _red"
                            onClick={() => setDeleteTracker(item.payout_staff_tracker_id)}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          }

          {
            reportData.payout_staff.type === 'payout' &&
            <div className="fieldset">
              <div className="legend">Payments</div>

              <div style={{ marginTop: '0' }} className="fields">
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th style={{ textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>

                  {
                    reportData.payout_staff.payout_staff.map(payment => (
                      <tr key={payment.payout_dispatcher_id}>
                        <td> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)} </td>

                        <td style={{ textAlign: 'right' }}> ${payment.total_amount} </td>
                      </tr>
                    ))
                  }
                </table>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                  }}
                >
                  <div>
                    <span style={{ marginRight: '10px', fontSize: '15pt' }}> Total Amount: </span>
                    <span style={{ fontSize: '15pt' }}> ${getSumPayoutStaff().toFixed(2)} </span>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newPayoutData.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input
                    type="text"
                    defaultValue={reportData.payout_staff.created_by ? reportData.payout_staff.created_by : 'System'}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newPayoutData.updated_at)}
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <span>Last Edited by:</span>
                  <input
                    type="text"
                    defaultValue={newPayoutData.updated_by}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.payout_staff_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Payout Staff</button>
              }
            </div>
            {
              editing &&
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>

                <button
                  disabled={isLoading || Number(reportData.payout_staff.total_bonus_amount) === Number(newPayoutData.total_bonus_amount)}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            }
          </div>

          {/* Item share popup */}
          {
            reportData.permissions.payout_staff_delete &&
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Payout Staff
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the payout staff it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    disabled={!readyToDelete || isLoading}
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }

          {recalculate && (
            <div className="item-delete-popup">

              <div style={{ maxWidth: '340px' }} className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Recalculate
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setRecalculate(false)}>
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    disabled={isLoading}
                    onClick={() => handleRecalculateStaff()}
                  >
                    Recalculate
                  </button>
                </div>
              </div>
            </div>
          )}

          {!!deleteTracker && (
            <div className="item-delete-popup">

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Line
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleteTracker('')}>
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    disabled={isLoading}
                    onClick={() => handleRemoveTracker()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}
