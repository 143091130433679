import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import moment from "moment"
import classNames from "classnames";
import qs from "qs";
import { DateTime } from "luxon"
import DateTimeComponent from "react-datetime"

import Icon from "../../components/Icon"
import Select from "../../components/Select"

import { httpClientUpdate, nErrorUpdate, screenSizeWidth, updatedDateToReqServer } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

import Absence from "../../models/Absence"
import { AbsencesReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AbsencesReport
}

interface HttpTimeSlotsReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    schedule: ScheduleProps[]
  }
}

interface ScheduleProps {
  day: string
  from: string
  limit: number
  schedule_id: string
  to: string
}

function AbsencesPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const width = screenSizeWidth()

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorTime, setErrorTime] = useState(false)

  const [permissions, setPermissions] = useState({
    absence_add: false,
    absence_delete: false,
    absence_edit: false,
    absence_report_show: false,
    absence_show: false,
  })
  const [newAbsenceData, setNewAbsenceData] = useState<Partial<Absence>>({
    service_resource: '',
    area: '',
    type: 'Range',
    absence_start: DateTime.now().plus({ hours: 0 }).set({ minute: 0 }).toJSDate(),
    absence_end: DateTime.now().plus({ hours: 1 }).set({ minute: 0 }).toJSDate(),
    date: DateTime.now().plus({ hours: 0 }).set({ minute: 0 }).toJSDate(),
  })

  const [absenceDataEdit, setNewAbsenceDataEdit] = useState<{
    nickname: string,
    area: string,
    service_resource_id: string,
    service_resource_code: number,
    area_id: string,
    time_zone: string
  }[] | null>(null)

  const [timeSlots, setTimeSlots] = useState<ScheduleProps[]>([])
  const [isLoadTimeSlots, setIsLoadTimeSlots] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string[]>([])

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      if (newAbsenceData.type === 'Range') {
        let start = moment(newAbsenceData.absence_start).format('MM/DD/YYYY hh:mma')
        let end = moment(newAbsenceData.absence_end).format('MM/DD/YYYY hh:mma')
        let timeZone = absenceDataEdit?.filter(item => item.service_resource_id === newAbsenceData.service_resource)

        const response = await httpClientUpdate.post(`/absences`, {
          account_id: activeAccountId,
          area_id: absenceDataEdit && absenceDataEdit.find((item) => item.service_resource_id === newAbsenceData.service_resource)?.area_id as string,
          service_resource_id: newAbsenceData.service_resource,
          absence_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone && timeZone[0].time_zone, start),
          absence_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', timeZone && timeZone[0].time_zone, end),
          note: newAbsenceData.note,
        })

        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('absences', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      } else {
        const response = await httpClientUpdate.post(`/absences`, {
          account_id: activeAccountId,
          area_id: absenceDataEdit && absenceDataEdit.find((item) => item.service_resource_id === newAbsenceData.service_resource)?.area_id as string,
          service_resource_id: newAbsenceData.service_resource,
          time_slot_ids: selectedTimeSlot,
          date: moment(newAbsenceData.date).format('YYYY-MM-DD'),
          type: 'timeslot',
          note: newAbsenceData.note,
        })

        if (response.data.success) {
          setIsSaving(false)
          $router.router.navigate('absences', {
            companyId: activeAccountId,
          }, { reload: true })
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {

      const { data: { data: absenceData, success, error } } = (await httpClientUpdate.post('/absences/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 100,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (absenceData.permissions.absence_add) {
          setPermissions(absenceData.permissions)
          setNewAbsenceDataEdit(absenceData.edit.service_resource)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function getTimeSlots() {
    try {
      const { data: { data: timeSlotsReport, success, error } } = (await httpClientUpdate.get('/serviceresources/schedule', {
        params: {
          account_id: activeAccountId,
          service_resource_id: newAbsenceData.service_resource,
          date: moment(newAbsenceData.date).format('MM/DD/YYYY')
        }
      })) as { data: HttpTimeSlotsReport }
      if (success) {
        setIsLoadTimeSlots(true)
        setTimeSlots(timeSlotsReport.schedule.sort((a, b) => a.from > b.from ? 1 : -1).map(item => {

          return {
            ...item,
            from: moment(item.from, 'hh:mm:ss').format('hh:mmA'),
            to: moment(item.to, 'hh:mm:ss').format('hh:mmA'),
          }
        }))
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newAbsenceData
  ])

  useEffect(() => {
    setIsLoadTimeSlots(false)
    setSelectedTimeSlot([])
  }, [newAbsenceData.type])

  useEffect(() => {
    if (newAbsenceData.type === 'Timeslot' && newAbsenceData.date && newAbsenceData.service_resource) {
      getTimeSlots()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAbsenceData.date, newAbsenceData.type, newAbsenceData.service_resource])

  // Handle service resource changing
  useEffect(() => {

    let sr = newAbsenceData.service_resource

    if (!absenceDataEdit) return

    setNewAbsenceData({
      ...newAbsenceData,
      area: absenceDataEdit.find((item) => item.service_resource_id === sr)?.area as string,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAbsenceData.service_resource])

  useEffect(() => {
    if (newAbsenceData.type === 'Range') {
      let startDate = moment(newAbsenceData.absence_start).format('YYYY/MM/DD')
      let endDate = moment(newAbsenceData.absence_end).format('YYYY/MM/DD')

      if (startDate === endDate) {
        let startTime = moment(newAbsenceData.absence_start).format('HH:mm')
        let endTime = moment(newAbsenceData.absence_end).format('HH:mm')

        if (startTime >= endTime) {
          setErrorTime(true)
        } else {
          setErrorTime(false)
        }
      } else if (startDate > endDate) {
        setErrorTime(true)
      } else {
        setErrorTime(false)
      }
    } else {
      setErrorTime(false)
    }
  }, [newAbsenceData])

  useEffect(() => {
    if (newAbsenceData.type === 'Timeslot') {
      setSelectedTimeSlot([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAbsenceData.date, newAbsenceData.service_resource])

  function handleChangeServiceResources(value: string) {
    setNewAbsenceData({ ...newAbsenceData, service_resource: value })
  }

  function handleSelectedTimeSlot(id: string) {
    if (selectedTimeSlot.includes(id)) {
      setSelectedTimeSlot(selectedTimeSlot.filter(item => item !== id))
    } else {
      let updatedSelected = selectedTimeSlot.map(item => item)
      updatedSelected.push(id)
      setSelectedTimeSlot(updatedSelected)
    }
  }

  // Render function
  return (<>
    {absenceDataEdit && permissions.absence_add ? (
      <div className="AbsencesPage_Item AbsencePage_New entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Absence</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('absences', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        {
          newAbsenceData.type === 'Range' ?
            <div className="fieldset">
              <div className="legend">Main</div>

              <div className="fields">
                <div className="__left">
                  <div className="field" style={{ zIndex: 6 }}>
                    <span>Service resource:</span>
                    <Select
                      options={absenceDataEdit.map((option) => ({
                        span: option.service_resource_code ? `${option.nickname}(${option.service_resource_code})` : option.nickname,
                        value: option.service_resource_id
                      }))}
                      selectedOption={newAbsenceData.service_resource as string}
                      onChange={(value) => handleChangeServiceResources(value as string)} />
                  </div>

                  <div className="field">
                    <span>Area:</span>
                    <input
                      disabled={true}
                      type="text"
                      defaultValue={newAbsenceData.area}
                    />
                  </div>

                  <div className="field">
                    <span>Type:</span>
                    <Select
                      options={['Range', 'Timeslot'].map((option) => ({
                        span: option,
                        value: option
                      }))}
                      selectedOption={newAbsenceData.type as string}
                      onChange={(value) => setNewAbsenceData({ ...newAbsenceData, type: value as string })} />
                  </div>
                </div>


                <div className="__right">

                  <div className="field">
                    <span>Absence start:</span>
                    <DateTimeComponent
                      className={classNames({
                        error: errorTime
                      })}
                      value={newAbsenceData.absence_start as Date}
                      onChange={(value) => setNewAbsenceData({ ...newAbsenceData, absence_start: moment.isMoment(value) ? value.toDate() : newAbsenceData.absence_start })}
                    />
                  </div>

                  <div className="field">
                    <span>Absence end:</span>
                    <DateTimeComponent
                      className={classNames({
                        error: errorTime
                      })}
                      value={newAbsenceData.absence_end as Date}
                      onChange={(value) => setNewAbsenceData({ ...newAbsenceData, absence_end: moment.isMoment(value) ? value.toDate() : newAbsenceData.absence_end })}
                    />
                  </div>
                </div>
              </div>
            </div> :
            <>
              {
                width >= 778 ?
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 300px)' }}>
                      <div className="fieldset">
                        <div className="legend">Main</div>

                        <div className="fields">
                          <div className="__left">
                            <div className="field" style={{ zIndex: 6 }}>
                              <span>Service resource:</span>
                              <Select
                                options={absenceDataEdit.map((option) => ({
                                  span: option.service_resource_code ? `${option.nickname}(${option.service_resource_code})` : option.nickname,
                                  value: option.service_resource_id
                                }))}
                                selectedOption={newAbsenceData.service_resource as string}
                                onChange={(value) => handleChangeServiceResources(value as string)} />
                            </div>

                            <div className="field">
                              <span>Area:</span>
                              <input
                                disabled={true}
                                type="text"
                                defaultValue={newAbsenceData.area}
                              />
                            </div>

                            <div className="field">
                              <span>Type:</span>
                              <Select
                                options={['Range', 'Timeslot'].map((option) => ({
                                  span: option,
                                  value: option
                                }))}
                                selectedOption={newAbsenceData.type as string}
                                onChange={(value) => setNewAbsenceData({ ...newAbsenceData, type: value as string })} />
                            </div>
                          </div>


                          <div className="__right">
                            <div className="field">
                              <span>Date:</span>

                              <DateTimeComponent
                                dateFormat={true}
                                timeFormat={false}
                                value={newAbsenceData.date as Date}
                                onChange={(value) => setNewAbsenceData({ ...newAbsenceData, date: moment.isMoment(value) ? value.toDate() : newAbsenceData.date })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="fieldset">
                        <div className="legend">Note</div>

                        <div className="fields">
                          <div className="__left">
                            <div className="field">
                              <span>Note:</span>
                              <input type="text"
                                value={newAbsenceData.note || ''}
                                onChange={({ target: { value } }) => setNewAbsenceData({ ...newAbsenceData, note: value })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="fieldset"
                      style={{ width: '300px' }}
                    >
                      <div className="legend">Time slots:</div>
                      <div className="field_time_slots">
                        {
                          isLoadTimeSlots &&
                          <div className="time_slots">
                            {
                              timeSlots.length ? timeSlots.map(item => (
                                <div
                                  key={item.schedule_id}
                                  className={classNames('time_slots_item',
                                    {
                                      selected: selectedTimeSlot.includes(item.schedule_id),
                                    }
                                  )}
                                  onClick={() => handleSelectedTimeSlot(item.schedule_id)}
                                >
                                  <p> {item.from} - {item.to} </p>
                                </div>
                              )) :
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    color: '#FF0000',
                                    margin: 0
                                  }}
                                >
                                  There are no available timeslots for this day. Please check another date.
                                </p>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div> :
                  <>
                    <div className="fieldset">
                      <div className="legend">Main</div>

                      <div className="fields">
                        <div className="__left">
                          <div className="field" style={{ zIndex: 6 }}>
                            <span>Service resource:</span>
                            <Select
                              options={absenceDataEdit.map((option) => ({
                                span: option.service_resource_code ? `${option.nickname}(${option.service_resource_code})` : option.nickname,
                                value: option.service_resource_id
                              }))}
                              selectedOption={newAbsenceData.service_resource as string}
                              onChange={(value) => handleChangeServiceResources(value as string)} />
                          </div>

                          <div className="field">
                            <span>Area:</span>
                            <input
                              disabled={true}
                              type="text"
                              defaultValue={newAbsenceData.area}
                            />
                          </div>

                          <div className="field">
                            <span>Type:</span>
                            <Select
                              options={['Range', 'Timeslot'].map((option) => ({
                                span: option,
                                value: option
                              }))}
                              selectedOption={newAbsenceData.type as string}
                              onChange={(value) => setNewAbsenceData({ ...newAbsenceData, type: value as string })} />
                          </div>
                        </div>


                        <div className="__right">
                          <div className="field">
                            <span>Date:</span>

                            <DateTimeComponent
                              dateFormat={true}
                              timeFormat={false}
                              value={newAbsenceData.date as Date}
                              onChange={(value) => setNewAbsenceData({ ...newAbsenceData, date: moment.isMoment(value) ? value.toDate() : newAbsenceData.date })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fieldset">
                      <div className="legend">Time slots:</div>
                      <div className="field_time_slots">
                        {
                          isLoadTimeSlots &&
                          <div className="time_slots">
                            {
                              timeSlots.length ? timeSlots.map(item => (
                                <div
                                  key={item.schedule_id}
                                  className={classNames('time_slots_item',
                                    {
                                      selected: selectedTimeSlot.includes(item.schedule_id),
                                    }
                                  )}
                                  onClick={() => handleSelectedTimeSlot(item.schedule_id)}
                                >
                                  <p> {item.from} - {item.to} </p>
                                </div>
                              )) :
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    color: '#FF0000',
                                    margin: 0
                                  }}
                                >
                                  There are no available timeslots for this day. Please check another date.
                                </p>
                            }
                          </div>
                        }
                      </div>
                    </div>

                    <div className="fieldset">
                      <div className="legend">Note</div>

                      <div className="fields">
                        <div className="__left">
                          <div className="field">
                            <span>Note:</span>
                            <input type="text"
                              value={newAbsenceData.note || ''}
                              onChange={({ target: { value } }) => setNewAbsenceData({ ...newAbsenceData, note: value })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              }
            </>
        }

        {
          newAbsenceData.type === 'Range' &&
          <div className="fieldset">
            <div className="legend">Note</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Note:</span>
                  <input type="text"
                    value={newAbsenceData.note || ''}
                    onChange={({ target: { value } }) => setNewAbsenceData({ ...newAbsenceData, note: value })}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        <div className="wrapper flex-container sb editing-buttons">
          <div></div>
          <div className="buttons">
            <button className="_bordered _red" onClick={() => $router.router.navigate('absences', {
              companyId: activeAccountId
            }, { reload: true })}>
              Cancel
            </button>
            {
              permissions.absence_add &&
              <button
                disabled={!readyToSave || newAbsenceData.service_resource === '' || (isLoadTimeSlots && !selectedTimeSlot.length) || isSaving || errorTime}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            }
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default AbsencesPage_New
