import { useEffect, useState } from "react"

import Icon from "../../components/Icon"
import AutoCompliteSelectComponent from "../../components/AutoCompliteSelect"

import { AppointDataProps } from "./List"
import { UnitProps } from "./Models"

interface BlockProps {
  editing: boolean
  setEditing: (value: string) => void
  data: UnitProps[]
  setData: (value: UnitProps[]) => void
  appointmentReport: Partial<AppointDataProps>
  disabledEditButton: boolean
  updatedUnitsItem: () => void
  status: boolean
  permmisions_units_edit: boolean
  isSending: boolean
}

let unitTypeOptions = ['Residential', 'Commercial', 'Industrial']

export default function UnitBlock({
  editing,
  setEditing,
  data,
  setData,
  appointmentReport,
  disabledEditButton,
  updatedUnitsItem,
  status,
  permmisions_units_edit,
  isSending,
}: BlockProps) {
  const [readyToSave, setReadyToSave] = useState(false)
  const [isNewUnit, setIsNewUnit] = useState(false)
  const [editUnit, setEditUnit] = useState(0)
  const [newUnit, setNewUnit] = useState({
    appliance_id: '',
    brand_id: '',
    appliance: '',
    brand: '',
    description: '',
    other_appliance: '',
    other_brand: '',
    unit_id: '',
    unit_type: '',
    model_number: '',
    serial_number: '',
    price: 'Diagnostic Fee $69',
    is_appointment: 0,
    is_new: true,
    is_use: false
  })

  useEffect(() => {
    editing && setReadyToSave(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleCancel() {
    setEditing('')
    setReadyToSave(false)
    setIsNewUnit(false)
    appointmentReport.units && setData(appointmentReport.units.map(item => {
      return {
        ...item,
        is_new: false,
        is_use: !!item.is_appointment
      }
    }))
    handleCancelEditUnit()
  }

  function handleSave() {
    setEditing('')
    setReadyToSave(false)
    updatedUnitsItem()
  }

  function handleRemoveUnit(index: number) {
    setData(data.filter((item, idx) => idx !== index))
  }

  function handleEditUnit(index: number) {
    setNewUnit({
      appliance_id: data[index].appliance_id,
      brand_id: data[index].brand_id,
      appliance: data[index].appliance,
      brand: data[index].brand,
      description: data[index].description,
      other_appliance: data[index].other_appliance,
      other_brand: data[index].other_brand,
      unit_id: data[index].unit_id,
      unit_type: data[index].unit_type,
      model_number: data[index].model_number,
      serial_number: data[index].serial_number,
      price: data[index].price,
      is_appointment: data[index].is_appointment,
      is_new: data[index].is_new,
      is_use: data[index].is_use
    })

    setEditUnit(index + 1)
  }

  function handleUseUnit(value: boolean, index: number) {
    let updateUnitsList = data.map((item) => item)
    updateUnitsList[index].is_use = value
    setData(updateUnitsList)
  }

  function handleCancelEditUnit() {
    setEditUnit(0)

    setNewUnit({
      appliance_id: '',
      brand_id: '',
      appliance: '',
      brand: '',
      other_appliance: '',
      other_brand: '',
      unit_id: '',
      unit_type: '',
      model_number: '',
      serial_number: '',
      description: '',
      price: 'Diagnostic Fee $69',
      is_appointment: 0,
      is_new: true,
      is_use: false
    })
  }

  function handleCancelNewUnit() {
    setIsNewUnit(false)

    setNewUnit({
      appliance_id: '',
      brand_id: '',
      appliance: '',
      brand: '',
      other_appliance: '',
      other_brand: '',
      unit_id: '',
      unit_type: '',
      model_number: '',
      serial_number: '',
      description: '',
      price: 'Diagnostic Fee $69',
      is_appointment: 0,
      is_new: true,
      is_use: false
    })
  }

  function handleSaveNewUnit() {
    let updatedPhones = data.map(item => item)
    updatedPhones.push({
      appliance_id: newUnit.appliance_id,
      appliance: newUnit.appliance,
      brand_id: newUnit.brand_id,
      brand: newUnit.brand,
      other_appliance: newUnit.other_appliance,
      other_brand: newUnit.other_brand,
      unit_id: newUnit.unit_id,
      unit_type: newUnit.unit_type,
      model_number: newUnit.model_number,
      serial_number: newUnit.serial_number,
      description: newUnit.description,
      price: newUnit.price,
      is_appointment: 0,
      is_new: newUnit.is_new,
      is_use: true
    })

    setData(updatedPhones)
    setIsNewUnit(false)

    setNewUnit({
      appliance_id: '',
      brand_id: '',
      appliance: '',
      brand: '',
      other_appliance: '',
      other_brand: '',
      unit_id: '',
      unit_type: '',
      model_number: '',
      serial_number: '',
      description: '',
      price: 'Diagnostic Fee $69',
      is_appointment: 0,
      is_new: true,
      is_use: false
    })
  }

  function handleSaveEditUnit() {
    let updatedPhones = data.map((item, index) => {

      if (index === editUnit - 1) {
        return {
          appliance_id: newUnit.appliance_id,
          appliance: newUnit.appliance,
          brand_id: newUnit.brand_id,
          brand: newUnit.brand,
          other_appliance: newUnit.other_appliance,
          other_brand: newUnit.other_brand,
          unit_id: newUnit.unit_id,
          unit_type: newUnit.unit_type,
          model_number: newUnit.model_number,
          serial_number: newUnit.serial_number,
          description: newUnit.description,
          price: newUnit.price,
          is_appointment: newUnit.is_appointment,
          is_new: newUnit.is_new,
          is_use: newUnit.is_use
        }
      } else {
        return { ...item }
      }
    })

    setData(updatedPhones)
    setEditUnit(0)

    setNewUnit({
      appliance_id: '',
      brand_id: '',
      appliance: '',
      brand: '',
      other_appliance: '',
      other_brand: '',
      unit_id: '',
      unit_type: '',
      model_number: '',
      serial_number: '',
      description: '',
      price: 'Diagnostic Fee $69',
      is_appointment: 0,
      is_new: true,
      is_use: false
    })
  }

  function checkSaveNewUnit() {
    let check = false
    if (newUnit.unit_type === '') {
      check = true
    }
    if (newUnit.appliance_id === '') {
      check = true
    }

    return check
  }

  function handleSelectAutoComplite(id: string, span: string, name: string, otherSpan?: string) {
    if (name === 'appliance') {
      setNewUnit({ ...newUnit, appliance_id: id, appliance: span, other_appliance: otherSpan ? otherSpan : '' })
    } else {
      setNewUnit({ ...newUnit, brand_id: id, brand: span, other_brand: otherSpan ? otherSpan : '' })
    }
  }

  return (
    <>
      <div className='call-units'>
        <div className='block-content'>
          <div className='block-content-title'>
            <div className='block-title'>Units</div>
          </div>

          <div
            className="action-buttons"
            style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '10px' }}
          >
            {
              !editing &&
              <button
                className="btn btn-add"
                disabled={disabledEditButton || status || !permmisions_units_edit}
                onClick={() => setEditing('Units')}
              >
                Edit
              </button>
            }

            {
              editing &&
              <>
                <button
                  disabled={!!editUnit}
                  className="btn btn-add btn-cancel"
                  style={{ width: 'fit-content', marginRight: '15px' }}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSending}
                  className="btn btn-add btn-save"
                  style={{ width: 'fit-content' }}
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </>
            }
          </div>

          <div className='data-border call-units-content'>
            <div className='header-units'>
              <div style={{ marginRight: '60px' }}>
                <p>Use</p>
              </div>
            </div>
            {
              data.map((item, index) => (
                <div key={index} className='block-content-units'>
                  <div>
                    <p>
                      {!!editUnit && editUnit - 1 === index && '* '}
                      {item.unit_type}:{' '}
                      {item.appliance === 'Other' ? item.other_appliance : item.appliance},{' '}
                      {item.brand === 'Other' ? item.other_brand : item.brand}{' '}
                      <span style={{ color: 'rgb(142, 142, 147)' }}>
                        {item.price ? `/ Price: ${item.price}` : ''}
                      </span>
                    </p>
                    <p style={{ color: 'rgb(142, 142, 147)' }}>
                      {item.serial_number && 's/n: '}
                      {item.serial_number && item.serial_number}
                      {item.serial_number && item.model_number && ' /'}
                      {item.model_number && ' m/n: '}
                      {item.model_number && item.model_number}
                    </p>
                    <p style={{ color: 'rgb(142, 142, 147)' }}> {item.description} </p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      className='trash call-contact_new-block_trash inactive'
                      style={{ marginRight: '34px', cursor: editing ? 'pointer' : 'inherit' }}
                    >
                      <div className='check'>
                        <input
                          className='input-checkbox'
                          name='is_use'
                          type="checkbox"
                          disabled={!editing}
                          style={{ cursor: editing ? 'pointer' : 'inherit' }}
                          checked={!!item.is_use}
                          onChange={(event) => handleUseUnit(event.target.checked, index)}
                        />

                        <label className='checkbox' htmlFor="">
                          {
                            !!item.is_use &&
                            <Icon style={{ fill: '#6093DE', width: '90%' }} icon="check-mark-1" />
                          }
                        </label>
                      </div>
                    </div>

                    <div
                      className='trash call-contact_new-block_trash inactive'
                      style={{ marginRight: '10px' }}
                      onClick={() => editing && !!!editUnit && !isNewUnit && handleEditUnit(index)}
                    >
                      <Icon
                        style={{
                          fill: editing && !!!editUnit && !isNewUnit ? '#FFB700' : '#8e8e93',
                          width: '100%',
                          cursor: editing && !!!editUnit && !isNewUnit ? 'pointer' : 'inherit'
                        }}
                        viewBox="0 0 16 16"
                        icon="pencil-1"
                      />
                    </div>

                    <div
                      className='trash call-contact_new-block_trash inactive'
                      onClick={() => item.is_new && !!!editUnit && !isNewUnit && handleRemoveUnit(index)}
                    >
                      <Icon
                        style={{
                          fill: item.is_new && !!!editUnit && !isNewUnit ? '#FF0000' : '#8e8e93',
                          width: '90%',
                          cursor: item.is_new && !!!editUnit && !isNewUnit ? 'pointer' : 'inherit'
                        }}
                        icon="x-mark-1"
                      />
                    </div>
                  </div>
                </div>
              ))
            }

            {
              editing && !isNewUnit && !!!editUnit &&
              <div className='call-units-add'>
                <p className='units-add-title'>Add Unit:</p>

                <div className='call-units_content-add'>
                  <button
                    onClick={() => setIsNewUnit(true)}
                    className='btn btn-add'
                  >
                    Add
                  </button>
                </div>
              </div>
            }
          </div>


          {
            (isNewUnit || !!editUnit) &&
            <div className='call-units_content'>
              <div className='data-border call-units_add'>
                <div style={{ flexWrap: 'wrap' }} className='call-units_add-row'>
                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Unit Type:</p>

                    <AutoCompliteSelectComponent options={unitTypeOptions.map((type) => ({
                      span: type,
                      value: type
                    }))}
                      selectedOption={newUnit.unit_type}
                      onChange={(value: string) => setNewUnit({ ...newUnit, unit_type: value })}
                    />
                  </div>

                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Unit:</p>

                    {
                      appointmentReport?.edit?.appliances ?
                        <AutoCompliteSelectComponent
                          disabled={!editing}
                          options={appointmentReport.edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                            span: type.name,
                            value: type.appliance_id
                          }))}
                          selectedOption={newUnit.appliance_id}
                          onChange={(value: string, span: string, otherSpan: string) =>
                            handleSelectAutoComplite(value, span, 'appliance', otherSpan)
                          }
                        /> :
                        <input type="text"
                          disabled={!editing}
                          value={newUnit.appliance_id}
                          onChange={({ target: { value } }) => setNewUnit({ ...newUnit, appliance_id: value })}
                        />
                    }
                  </div>

                  {
                    newUnit.appliance === 'Other' &&
                    <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                      <p className='call-units_add-data_title'>Other Unit:</p>

                      <input
                        style={{ width: '50%' }}
                        value={newUnit.other_appliance}
                        onChange={(event) => setNewUnit({ ...newUnit, other_appliance: event.target.value })}
                        className='data-border call-units_add-modelNumber_input'
                        type="text"
                      />
                    </div>
                  }

                  <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                    <p className='call-units_add-data_title'>Brand:</p>

                    {
                      appointmentReport?.edit?.brands ?
                        <AutoCompliteSelectComponent
                          disabled={!editing}
                          options={appointmentReport.edit.brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                            span: type.name,
                            value: type.brand_id
                          }))}
                          selectedOption={newUnit.brand_id}
                          onChange={(value: string, span: string, otherSpan: string) =>
                            handleSelectAutoComplite(value, span, 'brand', otherSpan)
                          }
                        /> :
                        <input type="text"
                          disabled={!editing}
                          value={newUnit.brand_id}
                          onChange={({ target: { value } }) => setNewUnit({ ...newUnit, brand_id: value })}
                        />
                    }
                  </div>

                  {
                    newUnit.brand === 'Other' &&
                    <div style={{ width: '25%', marginBottom: '10px' }} className='call-units_add-data'>
                      <p className='call-units_add-data_title'>Other Brand:</p>

                      <input
                        style={{ width: '50%' }}
                        value={newUnit.other_brand}
                        onChange={(event) => setNewUnit({ ...newUnit, other_brand: event.target.value })}
                        className='data-border call-units_add-modelNumber_input'
                        type="text"
                      />
                    </div>
                  }

                  <div style={{ width: '30%', marginBottom: '10px' }} className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor="">Model Number:</label>

                    <input
                      style={{ width: '50%' }}
                      value={newUnit.model_number}
                      onChange={(event) => setNewUnit({ ...newUnit, model_number: event.target.value })}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>

                  <div style={{ width: '30%', marginBottom: '10px' }} className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor="">Serial Number:</label>

                    <input
                      style={{ width: '50%' }}
                      value={newUnit.serial_number}
                      onChange={(event) => setNewUnit({ ...newUnit, serial_number: event.target.value })}
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                    />
                  </div>
                </div>

                <div className='call-units_add-row'>
                  <div className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor="">Description:</label>

                    <textarea
                      className='data-border call-units_add-description_textarea'
                      value={newUnit.description}
                      onChange={(event) => setNewUnit({ ...newUnit, description: event.target.value })}
                      cols={2}
                      rows={2}
                    ></textarea>
                  </div>

                  <div className='call-units_add-data'>
                    <label className='call-units_add-data_title' htmlFor=""> Price: </label>

                    <input
                      className='data-border call-units_add-modelNumber_input'
                      type="text"
                      value={newUnit.price}
                      onChange={(event) => setNewUnit({ ...newUnit, price: event.target.value })}
                    />
                  </div>
                </div>

                {
                  !!editUnit &&
                  <div className='call-units-add'>
                    <div className='call-units_content-add'>
                      <button
                        className='btn btn-add btn-cancel'
                        style={{ marginRight: '15px' }}
                        onClick={() => handleCancelEditUnit()}
                      >
                        Cancel
                      </button>

                      <button
                        className='btn btn-add btn-save'
                        onClick={() => handleSaveEditUnit()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                }

                {
                  isNewUnit &&
                  <div className='call-units-add'>
                    <div className='call-units_content-add'>
                      <button
                        className='btn btn-add btn-cancel'
                        style={{ marginRight: '15px' }}
                        onClick={() => handleCancelNewUnit()}
                      >
                        Cancel
                      </button>

                      <button
                        className='btn btn-add btn-save'
                        disabled={checkSaveNewUnit()}
                        onClick={() => handleSaveNewUnit()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
