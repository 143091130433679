import React, { useState, useEffect } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select";

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from "../../funcs";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/lists.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ListAreas
}

interface PermissionsProps {
  list_area_add: boolean
  list_area_delete: boolean
  list_area_edit: boolean
  list_area_show: boolean
}

interface AreasItem {
  area_id: string,
  priority: number,
  name: string,
  time_zone_id: number,
  time_zone: string
  editing: boolean
}

interface ListAreas {
  list_areas: AreasItem[]
  edit: {
    time_zone: TimeZoneProps[]
  }
  permissions: PermissionsProps
}

interface TimeZoneProps {
  id: number
  name: string
}

const timeZoneMock = [
  { id: 1, name: "America/New_York" },
  { id: 2, name: "America/Los_Angeles" }
]

function ListsPage_Areas() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [isSaving, setIsSaving] = useState(false)
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)

  const [newItemForm, setNewItemForm] = useState({
    name: '',
    time_zone_id: 0,
    time_zone: '',
  })

  const [editItemForm, setEditItemForm] = useState({
    name: '',
    time_zone_id: 0,
    time_zone: '',
    priority: 0,
  })

  const [items, setItems] = useState<AreasItem[] | []>([])
  const [editTimeZone, setEditTimeZone] = useState<TimeZoneProps[] | []>([])
  const [permissions, setPermissions] = useState({
    list_area_add: false,
    list_area_delete: false,
    list_area_edit: false,
    list_area_show: false,
  })
  // Load Areas function
  async function loadAreas() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    try {
      const { data: { data: areas, success, error } } = (await httpClientUpdate.get(`/lists/areas`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setPermissions(areas.permissions)
        setEditTimeZone(areas?.edit?.time_zone ? areas.edit.time_zone : timeZoneMock)
        setItems(areas?.list_areas.map(item => {
          return { ...item, editing: false }
        }))
        errorEdit && setErrorEdit(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // edit function
  async function editAreas(id: string, name: string, time_zone_id: number, priority: number) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.put(`/lists/areas/${id}`, qs.stringify({
        account_id: activeAccountId,
        name,
        time_zone_id,
        priority
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        return true
      } else {
        setIsSaving(false)
        return false
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorEdit(true)
      }
    }
  }

  // add function
  async function addAreas(name: string, time_zone_id: number) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.post(`/lists/areas`, qs.stringify({
        account_id: activeAccountId,
        name,
        time_zone_id: Number(time_zone_id),
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: '',
          time_zone_id: 0,
          time_zone: '',
        })
        return loadAreas()
      } else {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: '',
          time_zone_id: 0,
          time_zone: '',
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorAdd(true)
      }
    }
  }

  // remove function
  async function removeAreas(id: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/lists/areas/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        return loadAreas()
      } else {
        setIsSaving(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadAreas()

    // unmount page
    return () => {
      setItems([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    if (errorAdd && newItemForm.name) {
      setErrorAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemForm.name])

  useEffect(() => {
    if (errorEdit && editItemForm.name) {
      setErrorEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemForm.name])

  // Add item function
  function addItem() {

    let { name, time_zone_id } = newItemForm

    addAreas(name, time_zone_id)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...items]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setItems(_items)

    setEditItemForm({
      ...editItemForm,
      name: flag ? _items[i].name : '',
      time_zone_id: flag ? _items[i].time_zone_id : 0,
      time_zone: flag ? _items[i].time_zone : '',
      priority: flag ? _items[i].priority : 0,
    })
  }

  // Set item function
  async function setItem(i: number) {

    let _items = [...items]
    _items[i] = {
      name: editItemForm.name,
      time_zone_id: editItemForm.time_zone_id,
      time_zone: editItemForm.time_zone,
      priority: editItemForm.priority,
      editing: false,
      area_id: _items[i].area_id
    }

    let update = await editAreas(_items[i].area_id, _items[i].name, _items[i].time_zone_id, _items[i].priority)
    update && setItems(_items)
  }

  // Render function
  return (
    <div className="ListsPage_Areas">

      {/* Top navigation */}
      <div className="top-nav top-nav-list">
        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.appliances"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.appliances" })}
          >
            <Icon icon="task-1" />
            <span>Appliances</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.brands"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.brands" })}
          >
            <Icon icon="task-1" />
            <span>Brands</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.areas"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.areas" })}
          >
            <Icon icon="task-1" />
            <span>Areas</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.sources"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.sources" })}
          >
            <Icon icon="task-1" />
            <span>Sources</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.spam_filters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.spam_filters" })}
          >
            <Icon icon="task-1" />
            <span>Spam Filters</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.campaigns"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.campaigns" })}
          >
            <Icon icon="task-1" />
            <span>Campaigns</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.companies"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.companies" })}
          >
            <Icon icon="task-1" />
            <span>Companies</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.payments_sevices"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.payments_sevices" })}
          >
            <Icon icon="task-1" />
            <span>Payment Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.warehouses"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.warehouses" })}
          >
            <Icon icon="task-1" />
            <span>Warehouses</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.ticket_templates"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.ticket_templates" })}
          >
            <Icon icon="task-1" />
            <span>Ticket Templates</span>
          </BaseLink>
        </div>
      </div>

      {/* Contents */}
      <div className="wrapper">

        <div className="flex-container sb header">
          <h1>List of Areas</h1>

          {
            (errorAdd || errorEdit) &&
            <span style={{ color: '#FF0000' }}>This name is already taken</span>
          }

          {
            permissions.list_area_add &&
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gridGap: '15px',
                gap: '15px',
              }}
              className="flex-container-select"
            >
              <div className="label">Name:</div>

              <input
                type="text"
                value={newItemForm.name}
                onChange={({ target: { value } }) => setNewItemForm({ ...newItemForm, name: value })}
              />

              <div className="label">Time Zone:</div>

              <Select
                options={editTimeZone.map((option) => ({
                  span: option.name,
                  value: option.id
                }))}
                selectedOption={newItemForm.time_zone_id}
                onChange={(value, span) => setNewItemForm({ ...newItemForm, time_zone_id: +value, time_zone: span })}
              />

              <button
                className="_wa _green"
                disabled={newItemForm.name.length < 1 || newItemForm.time_zone_id === 0 || isSaving || errorAdd}
                onClick={(e) => { e.preventDefault(); addItem() }}
              >
                Add
              </button>
            </div>
          }
        </div>

        {/* Table */}
        {
          !!items.length &&
          permissions.list_area_show &&
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '40%' }}>Name</th>
                <th style={{ width: '50%' }}>Time Zone</th>
                <th style={{ width: '10%' }}>Priority</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {items.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_area_edit}
                        defaultValue={editItemForm.name}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, name: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="editing-form editing-form-select">
                      <Select
                        disabled={!permissions.list_area_edit}
                        options={editTimeZone.map((option) => ({
                          span: option.name,
                          value: option.id
                        }))}
                        selectedOption={editItemForm.time_zone_id}
                        onChange={(value, span) => setEditItemForm({ ...editItemForm, time_zone_id: +value, time_zone: span })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed appearance"
                        type="number"
                        disabled={!permissions.list_area_edit}
                        defaultValue={editItemForm.priority}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, priority: +value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={isSaving || errorEdit}
                      onClick={() => setItem(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_area_delete || isSaving}
                      onClick={() => removeAreas(item.area_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.time_zone}</td>
                  <td>{item.priority}</td>
                  <td>
                    <button
                      disabled={!permissions.list_area_edit}
                      className="_zeroed _iconed _blue"
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      disabled={!permissions.list_area_delete || isSaving}
                      className="_zeroed _iconed _red"
                      onClick={() => removeAreas(item.area_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>
        }
      </div>
    </div >
  )
}

export default ListsPage_Areas
