import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment from 'moment'
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { Deposit } from '../../models/Deposite'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"
import "../../styles/pages/payouts.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DepositData
}

interface DepositData {
  permissions: {
    deposit_add: boolean
    deposit_delete: boolean
    deposit_edit: boolean
    deposit_report_show: boolean
    deposit_show: boolean
  },
  deposit: Deposit

  edit: {
    status: {
      id: number
      name: string
    }[],
    service: {
      name: string
      payment_services_id: string
    }[]
  }
}
const paymentsData = [
  {
    payment_id: '1',
    date: '01/11/2023',
    type: 'complite',
    number: '123',
    service: 'Service',
    status: 'Status',
    net: 300,
    total: 310,
    fee: 10
  },
  {
    payment_id: '2',
    date: '01/21/2023',
    type: 'complite',
    number: '456',
    service: 'Service',
    status: 'Status',
    net: 500,
    total: 520,
    fee: 20
  },
  {
    payment_id: '3',
    date: '01/25/2023',
    type: 'complite',
    number: '789',
    service: 'Service',
    status: 'Status',
    net: 1300,
    total: 1343,
    fee: 43
  }
]

interface PaymentsProps {
  payment_id: string,
  date: string,
  type: string,
  number: string,
  service: string,
  status: string,
  net: number,
  total: number,
  fee: number,
  check?: boolean,
}

export default function DepositsPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [payments, setPayments] = useState<PaymentsProps[] | []>([])
  const [depositData, setDepositData] = useState<DepositData | null>(null)
  const [newDepositData, setNewDepositData] = useState<Partial<Deposit>>({
    status: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    depositData && setNewDepositData(depositData.deposit[0])
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/deposits/${$router.router.getState().params.depositsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('deposits', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      let status = depositData?.edit.status.filter(item => item.id === newDepositData.status_id)[0].name
      const response = await httpClientUpdate.put(`/deposits/${$router.router.getState().params.depositsId}`, qs.stringify({
        account_id: activeAccountId,
        deposit_id: newDepositData.deposit_id,
        bank_date: newDepositData.bank_date,
        type: newDepositData.type,
        service_id: newDepositData.service_id,
        status: status,
        status_id: newDepositData.status_id,
        payments: newDepositData.payments,
        total: newDepositData.total,
        net: newDepositData.net,
        fee: newDepositData.fee
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: depositData, success, error } } = await httpClientUpdate.get('/deposits/' + $router.router.getState().params.depositsId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (depositData.permissions.deposit_show) {
          setDepositData(depositData)
          setNewDepositData(depositData.deposit[0])
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newDepositData, payments
  ])

  function handleDownloadPayments() {
    let download = paymentsData.map(item => {
      return {
        ...item,
        check: false,
      }
    })
    setPayments(download)
  }

  function getSum(name: string) {
    let sum = 0
    payments.length && payments.forEach(item => sum = sum + item[name])
    return sum
  }

  function getSumCheck(name: string) {
    let sum = 0
    payments.length && payments.forEach(item => {
      if (item.check) {
        sum = sum + item[name]
      }
    })
    return sum
  }

  function handleChangeChecbox(id: string, name: string) {
    let updatedPayments = payments.map(item => {
      if (item.payment_id === id) {
        return {
          ...item,
          [name]: !item[name]
        }
      } else {
        return item
      }
    })
    setPayments(updatedPayments)
  }

  function handleRemovePayment(id: string) {
    let updatedPayments = payments.filter(item => item.payment_id !== id)
    setPayments(updatedPayments)
  }

  return (
    <>
      {
        depositData &&
        depositData.permissions.deposit_show &&
        <div className="PayoutPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Deposit</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'deposits',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  depositData.permissions.deposit_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Bank Date:</span>
                  {
                    editing ?
                      <Datetime
                        // disabled={true}
                        value={moment(newDepositData.bank_date) || ''}
                        onChange={(value) => setNewDepositData({ ...newDepositData, bank_date: moment.isMoment(value) ? value.toDate() : new Date() })}
                      /> :
                      <input
                        type="text"
                        defaultValue={newDepositData.bank_date?.toString() || ''}
                        disabled={true}
                      />

                  }
                </div>

                <div className="field">
                  <span>Status:</span>
                  {
                    depositData?.edit?.status.length ?
                      <Select disabled={!editing} options={depositData.edit.status.map((option) => ({
                        span: option.name,
                        value: option.id
                      }))}
                        selectedOption={newDepositData.status_id as number}
                        onChange={(value) => setNewDepositData({ ...newDepositData, status_id: value as number })}
                      /> :
                      <input
                        type="text"
                        defaultValue={newDepositData.status || ''}
                        disabled={true}
                      />
                  }
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Type:</span>
                  <input
                    type="text"
                    value={newDepositData.type || ''}
                    disabled={!editing}
                    onChange={(event) => setNewDepositData({
                      ...newDepositData, type: event.target.value
                    })}
                  />
                </div>

                <div className="field">
                  <span>Service:</span>
                  <Select
                    disabled={!editing}
                    options={depositData.edit.service.map((option) => ({
                      span: option.name,
                      value: option.payment_services_id
                    }))}
                    selectedOption={newDepositData.service_id as string}
                    onChange={(value) => setNewDepositData({ ...newDepositData, service_id: value as string })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Payments */}
          <div className="fieldset">
            <div style={{ display: 'flex', alignItems: 'center' }} className="legend">
              Payments
              {
                editing &&
                <span
                  onClick={() => handleDownloadPayments()}
                >
                  <Icon
                    viewBox="0 0 16 16"
                    style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                    icon="download-1"
                  />
                </span>
              }
            </div>

            <table className={classNames('table', '__show-on-wide', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Number</th>
                  <th>Service</th>
                  <th>Status</th>
                  <th>Net</th>
                  <th>Total</th>
                  <th>Fee</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              {payments && payments.map((payment, i) => (
                <tr
                  key={i}
                // onClick={() => $router.router.navigate('deposits.item', {
                //   companyId: activeAccountId, depositId: deposit.deposit_id
                // }, { reload: true })}
                >
                  <td>{payment.date}</td>
                  <td>{payment.type}</td>
                  <td>{payment.number}</td>
                  <td>{payment.service}</td>
                  <td>{payment.status}</td>
                  <td>${payment.net}</td>
                  <td>${payment.total}</td>
                  <td>${payment.fee}</td>
                  <td
                    onClick={() => editing && handleChangeChecbox(payment.payment_id, 'check')}
                  >
                    <Icon
                      style={{ width: '20px', height: '20px', fill: payment.check ? '#01B41F' : '#C3C6CB' }}
                      icon="check-mark-1"
                    />
                  </td>
                  <td
                    onClick={() => editing && handleRemovePayment(payment.payment_id)}
                  >
                    <Icon
                      style={{ width: '20px', height: '20px', fill: "#FF0000" }}
                      icon="x-mark-1"
                    />
                  </td>
                </tr>
              ))}

              {
                !!payments.length &&
                <tr>
                  <td>In total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td> ${getSum('net')} </td>
                  <td> ${getSum('fee')} </td>
                  <td> ${getSum('total')} </td>

                  <td></td>
                  <td></td>
                </tr>
              }
            </table>

            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
              <tr></tr>
              {payments.map((payment, i) => (
                <tr
                  key={i}
                // onClick={() => $router.router.navigate('deposits.item', {
                //   companyId: activeAccountId, depositId: deposit.deposit_id
                // }, { reload: true })}
                >
                  <td>
                    <div>{payment.date}</div>
                    <div>{payment.type}</div>
                  </td>
                  <td>
                    <div>{payment.number}</div>
                    <div>{payment.service}</div>
                  </td>
                  <td>
                    <div>{payment.status}</div>
                  </td>
                  <td>
                    <div>Net: ${payment.net}</div>
                    <div>Total: ${payment.total}</div>
                    <div>Fee: ${payment.fee}</div>
                  </td>
                  <td>
                    <div
                      onClick={() => editing && handleChangeChecbox(payment.payment_id, 'check')}
                    >
                      <Icon
                        style={{ width: '20px', height: '20px', fill: payment.check ? '#01B41F' : '#C3C6CB' }}
                        icon="check-mark-1"
                      />
                    </div>
                    <div
                      onClick={() => editing && handleRemovePayment(payment.payment_id)}
                    >
                      <Icon
                        style={{ width: '20px', height: '20px', fill: "#FF0000" }}
                        icon="x-mark-1"
                      />
                    </div>
                  </td>
                </tr>
              ))}

              {
                !!payments.length &&
                <tr>
                  <td>In total</td>

                  <td> </td>
                  <td>Net: ${getSum('net')} </td>
                  <td>Fee: ${getSum('fee')} </td>
                  <td>Total: ${getSum('total')} </td>
                </tr>
              }
            </table>
            {
              !!payments.length &&
              <div
                className={classNames('__hide-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  padding: '10px',
                  fontWeight: 'bold'
                }}
              >
                <div>Payments:  {payments.filter(item => item.check).length} </div>
                <div>Net: ${getSumCheck('net')}</div>
                <div>Fee: ${getSumCheck('fee')}</div>
                <div>Total: ${getSumCheck('total')}</div>
              </div>
            }

            <div className={classNames('mobile-table', '__show-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>

              <div className="mobile-table-items">

                {payments.map((payment, i: number) => (
                  <div
                    className="item"
                    key={i}
                  //  onClick={() => $router.router.navigate('deposits.item', {
                  //   companyId: activeAccountId, depositId: deposit.deposit_id
                  // }, { reload: true })}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div><strong>{payment.type}</strong> ({payment.date}) </div>
                        <div>
                          {payment.number}
                        </div>
                        <div>
                          {payment.service}
                        </div>
                        <div>
                          {payment.status}
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          Net: <b>${payment.net}</b>
                        </div>
                        <div>
                          Total: <b>${payment.total}</b>
                        </div>
                        <div>
                          Fee: <b>${payment.fee}</b>
                        </div>
                      </div>
                    </div>

                    <div className="__bottom">

                      <div
                        className="__left"
                        onClick={() => editing && handleChangeChecbox(payment.payment_id, 'check')}
                      >
                        <Icon
                          style={{ width: '20px', height: '20px', fill: payment.check ? '#01B41F' : '#C3C6CB' }}
                          icon="check-mark-1"
                        />
                      </div>

                      <div
                        className="__right small"
                        onClick={() => editing && handleRemovePayment(payment.payment_id)}
                      >
                        <Icon
                          style={{ width: '20px', height: '20px', fill: "#FF0000" }}
                          icon="x-mark-1"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {
                  !!payments.length &&
                  <div
                    className="item"
                  //  onClick={() => $router.router.navigate('deposits.item', {
                  //   companyId: activeAccountId, depositId: deposit.deposit_id
                  // }, { reload: true })}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>
                          Net: ${getSum('net')}
                        </div>
                        <div>
                          Total: ${getSum('total')}
                        </div>
                        <div>
                          Fee: ${getSum('fee')}
                        </div>
                      </div>

                      <div className="__right">
                        <div>Payments: <b> {payments.filter(item => item.check).length} </b></div>
                        <div>Net:<b> ${getSumCheck('net')} </b></div>
                        <div>Fee: <b> ${getSumCheck('fee')} </b></div>
                        <div>Total:<b> ${getSumCheck('total')} </b></div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, depositData.deposit[0].created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created by:</span>
                  <input type="text" defaultValue={depositData.deposit[0].created_by} disabled={true} />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Last Edited:</span>
                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, depositData.deposit[0].updated_at)}
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <span>Last Edited by:</span>
                  <input type="text" defaultValue={depositData.deposit[0].updated_by} disabled={true} />
                </div>
              </div>
            </div>
          </div>
          {editing &&
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  depositData.permissions.deposit_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Deposite</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={!readyToSave || isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>}

          {/* Item share popup */}
          {
            depositData.permissions.deposit_delete &&
            deleting &&
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Deposite
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the deposite it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}
