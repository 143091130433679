import classNames from 'classnames'

import { sendNotificationAppoint } from './GantChart'

interface SendNotificationProps {
  appoint: sendNotificationAppoint
  setAppoint: (value: sendNotificationAppoint | null) => void
  handleSend: (id: string, setId: (val: sendNotificationAppoint | null) => void) => void
  isSending: boolean
}

export default function SendNotification({
  appoint,
  setAppoint,
  handleSend,
  isSending }: SendNotificationProps) {

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '90vh',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99999',
      }}>
      <div className='appointment-send-notification-confirmation appointment-send-notification-confirmation-popup'>
        <div className='appointment-send-notification__header'>
          <div className='appointment-send-notification__header-text'>
            <p> Send notification </p>
          </div>
        </div>

        <div className='appointment-send-notification-confirmation__btns'>
          <div
            className='appointment-send-notification-confirmation__deny-btn'
            onClick={() => setAppoint(null)}
          >
            Cancel
          </div>

          <div
            className={classNames('appointment-send-notification-confirmation__confirm-btn',
              {
                disabled: isSending,
              }
            )}
            onClick={() => !isSending && handleSend(appoint.appointment_id, setAppoint)}
          >
            Send
          </div>
        </div>
      </div>
    </div>
  )
}
