import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import Datetime from 'react-datetime'
import moment from 'moment'
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, updatedDateToReqServer, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import '../../styles/pages/jobItemTech.sass'

interface AbsenceProps {
  absence_start: string | Date
  absence_end: string | Date
  time_zone: string
}

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AbsenceData
}

interface AbsenceData {
  permissions: {
    technician_absence_delete: boolean
    technician_absence_edit: boolean
  }
  absence: AbsenceProps
}

export default function ItemTech() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [editing, setEditing] = useState(false)
  const [data, setData] = useState<AbsenceData | null>(null)
  const [absenceData, setAbsenceData] = useState<AbsenceProps>({
    absence_start: '',
    absence_end: '',
    time_zone: ''
  })

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: absenceData, success, error } } = await httpClientUpdate.get('/technician/absence/' + $router.router.getState().params.absenceId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }
      if (success) {
        let dateStart = dateToInfoBlock('MM/dd/yyyy hh:mma', absenceData.absence.time_zone, absenceData.absence.absence_start as string)
        let dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mma', absenceData.absence.time_zone, absenceData.absence.absence_end as string)

        setData(absenceData)
        setAbsenceData({
          absence_start: dateStart,
          absence_end: dateEnd,
          time_zone: absenceData.absence.time_zone
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    absenceData
  ])

  function handleCancelEdit() {
    if (data) {
      let dateStart = dateToInfoBlock('MM/dd/yyyy hh:mma', data.absence.time_zone, data.absence.absence_start as string)
      let dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mma', data.absence.time_zone, data.absence.absence_end as string)

      data && setAbsenceData({
        absence_start: dateStart,
        absence_end: dateEnd,
        time_zone: data.absence.time_zone
      })
      setEditing(false)
    }
  }

  async function handleSave() {
    let start = moment.isMoment(absenceData.absence_start) ? moment(absenceData.absence_start).format('MM/DD/YYYY hh:mma') : absenceData.absence_start
    let end = moment.isMoment(absenceData.absence_end) ? moment(absenceData.absence_end).format('MM/DD/YYYY hh:mma') : absenceData.absence_end

    const response = await httpClientUpdate.put(`/technician/absence/${$router.router.getState().params.absenceId}`, qs.stringify({
      account_id: activeAccountId,
      absence_start: updatedDateToReqServer('MM/dd/yyyy hh:mma', absenceData.time_zone, start as string),
      absence_end: updatedDateToReqServer('MM/dd/yyyy hh:mma', absenceData.time_zone, end as string),
    }))
    if (response.data.success) {
      $router.router.navigate('appointment_calendar', {
        companyId: activeAccountId,
      }, { reload: true })
    }
  }

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/absence/${$router.router.getState().params.absenceId}`, {
      data: {
        account_id: activeAccountId,
      }
    })

    if (response.data.success) {
      $router.router.navigate('appointment_calendar', {
        companyId: activeAccountId,
      }, { reload: true })
    }
  }

  return (
    <div className="AbsencesPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <h1>Absence</h1>

        <div style={{ display: 'flex' }} >
          <button
            disabled={editing}
            style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
            className="_wa"
            onClick={() =>
              $router.router.navigate('appointment_calendar', {
                companyId: activeAccountId
              }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>

          {
            !editing && data?.permissions.technician_absence_edit ? (
              <button className="_wa _green" onClick={() => setEditing(true)}>
                <Icon
                  style={{ width: '16px', height: '16px' }}
                  viewBox="0 0 16 16"
                  icon="pencil-1"
                />
              </button>
            ) : null
          }
        </div>
      </div>

      <div className="fieldset">
        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>From:</span>

              <Datetime
                dateFormat="MM/DD/YYYY"
                timeFormat="hh:mma"
                inputProps={{ disabled: !editing }}
                value={absenceData?.absence_start as Date}
                onChange={(value) => setAbsenceData({ ...absenceData, absence_start: moment.isMoment(value) ? value.toDate() : value })}
              />
            </div>

          </div>

          <div className="__right">
            <div className="field">
              <span>To:</span>

              <Datetime
                dateFormat="MM/DD/YYYY"
                timeFormat="hh:mma"
                inputProps={{ disabled: !editing }}
                value={absenceData?.absence_end as Date}
                // onChange={(value) => setAbsenceData({ ...absenceData, absence_end: moment(value).format("MM-DD-YYYY hh:mm A") })}
                onChange={(value) => setAbsenceData({ ...absenceData, absence_end: moment.isMoment(value) ? value.toDate() : value })}
              />
            </div>
          </div>
        </div>
      </div>

      {editing ? (
        <div className="wrapper flex-container sb editing-buttons">
          <div>
            {
              data?.permissions.technician_absence_delete &&
              <button className="_red" onClick={() => setDeleting(true)}>Delete Absence</button>
            }
          </div>
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => handleCancelEdit()}
            >
              Cancel
            </button>
            <button
              disabled={!readyToSave}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}

      {/* Item share popup */}
      {deleting ? (
        <div className="item-delete-popup" onClick={() => setDeleting(false)}>

          <div className="wrapper" onClick={(e) => e.stopPropagation()}>

            <div className="title">
              Delete Absence
            </div>

            <div className="checkbox-wrapper">
              <Checkbox contents="I understand that after deleting the absence it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
            </div>

            <div className="buttons">

              <button
                className="_bordered _green"
                onClick={() => setDeleting(false)}
              >
                Cancel
              </button>

              <button
                disabled={!readyToDelete}
                className="_bordered _red"
                onClick={() => handleRemoveItem()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}


