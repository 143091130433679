import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import AutoCompliteComponent from '../../components/AutoCompliteComponent'

import { httpClientUpdate, nErrorUpdate, validateEmail } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { CanReadProps, ListMailsReportProps } from './List'

import "../../styles/pages/common/entity-edit.sass"
import '../../styles/pages/sms.sass'

export interface TemplateProps {
  responsibles: string
  template: string
  type: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [mailsOptions, setMailsOptions] = useState<CanReadProps[]>([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [files, setFiles] = useState<File[] | []>([])

  const [emailIsNotValid, setEmailIsNotValid] = useState(false)

  const [mailData, setMailData] = useState({
    to_mail: '',
    id: '',
    subject: '',
    text: '',
  })

  useEffect(() => {
    if (emailIsNotValid) {
      setEmailIsNotValid(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailData.to_mail])

  // Load mails function
  async function loadListMails() {
    try {
      // https://2022back4.artemiudintsev.com/api/mailboxes/check?account_id=88888
      const { data: { data: listMailsReport, success } } = (await httpClientUpdate.get('/mailboxes/check', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: ListMailsReportProps }

      if (success) {
        setMailsOptions(listMailsReport.can_write_mailboxes)
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absences on page mount
  useEffect(() => {
    loadListMails()

    // unmount page
    return () => {
      setMailsOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      const isValid = validateEmail(mailData.to_mail)

      if (!isValid) {
        setEmailIsNotValid(true)
        setIsSaving(false)
      } else {
        // https://2022back4.artemiudintsev.com/api/mailboxes/888885502wxgjy8mpt/send
        const formData = new FormData();

        files.length && files.forEach((item, index) => {
          formData.append(
            `attachments[]`,
            item,
            item.name
          );
        })

        activeAccountId && formData.append('account_id', activeAccountId);
        formData.append('text', mailData.text);
        formData.append('to_mail', mailData.to_mail);
        formData.append('subject', mailData.subject);
        formData.append('is_html', mailData.text.includes('html') ? '1' : '0');

        const response = await httpClientUpdate.post(`/mailboxes/${$router.router.getState().params.selectedSearch}/send`, formData, {

          headers: { "Content-Type": "multipart/form-data" }
        },)
        if (response.data.success) {
          setSelectedFile(null)
          setFiles([])

          $router.router.navigate('mail', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface,
            selectedSearch: $router.router.getState().params.selectedSearch,
            selectedFolder: $router.router.getState().params.selectedFolder,
            selectedEmailName: $router.router.getState().params.selectedEmailName,
          }, { reload: true })
        }
      }

    } catch (error) {
      setIsSaving(false)
    }
  }

  function onFileChange(event: any) {
    setSelectedFile(event.target.files[0])
  };

  function handleAddFile() {
    let updatedFiles = files.map(item => item)

    // @ts-ignore
    updatedFiles.push(selectedFile)

    setFiles(updatedFiles)
    setSelectedFile(null)
  }

  function handleRemoveFile(i: number) {
    setFiles(files.filter((item, index) => index !== i))
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Write Message</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('mail', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>From mail:</span>

              <input
                type="text"
                disabled={true}
                defaultValue={$router.router.getState().params.selectedEmailName}
              />
            </div>

            <div className="field">
              <span style={{ color: emailIsNotValid ? '#FF0000' : 'inherit' }}>To mail:</span>
              <AutoCompliteComponent options={mailsOptions.map((option) => ({
                span: option.email,
                value: option.mailbox_id,
              }))}
                selectedOption={mailData.id as string}
                onChange={(value, span) => setMailData({ ...mailData, to_mail: span as string, id: value as string })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field"></div>

            <div className="field">
              <span>Subject:</span>

              <input
                type="text"
                value={mailData.subject}
                onChange={(event) => setMailData({ ...mailData, subject: event.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Text:</span>

              <textarea
                value={mailData.text}
                onChange={(event) => setMailData({ ...mailData, text: event.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="fields">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '5px 0',
              width: '100%'
            }}
          >
            <input
              style={{ display: 'block' }}
              type="file"
              onChange={onFileChange}
            />
            <button
              className="_wa _green"
              onClick={handleAddFile}
              disabled={selectedFile === null}
            >
              Add
            </button>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              margin: '5px 0',
              width: '100%'
            }}
          >
            {
              files.map((item, i) => (
                <div
                  key={i}
                  style={{
                    width: '200px',
                    height: '200px',
                    position: 'relative'
                  }}
                >
                  <img src={URL.createObjectURL(item)} alt="Add file" />

                  <button
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                    className="_zeroed _iconed _gray"
                    onClick={() => handleRemoveFile(i)}
                  >
                    <Icon style={{ width: '16px', height: '16px' }} icon="x-mark-1" />
                  </button>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button className="_bordered _red" onClick={() => $router.router.navigate('mail', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface,
            selectedSearch: $router.router.getState().params.selectedSearch,
            selectedFolder: $router.router.getState().params.selectedFolder,
            selectedEmailName: $router.router.getState().params.selectedEmailName,
          }, {
            reload: true
          })}>
            Cancel
          </button>
          <button
            disabled={mailData.text === '' || mailData.id === '' || mailData.subject === '' || isSaving || emailIsNotValid}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
