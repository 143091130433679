import { memo } from 'react'

import Icon from "../Icon"

interface TopTapsProps {
  tap: string
  setTap: (tap: string) => void
}

function TopTaps({ tap, setTap }: TopTapsProps) {
  return (
    <div className='section__account_softphone-top'>
      <div
        onClick={() => setTap('Home')}
        className={tap === 'Home' ? 'section__account_softphone-tap selected' : 'section__account_softphone-tap'}
      >
        <div className='section__account_softphone-tap_icon'>
          <Icon viewBox="0 0 18 16" icon="home-1" />
        </div>

        <div className='section__account_softphone-tap_title'>Home</div>
      </div>

      <div
        onClick={() => setTap('Dial')}
        className={tap === 'Dial' ? 'section__account_softphone-tap selected' : 'section__account_softphone-tap'}
      >
        <div className='section__account_softphone-tap_icon'>
          <Icon viewBox="0 0 12 16" icon="dial-1" />
        </div>

        <div className='section__account_softphone-tap_title'>Dial</div>
      </div>

      <div
        onClick={() => setTap('Calls')}
        className={tap === 'Calls' ? 'section__account_softphone-tap selected' : 'section__account_softphone-tap'}
      >
        <div className='section__account_softphone-tap_icon'>
          <Icon viewBox="0 0 16 16" icon="call-1" />
        </div>

        <div className='section__account_softphone-tap_title'>Calls</div>
      </div>

      <div
        onClick={() => setTap('Contacts')}
        className={tap === 'Contacts' ? 'section__account_softphone-tap selected' : 'section__account_softphone-tap'}
      >
        <div className='section__account_softphone-tap_icon'>
          <Icon viewBox="0 0 22 20" icon="user-21" />
        </div>

        <div className='section__account_softphone-tap_title'>Contacts</div>
      </div>
    </div>
  )
}

export default memo(TopTaps)