import { memo, useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from "classnames"

import ActionMenuBlock from './ActionMenuBlock'
import InfoBlock from './InfoBlock'
import SelectComponent from '../Select'
import Icon from '../Icon'
import DotsSvg from '../DotsSvg'
import NextSvg from '../NextSvg'
import PauseSvg from '../PauseSvg'

import { useAppSelector } from "../../store/hooks"
import { formatPhoneNumber } from '../../funcs'

import { CallsDataProps, ClientsProps, DispatchersProps, PermissionsProps } from '../../App'

interface ActiveCallItemProps {
  handleActionMenus: (value: string) => void
  handleInfoBlock: (value: string) => void
  handleSubmit: (sid: string, tags: string, comment: string) => void

  pin: boolean
  setPin: (value: boolean) => void

  actionMenu: string[]
  infoBlock: string[]

  actionsMenus: string[]

  blackList: boolean
  setBlackList: (value: boolean) => void

  stoptalk: (value: string) => void
  callData: CallsDataProps

  permissions: Partial<PermissionsProps>

  dispatcherTwillio: DispatchersProps[]

  handleRedirectCall: (call_sid: string, dispatcher_id: string) => void
  handlePauseCall: (call_sid: string) => void
  handleResumeCall: (call_sid: string) => void
}

function ActiveCallItem({
  handleActionMenus,
  handleInfoBlock,
  handleSubmit,
  pin,
  setPin,
  actionMenu,
  infoBlock,
  actionsMenus,
  blackList,
  setBlackList,
  stoptalk,
  callData,
  permissions,
  dispatcherTwillio,
  handleRedirectCall,
  handlePauseCall,
  handleResumeCall,
}: ActiveCallItemProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [time, setTime] = useState(0)
  const [redirectModal, setRedirectModal] = useState(false)
  const [selectedDispatcher, setSelectedDispatcher] = useState('')

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => setTime(time + 1), 10)

    return () => clearInterval(intervalId)
  }, [time])

  // Hours calculation
  const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  function getClientName(clients: ClientsProps[], current_name: string) {
    let nameString = ''
    if (clients.length || current_name) {
      if (clients.length) {
        if (clients[0].company_name) {
          nameString = `${clients[0].company_name} ${clients[0].first_name ? `(${clients[0].first_name} ${clients[0].last_name})` : ''}`
        } else {
          nameString = `${clients[0].first_name} ${clients[0].last_name}`
        }
      } else {
        nameString = current_name
      }
    } else {
      nameString = 'Unknown'
    }
    return nameString
  }

  function handleRedirection() {
    setRedirectModal(!redirectModal)
  }

  return (
    <div className='section__account_softphone-content_home-item section__account_softphone-content_home-activeCall'>

      {
        redirectModal &&
        <div className='section__account_softphone-content_home-item_modal'>
          <div className='modal-row'>
            <span className='title'>Dispatcher:</span>

            <SelectComponent
              zeroed={true}
              disabled={!dispatcherTwillio.length}
              options={dispatcherTwillio.map((item) => ({
                span: item.code ? `${item.nickname} (${item.code})` : item.nickname,
                value: item.dispatcher_id
              }))}
              selectedOption={selectedDispatcher}
              onChange={(value: string) => setSelectedDispatcher(value)} />
          </div>

          <div className='modal-action'>
            <button className='close' onClick={handleRedirection}>Close</button>

            <button
              className='apply'
              disabled={!selectedDispatcher}
              onClick={() => handleRedirectCall(callData.current_call.call_sid, selectedDispatcher)}
            >
              Redirect
            </button>
          </div>
        </div>
      }
      <div className='section__account_softphone-content_home-item_top'>
        <div>
          {
            callData.current_call.is_hold ?
              'On Hold' :
              'Active Call'
          }
        </div>

        <div className='section__account_softphone-content_home-item_time'>
          <p>Active time</p>

          <p>
            {hours}:{minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
          </p>
        </div>
      </div>

      <div className='section__account_softphone-content_home-item_data'>
        <div className='section__account_softphone-content_home-item_text'>
          <div
            className='section__account_softphone-content_home-item_client-name'
            style={{ cursor: callData.permissions.client_show && !!callData.clients.length ? 'pointer' : 'inherit' }}
            onClick={() =>
              callData.permissions.client_show &&
              !!callData.clients.length &&
              $router.router.navigate('clients.item', {
                companyId: activeAccountId, clientId: callData.clients[0].client_id
              }, { reload: true })
            }
          >
            {
              !!callData.clients.length &&
              <div className='section__account_softphone-content_calls-contIcon visible'>
                <Icon viewBox="0 0 11 13" style={{ fill: '#FFFFFF' }} icon="account-1" />
              </div>
            }

            <p>
              {
                getClientName(callData.clients, callData.current_call.caller_name)
              }
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_client-tel'>
            <p> {formatPhoneNumber(callData.current_call.from_phone)} </p>
          </div>

          <div className='section__account_softphone-content_home-item_client-location'>
            <div className='section__account_softphone-content_home-item_location-icon'>
              <Icon viewBox="0 0 6 8" style={{ fill: '#FFFFFF' }} icon="location-1" />
            </div>

            <p>
              {callData.current_call.caller_city}, {callData.current_call.caller_state} {callData.current_call.caller_zip}
            </p>
          </div>
        </div>

        <div className='section__account_softphone-content_home-item_text'>
          <div className='section__account_softphone-content_home-item_operator-name'>
            <p>
              {callData.current_call.friendly_name}
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_operator-tel'>
            <p>
              {formatPhoneNumber(callData.current_call.to_phone)}
            </p>
          </div>

          <div className='section__account_softphone-content_home-item_operator-location'>
            <div className='section__account_softphone-content_home-item_location-icon'>
              <Icon viewBox="0 0 6 8" style={{ fill: '#FFFFFF' }} icon="location-1" />
            </div>

            <p>
              {callData.current_call.area}
            </p>
          </div>
        </div>
      </div>

      <div className='section__account_softphone-content_home-item_actionButtons'>
        <div className='section__account_softphone-content_home-item_actionButtons-block'>
          <div
            onClick={() => callData.calls.length && handleInfoBlock(callData.current_call.caller_sid)}
            className={classNames("section__account_softphone-content_home-item_button section__account_softphone-content_home-item_button-info", {
              inactive: !callData.calls.length
            })}
            style={{ cursor: !callData.calls.length ? 'inherit' : 'pointer' }}
          >
            <p>i</p>
          </div>

          {
            callData.current_call.is_hold ?
              <div
                className='section__account_softphone-content_home-item_actionButtons-button section__account_softphone-content_home-item_actionButtons-pause'
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  handleResumeCall(callData.current_call.caller_sid)
                }}
                style={{
                  width: '54px',
                  height: '24px',
                  border: '1px solid #6093DE',
                  borderRadius: '10.5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon style={{ fill: '#6093DE', width: '15px' }} icon="media-control-48" />
              </div> :
              <div
                className='section__account_softphone-content_home-item_actionButtons-button section__account_softphone-content_home-item_actionButtons-pause'
                onClick={() => handlePauseCall(callData.current_call.caller_sid)}
              >
                <PauseSvg />
              </div>
          }

          <div
            className='section__account_softphone-content_home-item_actionButtons-button section__account_softphone-content_home-item_actionButtons-forward'
            style={{
              opacity: !!dispatcherTwillio.length ? 1 : .5,
              cursor: !!dispatcherTwillio.length ? 'pointer' : 'inherit'
            }}
            onClick={() => {
              !!dispatcherTwillio.length && handleRedirection()
            }}
          >
            <NextSvg />

          </div>

          {
            (permissions.softphone_add_comment || permissions.softphone_make_appointment ||
              permissions.softphone_make_ticket || permissions.softphone_mark_blacklist ||
              permissions.softphone_reschedule_appointment) &&
            <div
              onClick={() => handleActionMenus(callData.current_call.caller_sid)}
              className='section__account_softphone-content_home-item_actionButtons-button section__account_softphone-content_home-item_actionButtons-actions'
            >
              <DotsSvg />
            </div>
          }
        </div>

        <div className='section__account_softphone-content_home-item_actionButtons-block'>
          <div
            onClick={() => setPin(!pin)}
            className={pin ?
              'section__account_softphone-content_home-item_actionButtons-microphone inactive' :
              'section__account_softphone-content_home-item_actionButtons-microphone'}
          >
            {
              !pin ?
                <svg viewBox="0 0 92 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="91" height="29" rx="14.5" stroke="#6093DE"></rect>
                  <path
                    d="M49.696 14.4619C49.696 16.503 48.0414 18.1576 46.0003 18.1576M46.0003 18.1576C43.9593 18.1576 42.3047 16.503 42.3047 14.4619M46.0003 18.1576V23.3315"
                    stroke="#6093DE" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"
                  ></path>
                  <rect x="43.4004" y="7" width="5.20001" height="9.75002" rx="2.6" fill="#6093DE"></rect>
                </svg> :
                <svg className="inactive" viewBox="0 0 92 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="91" height="29" rx="14.5" fill="#E7E9EC" stroke="#C2C2C4"></rect>
                  <path d="M51 8.24261L41 18.2426" stroke="#6093DE" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path
                    d="M42.6364 15.1922C42.5231 14.859 42.4616 14.5019 42.4616 14.1305C42.4616 13.9096 42.2825 13.7305 42.0616 13.7305C41.8407 13.7305 41.6616 13.9096 41.6616 14.1305C41.6616 14.7284 41.7897 15.2964 42.02 15.8085L42.6364 15.1922ZM43.2713 17.3856C43.8612 17.8368 44.5772 18.1312 45.3573 18.2068V23C45.3573 23.2209 45.5364 23.4 45.7573 23.4C45.9782 23.4 46.1573 23.2209 46.1573 23V18.2068C48.2315 18.0058 49.8529 16.2575 49.8529 14.1305C49.8529 13.9096 49.6738 13.7305 49.4529 13.7305C49.232 13.7305 49.0529 13.9096 49.0529 14.1305C49.0529 15.9506 47.5774 17.4261 45.7573 17.4261C45.0436 17.4261 44.3828 17.1992 43.8433 16.8137L43.2713 17.3856Z"
                    fillRule="evenodd" clipRule="evenodd" fill="#8E8E93"
                  ></path>
                  <path
                    fillRule="evenodd" clipRule="evenodd" fill="#8E8E93"
                    d="M48.3572 9.47103V9.26852C48.3572 7.83258 47.1932 6.66852 45.7572 6.66852C44.3213 6.66852 43.1572 7.83258 43.1572 9.26852V13.8185C43.1572 14.0772 43.195 14.3271 43.2654 14.5629L48.3572 9.47103ZM44.5401 16.1166C44.9032 16.3094 45.3175 16.4185 45.7572 16.4185C47.1932 16.4185 48.3572 15.2545 48.3572 13.8185V12.2995L44.5401 16.1166Z"
                  ></path>
                </svg>

            }
          </div>

          <div
            className='section__account_softphone-content_home-item_actionButtons-phoneDown'
            onClick={() => stoptalk(callData.current_call.caller_sid)}
          >
            <Icon style={{ transform: 'rotate(135deg)', width: '21px' }} viewBox="0 0 16 16" icon="call-1" />
          </div>
        </div>
      </div>
      {/* Actions menu */}
      {
        actionMenu.includes(callData.current_call.caller_sid) &&
        <ActionMenuBlock
          blackList={blackList}
          setBlackList={setBlackList}
          actionsMenus={actionsMenus}
          handleSubmit={handleSubmit}
          call_sid={callData.current_call.call_sid}
          company_id={callData.current_call.company_id}
          caller_phone={callData.current_call.from_phone}
          lead_type={callData.calls[0]}
          lead_id={callData.current_call.call_sid}
          real_lead_type={callData.calls[0]}
          real_lead_id={callData.current_call.call_sid}
          lead_text={`${callData.calls[0].direction} - From: ${callData.current_call.from_phone} ${callData.current_call.caller_name} - To: ${callData.current_call.to_phone} ${callData.current_call.friendly_name}`}
          source_id={callData.current_call.source_id}
          permissions={permissions}
        />
      }
      {/* Info Block */}
      {
        infoBlock.includes(callData.current_call.caller_sid) &&
        <InfoBlock
          calls={callData.calls}
          client_name={getClientName(callData.clients, callData.current_call.caller_name)}
          client_show={callData.permissions.client_show}
          client_id={callData.clients.length ? callData.clients[0].client_id : ''}
        />
      }
    </div>
  )
}

export default memo(ActiveCallItem)
