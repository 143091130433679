import { useEffect, useState } from "react"
import { BaseLink, useRoute } from "react-router5"
import classNames from "classnames"

import Icon from "../../../components/Icon"
import SelectComponent from "../../../components/Select"

import { getBase64FromRington, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from '../../../store/hooks'

import { HttpReport, UserGroupsProps } from "./List"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../../styles/pages/common/entity-edit.sass"

const emptyUserGroups: UserGroupsProps = {
  name: '',
  user_group_id: '',
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [userGroups, setUserGroups] = useState<UserGroupsProps[]>([])
  const [availableUserGroups, setAvailableUserGroups] = useState<UserGroupsProps[]>([])
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupsProps>(emptyUserGroups)

  const [isSaving, setIsSaving] = useState(false)

  const [newData, setNewData] = useState<{ name: string, description: string, userGroups: UserGroupsProps[], audio_file: string | null }>({
    name: '',
    description: '',
    userGroups: [],
    audio_file: null,
  })

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadData() {

    try {
      //  https://2022back4.artemiudintsev.com/api/audio-library/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: responseData, success, error } } = (await httpClientUpdate.get('/audio-library/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
        }
      })) as { data: HttpReport }
      if (success) {
        let _availableUsersGroups = []

        _availableUsersGroups = responseData.edit.user_groups.filter((item) => item)

        setUserGroups(responseData.edit.user_groups)
        setAvailableUserGroups([emptyUserGroups].concat(_availableUsersGroups))
        setSelectedUserGroups(emptyUserGroups)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    setIsSaving(true)
    try {

      // https://2022back4.artemiudintsev.com/api/audio-library
      const response = await httpClientUpdate.post(`/audio-library`, {
        account_id: activeAccountId,
        name: newData.name,
        description: newData.description,
        user_groups: newData.userGroups.map(item => item.user_group_id),
        audio_file: newData.audio_file
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.audioLibrary', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function addUserGroups() {
    if (!userGroups.length) return

    const newUsersList = [
      ...newData.userGroups,
      selectedUserGroups
    ]

    setNewData({
      ...newData,
      userGroups: newUsersList
    })

    let _availableUserGroups = [...availableUserGroups]

    _availableUserGroups = _availableUserGroups.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user.user_group_id === $user.user_group_id
      }) === -1
    })

    setAvailableUserGroups([
      ..._availableUserGroups
    ])

    setSelectedUserGroups(emptyUserGroups)
  }

  // Remove dispatcher function
  function removeUserGroup(i: number) {

    let _callerGroupUsers = [...newData.userGroups]

    let _user = _callerGroupUsers[i]

    _callerGroupUsers.splice(i, 1)

    setNewData({
      ...newData,
      userGroups: _callerGroupUsers
    })

    setAvailableUserGroups([
      ...availableUserGroups,
      _user
    ])
  }

  function addFile(event: any) {
    if (event.target.files[0]) {
      getBase64FromRington(event.target.files[0])
        .then(result => {
          let file = result;
          setNewData({
            ...newData,
            audio_file: file as string
          })
        })
        .catch(err => { });
    }
  }

  return (
    <div className="CallCenter_PhoneNumbersPage_New entity-edit">
      {/* Top navigation */}
      <div className="top-nav">
        <BaseLink
          router={$router.router}
          routeName="call_center.phoneNumbers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
        >
          <Icon icon="phone-1" />
          <span>Phone Numbers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.dispatchers"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
        >
          <Icon icon="user-1" />
          <span>Dispatchers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.additionalTime"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="time-22" />
          <span>Additional Time</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.absence"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.absence" })}
        >
          <Icon icon="time-16" />
          <span>Absence</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.groups" })}
        >
          <Icon icon="user-29" />
          <span>Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callFlows"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
        >
          <Icon icon="share-7" />
          <span>Call Flows</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.caller_groups"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
        >
          <Icon icon="user-29" />
          <span>Caller Groups</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.temporaryClosed"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="close-664" />
          <span>Temporary Closed</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.callCenters"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.callCenters" })}>
          <Icon icon="delivery-8" />
          <span>Call Centers</span>
        </BaseLink>

        <BaseLink
          router={$router.router}
          routeName="call_center.audioLibrary"
          routeParams={{ companyId: activeAccountId }}
          className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
        >
          <Icon viewBox="0 0 1024 1024" icon="audio-15" />
          <span>Audio Library</span>
        </BaseLink>
      </div>

      <div className="wrapper flex-container sb">
        <h1>New Audio</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('call_center.audioLibrary', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Name:</span>

              <input
                type="text"
                value={newData.name}
                onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Description:</span>
              <input
                type="text"
                value={newData.description}
                onChange={({ target: { value } }) => setNewData({ ...newData, description: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Audio File</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>File:</span>

              <input
                type="file"
                style={{ display: 'block' }}
                placeholder="Upload"
                onChange={addFile}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="flex-container sb wrap">
          <div className="legend">User Groups</div>

          <div className="legend-action-wrapper">
            <div className="input-wrapper">
              <SelectComponent
                options={availableUserGroups.map((user) => ({
                  span: user.name,
                  value: user.user_group_id
                }))}
                selectedOption={selectedUserGroups.user_group_id}
                onChange={(value) => setSelectedUserGroups(availableUserGroups.find((user) => user.user_group_id === value) as UserGroupsProps)}
              />
              <button
                disabled={!selectedUserGroups.user_group_id}
                className="_green"
                onClick={() => addUserGroups()}
              >
                Add
              </button>
            </div>
          </div>

        </div>

        <table className="table">
          <tr>
            <th>Name</th>
            <th></th>
          </tr>

          {newData.userGroups.map((user, i) => (
            <tr
              key={i}
            >
              <td>{user.name}</td>
              <td>
                <button
                  className="_zeroed _iconed _red"
                  onClick={() => removeUserGroup(i)}
                >
                  <Icon icon="x-mark-1" />
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button className="_bordered _red" onClick={() => $router.router.navigate('call_center.caller_groups', {
            companyId: activeAccountId
          }, {
            reload: true
          })}>
            Cancel
          </button>
          <button
            disabled={isSaving || !newData.name || !newData.userGroups.length || newData.audio_file === null}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
