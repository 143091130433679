import { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

interface PositionProps {
  lat: number,
  lng: number
}

interface PlacesAutocompleteProps {
  address: string
  setCenter: (value: PositionProps) => void
  setUpdateAddress: (value: string) => void
}

export const PlacesAutocomplete = ({ address, setCenter, setUpdateAddress }: PlacesAutocompleteProps) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setValue(address)
    clearSuggestions();
    getGeocode({ address: address }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setCenter({ lat, lng })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ref = useOnclickOutside(() => {
    clearSuggestions();
    setOpen(false)
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    setUpdateAddress(e.target.value)
    setOpen(true)
  };

  const handleSelect =
    ({ description }: any) =>
      () => {
        setValue(description, false);
        setUpdateAddress(description)
        clearSuggestions();

        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          setCenter({ lat, lng })
        });
        setOpen(false)
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Where are you going?"
      />
      {status === "OK" && open && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};