import { useEffect, useState } from 'react'
import classNames from 'classnames'

import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import SearchInvoices, { InvoiceAddProps } from './SearchInvoices'

import { NavActive } from '../../store/reducer'

import { InvoicesProps } from './ItemTech'
import Camera from './Camera'

interface PaymentCheckProps {
  navActive: NavActive
  phoneCall: boolean
  invoices: InvoicesProps[]
  permission_search_show: boolean
  job_name: string
  job_id: string
  handlePayment: (type: string, data: DataProps[], total: number, check_number?: string) => void
  handleClose: () => void
  isSaving: boolean
  contact_id: string
  paid_job: number
}

interface DataProps {
  is_add_invoice: boolean
  job_id?: string
  invoice_id?: string
  contact_id?: string
  name: string
  note: string
  id: string | null
  total: number
  paid: number
  amount: number
  is_check: boolean
}

export default function PaymentCheck({
  navActive,
  phoneCall,
  invoices,
  permission_search_show,
  job_name,
  handlePayment,
  handleClose,
  isSaving,
  contact_id,
  job_id,
  paid_job,
}: PaymentCheckProps) {
  const [data, setData] = useState<DataProps[]>([])
  const [addData, setAddData] = useState<DataProps[]>([])
  const [name, setName] = useState('')
  const [isJobCheck, setIsJobCheck] = useState({
    is_check: false,
    note: 'Deposit',
    amount: 0,
  })
  const [takePic, setTakePic] = useState(false)

  const [showSearchInvoices, setShowSearchInvoices] = useState(false)

  useEffect(() => {
    if (invoices.length) {
      let updated = invoices.map(item => {
        let total_string = item.invoice_total?.replaceAll('$', '')
        let total = total_string ? Number(total_string) : 0

        let paid = item.invoice_paid ? Number(item.invoice_paid) : 0

        return {
          name: item.name,
          note: 'Deposit',
          id: item.invoice_id,
          total: total,
          paid: paid,
          amount: 0,
          is_check: false,
          is_add_invoice: false
        }
      })

      setData(updated)
    }
  }, [invoices])

  function handleCheckedInvoice(value: boolean, index: number, is_add_invoice?: boolean) {
    setData(data.map((item, idx) => {
      if (idx === index) {
        let total = item.total

        let paid = item.paid

        let amount = 0

        if (value) {
          if (is_add_invoice) {
            amount = 0
          } else {
            amount = total - paid
          }
        }

        return {
          ...item,
          is_check: value,
          amount,
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleCheckedAddInvoice(value: boolean, index: number) {
    setAddData(addData.map((item, idx) => {
      if (idx === index) {
        let amount = 0

        if (value) {
          amount = 0
        }

        return {
          ...item,
          is_check: value,
          amount,
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeNote(value: string, index: number, is_add_data?: boolean) {
    if (is_add_data) {
      setAddData(addData.map((item, idx) => {
        if (idx === index) {
          return { ...item, note: value }
        } else {
          return { ...item }
        }
      }))
    } else {
      setData(data.map((item, idx) => {
        if (idx === index) {
          return { ...item, note: value }
        } else {
          return { ...item }
        }
      }))
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangeItem(event: any, index: number, is_add_invoice: boolean, is_add_data?: boolean) {
    let price = event.target.value
    if (is_add_data) {
      let numberWithoutDot = price.split('.')

      if (Number(price) >= 0) {
        if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
          let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          let newNumber = price

          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: newNumber }
            } else {
              return { ...item }
            }
          }))
        }
      }
    } else {
      let item = data[index]

      let total = item.total

      let paid = item.paid

      let max_price = total - paid

      let numberWithoutDot = price.split('.')

      if (Number(price) >= 0 && Number(price) <= max_price) {
        if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
          let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: max_price < Number(newNumber) ? max_price : Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          let newNumber = price

          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: newNumber }
            } else {
              return { ...item }
            }
          }))
        }
      }
    }
  }

  function handleChangeItemBackspace(event: any, index: number, is_add_data?: boolean) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (is_add_data) {
          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        }

      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        if (is_add_data) {
          setAddData(addData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          setData(data.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        }
      }
    }
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      } else {
        let newNumber = price

        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      }
    }
  }

  function handleJobChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setIsJobCheck({ ...isJobCheck, amount: Number(newNumber) })
      }
    }
  }

  function getPayByCheck() {
    let pay = 0

    data.forEach(item => {
      if (item.is_check) {
        pay = pay + Number(item.amount)
      }
    })

    addData.forEach(item => {
      if (item.is_check) {
        pay = pay + Number(item.amount)
      }
    })

    if (isJobCheck.is_check) {
      pay = pay + Number(isJobCheck.amount)
    }

    return pay
  }

  function handleSave() {
    let data_save: DataProps[] = []
    if (data.filter(item => item.is_check).length) {
      data_save = data.filter(item => item.is_check)
    }

    if (isJobCheck.is_check) {
      data_save.push({
        note: isJobCheck.note,
        id: null,
        amount: isJobCheck.amount,
        name: '',
        total: 0,
        paid: 0,
        is_check: true,
        is_add_invoice: false
      })
    }

    if (addData.filter(item => item.is_check).length) {
      data_save = data_save.concat(addData.filter(item => item.is_check))
    }

    handlePayment('Check', data_save, getPayByCheck(), name)
  }

  function handleAddInvoice(data_invoice: InvoiceAddProps[]) {
    let invoices = data_invoice.map(item => {
      let total = item.total ? Number(item.total.replace('$', '')) : 0
      return {
        job_id: item.job_id,
        invoice_id: item.invoice_id,
        contact_id: item.contact_id,
        note: '',
        id: null,
        amount: 0,
        name: item.name,
        total: total,
        paid: Number(item.paid),
        is_check: false,
        is_add_invoice: true,
      }
    })

    setAddData(addData.concat(invoices))

    setShowSearchInvoices(false)
  }

  function getInvoicesIds() {
    let ids: string[] = []

    data.forEach(item => {
      item.id && ids.push(item.id)
    })

    addData.forEach(item => {
      item.invoice_id && ids.push(item.invoice_id)
    })

    return ids
  }

  function getJobsIds() {
    let ids: string[] = [job_id]

    addData.forEach(item => {
      !item.invoice_id && item.job_id && ids.push(item.job_id)
    })

    return ids
  }

  return (
    <>
      {
        showSearchInvoices ?
          <div
            className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}
          >
            <SearchInvoices
              handleClose={() => setShowSearchInvoices(false)}
              contact_id={contact_id}
              handleAddInvoice={handleAddInvoice}
              invoice_ids={getInvoicesIds()}
              job_ids={getJobsIds()}
            />
          </div>
          :
          takePic ?
            <div
              className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}
            >
              <div
                className='content-title'
                style={{ flexDirection: 'column', gap: '20px' }}
              >
                <div
                  className='content-title-row'
                  style={{ alignItems: 'center' }}
                >
                  <div></div>

                  <div className='mobile-text'>
                    Take a pic
                  </div>

                  <div
                    onClick={() => setTakePic(false)}
                    className="content-title-plus"
                  >
                    <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 60 60" icon="plus-job-tech-1" />
                  </div>
                </div>

                <Camera
                  setTakePic={setTakePic}
                />
              </div>
            </div> :
            <div
              className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}
            >
              <div className='content-title'>
                <div
                  className='content-title-row'
                  style={{ alignItems: 'center' }}
                >
                  <div></div>

                  <div className='mobile-text'>
                    Add Check Payment
                  </div>

                  <div></div>
                </div>
              </div>

              <div className='payment-title'>
                <Icon style={{ width: '20px' }} viewBox="0 0 78 85" icon="group-23" />

                Invoices
              </div>

              <div className='payment-invoices-items'>
                {
                  data.map((item, index) => (
                    <div
                      key={index}
                      className="payment-invoices-item"
                    >
                      <div className='payment-invoices-item-row'>
                        <div className='invoice-info'>
                          <div className='invoice-info-title'>
                            <span title={item.name}> {item.name} </span>

                            {
                              item.total > 0 && item.total - item.paid === Number(item.amount) ?
                                <div
                                  className='check-mark'
                                  onClick={() => handleCheckedInvoice(false, index)}
                                >
                                  <Icon viewBox="0 0 60 60" icon="check-job-tech" />
                                </div> :
                                <Checkbox
                                  contents=""
                                  value={item.is_check}
                                  onChange={(value) => handleCheckedInvoice(value, index, item.is_add_invoice)}
                                />
                            }
                          </div>

                          <div className='invoice-info-row'>
                            <div className='invoice-info-total'>
                              Total: ${item.total.toFixed(2)}
                            </div>

                            <div className='invoice-info-paid'>
                              Paid: ${item.paid.toFixed(2)}
                            </div>
                          </div>
                        </div>

                        <div className='invoice-arrow'>
                          <button>
                            <Icon viewBox="0 0 24 24" icon="arrow-25" />
                          </button>
                        </div>
                      </div>

                      {
                        item.is_check &&
                        <div className='payment-invoices-item-row'>
                          <div className='invoice-data'>
                            <div className='invoice-data-row'>
                              <div className='title'>Note:</div>

                              <div className='input'>
                                <input
                                  type="text"
                                  value={item.note}
                                  onChange={(event) => handleChangeNote(event.target.value, index)}
                                />
                              </div>
                            </div>

                            <div className='invoice-data-row'>
                              <div className='title'>Amount:</div>

                              <div className='input'>
                                <input
                                  className="appearance"
                                  type='number'
                                  value={`${Number(item.amount).toFixed(2)}`}
                                  step={0.01}
                                  onFocus={(event) => handleFocus(event)}
                                  onChange={(event) => handleChangeItem(event, index, item.is_add_invoice)}
                                  onKeyDown={(event) => handleChangeItemBackspace(event, index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }

                <div className="payment-invoices-item">
                  <div className='payment-invoices-item-row'>
                    <div className='invoice-info'>
                      <div className='invoice-info-title'>
                        <span title={job_name}> {job_name} </span>

                        <Checkbox
                          contents=""
                          value={isJobCheck.is_check}
                          onChange={() => setIsJobCheck({ ...isJobCheck, is_check: !isJobCheck.is_check })}
                        />
                      </div>

                      <div className='invoice-info-row'>
                        <div className='invoice-info-total'>
                        </div>

                        <div className='invoice-info-paid'>
                          Paid: ${paid_job.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    isJobCheck.is_check &&
                    <div className='payment-invoices-item-row'>
                      <div className='invoice-data'>
                        <div className='invoice-data-row'>
                          <div className='title'>Note:</div>

                          <div className='input'>
                            <input
                              type="text"
                              value={isJobCheck.note}
                              onChange={(event) => setIsJobCheck({ ...isJobCheck, note: event.target.value })}
                            />
                          </div>
                        </div>

                        <div className='invoice-data-row'>
                          <div className='title'>Amount:</div>

                          <div className='input'>
                            <input
                              className="appearance"
                              type='number'
                              value={`${Number(isJobCheck.amount).toFixed(2)}`}
                              step={0.01}
                              onFocus={(event) => handleJobFocus(event)}
                              onChange={(event) => handleJobChangeItem(event)}
                              onKeyDown={(event) => handleJobChangeItemBackspace(event)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                {
                  addData.map((item, index) => (
                    <div
                      key={index}
                      className="payment-invoices-item"
                    >
                      <div className='payment-invoices-item-row'>
                        <div className='invoice-info'>
                          <div className='invoice-info-title'>
                            <span title={item.name}> {item.name} </span>

                            {
                              item.invoice_id ?
                                item.total > 0 && item.total - item.paid === Number(item.amount) ?
                                  <div
                                    className='check-mark'
                                    onClick={() => handleCheckedAddInvoice(false, index)}
                                  >
                                    <Icon viewBox="0 0 60 60" icon="check-job-tech" />
                                  </div> :
                                  <Checkbox
                                    contents=""
                                    value={item.is_check}
                                    onChange={(value) => handleCheckedAddInvoice(value, index)}
                                  /> :
                                <Checkbox
                                  contents=""
                                  value={item.is_check}
                                  onChange={(value) => handleCheckedAddInvoice(value, index)}
                                />
                            }
                          </div>

                          <div className='invoice-info-row'>
                            {
                              item.invoice_id ?
                                <div className='invoice-info-total'>
                                  Total: ${item.total.toFixed(2)}
                                </div> :
                                <div className='invoice-info-total'></div>
                            }

                            <div className='invoice-info-paid'>
                              Paid: ${item.paid.toFixed(2)}
                            </div>
                          </div>
                        </div>

                        <div className='invoice-arrow'>
                          {
                            item.invoice_id &&
                            <button>
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          }
                        </div>
                      </div>

                      {
                        item.is_check &&
                        <div className='payment-invoices-item-row'>
                          <div className='invoice-data'>
                            <div className='invoice-data-row'>
                              <div className='title'>Note:</div>

                              <div className='input'>
                                <input
                                  type="text"
                                  value={item.note}
                                  onChange={(event) => handleChangeNote(event.target.value, index, true)}
                                />
                              </div>
                            </div>

                            <div className='invoice-data-row'>
                              <div className='title'>Amount:</div>

                              <div className='input'>
                                <input
                                  className="appearance"
                                  type='number'
                                  value={`${Number(item.amount).toFixed(2)}`}
                                  step={0.01}
                                  onFocus={(event) => handleFocus(event)}
                                  onChange={(event) => handleChangeItem(event, index, item.is_add_invoice, true)}
                                  onKeyDown={(event) => handleChangeItemBackspace(event, index, true)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>

              {
                permission_search_show &&
                <div
                  className='add-invoice'
                  onClick={() => setShowSearchInvoices(true)}
                >
                  Add invoices from another job

                  <button>
                    <Icon viewBox="0 0 24 24" icon="arrow-25" />
                  </button>
                </div>
              }

              <div className='empty'></div>

              <div className='invoice-check'>
                <div
                  className='invoice-check-name'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setTakePic(true)}
                >
                  <div className='title'>
                    Take a pic
                  </div>
                </div>

                <div className='invoice-check-name'>
                  <div className='title'>
                    Check#
                  </div>
                  <input
                    type="text"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>

                <div className='invoice-check-input'>
                  Pay by Check: ${getPayByCheck().toFixed(2)}
                </div>
              </div>

              <div className='payment-page-actions'>
                <button
                  className="_bordered _red"
                  disabled={isSaving}
                  onClick={() => handleClose()}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _green"
                  disabled={getPayByCheck() === 0 || name === '' || isSaving}
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
      }
    </>
  )
}
