import { useEffect, useState } from "react";
import { useRoute } from "react-router5";
import classNames from "classnames";
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon";
import ReportFilters from "../../components/reports/Filters";
import ReportTableControls from "../../components/reports/TableControls";
import ReportTableField from "../../components/reports/TableField";

import { formatCIDLocalService, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { useAppSelector } from "../../store/hooks";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface ListProps {
  updated: number
}

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: GoogleReport
}

interface GoogleReport {
  interface: {
    filter_words: {
      mcid: string[],
    },
    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  google_local_services: GoogleLocalServicesProps[]

  permissions: {
    google_local_service_show: boolean
    google_local_service_add: boolean
  }

  edit: {
    google_accounts: GoogleAccountsProps[]
  }
}

interface GoogleLocalServicesProps {
  cid: string
  mcid: string
  created_at: string
  created_by: string
  google_account_id: string
  google_local_service_id: string
  name: string
  updated_at: string
  updated_by: string
}

export interface GoogleAccountsProps {
  email: string
  google_account_id: string
  name: string
  is_connect: boolean
}

type FilterWord = 'mcid'

export default function List({ updated }: ListProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportData, setReportData] = useState<GoogleReport | null>(null)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.google_local_services?.filter_field ? {
      mcid: [] as string[],
      ...cookiesSetting.google_local_services.filter_field,
    } : {
      mcid: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.google_local_services?.sort_field ? cookiesSetting.google_local_services.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.google_local_services?.sort_type ? cookiesSetting.google_local_services.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    }, {
      span: 'Manager CID',
      value: 'mcid',
    }, {
      span: 'CID',
      value: 'cid'
    }, {
      span: 'Google Account',
      value: 'google_account_id'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.google_local_services?.filter_field ? {
        mcid: [] as string[],
        ...cookiesSetting.google_local_services.filter_field,
      } : {
        mcid: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.google_local_services?.sort_field ? cookiesSetting.google_local_services.sort_field : 'name',
        direction: cookiesSetting && cookiesSetting?.google_local_services?.sort_type ? cookiesSetting.google_local_services.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load data function
  async function loadData() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    setCookie('settings', { ...cookiesSetting, google_local_services: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.google_local_services?.limit_rows ? cookiesSetting?.google_local_services.limit_rows : reportsMaxRows } })
    try {
      // https://2022back4.artemiudintsev.com/api/google/local-services/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: response, success, error } } = (await httpClientUpdate.get('/google/local-services/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.google_local_services?.limit_rows ? JSON.stringify(cookiesSetting?.google_local_services?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: JSON.stringify(localInterface.page),
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
        }
      })) as { data: HttpReport }
      if (success) {

        setReportData(response)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, google_local_services: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadData()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getGoogleName(id: string) {
    let name = ''
    let is_connected = false

    if (reportData) {
      reportData.edit.google_accounts.forEach(item => {
        if (item.google_account_id === id) {
          name = `${item.name} (${item.email})`
          is_connected = !!item.is_connect
        }
      })
    }

    return { name, is_connected }
  }

  return (
    <>
      {
        reportData ?
          <div className="CallsPage_List">
            { /* Page header */}
            <div className="page-header">
              <h1>Google Local Services</h1>
            </div>

            { /* List filters */}
            <ReportFilters
              onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
              searchInput={localInterface.search}
              searchButtonActive={searchButtonActive}

              updateButtonActive={reportIsDeprecated}
              onUpdate={() => {
                setReportIsDeprecated(false);
                $setUpdater(Math.random());
              }}

              phoneCall={phoneCall}
              navActive={navActive.is}
            />

            {/* Table controls */}
            <ReportTableControls
              zIndex={5}

              cookie_reportsMaxRows={cookiesSetting?.google_local_services?.limit_rows}

              onMaxRowsChange={(value) => {
                setCookie('settings', { ...cookiesSetting, google_local_services: { ...cookiesSetting.google_local_services, limit_rows: value } })
                $setUpdater(Math.random())
              }}

              amount={{
                total: reportData.interface.rows_all,
                start: reportData.interface.rows_start,
                end: reportData.interface.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages}
              onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
              onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
              onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
              onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}

              addButton={
                reportData.permissions.google_local_service_add ?
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('google-local-services.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      }, { reload: true })}
                    >
                      <Icon icon="plus-thin" />
                      <span>Add Local Services</span>
                      <Icon icon="user-29" />
                    </button>
                  </div> : <></>
              }
            />

            <div className="contents">

              {/* Wide desktop table */}
              <table className={classNames('table', '__show-on-wide',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>
                <thead>
                  <tr>
                    <ReportTableField
                      contents={(<span>Name</span>)}

                      sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />
                    <ReportTableField
                      contents={(<span>Manager CID</span>)}

                      allFiltersSelected={isAllFiltersSelected("mcid")}
                      onAllFiltersChange={(value) => switchFilter("mcid", "All", value)}

                      filterWords={reportData.interface.filter_words.mcid.map((filterWord) => ({
                        word: filterWord,
                        selected: isFilterSelected("mcid", filterWord)
                      }))}
                      onFilterChange={(value) => switchFilter("mcid", value.word, value.selected)}

                      sortDirection={localInterface.sort.field === 'mcid' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'mcid', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />

                    <ReportTableField
                      contents={(<span>CID</span>)}

                      sortDirection={localInterface.sort.field === 'cid' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'cid', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />
                    <ReportTableField
                      contents={(<span>Google Account</span>)}

                      sortDirection={localInterface.sort.field === 'google_account_id' ? localInterface.sort.direction : undefined}
                      onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'google_account_id', direction: value } })}

                      onFilterFire={() => $setUpdater(Math.random())}
                    />
                  </tr>
                </thead>

                {
                  reportData.google_local_services.map((account, i) => (
                    <tr
                      key={account.google_local_service_id}
                      style={{
                        cursor: reportData.permissions.google_local_service_show ? 'pointer' : 'inherit',
                        position: 'relative'
                      }}
                      onClick={() =>
                        reportData.permissions.google_local_service_show &&
                        account.google_local_service_id &&
                        $router.router.navigate('google-local-services.item', {
                          companyId: activeAccountId,
                          googleLocalServicesId: account.google_local_service_id,
                          localInterface: localInterface,
                        }, { reload: true })}
                      onContextMenu={(event) => {
                        reportData.permissions.google_local_service_show &&
                          account.google_local_service_id &&
                          handleChangeMenu(event, account.google_local_service_id, 'google-local-services', i)
                      }}
                    >
                      <td>
                        {account.name}
                      </td>

                      <td>
                        {formatCIDLocalService(account.mcid)}
                      </td>

                      <td>
                        {formatCIDLocalService(account.cid)}
                      </td>

                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={classNames('mailbox',
                            {
                              _connected: getGoogleName(account.google_account_id).is_connected,
                            }
                          )}
                            style={{ margin: '0 10px 0 0' }}
                          ></div>
                          {getGoogleName(account.google_account_id).name}
                        </div>

                        {
                          reportData.permissions.google_local_service_show &&
                          showContext === `google-local-services_${account.google_local_service_id}_${i}` &&
                          <div
                            className="redirect-menu"
                            ref={contextMenuRef}
                            style={{ left: `${screenX}px` }}
                          >
                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                $router.router.navigate('google-local-services.item', {
                                  companyId: activeAccountId,
                                  googleLocalServicesId: account.google_local_service_id,
                                  localInterface: localInterface,
                                }, { reload: true })
                              }
                              }
                            >
                              Open
                            </button>

                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                window.open(`${window.location.origin}/${activeAccountId}/google_local_services/${account.google_local_service_id}`, "_blank", 'noopener,noreferrer')
                                setShowContext('')
                              }}
                            >
                              Open in new tab
                            </button>
                          </div>
                        }
                      </td>
                    </tr>
                  ))
                }
              </table>

              {/* Medium screen table */}
              <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>
                <tr></tr>
                {reportData.google_local_services.map((account, i) => (
                  <tr
                    key={account.google_local_service_id}
                    style={{
                      cursor: reportData.permissions.google_local_service_show ? 'pointer' : 'inherit',
                      position: 'relative'
                    }}
                    onClick={() =>
                      reportData.permissions.google_local_service_show &&
                      account.google_local_service_id &&
                      $router.router.navigate('google-local-services.item', {
                        companyId: activeAccountId,
                        googleLocalServicesId: account.google_local_service_id,
                        localInterface: localInterface,
                      }, { reload: true })}
                    onContextMenu={(event) => {
                      reportData.permissions.google_local_service_show &&
                        account.google_local_service_id &&
                        handleChangeMenu(event, account.google_local_service_id, 'google-local-services', i)
                    }}
                  >
                    <td>
                      {account.name}
                    </td>

                    <td>
                      {formatCIDLocalService(account.mcid)}
                    </td>

                    <td>
                      {formatCIDLocalService(account.cid)}
                    </td>

                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classNames('mailbox',
                          {
                            _connected: getGoogleName(account.google_account_id).is_connected,
                          }
                        )}
                          style={{ margin: '0 10px 0 0' }}
                        ></div>
                        {getGoogleName(account.google_account_id).name}
                      </div>

                      {
                        reportData.permissions.google_local_service_show &&
                        showContext === `google-local-services_${account.google_local_service_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('google-local-services.item', {
                                companyId: activeAccountId,
                                googleLocalServicesId: account.google_local_service_id,
                                localInterface: localInterface,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/google_local_services/${account.google_local_service_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </table>

              {/* Mobile table items */}
              <div className={classNames('mobile-table-items', '__show-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>

                {reportData.google_local_services.map((account, i: number) => (
                  <div
                    className="item"
                    key={i}
                    onClick={(event) => {
                      event.preventDefault()
                      reportData.permissions.google_local_service_show &&
                        $router.router.navigate('google-local-services.item', {
                          companyId: activeAccountId,
                          googleLocalServicesId: account.google_local_service_id,
                          localInterface: localInterface,
                        }, { reload: true })
                    }}
                  >
                    <div className="__top">
                      <div className="__left">
                        <div>
                          <span className="gray">Name: </span>
                          <span> {account.name}</span>
                        </div>

                        <div>
                          <span className="gray">Manager CID: </span>
                          <span> {formatCIDLocalService(account.mcid)}</span>
                        </div>
                      </div>

                      <div className="__right">
                        <div>
                          <span className="gray">CID: </span>
                          <span> {formatCIDLocalService(account.cid)}</span>
                        </div>
                      </div>
                    </div>

                    <div className="__bottom">
                      <div className="__left">
                        <div className={classNames('mailbox-mobile',
                          {
                            _connected: getGoogleName(account.google_account_id).is_connected,
                          }
                        )}
                          style={{ marginRight: '5px' }}
                        ></div>
                        <span className="gray"> Google Account: </span>

                        <span>
                          {getGoogleName(account.google_account_id).name}
                        </span>
                      </div>

                      <div className="__right small">
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Table controls */}
            <ReportTableControls
              isUnder={true}

              onMaxRowsChange={(value) => {
                setCookie('settings', { ...cookiesSetting, google_local_services: { ...cookiesSetting.google_local_services, limit_rows: value } })
                $setUpdater(Math.random())
              }}

              amount={{
                total: reportData.interface.rows_all,
                start: reportData.interface.rows_start,
                end: reportData.interface.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages}
              onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
              onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
              onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
              onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}
            />
          </div> : null
      }
    </>
  )
}
