import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"

import Icon from "../../components/Icon"
import Select from "../../components/Select"

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"
import '../../styles/pages/sms.sass'

export interface TemplateProps {
  responsibles: string
  template: string
  type: string
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [message, setMessage] = useState('')
  const [notifyData, setNotifyData] = useState({
    type: '',
    notify_subject: '',
    notify_type: ''
  })

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/notify-templates
      const response = await httpClientUpdate.post(`/notify-templates`, {
        account_id: activeAccountId,
        type: notifyData.type,
        notify_text: message,
        notify_subject: notifyData.notify_subject,
        notify_type: notifyData.notify_type,
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('notify-templates', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      // message !== '' && handleSendMessage()
    }
  }

  return (
    <div className="TicketsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>Create Notify Template</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('notify-templates', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Type:</span>

              <Select
                options={['phone', 'email'].map((action) => ({
                  span: action,
                  value: action
                }))}
                selectedOption={notifyData.type}
                onChange={(value) => setNotifyData({ ...notifyData, type: value as string })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Notify Type:</span>

              <Select
                options={['payment'].map((action) => ({
                  span: action,
                  value: action
                }))}
                selectedOption={notifyData.notify_type}
                onChange={(value) => setNotifyData({ ...notifyData, notify_type: value as string })}
              />
            </div>
          </div>

          <div className="__left">
            <div className="field">
              <span>Notify Subject:</span>

              <input
                type="text"
                defaultValue={notifyData.notify_subject}
                onChange={({ target: { value } }) => setNotifyData({ ...notifyData, notify_subject: value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Template</div>

        <div
          style={{
            justifyContent: 'space-between',
            gridGap: 'inherit',
            textAlign: 'center'
          }}
          className="fields"
        >
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'relative'
          }}>
            <textarea
              style={{ width: '100%' }}
              placeholder='Your Message'
              value={message}
              onChange={({ target: { value } }) => setMessage(value)}
              onKeyDown={(event) => handleChangeMessage(event)}
            />

            <div
              style={{
                position: 'absolute',
                right: '5px',
                bottom: '5px',
                display: 'flex'
              }}
            >
              <button
                className='clear'
                disabled={message === ''}
                onClick={() => setMessage('')}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">Template View</div>

        <div
          style={{
            width: '100%',
            minHeight: '50px',
            padding: '10px',
            border: '1px solid #d0d3da',
            borderRadius: '5px',
            marginTop: '30px',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </div>

      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => $router.router.navigate('notify-templates', {
              companyId: activeAccountId
            }, {
              reload: true
            })}
          >
            Cancel
          </button>

          <button
            className="_bordered _green"
            disabled={message === '' || isSaving}
            onClick={() => handleSave()}
          >
            Create
          </button>
        </div>
      </div>
    </div >
  )
}
